import { Paper, CircularProgress, Box } from "@mui/material";
import { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import OrganizationListData from "./OrganizationListData";

function OrganizationDataGrid() {
  const { organizationStore, dialogStore } = useStore();
  const { organizationNestedLoading, setSelectedOrganization, getOrganizationList, deleteOrganization, organizationNested } = organizationStore;

  const onDelete = (id: string) =>
    dialogStore.open({
      action: async () => await deleteOrganization(id).then(() => getOrganizationList()),
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      actionButtonProps: {
        color: "error",
      },
      description: "Are you sure want to delete this item?",
    });

  useEffect(() => {
    getOrganizationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper sx={{ p: "16px" }}>
      {organizationNestedLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <OrganizationListData
          onEdit={(value) => setSelectedOrganization(value as any)}
          onDelete={(value) => onDelete(value)}
          list={organizationNested || []}
        />
      )}
    </Paper>
  );
}

export default observer(OrganizationDataGrid);
