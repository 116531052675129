import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { GridRowModel } from "@mui/x-data-grid-premium";
import { Box, Stack, Typography } from "@mui/material";
import { StyledDataGridPremium } from "../../app/component/StyledDataGridPremium";
import { useStore } from "../../app/stores/store";
import { convertProjectPlanToPreviewGrid } from "../../app/utils/utils";
import { PLAN_TYPE } from "../../app/config/enum";

type TExcelDataGrid = {
  setExcelDataPreview: Dispatch<SetStateAction<any>>;
  identifier: string;
};

export const ExcelDataGridPreview = ({ setExcelDataPreview, identifier }: TExcelDataGrid) => {
  const { uploadPlanProjectStore } = useStore();
  const { loadingUploadProjectAdmin, projectAdminPreviewGrid, setProjectAdminPreviewGrid } = uploadPlanProjectStore;
  const [gridColumnHelper, setGridColumnHelper] = useState<any[]>([]);
  const [gridGroupingNewData, setGridGroupingNewData] = useState<any>(undefined);

  const displayData = useMemo(() => {
    if (!gridGroupingNewData) return [];
    if (identifier === PLAN_TYPE.PHISYCAL) return gridGroupingNewData.physicalPlans;

    return gridGroupingNewData.financialPlans;
  }, [gridGroupingNewData, identifier]);

  const processRowUpdate = (newRow: GridRowModel) => {
    let helper = { ...gridGroupingNewData };
    const { proposalId, title, startDate, endDate, cost, details, ...rest } = newRow;

    let detailsDataTemp = Object.entries(rest).map(([key, value], index) => ({
      name: key,
      plan: value,
    }));
    const updatedRow = { ...newRow, details: detailsDataTemp };

    const temp = displayData.map((current: { proposalId: string }) => {
      if (current.proposalId !== newRow.proposalId) return current;
      return updatedRow;
    });

    let result = {
      ...helper,
      physicalPlans: identifier === PLAN_TYPE.PHISYCAL ? temp : helper?.physicalPlans,
      financialPlans: identifier === PLAN_TYPE.FINANCIAL ? temp : helper?.financialPlans,
    };

    setGridGroupingNewData(result);
    setProjectAdminPreviewGrid(result);
    return updatedRow;
  };

  useEffect(() => {
    if (!projectAdminPreviewGrid) {
      setGridColumnHelper([]);
      setGridGroupingNewData([]);
    } else {
      const { tempColumnHelper, result } = convertProjectPlanToPreviewGrid(projectAdminPreviewGrid);
      setGridColumnHelper(tempColumnHelper);
      setGridGroupingNewData(result);
    }
  }, [projectAdminPreviewGrid]);

  useEffect(() => {
    setExcelDataPreview(gridGroupingNewData);
  }, [gridGroupingNewData, setExcelDataPreview]);

  return (
    <Box>
      <Stack direction="row" gap="12px" sx={{ alignItems: "center", justifyContent: "space-between", height: "100%", my: "24px" }}>
        <Typography color="black" fontWeight={700} fontSize="20px">
          Preview Data {identifier}
        </Typography>
      </Stack>
      <StyledDataGridPremium
        getRowId={(row) => row.proposalId}
        disableRowSelectionOnClick
        autoHeight
        loading={loadingUploadProjectAdmin}
        editMode="row"
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ columnGrouping: true }}
        columns={[
          {
            field: "title",
            headerName: "Mata Anggaran",
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            width: 150,
          },
          {
            field: "startDate",
            headerName: "Start Date",
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            width: 150,
            renderCell: (params) => format(new Date(params.row.startDate), "MMM yyyy"),
          },
          {
            field: "endDate",
            headerName: "End Date",
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            width: 150,
            renderCell: (params) => format(new Date(params.row.endDate), "MMM yyyy"),
          },
          ...gridColumnHelper,
        ]}
        rows={displayData ?? []}
      />
    </Box>
  );
};
