import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, DialogContent, Stack, TextField, Typography } from "@mui/material";
import DraggableFileUpload, { DraggableFileUploadData } from "../../../../../../../app/component/input/DraggableFileUpload";
import { StatusTask, TaskSubTaskStatus, enumToArray } from "../../../../../../../app/config/enum";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Close, AddCardOutlined, Percent } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { NumericFormat } from "react-number-format";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { useStore } from "../../../../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import CSelect from "../../../../../../../app/component/CSelect";
import useQuery from "../../../../../../../app/hook/useQuery";
import * as yup from "yup";

type TAddCost = {
  setIsOpenCostModal: Dispatch<SetStateAction<any>>;
  setIsHasNoChild: Dispatch<SetStateAction<any>>;
  isOpenCostModal: boolean;
  projectOwnerName: string;
  isHasNoChild: boolean;
  selectedCost: any;
  generalCost: number;
  clearState: () => any;
  task: any;
  subTask: any;
};

function AddCost({
  setIsOpenCostModal,
  projectOwnerName,
  isOpenCostModal,
  setIsHasNoChild,
  selectedCost,
  generalCost,
  task,
  subTask,
  clearState,
}: TAddCost) {
  const { costRealizationStore, progressRealizationStore } = useStore();
  const { account } = useStore().accountStore;
  const [data, setData] = useState<DraggableFileUploadData[]>([]);
  const [deletedData, setDeletedData] = useState<any[]>([]);
  const query = useQuery();
  const id = query.get("id");

  const initialValues = useMemo(() => {
    return {
      weightFinancialActual: selectedCost ? Number(selectedCost?.weightFinancialActual) : 0,
      date: selectedCost ? new Date(selectedCost?.date ?? 0) : new Date(),
      status: TaskSubTaskStatus.Pembayaran,
      description: selectedCost ? selectedCost?.description : "",
      progress: selectedCost ? ((selectedCost?.weightFinancialActual / (subTask ? subTask.contractValue : task.contractValue)) * 100).toFixed(0) : 0,
    };
  }, [selectedCost, subTask, task]);

  const addCostFormik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      if (!id || !account) return;

      const formData = new FormData();
      let weightFinancialActualTemp = String(values.weightFinancialActual).replace("Rp", "");
      let dataAttachment = data.concat(deletedData);

      dataAttachment.forEach((item, index) => {
        formData.append(`attachments[${index}].attachment`, item.file as File);
        formData.append(`attachments[${index}].fileName`, "-");
        if (item.data) {
          formData.append(`attachments[${index}].id`, item.data.id);
          item.isDeleted && formData.append(`attachments[${index}].isDeleted`, item.isDeleted ? "1" : "0");
        }
      });
      formData.append("taskId", subTask ? subTask.tasksId : task.tasksId);
      subTask && formData.append("subtaskId", subTask.subTaskId);
      formData.append("weightFinancialActual", weightFinancialActualTemp.replaceAll(",", ""));
      formData.append("date", new Date(values.date).toUTCString());
      formData.append("description", values.description);
      formData.append("status", TaskSubTaskStatus.Pembayaran);
      formData.append("proposalId", id);

      if (selectedCost) {
        await costRealizationStore.editCostRealization(selectedCost?.id ?? "", formData).then(() => {
          progressRealizationStore.getNewProgressRealization(id, account, projectOwnerName);
          setIsOpenCostModal(false);
          setIsHasNoChild(false);
          addCostFormik.resetForm();
          clearState();
        });
      } else {
        await costRealizationStore.addCostRealization(formData).then(() => {
          progressRealizationStore.getNewProgressRealization(id, account, projectOwnerName);
          setIsOpenCostModal(false);
          setIsHasNoChild(false);
          addCostFormik.resetForm();
          clearState();
        });
      }
    },
    validationSchema: yup.object({
      date: yup.date().required(),
      progress: yup.number().required("Progress is a required field"),
      weightFinancialActual: yup.string().required("Cost is a required field"),
      status: yup.string().required(),
      description: yup.string(),
    }),
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!selectedCost) return;

    setData(
      selectedCost.attachments.map((item: any) => ({
        title: item.fileName ?? "No Name",
        file: { name: item.fileName ?? "No Name", id: item.id },
        data: item,
      })) ?? []
    );
  }, [selectedCost]);

  useEffect(() => {
    if (!isOpenCostModal) {
      setData([]);
      clearState();
    }
  }, [clearState, isOpenCostModal]);

  return (
    <form onSubmit={addCostFormik.handleSubmit} noValidate>
      <Dialog
        open={isOpenCostModal}
        onClose={() => {
          setIsOpenCostModal(false);
          addCostFormik.resetForm();
        }}
      >
        <DialogContent sx={{ padding: "16px", width: "365px" }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight="700" fontSize="14px" color="black">
              {`${selectedCost ? "Edit" : "Add"} Cost`}
            </Typography>
            <Close
              onClick={() => {
                setIsOpenCostModal(false);
                addCostFormik.resetForm();
              }}
            />
          </Stack>
          <Box mt={"12px"}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date"
                value={addCostFormik.values.date}
                views={["month", "year"]}
                onChange={(e: any) => addCostFormik.setFieldValue("date", e)}
                renderInput={(props) => (
                  <TextField
                    required
                    fullWidth
                    name="date"
                    error={!!addCostFormik.errors.date}
                    helperText={addCostFormik.errors.date && String(addCostFormik.errors.date)}
                    {...props}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box mt="12px">
            <CSelect
              options={enumToArray(StatusTask).map((statusTask) => ({
                label: statusTask.text,
                value: statusTask.value,
              }))}
              label="Status"
              name="status"
              disabled
              value={TaskSubTaskStatus.Pembayaran}
              error={!!addCostFormik.errors.status}
              helperText={addCostFormik.errors.status}
              onChange={addCostFormik.handleChange}
            />
          </Box>
          <Box mt="12px">
            <TextField
              label="Progress"
              name="progress"
              required
              fullWidth
              value={addCostFormik.values.progress}
              error={!!addCostFormik.errors.progress}
              helperText={addCostFormik.errors.progress}
              onChange={(e: any) => {
                addCostFormik.handleChange(e);
                addCostFormik.setFieldValue("weightFinancialActual", ((subTask ? subTask.contractValue : task.contractValue) * e.target.value) / 100);
              }}
              InputProps={{
                endAdornment: <Percent sx={{ color: "#757575" }} />,
              }}
            />
          </Box>
          <Box mt="12px">
            <NumericFormat
              prefix={"Rp"}
              thousandSeparator={true}
              customInput={TextField}
              label="Cost"
              name="weightFinancialActual"
              required
              fullWidth
              onChange={(e: any) => {
                addCostFormik.handleChange(e);
                let helper = e.target.value.replace("Rp", "");
                addCostFormik.setFieldValue(
                  "progress",
                  ((helper.replaceAll(",", "") / (subTask ? subTask.contractValue : task.contractValue)) * 100).toFixed(0)
                );
              }}
              value={addCostFormik.values.weightFinancialActual}
              error={!!addCostFormik.errors.weightFinancialActual}
              helperText={addCostFormik.errors.weightFinancialActual}
              InputProps={{
                endAdornment: <AddCardOutlined sx={{ color: "#757575" }} />,
              }}
            />
          </Box>
          <Box mt="12px">
            <TextField
              sx={{ mb: "12px" }}
              label="Description"
              name="description"
              multiline
              fullWidth
              rows={3}
              error={!!addCostFormik.errors.description}
              value={addCostFormik.values.description}
              onChange={addCostFormik.handleChange}
            />
          </Box>
          <Box mt="12px">
            <DraggableFileUpload
              getDeletedData={(value: any) => {
                let helper = deletedData;

                helper.push(value);
                setDeletedData(helper);
              }}
              data={data}
              setData={setData}
              editableTitle={false}
              accept="application/pdf, image/*"
            />
          </Box>

          <Stack direction="row" justifyContent="flex-end" gap="12px" mt={"24px"}>
            <Button
              onClick={() => {
                setIsOpenCostModal(false);
                addCostFormik.resetForm();
              }}
              type="button"
              variant="outlined"
              color="inherit"
            >
              Cancel
            </Button>
            <LoadingButton
              onClick={addCostFormik.submitForm}
              // disabled={!addCostFormik.isValid || !addCostFormik.dirty}
              loading={addCostFormik.isSubmitting}
              variant="contained"
              color="primary"
            >
              {`${selectedCost ? "Update" : "Add"}`}
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </form>
  );
}

export default observer(AddCost);
