import { makeAutoObservable, runInAction } from "mobx";
import { IProjectType, IProjectTypeBody } from "../models/projectType";
import agent from "../api/agent";

export class ProjectTypeStore {
  projectType: IProjectType[] = [];
  projectTypeLoading = false;
  projectTypesByUnitBusiness: IProjectType[] = [];
  projectTypesByUnitBusinessLoading = false;

  selectedProjectType: IProjectType | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  getProjectType = async (category?: string) => {
    this.projectTypeLoading = true;
    try {
      const res = await agent.General.getProjectType(category);

      runInAction(() => (this.projectType = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.projectTypeLoading = false));
    }
  };

  getProjectTypesByUnitBusiness = async (year: string | null, category?: string) => {
    this.projectTypesByUnitBusinessLoading = true;
    try {
      const res = await agent.General.getProjectTypeByUnitBusiness(year, category);

      runInAction(() => (this.projectTypesByUnitBusiness = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.projectTypesByUnitBusinessLoading = false));
    }
  };

  addProjectType = async (body: IProjectTypeBody) => {
    try {
      await agent.General.addProjectType(body);
    } catch (e) {
      throw e;
    }
  };

  editProjectType = async (id: string, body: IProjectTypeBody) => {
    try {
      await agent.General.editProjectType(id, body);
    } catch (e) {
      throw e;
    }
  };

  setSelectedProjectType = (projectType: IProjectType | null) => {
    this.selectedProjectType = projectType;
  };

  deleteProjectType = async (id: string) => {
    try {
      await agent.General.deleteProjectType(id);
    } catch (e) {
      throw e;
    }
  };
}
