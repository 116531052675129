import { makeAutoObservable } from "mobx";
import { AlertColor } from "@mui/material/Alert";

export default class SnackbarStore {
    isShow: boolean = false;
    message: string = "";
    severity: AlertColor = "info";

    constructor() {
        makeAutoObservable(this);
    }

    show = (severity: AlertColor, message: string) => {
        this.isShow = true;
        this.severity = severity;
        this.message = message;
    }

    handleHide = () => {
        this.isShow = false;
    }
}