import { DeleteOutline } from "@mui/icons-material";
import { Box, Button, Divider, FormControl, IconButton, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import ViewAttachment from "../ViewAttachment";

export type DraggableFileUploadData<T = any> = {
  isDeleted?: boolean;
  title: string;
  file: File | { name: string; id?: string };
  data?: T;
};

interface DraggableFileUploadProps<T> {
  data: DraggableFileUploadData<T>[];
  getDeletedData?: (value: any) => void;
  setData: React.Dispatch<React.SetStateAction<DraggableFileUploadData<T>[]>>;
  accept?: string;
  editableTitle?: boolean;
  viewableOptions?: {
    propsoalId: string;
  };
  viewable?: boolean;
  multiple?: boolean;
}

export default function DraggableFileUpload<T = any>({
  data,
  setData,
  getDeletedData,
  editableTitle = true,
  accept,
  viewableOptions,
  viewable,
  multiple = true,
}: DraggableFileUploadProps<T>) {
  const dropArea = useRef<HTMLDivElement>(null);
  const [isDraggingOverElement, setIsDraggingOverElement] = useState(false);

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDraggingOverElement(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDraggingOverElement(false);
  };

  const handleDrop = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      setIsDraggingOverElement(false);

      const dt = e.dataTransfer;
      const files = dt.files;

      Array.from(files).map((fl) => {
        setData((prev) => [...prev, { ...(fl as any), file: fl, title: "" }]);
      });
    },
    [setData]
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      Array.from(files).map((fl) => {
        setData((prev) => [...prev, { ...fl, file: fl, title: "" }]);
      });
    }
  };

  const handleGetDeletedData = (value: any) => {
    let temp = { ...value, isDeleted: true };

    if (getDeletedData && value.data) {
      getDeletedData(temp);
    }
  };

  useEffect(() => {
    dropArea.current?.addEventListener("dragover", handleDragOver);
    dropArea.current?.addEventListener("dragleave", handleDragLeave);
    dropArea.current?.addEventListener("drop", handleDrop);

    return () => {
      dropArea.current?.removeEventListener("dragover", handleDragOver);
      dropArea.current?.removeEventListener("dragleave", handleDragLeave);
      dropArea.current?.removeEventListener("drop", handleDrop);
    };
  }, [handleDrop]);

  return (
    <Stack gap={"16px"}>
      {data &&
        data.map((value, fileX) => (
          <UploadedItem
            editableTitle={editableTitle}
            key={fileX}
            viewableOptions={viewableOptions}
            value={value.title}
            filename={value.file.name}
            onDelete={() => {
              if (getDeletedData) {
                handleGetDeletedData(value);
              }
              setData((prev) => prev.filter((i, ix) => ix !== fileX));
            }}
            onChange={(e) => setData((prev) => prev.map((p, px) => (px === fileX ? { ...p, file: p.file, title: e.target.value } : p)))}
          />
        ))}
      <FormControl
        disabled={!multiple && !!data.length}
        ref={dropArea}
        sx={{
          border: ({ palette }) => "1px solid " + palette.grey[900],
          borderColor: ({ palette }) => (isDraggingOverElement ? palette.grey[900] : palette.grey[400]),
          backgroundColor: ({ palette }) => (isDraggingOverElement ? palette.grey[100] : "transparent"),
          borderRadius: "3px",
          p: "16px",
        }}
        fullWidth
      >
        <Stack gap="16px">
          <Typography textAlign="center" color={!multiple && !!data.length ? "GrayText" : "black"} fontWeight={700}>
            Drag Files Here
          </Typography>
          <Stack direction="row" alignItems="center" gap={"10px"} justifyContent="center">
            <Divider sx={{ width: "20px", borderWidth: "1px", borderColor: "#999999" }} />
            <Typography color="#999999">or</Typography>
            <Divider sx={{ width: "20px", borderWidth: "1px", borderColor: "#999999" }} />
          </Stack>
          <Button
            htmlFor="testing"
            component="label"
            color="inherit"
            variant="outlined"
            disabled={!multiple && !!data.length}
            sx={{ width: "fit-content", mx: "auto", textTransform: "capitalize" }}
          >
            Choose File
          </Button>
        </Stack>
      </FormControl>
      <input multiple={multiple} id={"testing"} type="file" hidden onChange={handleChange} accept={accept} />
    </Stack>
  );
}

interface UploadedItemProps {
  filename: string;
  onDelete: () => any;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => any;
  editableTitle: boolean;
  viewable?: boolean;
  viewableOptions?: {
    propsoalId: string;
  };
  url?: string;
}

const UploadedItem = ({ filename, editableTitle, value, onDelete, onChange, viewable, viewableOptions, url }: UploadedItemProps) => {
  return (
    <Box
      sx={{
        border: ({ palette }) => `1px solid ${palette.grey[400]}`,
        padding: "16px",
        borderRadius: "3px",
      }}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        {viewable && viewableOptions?.propsoalId && url ? (
          <ViewAttachment loadingComponent={<Skeleton width={150} variant="text" />} url={url} proposalId={viewableOptions.propsoalId}>
            <Typography fontWeight={700} color="black">
              {filename}
            </Typography>
          </ViewAttachment>
        ) : (
          <Typography fontWeight={700} color="black">
            {filename}
          </Typography>
        )}
        <IconButton onClick={onDelete} color="error" size="small">
          <DeleteOutline />
        </IconButton>
      </Stack>
      {editableTitle && <TextField sx={{ mt: "8px" }} onChange={onChange} label="File name" fullWidth value={value} />}
    </Box>
  );
};
