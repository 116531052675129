import { makeAutoObservable, reaction, runInAction, observable } from "mobx";
import { DataGridResult } from "../models/materialUI/dataGrid";
import agent from "../api/agent";
import { TSelectedSettingApproval, TSettingApproval, TSettingApprovalQueryParams } from "../models/settingApproval";

export class SettingApprovalStore {
  settingApprovalGrid: DataGridResult<TSettingApproval[]> = new DataGridResult<TSettingApproval[]>([], 0);
  queryParams: TSettingApprovalQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: "",
  };
  selectedSettingApproval: TSettingApproval | null = null;
  settingApprovalGridLoading = false;
  unitBusinessLoading = false;
  loadingForm = false;

  constructor() {
    makeAutoObservable(this, {
      loadingForm: observable,
    });

    reaction(
      () => this.queryParams,
      () => {
        this.getSettingApprovalGrid();
      }
    );
  }

  getSettingApprovalGrid = async () => {
    this.settingApprovalGridLoading = true;

    try {
      const res = await agent.Master.getSettingApprovalGrid(this.queryParams);

      runInAction(() => (this.settingApprovalGrid = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.settingApprovalGridLoading = false));
    }
  };

  addSettingApproval = async (body: TSelectedSettingApproval) => {
    try {
      await agent.Master.addSettingApproval(body);
    } catch (e) {
      throw e;
    }
  };

  editSettingApproval = async (id: string, body: TSelectedSettingApproval) => {
    try {
      await agent.Master.editSettingApproval(id, body);
    } catch (e) {
      throw e;
    }
  };

  setSelectedSettingApproval = (data: TSettingApproval | null) => {
    this.selectedSettingApproval = data;
  };

  deleteSettingApproval = async (id: string) => {
    try {
      await agent.Master.deleteSettingApproval(id);
    } catch (e) {
      throw e;
    }
  };

  setQueryParams = (query: "pageSize" | "search" | "currentPage", v: string | number) => {
    this.queryParams = {
      ...this.queryParams,
      [query]: v,
    };
  };
}
