import { makeAutoObservable, runInAction } from "mobx";
import { TStepBarPercentage } from "../models/stepBarPercentage";
import agent from "../api/agent";

export class stepBarPercentageStore {
  stepBarPercentage: TStepBarPercentage | undefined = undefined;
  stepBarPercentageLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  getStepBarPercentage = async (proposalId: string) => {
    this.stepBarPercentageLoading = true;
    try {
      const res = await agent.General.getStepBarPercentage(proposalId);

      runInAction(() => (this.stepBarPercentage = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.stepBarPercentageLoading = false));
    }
  };
}
