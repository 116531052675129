import { Button, Chip, Paper, TextField } from "@mui/material";
import { StyledDataGrid } from "../../app/component/StyledDataGrid";
import { useStore } from "../../app/stores/store";
import { useEffect } from "react";
import { format } from "date-fns";
import { Search } from "@mui/icons-material";
import { useDebounce } from "../../app/hook/useDebounce";
import { observer } from "mobx-react-lite";
import { NON_BD_OTHERS_NAME, ProjectCategory, ProjectStatus, ReviewerStatus } from "../../app/config/enum";
import { history } from "../..";

function ApprovalDataGrid() {
  const { getApproval, approval, approvalLoading, setQueryParams, queryparams } = useStore().approvalStore;

  const handleSearch = useDebounce(2000, (v) => setQueryParams("search", v.target.value));

  const handleClickDetail = (status: ReviewerStatus, title: string, id: string, category: ProjectCategory) => {
    switch (status) {
      case ReviewerStatus.InReview:
        return history.push(`/project-monitoring/approval/list/in-review?title=${title}&id=${id}`);
      case ReviewerStatus.WaitingReviewer:
        return history.push(`/project-monitoring/approval/list/${id}?status=${ProjectStatus.Reviewer}`);
      case ReviewerStatus.Submitted:
        return history.push(`/project-monitoring/proposal/list/form?projectCategory=${category}&id=${id}&status=${ProjectStatus.Reviewer}`);
      case ReviewerStatus.WaitingDecisionGate:
        return history.push(`/project-monitoring/approval/list/${id}?status=${ProjectStatus.DecisionGate}`);
      case ReviewerStatus.Approved:
        return history.push(
          `/project-monitoring/proposal/list/form?projectCategory=${category}&id=${id}&status=${ProjectStatus.DecisionGate}&reFID=0`
        );
    }
  };

  const handleToDetailPage = (id: string, step: string, title: string) => {
    alert(step);
    if (step !== ReviewerStatus.InReview) {
      history.push(`/project-monitoring/approval/list/${id}?status=${step}`);
    } else {
      history.push(`/project-monitoring/approval/list/in-review?title=${title}&id=${id}`);
    }
  };

  useEffect(() => {
    getApproval();
  }, [getApproval]);

  return (
    <Paper sx={{ p: "16px" }}>
      <TextField
        fullWidth
        sx={{ mb: "24px" }}
        onChange={handleSearch}
        placeholder="Search"
        InputProps={{
          startAdornment: <Search />,
        }}
      />
      <StyledDataGrid
        autoHeight
        columns={[
          {
            field: "proposalProject",
            headerName: "Usulan Investasi",
            headerAlign: "center",
            width: 300,
          },
          {
            field: "categoryDescription",
            headerAlign: "center",
            headerName: "Category",
            width: 200,
          },
          {
            field: "lastUpdate",
            headerAlign: "center",
            headerName: "Date",
            width: 150,
            renderCell: (params) => format(new Date(params.row.lastUpdate), "dd MMM yyyy - hh:mm"),
          },
          {
            field: "statusDescription",
            headerAlign: "center",
            headerName: "Status",
            width: 150,
            renderCell: (params) => <Chip label={params.row.statusDescription} />,
          },
          {
            field: "stepDescription",
            headerAlign: "center",
            headerName: "Tahapan",
            width: 150,
          },
          {
            field: "approval",
            headerAlign: "center",
            align: "center",
            headerName: "Progress",
            width: 150,
          },
          {
            field: "action",
            align: "center",
            headerAlign: "center",
            headerName: "Action",
            width: 150,

            renderCell: (params) => {
              return (
                <Button
                  onClick={() => handleClickDetail(params.row.status, params.row.proposalProject, params.row.id, params.row.category)}
                  variant="contained"
                >
                  See Detail
                </Button>
              );
            },
          },
        ]}
        disableSelectionOnClick
        rows={approval.data}
        loading={approvalLoading}
        pagination
        rowCount={approval.rowCount}
        paginationMode="server"
        rowsPerPageOptions={[5, 25, 50, 100]}
        pageSize={queryparams.pageSize}
        page={queryparams.currentPage}
        onPageChange={(v) => setQueryParams("currentPage", v)}
        onPageSizeChange={(v) => setQueryParams("pageSize", v)}
      />
    </Paper>
  );
}

export default observer(ApprovalDataGrid);
