import { useEffect, useState } from "react";
import { Box, Paper, TextField } from "@mui/material";
import DashboardLayoutHeader from "../../../app/component/layout/DashboardLayoutHeader";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ProjectCategory, ProjectStatus } from "../../../app/config/enum";
import { useStore } from "../../../app/stores/store";
import CollapsableDataList from "./components/CollapsableDataList";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";

// projectCategory props type is any because it's dynamic
export type TProposalIndexPageProps = {
  projectCategory: string;
  projectCategoryId: string;
};

export default function ProposalIndexPage({ projectCategory, projectCategoryId }: TProposalIndexPageProps) {
  const { setQueryParams } = useStore().projectManagementStore;
  const [year, setYear] = useState<string | null>(null);

  const route =
    (projectCategory === ProjectCategory.BD && `/project-monitoring/proposal/list/${ProjectCategory.BD}`) ||
    (projectCategory === ProjectCategory.NonBD && `/project-monitoring/proposal/list/${ProjectCategory.NonBD}`) ||
    (projectCategory === ProjectCategory.Anorganik && `/project-monitoring/proposal/list/${ProjectCategory.Anorganik}`) ||
    "";

  const categoryLabel =
    (projectCategory === ProjectCategory.BD && "Business Development") ||
    (projectCategory === ProjectCategory.NonBD && "Non-Business Development") ||
    (projectCategory === ProjectCategory.Anorganik && "Anorganik") ||
    "";

  useEffect(() => {
    setQueryParams("projectType", projectCategory);
  }, [projectCategory, setQueryParams]);

  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Proposal",
            href: route,
          },
          {
            label: "Proposal List",
            href: route,
          },
          {
            label: categoryLabel,
            href: route,
          },
        ]}
        pageTitle="Project Monitoring"
      >
        <Link to={`/project-monitoring/proposal/list/form/?status=${ProjectStatus.Proposal}&projectCategory=${projectCategory}`}>
          <DashboardLayoutHeader.Button startIcon={<Add />} variant="contained">
            Add Proposal
          </DashboardLayoutHeader.Button>
        </Link>
      </DashboardLayoutHeader>
      <Box sx={{ mb: "24px", display: "flex", justifyContent: "flex-end" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Year"
            componentsProps={{
              actionBar: {
                actions: ["clear"],
              },
            }}
            value={year}
            views={["year"]}
            inputFormat="yyyy"
            onChange={(e: any) => setYear(format(new Date(e), "yyyy") === "1970" ? null : format(new Date(e), "yyyy"))}
            renderInput={(props) => (
              <TextField onFocus={(e) => e.target.blur()} disabled size="small" sx={{ width: "100px" }} name="startYear" {...props} />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Paper sx={{ p: "16px", boxShadow: "none" }}>
        <CollapsableDataList year={year} projectCategory={projectCategory} projectCategoryId={projectCategoryId} />
      </Paper>
    </Box>
  );
}
