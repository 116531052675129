import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography } from "@mui/material";
import { DescriptionOutlined } from "@mui/icons-material";
import ViewAttachment from "../../../../app/component/ViewAttachment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

type TCancelNotes = {
  notes: string;
  projectOwnerName: string;
  cancelAttachment: string;
  proposalId: string;
};

export default function CancelNotes({ projectOwnerName, cancelAttachment, notes, proposalId }: TCancelNotes) {
  return (
    <Box>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <AccountCircleIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography color="black">{projectOwnerName}</Typography>}
            secondary={
              <Box>
                <Typography>{notes}</Typography>
              </Box>
            }
          />
        </ListItem>
        <hr />
        <Box sx={{ ml: "18px" }}>
          <Typography fontWeight="bold" color="black" sx={{ textAlign: "center", mb: "12px" }}>
            Attachment List
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box
                sx={{
                  display: "flex",
                  p: "8px",
                  mb: "12px",
                  borderRadius: "4px",
                  border: "1px solid rgba(29, 27, 32, 0.12)",
                }}
              >
                <ViewAttachment loadingComponent={<Skeleton width={150} variant="text" />} url={cancelAttachment} proposalId={proposalId}>
                  <DescriptionOutlined sx={{ color: "black", cursor: "pointer" }} />
                </ViewAttachment>
                <Box
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    display: "flex",
                    ml: "12px",
                  }}
                >
                  <Box sx={{ fontWeight: "bold", color: "black" }}>Attachment</Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </List>
    </Box>
  );
}
