import { Button, Dialog, DialogContent, Stack, TextField, Typography } from "@mui/material";
import DraggableFileUpload, { DraggableFileUploadData } from "../../../app/component/input/DraggableFileUpload";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { history } from "../../..";
import useQuery from "../../../app/hook/useQuery";

function ApproveDialog({ isOpen, onClose, isRequest = false }: { onClose: () => any; isOpen: boolean; isRequest?: boolean }) {
  const { submitReFid, approveReFid } = useStore().reFidStore;
  const [data, setData] = useState<DraggableFileUploadData[]>([]);
  const [summaryReview, setSummaryReview] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const query = useQuery();
  const status = query.get("status");
  const proposalId = query.get("proposalId");

  const onSubmit = async () => {
    if (!proposalId) return;

    setIsLoading(true);

    try {
      const formData = new FormData();

      data.forEach((item, index) => {
        formData.append(`data.attachments[${index}].file`, item.file as File);
        formData.append(`data.attachments[${index}].fileName`, "fileName");
      });
      formData.append("data.notes", summaryReview);

      if (isRequest) {
        await submitReFid(proposalId, formData).then(() => history.push(`/project-monitoring/re-fid/list`));
      } else {
        if (!status) return;
        formData.append("data.status", "APPROVED");
        await approveReFid(proposalId, status, formData).then(() => history.push(`/project-monitoring/re-fid/list`));
      }

      handleClose();
      history.push("/project-monitoring/re-fid/list");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setData([]);
    setError("");
    setIsLoading(false);

    onClose();
  };

  const handleSummaryReviewChange = (e: any) => {
    setSummaryReview(e.target.value);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent sx={{ width: "365px" }}>
        <Typography color="black" fontWeight={700} fontSize="14px">
          Info
        </Typography>
        <Typography mb="16px" color="black" mt="24px">
          {isRequest ? "Add document & notes to request Refid" : "Add document & summary review to approve Refid"}
        </Typography>
        <TextField
          onChange={(e) => handleSummaryReviewChange(e)}
          sx={{ mb: "12px" }}
          fullWidth
          label={isRequest ? "Notes" : "Summary Review"}
          name="notes"
          multiline
          rows={5}
        />
        <DraggableFileUpload data={data} setData={setData} editableTitle={false} accept="application/pdf, image/*" />
        <Stack mt={2} justifyContent="flex-end" direction="row" gap={1}>
          <Button onClick={handleClose} color="inherit" variant="outlined">
            Cancel
          </Button>
          <LoadingButton disabled={!!error || isLoading} loading={isLoading} variant="contained" onClick={onSubmit}>
            {isRequest ? "Submit" : "Approve"}
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default observer(ApproveDialog);
