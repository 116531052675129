import { Dispatch, SetStateAction } from "react";
import { Delete, DescriptionOutlined, EditOutlined, PriceCheck } from "@mui/icons-material";
import { TWeightPhysicalActuals } from "../../../../../../../app/models/progressRealization";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../../../app/stores/store";
import { format } from "date-fns";
import { Box, CircularProgress, Grid, Skeleton } from "@mui/material";
import useQuery from "../../../../../../../app/hook/useQuery";
import ViewAttachment from "../../../../../../../app/component/ViewAttachment";
import EmptyState from "../../../../../../../app/component/EmptyState";

type TCostHistoryCollapse = {
  financialActualProgress: any[];
  projectOwnerName: string;
  addProgressFormik: any;
  setIsOpenCostModal: Dispatch<SetStateAction<boolean>>;
  setSelectedCost: Dispatch<SetStateAction<any>>;
  setSelectedPhysicalActual: Dispatch<SetStateAction<TWeightPhysicalActuals | undefined>>;
  setIsOpenAddMOdal: Dispatch<SetStateAction<boolean>>;
};

function CostHistoryCollapse({
  setIsOpenCostModal,
  setSelectedCost,
  setSelectedPhysicalActual,
  setIsOpenAddMOdal,
  financialActualProgress,
  projectOwnerName,
  addProgressFormik,
}: TCostHistoryCollapse) {
  const { progressRealizationStore, dialogStore, accountStore, costRealizationStore } = useStore();
  const { account } = accountStore;
  const query = useQuery();
  const id = query.get("id");

  return (
    <Box>
      {costRealizationStore.costRealizationLoading ? (
        <CircularProgress />
      ) : (
        <>
          {financialActualProgress.length > 0 ? (
            <>
              <Grid container spacing={2}>
                {financialActualProgress
                  .slice(0)
                  .reverse()
                  .map((financial: any) => (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          p: "8px",
                          borderRadius: "4px",
                          border: "1px solid rgba(29, 27, 32, 0.12)",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "12px",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <Box sx={{ display: "flex", alignItems: "center", gap: "12px", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                              <PriceCheck />
                              <Box>
                                <Box sx={{ fontWeight: "bold" }}>{`Rp${financial.weightFinancialActual.toLocaleString()} | ${financial.status}`}</Box>
                                <Box sx={{ fontSize: "12px", color: "#999" }}>{financial.description}</Box>
                                <Box sx={{ fontSize: "12px", color: "#999" }}>{format(new Date(financial.date), "MMMM yyyy")}</Box>
                              </Box>
                            </Box>
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <Box
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  setIsOpenCostModal(true);
                                  setSelectedCost(financial);
                                  setSelectedPhysicalActual(financial);
                                }}
                              >
                                <EditOutlined sx={{ fontSize: "24px" }} />
                              </Box>
                              <Box
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  dialogStore.open({
                                    action: async () =>
                                      await costRealizationStore.deleteFinancialActualProgress(financial.id).then(() => {
                                        if (!id || !account) return;

                                        progressRealizationStore.getNewProgressRealization(id, account, projectOwnerName);
                                        setIsOpenAddMOdal(false);
                                        addProgressFormik.resetForm();
                                      }),
                                    actionText: "OK",
                                    title: "Delete",
                                    closeText: "Cancel",
                                    actionButtonProps: {
                                      color: "error",
                                    },
                                    description: "Are you sure want to delete this pembayaran?",
                                  });
                                }}
                              >
                                <Delete sx={{ fontSize: "24px" }} />
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            {financial.attachments.length > 0 ? (
                              <>
                                <hr />
                                <Grid container spacing={2}>
                                  {financial.attachments.map((attachment: any) => (
                                    <Grid item xs={6}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          p: "8px",
                                          borderRadius: "4px",
                                          border: "1px solid rgba(29, 27, 32, 0.12)",
                                        }}
                                      >
                                        <ViewAttachment
                                          loadingComponent={<Skeleton width={150} variant="text" />}
                                          url={attachment.url}
                                          proposalId={id ?? ""}
                                        >
                                          <DescriptionOutlined sx={{ cursor: "pointer" }} />
                                        </ViewAttachment>
                                        <Box
                                          sx={{
                                            width: "100%",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            display: "flex",
                                            ml: "12px",
                                          }}
                                        >
                                          <Box sx={{ fontWeight: "bold" }}>{attachment.fileName ?? "No Name"}</Box>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  ))}
                                </Grid>
                              </>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </>
          ) : (
            <EmptyState />
          )}
        </>
      )}
    </Box>
  );
}

export default observer(CostHistoryCollapse);
