import { Check, ChevronLeft, Close, DoubleArrow, EmojiFlagsOutlined, CancelOutlined } from "@mui/icons-material";
import DashboardLayoutHeader from "../../../app/component/layout/DashboardLayoutHeader";
import ProjectMonitoringFormStepper from "../ProjectMonitoringFormStepper";
import { Box, Chip, IconButton, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import MyTabPanel from "../../../app/component/TabPanel";
import ProposalLogTab from "./tabs/ProposalLogTab";
import ProposalDashboardTab from "./tabs/ProposalDashboardTab";
import ProposalUserManagement from "./tabs/ProposalUserManagement";
import a11yProps from "../../../app/utils/a11yProps";
import FidTab from "./tabs/fid/FidTab";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import useQuery from "../../../app/hook/useQuery";
import { history } from "../../..";
import ProposalGeneralInformationTab from "./tabs/ProposalGeneralInformationTab";
import { ProjectStatus } from "../../../app/config/enum";
import ProposalReviewerTab from "./tabs/ProposalReviewerTab";
import ApproveProposalForm from "./form/ApproveProposalForm";
import RefuseProposalForm from "./form/RefuseProposalForm";
import { HistoryCommentDataGrid } from "./components/HistoryCommentDataGrid";
import DecisionGateDialog from "./components/DecisionGateDialog";
import CancelProjectDialog from "./components/CancelProjectDialog";
import Connector from "../../../signalr-connection";
import ProposalRefidHistoryTab from "./tabs/ProposalRefidHistoryTab";

function ProposalFormPage() {
  const { newMessage } = Connector();
  const [openDecisionGateDialog, setOpenDecisionGateDialog] = useState(false);
  const [openCancelProjectDialog, setOpenCancelProjectDialog] = useState(false);
  const [tabIndex, setTabIndex] = useState(1);
  const { getGeneralInformation, generalInformation, generalInformationLoading } = useStore().proposalStore;
  const { promoteProposal, setProjectStatus, nextProjectStatus, currentProjectStatus } = useStore().projectManagementStore;
  const { resetGeneralInformation } = useStore().proposalStore;
  const query = useQuery();
  const { show } = useStore().snackbarStore;
  const [isLoading, setIsLoading] = useState(false);
  const [prevTabIndex, setPrevTabIndex] = useState(0);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openRefuseModal, setOpenRefuseModal] = useState(false);

  const reviewPage = query.get("reviewPage");
  const projectCategory = query.get("projectCategory");
  const isReFID = query.get("reFID");
  const fromPage = query.get("fromPage");

  const isApprovalMode = useMemo(() => {
    return reviewPage === "true";
  }, [reviewPage]);

  const [currentTabName, setCurrentTabName] = useState<string>();

  const isInProgresStep =
    currentProjectStatus?.status === ProjectStatus.Preparation ||
    currentProjectStatus?.status === ProjectStatus.Procurement ||
    currentProjectStatus?.status === ProjectStatus.Construction;

  const id = query.get("id");
  const status = query.get("status");

  const handlePromote = async () => {
    if (!id) return;

    setIsLoading(true);
    if (nextProjectStatus) {
      await promoteProposal(id, isInProgresStep ? ProjectStatus.ProjectClosing : nextProjectStatus.status)
        .then(() => {
          show("success", "Promote proposal sukses");
          history.push(`/project-monitoring/proposal/list/${projectCategory}`);
          newMessage(new Date().toISOString());
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
    return () => {
      resetGeneralInformation();
    };
  }, [getGeneralInformation, id, resetGeneralInformation]);

  useEffect(() => {
    // set tab index to preparation
    if (fromPage === "dashboard") {
      setTabIndex(2);
    } else {
      setTabIndex(1);
    }
  }, [fromPage]);

  const renderTab = (current: ProjectStatus) => {
    switch (status) {
      case ProjectStatus.Proposal:
        return (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabIndex}
                onChange={(_, v) => {
                  if (v !== 2) setPrevTabIndex(v);
                  setTabIndex(v);
                }}
                aria-label="basic tabs example"
              >
                <Tab label="Dashboard" {...a11yProps(0)} />
                <Tab label="Detail Information" {...a11yProps(1)} />
                <Tab label="Member" {...a11yProps(2)} />
                <Tab label="Log" {...a11yProps(3)} />
                <Tab label="History Re-FID" {...a11yProps(4)} />
              </Tabs>
            </Box>
            <MyTabPanel value={tabIndex} index={0}>
              <ProposalDashboardTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={1}>
              <ProposalGeneralInformationTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={2}>
              <ProposalUserManagement />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={3}>
              <ProposalLogTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={4}>
              <ProposalRefidHistoryTab />
            </MyTabPanel>
          </>
        );
      case ProjectStatus.Fid:
        return (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabIndex}
                onChange={(_, v) => {
                  if (v !== 2) setPrevTabIndex(v);
                  setTabIndex(v);
                }}
                aria-label="basic tabs example"
              >
                <Tab label="Dashboard" {...a11yProps(0)} />
                <Tab label="Detail Information" {...a11yProps(1)} />
                <Tab onClick={() => setCurrentTabName("FID")} label="FID" {...a11yProps(2)} />
                <Tab label="Member" {...a11yProps(3)} />
                <Tab label="Log" {...a11yProps(4)} />
                <Tab label="History Re-FID" {...a11yProps(5)} />
              </Tabs>
            </Box>
            <MyTabPanel value={tabIndex} index={0}>
              <ProposalDashboardTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={1}>
              <ProposalGeneralInformationTab />
            </MyTabPanel>
            {/* <MyTabPanel value={tabIndex} index={2}>
              <FidTab />
            </MyTabPanel> */}
            <MyTabPanel value={tabIndex} index={3}>
              <ProposalUserManagement />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={4}>
              <ProposalLogTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={5}>
              <ProposalRefidHistoryTab />
            </MyTabPanel>
          </>
        );
      case ProjectStatus.Reviewer:
      case ProjectStatus.DecisionGate:
        return (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabIndex}
                onChange={(_, v) => {
                  if (v !== 2) setPrevTabIndex(v);
                  setTabIndex(v);
                }}
                aria-label="basic tabs example"
              >
                <Tab label="Dashboard" {...a11yProps(0)} />
                <Tab label="Detail Information" {...a11yProps(1)} />
                {/* <Tab onClick={() => setCurrentTabName("FID")} label="FID" {...a11yProps(2)} /> */}
                <Tab label="Member" {...a11yProps(3)} />
                <Tab label="Reviewer" {...a11yProps(4)} />
                <Tab label="Log" {...a11yProps(5)} />
                <Tab label="History Re-FID" {...a11yProps(6)} />

              </Tabs>
            </Box>
            <MyTabPanel value={tabIndex} index={0}>
              <ProposalDashboardTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={1}>
              <ProposalGeneralInformationTab />
            </MyTabPanel>
            {/* <MyTabPanel value={tabIndex} index={2}>
              <FidTab />
            </MyTabPanel> */}
            <MyTabPanel value={tabIndex} index={2}>
              <ProposalUserManagement />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={3}>
              <ProposalReviewerTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={4}>
              <ProposalLogTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={6}>
              <ProposalRefidHistoryTab />
            </MyTabPanel>
          </>
        );
      case ProjectStatus.Preparation:
        return (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabIndex}
                onChange={(_, v) => {
                  if (v !== 2) setPrevTabIndex(v);
                  setTabIndex(v);
                }}
                aria-label="basic tabs example"
              >
                <Tab label="Dashboard" {...a11yProps(0)} />
                <Tab label="Detail Information" {...a11yProps(1)} />
                <Tab onClick={() => setCurrentTabName("FID")} label="Perencanaan" {...a11yProps(2)} />
                <Tab label="Member" {...a11yProps(3)} />
                <Tab label="Reviewer" {...a11yProps(4)} />
                <Tab label="Log" {...a11yProps(5)} />
                <Tab label="History Re-FID" {...a11yProps(6)} />
              </Tabs>
            </Box>
            <MyTabPanel value={tabIndex} index={0}>
              <ProposalDashboardTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={1}>
              <ProposalGeneralInformationTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={2}>
              <FidTab fromPage="dashboard" />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={3}>
              <ProposalUserManagement />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={4}>
              <ProposalReviewerTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={5}>
              <ProposalLogTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={6}>
              <ProposalRefidHistoryTab />
            </MyTabPanel>
          </>
        );
      case ProjectStatus.Procurement:
        return (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabIndex}
                onChange={(_, v) => {
                  if (v !== 2) setPrevTabIndex(v);
                  setTabIndex(v);
                }}
                aria-label="basic tabs example"
              >
                <Tab label="Dashboard" {...a11yProps(0)} />
                <Tab label="Detail Information" {...a11yProps(1)} />
                <Tab onClick={() => setCurrentTabName("FID")} label="Perencanaan" {...a11yProps(2)} />
                <Tab label="Member" {...a11yProps(3)} />
                <Tab label="Reviewer" {...a11yProps(4)} />
                <Tab label="Log" {...a11yProps(5)} />
                <Tab label="History Re-FID" {...a11yProps(6)} />
              </Tabs>
            </Box>
            <MyTabPanel value={tabIndex} index={0}>
              <ProposalDashboardTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={1}>
              <ProposalGeneralInformationTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={2}>
              <FidTab fromPage="dashboard" />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={3}>
              <ProposalUserManagement />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={4}>
              <ProposalReviewerTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={5}>
              <ProposalLogTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={6}>
              <ProposalRefidHistoryTab />
            </MyTabPanel>
          </>
        );
      case ProjectStatus.Construction:
        return (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabIndex}
                onChange={(_, v) => {
                  if (v !== 2) setPrevTabIndex(v);
                  setTabIndex(v);
                }}
                aria-label="basic tabs example"
              >
                <Tab label="Dashboard" {...a11yProps(0)} />
                <Tab label="Detail Information" {...a11yProps(1)} />
                <Tab onClick={() => setCurrentTabName("FID")} label="Perencanaan" {...a11yProps(2)} />
                <Tab label="Member" {...a11yProps(3)} />
                <Tab label="Reviewer" {...a11yProps(4)} />
                <Tab label="Log" {...a11yProps(5)} />
                <Tab label="History Re-FID" {...a11yProps(6)} />
              </Tabs>
            </Box>
            <MyTabPanel value={tabIndex} index={0}>
              <ProposalDashboardTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={1}>
              <ProposalGeneralInformationTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={2}>
              <FidTab fromPage="dashboard" />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={3}>
              <ProposalUserManagement />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={4}>
              <ProposalReviewerTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={5}>
              <ProposalLogTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={6}>
              <ProposalRefidHistoryTab />
            </MyTabPanel>
          </>
        );
      case ProjectStatus.ProjectClosing:
        return (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabIndex}
                onChange={(_, v) => {
                  if (v !== 2) setPrevTabIndex(v);
                  setTabIndex(v);
                }}
                aria-label="basic tabs example"
              >
                <Tab label="Dashboard" {...a11yProps(0)} />
                <Tab label="Detail Information" {...a11yProps(1)} />
                <Tab onClick={() => setCurrentTabName("FID")} label="Perencanaan" {...a11yProps(2)} />
                <Tab label="Member" {...a11yProps(3)} />
                <Tab label="Reviewer" {...a11yProps(4)} />
                <Tab label="Log" {...a11yProps(5)} />
                <Tab label="History Re-FID" {...a11yProps(6)} />
              </Tabs>
            </Box>
            <MyTabPanel value={tabIndex} index={0}>
              <ProposalDashboardTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={1}>
              <ProposalGeneralInformationTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={2}>
              <FidTab fromPage="dashboard" />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={3}>
              <ProposalUserManagement />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={4}>
              <ProposalReviewerTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={5}>
              <ProposalLogTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={6}>
              <ProposalRefidHistoryTab />
            </MyTabPanel>
          </>
        );
      case ProjectStatus.CancelProject:
        return (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabIndex}
                onChange={(_, v) => {
                  if (v !== 2) setPrevTabIndex(v);
                  setTabIndex(v);
                }}
                aria-label="basic tabs example"
              >
                <Tab label="Dashboard" {...a11yProps(0)} />
                <Tab label="Detail Information" {...a11yProps(1)} />
                <Tab onClick={() => setCurrentTabName("FID")} label="Perencanaan" {...a11yProps(2)} />
                <Tab label="Member" {...a11yProps(3)} />
                <Tab label="Reviewer" {...a11yProps(4)} />
                <Tab label="Log" {...a11yProps(5)} />
                <Tab label="History Re-FID" {...a11yProps(6)} />
              </Tabs>
            </Box>
            <MyTabPanel value={tabIndex} index={0}>
              <ProposalDashboardTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={1}>
              <ProposalGeneralInformationTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={2}>
              <FidTab fromPage="dashboard" />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={3}>
              <ProposalUserManagement />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={4}>
              <ProposalReviewerTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={5}>
              <ProposalLogTab />
            </MyTabPanel>
            <MyTabPanel value={tabIndex} index={6}>
              <ProposalRefidHistoryTab />
            </MyTabPanel>
          </>
        );
      default:
        return (
          <>
            <Tab label="Dashboard" {...a11yProps(0)} />
            <Tab label="Detail Information" {...a11yProps(1)} />
            <Tab label="Member" {...a11yProps(3)} />
            <Tab label="Log" {...a11yProps(4)} />
          </>
        );
    }
  };

  const decisionGateDialog = () => setOpenDecisionGateDialog(true);
  const cancelProjectDialog = () => setOpenCancelProjectDialog(true);

  useEffect(() => {
    if (status) setProjectStatus(status as any);

    return () => setProjectStatus(null);
  }, [setProjectStatus, status]);

  return (
    <div>
      <DashboardLayoutHeader
        pageTitle={`Project Monitoring ${generalInformation?.isReFID ? "- RE-FID" : ""}`}
        breadcrumbs={[
          {
            label: "Proposal",
            href: `/project-monitoring/proposal/list/${projectCategory}`,
          },
          {
            label: "Proposal List",
            href: `/project-monitoring/proposal/list/${projectCategory}`,
          },
          {
            label: "Add Proposal",
          },
        ]}
        chip={
          !!isReFID && Number(isReFID) === 1 ? (
            <Chip sx={{ backgroundColor: "#8CC257", color: "white", fontWeight: 700 }} label="Re-FID" />
          ) : undefined
        }
      >
        {isApprovalMode ? (
          <>
            <DashboardLayoutHeader.Button
              loading={isLoading}
              variant="contained"
              startIcon={<Close />}
              onClick={() => setOpenRefuseModal(true)}
              color="error"
            >
              Refuse
            </DashboardLayoutHeader.Button>
            <DashboardLayoutHeader.Button
              loading={isLoading}
              variant="contained"
              startIcon={<Check />}
              onClick={() => setOpenApproveModal(true)}
              color="success"
            >
              Approve
            </DashboardLayoutHeader.Button>
            <RefuseProposalForm isOpen={openRefuseModal} onClose={() => setOpenRefuseModal(false)} />
            <ApproveProposalForm isOpen={openApproveModal} onClose={() => setOpenApproveModal(false)} />
          </>
        ) : (
          <>
            {status === ProjectStatus.ProjectClosing || status === ProjectStatus.DecisionGate ? (
              ""
            ) : (
              <>
                {status !== ProjectStatus.CancelProject ? (
                  <>
                    {isInProgresStep || status === ProjectStatus.Fid ? (
                      <DashboardLayoutHeader.Button
                        loading={isLoading}
                        variant="contained"
                        color="error"
                        startIcon={<CancelOutlined />}
                        onClick={cancelProjectDialog}
                      >
                        Cancel Project
                      </DashboardLayoutHeader.Button>
                    ) : (
                      ""
                    )}
                    <DashboardLayoutHeader.Button
                      loading={isLoading}
                      variant="contained"
                      startIcon={isInProgresStep ? <EmojiFlagsOutlined /> : <DoubleArrow sx={{ rotate: "-90deg" }} />}
                      onClick={status === ProjectStatus.Reviewer ? decisionGateDialog : handlePromote}
                    >
                      {isInProgresStep ? "Close Project" : `Promote to ${nextProjectStatus?.text}`}
                    </DashboardLayoutHeader.Button>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        )}
      </DashboardLayoutHeader>
      <ProjectMonitoringFormStepper projectCategory={projectCategory ?? ""} current={currentProjectStatus?.step ?? (1 as any)} />
      <Paper sx={{ p: "16px", mt: "24px" }}>
        {currentTabName === "FID" ? (
          <FIDSection
            isInProgresStep={isInProgresStep}
            onBack={() => {
              setTabIndex(prevTabIndex);
              setCurrentTabName(undefined);
            }}
          />
        ) : (
          renderTab(currentProjectStatus?.status ?? ProjectStatus.Proposal)
        )}
      </Paper>
      <DecisionGateDialog
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        isInProgresStep={isInProgresStep}
        projectCategory={projectCategory ?? ""}
        isOpen={openDecisionGateDialog}
        onClose={() => setOpenDecisionGateDialog(false)}
      />
      <CancelProjectDialog
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        projectCategory={projectCategory ?? ""}
        isOpen={openCancelProjectDialog}
        onClose={() => setOpenCancelProjectDialog(false)}
      />
    </div>
  );
}

const FIDSection = ({ onBack, isInProgresStep }: { onBack: () => any; isInProgresStep: boolean }) => {
  return (
    <Box>
      <Stack direction="row" spacing="12px" alignItems="center">
        <IconButton onClick={onBack}>
          <ChevronLeft />
        </IconButton>
        <Typography color="black" fontWeight={700} fontSize="20px">
          {isInProgresStep ? "Perencanaan" : "FID"}
        </Typography>
      </Stack>
      <FidTab />
    </Box>
  );
};

export default observer(ProposalFormPage);
