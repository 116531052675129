import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { DataGridResult } from "../models/materialUI/dataGrid";
import { IOwnershipCategory, IOwnershipCategoryBody, IOwnershipCategoryQueryParams } from "../models/ownershipCategory";

export class OwnershipCategoryStore {
  ownershipCategoryGrid: DataGridResult<IOwnershipCategory[]> = new DataGridResult([], 0)
  ownershipCategoryGridLoading = false;
  
  ownershipStatus: IOwnershipCategory[] = []
  ownershipStatusLoading = false
  
  selectedOwnershipCategory: IOwnershipCategory | null = null
  
  queryparams: IOwnershipCategoryQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: ''
  }
  
  constructor() {
    makeAutoObservable(this)

    reaction(() => this.queryparams, () => {
      this.getOwnershipCategoryGrid()
    })
  }

  getOwnershipCategoryGrid = async () => {
    this.ownershipCategoryGridLoading = true
    try {
      const res = await agent.Master.getOwnershipCategoryGrid(this.queryparams)

      runInAction(() => this.ownershipCategoryGrid = res);
    } catch (e) {
      throw e
    } finally {
      runInAction(() => this.ownershipCategoryGridLoading = false)
    }
  }

  getOwnershipStatus = async () => {
    this.ownershipStatusLoading = true
    try {
      const res = await agent.Master.getOwnershipStatus()

      runInAction(() => this.ownershipStatus = res);
    } catch (e) {
      throw e
    } finally {
      runInAction(() => this.ownershipStatusLoading = false)
    }
  }

  addOwnershipCategory = async (body: IOwnershipCategoryBody) => {
    try {
      await agent.Master.addOwnershipCategory(body)
    } catch (e) {
      throw e;
    }
  }
  
  editOwnershipCategory = async (id: string, body: IOwnershipCategoryBody) => {
    try {
      await agent.Master.editOwnershipCategory(id, body)
    } catch (e) {
      throw e;
    }
  }

  setSelectedOwnershipCategory = (data: IOwnershipCategory | null) => {
    this.selectedOwnershipCategory = data;
  }

  deleteOwnershipCategory = async (id: string) => {
    try {
      await agent.Master.deleteOwnershipCategory(id)
    } catch (e) {
      throw e;
    }
  }

  setQueryParams = (query: 'pageSize' | 'search' | 'currentPage', v: string | number) => {
    this.queryparams = {
      ...this.queryparams,
      [query]: v,
    }
  }
}