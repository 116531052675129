import { useEffect } from "react";
import { ArrowBackIos, VisibilityOutlined, DescriptionOutlined, AttachFile } from "@mui/icons-material";
import { Grid, Paper, Box, Skeleton } from "@mui/material";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { Link } from "react-router-dom";
import DashboardLayoutHeader from "../../../app/component/layout/DashboardLayoutHeader";
import PageButton from "../components/PageButton";
// import CommentSection from "../components/CommentSection";
import LoadingSection from "../components/LoadingSection";
import useQuery from "../../../app/hook/useQuery";
import ViewAttachment from "../../../app/component/ViewAttachment";
import EmptyState from "../../../app/component/EmptyState";
import { ProjectCategory } from "../../../app/config/enum";

function KajianFs() {
  const { proposalStore } = useStore();
  const { getGeneralInformation, generalInformation, getKajianFs, kajianFs, kajianFsLoading } = proposalStore;
  const { id } = useParams();
  const query = useQuery();
  const status = query.get("status");

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
  }, [getGeneralInformation, id]);

  useEffect(() => {
    if (!id) return;

    getKajianFs(id);
  }, [getKajianFs, id]);

  return (
    <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Approval",
            href: "/project-monitoring/approval/list",
          },
          {
            label: "Approval List",
            href: "/project-monitoring/approval/list",
          },
          {
            label: `${generalInformation?.title}`,
          },
        ]}
        pageTitle="Project Monitoring"
      />

      <Paper sx={{ p: "16px" }}>
        <Link
          style={{ color: "#000", textDecoration: "none", display: "flex", alignItems: "center", gap: "3px", marginBottom: "24px" }}
          to={`/project-monitoring/approval/list/${id}?status=${status}`}
        >
          <ArrowBackIos sx={{ fontSize: "14px" }} />
          <Box>Back</Box>
        </Link>
        <Box
          sx={{
            mb: "12px",
            backgroundColor: "#F2F9FF",
            display: "flex",
            p: "16px",
            alignItems: "center",
            gap: "6px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <AttachFile sx={{ mr: "12px", color: "#0664B4", fontSize: "34px" }} />
            <Box sx={{ fontWeight: "bold" }}>{generalInformation?.category === ProjectCategory.BD ? "Kajian FS" : "Kajian PS"}</Box>
          </Box>
          {/* {kajianFsLoading ? "Loading..." : <CommentSection typeId={kajianFs?.id ?? ""} />} */}
        </Box>

        {kajianFsLoading ? (
          <LoadingSection />
        ) : (
          <Box>
            {kajianFs ? (
              <Grid pt="12px" container spacing={3}>
                <Grid key={kajianFs?.id ?? ""} item xs={6} spacing={3}>
                  <Box
                    sx={{
                      width: "100%",
                      alignItems: "center",
                      display: "flex",
                      p: "16px",
                      borderRadius: "4px",
                      border: "1px solid rgba(29, 27, 32, 0.12)",
                    }}
                  >
                    <DescriptionOutlined />
                    <Box sx={{ width: "100%", justifyContent: "space-between", alignItems: "center", display: "flex", ml: "12px" }}>
                      <Box>
                        <Box sx={{ fontWeight: "bold" }}>{kajianFs?.fileName}</Box>
                      </Box>
                      <ViewAttachment
                        loadingComponent={<Skeleton width={150} variant="text" />}
                        url={kajianFs?.attachment ?? ""}
                        proposalId={id ?? ""}
                      >
                        <VisibilityOutlined sx={{ cursor: "pointer" }} />
                      </ViewAttachment>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <EmptyState />
            )}
          </Box>
        )}

        <PageButton
          isPrevButton
          prevButtonRoute={
            generalInformation?.category === ProjectCategory.BD
              ? `/project-monitoring/approval/list/${id}/permission?status=${status}}`
              : `/project-monitoring/approval/list/${id}/investmentBudget?status=${status}`
          }
        />
      </Paper>
      <Box sx={{ py: "16px" }} />
    </Box>
  );
}

export default observer(KajianFs);
