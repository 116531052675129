import { makeAutoObservable } from "mobx";
import { history } from "../../..";

interface Modal {
    open: boolean;
    title: string;
    body: JSX.Element | null;
    loading: boolean;
}

export default class ModalStore {
    returnUrl?: string;

    modal: Modal = {
        open: false,
        title: "",
        body: null,
        loading: false
    }

    constructor() {
        makeAutoObservable(this);
    }

    setLoading = (value: boolean) => {
        this.modal.loading = value;
    }

    setTitle = (value: string) => {
        this.modal.title = value;
    }

    open = (content: JSX.Element, returnUrl?: string) => {
        this.modal.open = true;
        this.modal.body = content;
        this.returnUrl = returnUrl;
    }

    close = async () => {
        this.modal.open = false;
        this.modal.body = null;
        
        if (this.returnUrl) history.push(this.returnUrl);
        this.returnUrl = "";

        this.setLoading(false);
        this.setTitle("");
    }
}