import { useFormik } from "formik";
import DrawerForm from "../../app/component/DrawerForm";
import * as yup from "yup";
import { TextField } from "@mui/material";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import CSelect from "../../app/component/CSelect";
import { ProjectCategoryApproval } from "../../app/config/enum";

const validationSchema = yup.object({
  name: yup.string().required(),
  unitBusinessCategoryId: yup.string().required(),
  category: yup.string().required(),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function UnitBusinessForm({ isOpen, onClose }: { isOpen: boolean; onClose: () => any }) {
  const { addUnitBusiness, selectedUnitBusiness, editUnitBusiness, getUnitBusinessGrid, setSelectedUnitBusiness } = useStore().unitBusinessStore;
  const { getUnitBusinessCategory, unitBusinessCategoryLoading, unitBusinessCategory } = useStore().unitBusinessCategoryStore;

  const initialValues: ValidationSchema = useMemo(() => {
    if (selectedUnitBusiness) {
      return selectedUnitBusiness;
    } else {
      return {
        name: "",
        unitBusinessCategoryId: "",
        category: "",
      };
    }
  }, [selectedUnitBusiness]);

  const onSubmit = async (values: ValidationSchema) => {
    if (selectedUnitBusiness) {
      await editUnitBusiness(selectedUnitBusiness.id, values).then(() => {
        getUnitBusinessGrid();
        onClose();
        setSelectedUnitBusiness(null);
        resetForm();
        setErrors({});
      });
    } else {
      await addUnitBusiness(values).then(() => {
        getUnitBusinessGrid();
        onClose();
        setSelectedUnitBusiness(null);
        resetForm();
        setErrors({});
      });
    }
  };

  const { handleSubmit, resetForm, setErrors, handleChange, values, errors, isValid, isSubmitting, dirty } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    getUnitBusinessCategory();
  }, [getUnitBusinessCategory]);

  return (
    <DrawerForm
      title={`${selectedUnitBusiness ? "Edit" : "Add"} Mata Anggaran`}
      isOpen={isOpen || !!selectedUnitBusiness}
      onClose={() => {
        onClose();
        setSelectedUnitBusiness(null);
      }}
      onSubmit={handleSubmit}
      disableAction={!isValid || !dirty}
      isLoading={isSubmitting}
      isEdit={!!selectedUnitBusiness}
    >
      <TextField label="Name" fullWidth name="name" value={values.name} error={!!errors.name} helperText={errors.name} onChange={handleChange} />
      <CSelect
        name="unitBusinessCategoryId"
        label="Business Category"
        options={unitBusinessCategory.map((i) => ({
          label: i.name,
          value: i.id,
        }))}
        fullWidth
        onChange={handleChange}
        value={values.unitBusinessCategoryId}
        error={!!errors.unitBusinessCategoryId}
        helperText={errors.unitBusinessCategoryId}
        loading={unitBusinessCategoryLoading}
      />
      <CSelect
        name="category"
        label="Project Category"
        options={ProjectCategoryApproval.map((i) => ({
          label: i.text,
          value: i.value,
        }))}
        fullWidth
        onChange={handleChange}
        value={values.category}
        error={!!errors.category}
        helperText={errors.category}
        loading={unitBusinessCategoryLoading}
      />
    </DrawerForm>
  );
}

export default observer(UnitBusinessForm);
