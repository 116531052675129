import { Backdrop, CircularProgress } from "@mui/material";
import { observer } from "mobx-react-lite";

function RkdBackdrop() {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default observer(RkdBackdrop);