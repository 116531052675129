import { Button, Dialog, DialogContent, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import useQuery from "../../../../app/hook/useQuery";
import { history } from "../../../..";
import { ProjectStatus } from "../../../../app/config/enum";
import { useParams } from "react-router-dom";
import Connector from "../../../../signalr-connection";

function RefuseProposalForm({ isOpen, onClose }: { onClose: () => any; isOpen: boolean }) {
  const [data, setData] = useState<string>("");
  const { newMessage } = Connector();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { decisionGateApproveRefuseProposal, reviewerApproveRefuseProposal } = useStore().projectManagementStore;
  const { id } = useParams();
  const query = useQuery();
  const status = query.get("status");

  const onSubmit = async () => {
    if (!id) return;

    setIsLoading(true);

    try {
      if (data.length === 0) {
        setError("Reason must be filled");

        return;
      }

      const formData = new FormData();

      formData.append("status", "REFUSED");
      formData.append(`notes`, data);

      if (status === ProjectStatus.Reviewer) {
        await reviewerApproveRefuseProposal(id, formData);
      } else if (status === ProjectStatus.DecisionGate) {
        await decisionGateApproveRefuseProposal(id, formData);
      }

      handleClose();

      history.push(`/project-monitoring/approval/list`);
      newMessage(new Date().toISOString());
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setData("");
    setError("");
    setIsLoading(false);

    onClose();
  };

  useEffect(() => {
    if (data.length > 0) {
      setError("");
    }
  }, [data]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent sx={{ width: "365px" }}>
        <Typography color="black" fontWeight={700} fontSize="14px">
          Info
        </Typography>
        <Typography mb="16px" color="black" mt="24px">
          Add reason to refuse usulan investasi (optional)
        </Typography>
        <TextField
          multiline
          rows={5}
          value={data}
          fullWidth
          label="Reason"
          onChange={(e) => setData(e.target.value)}
          error={!!error}
          helperText={error}
        />
        <Stack mt={2} justifyContent="flex-end" direction="row" gap={1}>
          <Button onClick={handleClose} color="inherit" variant="outlined">
            Cancel
          </Button>
          <LoadingButton color="error" disabled={!!error || isLoading} loading={isLoading} variant="contained" onClick={onSubmit}>
            Refuse
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default observer(RefuseProposalForm);
