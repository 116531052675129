import { TCostRealization, TCostRealizationBody } from "../models/costRealization";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { DataGridResult } from "../models/materialUI/dataGrid";
import { IFidMilestoneQueryParams } from "../models/fidMilestones";
import { TNewProgressRealization } from "../models/progressRealization";
import { IAccountInfo } from "../models/account";
import { Roles } from "../config/enum";

export class CostRealizationStore {
  costRealization: TCostRealization[] = [];
  costRealizationLoading = false;

  newCostRealization: DataGridResult<TNewProgressRealization[]> = new DataGridResult([], 0);
  newCostRealizationLoading = false;

  financialActualProgress: any[] = [];
  financialActualProgressLoading = false;

  queryParams: IFidMilestoneQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  getCostRealization = async (proposalId: string) => {
    this.costRealizationLoading = true;
    try {
      const res = await agent.FID.getCostRealization(proposalId);
      runInAction(() => (this.costRealization = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.costRealizationLoading = false));
    }
  };

  deleteFinancialActualProgress = async (id: string) => {
    try {
      await agent.FID.deleteFinancialActualProgress(id);
    } catch (e) {
      throw e;
    }
  };

  getNewCostRealization = async (proposalId: string, account: IAccountInfo, projectOwner?: string) => {
    this.newCostRealizationLoading = true;
    let organizationIdHelper = account.displayName === projectOwner || account.displayName === Roles.Superadmin ? "" : account.organizationId;

    try {
      const res = await agent.FID.getNewCostRealization(proposalId, this.queryParams, organizationIdHelper);

      runInAction(() => (this.newCostRealization = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.newCostRealizationLoading = false));
    }
  };

  getFinancialActualProgress = async (subTaskId: string, tasksId: string) => {
    this.financialActualProgressLoading = true;

    try {
      const res = await agent.FID.getFinancialActualProgress(subTaskId, tasksId);

      return res;
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.financialActualProgressLoading = false));
    }
  };

  addCostRealization = async (payload: any) => {
    this.costRealizationLoading = true;
    try {
      await agent.FID.addCostRealization(payload);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.costRealizationLoading = false));
    }
  };

  editCostRealization = async (physicalId: string, payload: any) => {
    this.costRealizationLoading = true;
    try {
      await agent.FID.editCostRealization(physicalId, payload);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.costRealizationLoading = false));
    }
  };
}
