import { observer } from "mobx-react-lite";
import agent from "../../app/api/agent";
import { Button, Box } from "@mui/material";
import { Example } from "../../app/models/example";
import { useState } from "react";
import axios from 'axios';

function TesterError() {
    const [errorMsg, setErrorMsg] = useState("");

    const handleNotFound = async () => {
        axios.get('errortester/not-found/').catch(error => setErrorMsg(JSON.stringify(error, null, 2)));
    }

    const handleBadRequest = async () => {
        axios.get('errortester/bad-request/').catch(error => setErrorMsg(JSON.stringify(error, null, 2)));
    }

    const handleServerError = async () => {
        axios.get('errortester/server-error/').catch(error => setErrorMsg(JSON.stringify(error, null, 2)));
    }

    const handleUnauthorized = async () => {
        axios.get('errortester/unauthorized/').catch(error => setErrorMsg(JSON.stringify(error, null, 2)));
    }

    const handleValidationError = async () => {
        await agent.Example.create(new Example()).catch(error => setErrorMsg(JSON.stringify(error, null, 2)));
    }

    return (
        <>
            <Box>
                <Button color="error" variant="contained" onClick={handleNotFound}>Not Found</Button>
                <Button color="error" variant="contained" onClick={handleBadRequest}>Bad Request</Button>
                <Button color="error" variant="contained" onClick={handleServerError}>Server Error</Button>
                <Button color="error" variant="contained" onClick={handleUnauthorized}>Unauthorized</Button>
                <Button color="error" variant="contained" onClick={handleValidationError}>Validation Error</Button>
            </Box>
            <Box>
                <pre style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all'}}>{errorMsg}</pre>
            </Box>
        </>
    );
}

export default observer(TesterError);