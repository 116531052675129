import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Drawer, IconButton, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import TextEditor from "../../../../app/component/TextEditor";
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import useQuery from "../../../../app/hook/useQuery";
import { FlagEdit } from "../../../../app/config/enum";
import DraggableFileUploadV2, { DraggableFileUploadData } from "../../../../app/component/input/DraggableFileUploadV2";
import CommentList from "./components/CommentList";
import { NumericFormat } from "react-number-format";

const validationSchema = yup.object({
  items: yup.array().of(
    yup.object({
      riskProfilePrepar: yup.string(),
      topRisk: yup.string(),
      mitigation: yup.string(),
      riskRegister: yup.string(),
      costOfRisk: yup.number(),
    })
  ),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function ProposalRiskForm({ isOpen, handleClose, readOnly }: { isOpen: boolean; handleClose: () => any; readOnly: boolean }) {
  const [loadingAttc, setLoadingAttc] = useState(false);
  const [files, setFiles] = useState<DraggableFileUploadData[]>([]);
  const { addRisk, risk, getRisk, editRisk, deleteAttachment } = useStore().proposalStore;
  const query = useQuery();
  const id = query.get("id");

  const initialValues: any = useMemo(() => {
    if (!!risk && risk.length > 0) {
      const res = risk.map((object) => {
        console.log("object", object);

        return {
          id: object.id,
          riskProfilePrepar: object.riskProfilePrepar,
          mitigation: object.mitigation,
          riskRegister: object.riskRegister,
          topRisk: object.topRisk,
          costOfRisk: object.costOfRisk,
        };
      });

      return { items: res };
    } else {
      return {
        items: [
          {
            mitigation: undefined,
            riskProfilePrepar: undefined,
            riskRegister: undefined,
            topRisk: undefined,
            costOfRisk: 0,
          },
        ],
      };
    }
  }, [risk]);

  const onSubmit = async (values: ValidationSchema) => {
    if (readOnly) return;

    const id = query.get("id");

    if (!id) return;

    const arrayOfFileName = files.map((i) => (i.data ? i.data.fileName : i.file.name));
    const isDuplicateFileName = arrayOfFileName.some((item, index) => arrayOfFileName.indexOf(item) !== index);
    if (isDuplicateFileName) {
      return alert("Attachment name is duplicate");
    }

    const formData = new FormData();

    values.items?.forEach((item, index) => {
      item.mitigation && formData.append(`riskAnalyses[${index}].mitigation`, item.mitigation);
      item.riskProfilePrepar && formData.append(`riskAnalyses[${index}].riskProfilePrepar`, item.riskProfilePrepar);
      item.riskRegister && formData.append(`riskAnalyses[${index}].riskRegister`, item.riskRegister);
      item.topRisk && formData.append(`riskAnalyses[${index}].topRisk`, item.topRisk);
      item.costOfRisk && formData.append(`riskAnalyses[${index}].costOfRisk`, String(item.costOfRisk));

      if (risk && risk.length > 0) {
        const i = item as any;
        i.id && formData.append(`riskAnalyses[${index}].id`, i.id);
        formData.append(`riskAnalyses[${index}].flagEdit`, FlagEdit.PROPOSAL.toString());
      }

      files.forEach((file, x) => {
        if (file.data?.id) {
          formData.append(`riskAnalyses[${index}].attachments[${x}].attachment`, file.data.attachment as File);
          formData.append(`riskAnalyses[${index}].attachments[${x}].fileName`, "");
          formData.append(`riskAnalyses[${index}].attachments[${x}].id`, file.data.id);
        } else {
          formData.append(`riskAnalyses[${index}].attachments[${x}].attachment`, file.file as File);
          formData.append(`riskAnalyses[${index}].attachments[${x}].fileName`, "");
        }
      });
    });

    if (risk && risk.length > 0) {
      await editRisk(id, formData as any).then(() => {
        handleClose();
        resetForm();
        setErrors({ items: undefined });
        getRisk(id);
      });
    } else {
      await addRisk(id, formData as any).then(() => {
        handleClose();
        resetForm();
        setErrors({ items: undefined });
        getRisk(id);
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const { handleSubmit, isSubmitting, submitForm, resetForm, setErrors, setFieldValue, values, isValid, dirty } = formik;

  useEffect(() => {
    if (risk) {
      risk.map((item) => {
        setFiles(
          item.attachments.map((attachment) => ({
            file: {
              name: attachment.fileName,
            },
            data: attachment,
          }))
        );
      });
    }
  }, [risk]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Drawer
        anchor="right"
        open={isOpen}
        sx={{
          ".MuiPaper-root": {
            width: "500px",
          },
        }}
      >
        <Box
          sx={{
            m: "16px 16px 0 16px",
            pb: "8px",
            borderBottom: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "space-between",
            mb: "16px",
          }}
        >
          <Typography fontSize={"14px"} fontWeight={700} color="black">
            {!!risk && risk.length > 0 ? "Edit" : "Add"} Risk
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Stack flex={1} overflow="auto" p="16px" spacing="24px">
          {values.items?.map((value, index) => (
            <>
              <Box>
                <TextEditor
                  label="Penyusunan Risk Profile"
                  value={value.riskProfilePrepar}
                  onChange={(e) => setFieldValue(`items[${index}].riskProfilePrepar`, e)}
                />
              </Box>
              <Box>
                <TextEditor label="Top Risk" value={value.topRisk} onChange={(e) => setFieldValue(`items[${index}].topRisk`, e)} />
              </Box>
              <Box>
                <TextEditor label="Mitigation" value={value.mitigation} onChange={(e) => setFieldValue(`items[${index}].mitigation`, e)} />
              </Box>
              <Box>
                <TextEditor label="Risk Register" value={value.riskRegister} onChange={(e) => setFieldValue(`items[${index}].riskRegister`, e)} />
              </Box>
              <Box>
                <NumericFormat
                  customInput={TextField}
                  label="Cost Of Risk (Rp)"
                  fullWidth
                  value={value.costOfRisk}
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                      e.preventDefault();
                    }
                  }}
                  onValueChange={(e) => {
                    setFieldValue(`items[${index}].costOfRisk`, e.floatValue);
                  }}
                  thousandSeparator={true}
                  prefix="Rp "
                />
              </Box>
              <DraggableFileUploadV2
                data={files}
                viewable
                viewableOptions={{ propsoalId: id ?? "" }}
                loading={loadingAttc}
                onDelete={(data) => {
                  if (!id) return;

                  setLoadingAttc(true);

                  deleteAttachment(id, data.id)
                    .then(() => getRisk(id))
                    .finally(() => setLoadingAttc(false));
                }}
                setData={setFiles}
                accept="application/pdf, application/msword, image/*"
              />
            </>
          ))}
          {isOpen && risk ? <CommentList isOpen={isOpen} data={risk} /> : ""}
        </Stack>
        <Box
          sx={{
            m: "0 16px 16px 16px",
            pt: "16px",
            borderTop: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <LoadingButton loading={isSubmitting} disabled={!isValid || readOnly} type="submit" variant="contained" onClick={submitForm}>
            {!!risk && risk.length > 0 ? "Edit" : "Add"}
          </LoadingButton>
        </Box>
      </Drawer>
    </form>
  );
}

export default observer(ProposalRiskForm);
