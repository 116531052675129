import { Box } from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { Add } from "@mui/icons-material";
// import ProjectCategoryDataGrid from "./ProjectCategoryDataGrid";
// import ProjectCategoryForm from "./ProjectCategoryForm";
import { useState } from "react";
import ProjectCategoryForm from "./ProjectCategoryForm";
import ProjectCategoryDataGrid from "./ProjectCategoryDataGrid";

export default function ProjectCategoryPageIndex() {
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false)
  
  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: 'Project Category Non-BD',
            href: '/master/project-category-nbd'
          },
          {
            label: 'Project Category Non-BD List',
            href: '/master/project-category-nbd'
          },
        ]}
        pageTitle="Project Category Non-BD List"
      >
        <DashboardLayoutHeader.Button 
          startIcon={<Add />}
          variant="contained"
          onClick={() => setIsOpenForm(true)}
        >
          Add Project Category Non-BD
        </DashboardLayoutHeader.Button>
      </DashboardLayoutHeader>
      <ProjectCategoryDataGrid />
      <ProjectCategoryForm isOpen={isOpenForm} onClose={() => setIsOpenForm(false)} />
    </Box>
  )
}
