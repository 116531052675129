import { Avatar, Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import useQuery from "../../../../app/hook/useQuery";
import ListIsEmptySvg from "../../../../app/component/svg/ListIsEmptySvg";
import { format } from "date-fns";

function ProposalLogTab () {
  const { log, getLog, logLoading } = useStore().projectManagementStore
  const query = useQuery()

  const id = query.get('id')
  
  const renderEmptyState = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flex={1} flexDirection="column" height="100%">
        <ListIsEmptySvg />
        <Typography color="black" fontWeight={700}>There is nothing here</Typography>
      </Box>
    )
  }

  const renderLogs = () => {
    return log.map((item, index) => (
      <LogItem message={item.message} date={item.date} key={index} />
    ))
  }

  const renderLoading = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" my={5}>
        <CircularProgress />
      </Box>
    )
  }
  
  useEffect(() => {
    if (!id) return;
    
    getLog(id)
  }, [getLog, id])
  
  return (
    <Box>
      <Typography mb="16px" color="black" fontWeight={700} fontSize="20px">Log</Typography>
      <Stack spacing={'16px'}>
        {logLoading 
          ? renderLoading()
          : !!log.length
            ? renderLogs()
            : renderEmptyState()
        }
      </Stack>
    </Box>
  )
}

export default observer(ProposalLogTab)

const LogItem = ({ message, date }: {message: string, date: Date}) => {
  // const renderHiglightedText = (text: string) => (
  //   <Typography component='span' textTransform="capitalize" fontWeight={700} color={"black"}>{text}</Typography>
  // )
  
  return (
    <Box border="1px solid #F8F8F8" display="flex" gap="12px" alignItems="center" padding="16px">
      <Avatar />
      <Stack spacing="4px">
        <Typography color={"black"}>
          {message}
        </Typography>
        <Typography>
          {format(new Date(date), 'dd MMMM yyyy - hh:mm')}
        </Typography>
      </Stack>
    </Box>
  )
}