import { Box } from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { Add } from "@mui/icons-material";
import AuctionStatusDataGrid from "./AuctionStatusDataGrid";
import AuctionStatusForm from "./AuctionStatusForm";
import { useState } from "react";

export default function AuctionStatusPageIndex() {
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);

  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Status Lelang",
            href: "/master/auction-status",
          },
          {
            label: "Status Lelang List",
            href: "/master/auction-status",
          },
        ]}
        pageTitle="Status Lelang List"
      >
        <DashboardLayoutHeader.Button startIcon={<Add />} variant="contained" onClick={() => setIsOpenForm(true)}>
          Add Status Lelang
        </DashboardLayoutHeader.Button>
      </DashboardLayoutHeader>
      <AuctionStatusDataGrid />
      <AuctionStatusForm isOpen={isOpenForm} onClose={() => setIsOpenForm(false)} />
    </Box>
  );
}
