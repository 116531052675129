import { ChevronRight } from "@mui/icons-material";
import { Box, Collapse, ListItemButton, Typography } from "@mui/material";
import { ReactNode, useState } from "react";

interface CollapsableHistoryProps {
  label: string;
  children: ReactNode;
  color?: string;
}

export const CollapsableHistory = ({ label, children, color }: CollapsableHistoryProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box borderRadius="3px" border={1} borderColor={color ?? "rgba(0, 0, 0, .1)"}>
      <ListItemButton onClick={() => setExpanded((prev) => !prev)} sx={{ borderRadius: "3px", justifyContent: "space-between", py: 2 }}>
        <Box>
          <Typography fontWeight={700} color={color ?? "black"}>
            {label}
          </Typography>
          <Typography fontSize="small">Click to see detail</Typography>
        </Box>
        <ChevronRight sx={{ transform: expanded ? "rotate(270deg)" : "rotate(90deg)" }} />
      </ListItemButton>
      <Collapse in={expanded}>{children}</Collapse>
    </Box>
  );
};
