import { AttachMoneyRounded, BallotOutlined, Close, Edit, Visibility, VisibilityOutlined } from "@mui/icons-material";
import { Box, Checkbox, Drawer, FormControlLabel, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useStore } from "../../../../app/stores/store";
import { FormikProps, FormikValues, useFormik } from "formik";
import * as yup from "yup";
import useQuery from "../../../../app/hook/useQuery";
import { LoadingButton } from "@mui/lab";
import { isValid } from "date-fns";
import { Dispatch, Fragment, ReactElement, SetStateAction, useEffect, useMemo, useState } from "react";
import { FlagEdit } from "../../../../app/config/enum";
import DraggableFileUploadV2, { DraggableFileUploadData } from "../../../../app/component/input/DraggableFileUploadV2";
import CommentList from "./components/CommentList";
import { NumericFormat } from "react-number-format";
import MilestonesTab from "../tabs/fid/milestone-tab";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  readOnly: boolean;
};

const validationSchema = yup.object({
  softCost: yup.number().typeError("Soft Cost must be number").required("Soft Cost must be filled"),
  hardCost: yup.number().typeError("Hard Cost must be number").required("Hard Cost must be filled"),
  otherCost: yup.number().typeError("Other Cost must be number").required("Other Cost must be filled"),
  contingencies: yup.number().typeError("Contingencies must be number"),
  idc: yup.number().typeError("IDC must be number").required("IDC must be filled"),
  id: yup.string(),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

export default function ProposalCostplanAnalysisForm({ isOpen, handleClose, readOnly }: Props) {
  const [files, setFiles] = useState<DraggableFileUploadData[]>([]);
  const { addCostplanAnalysis, getCostplanAnalysis, costplanAnalysis, editCostplanAnalysis } = useStore().proposalStore;
  const query = useQuery();
  const [detailOpen, setDetailOpen] = useState(false);
  const [capexDrawer, setCapexDrawer] = useState(false);

  const id = query.get("id");

  const initialValues: ValidationSchema = useMemo(() => {
    if (costplanAnalysis && costplanAnalysis.length > 0) {
      return {
        ...costplanAnalysis[0],
        otherCost: costplanAnalysis[0].other,
      };
    } else {
      return {
        softCost: 0,
        hardCost: 0,
        otherCost: 0,
        contingencies: undefined,
        idc: 0,
        id: undefined,
      };
    }
  }, [costplanAnalysis]);

  const onSubmit = async (values: ValidationSchema) => {
    if (readOnly) return;

    const id = query.get("id");

    if (!id) return;

    const formData = new FormData();

    values.softCost && formData.append(`costplanAnalyses[0].softCost`, values.softCost.toString());
    values.hardCost && formData.append(`costplanAnalyses[0].hardCost`, values.hardCost.toString());
    values.otherCost && formData.append(`costplanAnalyses[0].other`, values.otherCost.toString());
    values.contingencies && formData.append(`costplanAnalyses[0].contingencies`, values.contingencies.toString());
    values.idc && formData.append(`costplanAnalyses[0].idc`, values.idc.toString());

    if (costplanAnalysis && costplanAnalysis.length > 0) {
      values.id && formData.append(`costplanAnalyses[0].id`, values.id);
      formData.append(`costplanAnalyses[0].flagEdit`, FlagEdit.PROPOSAL.toString());
    }
    files.forEach((file, x) => {
      if (file.data?.id) {
        formData.append(`costplanAnalyses[0].attachments[${x}].fileName`, "");
        formData.append(`costplanAnalyses[0].attachments[${x}].id`, file.data.id);
      } else {
        formData.append(`costplanAnalyses[0].attachments[${x}].attachment`, file.file as File);
        formData.append(`costplanAnalyses[0].attachments[${x}].fileName`, "");
      }
    });

    if (costplanAnalysis && costplanAnalysis.length > 0) {
      await editCostplanAnalysis(id, formData as any).then(() => {
        setCapexDrawer(true);
        resetForm();
        setErrors({});
        getCostplanAnalysis(id);
      });
    } else {
      await addCostplanAnalysis(id, formData as any).then(() => {
        setCapexDrawer(true);
        resetForm();
        setErrors({});
        getCostplanAnalysis(id);
      });
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });
  const { setErrors, resetForm, isSubmitting, submitForm } = formik;

  useEffect(() => {
    if (costplanAnalysis) {
      costplanAnalysis.map((item) => {
        setFiles(
          item.attachments.map((attachment) => ({
            file: {
              name: attachment.fileName,
            },
            data: attachment,
          }))
        );
      });
    }
  }, [costplanAnalysis]);

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        sx={{
          ".MuiPaper-root": {
            width: "500px",
          },
        }}
      >
        <Box
          sx={{
            m: "16px 16px 0 16px",
            pb: "8px",
            borderBottom: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "space-between",
            mb: "16px",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"14px"} fontWeight={700} color="black">
            {costplanAnalysis && costplanAnalysis.length > 0 ? "Analisis Costplan" : "Add Analisis Costplan (Sudah termasuk pajak)"}
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Stack component="form" flex={1} overflow="auto" p="16px" spacing="24px">
          {!!costplanAnalysis?.length ? (
            <>
              <AddedItem onView={() => setDetailOpen(true)} icon={<AttachMoneyRounded />} name="Detail Analisis Costplan" />
              <AddedItem onView={() => setCapexDrawer(true)} icon={<BallotOutlined />} name="CAPEX" />
              {isOpen && costplanAnalysis ? <CommentList isOpen={isOpen} data={costplanAnalysis} /> : ""}
            </>
          ) : (
            <FieldForm files={files} setFiles={setFiles} formik={formik} readOnly={readOnly} />
          )}
          {isOpen && costplanAnalysis ? <CommentList isOpen={isOpen} data={costplanAnalysis} /> : ""}
        </Stack>
        {Array.isArray(costplanAnalysis) && !costplanAnalysis.length && (
          <Box
            sx={{
              m: "0 16px 16px 16px",
              pt: "16px",
              borderTop: "1px solid #EAEAEA",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <LoadingButton disabled={!isValid || readOnly} onClick={submitForm} variant="contained" loading={isSubmitting}>
              Add
            </LoadingButton>
          </Box>
        )}
      </Drawer>
      <DetailCostplanAnalysis open={detailOpen} setOpen={setDetailOpen} files={files} setFiles={setFiles} formik={formik} readOnly={readOnly} />
      <CapexDrawer open={capexDrawer} setOpen={setCapexDrawer} />
    </>
  );
}

const AddedItem = ({ name, onView, icon }: { name: string; icon: ReactElement; onView: () => any }) => {
  return (
    <Box
      sx={{
        border: ({ palette }) => `1px solid ${palette.divider}`,
        padding: "16px",
        borderRadius: "4px",
        fontWeight: 700,
        display: "flex",
      }}
    >
      <Stack flex={1} direction="row" alignItems="center" spacing={"16px"}>
        {icon}
        <Stack>
          <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: "350px" }}>
            <Typography noWrap color="black" fontWeight={700} mb={"4px"}>
              {name}
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <IconButton onClick={onView} color="inherit">
        <VisibilityOutlined />
      </IconButton>
    </Box>
  );
};

type FieldFormProps = {
  formik: FormikProps<ValidationSchema>;
  readOnly: boolean;
  setFiles: Dispatch<SetStateAction<DraggableFileUploadData[]>>;
  files: DraggableFileUploadData[];
};

const FieldForm = ({ formik, readOnly, files, setFiles }: FieldFormProps) => {
  const [loadingAttc, setLoadingAttc] = useState(false);
  const { values, errors, setFieldValue } = formik;
  const { getCostplanAnalysis, deleteAttachment } = useStore().proposalStore;
  const query = useQuery();

  const id = query.get("id");

  return (
    <Stack flex={1} spacing="24px">
      <NumericFormat
        customInput={TextField}
        label="Soft Cost"
        fullWidth
        value={values.softCost}
        error={!!errors.softCost}
        helperText={errors.softCost}
        thousandSeparator={true}
        prefix={"Rp "}
        onValueChange={(e) => {
          setFieldValue(`softCost`, e.floatValue);
        }}
        name={`softCost`}
        disabled={readOnly}
      />
      <NumericFormat
        customInput={TextField}
        label="Hard Cost"
        fullWidth
        value={values.hardCost}
        error={!!errors?.hardCost}
        helperText={errors?.hardCost}
        thousandSeparator={true}
        prefix={"Rp "}
        onValueChange={(e) => {
          setFieldValue(`hardCost`, e.floatValue);
        }}
        name={`hardCost`}
        disabled={readOnly}
      />
      <NumericFormat
        customInput={TextField}
        label="Contingencies"
        fullWidth
        value={values.contingencies}
        error={!!errors.contingencies}
        helperText={errors.contingencies}
        thousandSeparator={true}
        prefix={"Rp "}
        onValueChange={(e) => {
          setFieldValue(`contingencies`, e.floatValue);
        }}
        name={`contingencies`}
        disabled={readOnly}
      />
      <NumericFormat
        customInput={TextField}
        label="Interest During Construction (IDC)"
        fullWidth
        value={values.idc}
        error={!!errors.idc}
        helperText={errors.idc}
        thousandSeparator={true}
        prefix={"Rp "}
        onValueChange={(e) => {
          setFieldValue(`idc`, e.floatValue);
        }}
        name={`idc`}
        disabled={readOnly}
      />
      <NumericFormat
        customInput={TextField}
        label="Other Cost"
        fullWidth
        value={values.otherCost}
        error={!!errors.otherCost}
        helperText={errors.otherCost}
        thousandSeparator={true}
        prefix={"Rp "}
        onValueChange={(e) => {
          setFieldValue(`otherCost`, e.floatValue);
        }}
        name={`otherCost`}
        disabled={readOnly}
      />
      <DraggableFileUploadV2
        data={files}
        setData={setFiles}
        viewable
        viewableOptions={{ propsoalId: id ?? "" }}
        loading={loadingAttc}
        accept="application/pdf,image/*,application/msword"
        onDelete={(data) => {
          if (!id) return;

          setLoadingAttc(true);

          deleteAttachment(id, data.id)
            .then(() => getCostplanAnalysis(id))
            .finally(() => setLoadingAttc(false));
        }}
      />
    </Stack>
  );
};

const DetailCostplanAnalysis = ({
  open,
  setOpen,
  readOnly,
  ...props
}: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>>; readOnly: boolean } & FieldFormProps) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      sx={{
        ".MuiPaper-root": {
          width: "500px",
        },
      }}
    >
      <Box
        sx={{
          m: "16px 16px 0 16px",
          pb: "8px",
          borderBottom: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "space-between",
          mb: "16px",
          alignItems: "center",
        }}
      >
        <Typography fontSize={"14px"} fontWeight={700} color="black">
          Detail Analisis Costplan (Sudah termasuk pajak)
        </Typography>
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </Box>
      <Box flex={1} p={2} overflow="auto">
        <FieldForm readOnly={readOnly} {...props} />
      </Box>
      <Box
        sx={{
          m: "0 16px 16px 16px",
          pt: "16px",
          borderTop: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <LoadingButton
          disabled={!props.formik.isValid || readOnly}
          onClick={props.formik.submitForm}
          variant="contained"
          loading={props.formik.isSubmitting}
        >
          Edit
        </LoadingButton>
      </Box>
    </Drawer>
  );
};

const CapexDrawer = ({ open, setOpen }: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      sx={{
        ".MuiPaper-root": {
          width: "75%",
        },
      }}
    >
      <Box
        sx={{
          m: "16px 16px 0 16px",
          pb: "8px",
          borderBottom: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "space-between",
          mb: "16px",
          alignItems: "center",
        }}
      >
        <Typography fontSize={"14px"} fontWeight={700} color="black">
          CAPEX
        </Typography>
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </Box>
      <Box flex={1} p={2} overflow="auto">
        <MilestonesTab isCapex />
      </Box>
      {/* <Box
        sx={{
          m: '0 16px 16px 16px',
          pt: '16px',
          borderTop: '1px solid #EAEAEA',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <LoadingButton 
          disabled={!props.formik.isValid || readOnly}
          onClick={props.formik.submitForm}
          variant="contained"
          loading={props.formik.isSubmitting}
        >
          Edit
        </LoadingButton>
      </Box> */}
    </Drawer>
  );
};
