import { createContext, useContext } from "react";
import AccountStore from "./accountStore";
import CommonStore from "./commonStore";
import DialogStore from "./helper/dialogStore";
import ExampleStore from "./exampleStore";
import ModalStore from "./helper/modalStore";
import SnackbarStore from "./helper/snackbarStore";
import UserStore from "./userStore";
import LandStore from "./landStore";
import ChangeRequestStore from "./changeRequestStore";
import { ProposalStore } from "./proposalStore";
import { ProjectManagementStore } from "./projectManagementStore";
import { GeneralStore } from "./generalStore";
import { ProjectTypeStore } from "./projectTypeStore";
import { RegionalStore } from "./regionalStore";
import { SubHoldingStore } from "./subHoldingStore";
import { ProjectCategoryStore } from "./projectCategoryStore";
import { UnderlyingStore } from "./underlyingStore";
import { AssetCategoryStore } from "./assetCategoryStore";
import { InventoryCategoryStore } from "./inventoryCategoryStore";
import { OwnershipCategoryStore } from "./ownershipCategoryStore";
import { UnitBusinessStore } from "./unitBusinessStore";
import { ProposalMemberStore } from "./proposalMemberStore";
import { MilestoneStore } from "./milestoneStore";
import { ProgressRealizationStore } from "./progressRealizationStore";
import { OrganizationStore } from "./organizationStore";
import { ProposalReviewerStore } from "./proposalReviewerStore";
import { ApprovalStore } from "./approvalStore";
import { SettingApprovalStore } from "./settingApprovalStore";
import { CostRealizationStore } from "./costRealizationStore";
import { ReviewerLog } from "./reviewerLog";
import { PeruntukanStore } from "./peruntukanStore";
import { stepBarPercentageStore } from "./stepBarPercentageStore";
import { UnitBusinessCategoryStore } from "./unitBusinessCategoryStore";
import { ReFidStore } from "./reFidStore";
import { ConsolidationStore } from "./consolidation";
import { DashboardStore } from "./dashboardStore";
import { ProjectCategoryNBDStore } from "./projectCategoryNBDStore";
import { AmandementStore } from "./amandement";
import { AuctionStatusStore } from "./auctionStatusStore";
import { ProjectCategoryAllStore } from "./projectCategoryAllStore";
import { GeneralLogStore } from "./generalLog";
import AbiStore from "./abiStore";
import { UploadPlanProjectStore } from "./uploadPlanProject";

export const store = {
  exampleStore: new ExampleStore(),
  commonStore: new CommonStore(),
  accountStore: new AccountStore(),
  userStore: new UserStore(),
  organizationStore: new OrganizationStore(),
  settingApprovalStore: new SettingApprovalStore(),
  consolidationStore: new ConsolidationStore(),
  snackbarStore: new SnackbarStore(),
  modalStore: new ModalStore(),
  dialogStore: new DialogStore(),
  landStore: new LandStore(),
  changeRequestStore: new ChangeRequestStore(),
  proposalStore: new ProposalStore(),
  projectManagementStore: new ProjectManagementStore(),
  generalStore: new GeneralStore(),
  projectTypeStore: new ProjectTypeStore(),
  regionalStore: new RegionalStore(),
  subHoldingStore: new SubHoldingStore(),
  projectCategoryStore: new ProjectCategoryStore(),
  underlyingStore: new UnderlyingStore(),
  assetCategoryStore: new AssetCategoryStore(),
  inventoryCategoryStore: new InventoryCategoryStore(),
  ownershipCategoryStore: new OwnershipCategoryStore(),
  auctionStatusStore: new AuctionStatusStore(),
  unitBusinessStore: new UnitBusinessStore(),
  peruntukanStore: new PeruntukanStore(),
  proposalMemberStore: new ProposalMemberStore(),
  milestonetore: new MilestoneStore(),
  generalLogStore: new GeneralLogStore(),
  amandementStore: new AmandementStore(),
  progressRealizationStore: new ProgressRealizationStore(),
  reviewerLogStore: new ReviewerLog(),
  costRealizationStore: new CostRealizationStore(),
  proposalReviewerStore: new ProposalReviewerStore(),
  approvalStore: new ApprovalStore(),
  stepBarPercentageStore: new stepBarPercentageStore(),
  unitBusinessCategoryStore: new UnitBusinessCategoryStore(),
  reFidStore: new ReFidStore(),
  DashboardStore: new DashboardStore(),
  projectCategoryAllStore: new ProjectCategoryAllStore(),
  projectCategoryNBDStore: new ProjectCategoryNBDStore(),
  abiStore: new AbiStore(),
  uploadPlanProjectStore: new UploadPlanProjectStore()
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
