import { Add, EditOutlined } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { ReactElement, cloneElement } from "react";

export const FillFormItem = ({
  icon,
  text,
  added,
  disabled,
  isCommented,
}: {
  icon: ReactElement;
  text: string;
  added?: boolean;
  disabled?: boolean;
  isCommented?: boolean;
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      border={added ? "none" : `1px solid #DEDEDE`}
      bgcolor={disabled ? "#F5F5F5" : isCommented ? "#fce4ec" : added ? "#F2F9EC" : "white"}
      borderRadius="4px"
      padding="16px"
      gap="12px"
      sx={{
        cursor: disabled ? "not-allowed" : "pointer",
        opacity: disabled ? 0.7 : 1,
      }}
    >
      {cloneElement(icon, {
        fontSize: "large",
        sx: { color: isCommented ? "#f48fb1" : added ? "#7CC443" : "#999999" },
      })}
      <Stack flex={1}>
        <Typography fontWeight={700} color="black">
          {text}
        </Typography>
        {added ? <Typography color="black">Data added</Typography> : <Typography>Data is empty, add now</Typography>}
      </Stack>
      {added ? <EditOutlined sx={{ color: "black" }} /> : <Add sx={{ color: "black" }} />}
    </Box>
  );
};
