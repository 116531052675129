import { Box, IconButton, ListItem } from "@mui/material";
import { ExpandLess, ExpandMore, Edit, Delete } from "@mui/icons-material";
import { TreeItem, TreeView } from "@mui/lab";
import { observer } from "mobx-react-lite";

type TRenderTree = {
  id: string;
  name: string;
  level: number | string;
  child?: readonly TRenderTree[];
};

type TOrganizationListData = {
  list: Array<any>;
  onDelete: (value: string) => void;
  onEdit: (value: any) => void;
};

function OrganizationListData(props: TOrganizationListData) {
  const { list, onDelete, onEdit } = props;
  const deleteOrganization = (id: string) => onDelete(id);
  const editOrganization = (value: any) => onEdit(value);

  const renderTree = (nodes: TRenderTree) => (
    <TreeItem sx={{ width: "100%", fontSize: "24px" }} key={nodes.id} nodeId={nodes.id} label={nodes.name}>
      {Array.isArray(nodes.child)
        ? nodes.child.map((node: any) => (
            <ListItem divider sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
              <Box sx={{ width: "100%" }}>{renderTree(node)}</Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                <IconButton size="small" onClick={() => editOrganization(node)}>
                  <Edit sx={{ fontSize: "18px" }} />
                </IconButton>
                <IconButton size="small" onClick={() => deleteOrganization(node.id)}>
                  <Delete sx={{ fontSize: "14px" }} />
                </IconButton>
              </Box>
            </ListItem>
          ))
        : null}
    </TreeItem>
  );

  return (
    <div>
      <TreeView
        aria-label="rich object"
        defaultCollapseIcon={<ExpandMore />}
        defaultExpanded={["root"]}
        defaultExpandIcon={<ExpandLess />}
        sx={{ width: "100%" }}
      >
        <>
          {list.map((item) => {
            return (
              <ListItem sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start" }} divider>
                {renderTree(item)}

                {item.name === "N/A" ? (
                  ""
                ) : (
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                    <IconButton size="small" onClick={() => editOrganization(item)}>
                      <Edit sx={{ fontSize: "18px" }} />
                    </IconButton>
                    <IconButton size="small" onClick={() => deleteOrganization(item.id)}>
                      <Delete sx={{ fontSize: "14px" }} />
                    </IconButton>
                  </Box>
                )}
              </ListItem>
            );
          })}
        </>
      </TreeView>
    </div>
  );
}

export default observer(OrganizationListData);
