import { Chip, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { IFidMilestone } from "../../../../../../app/models/fidMilestones";
import { format } from "date-fns";
import { AddCircleOutline, EditOutlined, MonetizationOnOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Dispatch, SetStateAction } from "react";
import useQuery from "../../../../../../app/hook/useQuery";
import { ProjectStatus, TaskSubTask, TaskSubTaskStatus } from "../../../../../../app/config/enum";

interface SubTaskTableProps {
  subTask: IFidMilestone["tasks"][0]["subTasks"];
  setDrawerTypePembayaran: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
  setSubtaskToEdit: Dispatch<SetStateAction<IFidMilestone["tasks"][0]["subTasks"][0] | undefined>>;
  setDialogTypePembayaran: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
  setDialogTypeProgress: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
  setFormType: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
}

const statusDisabledButtonSubTask = [
  TaskSubTaskStatus.PemenangLelang,
  TaskSubTaskStatus.Construction,
  TaskSubTaskStatus.Pembayaran,
  TaskSubTask.Completed,
];

export const SubTaskTable = ({
  setDialogTypeProgress,
  setDrawerTypePembayaran,
  setDialogTypePembayaran,
  subTask,
  setSubtaskToEdit,
  setFormType,
}: SubTaskTableProps) => {
  const query = useQuery();
  const status = query.get("status");

  return (
    <Table>
      <TableHead>
        <TableCell sx={{ fontWeight: 700 }}>Subtask</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Start Date</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>End Date</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Percentage to CAPEX</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Nominal Pagu</TableCell>
        <TableCell />
      </TableHead>
      <TableBody>
        {subTask.map((i) => (
          <TableRow key={i.id}>
            <TableCell>{i.name}</TableCell>
            <TableCell>
              <Chip color="primary" variant="filled" label={i.status} />
            </TableCell>
            <TableCell>{format(new Date(i.startDate), "MMMM yyyy")}</TableCell>
            <TableCell>{format(new Date(i.endDate), "MMMM yyyy")}</TableCell>
            <TableCell>{i.weightPhysical}%</TableCell>
            <TableCell>Rp {i.weightFinancial.toLocaleString()}</TableCell>
            <TableCell>
              <>
                <IconButton
                  color="inherit"
                  onClick={() => {
                    setDialogTypePembayaran("subtask");
                    setSubtaskToEdit(i);
                  }}
                >
                  <MonetizationOnOutlined />
                </IconButton>
                <IconButton
                  color="inherit"
                  onClick={() => {
                    setDialogTypeProgress("subtask");
                    setSubtaskToEdit(i);
                  }}
                >
                  <AddCircleOutline />
                </IconButton>
                <IconButton
                  color="inherit"
                  onClick={() => {
                    setDrawerTypePembayaran("subtask");
                    setSubtaskToEdit(i);
                  }}
                >
                  <VisibilityOutlined />
                </IconButton>
              </>
              <IconButton
                onClick={() => {
                  setSubtaskToEdit(i);
                  setFormType("subtask");
                }}
                color="inherit"
              >
                <EditOutlined />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
