import { useMemo } from "react";
import { Box, Button, Dialog, DialogContent, Stack, TextField, Typography } from "@mui/material";
import { TFidMilestoneSubtask, TFidMilestoneTask } from "../../../../../../app/models/fidMilestones";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Close, AttachMoney } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { NumericFormat } from "react-number-format";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { useStore } from "../../../../../../app/stores/store";
import * as yup from "yup";

const validationSchema = yup.object({
  taskId: yup.string().required(),
  subtaskId: yup.string().nullable(),
  weightFinancialPlan: yup.number().required().min(1, "Perencanaan Pembayaran must be greather than 0"),
  date: yup.date().required(),
  description: yup.string(),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

type TAddPerencanaanPembayaran = {
  dialogTypePembayaran: "task" | "subtask" | undefined;
  onClose: () => any;
  isOpenPerencanaanPembayaran: boolean;
  task: TFidMilestoneTask | undefined;
  subtask: TFidMilestoneSubtask | undefined;
};

export default function AddPerencanaanPembayaran({
  subtask,
  onClose,
  dialogTypePembayaran,
  task,
  isOpenPerencanaanPembayaran,
}: TAddPerencanaanPembayaran) {
  const { editFinancialPlan, addFinancialPlan, selectedFinancialPlan, setSelectedFinancialPlan, getFinancialPlan } = useStore().milestonetore;

  const initialValues: ValidationSchema = useMemo(() => {
    if (selectedFinancialPlan) {
      return {
        ...selectedFinancialPlan,
        taskId: dialogTypePembayaran === "subtask" ? subtask?.tasksId ?? "" : task?.id ?? "",
        subtaskId: dialogTypePembayaran === "subtask" ? subtask?.id : null,
      };
    } else {
      return {
        taskId: dialogTypePembayaran === "subtask" ? subtask?.tasksId ?? "" : task?.id ?? "",
        subtaskId: dialogTypePembayaran === "subtask" ? subtask?.id : null,
        date: new Date(),
        description: "",
        weightFinancialPlan: 0,
      };
    }
  }, [dialogTypePembayaran, selectedFinancialPlan, subtask, task]);

  const successCallback = () => {
    onClose();
    resetForm();
    setSelectedFinancialPlan(null);
    setErrors({
      weightFinancialPlan: undefined,
    });
  };

  const onSubmit = async (values: ValidationSchema) => {
    if (selectedFinancialPlan) {
      await editFinancialPlan(selectedFinancialPlan.id, values).then(() => {
        getFinancialPlan(
          dialogTypePembayaran === "subtask" ? subtask?.tasksId ?? "" : task?.id ?? "",
          dialogTypePembayaran === "subtask" ? subtask?.id ?? "" : ""
        );
        successCallback();
      });
    } else {
      await addFinancialPlan(values).then(() => {
        successCallback();
      });
    }
  };

  const { handleSubmit, resetForm, setErrors, setFieldValue, handleChange, values, errors, isValid, isSubmitting, dirty } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Dialog
        open={isOpenPerencanaanPembayaran}
        onClose={() => {
          onClose();
          resetForm();
        }}
      >
        <DialogContent sx={{ padding: "16px", width: "365px" }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight="700" fontSize="14px" color="black">
              {`${selectedFinancialPlan ? "Edit" : "Add"} Perencanaan Pembayaran`}
            </Typography>
            <Close
              sx={{ cursor: "pointer" }}
              onClick={() => {
                onClose();
                resetForm();
              }}
            />
          </Stack>
          <Box mt={"16px"}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date"
                value={values.date}
                views={["month", "year"]}
                onChange={(e: any) => setFieldValue("date", e)}
                renderInput={(props) => (
                  <TextField required fullWidth name="date" error={!!errors.date} helperText={errors.date && String(errors.date)} {...props} />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box mt="24px">
            <NumericFormat
              customInput={TextField}
              label="Perencanaan Pembayaran"
              name="weightFinancialPlan"
              required
              thousandSeparator={true}
              fullWidth
              value={values.weightFinancialPlan}
              error={!!errors.weightFinancialPlan}
              helperText={errors.weightFinancialPlan}
              onValueChange={(e) => {
                setFieldValue("weightFinancialPlan", e.floatValue);
              }}
              prefix={"Rp"}
              InputProps={{
                endAdornment: <AttachMoney sx={{ color: "#757575" }} />,
              }}
            />
          </Box>
          <Box mt="24px">
            <TextField
              sx={{ mb: "12px" }}
              label="Description"
              name="description"
              multiline
              fullWidth
              rows={3}
              error={!!errors.description}
              value={values.description}
              onChange={handleChange}
            />
          </Box>
          <Stack direction="row" justifyContent="flex-end" gap="12px" mt={"24px"}>
            <Button
              onClick={() => {
                onClose();
                resetForm();
              }}
              type="button"
              variant="outlined"
              color="inherit"
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={!isValid || !dirty}
              onClick={() => handleSubmit()}
              type="submit"
              loading={isSubmitting}
              variant="contained"
              color="primary"
            >
              {`${selectedFinancialPlan ? "Update" : "Add"}`}
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </form>
  );
}
