import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { IApproval } from "../models/approval";
import { DataGridResult } from "../models/materialUI/dataGrid";

export class ApprovalStore {
  approval: DataGridResult<IApproval[]> = new DataGridResult([], 0)
  approvalLoading = false
  queryparams = {
    currentPage: 0,
    pageSize: 25,
    search: ''
  }
  
  constructor() {
    makeAutoObservable(this)

    reaction(() => this.queryparams, () => {
      this.getApproval()
    })
  }

  getApproval = async () => {
    this.approvalLoading = true
    try {
      const res = await agent.ProjectManagement.getApprovalGrid(this.queryparams)
      
      runInAction(() => this.approval = res);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => this.approvalLoading = false)
    }
  }

  setQueryParams = (query: 'pageSize' | 'search' | 'currentPage', v: string | number) => {
    this.queryparams = {
      ...this.queryparams,
      [query]: v,
    }
  }
}