import { Fragment } from "react";
import { TextField, Box, Drawer, Typography, IconButton } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import { Stack } from "@mui/system";

type TConstructorDrawerProps = {
  data: any;
  handleClose: () => any;
  isOpen: boolean;
};

const ContructorDrawer = ({ data, isOpen, handleClose }: TConstructorDrawerProps) => {
  const closeForm = () => {
    handleClose();
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeForm}
      sx={{
        ".MuiPaper-root": {
          width: "500px",
        },
      }}
    >
      <Box
        sx={{
          m: "16px 16px 0 16px",
          pb: "8px",
          borderBottom: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "space-between",
          mb: "16px",
        }}
      >
        <Typography fontSize={"14px"} fontWeight={700} color="black">
          Vendor Info
        </Typography>
        <IconButton onClick={closeForm}>
          <Close />
        </IconButton>
      </Box>
      <Stack flex={1} overflow="auto" p="16px" spacing="24px">
        <Fragment>
          <TextField label="Vendor Name" value={data?.contractorName} disabled />
          <TextField label="Lingkup Pekerjaan" value={data?.contractorScope} disabled />
          <Stack direction="row" spacing={"24px"}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled
                label="Tanggal Mulai (bekerja)"
                value={data?.startDate}
                onChange={() => null}
                renderInput={(props) => <TextField required fullWidth {...props} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disabled
                label="Tanggal Selesai (bekerja)"
                value={data?.endDate}
                onChange={() => null}
                renderInput={(props) => <TextField required fullWidth {...props} />}
              />
            </LocalizationProvider>
          </Stack>
          <TextField label="Nama PIC" value={data?.picName} disabled />
          <TextField label="Jabaran PIC" value={data?.picPosition} disabled />
          <TextField label="Email PIC" value={data?.picEmail} disabled />
          <TextField label="Nomor Handphone PIC" value={data?.picPhone} disabled />
          <TextField label="Komitmen TKDN (%)" value={data?.tkdn} type="number" disabled />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled
              label="Komitmen TKDN Selesai Kontrak"
              value={data?.tkdnEndDate}
              onChange={() => null}
              renderInput={(props) => <TextField required fullWidth {...props} />}
            />
          </LocalizationProvider>
        </Fragment>
      </Stack>
      <Box
        sx={{
          m: "0 16px 16px 16px",
          pt: "16px",
          borderTop: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <LoadingButton
          type="button"
          onClick={() => {
            handleClose();
          }}
          variant="contained"
          disabled
        >
          Add
        </LoadingButton>
      </Box>
    </Drawer>
  );
};

export default ContructorDrawer;
