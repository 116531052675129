import { useEffect, useMemo, useState } from "react";
import { Button, Chip, Grid, MenuItem, Paper, Select, TextField, Box, FormControl, InputLabel } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { StyledDataGrid } from "../../app/component/StyledDataGrid";
import { CHANGE_REQUEST_STATUS } from "../../app/config/enum";
import { format } from "date-fns";
import { Link } from "react-router-dom";

function ChangeRequestIndex() {
  const { changeRequestStore } = useStore();
  const {
    search,
    changeRequest,
    status,
    currentPage,
    pageSize,
    rowCount,
    loadingList,
    getChangeRequest,
    resetChangeRequest,
    setCurrentPage,
    setPageSize,
  } = changeRequestStore;
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      changeRequestStore.setSearch(searchTerm);
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [changeRequestStore, searchTerm]);

  const columns = useMemo(
    () => [
      {
        field: "dateSubmitted",
        headerName: "Date",
        flex: 1,
        renderCell: (row: any) => format(new Date(row.value), "dd MMM yyyy - hh.mm"),
      },
      { field: "requesterName", headerName: "User", flex: 1, editable: false },
      // {
      //   field: "",
      //   headerName: "Description",
      //   flex: 2,
      //   editable: false,
      //   renderCell: (value: any) => {
      //     const { row } = value;
      //     const convertedRow = JSON.parse(JSON.stringify(row));

      //     return (
      //       <Box>
      //         {convertedRow.requesterName} request to <span style={{ fontWeight: "bold" }}>Edit</span> data
      //       </Box>
      //     );
      //   },
      // },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        editable: false,
        renderCell: (row: any) => <Chip component={"div"} label={row.value} color="primary" />,
      },
      {
        field: "action",
        headerName: "",
        flex: 0.5,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (row: any) => {
          return (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
              <Link to={`/change-request/${row.id}`}>
                <Button variant="contained">See Detail</Button>
              </Link>
            </Box>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    getChangeRequest();

    return () => {
      resetChangeRequest();
    };
  }, [changeRequestStore, search, status, currentPage, pageSize, getChangeRequest, resetChangeRequest]);

  return (
    <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Change Request",
            href: "/change-request",
          },
          {
            label: "Change Request List",
          },
        ]}
        pageTitle="Change Request"
      />
      <Paper sx={{ p: "16px" }}>
        <Grid sx={{ mb: 3 }} container spacing={2}>
          <Grid item xs={9}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select value={changeRequestStore.status} label="Status" onChange={(e) => changeRequestStore.setStatus(e.target.value)}>
                <MenuItem value={"all"}>All</MenuItem>
                {CHANGE_REQUEST_STATUS.map((item: any, index) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <StyledDataGrid
          rows={changeRequest}
          columns={columns}
          pageSize={pageSize}
          getRowId={(row) => row.id}
          rowsPerPageOptions={[5, 10, 20]}
          loading={loadingList}
          rowCount={rowCount}
          page={currentPage}
          paginationMode="server"
          disableSelectionOnClick
          autoHeight
          onPageChange={(newPage) => setCurrentPage(newPage)}
          onPageSizeChange={(pageSize) => setPageSize(pageSize)}
          getRowClassName={(params) => (params.row.isDeleted ? "StyledDataGrid-IsDeleted-Row" : "")}
        />
      </Paper>
    </Box>
  );
}

export default observer(ChangeRequestIndex);
