import { IconButton, Paper, Stack } from "@mui/material";
import { StyledDataGrid } from "../../app/component/StyledDataGrid";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Delete, Edit } from "@mui/icons-material";

function RegionalDataGrid() {
  const { regionalStore, dialogStore } = useStore()
  const { getRegional, regioanlLoading, setSelectedRegional, deleteRegional, regional } = regionalStore
  
  const onDelete = (id: string) => dialogStore.open({
    action: async () => await deleteRegional(id).then(getRegional),
    actionText: 'Delete',
    title: 'Delete',
    closeText: 'Cancel',
    actionButtonProps: {
      color: 'error'
    },
    description: 'Are you sure want to delete this item?'
  })
  
  useEffect(() => {
    getRegional()
  }, [getRegional])
  
  return (
    <Paper sx={{p: '16px'}}>
      <StyledDataGrid 
        // checkboxSelection 
        loading={regioanlLoading}
        rows={regional}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            flex: 1
          },
          {
            field: 'action',
            headerName: 'Action',
            width: 100,
            sortable: false,
            filterable: false,
            disableColumnMenu: true, 
            resizable: false,
            renderCell: (params) => (
              <Stack direction="row" spacing="8px">
                <IconButton size="small" onClick={() => setSelectedRegional(params.row as any)}>
                  <Edit />
                </IconButton>
                <IconButton size="small" onClick={() => onDelete(params.row.id)}>
                  <Delete />
                </IconButton>
              </Stack>
            )
          }
        ]}
        disableSelectionOnClick
        autoHeight
      />
    </Paper>
  )
}

export default observer(RegionalDataGrid)
