import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import { NumericFormat } from "react-number-format"
import * as yup from 'yup';
import { useStore } from "../../../../../../app/stores/store";
import { LoadingButton } from "@mui/lab";
import useQuery from "../../../../../../app/hook/useQuery";
import { Dispatch, SetStateAction } from "react";

interface RequestReFidDialogProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const RequestReFidDialog = ({ isOpen, setIsOpen }: RequestReFidDialogProps) => {
  const { addReFidGeneralInformation, reFidGeneralInformationLoading } = useStore().reFidStore
  const query = useQuery()
  const id = query.get('id')
  const { show } = useStore().snackbarStore
  
  const closeDialog = () => {
    setIsOpen(false)
    resetForm()
    setErrors({})
  }
  
  const { values, handleSubmit, errors, handleChange, resetForm, setErrors, setFieldValue } = useFormik({
    validationSchema: yup.object({
      description: yup.string().required(),
      cost: yup.number().required().positive(),
    }),
    initialValues: {
      description: '',
      cost: 0,
    },
    onSubmit: async (v) => {
      if (!id) return;
      
      await addReFidGeneralInformation(id, v).then(() => {
        closeDialog()
        show('success', 'Request Re-FID successfully created')
      })
    }
  })
  
  return (
    <Dialog open={isOpen} fullWidth>
      <DialogContent>
        <form onSubmit={handleSubmit} noValidate>
        <Typography fontSize={'20px'} mb={2} color={"black"} fontWeight={700}>Request Re-FID</Typography>
        <Stack gap={3}>
          <TextField 
            onChange={handleChange} 
            label="Description" 
            error={!!errors.description}
            helperText={errors.description}
            value={values.description}
            name="description"
          />
          <NumericFormat
          customInput={TextField}
          label="Cost"
          name="cost"
          required
          onKeyDown={(e) => {
            if (
              e.key === "e" ||
              e.key === "E" ||
              e.key === "-" ||
              e.key === "+"
            ) {
              e.preventDefault();
            }
          }}
          fullWidth
          value={values.cost}
          error={!!errors?.cost}
          helperText={errors?.cost}
          thousandSeparator={true}
          onValueChange={(e) => {
            setFieldValue(`cost`, e.floatValue);
          }}
          prefix="Rp "
        />
        </Stack>
        <Stack mt={3} direction="row" justifyContent="flex-end" gap={2}>
          <Button onClick={closeDialog} type="button" variant="outlined" color="inherit">Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={reFidGeneralInformationLoading}>Request</LoadingButton>
        </Stack>
        </form>
      </DialogContent>
    </Dialog>
  )
}