import { LoadingButton, TreeItem, TreeView } from "@mui/lab";
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { NumericFormat } from "react-number-format";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { IFidMilestone } from "../../../app/models/fidMilestones";
import { useStore } from "../../../app/stores/store";
import useQuery from "../../../app/hook/useQuery";
import { ProjectStatus, TaskSubTaskStatus } from "../../../app/config/enum";
import { findDeepObject } from "../../../app/utils/utils";
import DrawerForm from "../../../app/component/DrawerForm";

type OrgList = {
  value: string;
  id: string;
  level: string;
  name: string;
  child: OrgList[];
};

type AddItemFormProps = {
  taskToEdit?: IFidMilestone["tasks"][0];
  subTaskToEdit?: IFidMilestone["tasks"][0]["subTasks"][0];
  setTaskToEdit: Dispatch<SetStateAction<IFidMilestone["tasks"][0] | undefined>>;
  setSubtaskToEdit: Dispatch<SetStateAction<IFidMilestone["tasks"][0]["subTasks"][0] | undefined>>;
  parentId?: string;
  formType?: "task" | "subtask";
  onClose: () => any;
  isOpen: boolean;
  readOnly: boolean;
};

const taskValidation = yup.object({
  milestonesReFidId: yup.string().required(),
  name: yup.string().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  // weightPhysical: yup.number().max(100, "Weight Physical must be less than or equal to 100"),
  weightPhysical: yup.number(),
  weightFinancial: yup.number().required("Weight Financial must be filled").positive("Weight Financial must be positive value"),
  status: yup.string(),
  organizationId: yup.string(),
});

const subTaskValidation = yup.object({
  tasksReFidId: yup.string().required(),
  name: yup.string().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  // weightPhysical: yup.number().max(100, "Weight Physical must be less than or equal to 100"),
  weightPhysical: yup.number(),
  weightFinancial: yup.number().required("Weight Financial must be filled").positive("Weight Financial must be positive value"),
  status: yup.string(),
  organizationId: yup.string(),
});

export const AddItemFormDrawer = ({
  taskToEdit,
  subTaskToEdit,
  parentId,
  isOpen,
  setTaskToEdit,
  setSubtaskToEdit,
  formType,
  onClose,
  readOnly,
}: AddItemFormProps) => {
  const { reFidGeneralInformation, reFidGeneralInformationLoading, getReFidGeneralInformation, getCapexGrid } = useStore().reFidStore;
  const { editSubTask, deleteTaskLoading, deleteTask, deleteSubTask } = useStore().milestonetore;
  const { addTaskReFid, editTaskReFid, addSubTaskReFid, editSubTaskReFid } = useStore().reFidStore;
  const { getOrganizationList } = useStore().organizationStore;
  const { generalInformation } = useStore().proposalStore;
  const query = useQuery();
  const id = query.get("proposalId");
  const status = query.get("status");

  const closeDrawer = () => {
    onClose();
    setTaskToEdit(undefined);
    setSubtaskToEdit(undefined);
    resetForm();
    setErrors({});
  };

  const successCallback = () => {
    if (!id) return;

    closeDrawer();
    getCapexGrid(id);
  };

  const taskInitialValues = useMemo(() => {
    if (taskToEdit) {
      return {
        ...taskToEdit,
        milestonesReFidId: parentId,
      };
    } else {
      return {
        milestonesReFidId: parentId,
        name: "",
        organizationId: "",
        startDate: new Date(),
        endDate: new Date(),
        weightPhysical: 0,
        weightFinancial: 0,
        status: TaskSubTaskStatus.Perencanaan,
      };
    }
  }, [parentId, taskToEdit]);

  const subTaskInitialValues = useMemo(() => {
    if (subTaskToEdit) {
      return {
        ...subTaskToEdit,
        tasksReFidId: parentId,
      };
    } else {
      return {
        tasksReFidId: parentId,
        name: "",
        organizationId: "",
        startDate: new Date(),
        endDate: new Date(),
        weightPhysical: 0,
        weightFinancial: 0,
        status: TaskSubTaskStatus.Perencanaan,
      };
    }
  }, [parentId, subTaskToEdit]);

  const onSubmitTask = async (values: yup.InferType<typeof taskValidation>) => {
    if (!id) return;

    if (taskToEdit) {
      await editTaskReFid(taskToEdit.id, values as any).then(successCallback);
    } else {
      const { organizationId, ...payload } = values;
      await addTaskReFid(payload as any).then(successCallback);
    }
  };

  const onSubmitSubTask = async (values: yup.InferType<typeof taskValidation>) => {
    if (!id) return;

    if (subTaskToEdit) {
      await editSubTask(subTaskToEdit.id, values as any).then(successCallback);
    } else {
      const { organizationId, ...payload } = values;
      await addSubTaskReFid(payload as any).then(successCallback);
    }
  };

  const [organizationsList, setOrganizationsList] = useState<OrgList[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<OrgList>();

  const { handleSubmit, dirty, isValid, resetForm, isSubmitting, setErrors, values, errors, handleChange, setFieldValue } = useFormik<
    yup.InferType<typeof taskValidation> | yup.InferType<typeof subTaskValidation>
  >({
    initialValues: (formType === "task" ? taskInitialValues : subTaskInitialValues) as
      | yup.InferType<typeof taskValidation>
      | yup.InferType<typeof subTaskValidation>,
    onSubmit: (formType === "task" ? onSubmitTask : onSubmitSubTask) as any,
    validationSchema: formType === "task" ? taskValidation : subTaskValidation,
    enableReinitialize: true,
  });

  const handleChangeOrganizationId = (node: any) => {
    setSelectedOrg(node);
    setFieldValue("organizationId", node.id);
  };

  const renderTreeItem = (nodes: any) => {
    return (
      <TreeItem onClick={() => handleChangeOrganizationId(nodes)} key={nodes.id} nodeId={nodes.id} label={nodes.name}>
        {Array.isArray(nodes.child) ? nodes.child.map((node: any) => renderTreeItem(node)) : null}
      </TreeItem>
    );
  };

  useEffect(() => {
    getOrganizationList().then((organizationRes) => {
      setOrganizationsList(organizationRes?.map((item) => ({ ...item, text: item.name, value: item.id } as any)) ?? []);
    });
  }, [getOrganizationList]);

  useEffect(() => {
    const result = findDeepObject(organizationsList, "id", values.organizationId as any);
    setSelectedOrg(result ?? undefined);
  }, [organizationsList, values.organizationId]);

  useEffect(() => {
    if (!generalInformation) return;
    if (!values.weightFinancial) {
      setFieldValue("weightPhysical", 0);
    } else {
      const weightPhysical = ((values.weightFinancial / generalInformation.cost) * 100).toFixed(2);

      setFieldValue("weightPhysical", weightPhysical);
    }
  }, [generalInformation, values.weightFinancial, setFieldValue]);

  return (
    <DrawerForm
      isOpen={isOpen}
      onClose={closeDrawer}
      onSubmit={handleSubmit}
      isEdit={false}
      title={formType === "task" ? (!!taskToEdit ? "Edit Task" : "Add task") : subTaskToEdit ? "Edit Subtask" : "Add Subtask"}
      disableAction={!dirty || !isValid}
      isLoading={isSubmitting}
      customButton={
        <>
          {(taskToEdit || subTaskToEdit) && (
            <LoadingButton
              variant="outlined"
              loading={deleteTaskLoading}
              color="error"
              disabled={readOnly}
              onClick={() =>
                formType === "task"
                  ? taskToEdit &&
                    deleteTask(taskToEdit.id).then(() => {
                      if (!id) return;

                      successCallback();
                    })
                  : subTaskToEdit &&
                    deleteSubTask(subTaskToEdit.id).then(() => {
                      if (!id) return;

                      successCallback();
                    })
              }
              type="button"
            >
              Delete
            </LoadingButton>
          )}
          <LoadingButton variant="contained" loading={isSubmitting} type="submit" disabled={readOnly}>
            Save
          </LoadingButton>
        </>
      }
    >
      <TextField
        label="Name"
        name="name"
        required
        disabled={readOnly}
        fullWidth
        value={values.name}
        error={!!errors.name}
        helperText={errors.name}
        onChange={handleChange}
      />
      <Stack direction="row" spacing="24px">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            value={values.startDate}
            onChange={(e: any) => setFieldValue("startDate", e)}
            disabled={readOnly}
            inputFormat="dd MMM yyyy"
            renderInput={(props) => (
              <TextField
                required
                fullWidth
                name="startDate"
                error={!!errors.startDate}
                helperText={errors.startDate && String(errors.startDate)}
                {...props}
              />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="End Date"
            value={values.endDate}
            disabled={readOnly}
            inputFormat="dd MMM yyyy"
            onChange={(e: any) => setFieldValue("endDate", e)}
            renderInput={(props) => (
              <TextField
                required
                fullWidth
                name="endDate"
                error={!!errors.endDate}
                helperText={errors.endDate && String(errors.endDate)}
                {...props}
              />
            )}
          />
        </LocalizationProvider>
      </Stack>
      {formType === "task" && status !== ProjectStatus.Proposal && (
        <FormControl fullWidth>
          <InputLabel shrink id="organization-select-id">
            Organization
          </InputLabel>
          <Select
            labelId="organization-select-id"
            id="organization-select-id"
            label="Organization"
            value={values.organizationId}
            disabled={readOnly}
            displayEmpty
          >
            <MenuItem sx={{ display: "none" }} value={values.organizationId}>
              {selectedOrg?.name}
            </MenuItem>
            <TreeView
              aria-label="select organization"
              defaultCollapseIcon={<ExpandMore />}
              defaultExpandIcon={<ChevronRight />}
              sx={{ height: 240, flexGrow: 1, overflowY: "auto" }}
            >
              {!!organizationsList?.length &&
                organizationsList.map((item) => {
                  return renderTreeItem(item);
                })}
            </TreeView>
          </Select>
        </FormControl>
      )}
      <Box display="flex" flexDirection="row" gap={3}>
        <NumericFormat
          customInput={TextField}
          label="Weight Financial"
          name="weightFinancial"
          required
          disabled={readOnly}
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
              e.preventDefault();
            }
          }}
          fullWidth
          value={values.weightFinancial}
          error={!!errors?.weightFinancial}
          helperText={errors?.weightFinancial}
          thousandSeparator={true}
          onValueChange={(e) => {
            setFieldValue(`weightFinancial`, e.floatValue);
          }}
          prefix="Rp "
        />
        <TextField
          label="Weight Physical (%)"
          name="weightPhysical"
          required
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
              e.preventDefault();
            }
          }}
          sx={{ width: "150px" }}
          value={values.weightPhysical}
          error={!!errors.weightPhysical}
          helperText={errors?.weightPhysical}
          onChange={handleChange}
          disabled
          type="number"
        />
      </Box>
      {/* <CSelect
          options={Object.keys(TaskSubTaskStatus).map((i) => ({
            label: i === "InProgress" ? "In Progress" : i,
            value: (TaskSubTaskStatus as any)[i],
          }))}
          label="Status"
          name="status"
          value={values.status}
          error={!!errors.status}
          helperText={errors.status}
          onChange={handleChange}
        /> */}
    </DrawerForm>
  );
};
