import { Dispatch, SetStateAction, useState } from "react";
import { IFidMilestone } from "../../../../../../app/models/fidMilestones";
import { useStore } from "../../../../../../app/stores/store";
import useQuery from "../../../../../../app/hook/useQuery";
import { ProjectStatus } from "../../../../../../app/config/enum";
import { Box, Button, ButtonBase, Collapse, Divider, Stack, Typography } from "@mui/material";
import { Add, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { TaskTable } from "./task-table";
import EmptyState from "../../../../../../app/component/EmptyState";
import { observer } from "mobx-react-lite";

type MilestoneListProps = {
  milestone: IFidMilestone;
  readOnly: boolean;
  taskToEdit?: IFidMilestone["tasks"][0];
  setDrawerTypePembayaran: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
  setDialogTypePembayaran: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
  setDialogTypeProgress: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
  setTaskToEdit: Dispatch<SetStateAction<IFidMilestone["tasks"][0] | undefined>>;
  setSubtaskToEdit: Dispatch<SetStateAction<IFidMilestone["tasks"][0]["subTasks"][0] | undefined>>;
  setParentId: Dispatch<SetStateAction<string | undefined>>;
  setFormType: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
  setIsOpenDialog: Dispatch<SetStateAction<boolean>>;
};

export const MilestoneList = observer(
  ({
    readOnly,
    setDrawerTypePembayaran,
    milestone: { isOrigin, cost, milestonesName, milestonesId, tasks, totalAllocation, remainingBalance },
    taskToEdit,
    setIsOpenDialog,
    setTaskToEdit,
    setDialogTypeProgress,
    setDialogTypePembayaran,
    setSubtaskToEdit,
    setParentId,
    setFormType,
  }: MilestoneListProps) => {
    const { dialogStore, milestonetore } = useStore();
    const [open, setIsOpen] = useState(false);

    const openForm = (val: "task" | "subtask") => setFormType(val);

    const toggleExpandCollapse = () => setIsOpen((prev) => !prev);

    const query = useQuery();
    const id = query.get("id");
    const status = query.get("status");

    const handleDelete = (milestoneId: string) =>
      dialogStore.open({
        action: async () =>
          id &&
          (await milestonetore.deleteMilestones(id, milestoneId).then(() => {
            milestonetore.getMilestone(id);
            milestonetore.getTotalMilestonestasks(id);
          })),
        actionText: "Delete",
        title: "Delete",
        closeText: "Cancel",
        actionButtonProps: {
          color: "error",
        },
        description: "Are you sure want to delete this item?",
      });

    const showOrganizationField =
      status === ProjectStatus.Preparation ||
      status === ProjectStatus.Procurement ||
      status === ProjectStatus.Construction ||
      status === ProjectStatus.ProjectClosing;

    return (
      <>
        <Stack direction="row" alignItems="center" gap="8px">
          <Stack
            py={"20px"}
            sx={{
              cursor: "pointer",
              width: "100%",
              justifyContent: "flex-start",
            }}
            component={ButtonBase}
            onClick={toggleExpandCollapse}
          >
            <Stack flex={1} direction="row" spacing={"16px"} justifyContent="space-between" pr={4} sx={{ width: "100%" }}>
              <Stack direction="row" alignItems="" spacing={"16px"} sx={{ textAlign: "start", width: "50%" }}>
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                <Box>
                  <Typography fontWeight={700} color="black">
                    {milestonesName}
                  </Typography>
                  <Box sx={{ fontSize: "11px" }}>(remaining balance: Rp{remainingBalance ? remainingBalance.toLocaleString() : 0})</Box>
                </Box>
              </Stack>
              <Typography color="black" fontSize="11px">
                <Stack flex={1} direction="row" spacing={"16px"} justifyContent="" pr={4} sx={{ textAlign: "start", width: "100%" }}>
                  <Box>
                    <Box sx={{ fontWeight: "bold" }}>Total Alokasi Nominal Pagu / Nominal Pagu</Box>
                    <Box>
                      Rp{totalAllocation ? totalAllocation.toLocaleString() : 0} / Rp{cost ? cost.toLocaleString() : 0}
                    </Box>
                  </Box>
                </Stack>
              </Typography>
            </Stack>
          </Stack>
          {status === ProjectStatus.ProjectClosing ? (
            ""
          ) : (
            <Stack direction="row" alignItems="center" gap="8px">
              <div>
                <Button onClick={() => handleDelete(milestonesId)} disabled={isOrigin || readOnly} variant="outlined" color="error">
                  Delete
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    openForm("task");
                    setParentId(milestonesId);
                  }}
                  variant="contained"
                  sx={{ whiteSpace: "nowrap" }}
                  startIcon={<Add />}
                  disabled={readOnly}
                >
                  {taskToEdit ? "Edit" : "Add"} Task
                </Button>
              </div>
            </Stack>
          )}
        </Stack>
        <Divider />
        <Collapse sx={{ background: "rgba(0, 0, 0, .03)" }} in={open} timeout="auto" unmountOnExit>
          {tasks.length > 0 ? (
            <TaskTable
              setDialogTypeProgress={setDialogTypeProgress}
              setDrawerTypePembayaran={setDrawerTypePembayaran}
              setDialogTypePembayaran={setDialogTypePembayaran}
              readOnly={readOnly}
              setParentId={setParentId}
              setSubtaskToEdit={setSubtaskToEdit}
              setTaskToEdit={setTaskToEdit}
              setIsOpenDialog={setIsOpenDialog}
              setFormType={setFormType}
              tasks={tasks}
              showOrganizationField={showOrganizationField}
            />
          ) : (
            <EmptyState />
          )}
        </Collapse>
      </>
    );
  }
);
