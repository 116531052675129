export interface IExample {
    id?: string;
    name: string;
    price: number;
    date: Date;
    isReady: boolean;
}

export class Example implements IExample {
    id?: string = undefined;
    name;
    price;
    date;
    isReady;

    constructor() {
        this.name = "";
        this.price = 0;
        this.date = new Date();
        this.isReady = false;
    }

    clone(example: IExample) {
        this.id = example.id;
        this.name = example.name;
        this.price = example.price;
        this.date = new Date(example.date);
        this.isReady = example.isReady;
    }
}