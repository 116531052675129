import { ChevronRight, Close, ExpandMore } from "@mui/icons-material";
import { Autocomplete, Box, Drawer, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useStore } from "../../../../app/stores/store";
import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import CSelect from "../../../../app/component/CSelect";
import { history } from "../../../..";
import { LoadingButton, TreeItem, TreeView } from "@mui/lab";
import useQuery from "../../../../app/hook/useQuery";
import { BD_OTHERS_NAME, FlagEdit, NON_BD_OTHERS_NAME, NO_SUBTASK_ID, ProjectCategory, Roles } from "../../../../app/config/enum";
import CommentList from "./components/CommentList";
import { findDeepObject } from "../../../../app/utils/utils";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  readOnly: boolean;
};

type OrgList = {
  value: string;
  id: string;
  level: string;
  name: string;
  child: OrgList[];
};

function ProposalGeneralInformationForm({ isOpen, handleClose, readOnly }: Props) {
  const query = useQuery();
  const {
    generalStore,
    userStore,
    underlyingStore,
    proposalStore,
    assetCategoryStore,
    accountStore,
    inventoryCategoryStore,
    projectCategoryNBDStore,
    projectCategoryAllStore,
    unitBusinessCategoryStore,
    landStore,
  } = useStore();
  const { getRegionals, regionalsLoading, unitBusinessOptions, unitBusinessLoading, getUnitBusiness, regionalOptions, getProjectTypes } =
    generalStore;
  const { addGeneralInformation, getGeneralInformation, generalInformation, editGeneralInformation } = proposalStore;
  const { getUnderlying, underlying, underlyingLoading } = underlyingStore;
  const { projectCategoryAll } = projectCategoryAllStore;
  const { getDropdownLand, dropdownLand } = landStore;
  const { assetCategory, assetCategoryLoading, getAssetCategory } = assetCategoryStore;
  const { getInventoryCategory } = inventoryCategoryStore;
  const { getUserOptions, userOptions, userOptionsLoading } = userStore;
  const { account } = accountStore;
  const { getUnitBusinessCategory, unitBusinessCategory, unitBusinessCategoryLoading } = unitBusinessCategoryStore;
  const { getProjectCategoryNBD, projectCategoryNBD, projectCategoryNBDLoading } = projectCategoryNBDStore;
  const [organizationsList, setOrganizationsList] = useState<OrgList[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<OrgList>();
  const { getOrganizationList, getReviewerByOrganization, reviewerByOrganization } = useStore().organizationStore;

  const proposalId = query.get("id");
  const projectCategory = query.get("projectCategory");
  const status = query.get("status");

  const isSuperAdmin = useMemo(() => {
    return account?.roles.includes(Roles.Superadmin);
  }, [account]);

  const categoryProjectBDOrNonBD = useMemo(() => {
    let temp = projectCategoryAll.find((item) => {
      let helper = item.name === BD_OTHERS_NAME ? "BD" : item.name === NON_BD_OTHERS_NAME ? "NON_BD" : item.name;
      if (helper === projectCategory) return item;
    });

    return temp;
  }, [projectCategory, projectCategoryAll]);

  const isNBD = projectCategory === ProjectCategory.NonBD;

  const validationSchema = useMemo(() => {
    return yup.object({
      underlyingProjectId: yup.string().required(),
      projectOwnerId: yup.string().required(),
      assetCategoryId: yup.string().required(),
      inventoryCategoryId: yup.string(),
      title: yup.string().required(),
      ubid: yup.string().required(),
      regionalId: yup.string().required(),
      category: yup.string().required(),
      landId: yup.string(),
      startYear: yup.date().required(),
      cost: yup.number().required(),
      categoryUnitBusinessId: yup.string().required(),
      projectCategoryIdNbd: isNBD ? yup.string().required() : yup.string(),
      areaOperationNbd: yup.string(),
      locationNbd: yup.string(),
      directoratNbd: isNBD ? yup.string().required() : yup.string(),
      reviewerIdNbd: isNBD ? yup.string().required() : yup.string(),
    });
  }, [isNBD]);

  type ValidationSchema = yup.InferType<typeof validationSchema>;

  const onSubmit = async (values: ValidationSchema) => {
    if (readOnly) return;

    if (!projectCategory) return;

    const { ubid, areaOperationNbd, locationNbd, projectCategoryIdNbd, ...restPayload } = values;
    let payload;
    let convertedLandId = values.landId === "" ? null : values.landId;

    if (projectCategory === ProjectCategory.NonBD) {
      payload = {
        ...values,
        landId: convertedLandId,
        directoratNbd: isSuperAdmin ? restPayload.directoratNbd : userOptions.find((item) => item.id === values.projectOwnerId)?.organizationId,
      };
    } else {
      if (proposalId) {
        payload = {
          ...restPayload,
          ubid: ubid,
          areaOperationNbd: null,
          locationNbd: null,
          directoratNbd: null,
          landId: convertedLandId,
        };
      } else {
        payload = {
          ...restPayload,
          areaOperationNbd: null,
          locationNbd: null,
          directoratNbd: null,
          landId: convertedLandId,
        };
      }
    }

    if (!!generalInformation && proposalId) {
      await editGeneralInformation(proposalId, {
        ...payload,
        flagEdit: FlagEdit.PROPOSAL,
      } as any).then(() => {
        getGeneralInformation(proposalId);
        handleClose();
        formik.resetForm();
        formik.setErrors({
          underlyingProjectId: undefined,
          projectOwnerId: undefined,
          assetCategoryId: undefined,
          inventoryCategoryId: undefined,
          title: undefined,
          ubid: undefined,
          categoryUnitBusinessId: undefined,
          regionalId: undefined,
          category: undefined,
          startYear: undefined,
          cost: undefined,
          projectCategoryIdNbd: undefined,
          areaOperationNbd: undefined,
          locationNbd: undefined,
          directoratNbd: undefined,
          reviewerIdNbd: undefined,
        });
      });
    } else {
      await addGeneralInformation(payload as any)
        .then((res) => history.push(`/project-monitoring/proposal/list/form/?projectCategory=${projectCategory}&id=${res.id}&status=${status}`))
        .then(() => {
          handleClose();
          formik.resetForm();
          formik.setErrors({
            underlyingProjectId: undefined,
            projectOwnerId: undefined,
            assetCategoryId: undefined,
            inventoryCategoryId: undefined,
            title: undefined,
            categoryUnitBusinessId: undefined,
            ubid: undefined,
            regionalId: undefined,
            category: undefined,
            startYear: undefined,
            cost: undefined,
            projectCategoryIdNbd: undefined,
            areaOperationNbd: undefined,
            locationNbd: undefined,
            directoratNbd: undefined,
            reviewerIdNbd: undefined,
          });
        });
    }
  };

  const initialValues: ValidationSchema = useMemo(() => {
    if (generalInformation) {
      const { projectTypeId, id, ...rest } = generalInformation;
      return {
        ...rest,
        ubid: rest.ubid,
        categoryUnitBusinessId: rest.unitBussinessCategoryId,
        locationNbd: rest.locationNbd ?? "",
        areaOperationNbd: rest.areaOperationNbd ?? "",
      };
    } else {
      return {
        underlyingProjectId: "",
        projectOwnerId: "",
        assetCategoryId: "",
        landId: "",
        inventoryCategoryId: "",
        projectCategoryId: categoryProjectBDOrNonBD?.id,
        title: "",
        categoryUnitBusinessId: "",
        ubid: projectCategory !== ProjectCategory.NonBD ? NO_SUBTASK_ID : "",
        regionalId: "",
        category: categoryProjectBDOrNonBD?.category ?? "",
        startYear: new Date(),
        cost: 0,
        projectCategoryIdNbd: "",
        areaOperationNbd: "",
        locationNbd: "",
        directoratNbd: projectCategory === ProjectCategory.NonBD ? NO_SUBTASK_ID : "",
        reviewerIdNbd: "",
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalInformation, projectCategory]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const { handleSubmit, isSubmitting, submitForm, handleChange, setFieldValue, errors, values } = formik;

  useEffect(() => {
    getRegionals();
    getProjectTypes();
    getUnderlying();
    getAssetCategory();
    getInventoryCategory();
    getUserOptions();
    getUnitBusinessCategory();
    getProjectCategoryNBD();
  }, [
    getRegionals,
    getUserOptions,
    getProjectCategoryNBD,
    getProjectTypes,
    getUnderlying,
    getAssetCategory,
    getInventoryCategory,
    getUnitBusinessCategory,
  ]);

  useEffect(() => {
    if (!values.categoryUnitBusinessId) return;

    getUnitBusiness({ categoryUnitBusinessId: values.categoryUnitBusinessId });
  }, [values.categoryUnitBusinessId, getUnitBusiness]);

  useEffect(() => {
    if (isSuperAdmin) return;
    if (readOnly) return;
    if (!!generalInformation) return;

    const currentUser = userOptions.find((i) => i.userName === account?.username);

    if (!currentUser) return;

    setFieldValue("projectOwnerId", currentUser.id);
  }, [isSuperAdmin, setFieldValue, account, userOptions, readOnly, generalInformation]);

  const projectCategoryDisplay =
    (projectCategory === ProjectCategory.BD && "Business Development") ||
    (projectCategory === ProjectCategory.NonBD && "Non-Business Development") ||
    (projectCategory === ProjectCategory.Anorganik && "Non-Business Development");

  const handleChangeOrganizationId = (node: any) => {
    setSelectedOrg(node);
    getReviewerByOrganization(node.id);
    setFieldValue("directoratNbd", node.id);
  };

  const renderTreeItem = (nodes: any) => {
    return (
      <TreeItem onClick={() => handleChangeOrganizationId(nodes)} key={nodes.id} nodeId={nodes.id} label={nodes.name}>
        {Array.isArray(nodes.child) ? nodes.child.map((node: any) => renderTreeItem(node)) : null}
      </TreeItem>
    );
  };

  useEffect(() => {
    const result = findDeepObject(organizationsList, "id", values.directoratNbd ?? "");

    setSelectedOrg(result ?? undefined);
  }, [getReviewerByOrganization, organizationsList, values.directoratNbd]);

  useEffect(() => {
    getOrganizationList().then((organizationRes) => {
      setOrganizationsList(organizationRes?.map((item) => ({ ...item, text: item.name, value: item.id } as any)) ?? []);
    });

    getDropdownLand();
  }, [getOrganizationList, getDropdownLand]);

  useEffect(() => {
    if (!account) return;

    getReviewerByOrganization(account?.organizationId ?? "");
  }, [account, getReviewerByOrganization]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Drawer
        anchor="right"
        open={isOpen}
        sx={{
          ".MuiPaper-root": {
            width: "500px",
          },
        }}
      >
        <Box
          sx={{
            m: "16px 16px 0 16px",
            pb: "8px",
            borderBottom: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "space-between",
            mb: "16px",
          }}
        >
          <Typography fontSize={"14px"} fontWeight={700} color="black">
            {generalInformation ? "Edit" : "Add"} General Information - {projectCategoryDisplay}
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Stack flex={1} overflow="auto" p="16px" spacing="24px">
          <TextField
            fullWidth
            label="Title"
            name="title"
            required
            onChange={handleChange}
            value={values.title}
            error={!!errors.title}
            helperText={errors.title}
            disabled={readOnly}
          />
          {isNBD && (
            <FormControl fullWidth>
              <InputLabel size="small" shrink id="organization-select-id">
                Organization
              </InputLabel>
              <Select
                labelId="organization-select-id"
                id="organization-select-id"
                name="directoratNbd"
                label="Organization"
                value={values.directoratNbd}
                displayEmpty
                disabled={!isSuperAdmin}
                size="small"
                endAdornment={
                  !!values.directoratNbd ? (
                    <Close
                      onClick={() => {
                        setSelectedOrg(undefined);
                        setFieldValue("directoratNbd", "");
                      }}
                      sx={{ mr: 2, cursor: "pointer" }}
                      fontSize="small"
                    />
                  ) : undefined
                }
              >
                <MenuItem sx={{ display: "none" }} value={values.directoratNbd}>
                  {!isSuperAdmin ? userOptions.find((item) => item.id === values.projectOwnerId)?.organizationName : selectedOrg?.name}
                </MenuItem>
                <TreeView
                  aria-label="select organization"
                  defaultCollapseIcon={<ExpandMore />}
                  defaultExpandIcon={<ChevronRight />}
                  sx={{ height: 240, flexGrow: 1, overflowY: "auto" }}
                >
                  {!!organizationsList?.length &&
                    organizationsList.map((item) => {
                      return renderTreeItem(item);
                    })}
                </TreeView>
              </Select>
            </FormControl>
          )}
          <CSelect
            fullWidth
            options={assetCategory.map((i) => ({ label: i.name, value: i.id }))}
            label="Kategori Aset"
            name="assetCategoryId"
            onChange={handleChange}
            disabled={readOnly}
            loading={assetCategoryLoading}
            value={values.assetCategoryId}
            error={!!errors.assetCategoryId}
            helperText={errors.assetCategoryId}
          />
          <CSelect
            fullWidth
            options={dropdownLand.map((i) => ({ label: i.noAsset, value: i.id }))}
            label="Lahan"
            name="landId"
            onChange={handleChange}
            disabled={readOnly}
            loading={assetCategoryLoading}
            value={values.landId}
            error={!!errors.landId}
            helperText={errors.landId}
          />
          <CSelect
            label="Underlying"
            options={underlying.map((i) => ({ label: i.name, value: i.id }))}
            required
            disabled={readOnly}
            loading={underlyingLoading}
            name="underlyingProjectId"
            value={values.underlyingProjectId}
            error={!!errors.underlyingProjectId}
            helperText={errors.underlyingProjectId}
            onChange={handleChange}
          />
          <CSelect
            label="Project Type"
            disabled={unitBusinessCategoryLoading || readOnly}
            name="categoryUnitBusinessId"
            required
            loading={unitBusinessCategoryLoading}
            options={unitBusinessCategory.map((i) => ({
              label: i.name,
              value: i.id,
            }))}
            value={values.categoryUnitBusinessId}
            error={!!errors.categoryUnitBusinessId}
            helperText={errors.categoryUnitBusinessId}
            onChange={handleChange}
          />
          <CSelect
            label="Mata Anggaran"
            disabled={unitBusinessLoading || readOnly || !values.categoryUnitBusinessId || projectCategory !== ProjectCategory.NonBD}
            name="ubid"
            required
            loading={unitBusinessLoading}
            options={unitBusinessOptions}
            value={values.ubid}
            error={!!errors.ubid}
            helperText={errors.ubid}
            onChange={handleChange}
          />
          <CSelect
            label="Openational / Regional Unit"
            options={regionalOptions}
            name="regionalId"
            disabled={regionalsLoading || readOnly}
            loading={regionalsLoading}
            required
            value={values.regionalId}
            error={!!errors.regionalId}
            helperText={errors.regionalId}
            onChange={handleChange}
          />
          {isNBD && (
            <CSelect
              label="Project Category Non-BD"
              options={projectCategoryNBD.map((i) => ({
                label: i.name,
                value: i.id,
              }))}
              name="projectCategoryIdNbd"
              disabled={projectCategoryNBDLoading || readOnly}
              loading={projectCategoryNBDLoading}
              required
              value={values.projectCategoryIdNbd}
              error={!!errors.projectCategoryIdNbd}
              helperText={errors.projectCategoryIdNbd}
              onChange={handleChange}
            />
          )}
          {isNBD && (
            <TextField
              label="Area Operasi"
              name="areaOperationNbd"
              disabled={readOnly}
              required
              value={values.areaOperationNbd}
              error={!!errors.areaOperationNbd}
              helperText={errors.areaOperationNbd}
              onChange={handleChange}
            />
          )}
          {isNBD && (
            <TextField
              label="Lokasi/Kota"
              name="locationNbd"
              disabled={readOnly}
              required
              value={values.locationNbd}
              error={!!errors.locationNbd}
              helperText={errors.locationNbd}
              onChange={handleChange}
            />
          )}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Submission Date"
              value={values.startYear}
              views={["year", "month", "day"]}
              disabled={readOnly}
              onChange={(e: any) => setFieldValue("startYear", e)}
              renderInput={(props) => (
                <TextField
                  required
                  fullWidth
                  name="startYear"
                  error={!!errors.startYear}
                  helperText={errors.startYear && String(errors.startYear)}
                  {...props}
                />
              )}
            />
          </LocalizationProvider>
          <Autocomplete
            disablePortal
            componentsProps={{
              paper: {
                sx: {
                  width: "100% !important",
                },
              },
            }}
            id="projectOwnerId"
            disabled={readOnly || !isSuperAdmin}
            fullWidth
            defaultValue={{
              label: `${userOptions.find((item) => item.id === values.projectOwnerId)?.organizationName} - ${
                userOptions.find((item) => item.id === values.projectOwnerId)?.displayName
              }`,
              value: values.projectOwnerId,
            }}
            loading={userOptionsLoading}
            options={userOptions.map((i) => ({
              label: !!i.organizationName ? i.organizationName + " - " + i.displayName : i.displayName,
              value: i.id,
            }))}
            onChange={(e, v) => {
              setFieldValue("projectOwnerId", v?.value);
            }}
            renderInput={(params) => (
              <TextField
                name="projectOwnerId"
                error={!!errors.projectOwnerId}
                helperText={errors.projectOwnerId}
                value={values.projectOwnerId}
                {...params}
                label="Fungsi Pengusul"
              />
            )}
          />
          {isNBD && (
            <Autocomplete
              disablePortal
              id="reviewerIdNbd"
              componentsProps={{
                paper: {
                  sx: {
                    width: "100% !important",
                  },
                },
              }}
              defaultValue={{
                label: reviewerByOrganization.find((item) => item.id === values.reviewerIdNbd)?.name ?? "",
                value: values.reviewerIdNbd ?? "",
              }}
              fullWidth
              loading={userOptionsLoading}
              options={reviewerByOrganization.map((i) => ({
                label: i.name,
                value: i.id,
              }))}
              onChange={(e, v) => setFieldValue("reviewerIdNbd", v?.value)}
              renderInput={(params) => (
                <TextField name="reviewerIdNbd" error={!!errors.reviewerIdNbd} helperText={errors.reviewerIdNbd} label="Reviewer" {...params} />
              )}
            />
          )}
          <TextField
            id="input-with-icon-textfield"
            label="Capex"
            InputProps={{
              startAdornment: <Box>Rp{values.cost.toLocaleString()}</Box>,
            }}
            disabled
            value={""}
            error={!!errors.cost}
            helperText={errors.cost}
          />
          {isOpen && generalInformation ? <CommentList isOpen={isOpen} data={[generalInformation]} /> : ""}
        </Stack>
        <Box
          sx={{
            m: "0 16px 16px 16px",
            pt: "16px",
            borderTop: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <LoadingButton variant="contained" type="submit" disabled={readOnly} loading={isSubmitting} onClick={submitForm}>
            {generalInformation ? "Edit" : "Add"}
          </LoadingButton>
        </Box>
      </Drawer>
    </form>
  );
}

export default observer(ProposalGeneralInformationForm);
