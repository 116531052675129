import { Box } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useRef } from "react";

interface ChartGridProps {
  series: number[] | string[];
  labels: string[];
  title?: string;
  extendsOptions?: any;
  extendsChartOptions?: any;
}

export const ChartGrid = ({ series, labels, title = "", extendsOptions, extendsChartOptions }: ChartGridProps) => {
  const ref = useRef(null);

  useEffect(() => {
    const options: any = {
      series: series,
      chart: {
        width: "100%",
        type: "pie",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: `${format(new Date(), "dd MMM yyyy")}_${title}`,
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp: any) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: `${format(new Date(), "dd MMM yyyy")}_${title}`,
            },
            png: {
              filename: `${format(new Date(), "dd MMM yyyy")}_${title}`,
            },
          },
        },
        ...extendsChartOptions,
      },
      labels: labels,
      colors: ["rgb(0, 143, 251)", "#8BCE4A", "red"],
      theme: {
        monochrome: {
          enabled: true,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -5,
          },
        },
      },
      title: {
        text: '',
        offsetX: 30,
        offsetY: -8,
        style: {
          fontSize: "20px",
          cssClass: "apexcharts-yaxis-title",
        },
      },
      dataLabels: {
        formatter(val: any, opts: any) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name, val.toFixed(1) + "%"];
        },
      },
      // legend: {
      //   show: false,
      // },
      ...extendsOptions,
    };
    const chart = new ApexCharts(ref.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [labels, series, title, extendsOptions, extendsChartOptions]);

  return (
    <Box bgcolor="white" pt={3} sx={{ width: "100%" }}>
      <div ref={ref} />
    </Box>
  );
};
