import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ISubHolding, ISubHoldingBody, ISubHoldingGrid, ISubHoldingQueryParams } from "../models/subHoldings";
import { DataGridResult } from "../models/materialUI/dataGrid";

export class SubHoldingStore {
  subHoldingGrid: DataGridResult<ISubHoldingGrid[]> = new DataGridResult<ISubHoldingGrid[]>([], 0)
  subHoldingGridLoading = false
  queryParams: ISubHoldingQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: '',
  }
  
  subHolding: ISubHolding[] = []
  subHoldingLoading = false;
  
  selectedSubHolding: ISubHolding | null = null
  
  constructor() {
    makeAutoObservable(this)
  }

  getSubHoldingGrid = async () => {
    this.subHoldingGridLoading = true
    try {
      const res = await agent.General.getSubHoldingsGrid(this.queryParams)

      runInAction(() => this.subHoldingGrid = res);
    } catch (e) {
      throw e
    } finally {
      runInAction(() => this.subHoldingGridLoading = false)
    }
  }

  getSubHolding = async () => {
    this.subHoldingLoading = true
    try {
      const res = await agent.General.getSubHoldings()

      runInAction(() => this.subHolding = res);
    } catch (e) {
      throw e
    } finally {
      runInAction(() => this.subHoldingLoading = false)
    }
  }

  addSubHolding = async (body: ISubHoldingBody) => {
    try {
      await agent.General.addSubHoldings(body)
    } catch (e) {
      throw e;
    }
  }

  editSubHolding = async (id: string, body: ISubHoldingBody) => {
    try {
      await agent.General.editSubHoldings(id, body)
    } catch (e) {
      throw e;
    }
  }

  setSelectedSubHolding = (data: ISubHolding | null) => {
    this.selectedSubHolding = data;
  }

  deleteSubHolding = async (id: string) => {
    try {
      await agent.General.deleteSubHoldings(id)
    } catch (e) {
      throw e;
    }
  }

  setQueryParams = (query: 'pageSize' | 'search' | 'currentPage', v: string | number) => {
    this.queryParams = {
      ...this.queryParams,
      [query]: v,
    }
  }
}