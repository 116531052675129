import { useEffect, useMemo } from "react";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import SelectGroup from "../../app/component/SelectGroup";
import DrawerForm from "../../app/component/DrawerForm";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup.string().required(),
  parentId: yup.string(),
  level: yup.number(),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function OrganizationForm({ isOpen, onClose }: { isOpen: boolean; onClose: () => any }) {
  const { addOrganization, selectedOrganization, editOrganization, setSelectedOrganization, getOrganizationList, organizationNested } =
    useStore().organizationStore;

  const initialValues: ValidationSchema = useMemo(() => {
    if (selectedOrganization) {
      return { name: selectedOrganization.name, parentId: selectedOrganization.parentId, level: Number(selectedOrganization.parentLevel) };
    } else {
      return {
        name: "",
        parentId: "",
        level: 0,
      };
    }
  }, [selectedOrganization]);

  const onSubmit = async (values: ValidationSchema) => {
    const { parentId, ...restValues } = values;
    let payload = values.parentId === "" ? restValues : values;

    if (selectedOrganization) {
      await editOrganization(selectedOrganization.id, payload).then(() => {
        getOrganizationList();
        onClose();
        setSelectedOrganization(null);
        resetForm();
        setErrors({
          name: undefined,
        });
      });
    } else {
      await addOrganization(payload).then(() => {
        getOrganizationList();
        onClose();
        setSelectedOrganization(null);
        resetForm();
        setErrors({
          name: undefined,
        });
      });
    }
  };

  const { handleSubmit, resetForm, setErrors, handleChange, setFieldValue, values, errors, isValid, isSubmitting, dirty } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (isOpen) {
      getOrganizationList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <DrawerForm
      title={`${selectedOrganization ? "Edit" : "Add"} Organization`}
      isOpen={isOpen || !!selectedOrganization}
      onClose={() => {
        onClose();
        setSelectedOrganization(null);
      }}
      onSubmit={handleSubmit}
      disableAction={!isValid || !dirty}
      isLoading={isSubmitting}
      isEdit={!!selectedOrganization}
    >
      <TextField label="Name" fullWidth name="name" value={values.name} error={!!errors.name} helperText={errors.name} onChange={handleChange} />

      <SelectGroup
        name="parentId"
        list={organizationNested || []}
        label="Organization"
        defaultData={{ value: selectedOrganization?.parentId, text: selectedOrganization?.parentName }}
        onChangeData={(value: any) => {
          setFieldValue("level", Number(value.level));
          setFieldValue("parentId", value.id);
        }}
      />
    </DrawerForm>
  );
}

export default observer(OrganizationForm);
