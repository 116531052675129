import { Close } from "@mui/icons-material";
import { Box, Drawer, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useStore } from "../../../../app/stores/store";
import { useEffect, useMemo } from "react";
import CSelect from "../../../../app/component/CSelect";
import { useFormik } from "formik";
import * as yup from "yup";
import useQuery from "../../../../app/hook/useQuery";
import { LoadingButton } from "@mui/lab";
import { observer } from "mobx-react-lite";
import { FlagEdit } from "../../../../app/config/enum";
import CommentList from "./components/CommentList";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  readOnly: boolean;
};

const validationSchema = yup.object({
  landId: yup.string().required(),
  // landUsage: yup.number().moreThan(0).required()
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function ProposalLandForm({ isOpen, handleClose, readOnly }: Props) {
  const { landStore, proposalStore } = useStore();
  const { getDropdownLand, dropdownLand } = landStore;
  const { addLand, editLand, getLand, land } = proposalStore;
  const query = useQuery();

  const initialValues: ValidationSchema = useMemo(() => {
    if (land && land.length > 0) {
      const { id, ...rest } = land[0];
      return rest;
    } else {
      return {
        landId: "",
        // landUsage: 0,
      };
    }
  }, [land]);

  const onSubmit = async (values: ValidationSchema) => {
    if (readOnly) return;

    const id = query.get("id");

    if (!id) return;

    if (land && land.length > 0) {
      await editLand(id, { ...values, flagEdit: FlagEdit.PROPOSAL, id: land[0].id })
        .then(() => {
          handleClose();
          resetForm();
          setErrors({
            landId: undefined,
            // landUsage: undefined,
          });
        })
        .then(() => getLand(id));
    } else {
      await addLand(id, values)
        .then(() => {
          handleClose();
          resetForm();
          setErrors({
            landId: undefined,
            // landUsage: undefined,
          });
        })
        .then(() => getLand(id));
    }
  };

  const { handleSubmit, handleChange, resetForm, setErrors, values, errors, dirty, submitForm, isValid, isSubmitting } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const id = query.get("id");

    if (!id) return;

    getDropdownLand().then(() => getLand(id));
  }, [getDropdownLand, getLand, query]);

  const regulation = dropdownLand.find((i) => i.id === values.landId)?.regulations[0];

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Drawer
        anchor="right"
        open={isOpen}
        sx={{
          ".MuiPaper-root": {
            width: "500px",
          },
        }}
      >
        <Box
          sx={{
            m: "16px 16px 0 16px",
            pb: "8px",
            borderBottom: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "space-between",
            mb: "16px",
          }}
        >
          <Typography fontSize={"14px"} fontWeight={700} color="black">
            {!!land && land.length > 0 ? "Edit" : "Add"} Land
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Stack flex={1} overflow="auto" p="16px" spacing="24px">
          <CSelect
            label="Land"
            options={dropdownLand.map((i) => ({ label: `${i.noAsset}${i.address ? ` - ${i.address}` : ""}`, value: i.id }))}
            name="landId"
            value={values.landId}
            error={!!errors.landId}
            helperText={errors.landId}
            disabled={readOnly}
            onChange={handleChange}
          />
          <TextField
            label="Land Area"
            InputLabelProps={{
              shrink: !!dropdownLand.find((i) => i.id === values.landId)?.landArea,
            }}
            value={dropdownLand.find((i) => i.id === values.landId)?.landArea}
            disabled
          />
          {/* <TextField
            label="Land Usage"
            type="number"
            name="landUsage"
            value={values.landUsage}
            error={!!errors.landUsage}
            helperText={errors.landUsage}
            onChange={handleChange}
          /> */}
          {regulation && (
            <>
              <Typography color="black" fontSize="20px" fontWeight={700}>
                Regulation
              </Typography>
              <Stack direction="row" spacing={"24px"}>
                <TextField
                  fullWidth
                  label="KDB"
                  disabled
                  InputLabelProps={{
                    shrink: !!regulation.kdb,
                  }}
                  value={regulation.kdb}
                />
                <TextField
                  fullWidth
                  label="KLB"
                  disabled
                  InputLabelProps={{
                    shrink: !!regulation.klb,
                  }}
                  value={regulation.klb}
                />
              </Stack>
              <Stack direction="row" spacing={"24px"}>
                <TextField
                  fullWidth
                  label="GSB"
                  disabled
                  InputLabelProps={{
                    shrink: !!regulation.gbs,
                  }}
                  value={regulation.gbs}
                />
                <TextField
                  fullWidth
                  label="KDH"
                  disabled
                  InputLabelProps={{
                    shrink: !!regulation.kdh,
                  }}
                  value={regulation.kdh}
                />
              </Stack>
              <TextField
                fullWidth
                label="Building Height"
                disabled
                InputProps={{
                  endAdornment: <Typography color="black">Meter</Typography>,
                }}
                InputLabelProps={{
                  shrink: !!regulation.buildingHeight,
                }}
                value={regulation.buildingHeight}
              />
            </>
          )}
          {isOpen && land ? <CommentList isOpen={isOpen} data={land} /> : ""}
        </Stack>
        <Box
          sx={{
            m: "0 16px 16px 16px",
            pt: "16px",
            borderTop: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <LoadingButton loading={isSubmitting} disabled={!isValid || !dirty || readOnly} type="submit" variant="contained" onClick={submitForm}>
            {!!land && land.length > 0 ? "Edit" : "Add"}
          </LoadingButton>
        </Box>
      </Drawer>
    </form>
  );
}

export default observer(ProposalLandForm);
