import { Box } from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { Add } from "@mui/icons-material";
import AssetCategoryDataGrid from "./AssetCategoryDataGrid";
import AssetCategoryForm from "./AssetCategoryForm";
import { useState } from "react";

export default function AssetCategoryPageIndex() {
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false)
  
  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: 'Asset Category',
            href: '/master/asset-category'
          },
          {
            label: 'Asset Category List',
            href: '/master/asset-category'
          },
        ]}
        pageTitle="Asset Category List"
      >
        <DashboardLayoutHeader.Button 
          startIcon={<Add />}
          variant="contained"
          onClick={() => setIsOpenForm(true)}
        >
          Add Asset Category
        </DashboardLayoutHeader.Button>
      </DashboardLayoutHeader>
      <AssetCategoryDataGrid />
      <AssetCategoryForm isOpen={isOpenForm} onClose={() => setIsOpenForm(false)} />
    </Box>
  )
}