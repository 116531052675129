import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import Dialog from "@mui/material/Dialog";
import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { LoadingButton } from "@mui/lab";

function RkdDialog() {
  const { dialogStore } = useStore();
  const { isOpen, close, executeActions, dialog, isLoading } = dialogStore;

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        ".MuiPaper-root": {
          minWidth: "360px",
        },
      }}
    >
      <Stack gap={"24px"} padding={"16px"}>
        {dialog.title && (
          <Typography fontSize={"14px"} fontWeight={700} color="black">
            {dialog.title}
          </Typography>
        )}

        {dialog.description && (
          <Typography fontSize={"14px"} fontWeight={400} color="#212121">
            {dialog.description}
          </Typography>
        )}
				{dialog.content && dialog.content}
        <Stack
          direction={dialog.reverseButton ? "row-reverse" : "row"}
          gap={"16px"}
          justifyContent={dialog.reverseButton ? 'flex-start' : 'flex-end'}
        >
          {dialog.action && (
            <LoadingButton
              autoFocus
              onClick={() => executeActions()}
              disabled={isLoading}
              variant="contained"
              {...dialogStore.dialog.actionButtonProps}
            >
              {dialog.actionText ?? "OK"}
            </LoadingButton>
          )}
          <Button
            onClick={close}
            variant="outlined"
            color="inherit"
            {...dialogStore.dialog.closeButtonProps}
          >
            {dialog.closeText ?? (dialog.action ? "Cancel" : "OK")}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}

export default observer(RkdDialog);
