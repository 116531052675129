import React, { useContext, useState } from "react";
import {
  capitalize,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  SelectProps,
  FormHelperText,
  ListItem,
  Box,
  Collapse,
  ListItemText,
  List,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { RkdFormikContext } from "./RkdForm";
import { hasChildren } from "../../utils/utils";
import { observer } from "mobx-react-lite";
import { useField } from "formik";
import { TreeItem, TreeView } from "@mui/lab";

interface Props extends SelectProps {
  label: string;
  list: Array<any>;
}

type TRenderTree = {
  id: string;
  name: string;
  level: number | string;
  child?: readonly TRenderTree[];
};

function RkdSelect(props: Props) {
  const context = useContext(RkdFormikContext);
  const [optionValue, setOptionValue] = useState(context?.values[props.name!] ? context?.values[props.name!] : "");
  const [displayTextHelper, setDisplayTextHelper] = useState<string>(context?.values.organizationName ? context?.values.organizationName : "");
  const { list, label, ...otherProps } = props;
  const [field, meta] = useField(props.name!);

  const handleOnChange = (e: any, value?: any) => {
    context!.setFieldValue(props.name!, value ? value.id : e.target.value.id);
    setOptionValue(value ? value.id : e.target.value.id);
  };

  const MenuItemCustom = ({ item, value }: { item: any; value: string }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} value={value} />;
  };

  const SingleLevel = ({ item, value }: { item: any; value: string }) => {
    return (
      <ListItem
        button
        onClick={(e) => {
          setDisplayTextHelper(item.name);
          handleOnChange(e, value);
        }}
      >
        <MenuItem key={value} value={value}>
          {item.name}
        </MenuItem>
      </ListItem>
    );
  };

  const MultiLevel = ({ item }: { item: any }) => {
    const { child: children } = item;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
      setOpen((prev) => !prev);
    };

    return (
      <React.Fragment>
        <ListItem button onClick={handleClick}>
          <ListItemText primary={item.name} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child: any, key: any) => (
              <Box>
                <SingleLevel item={item} value={item.id} />
                <MenuItemCustom key={key} item={child} value={child.id} />
              </Box>
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };

  const renderTree = (nodes: TRenderTree) => (
    <TreeItem
      onClick={(e) => {
        setDisplayTextHelper(nodes.name);
        handleOnChange(e, nodes);
      }}
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
    >
      {Array.isArray(nodes.child) ? nodes.child.map((node: any) => renderTree(node)) : null}
    </TreeItem>
  );

  return (
    <FormControl fullWidth>
      <InputLabel id="selectId" sx={{ bgcolor: "#ffffff" }}>
        {label}
      </InputLabel>
      <Select
        sx={{ mb: 1 }}
        MenuProps={{ sx: { maxHeight: "300px" } }}
        labelId="selectId"
        displayEmpty
        onChange={(e) => handleOnChange(e)}
        value={optionValue}
        {...otherProps}
      >
        <MenuItem key={0} value="">
          None
        </MenuItem>
        {optionValue !== "" ? (
          <MenuItem style={{ display: "none" }} key={1} value={optionValue}>
            {displayTextHelper}
          </MenuItem>
        ) : (
          ""
        )}
        <TreeView aria-label="rich object" defaultCollapseIcon={<ExpandMore />} defaultExpanded={["root"]} defaultExpandIcon={<ExpandLess />}>
          <>
            {list.map((item, index) => {
              return <Box key={index}>{renderTree(item)}</Box>;
            })}
          </>
        </TreeView>
      </Select>
      <FormHelperText sx={{ mb: 1 }}>{meta.error && capitalize(meta.error)}</FormHelperText>
    </FormControl>
  );
}

export default observer(RkdSelect);
