import { useEffect, useMemo, useState } from "react";
import { OrganizationLevel, ProjectCategory, SETTING_APPROVAL_STATUS, enumToArray } from "../../app/config/enum";
import { MenuItem, TextField, Stack, FormControlLabel, Checkbox } from "@mui/material";
import { TOrganization } from "../../app/models/organization";
import { NumericFormat } from "react-number-format";
import { useFormik } from "formik";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import SelectGroup from "../../app/component/SelectGroup";
import DrawerForm from "../../app/component/DrawerForm";
import CSelect from "../../app/component/CSelect";
import * as yup from "yup";

type TSelectProps = {
  text: string;
  value: string;
};

const validationSchema = yup.object({
  category: yup.string().required(),
  organizationId: yup.string().required(),
  status: yup.string().required(),
  minCost: yup.number().when("status", {
    is: SETTING_APPROVAL_STATUS[1],
    then: yup.number().moreThan(-1).required(),
  }),
  maxCost: yup.number().when("status", {
    is: SETTING_APPROVAL_STATUS[1],
    then: yup.number().moreThan(0).required(),
  }),
  isReviewerLead: yup.bool(),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function SettingApprovalForm({ isOpen, onClose }: { isOpen: boolean; onClose: () => any }) {
  const [organizationsList, setOrganizationsList] = useState<TOrganization[] | undefined>();
  const [categoryList, setCategoryList] = useState<any>();
  const { settingApprovalStore, organizationStore } = useStore();
  const { addSettingApproval, selectedSettingApproval, editSettingApproval, getSettingApprovalGrid, setSelectedSettingApproval } =
    settingApprovalStore;

  const initialValues: ValidationSchema = useMemo(() => {
    if (selectedSettingApproval) {
      return {
        category: selectedSettingApproval.category,
        organizationId: selectedSettingApproval.organizationId,
        status: selectedSettingApproval.status,
        minCost: selectedSettingApproval.minCost,
        maxCost: selectedSettingApproval.maxCost,
        isReviewerLead: selectedSettingApproval.isReviewerLead,
      };
    } else {
      return {
        category: "",
        organizationId: "",
        status: "",
        minCost: 0,
        maxCost: 0,
        isReviewerLead: false,
      };
    }
  }, [selectedSettingApproval]);

  const onSubmit = async (values: ValidationSchema) => {
    if (selectedSettingApproval) {
      await editSettingApproval(selectedSettingApproval.id, values).then(() => {
        getSettingApprovalGrid();
        onClose();
        setSelectedSettingApproval(null);
        resetForm();
        setErrors({
          category: undefined,
          organizationId: undefined,
        });
      });
    } else {
      await addSettingApproval(values).then(() => {
        getSettingApprovalGrid();
        onClose();
        setSelectedSettingApproval(null);
        resetForm();
        setErrors({
          category: undefined,
          organizationId: undefined,
        });
      });
    }
  };

  const { handleSubmit, resetForm, setErrors, handleChange, setFieldValue, values, errors, isValid, isSubmitting, dirty } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    setCategoryList(enumToArray(ProjectCategory));
    organizationStore.getOrganizationList(OrganizationLevel.settingApproval).then((organizationRes) => {
      setOrganizationsList(
        organizationRes?.map((item) => ({
          ...item,
          text: item.name,
          value: item.id,
        }))
      );
    });
  }, [organizationStore]);

  return (
    <DrawerForm
      title={`${selectedSettingApproval ? "Edit" : "Add"} Setting Approval`}
      isOpen={isOpen || !!selectedSettingApproval}
      onClose={() => {
        onClose();
        setSelectedSettingApproval(null);
      }}
      onSubmit={handleSubmit}
      disableAction={!isValid || !dirty}
      isLoading={isSubmitting}
      isEdit={!!selectedSettingApproval}
    >
      <TextField
        select
        name="category"
        value={values.category}
        label="Category"
        onChange={handleChange}
        error={!!errors.category}
        helperText={errors.category}
      >
        <MenuItem value={""}>None</MenuItem>
        {categoryList?.map((item: TSelectProps) =>
          item.text !== "CANCEL_PROJECT" ? (
            <MenuItem key={item.value} value={item.text}>
              {item.text}
            </MenuItem>
          ) : (
            ""
          )
        )}
      </TextField>
      <SelectGroup
        name="organizationId"
        list={organizationsList || []}
        label="Organization"
        defaultData={{
          value: selectedSettingApproval?.organizationId,
          text: selectedSettingApproval?.organizationName,
        }}
        errorData={!!errors.organizationId}
        errorText={errors.organizationId}
        onChangeData={(value: any) => setFieldValue("organizationId", value.id)}
      />
      <CSelect
        options={SETTING_APPROVAL_STATUS.map((item: string) => ({
          label: item,
          value: item,
        }))}
        name="status"
        value={values.status}
        label="Status"
        onChange={handleChange}
      />
      {values.status === SETTING_APPROVAL_STATUS[1] && (
        <Stack direction="row" spacing={"24px"}>
          <NumericFormat
            customInput={TextField}
            label="Min Cost"
            fullWidth
            onKeyDown={(e) => {
              if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                e.preventDefault();
              }
            }}
            value={values.minCost}
            error={!!errors?.minCost}
            helperText={errors?.minCost}
            thousandSeparator={true}
            prefix={"Rp "}
            onValueChange={(e) => {
              setFieldValue(`minCost`, e.floatValue);
            }}
            name={`cost`}
          />
          <NumericFormat
            customInput={TextField}
            label="Max Cost"
            fullWidth
            value={values.maxCost}
            error={!!errors?.maxCost}
            helperText={errors?.maxCost}
            thousandSeparator={true}
            prefix={"Rp "}
            onValueChange={(e) => {
              setFieldValue(`maxCost`, e.floatValue);
            }}
            name={`cost`}
          />
        </Stack>
      )}

      {values.status === SETTING_APPROVAL_STATUS[0] && (
        <Stack direction="row" spacing={"24px"}>
          <FormControlLabel
            control={<Checkbox checked={values.isReviewerLead} name="isReviewerLead" onChange={handleChange} />}
            label="Ketua Reviewer"
          />
        </Stack>
      )}
    </DrawerForm>
  );
}

export default observer(SettingApprovalForm);
