import { Button, Dialog, DialogContent, Stack, TextField, Typography } from "@mui/material";
import DraggableFileUpload, { DraggableFileUploadData } from "../../../../app/component/input/DraggableFileUpload";
import { Dispatch, SetStateAction, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import useQuery from "../../../../app/hook/useQuery";
import { history } from "../../../..";
import Connector from "../../../../signalr-connection";

function CancelProjectDialog({
  isOpen,
  onClose,
  projectCategory,
  isLoading,
  setIsLoading,
}: {
  isLoading: boolean;
  onClose: () => any;
  isOpen: boolean;
  projectCategory: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}) {
  const [data, setData] = useState<DraggableFileUploadData[]>([]);
  const [notes, setNotes] = useState<string>("");
  const { newMessage } = Connector();
  const [error, setError] = useState("");
  const { nextProjectStatus, cancelProject } = useStore().projectManagementStore;
  const { show } = useStore().snackbarStore;
  const query = useQuery();
  const id = query.get("id");

  const onSubmit = async () => {
    if (!id) return;

    setIsLoading(true);

    try {
      const formData = new FormData();

      data.forEach((item, index) => {
        formData.append(`file`, item.file as File);
        formData.append(`fileName`, "fileName");
      });
      formData.append("notes", notes);

      if (nextProjectStatus) {
        await cancelProject(id, formData)
          .then(() => {
            show("success", "Cancel proposal sukses");
            history.push(`/project-monitoring/proposal/list/${projectCategory}`);
            newMessage(new Date().toISOString());
          })
          .finally(() => {
            setIsLoading(false);
          });
      }

      handleClose();

      history.push(`/project-monitoring/proposal/list/${projectCategory}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setData([]);
    setIsLoading(false);

    onClose();
  };

  const handleTextChange = (e: any) => setNotes(e.target.value);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent sx={{ width: "365px" }}>
        <Typography color="black" fontWeight={700} fontSize="14px">
          Warning
        </Typography>
        <Typography mb="16px" color="black" mt="24px">
          Add notes & attach file for cancel project
        </Typography>
        <TextField
          onChange={(e) => handleTextChange(e)}
          sx={{ mb: "12px" }}
          fullWidth
          label="Notes"
          name="notes"
          multiline
          rows={5}
          error={!!error}
          helperText={error}
        />
        <DraggableFileUpload multiple={false} data={data} setData={setData} editableTitle={false} accept="application/pdf, image/*" />
        <Stack mt={2} justifyContent="flex-end" direction="row" gap={1}>
          <Button onClick={handleClose} color="inherit" variant="outlined">
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} loading={isLoading} variant="contained" onClick={onSubmit}>
            Submit
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default observer(CancelProjectDialog);
