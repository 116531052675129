import { useEffect, useMemo, useState } from "react";
import { Add, KeyboardArrowDown, KeyboardArrowUp, VisibilityOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ProjectStatus, TaskSubTaskStatus, isTaskSubTaskDisabled } from "../../../../../../app/config/enum";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadingButton } from "@mui/lab";
import { NumericFormat } from "react-number-format";
import { useFormik } from "formik";
import { IFidTask } from "../../../../../../app/models/fidMilestones";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../../app/stores/store";
import { format } from "date-fns";
import SelectGroup from "../../../../../../app/component/SelectGroup";
import DrawerForm from "../../../../../../app/component/DrawerForm";
import EmptyState from "../../../../../../app/component/EmptyState";
import useQuery from "../../../../../../app/hook/useQuery";
import * as yup from "yup";

function MilestonesTabPreparation() {
  const { getTaskSubTaskGrid, taskSubTask, taskSubTaskLoading } = useStore().milestonetore;
  const { getGeneralInformation, generalInformation } = useStore().proposalStore;
  const { account } = useStore().accountStore;
  const query = useQuery();
  const id = query.get("id");

  useEffect(() => {
    if (id && account) getTaskSubTaskGrid(id, account, generalInformation?.projectOwnerName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTaskSubTaskGrid, id]);

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
  }, [getGeneralInformation, id]);

  return (
    <Box>
      {generalInformation && (
        <Stack
          mb={3}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ backgroundColor: ({ palette }) => palette.primary.light }}
          p={2}
          borderRadius={2}
        >
          <Typography fontSize="20px" fontWeight={700} sx={{ color: ({ palette }) => palette.primary.main }}>
            {generalInformation?.title}
          </Typography>
          <Typography fontSize="20px" fontWeight={700} sx={{ color: ({ palette }) => palette.primary.main }}>
            Rp {generalInformation?.cost.toLocaleString()}
          </Typography>
        </Stack>
      )}
      {taskSubTaskLoading ? (
        <Box display="flex" justifyContent="center" py="32px">
          <CircularProgress />
        </Box>
      ) : taskSubTask.data.length === 0 ? (
        <EmptyState />
      ) : (
        taskSubTask.data.map((item, index) => <ListItem projectOwnerName={generalInformation?.projectOwnerName} data={item} key={index} />)
      )}
    </Box>
  );
}

export default observer(MilestonesTabPreparation);

type ListItemProps = {
  data: IFidTask;
  projectOwnerName?: string;
};

type OrgList = {
  value: string;
  id: string;
  level: string;
  name: string;
  child: OrgList[];
};

const ListItem = observer(
  ({
    data: {
      status: taskStatus,
      weightPhysical,
      startDate,
      endDate,
      milestoneId,
      organizationId,
      weightFinancial,
      name,
      organizationName,
      id: taskId,
      subTasks,
    },
    projectOwnerName,
  }: ListItemProps) => {
    const { milestonetore, organizationStore } = useStore();
    const { account } = useStore().accountStore;
    const { generalInformation } = useStore().proposalStore;
    const [currentTask, setCurrentTask] = useState<IFidTask["subTasks"][0]>();
    const [organizationsList, setOrganizationsList] = useState<OrgList[]>([]);
    const [isOpenTaskForm, setIsOpenTaskForm] = useState<boolean>(false);
    const [isOpenAddMOdal, setIsOpenAddMOdal] = useState(false);
    const [open, setIsOpen] = useState(false);
    const toggleExpandCollapse = () => setIsOpen((prev) => !prev);
    const query = useQuery();
    const id = query.get("id");
    const status = query.get("status");

    const initialValues = useMemo(() => {
      if (currentTask) {
        return currentTask;
      } else {
        return {
          tasksId: taskId,
          name: "",
          startDate: new Date(),
          endDate: new Date(),
          weightPhysical: 0,
          weightFinancial: 0,
          status: TaskSubTaskStatus.Perencanaan,
        };
      }
    }, [taskId, currentTask]);

    const initialValuesTask = {
      milestoneId: milestoneId,
      name: name,
      organizationId: organizationId,
      startDate: startDate,
      endDate: endDate,
      weightPhysical: weightPhysical,
      weightFinancial: weightFinancial,
      status: taskStatus,
    };

    const addTaskFormik = useFormik({
      initialValues: initialValuesTask,
      onSubmit: async (values) => {
        if (!id || !account) return;

        await milestonetore.editTask(taskId, values).then(() => {
          milestonetore.getTaskSubTaskGrid(id, account, projectOwnerName);
          setIsOpenTaskForm(false);
          addTaskFormik.resetForm();
        });
      },
      validationSchema: yup.object({
        milestoneId: yup.string().required(),
        name: yup.string().required(),
        startDate: yup.date().required(),
        endDate: yup.date().required(),
        weightPhysical: yup.number().positive().max(100).required(),
        weightFinancial: yup.number().required().positive(),
        status: yup.string().required(),
        organizationId: yup.string().required(),
      }),
      enableReinitialize: true,
    });

    const addSubTaskFormik = useFormik({
      initialValues,
      onSubmit: async (values) => {
        if (!id || !account) return;

        if (currentTask) {
          await milestonetore.editSubTask(currentTask.id, values).then(() => {
            milestonetore.getTaskSubTaskGrid(id, account, projectOwnerName);
            setIsOpenAddMOdal(false);
            addSubTaskFormik.resetForm();
            setCurrentTask(undefined);
          });
        } else {
          await milestonetore.addSubTask(values).then(() => {
            milestonetore.getTaskSubTaskGrid(id, account, projectOwnerName);
            setIsOpenAddMOdal(false);
            addSubTaskFormik.resetForm();
            setCurrentTask(undefined);
          });
        }
      },
      validationSchema: yup.object({
        name: yup.string().required(),
        startDate: yup.date().required(),
        endDate: yup.date().required(),
        weightPhysical: yup.number().positive().max(100).required(),
        weightFinancial: yup.number().required().positive(),
        status: yup.string().required(),
      }),
      enableReinitialize: true,
    });

    const handleOpenEditModal = (item: IFidTask["subTasks"][0]) => {
      setCurrentTask(item);
      setIsOpenAddMOdal(true);
    };

    const handleOpenEditTask = () => {
      setIsOpenTaskForm(true);
    };

    const handleCloseAddSubTask = () => {
      setIsOpenTaskForm(false);
      setIsOpenAddMOdal(false);
      addSubTaskFormik.resetForm();
      addTaskFormik.resetForm();
      addSubTaskFormik.setErrors({
        endDate: undefined,
        id: undefined,
        milestoneId: undefined,
        name: undefined,
        organizationId: undefined,
        organizationName: undefined,
        startDate: undefined,
        status: undefined,
        weightFinancial: undefined,
        weightPhysical: undefined,
      });
      addTaskFormik.setErrors({
        endDate: undefined,
        milestoneId: undefined,
        name: undefined,
        organizationId: undefined,
        startDate: undefined,
        status: undefined,
        weightFinancial: undefined,
        weightPhysical: undefined,
      });
      setCurrentTask(undefined);
    };

    useEffect(() => {
      if (isOpenTaskForm) {
        organizationStore.getOrganizationList().then((organizationRes) => {
          setOrganizationsList(organizationRes?.map((item) => ({ ...item, text: item.name, value: item.id } as any)) ?? []);
        });
      }
    }, [isOpenTaskForm, organizationStore]);

    const { values: taskValues, setFieldValue: taskSetFieldValue } = addTaskFormik
    
    useEffect(() => {
      if (!taskValues.weightFinancial) return
      if (!generalInformation) return
      
      const val = ((taskValues.weightFinancial / generalInformation.cost) * 100).toFixed(2)
      
      taskSetFieldValue('weightPhysical', val)
    }, [taskValues, taskSetFieldValue, generalInformation])

    const { values: subTaskValues, setFieldValue: subTaskSetFieldValue } = addSubTaskFormik
    
    useEffect(() => {
      if (!subTaskValues.weightFinancial) return
      if (!generalInformation) return
      
      const val = ((subTaskValues.weightFinancial / generalInformation.cost) * 100).toFixed(2)
      
      subTaskSetFieldValue('weightPhysical', val)
    }, [subTaskValues, generalInformation, subTaskSetFieldValue])

    return (
      <>
        <Stack direction="row" alignItems="center" gap="8px">
          <Stack
            py={"20px"}
            sx={{
              cursor: "pointer",
              width: "100%",
              justifyContent: "flex-start",
            }}
            component={ButtonBase}
            onClick={toggleExpandCollapse}
          >
            <Stack flex={1} direction="row" spacing={"16px"} justifyContent="space-between" pr={4} sx={{ width: "100%" }}>
              <Stack direction="row" alignItems="center" spacing={"16px"}>
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                <Typography fontWeight={700} color="black">
                  {name}
                  <span style={{ fontWeight: "400" }}>{` - ${organizationName}`}</span>
                </Typography>
              </Stack>
              <Typography fontWeight={700} color="black">
                Rp {weightFinancial ? weightFinancial.toLocaleString() : 0}
              </Typography>
            </Stack>
          </Stack>
          {status === ProjectStatus.ProjectClosing ? (
            ""
          ) : (
            <Stack direction="row" alignItems="center" gap="8px">
              <div>
                <Button onClick={() => handleOpenEditTask()} variant="outlined" color="inherit">
                  Edit
                </Button>
              </div>
              <div>
                <Button
                  disabled={isTaskSubTaskDisabled(status ?? "", account, projectOwnerName)}
                  onClick={() => setIsOpenAddMOdal(true)}
                  variant="contained"
                  sx={{ whiteSpace: "nowrap" }}
                  startIcon={<Add />}
                >
                  Add Sub Task
                </Button>
              </div>
            </Stack>
          )}
        </Stack>
        <Divider />
        <Collapse in={open} timeout="auto" unmountOnExit>
          {subTasks.length > 0 ? (
            <Table>
              <TableHead>
                <TableCell sx={{ fontWeight: 700 }}>Sub Task</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Start Date</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>End Date</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Weight Physical (%)</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Weight Financial (IDR)</TableCell>
                <TableCell />
              </TableHead>
              <TableBody>
                {subTasks.map((item: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      <Chip component={"div"} label={item.status} color="primary" />
                    </TableCell>
                    <TableCell>{format(new Date(item.startDate), "MMM yyyy")}</TableCell>
                    <TableCell>{format(new Date(item.endDate), "MMM yyyy")}</TableCell>
                    <TableCell>{item.weightPhysical}%</TableCell>
                    <TableCell>Rp {item.weightFinancial.toLocaleString()}</TableCell>
                    <TableCell sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <IconButton size="small" color="inherit" onClick={() => handleOpenEditModal(item)}>
                        <VisibilityOutlined />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <EmptyState />
          )}
        </Collapse>
        <DrawerForm
          isOpen={isOpenAddMOdal}
          onClose={handleCloseAddSubTask}
          onSubmit={addSubTaskFormik.handleSubmit}
          isEdit={false}
          title="Add Sub Task"
          disableAction={!addSubTaskFormik.dirty || !addSubTaskFormik.isValid}
          isLoading={addSubTaskFormik.isSubmitting}
          customButton={
            <>
              {currentTask && (
                <LoadingButton
                  variant="outlined"
                  loading={milestonetore.deleteTaskLoading}
                  color="error"
                  disabled={isTaskSubTaskDisabled(status ?? "", account, projectOwnerName)}
                  onClick={() =>
                    currentTask &&
                    milestonetore.deleteSubTask(currentTask.id).then(() => {
                      if (!id || !account) return;

                      milestonetore.getTaskSubTaskGrid(id, account, projectOwnerName);
                      setIsOpenAddMOdal(false);
                      addSubTaskFormik.resetForm();
                    })
                  }
                  type="button"
                >
                  Delete
                </LoadingButton>
              )}
              <LoadingButton
                variant="contained"
                loading={addSubTaskFormik.isSubmitting}
                disabled={!addSubTaskFormik.dirty || !addSubTaskFormik.isValid}
                type="submit"
              >
                {currentTask ? "Edit" : "Add"}
              </LoadingButton>
            </>
          }
        >
          <TextField
            label="Name"
            name="name"
            required
            fullWidth
            disabled={isTaskSubTaskDisabled(status ?? "", account, projectOwnerName)}
            value={addSubTaskFormik.values.name}
            error={!!addSubTaskFormik.errors.name}
            helperText={addSubTaskFormik.errors.name}
            onChange={addSubTaskFormik.handleChange}
          />
          <Stack direction="row" spacing="24px">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                disabled={isTaskSubTaskDisabled(status ?? "", account, projectOwnerName)}
                value={addSubTaskFormik.values.startDate}
                views={["year", "month"]}
                onChange={(e: any) => addSubTaskFormik.setFieldValue("startDate", e)}
                renderInput={(props) => (
                  <TextField
                    required
                    fullWidth
                    name="startDate"
                    error={!!addSubTaskFormik.errors.startDate}
                    helperText={addSubTaskFormik.errors.startDate && String(addSubTaskFormik.errors.startDate)}
                    {...props}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                value={addSubTaskFormik.values.endDate}
                views={["year", "month"]}
                disabled={isTaskSubTaskDisabled(status ?? "", account, projectOwnerName)}
                onChange={(e: any) => addSubTaskFormik.setFieldValue("endDate", e)}
                renderInput={(props) => (
                  <TextField
                    required
                    fullWidth
                    name="endDate"
                    error={!!addSubTaskFormik.errors.endDate}
                    helperText={addSubTaskFormik.errors.endDate && String(addSubTaskFormik.errors.endDate)}
                    {...props}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
          <TextField
            label="Weight Physical (%)"
            name="weightPhysical"
            required
            disabled
            onKeyDown={(e) => {
              if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                e.preventDefault();
              }
            }}
            fullWidth
            value={addSubTaskFormik.values.weightPhysical}
            error={!!addSubTaskFormik.errors.weightPhysical}
            helperText={addSubTaskFormik.errors.weightPhysical}
            onChange={addSubTaskFormik.handleChange}
            type="number"
          />
          <NumericFormat
            customInput={TextField}
            label="Weight Financial"
            name="weightFinancial"
            disabled={isTaskSubTaskDisabled(status ?? "", account, projectOwnerName)}
            required
            onKeyDown={(e) => {
              if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                e.preventDefault();
              }
            }}
            fullWidth
            value={addSubTaskFormik.values.weightFinancial}
            error={!!addSubTaskFormik.errors?.weightFinancial}
            helperText={addSubTaskFormik.errors?.weightFinancial}
            thousandSeparator={true}
            onValueChange={(e) => {
              addSubTaskFormik.setFieldValue(`weightFinancial`, e.floatValue);
            }}
            prefix="Rp "
          />
          {/* <CSelect
            options={Object.keys(TaskSubTaskStatus).map((i) => ({
              label: i === "InProgress" ? "In Progress" : i,
              value: (TaskSubTaskStatus as any)[i],
            }))}
            label="Status"
            name="status"
            value={addSubTaskFormik.values.status}
            error={!!addSubTaskFormik.errors.status}
            helperText={addSubTaskFormik.errors.status}
            onChange={addSubTaskFormik.handleChange}
          /> */}
        </DrawerForm>

        {/* Add Task */}
        <DrawerForm
          isOpen={isOpenTaskForm}
          onClose={handleCloseAddSubTask}
          onSubmit={addTaskFormik.handleSubmit}
          isEdit={false}
          title="Edit Task"
          disableAction={!addTaskFormik.dirty || !addTaskFormik.isValid}
          isLoading={addTaskFormik.isSubmitting}
          customButton={
            <>
              <LoadingButton
                variant="contained"
                loading={addTaskFormik.isSubmitting}
                disabled={!addTaskFormik.dirty || !addTaskFormik.isValid}
                type="submit"
              >
                Edit
              </LoadingButton>
            </>
          }
        >
          <TextField
            label="Name"
            name="name"
            disabled={isTaskSubTaskDisabled(status ?? "", account, projectOwnerName)}
            required
            fullWidth
            value={addTaskFormik.values.name}
            error={!!addTaskFormik.errors.name}
            helperText={addTaskFormik.errors.name}
            onChange={addTaskFormik.handleChange}
          />
          <Stack direction="row" spacing="24px">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                views={["year", "month"]}
                disabled={isTaskSubTaskDisabled(status ?? "", account, projectOwnerName)}
                value={addTaskFormik.values.startDate}
                onChange={(e: any) => addTaskFormik.setFieldValue("startDate", e)}
                renderInput={(props) => (
                  <TextField
                    required
                    fullWidth
                    name="startDate"
                    error={!!addTaskFormik.errors.startDate}
                    helperText={addTaskFormik.errors.startDate && String(addTaskFormik.errors.startDate)}
                    {...props}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                disabled={isTaskSubTaskDisabled(status ?? "", account, projectOwnerName)}
                value={addTaskFormik.values.endDate}
                views={["year", "month"]}
                onChange={(e: any) => addTaskFormik.setFieldValue("endDate", e)}
                renderInput={(props) => (
                  <TextField
                    required
                    fullWidth
                    name="endDate"
                    error={!!addTaskFormik.errors.endDate}
                    helperText={addTaskFormik.errors.endDate && String(addTaskFormik.errors.endDate)}
                    {...props}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
          <SelectGroup
            name="organizationId"
            disabled={isTaskSubTaskDisabled(status ?? "", account, projectOwnerName)}
            list={organizationsList || []}
            label="Organization"
            defaultData={{ value: organizationId, text: organizationName }}
            errorData={!!addTaskFormik.errors.organizationId}
            errorText={addTaskFormik.errors.organizationId}
            onChangeData={(value: any) => addTaskFormik.setFieldValue("organizationId", value.id)}
          />
          <TextField
            label="Weight Physical (%)"
            disabled
            name="weightPhysical"
            required
            onKeyDown={(e) => {
              if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                e.preventDefault();
              }
            }}
            fullWidth
            value={addTaskFormik.values.weightPhysical}
            error={!!addTaskFormik.errors.weightPhysical}
            helperText={addTaskFormik.errors.weightPhysical}
            onChange={addTaskFormik.handleChange}
            type="number"
          />
          <NumericFormat
            customInput={TextField}
            disabled={isTaskSubTaskDisabled(status ?? "", account, projectOwnerName)}
            label="Weight Financial"
            name="weightFinancial"
            required
            onKeyDown={(e) => {
              if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                e.preventDefault();
              }
            }}
            fullWidth
            value={addTaskFormik.values.weightFinancial}
            error={!!addTaskFormik.errors?.weightFinancial}
            helperText={addTaskFormik.errors?.weightFinancial}
            thousandSeparator={true}
            onValueChange={(e) => {
              addTaskFormik.setFieldValue(`weightFinancial`, e.floatValue);
            }}
            prefix="Rp "
          />
          {/* <CSelect
            options={Object.keys(TaskSubTaskStatus).map((i) => ({
              label: i === "InProgress" ? "In Progress" : i,
              value: (TaskSubTaskStatus as any)[i],
            }))}
            label="Status"
            name="status"
            value={addTaskFormik.values.status}
            error={!!addTaskFormik.errors.status}
            helperText={addTaskFormik.errors.status}
            onChange={addTaskFormik.handleChange}
          /> */}
        </DrawerForm>
      </>
    );
  }
);
