import { ReactElement, useEffect, useMemo } from "react";
import {
  AttachFile,
  AttachMoneyRounded,
  AutoGraphRounded,
  FactCheckOutlined,
  HandshakeOutlined,
  HandymanOutlined,
  InfoOutlined,
  MapRounded,
  QueryStatsOutlined,
  Villa,
} from "@mui/icons-material";
import { Box, Grid, Stack } from "@mui/material";
import { ProjectCategory } from "../../app/config/enum";
import { FillFormItem } from "../projectMonitoring/proposal/FillFormItem";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import useQuery from "../../app/hook/useQuery";

type DrawerState = {
  generalInformation: boolean;
  land: boolean;
  productAnalysis: boolean;
  marketAnalysis: boolean;
  costplanAnalysis: boolean;
  economyAnalysis: boolean;
  collaborationAnalysis: boolean;
  permission: boolean;
  fid: boolean;
  kajianFs: boolean;
  technicalAnalysis: boolean;
  legal: boolean;
  risk: boolean;
  scopeOfWork: boolean;
  backgroundObjectiveBD: boolean;
  backgroundObjective: boolean;
  timeline: boolean;
  investmentBudget: boolean;
  costBenefit: boolean;
  drawdown: boolean;
  psRiskMitigation: boolean;
  contructor: boolean;
  attachment: boolean;
};

type FormList = {
  text: string;
  icon: ReactElement;
  name: keyof DrawerState;
  added: boolean;
  disabled?: boolean;
};

type TProposalFillFormProps = {
  isHistoryView?: boolean;
  category?: string;
  proposalId?: string;
};

function ApprovalFillPage({ proposalId, category, isHistoryView = true }: TProposalFillFormProps) {
  const query = useQuery();
  const status = query.get("status");

  const {
    getGeneralInformation,
    resetGeneralInformation,
    generalInformationLoading,
    generalInformation,
    getLand,
    resetLand,
    land,
    landLoading,
    permission,
    permissionLoading,
    getPermission,
    resetPermission,
    productAnalisysLoading,
    productAnalysis,
    getProductAnalysis,
    resetProductAnalysis,
    getMarketAnalysis,
    resetMarketAnalysis,
    marketAnalysis,
    marketAnalysisLoading,
    costplanAnalysis,
    costplanAnalysisLoading,
    getCostplanAnalysis,
    resetCostplanAnalysis,
    collaborationAnalysis,
    collaborationAnalysisLoading,
    resetCollaborationAnalysis,
    getCollaborationAnalysis,
    economyAnalysis,
    economyAnalysisLoading,
    getEconomyAnalysis,
    resetEconomyAnalysis,
    legal,
    legalLoading,
    getLegal,
    resetLegal,
    getRisk,
    risk,
    riskLoading,
    resetRisk,
    psBackgroundObjective,
    psBackgroundObjectiveLoading,
    getPsBackgroundObjective,
    resetPsBackgroundObjective,
    getScopeOfWork,
    scopeOfWork,
    scopeOfWorkLoading,
    resetScopeOfWork,
    backgroundObjective,
    backgroundObjectiveLoading,
    resetBackgroundObjective,
    getBackgroundObjective,
    timeline,
    timelineLoading,
    getTimeline,
    resetTimeline,
    investmentBudget,
    investmentBudgetLoading,
    getInvestmentBudget,
    resetInvestmentBudget,
    costBenefit,
    costBenefitLoading,
    getCostBenefit,
    resetCostBenefit,
    drawdown,
    drawdownLoading,
    resetDrawdown,
    getDrawdown,
    constructors,
    constructorsLoading,
    getConstructor,
    resetConstructor,
    psRiskMitigation,
    psRiskMitigationLoading,
    getPsRiskMitigation,
    resetPsRiskMitigation,
    technicalAnalysis,
    technicalAnalysisLoading,
    getTechnicalAnalysis,
    resetTechnicalAnalysis,
    getKajianFs,
    resetKajianFs,
    attachment,
    attachmentLoading,
    kajianFs,
    kajianFsLoading,
    getAttachment,
    resetAttachment,
  } = useStore().proposalStore;

  const generalFormList: FormList[] = useMemo(
    () => [
      // {
      //   text: "Vendor Info",
      //   icon: <HandymanOutlined />,
      //   name: "contructor",
      //   added: !!constructors && constructors.length > 0,
      //   disabled: !generalInformation || constructorsLoading,
      // },
      {
        text: "Attachment",
        icon: <AttachFile />,
        name: "attachment",
        added: !!attachment && attachment.length > 0,
        disabled: !generalInformation || attachmentLoading,
      },
    ],
    [attachment, attachmentLoading, generalInformation]
  );

  const feasibilityStudyFormList: FormList[] = useMemo(
    () => [
      {
        text: "General Information",
        icon: <InfoOutlined />,
        name: "generalInformation",
        added: !!generalInformation,
        disabled: generalInformationLoading,
      },
      // {
      //   text: "Latar Belakang dan Tujuan",
      //   icon: <InfoOutlined />,
      //   name: "backgroundObjectiveBD",
      //   added: !!backgroundObjective && backgroundObjective.length > 0,
      //   disabled: !generalInformation || backgroundObjectiveLoading,
      // },
      // {
      //   icon: <MapRounded />,
      //   text: "Lahan",
      //   name: "land",
      //   added: !!land && land.length > 0,
      //   disabled: !generalInformation || landLoading,
      // },
      // {
      //   icon: <Villa />,
      //   text: "Analisis Produk",
      //   name: "productAnalysis",
      //   added: !!productAnalysis && productAnalysis.length > 0,
      //   disabled: !generalInformation || productAnalisysLoading,
      // },
      // {
      //   icon: <AutoGraphRounded />,
      //   text: "Analisis Pasar",
      //   name: "marketAnalysis",
      //   added: !!marketAnalysis && marketAnalysis.length > 0,
      //   disabled: !generalInformation || marketAnalysisLoading,
      // },
      {
        icon: <AttachMoneyRounded />,
        text: "Analisis Costplan",
        name: "costplanAnalysis",
        added: !!costplanAnalysis && costplanAnalysis.length > 0,
        disabled: !generalInformation || costplanAnalysisLoading,
      },
      // {
      //   // TODO:
      //   icon: <AttachMoneyRounded />,
      //   text: "Analisis Teknik",
      //   name: "technicalAnalysis",
      //   added: !!technicalAnalysis && technicalAnalysis.length > 0,
      //   disabled: !generalInformation || technicalAnalysisLoading,
      // },
      {
        icon: <QueryStatsOutlined />,
        text: "Analisis Keekonomian",
        name: "economyAnalysis",
        added: !!economyAnalysis && economyAnalysis.length > 0,
        disabled: !generalInformation || economyAnalysisLoading,
      },
      // {
      //   icon: <HandshakeOutlined />,
      //   text: "Analisis Kerjasama",
      //   name: "collaborationAnalysis",
      //   added: !!collaborationAnalysis && collaborationAnalysis.length > 0,
      //   disabled: !generalInformation || collaborationAnalysisLoading,
      // },
      {
        icon: <FactCheckOutlined />,
        text: "Kajian Resiko",
        name: "risk",
        added: !!risk && risk.length > 0,
        disabled: !generalInformation || riskLoading,
      },
      {
        icon: <HandshakeOutlined />,
        text: "Kajian Legal",
        name: "legal",
        added: !!legal && legal.length > 0,
        disabled: !generalInformation || legalLoading,
      },
      {
        icon: <FactCheckOutlined />,
        text: "Kajian Perizinan",
        name: "permission",
        added: !!permission && permission.length > 0,
        disabled: !generalInformation || permissionLoading,
      },
      {
        text: "Kajian FS",
        icon: <AttachFile />,
        name: "kajianFs",
        added: !!kajianFs,
        disabled: !generalInformation || kajianFsLoading,
      },
    ],
    [
      generalInformation,
      generalInformationLoading,
      costplanAnalysis,
      costplanAnalysisLoading,
      economyAnalysis,
      economyAnalysisLoading,
      risk,
      riskLoading,
      legal,
      legalLoading,
      permission,
      permissionLoading,
      kajianFs,
      kajianFsLoading,
    ]
  );

  const projectSummaryFormList = useMemo(() => {
    return [
      {
        text: "General Information",
        icon: <InfoOutlined />,
        name: "generalInformation",
        added: !!generalInformation,
        disabled: generalInformationLoading,
      },
      // {
      //   text: "Latar Belakang dan Tujuan",
      //   icon: <InfoOutlined />,
      //   name: "backgroundObjective",
      //   added: !!psBackgroundObjective && psBackgroundObjective.length > 0,
      //   disabled: !generalInformation || psBackgroundObjectiveLoading,
      // },
      // {
      //   text: "Lingkup Pekerjaan",
      //   icon: <InfoOutlined />,
      //   name: "scopeOfWork",
      //   added: !!scopeOfWork && scopeOfWork.length > 0,
      //   disabled: !generalInformation || scopeOfWorkLoading,
      // },
      // {
      //   text: "Tata Waktu",
      //   icon: <InfoOutlined />,
      //   name: "timeline",
      //   added: !!timeline && timeline.length > 0,
      //   disabled: !generalInformation || timelineLoading || scopeOfWorkLoading || (scopeOfWork as any)?.length === 0,
      // },
      {
        text: "Rincian Nilai Investasi",
        icon: <InfoOutlined />,
        name: "investmentBudget",
        added: !!investmentBudget && investmentBudget.length > 0 && !!investmentBudget[0]?.budget && investmentBudget.length > 0,
        disabled:
          !generalInformation ||
          timelineLoading ||
          // scopeOfWorkLoading ||
          // (scopeOfWork as any)?.length === 0 ||
          (timeline as any)?.length === 0 ||
          investmentBudgetLoading,
      },
      // {
      //   text: "Drawdown",
      //   icon: <InfoOutlined />,
      //   name: "drawdown",
      //   // added: !!costBenefit && (costBenefit.length > 0 && !!costBenefit[0]?.benefit && !!costBenefit[0]?.cost) && costBenefit.length > 0,
      //   // disabled: !generalInformation || timelineLoading || scopeOfWorkLoading || ((scopeOfWork as any)?.length === 0) || (timeline as any)?.length === 0 || costBenefitLoading,
      //   added: !!drawdown && drawdown.length > 0,
      //   disabled: !generalInformation || drawdownLoading,
      // },
      // {
      //   text: "Cost and Benefit Analysis",
      //   icon: <InfoOutlined />,
      //   name: "costBenefit",
      //   added: !!costBenefit && costBenefit.length > 0 && !!costBenefit[0]?.benefit && !!costBenefit[0]?.cost && costBenefit.length > 0,
      //   disabled:
      //     !generalInformation ||
      //     timelineLoading ||
      //     scopeOfWorkLoading ||
      //     (scopeOfWork as any)?.length === 0 ||
      //     (timeline as any)?.length === 0 ||
      //     costBenefitLoading,
      // },
      // {
      //   text: "Resiko",
      //   icon: <InfoOutlined />,
      //   name: "psRiskMitigation",
      //   added: !!psRiskMitigation && psRiskMitigation.length > 0,
      //   disabled: !generalInformation || psRiskMitigationLoading,
      // },
      {
        text: "Kajian PS",
        icon: <AttachFile />,
        name: "kajianFs",
        added: !!kajianFs,
        disabled: !generalInformation || kajianFsLoading,
      },
    ];
  }, [
    generalInformation,
    generalInformationLoading,
    investmentBudget,
    timelineLoading,
    timeline,
    investmentBudgetLoading,
    kajianFs,
    kajianFsLoading,
  ]);

  useEffect(() => {
    if (!proposalId) return;

    getConstructor(proposalId);
    getPsBackgroundObjective(proposalId);
    getAttachment(proposalId);
    getGeneralInformation(proposalId);
    getLand(proposalId);
    getProductAnalysis(proposalId);
    getMarketAnalysis(proposalId);
    getCostplanAnalysis(proposalId);
    getCollaborationAnalysis(proposalId);
    getEconomyAnalysis(proposalId);
    getRisk(proposalId);
    getLegal(proposalId);
    getPermission(proposalId);
    getTimeline(proposalId);
    getScopeOfWork(proposalId);
    getBackgroundObjective(proposalId);
    getInvestmentBudget(proposalId);
    getCostBenefit(proposalId);
    getDrawdown(proposalId);
    getPsRiskMitigation(proposalId);
    getTechnicalAnalysis(proposalId);
    getKajianFs(proposalId);

    return () => {
      resetLand();
      resetProductAnalysis();
      resetMarketAnalysis();
      resetCostplanAnalysis();
      resetPsBackgroundObjective();
      resetCollaborationAnalysis();
      resetEconomyAnalysis();
      resetRisk();
      resetLegal();
      resetTimeline();
      resetBackgroundObjective();
      resetPermission();
      resetScopeOfWork();
      resetInvestmentBudget();
      resetCostBenefit();
      resetDrawdown();
      // resetConstructor();
      resetPsRiskMitigation();
      resetTechnicalAnalysis();
      resetKajianFs();
      // resetAttachment();
    };
  }, [
    getAttachment,
    resetAttachment,
    getGeneralInformation,
    resetTechnicalAnalysis,
    getPsBackgroundObjective,
    resetPsBackgroundObjective,
    getTechnicalAnalysis,
    getConstructor,
    resetPsRiskMitigation,
    getPsRiskMitigation,
    resetConstructor,
    getDrawdown,
    resetDrawdown,
    getCostBenefit,
    resetCostBenefit,
    getInvestmentBudget,
    resetInvestmentBudget,
    getProductAnalysis,
    getPermission,
    resetPermission,
    getLegal,
    resetLegal,
    resetTimeline,
    resetGeneralInformation,
    resetProductAnalysis,
    resetLand,
    resetMarketAnalysis,
    getMarketAnalysis,
    getLand,
    query,
    resetRisk,
    getRisk,
    getTimeline,
    getCostplanAnalysis,
    resetCostplanAnalysis,
    getCollaborationAnalysis,
    resetCollaborationAnalysis,
    resetEconomyAnalysis,
    getEconomyAnalysis,
    resetScopeOfWork,
    getScopeOfWork,
    getBackgroundObjective,
    resetBackgroundObjective,
    status,
    proposalId,
    getKajianFs,
    resetKajianFs,
  ]);

  const currentForm = useMemo(() => {
    if (category === ProjectCategory.BD || category === ProjectCategory.Anorganik) {
      return feasibilityStudyFormList;
    } else if (category === ProjectCategory.NonBD) {
      return projectSummaryFormList;
    }
  }, [category, feasibilityStudyFormList, projectSummaryFormList]);

  return (
    <>
      {currentForm && (
        <Grid container spacing={"24px"}>
          <Grid item md={6}>
            <Stack spacing="16px">
              {currentForm.slice(0, 4).map((i) => (
                <Link style={{ textDecoration: "none" }} to={`/project-monitoring/approval/list/${proposalId}/${i.name}?status=${status}`}>
                  <FillFormItem {...i} />
                </Link>
              ))}
            </Stack>
          </Grid>
          <Grid item md={6}>
            <Stack spacing="16px">
              {currentForm.slice(4, 12).map((i) => (
                <Link style={{ textDecoration: "none" }} to={`/project-monitoring/approval/list/${proposalId}/${i.name}?status=${status}`}>
                  <FillFormItem {...i} />
                </Link>
              ))}
            </Stack>
          </Grid>
        </Grid>
      )}
      <Box sx={{ my: "24px" }}>
        <hr />
      </Box>
      <Box>
        <Grid container spacing={3}>
          {generalFormList.map((item) => (
            <Grid item xs={6} key={item.name}>
              <Link style={{ textDecoration: "none" }} to={`/project-monitoring/approval/list/${proposalId}/${item.name}?status=${status}`}>
                <FillFormItem {...item} />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default observer(ApprovalFillPage);
