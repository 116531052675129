import { IconButton, Paper, Stack } from "@mui/material";
import { StyledDataGrid } from "../../app/component/StyledDataGrid";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Delete, Edit } from "@mui/icons-material";

function UnitBusinessCategoryDataFrid() {
  const { unitBusinessCategoryStore, dialogStore } = useStore()
  const { getUnitBusinessCategoryGrid, queryParams, setQueryParams, unitBusinessCategoryGridLoading, setSelectedUnitBusinessCategory, deleteUnitBusinessCategory, unitBusinessCategoryGrid } = unitBusinessCategoryStore
  
  const onDelete = (id: string) => dialogStore.open({
    action: async () => await deleteUnitBusinessCategory(id).then(getUnitBusinessCategoryGrid),
    actionText: 'Delete',
    title: 'Delete',
    closeText: 'Cancel',
    actionButtonProps: {
      color: 'error'
    },
    description: 'Are you sure want to delete this item?'
  })
  
  useEffect(() => {
    getUnitBusinessCategoryGrid()
  }, [getUnitBusinessCategoryGrid])
  
  return (
    <Paper sx={{p: '16px'}}>
      <StyledDataGrid 
        // checkboxSelection 
        loading={unitBusinessCategoryGridLoading}
        rows={unitBusinessCategoryGrid.data}
        rowCount={unitBusinessCategoryGrid.rowCount}
        pagination
        onPageChange={v => setQueryParams('currentPage', v)}
        onPageSizeChange={v => setQueryParams('pageSize', v)}
        paginationMode="server"
        pageSize={queryParams.pageSize}
        page={queryParams.currentPage}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            flex: 1
          },
          {
            field: 'action',
            headerName: 'Action',
            width: 100,
            sortable: false,
            filterable: false,
            disableColumnMenu: true, 
            resizable: false,
            renderCell: (params) => (
              <Stack direction="row" spacing="8px">
                <IconButton size="small" onClick={() => setSelectedUnitBusinessCategory(params.row as any)}>
                  <Edit />
                </IconButton>
                <IconButton size="small" onClick={() => onDelete(params.row.id)}>
                  <Delete />
                </IconButton>
              </Stack>
            )
          }
        ]}
        disableSelectionOnClick
        autoHeight
      />
    </Paper>
  )
}

export default observer(UnitBusinessCategoryDataFrid)
