import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import ExampleList from './ExampleList';
import { useStore } from '../../app/stores/store';

function ExamplePage() {
  const { commonStore } = useStore();
  useEffect(() => {
    commonStore.setTitlePage('Example Page',);
  });
  return (
    <>    
      <Grid container>      
        <Grid item xs={12}>
          <ExampleList />
        </Grid>
      </Grid>
    </>
  );
}

export default observer(ExamplePage);
