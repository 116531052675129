import { useEffect, useMemo, useRef, useState } from "react";
import { Paper, Box, Tab, FormControl, TextField, Grid, Autocomplete, Checkbox } from "@mui/material";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import * as yup from "yup";
import { CheckBoxOutlineBlank, CheckBox, FileDownloadOutlined, FileUploadOutlined } from "@mui/icons-material";
import { PreviewDataExcel } from "../../app/models/fidMilestones";
import { ExcelDataGridPreview } from "./excel-data-grid-preview";
import CSelect from "../../app/component/CSelect";
import { useFormik } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { PLAN_TYPE, ProjectCategory } from "../../app/config/enum";
import { format } from "date-fns";
import { TProjectsListResponse } from "../../app/models/recentProject";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const planPreview = [PLAN_TYPE.FINANCIAL, PLAN_TYPE.PHISYCAL];
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 400,
    },
  },
};

function UploadAdmin() {
  const { uploadPlanProjectStore, projectCategoryAllStore, DashboardStore, dialogStore, snackbarStore } = useStore();
  const { getProjectCategoryAll, projectCategoryAll } = projectCategoryAllStore;
  const {
    downloadTemplateUploadProjectAdmin,
    uploadTemplateUploadProjectAdmin,
    loadingUploadProjectAdmin,
    setProjectAdminPreviewGrid,
    submitTemplateUploadProjectAdmin,
    projectAdminPreviewGrid,
  } = uploadPlanProjectStore;
  const { projectsList, getProjectsList } = DashboardStore;
  const [value, setValue] = useState("1");
  const [date, setDate] = useState<Date[]>([new Date(), new Date()]);
  const [yearNbd, setYearNbd] = useState<Date>(new Date());
  const [selectedMultipleProjectName, setSelectedMultipleProjectName] = useState<TProjectsListResponse[]>([]);
  const [excelDataPreview, setExcelDataPreview] = useState<PreviewDataExcel[]>([]);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => setValue(newValue);
  const hiddenFileInput = useRef<any>(null);

  const { values, handleChange } = useFormik({
    initialValues: {
      projectCategory: "",
      projectName: "",
      startDate: new Date(),
      endDate: new Date(),
      year: new Date(),
    },
    validationSchema: {
      projectCategory: yup.string(),
      projectName: yup.string(),
      startDate: yup.date(),
      endDate: yup.date(),
      year: yup.date(),
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const isNbd = useMemo(() => {
    let temp = projectCategoryAll.find((item) => item.id === values.projectCategory)?.category;

    if (temp === ProjectCategory.NonBD) return true;

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.projectCategory]);

  const handleDownloadTemplate = () => {
    if (!values.projectCategory) return;

    let payload = {
      proposalId: selectedMultipleProjectName.map((item) => item.id),
      startDate: date[0],
      endDate: date[1],
      year: format(new Date(yearNbd), "yyyy"),
      category: projectCategoryAll.find((item) => item.id === values.projectCategory)?.category ?? "",
    };

    downloadTemplateUploadProjectAdmin(payload);
  };

  const handleUploadTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target.files && e?.target.files.length > 0) {
      const formData = new FormData();

      formData.append("file", e?.target.files[0]);

      uploadTemplateUploadProjectAdmin(formData).then(() => {
        snackbarStore.show("success", `upload data success`);
      });
    }
  };

  const onChangeDate = (newDate: Date, identifier: string) => {
    let temp = [...date];

    if (identifier === "startDate") temp[0] = newDate;
    if (identifier === "endDate") temp[1] = newDate;

    setDate(temp);
  };

  useEffect(() => {
    getProjectCategoryAll();

    if (!values.projectCategory) return;

    let projectsListPayload = {
      organizationId: "",
      projectCategory: values.projectCategory,
      year: 0,
    };

    getProjectsList(projectsListPayload);
    setSelectedMultipleProjectName([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.projectCategory]);

  const successCallback = () => {
    snackbarStore.show("success", "submit data success");
    setProjectAdminPreviewGrid(undefined);
    setSelectedMultipleProjectName([]);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <DashboardLayoutHeader
          breadcrumbs={[
            {
              label: "Upload Plan Project for Non Business Development from this Page",
              href: "/upload-admin",
            },
          ]}
          pageTitle="Upload Plan"
        />
        <Paper sx={{ p: "16px" }}>
          <Grid container spacing="12px" sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={4} sx={{ display: "flex", gap: "6px" }}>
              <FormControl sx={{ width: "100%" }}>
                <CSelect
                  sx={{ height: "53px" }}
                  value={values.projectCategory}
                  onChange={handleChange}
                  label="Project Category"
                  name="projectCategory"
                  size="small"
                  options={projectCategoryAll.map((item) => ({ value: item.id, label: item.name }))}
                />
              </FormControl>
            </Grid>
            {isNbd ? (
              <>
                <Grid item xs={4} sx={{ display: "flex", gap: "6px" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Year"
                      views={["year"]}
                      value={yearNbd}
                      inputFormat="yyyy"
                      onChange={(e: any) => setYearNbd(e)}
                      renderInput={(props) => <TextField required fullWidth name="startDate" {...props} />}
                    />
                  </LocalizationProvider>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={4} sx={{ display: "flex", gap: "6px" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Start Date"
                      views={["year", "month"]}
                      value={date[0]}
                      inputFormat="MMM yyyy"
                      onChange={(e: any) => onChangeDate(e, "startDate")}
                      renderInput={(props) => <TextField required fullWidth name="startDate" {...props} />}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="End Date"
                      views={["year", "month"]}
                      value={date[1]}
                      inputFormat="MMM yyyy"
                      onChange={(e: any) => onChangeDate(e, "endDate")}
                      renderInput={(props) => <TextField required fullWidth name="endDate" {...props} />}
                    />
                  </LocalizationProvider>
                </Grid>
              </>
            )}

            <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", alignContent: "center" }}>
              <Box sx={{ display: "flex", gap: "12px" }}>
                <LoadingButton
                  loading={loadingUploadProjectAdmin}
                  disabled={!selectedMultipleProjectName.length}
                  onClick={handleDownloadTemplate}
                  variant="contained"
                  startIcon={<FileDownloadOutlined />}
                >
                  Download
                </LoadingButton>
                <LoadingButton
                  loading={loadingUploadProjectAdmin}
                  sx={{ whiteSpace: "nowrap" }}
                  component="label"
                  variant="contained"
                  startIcon={<FileUploadOutlined />}
                >
                  Upload
                  <input
                    ref={hiddenFileInput}
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    hidden
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUploadTemplate(e)}
                  />
                </LoadingButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                sx={{ width: "100%" }}
                multiple
                value={selectedMultipleProjectName}
                onChange={(event, newValue) => {
                  if (newValue.find((option) => option.all))
                    return setSelectedMultipleProjectName(selectedMultipleProjectName.length === projectsList.length ? [] : projectsList);

                  setSelectedMultipleProjectName(newValue);
                }}
                options={projectsList}
                getOptionLabel={(option) => option?.name}
                disableCloseOnSelect
                filterOptions={(options: TProjectsListResponse[], params: any) => {
                  const filteredOptions = options.filter((option) => {
                    const query = params.inputValue.toLowerCase();
                    return option.name.toLowerCase().includes(query);
                  });
                  return [{ name: "Select All", all: true, id: "select-all", description: "Select All" }, ...filteredOptions];
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={option.all ? !!(selectedMultipleProjectName.length === projectsList.length) : selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={isNbd ? "Mata Anggaran" : "Projects Name"}
                    placeholder={isNbd ? "Mata Anggaran" : "Projects Name"}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box sx={{ width: "100%", typography: "body1", my: "24px" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                  {planPreview.map((item, index) => (
                    <Tab label={item} value={(index + 1).toString()} />
                  ))}
                </TabList>
              </Box>
              {planPreview.map((item, index) => (
                <TabPanel sx={{ color: "black" }} value={(index + 1).toString()}>
                  <ExcelDataGridPreview setExcelDataPreview={setExcelDataPreview} identifier={item} />
                </TabPanel>
              ))}
            </TabContext>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={loadingUploadProjectAdmin}
              onClick={() =>
                dialogStore.open({
                  title: "Submit Excel Data",
                  description: `Are you sure you want to submit this data?`,
                  closeText: "Cancel",
                  action: async () => {
                    await submitTemplateUploadProjectAdmin(projectAdminPreviewGrid).then(successCallback);
                  },
                  actionText: "Submit",
                })
              }
              type="submit"
              variant="contained"
              color="primary"
            >
              Submit
            </LoadingButton>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default observer(UploadAdmin);
