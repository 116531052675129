import { IconButton, Paper, Stack, TextField } from "@mui/material";
import { StyledDataGrid } from "../../app/component/StyledDataGrid";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Delete, Edit, Search } from "@mui/icons-material";
import { useDebounce } from "../../app/hook/useDebounce";

function ProjectCategoryDataGrid() {
  const { projectCategoryNBDStore, dialogStore } = useStore()
  const { getProjectCategoryNBDGrid, projectCategoryNBDGrid, setQueryParams, queryparams, deleteProjectCategoryNBD, projectCategoryNBDLoading, setSelectedProjectCategoryNBD } = projectCategoryNBDStore
  
  const handleSearch = useDebounce(2000, (e) => setQueryParams('search', e.target.value))
  
  const onDelete = (id: string) => dialogStore.open({
    action: async () => await deleteProjectCategoryNBD(id).then(getProjectCategoryNBDGrid),
    actionText: 'Delete',
    title: 'Delete',
    closeText: 'Cancel',
    actionButtonProps: {
      color: 'error'
    },
    description: 'Are you sure want to delete this item?'
  })
  
  useEffect(() => {
    getProjectCategoryNBDGrid()
  }, [getProjectCategoryNBDGrid])
  
  return (
    <Paper sx={{p: '16px'}}>
      <TextField 
        fullWidth
        placeholder="Search" 
        InputProps={{ startAdornment: <Search /> }} 
        sx={{ mb: '24px' }}
        onChange={handleSearch}
      />
      <StyledDataGrid 
        // checkboxSelection 
        loading={projectCategoryNBDLoading}
        rows={projectCategoryNBDGrid.data}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
          },
          {
            field: 'action',
            headerName: 'Action',
            width: 100,
            sortable: false,
            filterable: false,
            disableColumnMenu: true, 
            resizable: false,
            renderCell: (params) => (
              <Stack direction="row" spacing="8px">
                <IconButton size="small" onClick={() => setSelectedProjectCategoryNBD(params.row as any)}>
                  <Edit />
                </IconButton>
                <IconButton size="small" onClick={() => onDelete(params.row.id)}>
                  <Delete />
                </IconButton>
              </Stack>
            )
          }
        ]}
        disableSelectionOnClick
        autoHeight
        pagination
        pageSize={queryparams.pageSize}
        page={queryparams.currentPage}
        onPageSizeChange={(v) => setQueryParams('pageSize', v)}
        paginationMode="server"
        onPageChange={v => setQueryParams('currentPage', v)}
        rowCount={projectCategoryNBDGrid.rowCount}
      />
    </Paper>
  )
}

export default observer(ProjectCategoryDataGrid)
