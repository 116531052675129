import { Box, Tab, Tabs } from "@mui/material";
import MyTabPanel from "../../../../../app/component/TabPanel";
import { useEffect, useState } from "react";
import a11yProps from "../../../../../app/utils/a11yProps";
import MilestonesTab from "./milestone-tab";
import useQuery from "../../../../../app/hook/useQuery";
import { ProjectStatus, Roles } from "../../../../../app/config/enum";
import ProgressRealizationTab from "./tabs/ProgressRealizationTab";
import CostRealizationTab from "./tabs/CostRealizationTab";
import WbsTab from "./tabs/wbsTab";
import MilestonesTabPreparation from "./tabs/MilestonesTabPreparation";
import { useStore } from "../../../../../app/stores/store";
import Consolidation from "./tabs/Consolidation";
import { SKTLTab } from "./tabs/SKTLTab";
import AmandementPageIndex from "./tabs/AmandementTab/AmandementPageIndex";
import ABI from "./tabs/ABITab/ABI";

type TFidTab = {
  fromPage?: string;
};

export default function FidTab({ fromPage = "" }: TFidTab) {
  const statusForPreaparationForm: string[] = [
    ProjectStatus.Preparation,
    ProjectStatus.Procurement,
    ProjectStatus.Construction,
    ProjectStatus.ProjectClosing,
    ProjectStatus.CancelProject,
  ];
  const [tabIndex, setTabIndex] = useState(0);
  const query = useQuery();
  const status = query.get("status");
  const { progressRealization } = useStore().progressRealizationStore;
  const { generalInformation } = useStore().proposalStore;
  const { account } = useStore().accountStore;

  const filterFIDDisplay = () => {
    if (!account || !generalInformation) return;

    if (account?.roles.includes(Roles.Superadmin) || account.displayName === generalInformation.projectOwnerName) {
      return <MilestonesTab />;
    } else {
      return <MilestonesTabPreparation />;
    }
  };

  const renderTabsFID = () => (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabIndex} onChange={(_, v) => setTabIndex(v)} aria-label="basic tabs example">
          <Tab label="FID" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <MyTabPanel value={tabIndex} index={0}>
        <MilestonesTab />
      </MyTabPanel>
    </>
  );

  const renderTabs = () => {
    const hideProgress = !progressRealization.filter((i) => !!i.tasks.length).length;

    const tabs = hideProgress ? (
      <Tabs value={tabIndex} onChange={(_, v) => setTabIndex(v)} aria-label="basic tabs example">
        <Tab label="FID" {...a11yProps(0)} />
        <Tab label="WBS" {...a11yProps(1)} />
        <Tab label="Cost Realization" {...a11yProps(2)} />
      </Tabs>
    ) : (
      <Tabs value={tabIndex} onChange={(_, v) => setTabIndex(v)} aria-label="basic tabs example">
        <Tab label="FID" {...a11yProps(0)} />
        <Tab label="WBS" {...a11yProps(3)} />
        <Tab label="Progress Realization" {...a11yProps(1)} />
        <Tab label="Cost Realization" {...a11yProps(2)} />
      </Tabs>
    );
    const panel = hideProgress ? (
      <>
        <MyTabPanel value={tabIndex} index={0}>
          {statusForPreaparationForm.includes(status ?? "") ? <MilestonesTabPreparation /> : <MilestonesTab />}
        </MyTabPanel>
        <MyTabPanel value={tabIndex} index={1}>
          <WbsTab />
        </MyTabPanel>
        <MyTabPanel value={tabIndex} index={2}>
          <CostRealizationTab />
        </MyTabPanel>
      </>
    ) : (
      <>
        <MyTabPanel value={tabIndex} index={0}>
          {statusForPreaparationForm.includes(status ?? "") ? <MilestonesTabPreparation /> : <MilestonesTab />}
        </MyTabPanel>
        <MyTabPanel value={tabIndex} index={1}>
          <WbsTab />
        </MyTabPanel>
        <MyTabPanel value={tabIndex} index={2}>
          <ProgressRealizationTab />
        </MyTabPanel>
        <MyTabPanel value={tabIndex} index={3}>
          <CostRealizationTab />
        </MyTabPanel>
      </>
    );

    return { tabs, panel };
  };

  const renderTabsPreparation = () => (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabIndex} onChange={(_, v) => setTabIndex(v)} aria-label="basic tabs example">
          <Tab label="Budget Plan" {...a11yProps(0)} />
          <Tab label="WBS" {...a11yProps(3)} />
          <Tab label="Progress Realization" {...a11yProps(1)} />
          <Tab label="SKTL" {...a11yProps(2)} />
          <Tab label="Consolidation" {...a11yProps(4)} />
          <Tab label="Amandement" {...a11yProps(5)} />
          <Tab label="ABI" {...a11yProps(6)} />
        </Tabs>
      </Box>
      <MyTabPanel value={tabIndex} index={0}>
        {filterFIDDisplay()}
      </MyTabPanel>
      <MyTabPanel value={tabIndex} index={1}>
        <WbsTab />
      </MyTabPanel>
      <MyTabPanel value={tabIndex} index={2}>
        <ProgressRealizationTab />
      </MyTabPanel>
      <MyTabPanel value={tabIndex} index={3}>
        <SKTLTab />
      </MyTabPanel>
      <MyTabPanel value={tabIndex} index={4}>
        <Consolidation />
      </MyTabPanel>
      <MyTabPanel value={tabIndex} index={5}>
        <AmandementPageIndex />
      </MyTabPanel>
      <MyTabPanel value={tabIndex} index={6}>
        <ABI />
      </MyTabPanel>
    </>
  );

  useEffect(() => {
    // set tab index to sktl
    if (fromPage === "dashboard") {
      setTabIndex(3);
    } else {
      setTabIndex(0);
    }
  }, [fromPage]);

  return (
    <>
      {(status === ProjectStatus.Fid && renderTabsFID()) ||
        (statusForPreaparationForm.includes(status ?? "") && renderTabsPreparation()) ||
        renderTabsFID()}
    </>
  );
}
