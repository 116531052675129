import { useCallback, useEffect } from "react";
import { Grid, Paper, Skeleton, TextField, Typography, Box } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { ReviewerStatus, UserAction } from "../../app/config/enum";
import { useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";

function ChangeRequestDetail() {
  const { changeRequestStore } = useStore();
  const { getChangeRequestDetail, changeRequestDetail, changeRequestDetailLoading, keysHelper, resetChangeRequestDetail, userAction } =
    changeRequestStore;
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;

    getChangeRequestDetail(id);

    return () => resetChangeRequestDetail();
  }, [getChangeRequestDetail, id, resetChangeRequestDetail]);

  const changeRequestButton = useCallback(() => {
    const handleUserAction = (action: string) => {
      if (!id) return;
      userAction(id, action).then(() => getChangeRequestDetail(id));
    };

    if (changeRequestDetail?.status === "SUBMITTED") {
      return (
        <>
          <DashboardLayoutHeader.Button variant="contained" color="error" startIcon={<Close />} onClick={() => handleUserAction(UserAction.Refused)}>
            Refuse
          </DashboardLayoutHeader.Button>
          <DashboardLayoutHeader.Button
            variant="contained"
            color="success"
            startIcon={<Check />}
            onClick={() => handleUserAction(UserAction.Approved)}
          >
            Approve
          </DashboardLayoutHeader.Button>
        </>
      );
    }

    if (changeRequestDetail?.status === ReviewerStatus.Approved) {
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          {format(new Date(changeRequestDetail?.dateApproved), "dd MMM yyyy - hh:mm")}
          <DashboardLayoutHeader.Button variant="contained" disabled startIcon={<Check />}>
            Approved
          </DashboardLayoutHeader.Button>
        </Box>
      );
    }

    if (changeRequestDetail?.status === ReviewerStatus.Refused) {
      return (
        <DashboardLayoutHeader.Button variant="contained" disabled startIcon={<Check />}>
          Refused
        </DashboardLayoutHeader.Button>
      );
    }
  }, [changeRequestDetail, getChangeRequestDetail, id, userAction]);

  return (
    <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Change Request",
            href: "/change-request",
          },
          {
            label: "Change Request List",
            href: "/change-request",
          },
          {
            label: "Approval Change",
          },
        ]}
        pageTitle="Change Request"
      >
        {changeRequestButton()}
        {/* {changeRequestDetail?.status === "APPROVED" ? (
          <DashboardLayoutHeader.Button
            variant="contained"
            color="success"
            disabled
            startIcon={<Check />}
            onClick={() => handleUserAction(UserAction.Approved)}
          >
            Approved
          </DashboardLayoutHeader.Button>
        ) : (
          <>
            <DashboardLayoutHeader.Button
              variant="contained"
              color="error"
              startIcon={<Close />}
              // onClick={() => setSelectedLand(landDetail)}
            >
              Refuse
            </DashboardLayoutHeader.Button>
            <DashboardLayoutHeader.Button
              variant="contained"
              color="success"
              startIcon={<Check />}
              onClick={() => handleUserAction(UserAction.Approved)}
            >
              Approve
            </DashboardLayoutHeader.Button>
          </>
        )} */}
      </DashboardLayoutHeader>

      <Paper sx={{ p: "16px" }}>
        {changeRequestDetailLoading ? (
          <LoadingSection />
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Box sx={{ fontSize: "20px", fontWeight: "bold", mb: "24px" }}>Before</Box>
              <Grid container spacing={2}>
                {keysHelper.map((item: any) => (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      disabled
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor:
                            changeRequestDetail?.previousValue[item] !== changeRequestDetail?.nextValue[item] ? "#7CC444" : "#000000",
                        },
                      }}
                      label={item}
                      defaultValue={changeRequestDetail?.nextValue[item]}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ fontSize: "20px", fontWeight: "bold", mb: "24px" }}>After</Box>
              <Grid container spacing={2}>
                {keysHelper.map((item: any) => (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor:
                            changeRequestDetail?.previousValue[item] !== changeRequestDetail?.nextValue[item] ? "#7CC444" : "#000000",
                        },
                      }}
                      label={item}
                      defaultValue={changeRequestDetail?.previousValue[item]}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Paper>
      <Box sx={{ py: "16px" }} />
    </Box>
  );
}

export default observer(ChangeRequestDetail);

const LoadingSection = () => {
  return (
    <Grid container spacing={"24px"}>
      <Grid item md={6}>
        <Typography fontSize="20px" fontWeight={700} color="black" mb="16px">
          Before
        </Typography>
        <Skeleton variant="rounded" width="100%" height={56} />
      </Grid>
      <Grid item md={6}>
        <Typography fontSize="20px" fontWeight={700} color="black" mb="16px">
          After
        </Typography>
        <Skeleton variant="rounded" width="100%" height={56} />
      </Grid>
    </Grid>
  );
};
