import { useEffect, useState } from "react";
import { ISKTL } from "../../../../../../app/models/sktl";
import useQuery from "../../../../../../app/hook/useQuery";
import agent from "../../../../../../app/api/agent";
import {  Typography } from "@mui/material";
import SKTLDataGrid from "./SKTLDataGrid";

export const SKTLTab = () => {
  const [sktl, setSktl] = useState<ISKTL[]>([]);
  const query = useQuery();
  const id = query.get("id");

  // useEffect(() => {
  //   if (!id) return;

  //   agent.Proposal.getSKTL(id).then((res) => {
  //     setSktl(res);
  //   });
  // }, [id]);

  return (
    <>
      <Typography sx={{ mt: "24px", mb: "24px" }} color="black" fontWeight={700} fontSize="20px">
        SKTL
      </Typography>
      <SKTLDataGrid />
    </>
  );
};
