import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../../../app/stores/store";
import { useEffect } from "react";
import useQuery from "../../../../../../../app/hook/useQuery";

export type TABIGrid = {
  year: string;
};

function ABIGrid({ year }: TABIGrid) {
  const { abiStore } = useStore();
  const query = useQuery();
  const proposalId = query.get("id");

  useEffect(() => {
    if (!proposalId) return;

    abiStore.getAbiGrid(proposalId, Number(year));
  }, [abiStore, proposalId, year]);

  return (
    <TableContainer style={{ borderWidth: "1px", borderColor: "grey", borderStyle: "solid" }}>
      <Table>
        <TableHead>
          <TableCell sx={{ fontWeight: 700 }}></TableCell>
          <TableCell sx={{ fontWeight: 700 }}>
            <Box sx={{ justifyContent: "center" }}>Organization</Box>
          </TableCell>
          <TableCell sx={{ fontWeight: 700, textAlign: "center" }}>Status</TableCell>
          <TableCell sx={{ fontWeight: 700 }}>Start Date</TableCell>
          <TableCell sx={{ fontWeight: 700 }}>End Date</TableCell>
          <TableCell sx={{ fontWeight: 700 }}>Nominal Pagu</TableCell>
          <TableCell sx={{ fontWeight: 700 }}>Realisasi</TableCell>
          <TableCell sx={{ fontWeight: 700 }}>Percentage to ABI</TableCell>
        </TableHead>
        {abiStore.abiGrid.map((item: any) => (
          <TableBody>
            <TableRow>
              <TableCell>
                <Box
                  sx={{
                    fontWeight: item.type !== "SubTask" ? "bold" : "",
                    color: item.type === "Task" ? "#494949" : item.type === "SubTask" ? "" : "black",
                    width: "150px",
                    paddingLeft: item.type === "Task" ? "24px" : item.type === "SubTask" ? "48px" : "",
                  }}
                >
                  {item.name}
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ textAlign: "center" }}>{item.organization}</Box>
              </TableCell>
              <TableCell>
                <Box sx={{ textAlign: "center" }}>{item.status}</Box>
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>{item.startDate ? format(new Date(item.startDate), "MMM yyyy") : "-"}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>{item.endDate ? format(new Date(item.endDate), "MMM yyyy") : "-"}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Rp{item.nominalPagu.toLocaleString()}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Rp{item.realisasi.toLocaleString()}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                {item.percentageToAbi}
                {item.percentageToAbi ? "%" : "0%"}
              </TableCell>
            </TableRow>
          </TableBody>
        ))}
      </Table>
    </TableContainer>
  );
}

export default observer(ABIGrid);
