import { makeAutoObservable, runInAction } from "mobx";
import { IProgressRealization, IProgressRealizationBody, IProgressRealizationHistory, TNewProgressRealization } from "../models/progressRealization";
import agent from "../api/agent";
import { IFidMilestoneQueryParams } from "../models/fidMilestones";
import { DataGridResult } from "../models/materialUI/dataGrid";
import { IAccountInfo } from "../models/account";
import { Roles } from "../config/enum";

export class ProgressRealizationStore {
  progressRealization: IProgressRealization[] = [];
  progressRealizationLoading = false;

  newProgressRealization: DataGridResult<TNewProgressRealization[]> = new DataGridResult([], 0);
  newProgressRealizationLoading = false;

  physicalActualProgress: any[] = [];
  physicalActualProgressLoading = false;

  history: IProgressRealizationHistory[] | null = null;
  historyLoading = false;

  queryParams: IFidMilestoneQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  getProgressRealization = async (proposalId: string) => {
    this.progressRealizationLoading = true;
    try {
      const res = await agent.FID.getProgressRealization(proposalId);

      runInAction(() => (this.progressRealization = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.progressRealizationLoading = false));
    }
  };

  getNewProgressRealization = async (proposalId: string, account: IAccountInfo, projectOwner?: string) => {
    this.newProgressRealizationLoading = true;
    let organizationIdHelper =
      account.roles.includes(Roles.Procurement) || account.displayName === projectOwner || account.displayName === Roles.Superadmin
        ? ""
        : account.organizationId;

    try {
      const res = await agent.FID.getNewProgressRealization(proposalId, this.queryParams, organizationIdHelper);

      runInAction(() => (this.newProgressRealization = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.newProgressRealizationLoading = false));
    }
  };

  deletePhysicalActualProgress = async (id: string) => {
    try {
      await agent.FID.deletePhysicalActualProgress(id);
    } catch (e) {
      throw e;
    }
  };

  getPhysicalActualProgress = async (subTaskId: string, tasksId: string) => {
    this.physicalActualProgressLoading = true;

    try {
      const res = await agent.FID.getPhysicalActualProgress(subTaskId, tasksId);

      return res;
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.physicalActualProgressLoading = false));
    }
  };

  addProgressRealization = async (payload: IProgressRealizationBody) => {
    this.progressRealizationLoading = true;
    try {
      await agent.FID.addProgressRealization(payload);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.progressRealizationLoading = false));
    }
  };

  editProgressRealization = async (physicalId: string, payload: IProgressRealizationBody) => {
    this.progressRealizationLoading = true;
    try {
      await agent.FID.editProgressRealization(physicalId, payload);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.progressRealizationLoading = false));
    }
  };

  getProgressRealizationHistory = async (physicalId: string) => {
    this.historyLoading = true;
    try {
      const res = await agent.FID.getProgressRealizationHistory(physicalId);
      runInAction(() => (this.history = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.historyLoading = false));
    }
  };
  clearHistory = async () => {
    this.history = null;
  };
}
