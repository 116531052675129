import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IProjectCategoryNBD } from "../models/projectCategoryNDB";
import { DataGridResult } from "../models/materialUI/dataGrid";

export class ProjectCategoryNBDStore {
  projectCategoryNBD: IProjectCategoryNBD[] = []
  projectCategoryNBDGrid: DataGridResult<IProjectCategoryNBD[]> = new DataGridResult([], 0)
  
  projectCategoryNBDLoading = false;
  
  selectedProjectCategoryNBD: IProjectCategoryNBD | null = null
  
  queryparams: any = {
    currentPage: 0,
    pageSize: 25,
    search: ''
  }
  
  constructor() {
    makeAutoObservable(this)
  }

  getProjectCategoryNBD = async () => {
    this.projectCategoryNBDLoading = true
    try {
      const res = await agent.Master.getProjectCategoryNBD()

      runInAction(() => this.projectCategoryNBD = res);
    } catch (e) {
      throw e
    } finally {
      runInAction(() => this.projectCategoryNBDLoading = false)
    }
  }

  getProjectCategoryNBDGrid = async () => {
    this.projectCategoryNBDLoading = true
    try {
      const res = await agent.Master.getProjectCategoryNBDGrid()

      runInAction(() => this.projectCategoryNBDGrid = res);
    } catch (e) {
      throw e
    } finally {
      runInAction(() => this.projectCategoryNBDLoading = false)
    }
  }

  addProjectCategoryNBD = async (body: any) => {
    try {
      await agent.Master.addProjectCategoryNBD(body)
    } catch (e) {
      throw e;
    }
  }

  editProjectCategoryNBD = async (id: string, body: any) => {
    try {
      await agent.Master.editProjectCategoryNBD(id, body)
    } catch (e) {
      throw e;
    }
  }

  setSelectedProjectCategoryNBD = (data: IProjectCategoryNBD | null) => {
    this.selectedProjectCategoryNBD = data;
  }

  deleteProjectCategoryNBD = async (id: string) => {
    try {
      await agent.Master.deleteProjectCategoryNBD(id)
    } catch (e) {
      throw e;
    }
  }

  setQueryParams = (query: 'pageSize' | 'search' | 'currentPage', v: string | number) => {
    this.queryparams = {
      ...this.queryparams,
      [query]: v,
    }
  }
}