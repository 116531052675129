import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { DataGridResult } from "../models/materialUI/dataGrid";
import { IAssetCategory, IAssetCategoryBody, IAssetCategoryQueryParams } from "../models/assetCategory";

export class AssetCategoryStore {
  assetCategoryGrid: DataGridResult<IAssetCategory[]> = new DataGridResult([], 0)
  assetCategoryGridLoading = false;
  
  assetCategory: IAssetCategory[] = []
  assetCategoryLoading = false
  
  selectedAssetCategory: IAssetCategory | null = null
  
  queryparams: IAssetCategoryQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: ''
  }
  
  constructor() {
    makeAutoObservable(this)

    reaction(() => this.queryparams, () => {
      this.getAssetCategoryGrid()
    })
  }

  getAssetCategoryGrid = async () => {
    this.assetCategoryGridLoading = true
    try {
      const res = await agent.Master.getAssetCategoryGrid(this.queryparams)

      runInAction(() => this.assetCategoryGrid = res);
    } catch (e) {
      throw e
    } finally {
      runInAction(() => this.assetCategoryGridLoading = false)
    }
  }

  getAssetCategory = async () => {
    this.assetCategoryLoading = true
    try {
      const res = await agent.Master.getAssetCategory()

      runInAction(() => this.assetCategory = res);
    } catch (e) {
      throw e
    } finally {
      runInAction(() => this.assetCategoryLoading = false)
    }
  }

  addAssetCategory = async (body: IAssetCategoryBody) => {
    try {
      await agent.Master.addAssetCategory(body)
    } catch (e) {
      throw e;
    }
  }

  editAssetCategory = async (id: string, body: IAssetCategoryBody) => {
    try {
      await agent.Master.editAssetCategory(id, body)
    } catch (e) {
      throw e;
    }
  }

  setSelectedAssetCategory = (data: IAssetCategory | null) => {
    this.selectedAssetCategory = data;
  }

  deleteAssetCategory = async (id: string) => {
    try {
      await agent.Master.deleteAssetCategory(id)
    } catch (e) {
      throw e;
    }
  }

  setQueryParams = (query: 'pageSize' | 'search' | 'currentPage', v: string | number) => {
    this.queryparams = {
      ...this.queryparams,
      [query]: v,
    }
  }
}