import { capitalize, BaseTextFieldProps, InputAdornment, TextField } from "@mui/material";
import { useField } from "formik";


export interface RkdTextInputProps extends BaseTextFieldProps {
    startAdornment?: string,
    endAdornment?: string
}

interface Adornments {
    startAdornment?: JSX.Element,
    endAdornment?: JSX.Element,
}

export default function RkdTextInput(props: RkdTextInputProps) {
    const [field, meta] = useField(props.name!);
    const {startAdornment, endAdornment, ...otherProps} = props;

    let adornments: Adornments = {};
    if (startAdornment) {
        adornments.startAdornment = <InputAdornment position="start">{startAdornment}</InputAdornment>;
    }
    if (endAdornment) {
        adornments.endAdornment = <InputAdornment position="end">{endAdornment}</InputAdornment>;
    }

    return (
        <TextField
            variant="outlined"
            error={meta.touched && !!meta.error }
            helperText={meta.error && capitalize(meta.error)}
            InputProps={{...adornments}}
            {...field} {...otherProps}
        />
    );
}