import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";

export interface IDataGridParams {
    currentPage: number,
    pageSize: number,
    sortModel: GridSortModel,
    filterModel: GridFilterModel | null
}

export interface IDataGridResult<T> {
    rowCount: number,
    data: T
}

export class DataGridResult<T> implements IDataGridResult<T> {
    data;
    rowCount = 0;

    constructor(data: T, rowCount: number) {
        this.data = data;
        this.rowCount = rowCount;
    }
}