import { Add, Search, FileDownloadOutlined, Lock, LockOpen, FileUploadOutlined } from "@mui/icons-material";
import { Box, Button, CircularProgress, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../../app/stores/store";
import EmptyState from "../../../../../../app/component/EmptyState";
import useQuery from "../../../../../../app/hook/useQuery";
import { observer } from "mobx-react-lite";
import { useDebounce } from "../../../../../../app/hook/useDebounce";
import { IFidMilestone } from "../../../../../../app/models/fidMilestones";
import { ProjectCategory, ProjectStatus, Roles } from "../../../../../../app/config/enum";
import { AddItemFormDrawer } from "./add-item-form-drawer";
import { AddMilestoneDialog } from "./add-milestone-dialog";
import { MilestoneList } from "./milestone-list";
import { RequestReFidDialog } from "./request-refid-dialog";
import { history } from "../../../../../..";
import AddPerencanaanPembayaran from "./add-perencanaan-pembayaran";
import ViewPerencanaanPembayaran from "./view-edit-perencanaan-pembayaran";
import AddPerencanaanProgress from "./add-perencanaan-progress";
import ImportDialog from "./import-dialog";
import DownloadDialog from "./download-dialog";
import ChooseImportDialog from "./choose-import-dialog";
import ChartDialog from "./chart-dialog";

type TMilestonesTab = {
  isCapex?: boolean;
};

function MilestonesTab({ isCapex = false }: TMilestonesTab) {
  const { proposalStore, milestonetore, proposalMemberStore, accountStore, dialogStore, abiStore } = useStore();
  const {
    getMilestone,
    setQueryParams,
    milestonesLoading,
    milestones,
    getTotalMilestonestasks,
    loadingDownloadTemplate,
    clearSelectedFinancialPlan,
    clearSelectedPhysicalPlan,
  } = milestonetore;
  const { getGeneralInformation, generalInformation } = proposalStore;
  const { member, getMember } = proposalMemberStore;
  const { account } = accountStore;
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [subtaskToEdit, setSubtaskToEdit] = useState<IFidMilestone["tasks"][0]["subTasks"][0]>();
  const [dialogTypePembayaran, setDialogTypePembayaran] = useState<"task" | "subtask">();
  const [dialogTypeProgress, setDialogTypeProgress] = useState<"task" | "subtask">();
  const [drawerPembayaran, setDrawerTypePembayaran] = useState<"task" | "subtask">();
  const [isOpenImportDialog, setIsOpenImportDialog] = useState<boolean>(false);
  const [taskToEdit, setTaskToEdit] = useState<IFidMilestone["tasks"][0]>();
  const [addMilestoneDialog, setAddMilestoneDialog] = useState(false);
  const [openChooseImportDialog, setOpenChooseImportDialog] = useState(false);
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
  const [reFidDialogOpen, setReFidDialogOpen] = useState(false);
  const [formType, setFormType] = useState<"task" | "subtask">();
  const [parentId, setParentId] = useState<string>();
  const query = useQuery();
  const id = query.get("id");
  const status = query.get("status");
  const projectCategory = query.get("projectCategory");

  const isDrawerPembayaranOpen = !!drawerPembayaran;
  const closeDrawerPembayaran = () => {
    clearSelectedPhysicalPlan();
    clearSelectedFinancialPlan();
    setDrawerTypePembayaran(undefined);
  };

  const onCloseImportDialog = () => setIsOpenImportDialog(false);

  const isDialogProgressOpen = !!dialogTypeProgress;
  const closeDialogProgress = () => {
    setDialogTypeProgress(undefined);
  };

  const isDialogPembayaranOpen = !!dialogTypePembayaran;
  const closeDialogPembayaran = () => {
    setDialogTypePembayaran(undefined);
  };

  const isFormDrawerOpen = !!formType;
  const closeFormDrawer = () => {
    setFormType(undefined);
    setParentId(undefined);
  };
  const closeDialog = () => {
    setAddMilestoneDialog(false);
    setParentId(undefined);
  };

  const handleSearch = useDebounce(2000, (v) => setQueryParams("search", v.target.value));

  const readOnly = useMemo(() => {
    const currentMember = member.find((i) => i.name === account?.displayName);

    return generalInformation?.projectOwnerName !== account?.displayName && !account?.roles.includes(Roles.Superadmin) && !currentMember;
  }, [generalInformation, account, member]);

  const displayRequestReFID = useMemo(() => {
    return (
      status === ProjectStatus.Construction ||
      status === ProjectStatus.DecisionGate ||
      status === ProjectStatus.Preparation ||
      status === ProjectStatus.Procurement
    );
  }, [status]);

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
    getMember(id);
    getMilestone(id);
    getTotalMilestonestasks(id);
  }, [getGeneralInformation, getMember, getMilestone, getTotalMilestonestasks, id]);

  return (
    <Box>
      <Stack mb="24px" direction="row" spacing="12px">
        <TextField
          fullWidth
          InputProps={{
            startAdornment: <Search />,
          }}
          onChange={handleSearch}
          placeholder="Search"
        />
        {status === ProjectStatus.ProjectClosing ? (
          ""
        ) : (
          <>
            <Button onClick={() => setAddMilestoneDialog(true)} sx={{ whiteSpace: "nowrap" }} disabled={readOnly} startIcon={<Add />}>
              Add Milestone
            </Button>
            {!isCapex ? (
              <>
                <Button
                  disabled={loadingDownloadTemplate}
                  onClick={() => setOpenDownloadDialog(true)}
                  variant="outlined"
                  startIcon={<FileDownloadOutlined />}
                >
                  Choose Template
                </Button>
                <Button
                  disabled={loadingDownloadTemplate}
                  onClick={() => setOpenChooseImportDialog(true)}
                  variant="outlined"
                  startIcon={<FileUploadOutlined />}
                >
                  Import
                </Button>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </Stack>
      {generalInformation && (
        <Stack direction="row" spacing={2} mb={3}>
          <Stack
            flex={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ backgroundColor: ({ palette }) => palette.primary.light }}
            p={2}
            borderRadius={2}
          >
            <Typography fontSize="20px" fontWeight={700} sx={{ color: ({ palette }) => palette.primary.main }}>
              {generalInformation?.title}
            </Typography>
            <Typography fontSize="20px" fontWeight={700} sx={{ color: ({ palette }) => palette.primary.main }}>
              <Stack flex={1} direction="row" spacing={"16px"} justifyContent="" pr={4} sx={{ textAlign: "end", width: "100%" }}>
                <Box>
                  <Box>Rp {generalInformation?.cost.toLocaleString()}</Box>
                  <Box sx={{ fontSize: "11px" }}>
                    (remaining balance: Rp{milestones.data.reduce((partialSum, a) => partialSum + a.remainingBalance, 0).toLocaleString()})
                  </Box>
                </Box>
              </Stack>
            </Typography>
          </Stack>
          {displayRequestReFID && (
            <Button
              variant="outlined"
              onClick={() =>
                history.push(`/project-monitoring/proposal/list/${projectCategory}/request-refid?proposalId=${id}&title=${generalInformation.title}`)
              }
            >
              Request {projectCategory === ProjectCategory.NonBD ? "Re-PS" : "Re-FID"}
            </Button>
          )}
        </Stack>
      )}
      {milestonesLoading ? (
        <Box display="flex" justifyContent="center" py="32px">
          <CircularProgress />
        </Box>
      ) : milestones.data.length === 0 ? (
        <EmptyState />
      ) : (
        milestones.data.map((item, index) => (
          <MilestoneList
            setDrawerTypePembayaran={setDrawerTypePembayaran}
            setDialogTypeProgress={setDialogTypeProgress}
            setDialogTypePembayaran={setDialogTypePembayaran}
            setParentId={setParentId}
            setFormType={setFormType}
            setIsOpenDialog={setIsOpenDialog}
            setSubtaskToEdit={setSubtaskToEdit}
            setTaskToEdit={setTaskToEdit}
            taskToEdit={taskToEdit}
            readOnly={readOnly}
            milestone={item}
            key={index}
          />
        ))
      )}
      <AddMilestoneDialog readOnly={readOnly} isOpen={addMilestoneDialog} onClose={closeDialog} />
      <AddItemFormDrawer
        readOnly={readOnly}
        subTaskToEdit={subtaskToEdit}
        formType={formType}
        isOpen={isFormDrawerOpen}
        parentId={parentId}
        taskToEdit={taskToEdit}
        onClose={closeFormDrawer}
        setTaskToEdit={setTaskToEdit}
        setSubtaskToEdit={setSubtaskToEdit}
      />
      <AddPerencanaanPembayaran
        onClose={closeDialogPembayaran}
        task={taskToEdit}
        subtask={subtaskToEdit}
        dialogTypePembayaran={dialogTypePembayaran}
        isOpenPerencanaanPembayaran={isDialogPembayaranOpen}
      />
      <AddPerencanaanProgress
        onClose={closeDialogProgress}
        task={taskToEdit}
        subtask={subtaskToEdit}
        dialogTypeProgress={dialogTypeProgress}
        isOpenPerencanaanProgress={isDialogProgressOpen}
      />
      <ViewPerencanaanPembayaran
        drawerPembayaran={drawerPembayaran}
        task={taskToEdit}
        setDialogTypePembayaran={setDialogTypePembayaran}
        subtask={subtaskToEdit}
        setDialogTypeProgress={setDialogTypeProgress}
        onClose={closeDrawerPembayaran}
        isOpenDrawer={isDrawerPembayaranOpen}
      />
      <ImportDialog onClose={onCloseImportDialog} isOpenImportDialog={isOpenImportDialog} />
      <RequestReFidDialog isOpen={reFidDialogOpen} setIsOpen={setReFidDialogOpen} />
      <DownloadDialog openDialog={openDownloadDialog} setOpenDialog={setOpenDownloadDialog} />
      <ChooseImportDialog
        openDialog={openChooseImportDialog}
        setOpenDialog={setOpenChooseImportDialog}
        setIsOpenImportDialog={setIsOpenImportDialog}
      />
      {isOpenDialog ? <ChartDialog taskToEdit={taskToEdit} isOpenDialog={isOpenDialog} setIsOpenDialog={setIsOpenDialog} /> : undefined}
    </Box>
  );
}

export default observer(MilestonesTab);
