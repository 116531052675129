import { FileUploadOutlined } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useStore } from "../../../../../../app/stores/store";
import useQuery from "../../../../../../app/hook/useQuery";

type TDownloadDialog = {
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  setIsOpenImportDialog: Dispatch<SetStateAction<boolean>>;
};

function ChooseImportDialog({ openDialog, setOpenDialog, setIsOpenImportDialog }: TDownloadDialog) {
  const { milestonetore } = useStore();
  const { uploadExcelMilestones, uploadExcelPlan, loadingUploadProduct, setTemplateIdentifier } = milestonetore;
  const query = useQuery();
  const id = query.get("id");
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const descriptionElementRef = useRef<HTMLElement>(null);

  const handleClose = () => setOpenDialog(false);

  const successCallback = (identifier: string) => {
    setIsOpenImportDialog(true);
    setOpenDialog(false);
    setTemplateIdentifier(identifier);
  };

  return (
    <Dialog maxWidth="xl" open={openDialog} onClose={handleClose}>
      <DialogTitle id="scroll-dialog-title">Import Template</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <Box sx={{ gap: "12px", display: "flex" }}>
            <Button
              disabled={loadingUploadProduct}
              sx={{ whiteSpace: "nowrap" }}
              component="label"
              variant="outlined"
              startIcon={<FileUploadOutlined />}
            >
              Import Milestones Template
              <input
                disabled={loadingUploadProduct}
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                hidden
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (!id) return;
                  if (e.target.files && e.target.files.length > 0) {
                    uploadExcelMilestones(e.target.files[0], id).then(() => successCallback("milestones"));
                  }
                }}
                name="postScreenshotFile"
              />
            </Button>
            <Button
              disabled={loadingUploadProduct}
              sx={{ whiteSpace: "nowrap" }}
              component="label"
              variant="outlined"
              startIcon={<FileUploadOutlined />}
            >
              Import Plan Template
              <input
                disabled={loadingUploadProduct}
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                hidden
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (!id) return;
                  if (e.target.files && e.target.files.length > 0) {
                    uploadExcelPlan(e.target.files[0], id).then(() => successCallback("plan"));
                  }
                }}
                name="postScreenshotFile"
              />
            </Button>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default observer(ChooseImportDialog);
