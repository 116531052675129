import { ProjectStatus } from "../../../../app/config/enum";
import { observer } from "mobx-react-lite";
import { Box } from "@mui/material";
import ProjectClosingStepForm from "./stepform/ProjectClosingStepForm";
import PreparationStepForm from "./stepform/PreparationStepForm";
import ProposalStepForm from "./stepform/ProposalStepForm";
import useQuery from "../../../../app/hook/useQuery";

function ProposalGeneralInformationTab() {
  const query = useQuery();
  const status = query.get("status");

  const statusForPreaparationForm: string[] = [
    ProjectStatus.Preparation,
    ProjectStatus.Procurement,
    ProjectStatus.Construction,
  ];

  return (
    <>
      {status === ProjectStatus.ProjectClosing ? (
        <ProjectClosingStepForm />
      ) : (
        <Box>
          {statusForPreaparationForm.includes(status ?? "") ? (
            <PreparationStepForm />
          ) : (
            <ProposalStepForm />
          )}
        </Box>
      )}
    </>
  );
}

export default observer(ProposalGeneralInformationTab);
