import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Collapse, Drawer, IconButton, Stack } from "@mui/material";
import { Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import { TFidMilestoneSubtask, TFidMilestoneTask } from "../../../../../../app/models/fidMilestones";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../../app/stores/store";
import PembayaranCollapse from "./view-perencanaan-pembayaran-collapse";
import ProgressCollapse from "./view-perencanaan-progress-collapse";

type TViewPerencanaanPembayaran = {
  isOpenDrawer: boolean;
  onClose: () => any;
  drawerPembayaran: "task" | "subtask" | undefined;
  setDialogTypeProgress: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
  setDialogTypePembayaran: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
  task: TFidMilestoneTask | undefined;
  subtask: TFidMilestoneSubtask | undefined;
};

function ViewPerencanaanPembayaran({
  setDialogTypeProgress,
  setDialogTypePembayaran,
  drawerPembayaran,
  task,
  subtask,
  onClose,
  isOpenDrawer,
}: TViewPerencanaanPembayaran) {
  const { milestonetore } = useStore();
  const { financialPlanLoading, physicalPlanLoading, getFinancialPlan, getPhysicalPlan } = milestonetore;
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>();

  const perencanaanCollapse = [
    {
      title: "Perencanaan Progress",
      collpaseItem: (
        <ProgressCollapse subtask={subtask} task={task} setDialogTypeProgress={setDialogTypeProgress} drawerPembayaran={drawerPembayaran} />
      ),
    },
    {
      title: "Perencanaan Pembayaran",
      collpaseItem: (
        <PembayaranCollapse subtask={subtask} task={task} setDialogTypePembayaran={setDialogTypePembayaran} drawerPembayaran={drawerPembayaran} />
      ),
    },
  ];

  const handleClick = (index: number) => {
    setOpenCollapse((prev) => !prev);
    setIndexCollapse(index);
  };

  useEffect(() => {
    if (!isOpenDrawer) return;

    getFinancialPlan(
      drawerPembayaran === "subtask" ? subtask?.tasksId ?? "" : task?.id ?? "",
      drawerPembayaran === "subtask" ? subtask?.id ?? "" : ""
    );

    getPhysicalPlan(
      drawerPembayaran === "subtask" ? subtask?.tasksId ?? "" : task?.id ?? "",
      drawerPembayaran === "subtask" ? subtask?.id ?? "" : ""
    );
  }, [drawerPembayaran, getFinancialPlan, getPhysicalPlan, isOpenDrawer, subtask, task]);

  return (
    <Drawer
      anchor="right"
      open={isOpenDrawer}
      sx={{
        ".MuiPaper-root": {
          width: "500px",
        },
      }}
    >
      <Box
        sx={{
          m: "16px 16px 0 16px",
          pb: "8px",
          borderBottom: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "space-between",
          mb: "16px",
          alignItems: "center",
        }}
      >
        <Box sx={{ color: "black", fontWeight: "bold" }}>Perencanaan Progress & Pembayaran</Box>
        <IconButton onClick={() => onClose()}>
          <Close />
        </IconButton>
      </Box>
      <Stack flex={1} overflow="auto" p="16px" spacing="24px">
        {financialPlanLoading || physicalPlanLoading ? (
          <Box display="flex" justifyContent="center" py="32px">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ fontSize: "20px", fontWeight: "bold" }}>{drawerPembayaran === "subtask" ? subtask?.name ?? "" : task?.name ?? ""}</Box>
            {perencanaanCollapse.map((item, index) => (
              <Box
                sx={{
                  p: "16px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  border: "1px solid #DEDEDE",
                  background: "#FFF",
                  mb: "12px",
                }}
              >
                <Box onClick={() => handleClick(index)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Box>
                    <Box sx={{ fontWeight: "bold", color: "#212121" }}>{item.title}</Box>
                    <Box sx={{ fontSize: "12px" }}>Click to see detail</Box>
                  </Box>
                  <Box>{openCollapse && indexCollapse === index ? <ExpandLess /> : <ExpandMore />}</Box>
                </Box>
                <Collapse sx={{ mt: "24px" }} in={openCollapse && indexCollapse === index} timeout="auto" unmountOnExit>
                  {item.collpaseItem}
                </Collapse>
              </Box>
            ))}
          </>
        )}
      </Stack>
      <Box
        sx={{
          m: "0 16px 16px 16px",
          pt: "16px",
          borderTop: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "flex-end",
          cursor: "pointer",
        }}
      >
        <Button onClick={() => onClose()} variant="outlined" color="inherit">
          Close
        </Button>
      </Box>
    </Drawer>
  );
}

export default observer(ViewPerencanaanPembayaran);
