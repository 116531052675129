import { action, makeObservable, observable, runInAction } from "mobx"
import agent from "../api/agent";
import { IExample } from "../models/example";
import DataGridStore from "./materialUI/dataGridStore";

export default class ExampleStore extends DataGridStore {
    loadingDelete = false;
    loadingForm = false;

    constructor() {
        super();

        makeObservable(this, {
            loadingDelete: observable,
            loadingForm: observable,
            getExampleList: action,
            getExampleDetail: action,
            createExample: action,
            editExample: action,
            deleteExample: action
        });
    }

    updateGridCallback = action(() => {
        this.getExampleList();
    })

    getExampleList = async () => {
        this.loadingGrid = true;
        try {
            const urlParams = this.createDataGridParam();
            const result = await agent.Example.listGrid(urlParams);
            result.data.forEach(row => {
                row.date = new Date(row.date + "Z");
            });
            this.setDataGridResult(result);
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingGrid = false);
        }
    }

    getExamples = async (search?: string) => {
        try {
            const params = new URLSearchParams();
            if (search) params.append('search', search);
            const result = await agent.Example.list(params);
            result.forEach(row => {
                row.date = new Date(row.date + "Z");
            });
            return result;
        } catch (error) {
            throw error;
        }
    }

    getExampleDetail = async (id: string) => {
        this.loadingForm = true;
        try {
            const result = await agent.Example.detail(id);
            result.date = new Date(result.date + "Z");
            return result;
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingForm = false);
        }
    }

    createExample = async (example: IExample) => {
        try {
            await agent.Example.create(example);
        } catch (error) {
            throw error;
        }
    }

    editExample = async (example: IExample) => {
        try {
            await agent.Example.edit(example);
        } catch (error) {
            throw error;
        }
    }

    deleteExample = async (id: string) => {
        this.loadingDelete = true;
        try {
            await agent.Example.delete(id);
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingDelete = false);
        }
    }
}