import { useEffect } from "react";
import DashboardLayoutHeader from "../../../app/component/layout/DashboardLayoutHeader";
import { ArrowBackIos, InfoOutlined } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Grid, Paper, TextField, Box } from "@mui/material";
import { ProjectCategory } from "../../../app/config/enum";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { NumericFormat } from "react-number-format";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
// import CommentSection from "../components/CommentSection";
import PageButton from "../components/PageButton";
import CSelect from "../../../app/component/CSelect";
import useQuery from "../../../app/hook/useQuery";
import LoadingSection from "../components/LoadingSection";

function GeneralInformation() {
  const { proposalStore, userStore } = useStore();
  const { getUserOptions, userOptions } = userStore;
  const { getGeneralInformation, generalInformation, generalInformationLoading } = proposalStore;
  const { id } = useParams();
  const query = useQuery();
  const status = query.get("status");

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
  }, [getGeneralInformation, id]);

  useEffect(() => {
    getUserOptions();
  }, [getUserOptions]);

  return (
    <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Approval",
            href: "/project-monitoring/approval/list",
          },
          {
            label: "Approval List",
            href: "/project-monitoring/approval/list",
          },
          {
            label: `${generalInformation?.title}`,
          },
        ]}
        pageTitle="Project Monitoring"
      />

      <Paper sx={{ p: "16px" }}>
        <Link
          style={{ color: "#000", textDecoration: "none", display: "flex", alignItems: "center", gap: "3px", marginBottom: "24px" }}
          to={`/project-monitoring/approval/list/${id}?status=${status}`}
        >
          <ArrowBackIos sx={{ fontSize: "14px" }} />
          <Box>Back</Box>
        </Link>
        <Box
          sx={{
            mb: "12px",
            backgroundColor: "#F2F9FF",
            display: "flex",
            p: "16px",
            alignItems: "center",
            gap: "6px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <InfoOutlined sx={{ mr: "12px", color: "#0664B4", fontSize: "34px" }} />
            <Box sx={{ fontWeight: "bold" }}>General Information</Box>
          </Box>
          {/* <CommentSection typeStatus="generalInformation" typeId={generalInformation?.id} type="generalInformation" /> */}
        </Box>

        {generalInformationLoading ? (
          <LoadingSection />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Stack flex={1} overflow="auto" pt="12px" spacing="24px">
                <TextField fullWidth InputLabelProps={{ shrink: true }} disabled label="Title" defaultValue={generalInformation?.title} />
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  disabled
                  label="Kategori Aset"
                  defaultValue={generalInformation?.assetCategoryName}
                />
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  disabled
                  label="Underlying"
                  defaultValue={generalInformation?.underlyingProjectName}
                />
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Project Type"
                  disabled
                  defaultValue={generalInformation?.unitBussinessCategoryName}
                  // defaultValue={generalInformation?.projectTypeName}
                />
                <TextField label="Mata Anggaran" disabled defaultValue={generalInformation?.ubName} />
                {/* <TextField label="Kategori Mata Anggaran" disabled defaultValue={generalInformation?.unitBussinessCategoryName} /> */}
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack flex={1} overflow="auto" pt="12px" spacing="24px">
                <TextField label="Operational / Regional Unit" disabled defaultValue={generalInformation?.regionalName} />
                <Stack direction="row" spacing={"24px"}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Start Date"
                      value={generalInformation?.startYear}
                      views={["year", "month"]}
                      disabled
                      onChange={() => null}
                      renderInput={(props) => <TextField required fullWidth name="startYear" {...props} />}
                    />
                  </LocalizationProvider>
                </Stack>
                <CSelect
                  fullWidth
                  options={userOptions.map((i) => ({ label: `${i.organizationName} - ${i.displayName}`, value: i.id }))} // TODO: get api user
                  label="Fungsi Pengusul"
                  name="projectOwnerId"
                  disabled
                  value={generalInformation?.projectOwnerId}
                />
                <NumericFormat
                  prefix={"Rp"}
                  thousandSeparator={true}
                  customInput={TextField}
                  placeholder="Capex"
                  label="Capex"
                  value={generalInformation?.cost}
                  disabled
                />
              </Stack>
            </Grid>
          </Grid>
        )}

        <PageButton
          isNextButton
          nextButtonRoute={
            generalInformation?.category === ProjectCategory.BD
              ? `/project-monitoring/approval/list/${id}/costplanAnalysis?status=${status}}`
              : `/project-monitoring/approval/list/${id}/investmentBudget?status=${status}`
          }
        />
      </Paper>
      <Box sx={{ py: "16px" }} />
    </Box>
  );
}

export default observer(GeneralInformation);
