import { Add, Close, DeleteOutline, Search } from "@mui/icons-material";
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogContent, IconButton, Stack, TextField, Typography } from "@mui/material";
import { StyledDataGrid } from "../../../../app/component/StyledDataGrid";
import EmptyState from "../../../../app/component/EmptyState";
import CSelect from "../../../../app/component/CSelect";
import { useStore } from "../../../../app/stores/store";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { ProposalRole } from "../../../../app/config/enum";
import * as yup from "yup";
import useQuery from "../../../../app/hook/useQuery";
import { IUserOptions } from "../../../../app/models/user";
import { LoadingButton } from "@mui/lab";
import { observer } from "mobx-react-lite";
import { useDebounce } from "../../../../app/hook/useDebounce";

function ProposalUserManagement() {
  const { userOptions, getUserOptions } = useStore().userStore;
  const { getMember, member, memberLoading, addMember, deleteMember } = useStore().proposalMemberStore;
  const { dialogStore } = useStore();
  const query = useQuery();
  const [selectedUser, setSelectedUser] = useState<IUserOptions | null>(null);
  const [formOpen, setFormOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = useDebounce(2000, (c) => setSearchTerm(c.target.value));

  const id = query.get("id");

  const { setFieldValue, submitForm, isSubmitting, handleChange, values, resetForm, errors, dirty, isValid } = useFormik({
    initialValues: {
      userId: "",
      proposalRole: ProposalRole.Member,
      proposalId: id ?? "",
    },
    validationSchema: yup.object({
      userId: yup.string().required("User is required"),
      proposalRole: yup.string().required("Proposal role is required"),
      proposalId: yup.string().required(),
    }),
    onSubmit: async (values) => {
      await addMember(values).then(() => {
        if (id) getMember(id);

        closeForm();
      });
    },
    enableReinitialize: true,
  });

  const closeForm = () => {
    resetForm();
    setSelectedUser(null);
    setFormOpen(false);
  };

  useEffect(() => {
    getUserOptions();
  }, [getUserOptions]);

  useEffect(() => {
    if (!selectedUser) return;
    setFieldValue("userId", selectedUser.id);
  }, [selectedUser, setFieldValue]);

  useEffect(() => {
    if (id) getMember(id);
  }, [getMember, id]);

  useEffect(() => {
    if (id && searchTerm) getMember(id, { search: searchTerm });
  }, [searchTerm, id, getMember]);

  return (
    <Box>
      <Stack direction="row" spacing="16px">
        <TextField
          fullWidth
          InputProps={{
            startAdornment: <Search />,
          }}
          onChange={(e) => handleSearch(e)}
          placeholder="Search"
        />
        <Button sx={{ whiteSpace: "nowrap" }} startIcon={<Add />} onClick={() => setFormOpen(true)}>
          Add User
        </Button>
      </Stack>
      {member.length > 0 ? (
        <StyledDataGrid
          columns={[
            {
              field: "name",
              headerName: "Name",
              flex: 1,
            },
            {
              field: "organizationName",
              headerName: "Organization",
              flex: 1,
            },
            {
              field: "email",
              headerName: "Email",
              flex: 1,
            },
            {
              field: "action",
              renderCell: (pamars) => (
                <IconButton
                  color="error"
                  onClick={() =>
                    dialogStore.open({
                      action: async () =>
                        await deleteMember(pamars.row.id).then(() => {
                          id && getMember(id);
                        }),
                      actionText: "Delete",
                      title: "Delete",
                      closeText: "Cancel",
                      actionButtonProps: {
                        color: "error",
                      },
                      description: "Are you sure want to delete this item?",
                    })
                  }
                  size="small"
                >
                  <DeleteOutline />
                </IconButton>
              ),
            },
          ]}
          sx={{ mt: "24px" }}
          autoHeight
          rows={member}
          loading={memberLoading}
        />
      ) : (
        <Box py={"32px"}>
          {memberLoading ? (
            <Stack alignItems="center">
              <CircularProgress />
            </Stack>
          ) : (
            <EmptyState />
          )}
        </Box>
      )}

      <Dialog open={formOpen} onClose={closeForm}>
        <DialogContent sx={{ padding: "16px", width: "365px" }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight="700" fontSize="14px" color="black">
              Add user
            </Typography>
            <Close onClick={closeForm} />
          </Stack>
          <Box mt={"16px"}>
            <Autocomplete
              options={userOptions}
              renderInput={(params) => <TextField label="User" {...params} />}
              value={selectedUser}
              onChange={(v, e) => setSelectedUser(e)}
              getOptionLabel={(user) => `${user.userName} ${!user.organizationName ? "" : `- ${user.organizationName}`}`}
              id="combo-box-user"
            />
          </Box>
          {/* <Box mt={'16px'}>
              <CSelect
                value={values.proposalRole}
                name="proposalRole"
                onChange={handleChange}
                error={!!errors.proposalRole}
                helperText={errors.proposalRole}
                options={Object.keys(ProposalRole).map((i) => ({
                  label: i === 'TimPengusul' ? 'Tim Pengusul' : i,
                  value: (ProposalRole as any)[i],
                }))}
                label="Role"
              />
            </Box> */}
          <Stack direction="row" justifyContent="flex-end" gap="12px" mt={"24px"}>
            <Button onClick={closeForm} type="button" variant="outlined" color="inherit">
              Cancel
            </Button>
            <LoadingButton
              onClick={submitForm}
              disabled={!isValid || !dirty}
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
            >
              Add
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default observer(ProposalUserManagement);
