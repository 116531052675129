import { observer } from "mobx-react-lite";

interface Props {
    children?: JSX.Element | JSX.Element[] | "";
}

function RkdFormField({children}: Props) {
    return (
        <div className="RkdFormField">{children}</div>
    );
}

export default observer(RkdFormField);