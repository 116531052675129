import { useEffect } from "react";
import DashboardLayoutHeader from "../../../app/component/layout/DashboardLayoutHeader";
import { Grid, Paper, TextField, Box, Stack } from "@mui/material";
import { ArrowBackIos, AttachMoneyRounded } from "@mui/icons-material";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { Link } from "react-router-dom";
import PageButton from "../components/PageButton";
// import CommentSection from "../components/CommentSection";
import LoadingSection from "../components/LoadingSection";
import useQuery from "../../../app/hook/useQuery";
import AttachmentSection from "../components/AttachmentSection";
import { NumericFormat } from "react-number-format";

function CostplanAnalysis() {
  const { proposalStore } = useStore();
  const { getCostplanAnalysis, costplanAnalysis, costplanAnalysisLoading, getGeneralInformation, generalInformation } = proposalStore;
  const { id } = useParams();
  const query = useQuery();
  const status = query.get("status");

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
  }, [getGeneralInformation, id]);

  useEffect(() => {
    if (!id) return;

    getCostplanAnalysis(id);
  }, [getCostplanAnalysis, id]);

  return (
    <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Approval",
            href: "/project-monitoring/approval/list",
          },
          {
            label: "Approval List",
            href: "/project-monitoring/approval/list",
          },
          {
            label: `${generalInformation?.title}`,
          },
        ]}
        pageTitle="Project Monitoring"
      />

      <Paper sx={{ p: "16px" }}>
        <Link
          style={{ color: "#000", textDecoration: "none", display: "flex", alignItems: "center", gap: "3px", marginBottom: "24px" }}
          to={`/project-monitoring/approval/list/${id}?status=${status}`}
        >
          <ArrowBackIos sx={{ fontSize: "14px" }} />
          <Box>Back</Box>
        </Link>
        <Box
          sx={{
            mb: "12px",
            backgroundColor: "#F2F9FF",
            display: "flex",
            p: "16px",
            alignItems: "center",
            gap: "6px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <AttachMoneyRounded sx={{ mr: "12px", color: "#0664B4", fontSize: "34px" }} />
            <Box sx={{ fontWeight: "bold" }}>Analisis Costplan</Box>
          </Box>
          {/* {costplanAnalysisLoading ? "Loading..." : <CommentSection typeId={costplanAnalysis?.length ? costplanAnalysis[0]?.id : ""} />} */}
        </Box>

        {costplanAnalysisLoading ? (
          <LoadingSection />
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Stack flex={1} overflow="auto" pt="12px" spacing="24px">
                  <NumericFormat
                    label="Hard Cost"
                    prefix={"Rp"}
                    thousandSeparator={true}
                    customInput={TextField}
                    placeholder="Hard Cost"
                    value={costplanAnalysis && costplanAnalysis[0]?.hardCost}
                    disabled
                  />
                  {/* <TextField label="Contractor Cost" value={costplanAnalysis && costplanAnalysis[0]?.contractorCost} type="number" disabled /> */}
                  <NumericFormat
                    label="Soft Cost"
                    prefix={"Rp"}
                    thousandSeparator={true}
                    customInput={TextField}
                    placeholder="Soft Cost"
                    value={costplanAnalysis && costplanAnalysis[0]?.softCost}
                    disabled
                  />
                  <NumericFormat
                    label="Other Cost"
                    prefix={"Rp"}
                    thousandSeparator={true}
                    customInput={TextField}
                    placeholder="Other Cost"
                    value={costplanAnalysis && costplanAnalysis[0]?.other}
                    disabled
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack flex={1} overflow="auto" pt="12px" spacing="24px">
                  <NumericFormat
                    label="Contingencies"
                    prefix={"Rp"}
                    thousandSeparator={true}
                    customInput={TextField}
                    placeholder="Contingencies"
                    value={costplanAnalysis && costplanAnalysis[0]?.contingencies}
                    disabled
                  />
                  <NumericFormat
                    label="IDC"
                    prefix={"Rp"}
                    thousandSeparator={true}
                    customInput={TextField}
                    placeholder="IDC"
                    value={costplanAnalysis && costplanAnalysis[0]?.idc}
                    disabled
                  />
                  {/* <TextField label="PPN" value={costplanAnalysis && costplanAnalysis[0]?.ppn} type="number" disabled /> */}
                </Stack>
              </Grid>
            </Grid>
            <AttachmentSection attachtment={costplanAnalysis?.length ? costplanAnalysis[0]?.attachments : []} />
          </>
        )}

        <PageButton
          isPrevButton
          isNextButton
          prevButtonRoute={`/project-monitoring/approval/list/${id}/generalInformation?status=${status}`}
          nextButtonRoute={`/project-monitoring/approval/list/${id}/economyAnalysis?status=${status}`}
        />
      </Paper>
      <Box sx={{ py: "16px" }} />
    </Box>
  );
}

export default observer(CostplanAnalysis);
