import { useEffect, useMemo, useState } from "react";
import { StyledDataGrid } from "../../../../app/component/StyledDataGrid";
import { useStore } from "../../../../app/stores/store";
import useQuery from "../../../../app/hook/useQuery";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import { Box, Chip, Dialog, DialogTitle, Grid, IconButton, Skeleton, Stack } from "@mui/material";
import { VisibilityOutlined } from "@mui/icons-material";
import { IProposalReviewer } from "../../../../app/models/proposalReviewer";
import { ProjectStatus, ReviewerStatus } from "../../../../app/config/enum";
import ViewAttachment from "../../../../app/component/ViewAttachment";
import { LoadingButton } from "@mui/lab";
import { history } from "../../../..";

function ProposalReviewerTab() {
  const { getReviewer, reviewerLoading, reviewer } = useStore().proposalReviewerStore;
  const { generalInformation } = useStore().proposalStore;
  const { account } = useStore().accountStore;
  const query = useQuery();
  const [currentReason, setCurrentReason] = useState<string | null>(null);
  const { reviewerApproveRefuseProposal, reviewerApproveRejectLoading } = useStore().projectManagementStore
  const { open } = useStore().dialogStore
  const id = query.get("id");
  const status = query.get("status");
  const projectCategory = query.get('projectCategory')

  useEffect(() => {
    if (!id) return;
    getReviewer(id);
  }, [getReviewer, id]);

  const handleClickEye = (row: IProposalReviewer) => {
    setCurrentReason(row.reason);
  };

  const approveAllReviewer = async () => {
    if (!id) return;

    const formData = new FormData()
    formData.append("status", "SUBMITTED");
    formData.append("IsReviewedAll", "1");

    await reviewerApproveRefuseProposal(id, formData)
      .then(() => {
        history.push(`/project-monitoring/proposal/list/form?projectCategory=${projectCategory}&id=${id}&status=${ProjectStatus.DecisionGate}&reviewPage=${true}`)
      });
  }

  const openApproveAllConfirmation = () => open({
    action: approveAllReviewer,
    actionText: 'Yes',
    closeText: 'No',
    title: 'Confirmation',
    description: 'Are you sure want to do force approve for all reviewer',
    actionButtonProps: {
      variant: 'contained',
      color: 'error'
    }
  })

  const isProjectOwner = useMemo(() => {
    return account?.displayName === generalInformation?.projectOwnerName
  }, [generalInformation, account])

  return (
    <>
      {/* {(status === ProjectStatus.Reviewer && isProjectOwner) && (
        <Stack mb={2} flexDirection="row" justifyContent="right">
          <LoadingButton
            onClick={openApproveAllConfirmation}
            loading={reviewerApproveRejectLoading} 
            variant="contained"
          >
            Approve All Reviewer
          </LoadingButton>
        </Stack>
      )} */}
      <StyledDataGrid
        autoHeight
        loading={reviewerLoading}
        columns={[
          {
            resizable: true,
            field: "name",
            headerName: "User",
            width: 200,
          },
          {
            resizable: true,
            field: "organization",
            headerName: "Organization",
            width: 250,
          },
          {
            field: "submittedDate",
            headerName: "Date",
            width: 200,
            renderCell: (params) => (params.row.submittedDate ? format(new Date(params.row.submittedDate), "dd MMM yyyy - hh:mm") : "-"),
            filterable: false,
            resizable: true,
            sortable: false,
          },
          {
            field: "submittedDuration",
            headerName: "Submitted Duration",
            width: 200,
            resizable: true,
          },
          {
            field: "status",
            headerName: "Status",
            width: 150,
            resizable: true,
            renderCell: (params) => {
              // if (params.row.status === 'IN_')
              return <Chip label={params.row.status} />;
            },
          },
          {
            resizable: true,
            field: "from",
            width: 150,
            headerName: "From",
          },
          {
            resizable: true,
            field: "attachment",
            headerName: "Attachment",
            width: 250,
            renderCell: (params) => {
              return (
                <Grid container>
                  {params.row.attachments.map((item: any) => (
                    <Grid item xs={12} sx={{ textDecoration: 'underline', color: 'blue' }}>
                      <ViewAttachment loadingComponent={<Skeleton width={150} variant="text" />} url={item.url} proposalId={id ?? ""}>
                        {item.fileName}
                      </ViewAttachment>
                    </Grid>
                  ))}
                </Grid>
              );
            },
          },
          {
            resizable: true,
            field: "reason",
            headerName: "Reason",
            width: 250,
          },
          {
            field: "action",
            headerName: "Action",
            sortable: false,
            width: 100,
            filterable: false,
            disableColumnMenu: true,
            renderCell(params) {
              return (
                <Box display="flex" justifyContent="flex-end">
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={() => handleClickEye(params.row.reason)}
                    disabled={params.row.status !== ReviewerStatus.Approved || params.row.status !== ReviewerStatus.Refused}
                  >
                    <VisibilityOutlined />
                  </IconButton>
                </Box>
              );
            },
          },
        ]}
        disableSelectionOnClick={true}
        getRowId={(row) => row.submittedDate + "-" + Math.random()}
        rows={reviewer}
      />
      <Dialog open={!!currentReason} onClose={() => setCurrentReason(null)}>
        <DialogTitle title="Reason">Reason</DialogTitle>
      </Dialog>
    </>
  );
}

export default observer(ProposalReviewerTab);
