import { Close } from "@mui/icons-material";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { useRef } from "react";

export type CSelectProps = {
  options: { label: string; value: string }[];
  label?: string;
  loading?: boolean;
  helperText?: string;
  onClear?: () => any;
} & SelectProps;

const CSelect = ({
  label,
  options,
  loading,
  helperText,
  onClear,
  size = "medium",
  ...props
}: CSelectProps) => {
  const ref = useRef<SelectProps>(null);
  return (
    <FormControl fullWidth>
      <InputLabel
        size={
          size === "medium" ? "normal" : size === "small" ? "small" : "normal"
        }
        disabled={props.disabled}
        required={props.required}
        id={label + "id"}
      >
        {label}
      </InputLabel>
      <Select
        ref={ref}
        labelId={label + "id"}
        label={label}
        disabled={loading || props.disabled}
        startAdornment={
          loading ? (
            <CircularProgress sx={{ mr: "16px" }} size={"15px"} />
          ) : undefined
        }
        endAdornment={
          onClear ? (
            !!props.value ? (
              <Close
                onClick={onClear}
                sx={{ mr: 2, cursor: "pointer" }}
                fontSize="small"
              />
            ) : undefined
          ) : undefined
        }
        size={size}
        {...props}
      >
        {options.map((i) => (
          <MenuItem key={i.value} value={i.value}>
            {i.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && props.error && (
        <FormHelperText error={props.error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CSelect;
