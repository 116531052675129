import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TableContainer,
  Chip,
} from "@mui/material";
import {
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
  VisibilityOutlined,
  Percent,
  AddCircleOutline,
  InsertDriveFileOutlined,
  TextsmsOutlined,
  ExpandLess,
  ExpandMore,
  MonetizationOnOutlined,
} from "@mui/icons-material";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { IProgressRealization, TWeightPhysicalActuals } from "../../../../../../app/models/progressRealization";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadingButton } from "@mui/lab";
import {
  CONSTRUCTION_OTHERS_NAME,
  Roles,
  StatusLelang,
  StatusTask,
  TaskSubTask,
  TaskSubTaskStatus,
  enumToArray,
} from "../../../../../../app/config/enum";
import { useFormik } from "formik";
import { useStore } from "../../../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EmptyState from "../../../../../../app/component/EmptyState";
import useQuery from "../../../../../../app/hook/useQuery";
import * as yup from "yup";
import Progress from "./components/Progress";
import CSelect from "../../../../../../app/component/CSelect";
import { DraggableFileUploadData } from "../../../../../../app/component/input/DraggableFileUploadV2";
import { ITaskSubTaskAttachment } from "../../../../../../app/models/taskSubTaskAttachment";
import agent from "../../../../../../app/api/agent";
import AttachmentComment, { disabledButtonForFungsiPengusul } from "./components/AttachmentComment";
import DraggableFileUploadV3 from "../../../../../../app/component/input/DraggableFileUploadV3";
import { NumericFormat } from "react-number-format";
import AddCost from "./components/AddCost";
import ProgressHistoryCollapse from "./components/ProgressHistoryCollapse";
import CostHistoryCollapse from "./components/CostHistoryCollapse";

function ProgressRealizationTab() {
  const { getGeneralInformation, generalInformation } = useStore().proposalStore;
  const { getNewProgressRealization, newProgressRealization, newProgressRealizationLoading } = useStore().progressRealizationStore;
  const { account } = useStore().accountStore;
  const [selectedSubTask, setSelectedSubtask] = useState<IProgressRealization["tasks"][0]>();
  const query = useQuery();
  const id = query.get("id");

  useEffect(() => {
    if (id && account) getNewProgressRealization(id, account, generalInformation?.projectOwnerName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewProgressRealization, id]);

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
  }, [getGeneralInformation, id]);

  // const showProgress = useMemo(() => {
  //   const taskAvailable = !!newProgressRealization.data.filter(i => !!i.milestones.length).length
  //   const isOwner = generalInformation?.projectOwnerName === account?.displayName
  //   const isSuperAdmin = account?.roles.includes(Roles.Superadmin)

  //   return taskAvailable && (isOwner || isSuperAdmin)
  // }, [generalInformation, account, progressRealization])

  return (
    <Box>
      {newProgressRealizationLoading ? (
        <Box display="flex" justifyContent="center" py="32px">
          <CircularProgress />
        </Box>
      ) : newProgressRealization.data.length === 0 ? (
        <EmptyState />
      ) : (
        newProgressRealization.data[0].milestones.map((item, index) => (
          <>
            {index === 0 ? (
              <Box key={index}>
                <Stack
                  mb={3}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ backgroundColor: ({ palette }) => palette.primary.light }}
                  p={2}
                  borderRadius={2}
                >
                  <Typography fontSize="20px" fontWeight={700} sx={{ color: ({ palette }) => palette.primary.main }}>
                    {newProgressRealization.data[0].projectName}
                  </Typography>
                  <Typography fontSize="20px" fontWeight={700} sx={{ color: ({ palette }) => palette.primary.main }}>
                    {newProgressRealization.data[0].projectCostProgress}% / 100%
                  </Typography>
                </Stack>
                <ListItem
                  generalCost={generalInformation?.cost}
                  projectOwnerName={generalInformation?.projectOwnerName}
                  setSelectedSubtask={setSelectedSubtask}
                  selectedSubTask={selectedSubTask}
                  milestone={item}
                />
              </Box>
            ) : (
              <ListItem
                generalCost={generalInformation?.cost}
                projectOwnerName={generalInformation?.projectOwnerName}
                key={index}
                setSelectedSubtask={setSelectedSubtask}
                selectedSubTask={selectedSubTask}
                milestone={item}
              />
            )}
          </>
        ))
      )}
    </Box>
  );
}

export default observer(ProgressRealizationTab);

type ListItemProps = {
  milestone: any;
  setSelectedSubtask: Dispatch<SetStateAction<any>>;
  selectedSubTask?: any;
  projectOwnerName?: string;
  generalCost?: number;
};

const ListItem = ({ generalCost, milestone, setSelectedSubtask, selectedSubTask, projectOwnerName }: ListItemProps) => {
  const { progressRealizationStore, costRealizationStore, accountStore } = useStore();
  const { account } = accountStore;
  const [isOpenAttachmentCommentSection, setIsOpenAttachmentCommentSection] = useState<boolean>();
  const [selectedPhysicalActual, setSelectedPhysicalActual] = useState<TWeightPhysicalActuals>();
  const [financialActualProgress, setFinancialActualProgress] = useState<any[]>();
  const [physicalActualProgress, setPhysicalActualProgress] = useState<any[]>();
  const [isOpenAddMOdal, setIsOpenAddMOdal] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<any>();
  const [isHasNoChild, setIsHasNoChild] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [drawerType, setDrawerType] = useState<string>("");
  const [open, setIsOpen] = useState<boolean>(false);
  const [nestedIndex, setNestedIndex] = useState<number>();
  const [openNestedTable, setOpenNestedTable] = useState<boolean>(false);
  const [isOpenCostModal, setIsOpenCostModal] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>();
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const [selectedCost, setSelectedCost] = useState<any>();
  const [generalStatus, setGeneralStatus] = useState<TaskSubTaskStatus>(TaskSubTaskStatus.Construction);
  const toggleExpandCollapse = () => setIsOpen((prev) => !prev);
  const query = useQuery();
  const id = query.get("id");
  const proposalStatus = query.get("status");
  const [attachmentForm, setAttachmentForm] = useState(false);

  const handleCloseMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, identifer: string) => {
    handleCloseMenu(e);
    setIsOpenDrawer(true);
    setDrawerType(identifer);
  };

  const initialValues = useMemo(() => {
    return {
      weightPhysicalActual: drawerType === "edit" ? selectedPhysicalActual?.weightPhysicalActual : 0,
      date: drawerType === "edit" ? new Date(selectedPhysicalActual?.date ?? 0) : new Date(),
      status: TaskSubTaskStatus.Construction,
      description: drawerType === "edit" ? selectedPhysicalActual?.description : "",
      constraint: drawerType === "edit" ? selectedPhysicalActual?.constraint : "",
      mitigation: drawerType === "edit" ? selectedPhysicalActual?.mitigation : "",
    };
  }, [drawerType, selectedPhysicalActual]);

  const addProgressFormik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      if (!id || !account) return;

      let payload = {
        ...values,
        proposalId: id,
        subtaskId: isHasNoChild ? null : selectedSubTask.subTaskId,
        taskId: isHasNoChild ? selectedTask.tasksId : selectedSubTask.tasksId,
      };

      if (drawerType === "edit") {
        await progressRealizationStore.editProgressRealization(selectedPhysicalActual?.id ?? "", payload as any).then(() => {
          progressRealizationStore.getNewProgressRealization(id, account, projectOwnerName);
          setIsOpenAddMOdal(false);
          setIsOpenDrawer(false);
          setIsHasNoChild(false);
          addProgressFormik.resetForm();
        });
      } else {
        await progressRealizationStore.addProgressRealization(payload as any).then(() => {
          progressRealizationStore.getNewProgressRealization(id, account, projectOwnerName);
          setIsOpenAddMOdal(false);
          setIsOpenDrawer(false);
          setIsHasNoChild(false);
          addProgressFormik.resetForm();
        });
      }
    },
    validationSchema: yup.object({
      date: yup.date().required(),
      weightPhysicalActual: yup.number().required("Progress is a required field").max(100, "Can't more than 100"),
      status: yup.string().required(),
    }),
    enableReinitialize: true,
  });

  const costProgressHistory = [
    {
      title: "Progress History",
      history: (
        <ProgressHistoryCollapse
          setIsOpenAddMOdal={setIsOpenAddMOdal}
          setSelectedPhysicalActual={setSelectedPhysicalActual}
          generalStatus={generalStatus}
          physicalActualProgress={physicalActualProgress ?? []}
        />
      ),
    },
    {
      title: "Cost History",
      history: (
        <CostHistoryCollapse
          setSelectedCost={setSelectedCost}
          addProgressFormik={addProgressFormik}
          projectOwnerName={projectOwnerName ?? ""}
          setIsOpenAddMOdal={setIsOpenAddMOdal}
          setSelectedPhysicalActual={setSelectedPhysicalActual}
          setIsOpenCostModal={setIsOpenCostModal}
          financialActualProgress={financialActualProgress ?? []}
        />
      ),
    },
  ];

  const getProgressByTask = (task: any) => {
    setSelectedTask(task);
    setIsHasNoChild(true);
    setSelectedSubtask(undefined);

    progressRealizationStore.getPhysicalActualProgress("", task.tasksId).then((res: any[]) => {
      addProgressFormik.setFieldValue("status", res.length > 0 ? res[0].status : "");
      setPhysicalActualProgress(res);
    });

    costRealizationStore.getFinancialActualProgress("", task.tasksId).then((res: any[]) => {
      addProgressFormik.setFieldValue("status", res.length > 0 ? res[0].status : "");
      setFinancialActualProgress(res);
    });
  };

  const handleClick = (index: number) => {
    setOpenCollapse((prev) => !prev);
    setIndexCollapse(index);
  };

  useEffect(() => {
    if (!selectedSubTask) return;
    setPhysicalActualProgress([]);
    setFinancialActualProgress([]);

    if (isOpenDrawer) {
      progressRealizationStore.getPhysicalActualProgress(selectedSubTask.subTaskId, selectedSubTask.tasksId).then((res) => {
        addProgressFormik.setFieldValue("status", res[0].status);
        setPhysicalActualProgress(res);
      });

      costRealizationStore.getFinancialActualProgress(selectedSubTask.subTaskId, selectedSubTask.tasksId).then((res) => {
        addProgressFormik.setFieldValue("status", res[0].status);
        setFinancialActualProgress(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenDrawer, costRealizationStore, progressRealizationStore, selectedSubTask]);

  return (
    <>
      <Stack direction="row" alignItems="center" gap="8px">
        <Stack
          py={"20px"}
          sx={{
            cursor: "pointer",
            width: "100%",
            justifyContent: "flex-start",
          }}
          component={ButtonBase}
          onClick={toggleExpandCollapse}
        >
          <Stack direction="row" spacing={"16px"} sx={{ width: "100%", justifyContent: "flex-start" }}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            <Typography sx={{ width: "100%" }} fontWeight={700} color="black">
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ color: "black" }}>{milestone.milestoneName}</Box>
                <Typography color="black" fontSize="11px">
                  <Stack flex={1} direction="row" spacing={"16px"} justifyContent="" pr={4} sx={{ textAlign: "end", width: "100%" }}>
                    <Box>
                      <Box sx={{ fontWeight: "bold" }}>Jumlah Nilai Contract</Box>
                      <Box>Rp{milestone.milestonesContractBudget.toLocaleString() ?? 0}</Box>
                    </Box>

                    <Box>
                      <Box sx={{ fontWeight: "bold" }}>Total ABI</Box>
                      <Box>
                        Rp{milestone.milestonesBudget.toLocaleString() ?? 0} | {milestone.milestoneWeight.toLocaleString() ?? 0}%
                      </Box>
                    </Box>

                    <Box>
                      <Box sx={{ fontWeight: "bold" }}>Payment</Box>
                      <Box>
                        Rp{milestone.milestonesContractCost.toLocaleString() ?? 0} / Rp{milestone.milestonesBudget.toLocaleString() ?? 0}
                      </Box>
                    </Box>

                    <Box>
                      <Box sx={{ fontWeight: "bold" }}>Progress</Box>
                      <Box>{milestone.milestoneProgress.toLocaleString() ?? 0}%</Box>
                    </Box>
                  </Stack>
                </Typography>
                {/* <Box sx={{ color: "black", fontWeight: "normal" }}>{`${milestone.milestoneProgress}% / ${milestone.milestoneWeight}%sss`}</Box> */}
              </Box>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Collapse in={open} timeout="auto" unmountOnExit>
        {milestone.tasks.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableCell></TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Start Date</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>End Date</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Percentage to CAPEX</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Nominal Pagu</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Nilai Contract</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Progress (%)</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>
                  <Box sx={{ width: "180px" }}>Physical Realization (%)</Box>
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Perencanaan</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Doc Tender</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Lelang</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>
                  <Box sx={{ width: "150px" }}>Pemenang Lelang</Box>
                </TableCell>
                {/* EXECUTION IS NEW NAME FOR CONSTRUCTION */}
                <TableCell sx={{ fontWeight: 700 }}>Execution</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Pembayaran</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Action</TableCell>
              </TableHead>
              {milestone.tasks.map((task: any, index: number) => (
                <TableBody>
                  <TableRow>
                    {task.subtasks.length > 0 ? (
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            setNestedIndex(index);
                            setOpenNestedTable(!openNestedTable);
                          }}
                        >
                          {openNestedTable && index === nestedIndex ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )}
                    <TableCell>
                      <Box sx={{ width: "100px" }}>{task.taskName}</Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ width: "100px" }}>{format(new Date(task.starDate), "MMMM yyyy")}</Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ width: "100px" }}>{format(new Date(task.endDate), "MMMM yyyy")}</Box>
                    </TableCell>
                    <TableCell>
                      <Chip label={task.status === TaskSubTaskStatus.Construction ? CONSTRUCTION_OTHERS_NAME : task.status} color="primary" />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ width: "160px" }}>{task.taskWeight.toFixed(1)}%</Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ width: "120px" }}>Rp{task.taskCost.toLocaleString()}</Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ width: "120px" }}>Rp{task.contractValue.toLocaleString()}</Box>
                    </TableCell>
                    <TableCell>
                      <Progress data={task.taskProgress} max={task.taskWeight.toFixed(1)} />
                    </TableCell>
                    <TableCell>
                      <Progress data={task.taskPhysicalRealization} />
                    </TableCell>
                    <TableCell>
                      <Progress data={task.taskPerencanaan} />
                    </TableCell>
                    <TableCell>
                      <Progress data={task.taskDocTender} />
                    </TableCell>
                    <TableCell>
                      <Progress data={task.taskLelang} />
                    </TableCell>
                    <TableCell>
                      <Progress data={task.taskPemenangLelang} />
                    </TableCell>
                    <TableCell>
                      <Progress data={task.taskConstruction} />
                    </TableCell>
                    <TableCell>
                      <Progress data={task.taskPembayaran} />
                    </TableCell>
                    {task.subtasks.length > 0 ? (
                      <TableCell></TableCell>
                    ) : (
                      <TableCell>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "12px", alignItems: "center" }}>
                          {/* ADD PROGRESS */}
                          {task.status === TaskSubTaskStatus.Construction || task.status === TaskSubTaskStatus.Pembayaran ? (
                            <>
                              {account?.roles.includes(Roles.Procurement) ? (
                                ""
                              ) : (
                                <Box sx={{ display: "flex" }}>
                                  <IconButton
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setIsOpenCostModal(true);
                                      setSelectedPhysicalActual(task);
                                      setSelectedTask(task);
                                    }}
                                  >
                                    <MonetizationOnOutlined sx={{ fontSize: "24px" }} />
                                  </IconButton>
                                  <IconButton
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                      addProgressFormik.setFieldValue("status", task.status);
                                      setSelectedTask(task);
                                      setSelectedSubtask(undefined);
                                      setIsHasNoChild(true);
                                      setDrawerType("");
                                      setIsOpenAddMOdal(true);
                                    }}
                                  >
                                    <AddCircleOutline />
                                  </IconButton>
                                </Box>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {/* Add Attachment */}
                          {((task.status === TaskSubTaskStatus.Perencanaan || task.status === TaskSubTask.Completed) &&
                            account?.roles.includes(Roles.Procurement)) ||
                          task.status === TaskSubTaskStatus.Construction ? (
                            ""
                          ) : (
                            <IconButton
                              onClick={() => {
                                setSelectedTask(task);
                                setSelectedSubtask(undefined);
                                setAttachmentForm(true);
                              }}
                            >
                              <InsertDriveFileOutlined />
                            </IconButton>
                          )}
                          <IconButton
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              setIsOpenAttachmentCommentSection(true);
                              setSelectedSubtask(undefined);
                              setSelectedTask(task);
                            }}
                          >
                            <TextsmsOutlined />
                          </IconButton>
                          {task.status === TaskSubTaskStatus.Construction ||
                          task.status === TaskSubTaskStatus.Pembayaran ||
                          task.status === TaskSubTask.Completed ? (
                            <IconButton
                              sx={{ cursor: "pointer" }}
                              onClick={(e) => {
                                handleOpenMenu(e, "edit");
                                getProgressByTask(task);
                                setSelectedSubtask(undefined);
                                setSelectedTask(task);
                                setGeneralStatus(task.status);
                              }}
                            >
                              <VisibilityOutlined />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    {task.subtasks.length > 0 ? (
                      <TableCell sx={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#edecec4d", paddingLeft: "48px" }} colSpan={16}>
                        <Collapse in={openNestedTable && index === nestedIndex} timeout="auto" unmountOnExit>
                          <Box sx={{ m: 1, ml: 3 }}>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Start Date</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>End Date</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Percentage to CAPEX</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Nominal Pagu</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Nilai Contract</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Progress (%)</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>
                                  <Box sx={{ width: "180px" }}>Physical Realization (%)</Box>
                                </TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Perencanaan</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Doc Tender</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Lelang</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>
                                  <Box sx={{ width: "150px" }}>Pemenang Lelang</Box>
                                </TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Execution</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Pembayaran</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {task.subtasks.map((subtask: any, siIndex: number) => (
                                <TableRow key={siIndex}>
                                  <TableCell>
                                    <Box sx={{ width: "100px" }}>{subtask.subTaskName}</Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box sx={{ width: "100px" }}>{format(new Date(subtask.starDate), "MMMM yyyy")}</Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box sx={{ width: "100px" }}>{format(new Date(subtask.endDate), "MMMM yyyy")}</Box>
                                  </TableCell>
                                  <TableCell>
                                    <Chip
                                      label={subtask.status === TaskSubTaskStatus.Construction ? CONSTRUCTION_OTHERS_NAME : subtask.status}
                                      color="primary"
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Box sx={{ width: "160px" }}>{subtask.subTaskWeight.toFixed(1)}%</Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box sx={{ width: "120px" }}>Rp{subtask.subTaskCost.toLocaleString()}</Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box sx={{ width: "120px" }}>Rp{subtask.contractValue.toLocaleString()}</Box>
                                  </TableCell>
                                  <TableCell>
                                    <Progress data={subtask.subTaskProgress} max={subtask.subTaskWeight.toFixed(1)} />
                                  </TableCell>
                                  <TableCell>
                                    <Progress data={subtask.subTaskPhysicalRealization} />
                                  </TableCell>
                                  <TableCell>
                                    <Progress data={subtask.subTaskPerencanaan} />
                                  </TableCell>
                                  <TableCell>
                                    <Progress data={subtask.subTaskDocTender} />
                                  </TableCell>
                                  <TableCell>
                                    <Progress data={subtask.subTaskLelang} />
                                  </TableCell>
                                  <TableCell>
                                    <Progress data={subtask.subTaskPemenangLelang} />
                                  </TableCell>
                                  <TableCell>
                                    <Progress data={subtask.subTaskConstruction} />
                                  </TableCell>
                                  <TableCell>
                                    <Progress data={subtask.subTaskPembayaran} />
                                  </TableCell>
                                  <TableCell>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "12px", alignItems: "center" }}>
                                      {/* ADD PROGRESS */}
                                      {subtask.status === TaskSubTaskStatus.Construction || subtask.status === TaskSubTaskStatus.Pembayaran ? (
                                        <>
                                          {account?.roles.includes(Roles.Procurement) ? (
                                            ""
                                          ) : (
                                            <Box sx={{ display: "flex" }}>
                                              <IconButton
                                                sx={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  setSelectedSubtask(subtask);
                                                  setSelectedPhysicalActual(subtask);
                                                  setIsOpenCostModal(true);
                                                }}
                                              >
                                                <MonetizationOnOutlined sx={{ fontSize: "24px" }} />
                                              </IconButton>
                                              <IconButton
                                                sx={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  addProgressFormik.setFieldValue("status", subtask.status);
                                                  setDrawerType("");
                                                  setIsOpenAddMOdal(true);
                                                  setSelectedSubtask(subtask);
                                                }}
                                              >
                                                <AddCircleOutline />
                                              </IconButton>
                                            </Box>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {/* Add Attachment */}
                                      {((subtask.status === TaskSubTaskStatus.Perencanaan || subtask.status === TaskSubTask.Completed) &&
                                        account?.roles.includes(Roles.Procurement)) ||
                                      subtask.status === TaskSubTaskStatus.Construction ? (
                                        ""
                                      ) : (
                                        <IconButton
                                          onClick={() => {
                                            setSelectedTask(task);
                                            setSelectedSubtask(subtask);
                                            setAttachmentForm(true);
                                          }}
                                        >
                                          <InsertDriveFileOutlined />
                                        </IconButton>
                                      )}
                                      <IconButton
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setIsOpenAttachmentCommentSection(true);
                                          setSelectedSubtask(subtask);
                                        }}
                                      >
                                        <TextsmsOutlined />
                                      </IconButton>
                                      {subtask.status === TaskSubTaskStatus.Construction ||
                                      subtask.status === TaskSubTaskStatus.Pembayaran ||
                                      subtask.status === TaskSubTask.Completed ? (
                                        <IconButton
                                          sx={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            handleOpenMenu(e, "edit");
                                            setSelectedSubtask(subtask);
                                            setGeneralStatus(subtask.status);
                                          }}
                                        >
                                          <VisibilityOutlined />
                                        </IconButton>
                                      ) : (
                                        ""
                                      )}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Box>
                        </Collapse>
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                </TableBody>
              ))}
            </Table>
          </TableContainer>
        ) : (
          <EmptyState />
        )}
      </Collapse>
      <form onSubmit={addProgressFormik.handleSubmit} noValidate>
        <Dialog
          open={isOpenAddMOdal}
          onClose={() => {
            setIsOpenAddMOdal(false);
            addProgressFormik.resetForm();
          }}
        >
          <DialogContent sx={{ padding: "16px", width: "365px" }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography fontWeight="700" fontSize="14px" color="black">
                {`${drawerType === "edit" ? "Edit" : "Add"} Progress`}
              </Typography>
              <Close
                onClick={() => {
                  setIsOpenAddMOdal(false);
                  addProgressFormik.resetForm();
                }}
              />
            </Stack>
            <Box mt={"24px"}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  value={addProgressFormik.values.date}
                  views={["month", "year"]}
                  onChange={(e: any) => addProgressFormik.setFieldValue("date", e)}
                  renderInput={(props) => (
                    <TextField
                      required
                      fullWidth
                      name="date"
                      error={!!addProgressFormik.errors.date}
                      helperText={addProgressFormik.errors.date && String(addProgressFormik.errors.date)}
                      {...props}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box mt="12px">
              <CSelect
                options={enumToArray(StatusTask).map((statusTask) => ({
                  label: statusTask.text,
                  value: statusTask.value,
                }))}
                label="Status"
                disabled
                name="status"
                value={addProgressFormik.values.status}
                error={!!addProgressFormik.errors.status}
                helperText={addProgressFormik.errors.status}
                onChange={addProgressFormik.handleChange}
              />
            </Box>
            <Box mt="12px">
              <TextField
                label="Progress"
                name="weightPhysicalActual"
                required
                fullWidth
                value={addProgressFormik.values.weightPhysicalActual}
                error={!!addProgressFormik.errors.weightPhysicalActual}
                helperText={addProgressFormik.errors.weightPhysicalActual}
                onChange={addProgressFormik.handleChange}
                type="number"
                InputProps={{
                  endAdornment: <Percent sx={{ color: "#757575" }} />,
                }}
              />
            </Box>
            <Box mt="12px">
              <TextField
                label="Description"
                name="description"
                multiline
                fullWidth
                rows={3}
                helperText={addProgressFormik.errors.description}
                error={!!addProgressFormik.errors.description}
                value={addProgressFormik.values.description}
                onChange={addProgressFormik.handleChange}
              />
            </Box>
            <Box mt="12px">
              <TextField
                label="Kendala"
                name="constraint"
                multiline
                fullWidth
                rows={3}
                helperText={addProgressFormik.errors.constraint}
                error={!!addProgressFormik.errors.constraint}
                value={addProgressFormik.values.constraint}
                onChange={addProgressFormik.handleChange}
              />
            </Box>
            <Box mt="12px">
              <TextField
                label="Mitigasi"
                name="mitigation"
                multiline
                fullWidth
                rows={3}
                helperText={addProgressFormik.errors.mitigation}
                error={!!addProgressFormik.errors.mitigation}
                value={addProgressFormik.values.mitigation}
                onChange={addProgressFormik.handleChange}
              />
            </Box>
            <Stack direction="row" justifyContent="flex-end" gap="12px" mt={"24px"}>
              <Button
                onClick={() => {
                  setIsOpenAddMOdal(false);
                  addProgressFormik.resetForm();
                }}
                type="button"
                variant="outlined"
                color="inherit"
              >
                Cancel
              </Button>
              <LoadingButton
                onClick={addProgressFormik.submitForm}
                disabled={!addProgressFormik.isValid || !addProgressFormik.dirty}
                loading={addProgressFormik.isSubmitting}
                variant="contained"
                color="primary"
              >
                {`${drawerType === "edit" ? "Update" : "Add"}`}
              </LoadingButton>
            </Stack>
          </DialogContent>
        </Dialog>
      </form>
      <Drawer
        anchor="right"
        open={isOpenDrawer}
        sx={{
          ".MuiPaper-root": {
            width: "40%",
          },
        }}
      >
        <Box
          sx={{
            m: "16px 16px 0 16px",
            pb: "8px",
            borderBottom: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "space-between",
            mb: "16px",
            alignItems: "center",
          }}
        >
          <Box sx={{ color: "black", fontWeight: "bold" }}>Progress & Cost History</Box>
          <IconButton onClick={() => setIsOpenDrawer(false)}>
            <Close />
          </IconButton>
        </Box>
        <Stack flex={1} overflow="auto" p="16px" spacing="24px">
          <Box sx={{ fontSize: "20px", fontWeight: "bold" }}>{selectedSubTask?.subTaskName || selectedTask?.taskName}</Box>
          {costProgressHistory.map((item, index) => (
            <Box
              sx={{
                p: "16px",
                cursor: "pointer",
                borderRadius: "4px",
                border: "1px solid #DEDEDE",
                background: "#FFF",
                mb: "12px",
              }}
            >
              <Box onClick={() => handleClick(index)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box>
                  <Box sx={{ fontWeight: "bold", color: "#212121" }}>{item.title}</Box>
                  <Box sx={{ fontSize: "12px" }}>Click to see detail</Box>
                </Box>
                <Box>{openCollapse && indexCollapse === index ? <ExpandLess /> : <ExpandMore />}</Box>
              </Box>
              <Collapse sx={{ mt: "24px" }} in={openCollapse && indexCollapse === index} timeout="auto" unmountOnExit>
                {item.history}
              </Collapse>
            </Box>
          ))}
        </Stack>
        <Box
          sx={{
            m: "0 16px 16px 16px",
            pt: "16px",
            borderTop: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
        >
          <Button onClick={() => setIsOpenDrawer(false)} variant="outlined" color="inherit">
            Close
          </Button>
        </Box>
      </Drawer>
      {selectedPhysicalActual ? (
        <AddCost
          subTask={selectedSubTask}
          task={selectedTask}
          selectedCost={selectedCost}
          generalCost={generalCost ?? 0}
          projectOwnerName={projectOwnerName ?? ""}
          setIsOpenCostModal={setIsOpenCostModal}
          setIsHasNoChild={setIsHasNoChild}
          isOpenCostModal={isOpenCostModal}
          isHasNoChild={isHasNoChild}
          clearState={() => {
            setSelectedCost(undefined);
          }}
        />
      ) : (
        ""
      )}
      {isOpenAttachmentCommentSection ? (
        <AttachmentComment
          projectOwnerName={projectOwnerName ?? ""}
          proposalId={id ?? ""}
          subTask={selectedSubTask}
          task={selectedTask}
          isOpenDrawer={isOpenAttachmentCommentSection}
          setIsOpenDrawer={setIsOpenAttachmentCommentSection}
        />
      ) : (
        ""
      )}
      <AttachmentDrawer
        isOpen={attachmentForm}
        onClose={() => setAttachmentForm(false)}
        selectedTask={selectedTask}
        selectedSubTask={selectedSubTask}
        clearState={() => {
          setSelectedTask(undefined);
          setSelectedSubtask(undefined);
        }}
      />
    </>
  );
};

type TForm = {
  AuctionStatus?: string;
  files: DraggableFileUploadData[];
  VendorWinner?: string;
  ContractValue?: number;
  ContractName?: string;
  ContractNumber?: string;
  startDateContract?: Date;
  endDateContract?: Date;
};
type TFormErrors = {
  AuctionStatus?: string;
  files?: string;
  VendorWinner?: string;
  ContractValue?: string;
  ContractName?: string;
  ContractNumber?: string;
  startDateContract?: Date;
  endDateContract?: Date;
};

const initialForm = {
  AuctionStatus: undefined,
  files: [],
  VendorWinner: undefined,
  ContractValue: undefined,
  ContractName: undefined,
  ContractNumber: undefined,
  startDateContract: new Date(),
  endDateContract: new Date(),
};
const initialErrors = {
  AuctionStatus: undefined,
  files: undefined,
  VendorWinner: undefined,
  ContractValue: undefined,
  ContractName: undefined,
  ContractNumber: undefined,
  startDateContract: undefined,
  endDateContract: undefined,
};

const AttachmentDrawer = observer(
  ({
    isOpen,
    onClose,
    selectedTask,
    selectedSubTask,
    clearState,
  }: {
    isOpen: boolean;
    onClose: () => any;
    selectedTask: any;
    selectedSubTask: any;
    clearState: () => any;
  }) => {
    const { account } = useStore().accountStore;
    const [loading, setLoading] = useState(false);
    const [delLoading, setDelLoading] = useState(false);
    const [attc, setAttc] = useState<ITaskSubTaskAttachment[]>([]);

    const [form, setForm] = useState<TForm>(initialForm);
    const [formErrors, setFormErrors] = useState<TFormErrors>(initialErrors);

    const [files, setFiles] = useState<DraggableFileUploadData[]>([]);
    const [attachmentLoading, getAttachmentLoading] = useState(false);
    const { addTaskSubTaskAttachment, deleteTaskAttachment, getTaskAttachment, getTaskSubTaskAttachment } = useStore().milestonetore;
    const { getAuctionStatus, auctionStatus, auctionStatusLoading } = useStore().auctionStatusStore;
    const query = useQuery();
    const id = query.get("id");

    const isTask = useMemo(() => !selectedSubTask && !!selectedTask, [selectedSubTask, selectedTask]);
    const isSubTask = useMemo(() => !!selectedSubTask && !!selectedTask, [selectedSubTask, selectedTask]);
    const taskId = useMemo(() => selectedTask?.tasksId, [selectedTask]);
    const subTaskId = useMemo(() => selectedSubTask?.subTaskId, [selectedSubTask]);

    const taskSubtaskStatus = useMemo(() => {
      if (isSubTask) {
        return selectedSubTask.status;
      } else if (isTask) {
        return selectedTask.status;
      }
    }, [selectedSubTask, selectedTask, isSubTask, isTask]);

    const disableSubmitButton = useMemo(() => {
      if (account?.roles.includes(Roles.Superadmin)) return false;
      if (disabledButtonForFungsiPengusul.includes(taskSubtaskStatus) && !account?.roles.includes(Roles.Procurement)) return true;

      return false;
    }, [account?.roles, taskSubtaskStatus]);

    const handleClose = () => {
      onClose();
      setForm(initialForm);
      setAttc([]);
      clearState();
    };

    const handleChange = (name: keyof Omit<TForm, "files">, value?: string | number) => {
      setForm((prev) => ({
        ...prev,
        [name]: value,
      }));
      if (!!value) {
        setFormErrors((prev) => ({
          ...prev,
          [name]: undefined,
        }));
      }
    };

    // const newFiles = useMemo(() => form.files.filter((i) => !i.data?.id), [form.files]);

    const getAttachment = useCallback(async () => {
      if (!id) return;

      getAttachmentLoading(true);

      try {
        if (isSubTask) {
          const res = await agent.FID.getSubtaskAttachment(id, taskId, subTaskId);
          setAttc(res);
        } else if (isTask) {
          const res = await agent.FID.getTaskAttachment(id, taskId);
          setAttc(res);
        }
      } finally {
        getAttachmentLoading(false);
      }
    }, [id, isTask, taskId, isSubTask, subTaskId]);

    const validateForm = (): boolean => {
      if (taskSubtaskStatus === TaskSubTaskStatus.Lelang) {
        !form.AuctionStatus && setFormErrors((prev) => ({ ...prev, AuctionStatus: "Status lelang must be filled" }));
        !files.length && setFormErrors((prev) => ({ ...prev, files: "You must attach atleast 1 new attachment" }));

        if (!form.AuctionStatus) {
          return false;
        }
      }

      if (taskSubtaskStatus === TaskSubTaskStatus.PemenangLelang) {
        !form.VendorWinner && setFormErrors((prev) => ({ ...prev, VendorWinner: "Pemenang lelang must be filled" }));
        !form.ContractValue && setFormErrors((prev) => ({ ...prev, ContractValue: "Nilai kontrak must be filled" }));
        !files.length && setFormErrors((prev) => ({ ...prev, files: "You must attach atleast 1 new attachment" }));
        !form.ContractName && setFormErrors((prev) => ({ ...prev, ContractName: "Contract Name must be filled" }));
        !form.ContractNumber && setFormErrors((prev) => ({ ...prev, ContractNumber: "Contract Number must be filled" }));
        // !newFiles.length && setFormErrors((prev) => ({ ...prev, files: "You must attach atleast 1 new attachment" }));

        if (!form.ContractValue || !form.VendorWinner) {
          return false;
        }
      }

      if (taskSubtaskStatus === TaskSubTaskStatus.Perencanaan) {
        !files.length && setFormErrors((prev) => ({ ...prev, files: "You must attach atleast 1 new attachment" }));

        if (!files.length) {
          return false;
        }
      }

      !files.length && setFormErrors((prev) => ({ ...prev, files: "You must attach atleast 1 new attachment" }));

      return true;
    };

    const handleSubmit = async () => {
      if (!id) return;

      if (!validateForm()) return;

      try {
        const formData = new FormData();

        // Status === LELANG
        if (taskSubtaskStatus === TaskSubTaskStatus.Lelang) {
          form.AuctionStatus && formData.append("data.auctionStatus", form.AuctionStatus);
        }
        // Status === PEMENANG_LELANG
        if (taskSubtaskStatus === TaskSubTaskStatus.PemenangLelang) {
          form.VendorWinner && formData.append("data.vendorWinner", form.VendorWinner);
          form.ContractValue && formData.append("data.contractValue", form.ContractValue.toString());
          form.ContractName && formData.append("data.contractName", form.ContractName.toString());
          form.ContractNumber && formData.append("data.contractNumber", form.ContractNumber.toString());
          form.startDateContract && formData.append("data.startDateContract", new Date(form.startDateContract).toUTCString());
          form.endDateContract && formData.append("data.endDateContract", new Date(form.endDateContract).toUTCString());
        }

        if (isSubTask) {
          formData.append("data.taskId", taskId);
          formData.append("data.subTaskId", subTaskId);

          if (Array.isArray(files) && !!files.length) {
            files.forEach((file, index) => {
              // if ((file as any).description) formData.append(`data.taskSubTasksAttachments[${index}].description`, (file as any).description);
              formData.append(`data.taskSubTasksAttachments[${index}].description`, (file as any).description ?? "");
              if (file.data?.id) formData.append(`data.taskSubTasksAttachments[${index}].id`, file.data?.id);
              // else
              //   formData.append(`data.taskSubTasksAttachments[${index}].fileName`, file.file.name as any);

              formData.append(`data.taskSubTasksAttachments[${index}].attachment`, file.file as File);
            });
          }
        } else if (isTask) {
          formData.append(`data.taskId`, taskId);

          if (Array.isArray(files) && !!files.length) {
            files.forEach((file, index) => {
              // if ((file as any).description) formData.append(`data.taskSubTasksAttachments[${index}].description`, (file as any).description);

              formData.append(`data.taskSubTasksAttachments[${index}].description`, (file as any).description ?? "");

              if (file.data?.id) formData.append(`data.taskSubTasksAttachments[${index}].id`, file.data?.id);
              // else
              //   formData.append(`data.taskSubTasksAttachments[${index}].fileName`, file.file.name as any);

              formData.append(`data.taskSubTasksAttachments[${index}].attachment`, file.file as File);
            });
          }
        }

        setLoading(true);

        await addTaskSubTaskAttachment(id, formData)
          .then(() => {
            handleClose();
            getAttachment();
          })
          .finally(() => setLoading(false));
      } catch (e) {
        return e;
      }
    };

    useEffect(() => {
      getAttachment();
      if (taskSubtaskStatus === TaskSubTaskStatus.Lelang) {
        getAuctionStatus();
      }
    }, [getAttachment, getAuctionStatus, taskSubtaskStatus]);

    useEffect(() => {
      if (Array.isArray(attc) && attc.length > 0) {
        if (taskSubtaskStatus === TaskSubTaskStatus.Lelang) {
          setForm((prev) => ({ ...prev, AuctionStatus: attc[0].auctionStatus }));
        } else if (taskSubtaskStatus === TaskSubTaskStatus.PemenangLelang) {
          setForm((prev) => ({ ...prev, ContractValue: attc[0].contractValue }));
          setForm((prev) => ({ ...prev, VendorWinner: attc[0].vendorWinner }));
          setForm((prev) => ({ ...prev, ContractName: attc[0].contractName }));
          setForm((prev) => ({ ...prev, ContractNumber: attc[0].contractNumber }));
        }
      }

      const filterFn = (item: ITaskSubTaskAttachment) => {
        let isMatchStep: boolean = false;

        if (isSubTask) {
          isMatchStep = item.submittedOnStep === selectedSubTask.status;
        } else if (isTask) {
          isMatchStep = item.submittedOnStep === selectedTask.status;
        }

        return item.isApproved === false && isMatchStep;
      };

      setFiles(
        attc.filter(filterFn).map((i) => ({
          file: { name: i.fileName! },
          description: i.description,
          data: i,
        }))
      );

      return () => {
        setFiles([]);
        setForm(initialForm);
      };
    }, [attc, isSubTask, isTask, selectedSubTask, selectedTask, taskSubtaskStatus]);

    useEffect(() => {
      setForm((prev) => ({ ...prev, files }));
    }, [files]);

    useEffect(() => {
      if (files.length)
        setFormErrors((prev) => ({
          ...prev,
          files: undefined,
        }));
    }, [files]);

    return (
      <Drawer
        anchor="right"
        open={isOpen}
        sx={{
          ".MuiPaper-root": {
            width: "500px",
          },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: "16px",
          pt: "16px",
        }}
      >
        <Box
          sx={{
            ".MuiPaper-root": {
              width: "500px",
            },
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: "16px",
            pt: "16px",
          }}
        >
          <Typography fontSize={"14px"} fontWeight={700} color="black">
            Attachment - {taskSubtaskStatus}
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Stack flex={1} spacing={2} overflow="auto" p="16px">
          {taskSubtaskStatus === TaskSubTaskStatus.Lelang && (
            <CSelect
              options={auctionStatus.map((item) => ({
                label: item.status,
                value: item.id,
              }))}
              onChange={(e) => handleChange("AuctionStatus", e.target.value as string)}
              label="Status lelang"
              loading={auctionStatusLoading}
              value={form.AuctionStatus ?? StatusLelang.GagalTender}
              error={!!formErrors.AuctionStatus}
              helperText={formErrors.AuctionStatus}
            />
          )}
          {taskSubtaskStatus === TaskSubTaskStatus.PemenangLelang && (
            <>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date Contract"
                  value={form.startDateContract}
                  onChange={(e: any) => handleChange("startDateContract", e)}
                  renderInput={(props: any) => (
                    <TextField
                      required
                      fullWidth
                      name="startDateContract"
                      error={!!formErrors.startDateContract}
                      helperText={formErrors.startDateContract && String(formErrors.startDateContract)}
                      {...props}
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="End Date Contract"
                  value={form.endDateContract}
                  onChange={(e: any) => handleChange("endDateContract", e)}
                  renderInput={(props) => (
                    <TextField
                      required
                      fullWidth
                      name="endDateContract"
                      error={!!formErrors.endDateContract}
                      helperText={formErrors.endDateContract && String(formErrors.endDateContract)}
                      {...props}
                    />
                  )}
                />
              </LocalizationProvider>
              <TextField
                onChange={(e) => handleChange("VendorWinner", e.target.value as string)}
                label="Pemenang Lelang"
                InputLabelProps={{ shrink: true }}
                value={form.VendorWinner}
                error={!!formErrors.VendorWinner}
                helperText={formErrors.VendorWinner}
              />
              <TextField
                onChange={(e) => handleChange("ContractName", e.target.value as string)}
                label="Contract Name"
                value={form.ContractName}
                InputLabelProps={{ shrink: true }}
                error={!!formErrors.ContractName}
                helperText={formErrors.ContractName}
              />
              <TextField
                onChange={(e) => handleChange("ContractNumber", e.target.value as string)}
                label="Contract Number"
                value={form.ContractNumber}
                InputLabelProps={{ shrink: true }}
                error={!!formErrors.ContractNumber}
                helperText={formErrors.ContractNumber}
              />
              <NumericFormat
                customInput={TextField}
                label="Contract Value"
                fullWidth
                value={form.ContractValue}
                error={!!formErrors.ContractValue}
                helperText={formErrors.ContractValue}
                thousandSeparator={true}
                prefix={"Rp "}
                onValueChange={(e) => {
                  handleChange(`ContractValue`, e.floatValue);
                }}
                name={`ContractValue`}
              />
            </>
          )}
          <Box>
            {taskSubtaskStatus === TaskSubTaskStatus.PemenangLelang ? <Box sx={{ fontWeight: "bold", marginBottom: "12px" }}>PO/Contract</Box> : ""}
            <DraggableFileUploadV3
              data={files}
              setData={setFiles}
              viewable
              viewableOptions={{
                propsoalId: !!id ? id : undefined,
              }}
              error={formErrors.files}
              loading={attachmentLoading || delLoading}
              accept="application/pdf,applocation/msword,image/*"
              fields={(file) => [
                {
                  fieldComponent: "TextField",
                  name: "description",
                  multiline: true,
                  rows: 5,
                  label: "Description",
                  // disabled: file.data?.id,
                },
              ]}
              onDelete={async (file) => {
                if (!id) return;

                setDelLoading(true);

                await deleteTaskAttachment(id, file.id)
                  .then(() => {
                    if (isSubTask) {
                      getTaskSubTaskAttachment(id, taskId, subTaskId);
                    } else if (isTask) {
                      getTaskAttachment(id, taskId);
                    }
                    getAttachment();
                  })
                  .finally(() => setDelLoading(false));
              }}
            />
          </Box>
        </Stack>
        <Box
          sx={{
            m: "0 16px 16px 16px",
            pt: "16px",
            borderTop: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <LoadingButton
            disabled={attachmentLoading || delLoading || !files.length || disableSubmitButton}
            loading={loading}
            onClick={handleSubmit}
            variant="contained"
          >
            Save
          </LoadingButton>
        </Box>
      </Drawer>
    );
  }
);
