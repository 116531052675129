import { Autocomplete, Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "../../app/stores/store";
import * as yup from "yup";
import BarChartGrid from "./BarChartGrid";
import ProjectStatusCard from "./components/ProjectStatusCard";
import LoadingSection from "../approval/components/LoadingSection";
import CSelect from "../../app/component/CSelect";
import { ChevronRight, Close, ExpandMore } from "@mui/icons-material";
import { TreeItem, TreeView } from "@mui/lab";
import { OrgList } from "./ProjectDataGrid";
import { BD_OTHERS_NAME, NO_SUBTASK_ID, PROJECT_BUDGET, ProjectCategory, Roles } from "../../app/config/enum";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { useFormik } from "formik";
import { TProjectCategoryAll } from "../../app/models/projectCategoryAll";
import DynamicBarDialog from "./DynamicBarDialog";
import SCurveSchedulePerformanceIndex from "../projectMonitoring/proposal/tabs/fid/tabs/SCurveSchedulePerformanceIndex";
import BarChartProjectMilestones from "./BarChartProjectMilestones";
import { TStatusDetailMembers } from "./utils/utils";

export type TSelectedProjectCategory = {
  value: string;
  label: string;
};

function Projects() {
  const { account } = useStore().accountStore;
  const { projectCategoryAll, getProjectCategoryAll } = useStore().projectCategoryAllStore;
  const { getUnitBusinessByOrganization, unitBusinessByOrganization } = useStore().generalStore;
  const { dataPointIndexBarChartMilestones, setBarChartMilestonesTasks, barMilestonesTask, getBarMilestonesTask, indexHelperBarChart } =
    useStore().DashboardStore;
  const { getOrganizationList } = useStore().organizationStore;
  const [selectedUnitBusiness, setSelectedUnitBusiness] = useState<TSelectedProjectCategory | undefined>(undefined);
  const [selectedProjectName, setSelectedProjectName] = useState<TSelectedProjectCategory | undefined>(undefined);
  const [organizationsList, setOrganizationsList] = useState<OrgList[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<OrgList>();
  const [status, setSelectedStatus] = useState<string | undefined>(undefined);

  const {
    projectBudgetDetailTask,
    projectBudgetTaskLoading,
    projectsList,
    dataPointIndexBarChart,
    statusTask,
    statusTaskLoading,
    projectBudgetTask,
    getTaskListByStatus,
    barMilestones,
    getStatusTask,
    getProjectBudgetTask,
    getProjectBudgetDetailTask,
    getProjectsList,
    getBarMilestones,
    getSCurveSPI,
    sCurveSpi,
  } = useStore().DashboardStore;

  const statusDetailMembers: TStatusDetailMembers[] = useMemo(() => {
    let result = [
      {
        title: "Task Count",
        count: statusTask?.taskCount,
        identifier: "TASK_COUNT",
      },
      {
        title: "Perencanaan",
        count: statusTask?.perencanaan,
        identifier: "PERENCANAAN",
      },
      {
        title: "Document Tender",
        count: statusTask?.documentTender,
        identifier: "DOCUMENT_TENDER",
      },
      {
        title: "Lelang",
        count: statusTask?.lelang,
        identifier: "LELANG",
      },
      {
        title: "Pemenang Lelang",
        count: statusTask?.pemenangLelang,
        identifier: "PEMENANG_LELANG",
      },
      {
        title: "Execution",
        count: (statusTask?.construction ?? 0) + (statusTask?.pembayaran ?? 0),
        identifier: "EXECUTION",
      },
      {
        title: "Completed",
        count: statusTask?.completed,
        identifier: "COMPLETED",
      },
    ];

    return result;
  }, [statusTask]);

  const isSuperAdmin = useMemo(() => {
    if (account?.roles.includes(Roles.Superadmin)) return true;

    return false;
  }, [account]);

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      year: new Date().getFullYear().toString(),
      organizationId: isSuperAdmin ? "" : account?.organizationId ?? "",
      projectCategory: "",
      projectName: "",
      month: new Date().getMonth().toString(),
    },
    validationSchema: {
      year: yup.string(),
      month: yup.string(),
      organizationId: yup.string(),
      projectCategory: yup.string(),
      projectName: yup.string(),
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const renderTreeItem = (nodes: any) => {
    return (
      <TreeItem onClick={() => handleChangeOrganizationId(nodes)} key={nodes.id} nodeId={nodes.id} label={nodes.name}>
        {Array.isArray(nodes.child) ? nodes.child.map((node: any) => renderTreeItem(node)) : null}
      </TreeItem>
    );
  };

  const isLoading = useMemo(() => {
    if (projectBudgetTaskLoading) return true;
    if (statusTaskLoading) return true;
    // if (barMilestonesLoading) return true;

    return false;
  }, [projectBudgetTaskLoading, statusTaskLoading]);

  const dynamicMuiGridSX = useMemo(() => {
    let isNbd = false;
    let muiSx = 4;

    if (projectCategoryAll.find((item) => item.id === values.projectCategory)?.category === ProjectCategory.NonBD) {
      isNbd = true;
      muiSx = 2.4;
    }

    return { isNbd, muiSx };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.projectCategory]);

  const handleChangeOrganizationId = (node: any) => {
    setSelectedOrg(node);
    setFieldValue("organizationId", node.id);
  };

  useEffect(() => {
    if (!values.projectCategory) return;

    let payload = {
      id: barMilestones?.ids[indexHelperBarChart] ?? "",
      proposalId: selectedProjectName?.value ?? "",
      projectCategoryId: values.projectCategory,
      organizationId: values.organizationId,
      unitBussinessId: selectedUnitBusiness?.value ?? "",
      year: values.year,
    };

    getBarMilestonesTask(payload, "tasks").then((res) => setBarChartMilestonesTasks(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPointIndexBarChartMilestones]);

  useEffect(() => {
    if (!values.projectCategory) return;

    let projectsListPayload = {
      organizationId: dynamicMuiGridSX.isNbd ? values.organizationId : NO_SUBTASK_ID,
      projectCategory: values.projectCategory,
      year: Number(values.year),
      unitBusinessId: dynamicMuiGridSX.isNbd ? selectedUnitBusiness?.value : NO_SUBTASK_ID,
    };

    getProjectsList(projectsListPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.organizationId, values.projectCategory, selectedUnitBusiness?.value]);

  useEffect(() => {
    let projectCategoryIdentifier = projectCategoryAll.find((item) => item.id === values.projectCategory)?.category;

    if (!values.projectCategory || projectCategoryIdentifier !== ProjectCategory.NonBD) return;

    let paramsGetUnitBusinessByOrganization = {
      organizationId: values.organizationId,
      category: projectCategoryIdentifier,
    };

    getUnitBusinessByOrganization(paramsGetUnitBusinessByOrganization);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.projectCategory, values.organizationId]);

  useEffect(() => {
    if (!values.projectCategory) return;

    let payload = {
      proposalId: selectedProjectName?.value ?? "",
      projectCategoryId: values.projectCategory,
      organizationId: values.organizationId,
      unitBussinessId: selectedUnitBusiness?.value ?? "",
      year: values.year,
    };

    getStatusTask(payload);
    getProjectBudgetTask({ ...payload, month: values.month });
    getSCurveSPI(payload);
    getBarMilestones(
      payload,
      projectCategoryAll.find((item) => item.id === values.projectCategory)?.category === ProjectCategory.NonBD ? "tasks" : "milestones"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectName, selectedUnitBusiness, values.organizationId, values.projectCategory, values.year, values.month]);

  useEffect(() => {
    if (!dataPointIndexBarChart) return;

    let payload = {
      proposalId: selectedProjectName?.value ?? "",
      projectCategoryId: values.projectCategory,
      organizationId: values.organizationId,
      unitBussinessId: selectedUnitBusiness?.value ?? "",
      year: values.year,
    };

    getProjectBudgetDetailTask(payload, dataPointIndexBarChart === PROJECT_BUDGET.PLAN ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPointIndexBarChart, getProjectBudgetDetailTask, selectedProjectName]);

  useEffect(() => {
    if (!values.projectCategory || !status) return;

    let payload = {
      proposalId: selectedProjectName?.value ?? "",
      projectCategoryId: values.projectCategory,
      organizationId: values.organizationId,
      unitBussinessId: selectedUnitBusiness?.value ?? "",
      year: values.year,
      status,
    };

    getTaskListByStatus(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectName, selectedUnitBusiness, values.organizationId, values.projectCategory, values.year, status]);

  // STARTUP
  useEffect(() => {
    getOrganizationList().then((organizationRes) => {
      setOrganizationsList(organizationRes?.map((item) => ({ ...item, text: item.name, value: item.id } as any)) ?? []);
    });
    getProjectCategoryAll().then((res: TProjectCategoryAll[]) => {
      let temp: string = res.find((item: TProjectCategoryAll) => item.name === BD_OTHERS_NAME)?.id ?? "";
      setFieldValue("projectCategory", temp);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack gap={3}>
      <Typography sx={{ textAlign: "center", my: "12px" }} variant="h1" color="black">
        Projects Dashboard
      </Typography>
      <Grid
        container
        spacing={1}
        sx={{
          alignItems: "center",
          mt: "2px",
          backgroundColor: "white",
          p: "12px 12px 24px 12px",
          borderRadius: "10px",
          boxShadow: "0 20px 25px -5px rgba(0,0,0,0.1), 0 8px 10px -6px rgba(0,0,0,0.1)",
        }}
      >
        <Grid item xs={12} sx={{ mb: "12px" }}>
          <Typography variant="h2" color="black">
            Filter Box
          </Typography>
        </Grid>
        <Grid item xs={dynamicMuiGridSX.muiSx}>
          <CSelect
            value={values.projectCategory}
            onChange={handleChange}
            label="Project Category"
            name="projectCategory"
            size="small"
            options={projectCategoryAll.map((item) => ({ value: item.id, label: item.name }))}
          />
        </Grid>
        {dynamicMuiGridSX.isNbd ? (
          <>
            <Grid item xs={dynamicMuiGridSX.muiSx}>
              <FormControl fullWidth>
                <InputLabel size="small" id="organization-select-id">
                  Organization
                </InputLabel>
                <Select
                  labelId="organization-select-id"
                  id="organization-select-id"
                  label="Organization"
                  value={values.organizationId}
                  disabled={!isSuperAdmin}
                  displayEmpty
                  size="small"
                  endAdornment={
                    !!values.organizationId && isSuperAdmin ? (
                      <Close
                        onClick={() => {
                          setSelectedOrg(undefined);
                          setFieldValue("organizationId", "");
                        }}
                        sx={{ mr: 2, cursor: "pointer" }}
                        fontSize="small"
                      />
                    ) : undefined
                  }
                >
                  <MenuItem sx={{ display: "none" }} value={values.organizationId}>
                    {isSuperAdmin ? selectedOrg?.name : account?.organizationName}
                  </MenuItem>
                  <TreeView
                    aria-label="select organization"
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                    sx={{ height: 240, flexGrow: 1, overflowY: "auto" }}
                  >
                    {!!organizationsList?.length &&
                      organizationsList.map((item) => {
                        return renderTreeItem(item);
                      })}
                  </TreeView>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={dynamicMuiGridSX.muiSx}>
              <Autocomplete
                size="small"
                disablePortal
                id="unitBusinessByOrganization"
                getOptionLabel={(option) => option.label}
                value={selectedUnitBusiness}
                options={unitBusinessByOrganization.map((i) => ({
                  label: i.name,
                  value: i.id,
                }))}
                onChange={(e, v) => setSelectedUnitBusiness(v ?? undefined)}
                renderInput={(params) => <TextField {...params} value={selectedUnitBusiness?.label} label="Mata Anggaran" />}
              />
            </Grid>
          </>
        ) : undefined}

        <Grid item xs={dynamicMuiGridSX.muiSx}>
          <Autocomplete
            size="small"
            disablePortal
            id="projectName"
            getOptionLabel={(option) => option.label}
            disabled={!values.projectCategory}
            value={selectedProjectName}
            options={projectsList.map((i) => ({
              label: i.name,
              value: i.id,
            }))}
            onChange={(e, v) => setSelectedProjectName(v ?? undefined)}
            renderInput={(params) => <TextField {...params} value={selectedProjectName?.label} label="Project Name" />}
          />
        </Grid>
        <Grid item xs={dynamicMuiGridSX.muiSx}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Year"
              componentsProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              value={values.year}
              views={["year"]}
              inputFormat="yyyy"
              onChange={(e: any) => setFieldValue("year", format(new Date(e), "yyyy") === "1970" ? null : format(new Date(e), "yyyy"))}
              renderInput={(props) => <TextField onFocus={(e) => e.target.blur()} disabled size="small" fullWidth name="startYear" {...props} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      {isLoading ? (
        <LoadingSection />
      ) : (
        <>
          <Grid container spacing={2}>
            {statusDetailMembers.map((item: TStatusDetailMembers) => (
              <Grid item xs={1.7} onClick={() => setSelectedStatus(item.identifier)}>
                <ProjectStatusCard title={item.title} count={item.count} />
              </Grid>
            ))}
            {projectBudgetTask && (
              <>
                <Grid item xs={6}>
                  <Paper
                    sx={{
                      height: "470px",
                      borderRadius: "10px",
                      p: "12px 20px 20px 20px",
                      boxShadow: "0 20px 25px -5px rgba(0,0,0,0.1), 0 8px 10px -6px rgba(0,0,0,0.1)",
                    }}
                  >
                    <Grid container sx={{ display: "flex" }}>
                      <Grid item xs={8}>
                        <Typography>Project Budgets (dalam jutaan)</Typography>
                        <Typography
                          sx={{ color: projectBudgetTask.currently > 100 ? "red" : "#7CC444" }}
                          variant="h3"
                        >{`Current Progress: ${projectBudgetTask.currently.toLocaleString()}% (${
                          projectBudgetTask.currently > 100 ? "Over Budget" : "On Budget"
                        })`}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Month"
                            componentsProps={{
                              actionBar: {
                                actions: ["clear"],
                              },
                            }}
                            value={values.month}
                            views={["month"]}
                            inputFormat="MMMM"
                            onChange={(e: any) => setFieldValue("month", e ? format(new Date(e), "M") : null)}
                            renderInput={(props) => (
                              <TextField onFocus={(e) => e.target.blur()} disabled size="small" fullWidth name="startYear" {...props} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>

                    <BarChartGrid
                      isCost
                      title="Project Budget"
                      currently={projectBudgetTask.currently}
                      labels={projectBudgetTask.labels}
                      name="Cost"
                      series={projectBudgetTask.series}
                    />
                  </Paper>
                </Grid>

                {!!projectBudgetDetailTask && (
                  <DynamicBarDialog
                    isCost
                    labels={projectBudgetDetailTask.labels}
                    series={projectBudgetDetailTask.series}
                    name="Cost"
                    openDialog={!!dataPointIndexBarChart}
                  />
                )}
              </>
            )}

            {sCurveSpi && (
              <Grid item xs={6}>
                <Paper
                  sx={{
                    height: "470px",
                    borderRadius: "10px",
                    p: "12px 20px 20px 20px",
                    boxShadow: "0 20px 25px -5px rgba(0,0,0,0.1), 0 8px 10px -6px rgba(0,0,0,0.1)",
                  }}
                >
                  <Typography>S-Curve</Typography>
                  <Box sx={{ mt: "24px" }}>
                    <SCurveSchedulePerformanceIndex data={sCurveSpi} />
                  </Box>
                </Paper>
              </Grid>
            )}
            {barMilestones && (
              <>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      height: "470px",
                      borderRadius: "10px",
                      p: "12px 20px 20px 20px",
                      boxShadow: "0 20px 25px -5px rgba(0,0,0,0.1), 0 8px 10px -6px rgba(0,0,0,0.1)",
                    }}
                  >
                    <Typography>{dynamicMuiGridSX.isNbd ? "Project Task" : "Mata Anggaran"} (dalam jutaan)</Typography>
                    <BarChartProjectMilestones
                      title="Project Budget"
                      currently={barMilestones.currently}
                      labels={barMilestones.labels}
                      name="Cost"
                      series={barMilestones.series}
                    />
                  </Paper>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}

      {dataPointIndexBarChartMilestones && projectCategoryAll.find((item) => item.id === values.projectCategory)?.category === ProjectCategory.BD ? (
        <DynamicBarDialog
          isCost
          labels={barMilestonesTask?.labels ?? []}
          series={barMilestonesTask?.series ?? []}
          name="Cost"
          openDialog={dataPointIndexBarChartMilestones}
        />
      ) : undefined}
    </Stack>
  );
}

export default observer(Projects);
