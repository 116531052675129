import { useState } from "react";
import { MenuItem, SelectProps, Box, TextField } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { TreeItem, TreeView } from "@mui/lab";

type TProps = SelectProps & {
  label: string;
  list: Array<any>;
  defaultData?: any;
  onChangeData: (value: string) => void;
  errorData?: any;
  errorText?: string;
};

type TRenderTree = {
  id: string;
  name: string;
  level: number | string;
  child?: readonly TRenderTree[];
}

function SelectGroup(props: TProps) {
  const { list, label, onChangeData, errorData, errorText, defaultData } = props;
  const [displayTextHelper, setDisplayTextHelper] = useState<string>(defaultData?.text ? defaultData.text : "");
  const [optionValue, setOptionValue] = useState(defaultData?.value ? defaultData.value : "");

  const handleOnChange = (e: any, value?: any) => {
    onChangeData(value ? value : e.target.value);
    setOptionValue(value ? value : e.target.value);
  };

  const renderTree = (nodes: TRenderTree) => (
    <TreeItem
      onClick={(e) => {
        setDisplayTextHelper(nodes.name);
        handleOnChange(e, nodes);
      }}
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
    >
      {Array.isArray(nodes.child) ? nodes.child.map((node: any) => renderTree(node)) : null}
    </TreeItem>
  );

  return (
    <Box sx={{ width: "100%" }}>
      <TextField
        sx={{ width: "100%" }}
        select
        error={errorData}
        helperText={errorText}
        name="organizationId"
        id="demo-simple-select"
        value={optionValue}
        label={label}
        onChange={(e) => handleOnChange(e)}
        SelectProps={{ MenuProps: { sx: { maxHeight: "300px" } } }}
      >
        <MenuItem value={""}>None</MenuItem>
        {optionValue !== "" ? (
          <MenuItem style={{ display: "none" }} key={1} value={optionValue}>
            {displayTextHelper}
          </MenuItem>
        ) : (
          ""
        )}
        <TreeView aria-label="rich object" defaultCollapseIcon={<ExpandMore />} defaultExpanded={["root"]} defaultExpandIcon={<ExpandLess />}>
          <>
            {list.map((item) => {
              return <>{renderTree(item)}</>;
            })}
          </>
        </TreeView>
      </TextField>
    </Box>
  );
}

export default observer(SelectGroup);
