import { Box } from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import GeneralLogDataGrid from "./GeneralLogDataGrid";

export default function GeneralLogPageIndex() {
  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "General Log",
            href: "/master/auction-status",
          },
          {
            label: "General Log List",
            href: "/master/auction-status",
          },
        ]}
        pageTitle="General Log List"
      />
      <GeneralLogDataGrid />
    </Box>
  );
}
