import { IconButton, Paper, Stack, TextField } from "@mui/material";
import { StyledDataGrid } from "../../app/component/StyledDataGrid";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Delete, Edit, Search } from "@mui/icons-material";
import { useDebounce } from "../../app/hook/useDebounce";

function AuctionStatusDataGrid() {
  const { auctionStatusStore, dialogStore } = useStore();
  const {
    getAuctionStatusGrid,
    auctionStatusGrid,
    deleteAuctionStatus,
    auctionStatusGridLoading,
    setSelectedAuctionStatus,
    setQueryParams,
    queryparams,
  } = auctionStatusStore;

  const handleSearch = useDebounce(2000, (e) => setQueryParams("search", e.target.value));

  const onDelete = (id: string) =>
    dialogStore.open({
      action: async () => await deleteAuctionStatus(id).then(getAuctionStatusGrid),
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      actionButtonProps: {
        color: "error",
      },
      description: "Are you sure want to delete this item?",
    });

  useEffect(() => {
    getAuctionStatusGrid();
  }, [getAuctionStatusGrid]);

  return (
    <Paper sx={{ p: "16px" }}>
      <TextField fullWidth placeholder="Search" InputProps={{ startAdornment: <Search /> }} sx={{ mb: "24px" }} onChange={handleSearch} />
      <StyledDataGrid
        loading={auctionStatusGridLoading}
        rows={auctionStatusGrid.data}
        columns={[
          {
            field: "status",
            headerName: "Status",
            flex: 1,
          },
          {
            field: "description",
            headerName: "Description",
            flex: 1,
          },
          {
            field: "action",
            headerName: "Action",
            width: 100,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            resizable: false,
            renderCell: (params) => (
              <Stack direction="row" spacing="8px">
                <IconButton disabled={params.row.status === "Gagal_Tender"} size="small" onClick={() => setSelectedAuctionStatus(params.row as any)}>
                  <Edit />
                </IconButton>
                <IconButton disabled={params.row.status === "Gagal_Tender"} size="small" onClick={() => onDelete(params.row.id)}>
                  <Delete />
                </IconButton>
              </Stack>
            ),
          },
        ]}
        disableSelectionOnClick
        autoHeight
        pagination
        pageSize={queryparams.pageSize}
        page={queryparams.currentPage}
        onPageSizeChange={(v) => setQueryParams("pageSize", v)}
        paginationMode="server"
        onPageChange={(v) => setQueryParams("currentPage", v)}
        rowCount={auctionStatusGrid.rowCount}
      />
    </Paper>
  );
}

export default observer(AuctionStatusDataGrid);
