import { makeAutoObservable, reaction, runInAction } from "mobx";
import { TChangeRequest, TChangeRequestGrid } from "../models/changeRequest";
import agent from "../api/agent";

export default class ChangeRequestStore {
  currentPage = 0;
  pageSize = 5;
  search = "";
  status: string = "all";
  rowCount = 0;

  changeRequest: TChangeRequest[] = [];
  isSubmitted = false;

  loadingDelete = false;
  loadingList = false;

  selectedChangeRequest: TChangeRequest | null = null;
  changeRequestDetail: TChangeRequest | null = null;
  changeRequestDetailLoading = false;
  keysHelper: string[] = [];

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.isSubmitted,
      (submit) => {
        if (submit) {
          this.getChangeRequest();
        }
        this.isSubmitted = false;
      }
    );
  }

  setSelectedChangeRequest = (changeRequest: TChangeRequest | null) => {
    this.selectedChangeRequest = changeRequest;
  };

  userAction = async (changeRequestId: string, action: string) => {
    try {
      await agent.ChangeRequest.userAction(changeRequestId, action);
    } catch (e) {
      throw e;
    }
  };

  getChangeRequestDetail = async (changeRequestId: string) => {
    this.changeRequestDetailLoading = true;
    try {
      const res = await agent.ChangeRequest.detail(changeRequestId);

      this.setChangeRequestDetail(res);
      this.setKeysHelper(Object.keys(res.nextValue));
    } catch (e) {
      throw e;
    } finally {
      this.changeRequestDetailLoading = false;
    }
  };

  setChangeRequestDetail = (changeRequestDetail: TChangeRequest | null) => {
    this.changeRequestDetail = changeRequestDetail;
  };

  createDataGridParam = () => {
    const params = new URLSearchParams();
    params.append("currentPage", this.currentPage.toString());
    params.append("pageSize", this.pageSize.toString());
    this.status !== "all" && params.append("status", this.status);
    params.append("Search", encodeURIComponent(this.search));
    return params;
  };

  getChangeRequest = async () => {
    this.loadingList = true;
    try {
      const urlParams = this.createDataGridParam();
      const changeRequestData = await agent.ChangeRequest.listGrid(urlParams);
      this.setChangeRequest(changeRequestData);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingList = false));
    }
  };

  resetChangeRequest = () => {
    this.changeRequest = [];
  };

  resetChangeRequestDetail = () => {
    this.changeRequestDetail = null;
    this.keysHelper = [];
  };

  setChangeRequest = (changeRequest: TChangeRequestGrid) => {
    this.changeRequest = changeRequest.data;
    this.rowCount = changeRequest.rowCount;
  };

  setKeysHelper = (data: any) => {
    this.keysHelper = data;
  };

  setSubmitted = () => {
    this.isSubmitted = true;
  };

  setCurrentPage = (data: number) => {
    this.currentPage = data;
  };

  setPageSize = (data: number) => {
    this.pageSize = data;
  };

  setSearch = (data: string) => {
    this.search = data;
  };

  setStatus = (data: string) => {
    this.status = data;
  };
}
