import { Button } from "@mui/material";
import * as FileSaver from "file-saver";
import { patraBase64 } from "../../../app/utils/utils";
import { format } from "date-fns";
import { Workbook } from "exceljs";

type TExportToExcel = {
  apiData: any;
  fileName: any;
};

export const ExportToExcel = ({ apiData, fileName }: TExportToExcel) => {
  const exportExcel = (apiData: any, fileName: any) => {
    const workbook = new Workbook();
    const sheet = workbook.addWorksheet("My Sheet");

    sheet.getRow(1).font = {
      size: 14,
      bold: true,
    };

    sheet.columns = [
      {
        header: "Project Name",
        key: "title",
        width: 30,
      },
      { header: "Category", key: "projectCategoryName", width: 30 },
      {
        header: "Unit Operasi",
        key: "regionalName",
        width: 20,
      },
      {
        header: "Organization",
        key: "organization",
        width: 20,
      },
      {
        header: "Project Status",
        key: "projectStatusName",
        width: 15,
      },
      {
        header: "Cost (Rp)",
        key: "cost",
        width: 10,
      },
      {
        header: "Realisasi Cost (Rp)",
        key: "realizationCost",
        width: 10,
      },
      {
        header: "Realisasi Progress (%)",
        key: "realizationProgress",
        width: 10,
      },
      {
        header: "SPI (%)",
        key: "spi",
        width: 10,
      },
      {
        header: "CPI (%)",
        key: "cpi",
        width: 10,
      },
    ];

    // FOR SKIPPING ROW 1,2 AND 3
    for (let index = 1; index <= 3; index++) {
      sheet.insertRow(index, "");
    }

    // IMPORT IMAGE BY BASE64
    const imageId2 = workbook.addImage({
      base64: patraBase64,
      extension: "png",
    });

    // IMPORT BY PATH
    // const imageId1 = workbook.addImage({
    //   filename: "./logopng.png",
    //   extension: "png",
    // });

    // ADD VALUE BY SPECIFIC ROW AND COLUMN
    sheet.getRow(1).height = 40;
    sheet.getRow(2).getCell(1).value = "Created Date";
    sheet.getRow(2).getCell(2).value = format(new Date(), "dd MMMM yyyy");
    sheet.addImage(imageId2, {
      tl: { col: 0, row: 0 },
      ext: { width: 200, height: 40 },
    });

    sheet.getRow(4).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFF00" },
    };

    sheet.getRow(4).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };

    // sheet.getRow(5).fill = {
    //   type: "pattern",
    //   pattern: "solid",
    //   fgColor: { argb: "A0C5E3" },
    // };

    // sheet.getRow(5).border = {
    //   top: { style: "thin" },
    //   left: { style: "thin" },
    //   bottom: { style: "thin" },
    //   right: { style: "thin" },
    // };

    // for (let index = 1; index <= apiData.length; index++) {
    //   if (index > 4) {
    //     // sheet.getRow(index).border = {
    //     //   top: { style: "thin" },
    //     //   left: { style: "thin" },
    //     //   bottom: { style: "thin" },
    //     //   right: { style: "thin" },
    //     // };
    //     // sheet.getRow(index).fill = {
    //     //   type: "pattern",
    //     //   pattern: "solid",
    //     //   fgColor: { argb: "A0C5E3" },
    //     // };
    //   }
    // }

    // FORMAT COLUMN
    for (let index = 6; index <= 10; index++) {
      let temp = index === 6 || index === 7 ? `"Rp"#,##;[Red]\-"Rp"#,##` : `#,##"%";[Red]\-#,##"%"`;
      sheet.getColumn(index).numFmt = temp;
    }

    // ADD DATA BY ROW
    apiData?.map((item: any) => {
      sheet.addRow({
        title: item.title,
        projectCategoryName: item.projectCategoryName,
        regionalName: item.regionalName,
        organization: item.organization,
        projectStatusName: item.projectStatusName,
        cost: item.cost,
        realizationCost: item.realizationCost,
        realizationProgress: item.realizationProgress,
        spi: item.spi,
        cpi: item.cpi,
      });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      FileSaver.saveAs(new Blob([buffer], { type: "application/octet-stream" }), `${fileName}.xlsx`);
    });
  };

  return (
    <Button variant="outlined" color="primary" onClick={(e) => exportExcel(apiData, fileName)}>
      Export
    </Button>
  );
};
