import { useFormik } from "formik";
import DrawerForm from "../../app/component/DrawerForm";
import * as yup from 'yup';
import { TextField } from "@mui/material";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";

const validationSchema = yup.object({
  name: yup.string().required(),
  description: yup.string(),
})

type ValidationSchema = yup.InferType<typeof validationSchema>

function AssetCategoryForm({ isOpen, onClose }: {isOpen: boolean, onClose: () => any}) {
  const { addAssetCategory, selectedAssetCategory, editAssetCategory, getAssetCategoryGrid, setSelectedAssetCategory } = useStore().assetCategoryStore
  
  const initialValues: ValidationSchema = useMemo(() => {
    if (selectedAssetCategory) {
      return selectedAssetCategory
    } else {
      return { name: '', description: '' }
    }
  }, [selectedAssetCategory])

  const onSubmit = async (values: ValidationSchema) => {
    if (selectedAssetCategory) {
      await editAssetCategory(selectedAssetCategory.id, values)
        .then(() => {
          getAssetCategoryGrid()
          onClose()
          setSelectedAssetCategory(null)
          resetForm()
          setErrors({
            name: undefined
          })
        })
    } else {
      await addAssetCategory(values)
        .then(() => {
          getAssetCategoryGrid()
          onClose()
          setSelectedAssetCategory(null)
          resetForm()
          setErrors({
            name: undefined
          })
        })
    }
  }

  const { handleSubmit, resetForm, setErrors, handleChange, values, errors, isValid, isSubmitting, dirty } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  })
  
  return (
    <DrawerForm
      title={`${selectedAssetCategory ? 'Edit' : 'Add'} Asset Category`}
      isOpen={isOpen || !!selectedAssetCategory}
      onClose={() => {
        onClose()
        setSelectedAssetCategory(null)
      }}
      onSubmit={handleSubmit}
      disableAction={!isValid || !dirty}
      isLoading={isSubmitting}
      isEdit={!!selectedAssetCategory}
    >
      <TextField 
        label="Name"
        fullWidth
        name="name"
        value={values.name}
        error={!!errors.name}
        helperText={errors.name}
        onChange={handleChange}
      />
      <TextField 
        label="Description"
        fullWidth
        name="description"
        value={values.description}
        error={!!errors.description}
        helperText={errors.description}
        onChange={handleChange}
        multiline
        rows={5}
      />
    </DrawerForm>
  )
}

export default observer(AssetCategoryForm)