import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import SKTLDataGrid from "../../projectMonitoring/proposal/tabs/fid/tabs/SKTLDataGrid";

type TBarDialogProps = {
  openChartDialog: boolean;
  setOpenChartDialog: Dispatch<SetStateAction<boolean>>;
  proposalId: string;
};

function DynamicBarShowChartDialog({ proposalId, setOpenChartDialog, openChartDialog }: TBarDialogProps) {
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const descriptionElementRef = useRef<HTMLElement>(null);

  const handleClose = () => setOpenChartDialog(false);

  return (
    <Dialog fullWidth maxWidth="lg" open={openChartDialog} onClose={handleClose}>
      <DialogTitle id="scroll-dialog-title">SKTL</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <SKTLDataGrid proposalIdFromProjectDashboard={proposalId} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default observer(DynamicBarShowChartDialog);
