import { Box } from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { Add } from "@mui/icons-material";
import RegionalDataGrid from "./RegionalDataGrid";
import RegionalForm from "./RegionalForm";
import { useState } from "react";

export default function RegionalPageIndex() {
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false)
  
  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: 'Regional',
            href: '/master/regional'
          },
          {
            label: 'Regional List',
            href: '/master/regional'
          },
        ]}
        pageTitle="Regional"
      >
        <DashboardLayoutHeader.Button 
          startIcon={<Add />}
          variant="contained"
          onClick={() => setIsOpenForm(true)}
        >
          Add Regional
        </DashboardLayoutHeader.Button>
      </DashboardLayoutHeader>
      <RegionalDataGrid />
      <RegionalForm isOpen={isOpenForm} onClose={() => setIsOpenForm(false)} />
    </Box>
  )
}