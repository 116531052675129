import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Button, Box, Dialog, DialogContent, Typography, TextField } from "@mui/material";
import { TReviewerAttachmentLogPayload } from "../../../../../../../app/models/reviewerLog";
import { CheckOutlined, CloseOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { ReviewerLog } from "../../../../../../../app/config/enum";
import { useStore } from "../../../../../../../app/stores/store";
import { Stack } from "@mui/system";
import useQuery from "../../../../../../../app/hook/useQuery";

type TCommentAttachmentProps = {
  attachmentComment: string;
  attachmentStatus: string;
  attachmentId: string;
  disableSubmitButton?: boolean;
  taskId: string;
  subTask: any;
  setIsOpenDrawer: Dispatch<SetStateAction<any>>;
};

export default function CommentAttachment({
  setIsOpenDrawer,
  subTask,
  taskId,
  disableSubmitButton = false,
  attachmentComment = "",
  attachmentStatus = "",
  attachmentId,
}: TCommentAttachmentProps) {
  const { reviewerLogStore, dialogStore, milestonetore } = useStore();
  const { reviewerLogLoading, editReviewerLogAttachment } = reviewerLogStore;
  // const { getTaskAttachment, getTaskSubTaskAttachment } = milestonetore;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const query = useQuery();
  const id = query.get("id");

  const onSubmit = (status: string) => {
    if (!id) return;

    setIsLoading(true);

    const payload: TReviewerAttachmentLogPayload = {
      id: attachmentId,
      status: status,
      notes: comment,
    };

    if (status === ReviewerLog.PROCEED) {
      dialogStore.open({
        action: async () =>
          await editReviewerLogAttachment(id, payload).then(() => {
            setIsOpenDrawer(false);
            // subTask ? getTaskSubTaskAttachment(id, subTask.tasksId, subTask.subTaskId) : getTaskAttachment(id, taskId);
            setIsOpen(false);
            setIsLoading(false);
          }),
        closeButtonProps: {
          onClick: () => {
            dialogStore.close();
            setIsLoading(false);
          },
        },
        actionText: "OK",
        title: "Approve",
        closeText: "Cancel",
        actionButtonProps: {
          color: "error",
        },
        description: "Are you sure want to approve this attachment?",
      });
    } else {
      editReviewerLogAttachment(id, payload).then(() => {
        setIsOpenDrawer(false);
        // subTask ? getTaskSubTaskAttachment(id, subTask.subTaskId, subTask.subTaskId) : getTaskAttachment(id, taskId);
        setIsOpen(false);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    setIsLoading(false);
    setComment(attachmentComment);
  }, [attachmentComment]);

  return (
    <Box>
      {reviewerLogLoading || isLoading ? (
        <>Loading...</>
      ) : (
        <>
          {attachmentStatus !== "" || attachmentStatus === null ? (
            <>
              {attachmentStatus === ReviewerLog.NOT_PROCEED ? (
                <>
                  <Button disabled={reviewerLogLoading || isLoading} variant="outlined" color="info" onClick={() => setIsOpen(true)}>
                    Comment
                  </Button>
                </>
              ) : (
                <>
                  <Button disabled variant="contained" color="error">
                    Reviewed
                  </Button>
                </>
              )}
            </>
          ) : (
            <Box sx={{ display: "flex" }}>
              <LoadingButton
                loading={isLoading || reviewerLogLoading}
                disabled={reviewerLogLoading || disableSubmitButton}
                sx={{ mr: "12px" }}
                size="small"
                variant="contained"
                color="error"
                onClick={() => setIsOpen(true)}
              >
                <CloseOutlined />
              </LoadingButton>
              <LoadingButton
                loading={isLoading}
                size="small"
                disabled={reviewerLogLoading || disableSubmitButton}
                variant="contained"
                color="success"
              >
                <CheckOutlined onClick={() => onSubmit(ReviewerLog.PROCEED)} />
              </LoadingButton>
            </Box>
          )}
        </>
      )}

      <Dialog open={isOpen}>
        <DialogContent sx={{ width: "365px" }}>
          <Typography sx={{ mb: "24px" }} color="black" fontWeight={700} fontSize="14px">
            Add Comment
          </Typography>
          <TextField
            multiline
            rows={5}
            value={comment}
            fullWidth
            disabled={attachmentStatus !== ""}
            label="Comment"
            onChange={(e) => setComment(e.target.value)}
          />
          <Stack mt={2} justifyContent="flex-end" direction="row" gap={1}>
            <Button onClick={() => setIsOpen(false)} color="inherit" variant="outlined">
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              disabled={comment === "" || attachmentStatus !== ""}
              loading={isLoading}
              variant="contained"
              onClick={() => onSubmit(ReviewerLog.NOT_PROCEED)}
            >
              Add
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
