import { useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import StatusDetailDialog from "../StatusDetailDialog";

type TProjectStatusCard = {
  title: string;
  count?: number;
};

export default function ProjectStatusCard({ title, count = 0 }: TProjectStatusCard) {
  const [isOpenStatusDetail, setIsOpenStatusDetail] = useState(false);

  return (
    <Box>
      <Paper
        onClick={() => setIsOpenStatusDetail(!isOpenStatusDetail)}
        sx={{
          cursor: "pointer",
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "120px",
          borderRadius: "12px",
          p: "10px 20px 20px 20px",
          boxShadow: "0 20px 25px -5px rgba(0,0,0,0.1), 0 8px 10px -6px rgba(0,0,0,0.1)",
        }}
      >
        <Typography variant="h3">{title}</Typography>
        <Box sx={{ pt: "12px", textAlign: "center", fontWeight: "bold", fontSize: "24px" }}>{count}</Box>
      </Paper>
      <StatusDetailDialog isOpen={isOpenStatusDetail} setIsOpen={setIsOpenStatusDetail} />
    </Box>
  );
}
