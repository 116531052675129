import { useFormik } from "formik";
import { TextField } from "@mui/material";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import DrawerForm from "../../app/component/DrawerForm";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup.string().required(),
  description: yup.string().required(),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function PeruntukanForm({ isOpen, onClose }: { isOpen: boolean; onClose: () => any }) {
  const { addPeruntukan, setSelectedPeruntukan, editPeruntukan, getPeruntukanGrid, selectedPeruntukan } = useStore().peruntukanStore;

  const initialValues: ValidationSchema = useMemo(() => {
    if (selectedPeruntukan) {
      return selectedPeruntukan;
    } else {
      return {
        name: "",
        description: "",
      };
    }
  }, [selectedPeruntukan]);

  const onSubmit = async (values: ValidationSchema) => {
    if (selectedPeruntukan) {
      await editPeruntukan(selectedPeruntukan.id, values).then(() => {
        getPeruntukanGrid();
        onClose();
        setSelectedPeruntukan(null);
        resetForm();
        setErrors({
          name: undefined,
          description: undefined,
        });
      });
    } else {
      await addPeruntukan(values).then(() => {
        getPeruntukanGrid();
        onClose();
        setSelectedPeruntukan(null);
        resetForm();
        setErrors({
          name: undefined,
          description: undefined,
        });
      });
    }
  };

  const { handleSubmit, resetForm, setErrors, handleChange, values, errors, isValid, isSubmitting, dirty } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <DrawerForm
      title={`${selectedPeruntukan ? "Edit" : "Add"} Peruntukan`}
      isOpen={isOpen || !!selectedPeruntukan}
      onClose={() => {
        onClose();
        setSelectedPeruntukan(null);
      }}
      onSubmit={handleSubmit}
      disableAction={!isValid || !dirty}
      isLoading={isSubmitting}
      isEdit={!!selectedPeruntukan}
    >
      <TextField label="Name" fullWidth name="name" value={values.name} error={!!errors.name} helperText={errors.name} onChange={handleChange} />
      <TextField
        onChange={handleChange}
        sx={{ mb: "12px" }}
        fullWidth
        label="Description"
        name="description"
        value={values.description}
        error={!!errors.description}
        helperText={errors.description}
        multiline
        rows={3}
      />
    </DrawerForm>
  );
}

export default observer(PeruntukanForm);
