import { useEffect } from "react";
import { StyledDataGrid } from "../../app/component/StyledDataGrid";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { IconButton } from "@mui/material";
import { VisibilityOutlined } from "@mui/icons-material";
import { history } from "../..";
import { ProjectStatus } from "../../app/config/enum";

export const ReFidDataGrid = observer(() => {
  const { getReFidGrid, reFidGrid } = useStore().reFidStore;

  useEffect(() => {
    getReFidGrid();
  }, [getReFidGrid]);

  return (
    <StyledDataGrid
      autoHeight
      columns={[
        {
          field: "proposalName",
          width: 230,
          headerName: "Proposal Name",
        },
        {
          field: "category",
          width: 200,
          headerName: "Category",
        },
        {
          field: "requesterName",
          width: 150,
          headerName: "Requested By",
        },
        {
          field: "status",
          width: 110,
          headerName: "Tahapan",
        },
        {
          field: "approval",
          width: 100,
          headerName: "Progress",
        },
        {
          field: "description",
          width: 200,
          headerName: "Description",
        },
        {
          field: "cost",
          width: 150,
          headerName: "Cost",
          renderCell: (params) => "Rp " + params.row.cost.toLocaleString(),
        },
        {
          field: "action",
          width: 80,
          headerName: "Action",
          renderCell: (params) => (
            <IconButton
              sx={{ ml: "auto" }}
              onClick={
                () =>
                  params.row.status === ProjectStatus.Proposal
                    ? history.push(
                        `/project-monitoring/proposal/list/${params.row.category}/request-refid?proposalId=${params.row.proposalId}&title=${params.row.proposalName}`
                      )
                    : history.push(`/project-monitoring/re-fid/list/${params.row.id}?proposalId=${params.row.proposalId}&status=${params.row.status}`)

                // history.push(`/project-monitoring/re-fid/list/${params.row.id}?proposalId=${params.row.proposalId}&status=${params.row.status}`)
              }
            >
              <VisibilityOutlined />
            </IconButton>
          ),
        },
      ]}
      rows={reFidGrid.data}
      rowCount={reFidGrid.rowCount}
      disableSelectionOnClick
    />
  );
});
