import { Button, Typography, ButtonProps, Box } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { ChangeEvent, useContext, useState } from "react";
import { RkdFormikContext } from "./RkdForm";
import { observer } from "mobx-react-lite";

interface PropsButtonUpload extends ButtonProps{
  text: string,
  typeFile: Array<string>, 
  photo?: any;
}

const RkdFileUpload = (props: PropsButtonUpload) => {
  const { text, typeFile, name, photo } = props;      
  const [photoPreview, setPhotoPreview] = useState(photo ? photo : '/assets/user.png')
  const context = useContext(RkdFormikContext); 
  const type = typeFile.toString();
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    
    const file = new FormData();
    file.append(
      "File",
      e.target.files[0],
      e.target.files[0].name
    ); 

    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0])    
    reader.onloadend = () => {
      setPhotoPreview([reader.result])      
    }    
    
    context!.setFieldValue(name!, file);    
  }

  return (
    <Box sx={{ my: '8px' }}>
      <Button
          component="label"
          variant="outlined"
          color= "primary"                    
          sx={{ width: '100%', height: 300, padding: 0 }}
      >        
        <input type="file" accept={type} hidden onChange={(e) => handleOnChange(e)} name={name} />  
        <Box sx={{ display: 'flex', alignItems: 'center', paddingX: '12px', paddingY: '8px', bgcolor: 'primary.main', color:'primary.contrastText' }}>
          <UploadFileIcon sx={{ mr: '6px' }} />          
          <Typography variant='h3' color='primary.contrastText'>{text}</Typography>
        </Box>        
        <img src={photoPreview} alt="" style={{ objectFit: 'cover', width: '100%', height: '100%', position:'absolute', zIndex:'-1' }}/>              
      </Button>
      <Typography variant='subtitle1'>*You can only upload file with type {type}</Typography>      
    </Box>
  );
};

export default observer(RkdFileUpload);