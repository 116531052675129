import { makeAutoObservable, reaction, runInAction, observable } from "mobx";
import { TAmandement, TAmandementQueryParams, TApproveRejectAmandementBody } from "../models/amandement";
import { DataGridResult } from "../models/materialUI/dataGrid";
import agent from "../api/agent";

export class AmandementStore {
  amandementGrid: DataGridResult<TAmandement[]> = new DataGridResult<TAmandement[]>([], 0);
  queryParams: TAmandementQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: "",
  };

  selectedAmandementGrid: TAmandement | null = null;
  amandementGridLoading = false;
  loadingForm = false;

  constructor() {
    makeAutoObservable(this, {
      loadingForm: observable,
    });

    reaction(
      () => this.queryParams,
      () => {
        this.getAmandementGrid();
      }
    );
  }

  approveRejectAmandement = async (status: string, payload: TApproveRejectAmandementBody) => {
    try {
      await agent.Amandement.approveRejectAmandement(status, payload);
    } catch (e) {
      throw e;
    }
  };

  getAmandementGrid = async (proposalId?: string) => {
    this.amandementGridLoading = true;

    try {
      const res = await agent.Amandement.getAmandementGrid(this.queryParams, proposalId ?? "");

      runInAction(() => (this.amandementGrid = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.amandementGridLoading = false));
    }
  };

  editAmandement = async (id: string, body: any) => {
    try {
      await agent.Amandement.editAmandement(id, body);
    } catch (e) {
      throw e;
    }
  };

  setSelectedAmandementGrid = (data: TAmandement | null) => {
    this.selectedAmandementGrid = data;
  };

  deleteAmandement = async (id: string) => {
    try {
      await agent.Amandement.deleteAmandement(id);
    } catch (e) {
      throw e;
    }
  };

  setQueryParams = (query: "pageSize" | "search" | "currentPage", v: string | number) => {
    this.queryParams = {
      ...this.queryParams,
      [query]: v,
    };
  };
}
