import { Box, Button, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { FillFormItem } from "../projectMonitoring/proposal/FillFormItem";
import { BallotOutlined, InfoOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { ReFidGeneralInfoForm } from "./form/ReFidGeneralInfoForm";
import { useState } from "react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import MilestonesDrawerForm from "./capex/index";
import { useEffect } from "react";
import useQuery from "../../app/hook/useQuery";
import { history } from "../..";
import { NumericFormat } from "react-number-format";
import ApproveDialog from "./dialog/ApproveDialog";

function ReFidFormPage() {
  const { dialogStore } = useStore();
  const { reFidGeneralInformation, reFidGeneralInformationLoading, getReFidGeneralInformation, deleteGeneralInformationReFid, submitReFid } =
    useStore().reFidStore;
  const { getGeneralInformation, generalInformation, generalInformationLoading } = useStore().proposalStore;
  const [generalInformationDrawerOpen, setGeneralInformationDrawerOpen] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [capexDrawer, setCapexDrawer] = useState(false);

  const params = useParams();
  const projectCategory = params.projectCategory;
  const title = params.projectTitle;
  const query = useQuery();
  const id = query.get("proposalId");

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
    getReFidGeneralInformation(id);
  }, [getGeneralInformation, getReFidGeneralInformation, id]);

  return (
    <>
      <Box>
        <DashboardLayoutHeader
          pageTitle="Request Re-FID"
          breadcrumbs={[
            {
              label: "Proposal",
              href: `/project-monitoring/proposal/list/${projectCategory}`,
            },
            {
              label: "Proposal List",
              href: `/project-monitoring/proposal/list/${projectCategory}`,
            },
            {
              label: `Request Re-FID`,
              href: `/project-monitoring/proposal/list/${projectCategory}/request-refid/${title}`,
            },
          ]}
        >
          <div>
            <Button
              sx={{ mr: "6px" }}
              color="error"
              variant="contained"
              onClick={() => {
                if (!id) return;

                dialogStore.open({
                  action: async () =>
                    deleteGeneralInformationReFid(id, reFidGeneralInformation?.id ?? "").then(() =>
                      history.push(`/project-monitoring/proposal/list/BD`)
                    ),
                  actionText: "Submit",
                  title: "Submit",
                  closeText: "Cancel",
                  actionButtonProps: {
                    color: "error",
                  },
                  description: "Are you sure want to cancel this Re-FID?",
                });
              }}
            >
              Cancel
            </Button>
            <Button
              color="success"
              variant="contained"
              // onClick={() => {
              //   if (!id) return;

              //   dialogStore.open({
              //     action: async () => submitReFid(id).then(() => history.push(`/project-monitoring/re-fid/list`)),
              //     actionText: "Submit",
              //     title: "Submit",
              //     closeText: "Cancel",
              //     actionButtonProps: {
              //       color: "primary",
              //     },
              //     description: "Are you sure want to submit this Re-FID?",
              //   });
              // }}
              onClick={() => setOpenApproveModal(true)}
            >
              Submit
            </Button>
          </div>
        </DashboardLayoutHeader>
        <Paper sx={{ p: 2 }}>
          <Box mb="24px">
            <Stack gap="24px">
              {!!generalInformation?.title && !!generalInformation.cost && (
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Typography sx={{ mb: "12px" }} color="black" fontWeight={700} fontSize="20px">
                      Title
                    </Typography>
                    <TextField fullWidth disabled placeholder="Title" value={generalInformation.title} />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={{ mb: "12px" }} color="black" fontWeight={700} fontSize="20px">
                      Previous Cost
                    </Typography>
                    <NumericFormat
                      sx={{ width: "100%" }}
                      prefix={"Rp"}
                      thousandSeparator={true}
                      customInput={TextField}
                      placeholder="Cost"
                      value={generalInformation.cost}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Kategori Proyek" value={generalInformation.categoryName} disabled />
                  </Grid>
                </Grid>
              )}
            </Stack>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="black" fontWeight={700} fontSize="20px">
                Detail Information
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ cursor: "pointer" }} onClick={() => (reFidGeneralInformationLoading ? {} : setGeneralInformationDrawerOpen(true))}>
                <FillFormItem
                  icon={<InfoOutlined />}
                  text="CAPEX"
                  added={!!reFidGeneralInformation}
                  disabled={reFidGeneralInformationLoading}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ cursor: "pointer" }} onClick={() => (!reFidGeneralInformation ? {} : setCapexDrawer(true))}>
                <FillFormItem icon={<BallotOutlined />} text="Detail CAPEX" added={!!reFidGeneralInformation} disabled={!reFidGeneralInformation} />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <ReFidGeneralInfoForm
        category={generalInformation?.category ?? ""}
        isOpen={generalInformationDrawerOpen}
        setIsOpen={setGeneralInformationDrawerOpen}
      />
      <MilestonesDrawerForm setIsOpen={setCapexDrawer} isOpen={capexDrawer} />
      <ApproveDialog isRequest isOpen={openApproveModal} onClose={() => setOpenApproveModal(false)} />
    </>
  );
}

export default observer(ReFidFormPage);
