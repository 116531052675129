import { Chip, IconButton, Paper, Stack } from "@mui/material";
import { Delete, Edit, CheckCircleOutline, CancelOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { StyledDataGrid } from "../../../../../../../app/component/StyledDataGrid";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../../../app/stores/store";
import { format } from "date-fns";
import useQuery from "../../../../../../../app/hook/useQuery";
import RefuseAmandementForm from "./RefuseAmandementForm";

function AmandementDataGrid() {
  const { dialogStore, amandementStore } = useStore();
  const {
    approveRejectAmandement,
    getAmandementGrid,
    queryParams,
    setQueryParams,
    amandementGridLoading,
    setSelectedAmandementGrid,
    deleteAmandement,
    amandementGrid,
  } = amandementStore;
  const [openRefuseModal, setOpenRefuseModal] = useState(false);
  const [choosedGridId, setChoosedGridId] = useState("");
  const query = useQuery();
  const proposalId = query.get("id");

  const onDelete = (id: string) => {
    if (!proposalId) return;

    dialogStore.open({
      action: async () => await deleteAmandement(id).then(() => getAmandementGrid(proposalId)),
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      actionButtonProps: {
        color: "error",
      },
      description: "Are you sure want to delete this amandement?",
    });
  };

  const onApproveReject = (status: string, id: string) => {
    if (!proposalId) return;

    let payload = {
      id: id,
      proposalId: proposalId,
      notes: "",
    };

    if (status === "APPROVED") {
      dialogStore.open({
        action: async () => await approveRejectAmandement(status, payload).then(() => getAmandementGrid(proposalId)),
        actionText: "Approve",
        title: "Approve",
        closeText: "Cancel",
        actionButtonProps: {
          color: "primary",
        },
        description: "Are you sure want to approve this amandement?",
      });
    }
  };

  useEffect(() => {
    if (!proposalId) return;

    getAmandementGrid(proposalId);
  }, [getAmandementGrid, proposalId]);

  return (
    <Paper sx={{ p: "16px" }}>
      <StyledDataGrid
        loading={amandementGridLoading}
        rows={amandementGrid.data}
        rowCount={amandementGrid.rowCount}
        pagination
        onPageChange={(v: string | number) => setQueryParams("currentPage", v)}
        onPageSizeChange={(v: string | number) => setQueryParams("pageSize", v)}
        paginationMode="server"
        pageSize={queryParams.pageSize}
        page={queryParams.currentPage}
        columns={[
          {
            field: "vendorWinner",
            headerName: "Vendor Winner",
            width: 200,
          },
          {
            field: "status",
            headerName: "Status",
            width: 150,
            renderCell: (params) => (
              <Chip sx={{ color: "white", backgroundColor: params.row.status !== "REJECTED" ? "#0664B4" : "#DB1A22" }} label={params.row.status} />
            ),
          },
          {
            field: "contractName",
            headerName: "Contract Name",
            width: 200,
          },
          {
            field: "contractNumber",
            headerName: "Contract Number",
            width: 150,
          },
          {
            field: "contractValue",
            headerName: "Contract Value",
            width: 150,
          },
          {
            field: "startDateContract",
            headerName: "Start Date",
            width: 150,
            renderCell: (params) => format(new Date(params.row.startDateContract), "dd MMM yyyy"),
          },
          {
            field: "endDateContract",
            headerName: "End Date",
            width: 150,
            renderCell: (params) => format(new Date(params.row.endDateContract), "dd MMM yyyy"),
          },
          {
            field: "action",
            headerName: "Action",
            width: 180,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            resizable: false,
            renderCell: (params: any) => (
              <Stack direction="row" spacing="8px">
                {params.row.status === "SUBMITTED" ? (
                  <>
                    <IconButton size="small" onClick={() => setSelectedAmandementGrid(params.row as any)}>
                      <Edit />
                    </IconButton>
                    <IconButton size="small" onClick={() => onDelete(params.row.id)}>
                      <Delete />
                    </IconButton>
                    <IconButton size="small" onClick={() => onApproveReject("APPROVED", params.row.id)}>
                      <CheckCircleOutline />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setChoosedGridId(params.row.id);
                        setOpenRefuseModal(true);
                      }}
                    >
                      <CancelOutlined />
                    </IconButton>
                  </>
                ) : (
                  ""
                )}
              </Stack>
            ),
          },
        ]}
        disableSelectionOnClick
        autoHeight
      />
      <RefuseAmandementForm gridId={choosedGridId} isOpen={openRefuseModal} onClose={() => setOpenRefuseModal(false)} />
    </Paper>
  );
}

export default observer(AmandementDataGrid);
