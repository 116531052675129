import { useEffect, useState } from "react";
import { ArrowBackIos, VisibilityOutlined, HandymanOutlined, DescriptionOutlined } from "@mui/icons-material";
import { Grid, Paper, Box } from "@mui/material";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { Link } from "react-router-dom";
import DashboardLayoutHeader from "../../../app/component/layout/DashboardLayoutHeader";
import ContructorDrawer from "./ContructorDrawer";
import PageButton from "../components/PageButton";
// import CommentSection from "../components/CommentSection";
import LoadingSection from "../components/LoadingSection";
import useQuery from "../../../app/hook/useQuery";

function Contructor() {
  const { proposalStore } = useStore();
  const [defaultDrawerData, setDefaultDrawerData] = useState<any>();
  const { constructors, constructorsLoading, getConstructor, getGeneralInformation, generalInformation } = proposalStore;
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const { id } = useParams();
  const query = useQuery();
  const status = query.get("status");

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
  }, [getGeneralInformation, id]);

  useEffect(() => {
    if (!id) return;

    getConstructor(id);
  }, [getConstructor, id]);

  return (
    <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Approval",
            href: "/project-monitoring/approval/list",
          },
          {
            label: "Approval List",
            href: "/project-monitoring/approval/list",
          },
          {
            label: `${generalInformation?.title}`,
          },
        ]}
        pageTitle="Project Monitoring"
      />

      <Paper sx={{ p: "16px" }}>
        <Link
          style={{ color: "#000", textDecoration: "none", display: "flex", alignItems: "center", gap: "3px", marginBottom: "24px" }}
          to={`/project-monitoring/approval/list/${id}?status=${status}`}
        >
          <ArrowBackIos sx={{ fontSize: "14px" }} />
          <Box>Back</Box>
        </Link>
        <Box
          sx={{
            mb: "12px",
            backgroundColor: "#F2F9FF",
            display: "flex",
            p: "16px",
            alignItems: "center",
            gap: "6px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <HandymanOutlined sx={{ mr: "12px", color: "#0664B4", fontSize: "34px" }} />
            <Box sx={{ fontWeight: "bold" }}>Vendor Info</Box>
          </Box>
          {/* {constructorsLoading ? "Loading..." : <CommentSection typeId={constructors?.length ? constructors[0]?.id : ""} />} */}
        </Box>

        {constructorsLoading ? (
          <LoadingSection />
        ) : (
          <Grid pt="12px" container spacing={3}>
            {constructors?.map((item) => (
              <Grid key={item.id} item xs={6} spacing={3}>
                <Box
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    p: "16px",
                    borderRadius: "4px",
                    border: "1px solid rgba(29, 27, 32, 0.12)",
                  }}
                >
                  <DescriptionOutlined />
                  <Box sx={{ width: "100%", justifyContent: "space-between", alignItems: "center", display: "flex", ml: "12px" }}>
                    <Box>
                      <Box sx={{ fontWeight: "bold" }}>{item.contractorName}</Box>
                      <Box sx={{ fontSize: "12px", color: "#999" }}>{item.contractorScope}</Box>
                    </Box>
                    <VisibilityOutlined
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setDefaultDrawerData(item);
                        setIsOpenDrawer(true);
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}

        <PageButton isNextButton nextButtonRoute={`/project-monitoring/approval/list/${id}/attachment?status=${status}`} />
      </Paper>
      <Box sx={{ py: "16px" }} />
      <ContructorDrawer data={defaultDrawerData} isOpen={isOpenDrawer} handleClose={() => setIsOpenDrawer(false)} />
    </Box>
  );
}

export default observer(Contructor);
