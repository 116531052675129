import { Chip, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { format } from "date-fns";
import { VisibilityOutlined } from "@mui/icons-material";
import { Dispatch, SetStateAction } from "react";
import { IFidMilestone } from "../../../app/models/fidMilestones";

interface SubTaskTableProps {
  subTask: IFidMilestone["tasks"][0]["subTasks"];
  setParentId: Dispatch<SetStateAction<string | undefined>>;
  setSubtaskToEdit: Dispatch<SetStateAction<IFidMilestone["tasks"][0]["subTasks"][0] | undefined>>;
  setFormType: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
}

export const SubTaskTable = ({ setParentId, subTask, setSubtaskToEdit, setFormType }: SubTaskTableProps) => {
  return (
    <Table>
      <TableHead>
        <TableCell sx={{ fontWeight: 700 }}>Subtask</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Start Date</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>End Date</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Weight Physical (%)</TableCell>
        <TableCell sx={{ fontWeight: 700 }}>Weight Financial (Rp)</TableCell>
        <TableCell />
      </TableHead>
      <TableBody>
        {subTask.map((i) => (
          <TableRow key={i.id}>
            <TableCell>{i.name}</TableCell>
            <TableCell>
              <Chip color="primary" variant="filled" label={i.status} />
            </TableCell>
            <TableCell>{format(new Date(i.startDate), "dd MMM yyyy")}</TableCell>
            <TableCell>{format(new Date(i.endDate), "dd MMM yyyy")}</TableCell>
            <TableCell>{i.weightPhysical}%</TableCell>
            <TableCell>Rp {i.weightFinancial.toLocaleString()}</TableCell>
            <TableCell>
              <IconButton
                onClick={() => {
                  setSubtaskToEdit(i);
                  setFormType("subtask");
                  setParentId(i.tasksId);
                }}
                color="inherit"
              >
                <VisibilityOutlined />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
