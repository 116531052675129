import { makeAutoObservable, runInAction } from "mobx";
import { TReviewerLog, TReviewerLogEditPayload } from "../models/reviewerLog";
import agent from "../api/agent";

export class ReviewerLog {
  reviewerLogLoading = false;
  reviewerAcceptanceLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  getReviewerLog = async (proposalId: string, typeId: string, proposalStatus: string, typeStatus: string) => {
    this.reviewerLogLoading = true;
    try {
      const res = await agent.ReviewerLog.getReviewerLog(proposalId, typeId, proposalStatus, typeStatus);

      return res;
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.reviewerLogLoading = false));
    }
  };

  addReviewerLog = async (proposalId: string, body: TReviewerLog) => {
    try {
      await agent.ReviewerLog.addReviewerLog(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  editReviewerLog = async (proposalId: string, body: TReviewerLogEditPayload) => {
    try {
      await agent.ReviewerLog.editReviewerLog(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  deleteReviewerLog = async (proposalId: string, typeId: string, type?: string) => {
    try {
      await agent.ReviewerLog.deleteReviewerLog(proposalId, typeId, type);
    } catch (e) {
      throw e;
    }
  };

  editReviewerLogAttachment = async (proposalId: string, body: any) => {
    try {
      await agent.ReviewerLog.editReviewerAttachmentLog(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  reviewerAcceptance = async (proposalId: string, body: any) => {
    this.reviewerAcceptanceLoading = true;
    try {
      await agent.ReviewerLog.reviewerAcceptance(proposalId, body);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.reviewerAcceptanceLoading = false));
    }
  };
}
