import { IAccountInfo } from "../models/account";

export enum FileType {
  Image = "image/*",
  Audio = "audio/*",
  Vieo = "video/*",
  Document = "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint",
  Pdf = "application/pdf",
}

export const NO_SUBTASK_ID = "00000000-0000-0000-0000-000000000000";

export enum sex {
  Male = "Laki - Laki",
  Female = "Perempuan",
}

export enum Roles {
  Superadmin = "Superadmin",
  General = "General",
  AdminAsset = "AdminAsset",
  Procurement = "Procurement",
}

export const CHANGE_REQUEST_STATUS = ["SUBMITTED", "APPROVED"];
export const CONSTRUCTION_OTHERS_NAME = "EXECUTION";
export const PROJECT_BUDGET = {
  PLAN: "Plan",
  REALIZATION: "Realization",
};
export const PLAN_TYPE = {
  PHISYCAL: "Physical Plan",
  FINANCIAL: "Financial Plan",
};

export enum LandType {
  Hgb = "HGB",
  External = "External",
  Others = "Others",
}

export enum UserAction {
  Approved = "Approved",
  Refused = "Refuse",
}

export enum LandStatus {
  ClearAndClean = "CLEAR AND CLEAN",
  NotClear = "NOT CLEAR",
}

export enum ReviewerLog {
  PROCEED = "PROCEED",
  NOT_PROCEED = "NOT_PROCEED",
}

export enum FlagEdit {
  PROPOSAL = 0,
  FID = 1,
  REVIEWER = 2,
  DECISION_GATE = 3,
  PREPARATION = 4,
  PROCUREMENT = 5,
  CONSTRUCTION = 6,
  PROJECT_CLOSING = 7,
}

export enum ProjectStatus {
  Proposal = "PROPOSAL",
  Fid = "FID",
  Reviewer = "REVIEWER",
  DecisionGate = "DECISION_GATE",
  Preparation = "PREPARATION",
  Procurement = "PROCUREMENT",
  Construction = "CONSTRUCTION",
  ProjectClosing = "PROJECT_CLOSING",
  CancelProject = "CANCEL_PROJECT",
}

export const BD_OTHERS_NAME = "Business Development";
export const NON_BD_OTHERS_NAME = "Non Business Development";

export enum OrganizationLevel {
  settingApproval = "3",
}

export enum ProjectCategory {
  Anorganik = "ANORGANIK",
  BD = "BD",
  NonBD = "NON_BD",
  CancelProject = "CANCEL_PROJECT",
}

export const defaultProjectMonitoringMenu = [
  {
    title: "Business Development",
    route: `/project-monitoring/proposal/list/${ProjectCategory.BD}`,
  },
  {
    title: "Non-Business Development",
    route: `/project-monitoring/proposal/list/${ProjectCategory.NonBD}`,
  },
  {
    title: "Anorganik",
    route: `/project-monitoring/proposal/list/${ProjectCategory.Anorganik}`,
  },
];

export const ProjectCategoryApproval = [
  {
    text: "Business Development",
    value: "BD",
  },
  {
    text: "Non Business Development",
    value: "NON_BD",
  },
  {
    text: "Anorganik",
    value: "ANORGANIK",
  },
];

export enum ProposalRole {
  Member = "MEMBER",
  TimPengusul = "TIM_PENGUSUL",
}

export enum TaskStatus {
  Open = "OPEN",
  InProgress = "IN_PROGRESS",
  Done = "DONE",
}

export enum TaskSubTaskStatus {
  Perencanaan = "PERENCANAAN",
  DocumentTender = "DOCUMENT_TENDER",
  Lelang = "LELANG",
  PemenangLelang = "PEMENANG_LELANG",
  Construction = "CONSTRUCTION",
  Pembayaran = "PEMBAYARAN",
}

export enum TaskSubTask {
  Completed = "COMPLETED",
}

export const SETTING_APPROVAL_STATUS = ["REVIEWER", "DECISION_GATE"];

export enum ReviewerStatus {
  WaitingReviewer = "WAITING_REVIEWER",
  InReview = "IN_REVIEW",
  WaitingDecisionGate = "WAITING_DECISION_GATE",
  Approved = "APPROVED",
  Submitted = "SUBMITTED",
  Refused = "REFUSE",
}

export enum Operator {
  LessThanEqualTo = "LESS_THAN_EQUAL_TO",
  MoreThanEqualTo = "MORE_THAN_EQUAL_TO",
}

export const SUPERADMIN = "Superadmin";

// export const displayText = (role: Roles) => {
//   switch (role)
//   {
//     case Roles.Superadmin: return "Super Administrator";
//     case Roles.Admin: return "Administrator";
//     default: return "";
//   }
// }

//Notes: yang dikirim di db keys nya

export function enumToArray(obj: any) {
  return Object.keys(obj).map((value) => ({ value, text: obj[value] }));
}

export const enumMap = <T>(obj: {}, callbackFn: (key: string, value: string, index: number) => T): T[] => {
  // const enumValues: string[] = Object.values(obj);
  return Object.keys(obj).map<T>((k, i) => callbackFn(k, k, i));
};

export function capitalizeWords(inputString: string): string {
  return inputString
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const isTaskSubTaskDisabled = (status: string = "", account: IAccountInfo | null, projectOwnerName: string = "") => {
  const statusForPreaparationForm: string[] = [ProjectStatus.Procurement, ProjectStatus.Construction];

  if (!account) return true;
  if (status === ProjectStatus.ProjectClosing) return true;
  //If (Procurement OR Construction) And (not owner or not superadmin) disabled
  if (statusForPreaparationForm.includes(status) && (account.displayName !== projectOwnerName || account.displayName !== Roles.Superadmin))
    return true;

  return false;
};

// Valuenya yang kiri
export enum StatusLelang {
  PenunjukanLangsung = "PENUNJUKAN_LANGSUNG",
  LelangTerbuka = "LELANG_TERBUKA",
  GagalTender = "GAGAL_TENDER",
}

export enum StatusTask {
  PERENCANAAN = "Perencanaan",
  DOCUMENT_TENDER = "Document Tender",
  LELANG = "Lelang",
  PEMENANG_LELANG = "Pemenang Lelang",
  CONSTRUCTION = "Execution",
  PEMBAYARAN = "Pembayaran",
}
