import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Formik, FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import { OptionalObjectSchema } from "yup/lib/object";
import * as Yup from 'yup';
import { createContext } from "react";
import { useStore } from "../../stores/store";

interface Props {
    initialValues: any,
    submitAction: (value: any) => Promise<any>,
    validationSchema?: OptionalObjectSchema<any>,
    formTitle?: string,
    buttonSubmitText?: string,
    actionAfterSubmit?: () => any,
    globalErrorMessage?: string,
    successMessage?: string,
    children?: JSX.Element | JSX.Element[],
    logOnly?: boolean
}

export const RkdFormikContext = createContext<FormikProps<any> | null>(null);

function RkdForm({ validationSchema, initialValues, formTitle, buttonSubmitText, submitAction, actionAfterSubmit, globalErrorMessage, successMessage, children, logOnly }: Props) {
    const { snackbarStore } = useStore();

    if (!validationSchema) validationSchema = Yup.object();

    return (
        <Formik validationSchema={validationSchema} initialValues={initialValues} enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
                if (logOnly) { console.log(values); snackbarStore.show("info", "LogOnly mode, please see console for info"); }
                else submitAction(values)
                    .then(() => {
                        if (actionAfterSubmit) actionAfterSubmit();
                        if (successMessage) snackbarStore.show("success", successMessage);
                    })
                    .finally(() => setSubmitting(false))
                }
            }
        >
            {
                (formProps) => (
                    <Form onSubmit={formProps.handleSubmit} autoComplete='off'>
                        {formTitle && <h2>{formTitle}</h2>}
                        <Box sx={{ display: 'flex', flexDirection: 'column','& .MuiTextField-root': { mb: 3, width: '100%' } }}>
                            <RkdFormikContext.Provider value={formProps}>{children}</RkdFormikContext.Provider>
                            {buttonSubmitText &&
                                <LoadingButton
                                    type="submit" variant='contained' color='primary'
                                    loading={formProps.isSubmitting}
                                    disabled={formProps.isSubmitting || !formProps.dirty || !formProps.isValid}
                                >
                                    {buttonSubmitText}
                                </LoadingButton>
                            }
                        </Box>
                    </Form>
                )
            }
        </Formik>

    );
}

export default observer(RkdForm);