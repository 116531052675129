import { Dispatch, SetStateAction } from "react";
import { EditOutlined, ShowChart } from "@mui/icons-material";
import { CONSTRUCTION_OTHERS_NAME, TaskSubTaskStatus } from "../../../../../../../app/config/enum";
import { TWeightPhysicalActuals } from "../../../../../../../app/models/progressRealization";
import { Box, CircularProgress } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../../../app/stores/store";
import { format } from "date-fns";
import EmptyState from "../../../../../../../app/component/EmptyState";

type TProgressHistoryCollapse = {
  physicalActualProgress: any[];
  generalStatus: TaskSubTaskStatus;
  setSelectedPhysicalActual: Dispatch<SetStateAction<TWeightPhysicalActuals | undefined>>;
  setIsOpenAddMOdal: Dispatch<SetStateAction<boolean>>;
};

function ProgressHistoryCollapse({ setSelectedPhysicalActual, setIsOpenAddMOdal, generalStatus, physicalActualProgress }: TProgressHistoryCollapse) {
  const differentBehaviourStatus = [TaskSubTaskStatus.Construction, TaskSubTaskStatus.Pembayaran];
  const { progressRealizationStore } = useStore();

  return (
    <Box>
      {progressRealizationStore.progressRealizationLoading ? (
        <CircularProgress />
      ) : (
        <>
          {physicalActualProgress.length > 0 ? (
            <>
              {physicalActualProgress
                ?.slice(0)
                .reverse()
                .map((item: any) => (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "12px",
                      marginBottom: "12px",
                      padding: "16px",
                      alignItems: "center",
                      borderRadius: "4px",
                      border: "1px solid rgba(29, 27, 32, 0.12)",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                          <ShowChart />
                          <Box>
                            <Box sx={{ fontWeight: "bold" }}>{`${item.weightPhysicalActual}% | ${
                              item.status === TaskSubTaskStatus.Construction ? CONSTRUCTION_OTHERS_NAME : item.status
                            }`}</Box>
                            <Box sx={{ fontSize: "12px", color: "#999" }}>{format(new Date(item.date), "MMMM yyyy")}</Box>
                          </Box>
                        </Box>
                        <Box>
                          {differentBehaviourStatus.includes(generalStatus) ? (
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <Box
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  setIsOpenAddMOdal(true);
                                  setSelectedPhysicalActual(item);
                                }}
                              >
                                <EditOutlined sx={{ fontSize: "24px" }} />
                              </Box>
                            </Box>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
            </>
          ) : (
            <EmptyState />
          )}
        </>
      )}
    </Box>
  );
}

export default observer(ProgressHistoryCollapse);
