import { makeAutoObservable, runInAction } from "mobx";
import { IProposalReviewer } from "../models/proposalReviewer";
import agent from "../api/agent";
import { IComment } from "../models/comment";

export class ProposalReviewerStore {
  reviewer: IProposalReviewer[] = [];
  reviewerLoading = false;

  reviewerRefid: IProposalReviewer[] = [];
  reviewerRefidLoading = false;

  comment: IComment[] = [];
  commentLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  getReviewerRefid = async (proposalId: string) => {
    this.reviewerRefidLoading = true;
    try {
      const res = await agent.Proposal.getReviewerRefid(proposalId);

      runInAction(() => (this.reviewerRefid = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.reviewerRefidLoading = false));
    }
  };

  getReviewer = async (proposalId: string) => {
    this.reviewerLoading = true;
    try {
      const res = await agent.Proposal.getReviewer(proposalId);

      runInAction(() => (this.reviewer = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.reviewerLoading = false));
    }
  };

  getComment = async (proposalId: string) => {
    this.commentLoading = true;
    try {
      const res = await agent.ReviewerLog.getComment(proposalId);

      runInAction(() => (this.comment = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.commentLoading = false));
    }
  };
}
