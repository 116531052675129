import { useEffect } from "react";
import DashboardLayoutHeader from "../../../app/component/layout/DashboardLayoutHeader";
import { Grid, Paper, TextField, Box, Stack } from "@mui/material";
import { ArrowBackIos, InfoOutlined } from "@mui/icons-material";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { Link } from "react-router-dom";
import PageButton from "../components/PageButton";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import CommentSection from "../components/CommentSection";
import LoadingSection from "../components/LoadingSection";
import useQuery from "../../../app/hook/useQuery";

function Timeline() {
  const { proposalStore } = useStore();
  const { timeline, timelineLoading, getTimeline, getGeneralInformation, generalInformation } = proposalStore;
  const { id } = useParams();
  const query = useQuery();
  const status = query.get("status");

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
  }, [getGeneralInformation, id]);

  useEffect(() => {
    if (!id) return;

    getTimeline(id);
  }, [getTimeline, id]);

  return (
    <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Approval",
            href: "/project-monitoring/approval/list",
          },
          {
            label: "Approval List",
            href: "/project-monitoring/approval/list",
          },
          {
            label: `${generalInformation?.title}`,
          },
        ]}
        pageTitle="Project Monitoring"
      />

      <Paper sx={{ p: "16px" }}>
        <Link
          style={{ color: "#000", textDecoration: "none", display: "flex", alignItems: "center", gap: "3px", marginBottom: "24px" }}
          to={`/project-monitoring/approval/list/${id}?status=${status}`}
        >
          <ArrowBackIos sx={{ fontSize: "14px" }} />
          <Box>Back</Box>
        </Link>
        <Box
          sx={{
            mb: "12px",
            backgroundColor: "#F2F9FF",
            display: "flex",
            p: "16px",
            alignItems: "center",
            gap: "6px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <InfoOutlined sx={{ color: "#0664B4", fontSize: "34px" }} />
            <Box sx={{ fontWeight: "bold" }}>Tata Waktu</Box>
          </Box>
          {/* {timelineLoading ? "Loading..." : <CommentSection typeId={timeline?.length ? timeline[0]?.id : ""} />} */}
        </Box>

        {timelineLoading ? (
          <LoadingSection />
        ) : (
          <Grid pt="12px" container spacing={3}>
            {timeline?.map((item, index) => (
              <>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    label={`Description ${timeline && timeline?.length > 1 ? index + 1 : ""}`}
                    rows={5}
                    multiline
                    value={item.description}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={"24px"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={`Start Date ${timeline && timeline?.length > 1 ? index + 1 : ""}`}
                        value={new Date()}
                        views={["year", "month"]}
                        onChange={() => null}
                        disabled
                        renderInput={(props) => <TextField fullWidth disabled {...props} />}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={`End Date ${timeline && timeline?.length > 1 ? index + 1 : ""}`}
                        value={new Date()}
                        views={["year", "month"]}
                        onChange={() => null}
                        disabled
                        renderInput={(props) => <TextField fullWidth disabled {...props} />}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              </>
            ))}
          </Grid>
        )}

        <PageButton
          isPrevButton
          isNextButton
          prevButtonRoute={`/project-monitoring/approval/list/${id}/scopeOfWork?status=${status}`}
          nextButtonRoute={`/project-monitoring/approval/list/${id}/investmentBudget?status=${status}`}
        />
      </Paper>
      <Box sx={{ py: "16px" }} />
    </Box>
  );
}

export default observer(Timeline);
