import { Close } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MapViewer from "../../app/component/map/MapViewer";
import * as yup from "yup";
import { useFormik } from "formik";
import { FileType } from "../../app/config/enum";
import DraggableFileUpload, { DraggableFileUploadData } from "../../app/component/input/DraggableFileUpload";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "../../app/stores/store";
import { ILandPayload } from "../../app/models/land";
import { LoadingButton } from "@mui/lab";
import { observer } from "mobx-react-lite";
import ErrorBoundary from "../../app/component/error/ErrorBoundary";
import { NumericFormat } from "react-number-format";
import { provincies } from "../../app/data/provincies";
import { cities } from "../../app/data/cities";

interface LandFormProps {
  isOpen: boolean;
  onClose: () => void;
  refetchDetail?: boolean;
}

function LandForm({ isOpen, onClose, refetchDetail = false }: LandFormProps) {
  const [attachment, setAttachment] = useState<(DraggableFileUploadData & { url?: string })[]>([]);
  const [provinceId, setProvinceId] = useState<number>();
  const { landStore, ownershipCategoryStore, peruntukanStore } = useStore();
  const { getOwnershipStatus, ownershipStatus } = ownershipCategoryStore;
  const { getPeruntukan, peruntukan } = peruntukanStore;
  const { addLand, editLand, getLand, selectedLand, getLandDetail, landDetail, landDetailLoading, getLandType, getLandStatus, landType, landStatus } =
    landStore;

  const validationSchema = yup.object({
    noAsset: yup.string().required("No Asset must be filled"),
    type: yup.object({
      name: yup.string().required("Type must be filled"),
      id: yup.string().required("Type must be filled"),
    }),
    status: yup.object({
      name: yup.string().required("Status must be filled"),
      id: yup.string().required("Status must be filled"),
    }),
    address: yup.string().nullable(),
    landArea: yup.number().nullable(),
    typeNotes: yup
      .string()
      .nullable()
      .when("type.id", {
        is: landType.find((i) => i.name === "Others")?.id,
        then: yup.string().required("Type notes must be filled"),
      }),
    statusNotes: yup
      .string()
      .nullable()
      .when("status.id", {
        is: landStatus.find((i) => i.name === "Others")?.id,
        then: yup.string().required("Status notes must be filled"),
      }),
    ownershipStatusId: yup.string(),
    landStatusAllotmentId: yup.string(),
    pbb: yup.string().nullable(),
    province: yup.string().nullable(),
    city: yup.string().nullable(),
    certificate: yup.string().nullable(),
    location: yup.string().nullable(),
    latitude: yup.string().nullable(),
    longitude: yup.string().nullable(),
    regulations: yup.array(
      yup.object({
        kdb: yup.number().nullable(),
        klb: yup.number().nullable(),
        gbs: yup.number().nullable(),
        kdh: yup.number().nullable(),
        buildingHeight: yup.number().nullable(),
        others: yup.string().nullable(),
      })
    ),
    attachments: yup.array(
      yup.object({
        title: yup.string(),
        attachment: yup.mixed(),
      })
    ),
  });

  type ValidationSchema = yup.InferType<typeof validationSchema>;

  const initialValues: ValidationSchema = {
    noAsset: "",
    type: {
      id: "",
      name: "",
    },
    status: {
      id: "",
      name: "",
    },
    address: undefined,
    landArea: undefined,
    typeNotes: undefined,
    pbb: undefined,
    province: "",
    city: "",
    certificate: undefined,
    location: undefined,
    latitude: undefined,
    longitude: undefined,
    statusNotes: undefined,
    ownershipStatusId: "",
    landStatusAllotmentId: "",
    regulations: [
      {
        buildingHeight: undefined,
        gbs: undefined,
        kdb: undefined,
        kdh: undefined,
        klb: undefined,
        others: "",
      },
    ],
    attachments: undefined,
  };

  const initialEditValues: ValidationSchema = useMemo(
    () => ({
      address: selectedLand?.address,
      attachments: undefined,
      certificate: selectedLand?.certificate,
      landArea: selectedLand?.landArea,
      latitude: selectedLand?.latitude,
      longitude: selectedLand?.longitude,
      location: selectedLand?.location,
      noAsset: selectedLand?.noAsset ?? "",
      pbb: selectedLand?.pbb,
      province: selectedLand?.province ?? "",
      city: selectedLand?.city ?? "",
      statusNotes: selectedLand?.statusNotes,
      ownershipStatusId: selectedLand?.ownershipStatusId,
      landStatusAllotmentId: selectedLand?.landStatusAllotmentId,
      regulations: [
        {
          buildingHeight: selectedLand?.regulations[0] ? selectedLand?.regulations[0].buildingHeight : undefined,
          gbs: selectedLand?.regulations[0] ? selectedLand?.regulations[0].gbs : undefined,
          kdb: selectedLand?.regulations[0] ? selectedLand?.regulations[0].kdb : undefined,
          kdh: selectedLand?.regulations[0] ? selectedLand?.regulations[0].kdh : undefined,
          klb: selectedLand?.regulations[0] ? selectedLand?.regulations[0].klb : undefined,
          others: selectedLand?.regulations[0] ? selectedLand?.regulations[0].others : "",
        },
      ],
      status: {
        id: selectedLand?.landStatusId ?? "",
        name: selectedLand?.statusName ?? "",
      },
      type: {
        id: selectedLand?.landTypeId ?? "",
        name: selectedLand?.typeName ?? "",
      },
      typeNotes: selectedLand?.typeNotes,
    }),
    [selectedLand]
  );

  const handleClose = () => {
    onClose();
    resetForm();
    setAttachment([]);
  };

  const onSubmit = async (values: ValidationSchema) => {
    const formData = new FormData();

    formData.append("noAsset", values.noAsset);
    formData.append("LandTypeId", values.type.id);
    formData.append("LandStatusId", values.status.id);

    values.typeNotes && formData.append("typeNotes", values.typeNotes);
    values.statusNotes && formData.append("statusNotes", values.statusNotes);
    values.ownershipStatusId && formData.append("ownershipStatusId", values.ownershipStatusId);
    values.landStatusAllotmentId && formData.append("landStatusAllotmentId", values.landStatusAllotmentId);
    values.address && formData.append("address", values.address);
    values.landArea && formData.append("landArea", String(values.landArea));
    values.pbb && formData.append("pbb", values.pbb);
    values.certificate && formData.append("certificate", values.certificate);
    values.location && formData.append("location", values.location);
    values.latitude && formData.append("latitude", values.latitude);
    values.longitude && formData.append("longitude", values.longitude);
    values.province && formData.append("province", values.province);
    values.city && formData.append("city", values.city);
    if (values.regulations) {
      values.regulations[0].buildingHeight && formData.append("regulations[0].BuildingHeight", String(values.regulations[0].buildingHeight));
      values.regulations[0].gbs && formData.append("regulations[0].gbs", String(values.regulations[0].gbs));
      values.regulations[0].kdb && formData.append("regulations[0].kdb", String(values.regulations[0].kdb));
      values.regulations[0].kdh && formData.append("regulations[0].kdh", String(values.regulations[0].kdh));
      values.regulations[0].klb && formData.append("regulations[0].klb", String(values.regulations[0].klb));
      values.regulations[0].others && formData.append("regulations[0].others", String(values.regulations[0].others));
    }
    if (attachment.length > 0) {
      attachment.forEach((atc, atcX) => {
        formData.append(`attachments[${atcX}].title`, atc.title);
        atc.url
          ? atc.url && formData.append(`attachments[${atcX}].url`, atc.url)
          : formData.append(`attachments[${atcX}].attachment`, atc.file as any);
      });
    }

    if (selectedLand) {
      await editLand(selectedLand.id, formData as unknown as ILandPayload)
        .then(handleClose)
        .then(() => {
          getLand();
          getLandDetail(selectedLand.id);
        });
    } else {
      await addLand(formData as unknown as ILandPayload)
        .then(handleClose)
        .then(() => getLand());
    }
  };

  const { values, errors, handleChange, setFieldError, handleSubmit, setFieldValue, isSubmitting, resetForm } = useFormik({
    validationSchema,
    initialValues: selectedLand ? initialEditValues : initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (refetchDetail) {
      if (!selectedLand) return;

      getLandDetail(selectedLand.id);
    }
  }, [selectedLand, getLandDetail, refetchDetail]);

  useEffect(() => {
    if (!landDetail || !selectedLand) return;

    setAttachment(landDetail.attachments.map((i) => ({ file: { name: i.fileName }, title: i.title, url: i.url })) as any);
  }, [landDetail, isOpen, selectedLand]);

  useEffect(() => {
    getLandType();
    getLandStatus();
    getOwnershipStatus();
    if (isOpen) {
      getPeruntukan();
    }
  }, [getLandStatus, getLandType, getOwnershipStatus, getPeruntukan, isOpen]);

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <Box
        component={"form"}
        noValidate
        onSubmit={handleSubmit}
        sx={{ width: "500px", overflow: "hidden", display: "flex", flexDirection: "column" }}
      >
        <Box flex={1} overflow="auto" p="16px">
          <Box
            sx={{
              pb: "16px",
              borderBottom: "1px solid #EAEAEA",
              display: "flex",
              justifyContent: "space-between",
              mb: "16px",
            }}
          >
            <Typography fontSize={"14px"} fontWeight={700} color="black">
              {!!selectedLand ? "Edit" : "Add"} Profile Lahan
            </Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Box>
            <Typography color="black" fontSize="20px" fontWeight={700} mb="16px">
              General Information
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <TextField
                  sx={{ flex: 1 }}
                  label="No Asset"
                  name="noAsset"
                  value={values.noAsset}
                  error={!!errors.noAsset}
                  helperText={errors.noAsset}
                  onChange={handleChange}
                  required
                />
                <TextField
                  sx={{ flex: 1 }}
                  label="Luas Lahan (m2)"
                  name="landArea"
                  value={values.landArea}
                  error={!!errors.landArea}
                  helperText={errors.landArea}
                  onChange={handleChange}
                  type="number"
                />
              </Box>
              <TextField
                label="Address"
                fullWidth
                rows={5}
                multiline
                name="address"
                value={values.address}
                error={!!errors.address}
                helperText={errors.address}
                onChange={handleChange}
              />
              <Box sx={{ display: "flex", gap: "16px" }}>
                <FormControl required sx={{ flex: 1 }}>
                  <InputLabel required id="type-select-label">
                    Tipe Lahan
                  </InputLabel>
                  <Select
                    required
                    labelId="type-select-label"
                    id="type-select"
                    value={values.type.id}
                    label="Type"
                    onChange={(e) => {
                      const id = e.target.value;

                      const type = landType.find((i) => i.id === id);

                      if (!type) return;

                      setFieldValue("type.name", type.name);
                      setFieldValue("type.id", type.id);

                      setFieldError("type.name", undefined);
                      setFieldError("type.id", undefined);
                    }}
                  >
                    {landType &&
                      landType.map((i) => (
                        <MenuItem key={i.id} value={i.id}>
                          {i.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors.type?.id && <FormHelperText error>{errors.type?.id}</FormHelperText>}
                </FormControl>
                {values.type.id === landType.find((i) => i.name === "Others")?.id && (
                  <TextField
                    sx={{ flex: 1 }}
                    label="Notes Tipe Lahan"
                    name="typeNotes"
                    value={values.typeNotes}
                    error={!!errors.typeNotes}
                    helperText={errors.typeNotes}
                    onChange={handleChange}
                    required={values.type.id === landType.find((i) => i.name === "Others")?.id}
                  />
                )}
              </Box>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <FormControl required sx={{ flex: 1 }}>
                  <InputLabel required id="status-select-label">
                    Status Lahan
                  </InputLabel>
                  <Select
                    required
                    labelId="status-select-label"
                    id="status-select"
                    value={values.status.id}
                    label="Status"
                    name="status.id"
                    onChange={(e) => {
                      const id = e.target.value;

                      const status = landStatus.find((i) => i.id === id);

                      if (!status) return;

                      setFieldValue("status.name", status.name);
                      setFieldValue("status.id", status.id);

                      setFieldError("status.name", undefined);
                      setFieldError("status.id", undefined);
                    }}
                  >
                    {landStatus &&
                      landStatus.map((i) => (
                        <MenuItem key={i.id} value={i.id}>
                          {i.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors.status?.id && <FormHelperText error>{errors.status.id}</FormHelperText>}
                </FormControl>
                {values.status.id === landStatus.find((i) => i.name === "Others")?.id && (
                  <TextField
                    sx={{ flex: 1 }}
                    label="Notes Status Lahan"
                    name="statusNotes"
                    value={values.statusNotes}
                    error={!!errors.statusNotes}
                    helperText={errors.statusNotes}
                    onChange={handleChange}
                    required={values.status.id === landStatus.find((i) => i.name === "Others")?.id}
                  />
                )}
              </Box>
              <FormControl sx={{ flex: 1 }}>
                <InputLabel id="status-select-label">Status Kepemilikan</InputLabel>
                <Select
                  labelId="status-select-label"
                  id="ownershipStatusId-select"
                  value={values.ownershipStatusId}
                  label="Status Kepemilikan"
                  name="ownershipStatusId"
                  onChange={handleChange}
                >
                  {ownershipStatus &&
                    ownershipStatus.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i.name}
                      </MenuItem>
                    ))}
                </Select>
                {errors.ownershipStatusId && <FormHelperText error>{errors.ownershipStatusId}</FormHelperText>}
              </FormControl>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <TextField
                  sx={{ flex: 1 }}
                  label="Latitude"
                  name="latitude"
                  InputLabelProps={{
                    shrink: !!values.latitude,
                  }}
                  value={values.latitude}
                  error={!!errors.latitude}
                  helperText={errors.latitude}
                  onChange={handleChange}
                  type="number"
                />
                <TextField
                  sx={{ flex: 1 }}
                  label="Longitude"
                  name="longitude"
                  InputLabelProps={{
                    shrink: !!values.longitude,
                  }}
                  value={values.longitude}
                  error={!!errors.longitude}
                  helperText={errors.longitude}
                  onChange={handleChange}
                  type="number"
                />
              </Box>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <FormControl sx={{ flex: 1 }}>
                  <InputLabel id="status-select-label">Provinsi</InputLabel>
                  <Select
                    labelId="status-select-label"
                    id="province-select"
                    value={values.province}
                    label="Provinsi"
                    name="province"
                    onChange={handleChange}
                  >
                    {provincies.map((i) => (
                      <MenuItem onClick={() => setProvinceId(i.kode.id_provinsi)} key={i.kode.id_provinsi} value={i.provinsi}>
                        {i.provinsi}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.ownershipStatusId && <FormHelperText error>{errors.ownershipStatusId}</FormHelperText>}
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                  <InputLabel id="status-select-label">Kabupaten/Kota</InputLabel>
                  <Select
                    labelId="status-select-label"
                    id="city-select"
                    value={values.city}
                    label="Kabupaten/Kota"
                    name="city"
                    onChange={handleChange}
                  >
                    {cities
                      .filter((city: any) => city.kode.id_provinsi === provinceId)
                      .map((i) => (
                        <MenuItem key={i.kode.id_kota} value={i.kota}>
                          {i.kota}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors.ownershipStatusId && <FormHelperText error>{errors.ownershipStatusId}</FormHelperText>}
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: "16px" }}>
                {/* <TextField
                  sx={{ flex: 1 }}
                  label="Location"
                  name="location"
                  value={values.location}
                  error={!!errors.location}
                  helperText={errors.location}
                  onChange={handleChange}
                /> */}
                <TextField
                  sx={{ flex: 1 }}
                  label="Certificate"
                  name="certificate"
                  value={values.certificate}
                  error={!!errors.certificate}
                  helperText={errors.certificate}
                  onChange={handleChange}
                />
              </Box>
              <NumericFormat
                customInput={TextField}
                label="NJOP Bumi (m2)"
                name="pbb"
                fullWidth
                value={values.pbb}
                error={!!errors.pbb}
                helperText={errors.pbb}
                onChange={handleChange}
                thousandSeparator={true}
                prefix="Rp "
              />
              {/* <TextField
                fullWidth
                label="NJOP Bumi (m2)"
                name="pbb"
                value={values.pbb}
                error={!!errors.pbb}
                helperText={errors.pbb}
                onChange={handleChange}
              /> */}
            </Box>
            <Divider sx={{ my: "24px" }} />
            <Typography fontSize="20px" fontWeight={700} color="black" mb={"16px"}>
              Regulation
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
              <Box display="flex" gap="16px">
                <FormControl sx={{ flex: 1 }}>
                  <InputLabel id="status-select-label">Peruntukan</InputLabel>
                  <Select
                    labelId="status-select-label"
                    id="landStatusAllotmentId-select"
                    value={values.landStatusAllotmentId}
                    label="Peruntukan"
                    name="landStatusAllotmentId"
                    onChange={handleChange}
                  >
                    {peruntukan &&
                      peruntukan.map((i) => (
                        <MenuItem key={i.id} value={i.id}>
                          {i.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors.ownershipStatusId && <FormHelperText error>{errors.ownershipStatusId}</FormHelperText>}
                </FormControl>
                <TextField
                  sx={{ flex: 1 }}
                  label="KDB"
                  name="regulations[0].kdb"
                  value={(values.regulations as any)[0].kdb}
                  error={!!errors.regulations}
                  onChange={handleChange}
                  type="number"
                />
              </Box>
              <Box display="flex" gap="16px">
                <TextField
                  sx={{ flex: 1 }}
                  label="KLB"
                  name="regulations[0].klb"
                  value={(values.regulations as any)[0].klb}
                  error={!!errors.regulations}
                  onChange={handleChange}
                  type="number"
                />
                {/* <TextField
                  sx={{ flex: 1 }}
                  label="GSB"
                  name="regulations[0].gbs"
                  value={(values.regulations as any)[0].gbs}
                  error={!!errors.regulations}
                  onChange={handleChange}
                  type="number"
                /> */}
                <TextField
                  sx={{ flex: 1 }}
                  label="KDH"
                  name="regulations[0].kdh"
                  value={(values.regulations as any)[0].kdh}
                  error={!!errors.regulations}
                  onChange={handleChange}
                  type="number"
                />
              </Box>
              <TextField
                fullWidth
                label="Building height"
                name="regulations[0].buildingHeight"
                value={(values.regulations as any)[0].buildingHeight}
                error={!!errors.regulations}
                onChange={handleChange}
                type="number"
                InputProps={{
                  endAdornment: <Typography>Meter</Typography>,
                }}
              />
              <TextField
                sx={{ flex: 1 }}
                label="Lain Lain"
                name="regulations[0].others"
                value={(values.regulations as any)[0].others}
                error={!!errors.regulations}
                onChange={handleChange}
              />
            </Box>
            <Divider sx={{ my: "24px" }} />
            <Typography color="black" fontSize="20px" fontWeight={700} mb="16px">
              Attachment
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
              {landDetailLoading ? (
                <CircularProgress sx={{ mx: "auto" }} />
              ) : (
                <ErrorBoundary>
                  <DraggableFileUpload
                    setData={setAttachment}
                    data={attachment}
                    accept={`${FileType.Image}, ${FileType.Document}, ${FileType.Pdf}`}
                  />
                </ErrorBoundary>
              )}
            </Box>
            <Divider sx={{ my: "24px" }} />
            <Typography color="black" fontSize="20px" fontWeight={700} mb="16px">
              Location
            </Typography>
            <ErrorBoundary>
              <MapViewer style={{ height: "250px" }} zoom={12}>
                <MapViewer.SearchControl />
                <MapViewer.Marker
                  onClick={(e) => {
                    setFieldValue("latitude", e.latlng.lat);
                    setFieldValue("longitude", e.latlng.lng);
                  }}
                  autoFocus
                  position={
                    values.latitude && values.longitude
                      ? {
                          lat: Number(values.latitude),
                          lng: Number(values.longitude),
                        }
                      : undefined
                  }
                />
              </MapViewer>
            </ErrorBoundary>
          </Box>
        </Box>
        <Box px={"16px"} pb={"16px"} display="flex" flexDirection="column" alignItems="flex-end">
          <Divider sx={{ mb: "16px", width: "100%" }} />
          <LoadingButton type="submit" sx={{ width: "fit-content" }} variant="contained" loading={isSubmitting}>
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  );
}

export default observer(LandForm);
