import { Box, Grid, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import ProposalFillForm from "../../ProposalFillForm";
import { useStore } from "../../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import useQuery from "../../../../../app/hook/useQuery";
import { history } from "../../../../..";
import { BD_OTHERS_NAME, NON_BD_OTHERS_NAME, ProjectCategory } from "../../../../../app/config/enum";
import { NumericFormat } from "react-number-format";

function ProposalStepForm() {
  const { generalInformation, editGeneralInformation, getGeneralInformation, editGeneralInformationLoading } = useStore().proposalStore;
  const { getProjectCategoryAll, projectCategoryAll } = useStore().projectCategoryAllStore;
  const query = useQuery();
  const [title, setTitle] = useState<string>();
  const [cost, setCost] = useState<number>();
  const [category, setCategory] = useState<ProjectCategory>(ProjectCategory.Anorganik);

  const proposalId = query.get("id");
  const currentProjectCategory = query.get("projectCategory");
  const status = query.get("status");

  const handleUpdateGeneralInformation = () => {
    if (!proposalId) return;

    editGeneralInformation(proposalId, { ...generalInformation, title, cost })
      .then(() => getGeneralInformation(proposalId))
      .catch(() => {
        setCost(generalInformation?.cost);
        setTitle(generalInformation?.title);
      });
  };

  const handleUpdateCategoryOnClick = (v: any) => {
    if (proposalId) {
      editGeneralInformation(proposalId, { ...generalInformation, category: v.target.value })
        .then(() =>
          history.push(
            `/project-monitoring/proposal/list/form?projectCategory=${v.target.value}&${proposalId ? `id=${proposalId}` : ""}&${
              status ? `status=${status}` : ""
            }`
          )
        )
        .then(() => {
          getGeneralInformation(proposalId);
        })
        .catch(() => setCategory(generalInformation?.category as any));
    } else {
      history.push(
        `/project-monitoring/proposal/list/form?projectCategory=${v.target.value}&${proposalId ? `id=${proposalId}` : ""}&${
          status ? `status=${status}` : ""
        }`
      );
    }
  };

  useEffect(() => {
    getProjectCategoryAll();
  }, [getProjectCategoryAll]);

  useEffect(() => {
    setTitle(generalInformation?.title);
    setCost(generalInformation?.cost);
  }, [setTitle, generalInformation, setCost]);

  return (
    <Box>
      <Box mb="24px">
        <Stack gap="24px">
          {!!generalInformation?.title && !!generalInformation.cost && (
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Typography sx={{ mb: "12px" }} color="black" fontWeight={700} fontSize="20px">
                  Title
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Title"
                  value={title}
                  // disabled={editGeneralInformationLoading || isApprovalMode || status !== ProjectStatus.Proposal}
                  disabled
                  onChange={(v) => setTitle(v.target.value)}
                  onBlur={handleUpdateGeneralInformation}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ mb: "12px" }} color="black" fontWeight={700} fontSize="20px">
                  Cost
                </Typography>
                <NumericFormat
                  customInput={TextField}
                  fullWidth
                  placeholder="Cost"
                  value={cost}
                  thousandSeparator={true}
                  prefix={"Rp"}
                  onValueChange={(e) => {
                    setCost(e.floatValue);
                  }}
                  disabled
                  onBlur={handleUpdateGeneralInformation}
                />
              </Grid>
            </Grid>
          )}
          {/* <CSelect
            loading={projectCategoryLoading}
            // disabled={isApprovalMode || status !== ProjectStatus.Proposal}
            disabled
            label="Kategori Proyek"
            options={projectCategory.map((i) => ({ label: i.text, value: i.value }))}
            value={currentProjectCategory}
            onChange={handleUpdateCategoryOnClick}
          /> */}

          <Select disabled label="Kategori Proyek" value={currentProjectCategory} onChange={handleUpdateCategoryOnClick}>
            {projectCategoryAll.map((item) => {
              return (
                <MenuItem
                  sx={{ display: "none" }}
                  value={item.name === BD_OTHERS_NAME ? "BD" : item.name === NON_BD_OTHERS_NAME ? "NON_BD" : item.name}
                >
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </Stack>
      </Box>
      {currentProjectCategory && (
        <Box>
          <ProposalFillForm />
        </Box>
      )}
    </Box>
  );
}

export default observer(ProposalStepForm);
