import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Drawer, IconButton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import TextEditor from "../../../../app/component/TextEditor";
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import useQuery from "../../../../app/hook/useQuery";
import { FlagEdit } from "../../../../app/config/enum";
import DraggableFileUploadV2, { DraggableFileUploadData } from "../../../../app/component/input/DraggableFileUploadV2";
import CommentList from "./components/CommentList";

const validationSchema = yup.object({
  items: yup.array().of(
    yup.object({
      PDPPU: yup.string(),
      BuildingApproval: yup.string(),
      CityPlanInformation: yup.string(),
      LicensesCertificate: yup.string(),
      OtherLicensing: yup.string(),
    })
  ),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function ProposalPermissionForm({ isOpen, handleClose, readOnly }: { isOpen: boolean; handleClose: () => any; readOnly: boolean }) {
  const [files, setFiles] = useState<DraggableFileUploadData[]>([]);
  const [loadingAttc, setLoadingAttc] = useState(false);
  const { addPermission, permission, getPermission, editPermission, deleteAttachment } = useStore().proposalStore;
  const query = useQuery();
  const id = query.get("id");

  const initialValues: any = useMemo(() => {
    if (!!permission && permission.length > 0) {
      const res = permission.map((object) => {
        return {
          id: object.id,
          PDPPU: object.pdppu,
          BuildingApproval: object.buildingApproval,
          CityPlanInformation: object.cityPlanInformation,
          LicensesCertificate: object.licensesCertificate,
          OtherLicensing: object.otherLicensing,
        };
      });

      return { items: res };
    } else {
      return {
        items: [
          {
            PDPPU: undefined,
            BuildingApproval: undefined,
            CityPlanInformation: undefined,
            LicensesCertificate: undefined,
            OtherLicensing: undefined,
          },
        ],
      };
    }
  }, [permission]);

  const onSubmit = async (values: ValidationSchema) => {
    if (readOnly) return;

    const id = query.get("id");

    if (!id) return;

    const formData = new FormData();

    values.items?.forEach((item, index) => {
      item.PDPPU && formData.append(`permissionAnalyses[${index}].PDPPU`, item.PDPPU);
      item.BuildingApproval && formData.append(`permissionAnalyses[${index}].BuildingApproval`, item.BuildingApproval);
      item.CityPlanInformation && formData.append(`permissionAnalyses[${index}].CityPlanInformation`, item.CityPlanInformation);
      item.LicensesCertificate && formData.append(`permissionAnalyses[${index}].LicensesCertificate`, item.LicensesCertificate);
      item.OtherLicensing && formData.append(`permissionAnalyses[${index}].OtherLicensing`, item.OtherLicensing);

      if (permission && permission.length > 0) {
        const i = item as any;
        i.id && formData.append(`permissionAnalyses[${index}].id`, i.id);
        formData.append(`permissionAnalyses[${index}].flagEdit`, FlagEdit.PROPOSAL.toString());
      }

      files.forEach((file, x) => {
        if (file.data?.id) {
          // formData.append(`permissionAnalyses[${index}].attachments[${x}].attachment`, file.data.attachment as File)
          // formData.append(`permissionAnalyses[${index}].attachments[${x}].fileName`, '')
          formData.append(`permissionAnalyses[${index}].attachments[${x}].id`, file.data.id);
        } else {
          formData.append(`permissionAnalyses[${index}].attachments[${x}].attachment`, file.file as File);
          formData.append(`permissionAnalyses[${index}].attachments[${x}].fileName`, "");
        }
      });
    });

    if (permission && permission.length > 0) {
      await editPermission(id, formData as any).then(() => {
        handleClose();
        resetForm();
        setErrors({ items: undefined });
        getPermission(id);
      });
    } else {
      await addPermission(id, formData as any).then(() => {
        handleClose();
        resetForm();
        setErrors({ items: undefined });
        getPermission(id);
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const { handleSubmit, isSubmitting, submitForm, resetForm, setErrors, setFieldValue, values, isValid, dirty } = formik;

  useEffect(() => {
    if (permission) {
      permission.map((item) => {
        setFiles(
          item.attachments.map((attachment) => ({
            file: {
              name: attachment.fileName,
            },
            data: attachment,
          }))
        );
      });
    }
  }, [permission]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Drawer
        anchor="right"
        open={isOpen}
        sx={{
          ".MuiPaper-root": {
            width: "500px",
          },
        }}
      >
        <Box
          sx={{
            m: "16px 16px 0 16px",
            pb: "8px",
            borderBottom: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "space-between",
            mb: "16px",
          }}
        >
          <Typography fontSize={"14px"} fontWeight={700} color="black">
            {!!permission && permission.length > 0 ? "Edit" : "Add"} Kajian Perizinan
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Stack flex={1} overflow="auto" p="16px" spacing="24px">
          {values.items?.map((value, index) => (
            <>
              <Box>
                <TextEditor
                  label="Peraturan Daerah dan Peraturan Perundang undangan lainya"
                  value={value.PDPPU}
                  onChange={(e) => setFieldValue(`items[${index}].PDPPU`, e)}
                />
              </Box>
              <Box>
                <TextEditor
                  label="Persetujuan Bangunan Gedung"
                  value={value.BuildingApproval}
                  onChange={(e) => setFieldValue(`items[${index}].BuildingApproval`, e)}
                />
              </Box>
              <Box>
                <TextEditor
                  label="Informasi Rencana Kota"
                  value={value.CityPlanInformation}
                  onChange={(e) => setFieldValue(`items[${index}].CityPlanInformation`, e)}
                />
              </Box>
              <Box>
                <TextEditor
                  label="Perizinan dan Sertifikat lainya"
                  value={value.LicensesCertificate}
                  onChange={(e) => setFieldValue(`items[${index}].LicensesCertificate`, e)}
                />
              </Box>
              <Box>
                <TextEditor
                  label="Hal lain terkait perijinan yang perlu diperhatikan"
                  value={value.OtherLicensing}
                  onChange={(e) => setFieldValue(`items[${index}].OtherLicensing`, e)}
                />
              </Box>
              <Box>
                <DraggableFileUploadV2
                  data={files}
                  setData={setFiles}
                  viewable
                  viewableOptions={{ propsoalId: id ?? "" }}
                  accept="application/pdf,application/msword,image/*"
                  loading={loadingAttc}
                  onDelete={(data) => {
                    if (!id) return;

                    setLoadingAttc(true);

                    deleteAttachment(id, data.id)
                      .then(() => getPermission(id))
                      .finally(() => setLoadingAttc(false));
                  }}
                />
              </Box>
            </>
          ))}
          {isOpen && permission ? <CommentList isOpen={isOpen} data={permission} /> : ""}
        </Stack>
        <Box
          sx={{
            m: "0 16px 16px 16px",
            pt: "16px",
            borderTop: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <LoadingButton loading={isSubmitting} disabled={!isValid || readOnly} type="submit" variant="contained" onClick={submitForm}>
            {!!permission && permission.length > 0 ? "Edit" : "Add"}
          </LoadingButton>
        </Box>
      </Drawer>
    </form>
  );
}

export default observer(ProposalPermissionForm);
