import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { StyledDataGrid } from "../../../app/component/StyledDataGrid";
import { format } from "date-fns";

export const StatusDetailGrid = observer(() => {
  const { taskListByStatus } = useStore().DashboardStore;

  return (
    <StyledDataGrid
      rows={taskListByStatus}
      getRowId={(row) => row.taskId}
      columns={[
        {
          field: "projectName",
          headerName: "Project",
          flex: 1
        },
        {
          field: "name",
          headerName: "Task",
          flex: 0.8
        },
        {
          field: "startDate",
          headerName: "Start Date",
          renderCell: (params) => format(new Date(params.row.startDate), "MMM yyyy"),
          width: 100,
        },
        {
          field: "endDate",
          headerName: "End Date",
          renderCell: (params) => format(new Date(params.row.endDate), "MMM yyyy"),
          width: 100,
        },
        {
          field: "organizationName",
          headerName: "Organization Name",
          width: 180
        },
        {
          field: "nominalPagu",
          headerName: "Nominal Pagu",
          width: 120
        },
      ]}
      autoHeight
    />
  );
});
