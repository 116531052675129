import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TableContainer,
  Skeleton,
  Chip,
  Grid,
} from "@mui/material";
import {
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
  VisibilityOutlined,
  AddCircleOutline,
  AddCardOutlined,
  Delete,
  EditOutlined,
  AddCard,
  DescriptionOutlined,
} from "@mui/icons-material";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { IProgressRealization, TWeightPhysicalActuals } from "../../../../../../app/models/progressRealization";
import DraggableFileUpload, { DraggableFileUploadData } from "../../../../../../app/component/input/DraggableFileUpload";
import { StatusTask, TaskSubTaskStatus, enumToArray } from "../../../../../../app/config/enum";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadingButton } from "@mui/lab";
import { NumericFormat } from "react-number-format";
import { useFormik } from "formik";
import { useStore } from "../../../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EmptyState from "../../../../../../app/component/EmptyState";
import CSelect from "../../../../../../app/component/CSelect";
import useQuery from "../../../../../../app/hook/useQuery";
import Progress from "./components/Progress";
import * as yup from "yup";
import ViewAttachment from "../../../../../../app/component/ViewAttachment";

function CostRealizationTab() {
  const { getGeneralInformation, generalInformation } = useStore().proposalStore;
  const { newCostRealization, newCostRealizationLoading, getNewCostRealization } = useStore().costRealizationStore;
  const { account } = useStore().accountStore;
  const [selectedSubTask, setSelectedSubtask] = useState<IProgressRealization["tasks"][0]>();
  const query = useQuery();
  const id = query.get("id");

  useEffect(() => {
    if (id && account) getNewCostRealization(id, account, generalInformation?.projectOwnerName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewCostRealization, id]);

  useEffect(() => {
    if (!id) return;
    getGeneralInformation(id);
  }, [getGeneralInformation, id]);

  return (
    <Box>
      {newCostRealizationLoading ? (
        <Box display="flex" justifyContent="center" py="32px">
          <CircularProgress />
        </Box>
      ) : newCostRealization.data.length === 0 ? (
        <EmptyState />
      ) : (
        newCostRealization.data[0].milestones.map((item, index) => (
          <>
            {index === 0 ? (
              <Box key={index}>
                <Stack
                  mb={3}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ backgroundColor: ({ palette }) => palette.primary.light }}
                  p={2}
                  borderRadius={2}
                >
                  <Typography fontSize="20px" fontWeight={700} sx={{ color: ({ palette }) => palette.primary.main }}>
                    {newCostRealization.data[0].projectName}
                  </Typography>
                  <Typography fontSize="20px" fontWeight={700} sx={{ color: ({ palette }) => palette.primary.main }}>
                    Rp{newCostRealization.data[0].projectCostProgress.toLocaleString()} / Rp{newCostRealization.data[0].projectCost.toLocaleString()}
                  </Typography>
                </Stack>
                <ListItem
                  projectOwnerName={generalInformation?.projectOwnerName}
                  setSelectedSubtask={setSelectedSubtask}
                  selectedSubTask={selectedSubTask}
                  milestone={item}
                />
              </Box>
            ) : (
              <ListItem
                projectOwnerName={generalInformation?.projectOwnerName}
                key={index}
                setSelectedSubtask={setSelectedSubtask}
                selectedSubTask={selectedSubTask}
                milestone={item}
              />
            )}
          </>
        ))
      )}
    </Box>
  );
}

export default observer(CostRealizationTab);

type ListItemProps = {
  generalInformation?: any;
  milestone: any;
  setSelectedSubtask: Dispatch<SetStateAction<any>>;
  selectedSubTask?: any;
  projectOwnerName?: string;
};

const ListItem = ({ milestone, setSelectedSubtask, selectedSubTask, projectOwnerName }: ListItemProps) => {
  const { account } = useStore().accountStore;
  const [selectedFinancialActual, setSelectedFinancialActual] = useState<TWeightPhysicalActuals>();
  const [financialActualProgress, setFinancialActualProgress] = useState<any[]>();
  const [openNestedTable, setOpenNestedTable] = useState<boolean>(false);
  const [isOpenAddMOdal, setIsOpenAddMOdal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isHasNoChild, setIsHasNoChild] = useState<boolean>(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [data, setData] = useState<DraggableFileUploadData[]>([]);
  const [deletedData, setDeletedData] = useState<any[]>([]);
  const [drawerType, setDrawerType] = useState<string>("");
  const [nestedIndex, setNestedIndex] = useState<number>();
  const { costRealizationStore, dialogStore } = useStore();
  const [selectedTask, setSelectedTask] = useState<any>();
  const [open, setIsOpen] = useState<boolean>(false);
  const toggleExpandCollapse = () => setIsOpen((prev) => !prev);
  const query = useQuery();
  const id = query.get("id");

  const handleCloseMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, identifer: string) => {
    handleCloseMenu(e);
    setIsOpenDrawer(true);
    setDrawerType(identifer);
  };

  const getProgressByTask = (task: any) => {
    setSelectedTask(task);
    setIsHasNoChild(true);
    setSelectedSubtask(undefined);

    costRealizationStore.getFinancialActualProgress("", task.tasksId).then((res: any[]) => {
      addProgressFormik.setFieldValue("status", res.length > 0 ? res[0].status : "");
      setFinancialActualProgress(res);
    });
  };

  const initialValues = useMemo(() => {
    return {
      weightFinancialActual: drawerType === "edit" ? selectedFinancialActual?.weightFinancialActual : 0,
      date: drawerType === "edit" ? new Date(selectedFinancialActual?.date ?? 0) : new Date(),
      status: drawerType === "edit" ? selectedFinancialActual?.status : TaskSubTaskStatus.Construction,
      description: drawerType === "edit" ? selectedFinancialActual?.description ?? "" : "",
    };
  }, [drawerType, selectedFinancialActual]);

  const addProgressFormik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      if (!id || !account) return;

      const formData = new FormData();
      let dataAttachment = data.concat(deletedData);

      dataAttachment.forEach((item, index) => {
        formData.append(`attachments[${index}].attachment`, item.file as File);
        formData.append(`attachments[${index}].fileName`, "-");
        if (item.data) {
          formData.append(`attachments[${index}].id`, item.data.id);
          item.isDeleted && formData.append(`attachments[${index}].isDeleted`, item.isDeleted ? "1" : "0");
        }
      });

      formData.append("taskId", isHasNoChild ? selectedTask.tasksId : selectedSubTask.tasksId);
      !isHasNoChild && formData.append("subtaskId", selectedSubTask.subTaskId);
      formData.append("weightFinancialActual", String(values.weightFinancialActual));
      formData.append("date", new Date(values.date).toUTCString());
      formData.append("description", values.description);
      formData.append("proposalId", id);
      formData.append("status", values.status ?? '')

      if (drawerType === "edit") {
        await costRealizationStore.editCostRealization(selectedFinancialActual?.id ?? "", formData).then(() => {
          costRealizationStore.getNewCostRealization(id, account, projectOwnerName);
          setIsOpenAddMOdal(false);
          setIsOpenDrawer(false);
          setIsHasNoChild(false);
          addProgressFormik.resetForm();
        });
      } else {
        await costRealizationStore.addCostRealization(formData).then(() => {
          costRealizationStore.getNewCostRealization(id, account, projectOwnerName);
          setIsOpenAddMOdal(false);
          setIsOpenDrawer(false);
          setIsHasNoChild(false);
          addProgressFormik.resetForm();
        });
      }
    },
    validationSchema: yup.object({
      date: yup.date().required(),
      weightFinancialActual: yup.number().required("Cost is a required field"),
      status: yup.string().required(),
      description: yup.string(),
    }),
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!selectedSubTask) return;
    setFinancialActualProgress([]);

    if (isOpenDrawer) {
      costRealizationStore.getFinancialActualProgress(selectedSubTask.subTaskId, selectedSubTask.tasksId).then((res) => {
        addProgressFormik.setFieldValue("status", res[0].status);
        setFinancialActualProgress(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costRealizationStore, isOpenDrawer, selectedSubTask]);

  return (
    <>
      <Stack direction="row" alignItems="center" gap="8px">
        <Stack
          py={"20px"}
          sx={{
            cursor: "pointer",
            width: "100%",
            justifyContent: "flex-start",
          }}
          component={ButtonBase}
          onClick={toggleExpandCollapse}
        >
          <Stack direction="row" spacing={"16px"} sx={{ width: "100%", justifyContent: "flex-start" }}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            <Typography sx={{ width: "100%" }} fontWeight={700}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ color: "black" }}>{milestone.milestoneName}</Box>
                <Box
                  sx={{ color: "black", fontWeight: "normal" }}
                >{`Rp${milestone.milestoneCostProgress.toLocaleString()} / Rp${milestone.milestoneCost.toLocaleString()}`}</Box>
              </Box>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Collapse in={open} timeout="auto" unmountOnExit>
        {milestone.tasks.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableCell></TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Start Date</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>End Date</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Weight (Rp)</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Progress (%)</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>
                  <Box sx={{ width: "180px" }}>Cost Realization (Rp)</Box>
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Perencanaan</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>
                  <Box sx={{ width: "100px" }}>Doc Tender</Box>
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Lelang</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>
                  <Box sx={{ width: "160px" }}>Pemenang Lelang</Box>
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Construction</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Pembayaran</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Action</TableCell>
              </TableHead>
              {milestone.tasks.map((task: any, index: number) => (
                <TableBody>
                  <TableRow>
                    {task.subtasks.length > 0 ? (
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            setNestedIndex(index);
                            setOpenNestedTable(!openNestedTable);
                          }}
                        >
                          {openNestedTable && index === nestedIndex ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )}
                    <TableCell>
                      <Box sx={{ width: "100px" }}>{task.taskName}</Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ width: "100px" }}>{format(new Date(task.starDate), "dd MMM yyyy")}</Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ width: "100px" }}>{format(new Date(task.endDate), "dd MMM yyyy")}</Box>
                    </TableCell>
                    <TableCell>
                      <Chip label={task.status} color="primary" />
                    </TableCell>
                    <TableCell>Rp{task.taskCost.toLocaleString()}</TableCell>
                    <TableCell>
                      <Progress data={(task.taskCostProgress * 100).toFixed(2)} />
                    </TableCell>
                    <TableCell>Rp{task.taskCostRealization.toLocaleString()}</TableCell>
                    <TableCell>Rp{task.taskPerencanaan.toLocaleString()}</TableCell>
                    <TableCell>Rp{task.taskDocTender.toLocaleString()}</TableCell>
                    <TableCell>Rp{task.taskLelang.toLocaleString()}</TableCell>
                    <TableCell>Rp{task.taskPemenangLelang.toLocaleString()}</TableCell>
                    <TableCell>Rp{task.taskConstruction.toLocaleString()}</TableCell>
                    <TableCell>Rp{task.taskPembayaran.toLocaleString()}</TableCell>
                    {task.subtasks.length > 0 ? (
                      <TableCell></TableCell>
                    ) : (
                      <TableCell>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "12px", alignItems: "center" }}>
                          <Box
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              setSelectedTask(task);
                              setSelectedSubtask(undefined);
                              setIsHasNoChild(true);
                              setDrawerType("");
                              setIsOpenAddMOdal(true);
                              setData([]);
                            }}
                          >
                            <AddCircleOutline />
                          </Box>
                          <Box
                            sx={{ cursor: "pointer" }}
                            color="inherit"
                            onClick={(e) => {
                              handleOpenMenu(e, "edit");
                              getProgressByTask(task);
                            }}
                          >
                            <VisibilityOutlined />
                          </Box>
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    {task.subtasks.length > 0 ? (
                      <TableCell sx={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#edecec4d", paddingLeft: "48px" }} colSpan={16}>
                        <Collapse in={openNestedTable && index === nestedIndex} timeout="auto" unmountOnExit>
                          <Box sx={{ m: 1, ml: 2 }}>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Start Date</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>End Date</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Weight (Rp)</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Progress (%)</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>
                                  <Box sx={{ width: "180px" }}>Cost Realization (Rp)</Box>
                                </TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Perencanaan</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>
                                  <Box sx={{ width: "100px" }}>Doc Tender</Box>
                                </TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Lelang</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>
                                  <Box sx={{ width: "160px" }}>Pemenang Lelang</Box>
                                </TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Construction</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Pembayaran</TableCell>
                                <TableCell sx={{ fontWeight: 700 }}>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {task.subtasks.map((subtask: any, siIndex: number) => (
                                <TableRow key={siIndex}>
                                  <TableCell>
                                    <Box sx={{ width: "100px" }}>{subtask.subTaskName}</Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box sx={{ width: "100px" }}>{format(new Date(subtask.starDate), "dd MMM yyyy")}</Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box sx={{ width: "100px" }}>{format(new Date(subtask.endDate), "dd MMM yyyy")}</Box>
                                  </TableCell>
                                  <TableCell>
                                    <Chip label={subtask.status} color="primary" />
                                  </TableCell>
                                  <TableCell>Rp{subtask.subTaskCost.toLocaleString()}</TableCell>
                                  <TableCell>
                                    <Progress data={(subtask.subTaskCostProgress * 100).toFixed(2)} />
                                  </TableCell>
                                  <TableCell>Rp{subtask.subTaskCostRealization.toLocaleString()}</TableCell>
                                  <TableCell>Rp{subtask.subTaskPerencanaan.toLocaleString()}</TableCell>
                                  <TableCell>Rp{subtask.subTaskDocTender.toLocaleString()}</TableCell>
                                  <TableCell>Rp{subtask.subTaskLelang.toLocaleString()}</TableCell>
                                  <TableCell>Rp{subtask.subTaskPemenangLelang.toLocaleString()}</TableCell>
                                  <TableCell>Rp{subtask.subTaskConstruction.toLocaleString()}</TableCell>
                                  <TableCell>Rp{subtask.subTaskPembayaran.toLocaleString()}</TableCell>
                                  <TableCell>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "12px", alignItems: "center" }}>
                                      <Box
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setDrawerType("");
                                          setIsOpenAddMOdal(true);
                                          setSelectedSubtask(subtask);
                                          setData([]);
                                        }}
                                      >
                                        <AddCircleOutline />
                                      </Box>
                                      <Box
                                        sx={{ cursor: "pointer" }}
                                        color="inherit"
                                        onClick={(e) => {
                                          handleOpenMenu(e, "edit");
                                          setSelectedSubtask(subtask);
                                        }}
                                      >
                                        <VisibilityOutlined />
                                      </Box>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Box>
                        </Collapse>
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                </TableBody>
              ))}
            </Table>
          </TableContainer>
        ) : (
          <EmptyState />
        )}
      </Collapse>

      <form onSubmit={addProgressFormik.handleSubmit} noValidate>
        <Dialog
          open={isOpenAddMOdal}
          onClose={() => {
            setIsOpenAddMOdal(false);
            addProgressFormik.resetForm();
          }}
        >
          <DialogContent sx={{ padding: "16px", width: "365px" }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography fontWeight="700" fontSize="14px" color="black">
                {`${drawerType === "edit" ? "Edit" : "Add"} Cost`}
              </Typography>
              <Close
                onClick={() => {
                  setIsOpenAddMOdal(false);
                  addProgressFormik.resetForm();
                }}
              />
            </Stack>
            <Box mt={"12px"}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  value={addProgressFormik.values.date}
                  onChange={(e: any) => addProgressFormik.setFieldValue("date", e)}
                  renderInput={(props) => (
                    <TextField
                      required
                      fullWidth
                      name="date"
                      error={!!addProgressFormik.errors.date}
                      helperText={addProgressFormik.errors.date && String(addProgressFormik.errors.date)}
                      {...props}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box mt="12px">
              <CSelect
                options={enumToArray(StatusTask).map((statusTask) => ({
                  label: statusTask.text,
                  value: statusTask.value,
                }))}
                label="Status"
                name="status"
                value={addProgressFormik.values.status}
                error={!!addProgressFormik.errors.status}
                helperText={addProgressFormik.errors.status}
                onChange={addProgressFormik.handleChange}
              />
            </Box>
            <Box mt="12px">
              <NumericFormat
                prefix={"Rp"}
                thousandSeparator={true}
                customInput={TextField}
                label="Cost"
                name="weightFinancialActual"
                required
                fullWidth
                value={Number(addProgressFormik.values.weightFinancialActual)}
                error={!!addProgressFormik.errors.weightFinancialActual}
                helperText={addProgressFormik.errors.weightFinancialActual}
                onValueChange={(e) => addProgressFormik.setFieldValue("weightFinancialActual", e.floatValue)}
                InputProps={{
                  endAdornment: <AddCardOutlined sx={{ color: "#757575" }} />,
                }}
              />
            </Box>
            <Box mt="12px">
              <TextField
                sx={{ mb: "12px" }}
                label="Description"
                name="description"
                multiline
                fullWidth
                rows={3}
                helperText={addProgressFormik.errors.description}
                error={!!addProgressFormik.errors.description}
                value={addProgressFormik.values.description}
                onChange={addProgressFormik.handleChange}
              />
            </Box>
            <Box mt="12px">
              <DraggableFileUpload
                getDeletedData={(value: any) => {
                  let helper = deletedData;

                  helper.push(value);
                  setDeletedData(helper);
                }}
                data={data}
                setData={setData}
                editableTitle={false}
                accept="application/pdf, image/*"
              />
            </Box>

            <Stack direction="row" justifyContent="flex-end" gap="12px" mt={"24px"}>
              <Button
                onClick={() => {
                  setIsOpenAddMOdal(false);
                  addProgressFormik.resetForm();
                }}
                type="button"
                variant="outlined"
                color="inherit"
              >
                Cancel
              </Button>
              <LoadingButton
                onClick={addProgressFormik.submitForm}
                // disabled={!addProgressFormik.isValid || !addProgressFormik.dirty}
                loading={addProgressFormik.isSubmitting}
                variant="contained"
                color="primary"
              >
                {`${drawerType === "edit" ? "Update" : "Add"}`}
              </LoadingButton>
            </Stack>
          </DialogContent>
        </Dialog>
      </form>

      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={() => setIsOpenDrawer(false)}
        sx={{
          ".MuiPaper-root": {
            width: "500px",
          },
        }}
      >
        <Box
          sx={{
            m: "16px 16px 0 16px",
            pb: "8px",
            borderBottom: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "space-between",
            mb: "16px",
            alignItems: "center",
          }}
        >
          <Box sx={{ color: "black", fontWeight: "bold" }}>Cost history</Box>
          <IconButton onClick={() => setIsOpenDrawer(false)}>
            <Close />
          </IconButton>
        </Box>
        <Stack flex={1} overflow="auto" p="16px" spacing="24px">
          {costRealizationStore.financialActualProgressLoading ? (
            <Box display="flex" justifyContent="center" py="32px">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box sx={{ fontSize: "20px", fontWeight: "bold" }}>{selectedSubTask?.subTaskName || selectedTask?.taskName}</Box>
              {financialActualProgress?.map((item: any) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "12px",
                    padding: "16px",
                    alignItems: "center",
                    borderRadius: "4px",
                    border: "1px solid rgba(29, 27, 32, 0.12)",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                        <AddCard />
                        <Box>
                          <Box sx={{ fontWeight: "bold" }}>{`Rp${item.weightFinancialActual.toLocaleString()} | ${item.status}`}</Box>
                          <Box sx={{ fontSize: "12px" }}>{item.description}</Box>
                          <Box sx={{ fontSize: "12px", color: "#999" }}>{format(new Date(item.date), "dd MMMM yyyy")}</Box>
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setData(
                              item.attachments.map((item: any) => ({
                                title: item.fileName ?? "No Name",
                                file: { name: item.fileName ?? "No Name", id: item.id },
                                data: item,
                              })) ?? []
                            );
                            setIsOpenAddMOdal(true);
                            setSelectedFinancialActual(item);
                          }}
                        >
                          <EditOutlined sx={{ fontSize: "24px" }} />
                        </Box>
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            dialogStore.open({
                              action: async () =>
                                await costRealizationStore.deleteFinancialActualProgress(item.id).then(() => {
                                  if (!id || !account) return;

                                  costRealizationStore.getNewCostRealization(id, account, projectOwnerName);
                                  setIsOpenAddMOdal(false);
                                  setIsOpenDrawer(false);
                                  addProgressFormik.resetForm();
                                }),
                              actionText: "OK",
                              title: "Delete",
                              closeText: "Cancel",
                              actionButtonProps: {
                                color: "error",
                              },
                              description: "Are you sure want to delete this progress?",
                            });
                          }}
                        >
                          <Delete sx={{ fontSize: "24px" }} />
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      {item.attachments.length > 0 ? (
                        <>
                          <hr />
                          <Box sx={{ textAlign: "center", fontWeight: "bold" }}>Attachment List</Box>
                          <Grid container sx={{ mt: "2px" }} spacing={2}>
                            {item.attachments.map((attachment: any) => (
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    p: "8px",
                                    borderRadius: "4px",
                                    border: "1px solid rgba(29, 27, 32, 0.12)",
                                  }}
                                >
                                  <ViewAttachment
                                    loadingComponent={<Skeleton width={150} variant="text" />}
                                    url={attachment.url}
                                    proposalId={id ?? ""}
                                  >
                                    <DescriptionOutlined sx={{ cursor: "pointer" }} />
                                  </ViewAttachment>
                                  <Box sx={{ width: "100%", justifyContent: "space-between", alignItems: "center", display: "flex", ml: "12px" }}>
                                    <Box sx={{ fontWeight: "bold" }}>{attachment.fileName ?? "No Name"}</Box>
                                  </Box>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                </Box>
              ))}
            </>
          )}
        </Stack>
        <Box
          sx={{
            m: "0 16px 16px 16px",
            pt: "16px",
            borderTop: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={() => setIsOpenDrawer(false)} variant="outlined" color="inherit">
            Close
          </Button>
        </Box>
      </Drawer>
    </>
  );
};
