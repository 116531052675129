import { ButtonProps } from "@mui/material";
import { makeAutoObservable, runInAction } from "mobx";
import { ReactNode } from "react";

export interface IDialog {
    title?: string,
    description?: string,
    content?: ReactNode;
    closeText?: string,
    action?: () => Promise<any>,
    actionText?: string,
    actionButtonProps?: ButtonProps;
    closeButtonProps?: ButtonProps;
    reverseButton?: boolean;
}

export default class DialogStore {
    dialog: IDialog = {
        title: undefined,
        description: undefined,
        closeText: undefined,
        action: undefined,
        actionText: undefined,
        actionButtonProps: undefined,
        closeButtonProps: undefined,
        reverseButton: false,
        content: undefined,
    }

    isOpen = false;
    isLoading = false;

    constructor() {
        makeAutoObservable(this);
    }

    open = (dialog: IDialog) => {
        this.dialog.title = dialog.title;
        this.dialog.description = dialog.description;
        this.dialog.closeText = dialog.closeText;
        this.dialog.action = dialog.action;
        this.dialog.actionText = dialog.actionText;
        this.isOpen = true;
        this.dialog.actionButtonProps = dialog.actionButtonProps
        this.dialog.closeButtonProps = dialog.closeButtonProps
        this.dialog.reverseButton = dialog.reverseButton
        this.dialog.content = dialog.content
    }

    close = () => {
        this.isOpen = false;
        this.dialog.title = undefined;
        this.dialog.description = undefined;
        this.dialog.closeText = undefined;
        this.dialog.action = undefined;
        this.dialog.actionText = undefined;
        this.dialog.actionButtonProps = undefined
        this.dialog.closeButtonProps = undefined
        this.dialog.reverseButton = false;
        this.dialog.content = undefined
    }

    executeActions = async () => {
        if (this.dialog.action) {
            this.isLoading = true;
            try {
                await this.dialog.action().then(() => runInAction(() => this.close()));
            } catch (error) {
                throw error;
            } finally {
                runInAction(() => this.isLoading = false);
            }
            
        }
    }
}