import { Close } from "@mui/icons-material";
import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { DraggableFileUploadData } from "../../../../app/component/input/DraggableFileUpload";
import { useEffect, useMemo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useStore } from "../../../../app/stores/store";
import useQuery from "../../../../app/hook/useQuery";
import { observer } from "mobx-react-lite";
import { IProposalAttachment } from "../../../../app/models/proposalAttachment";
import DraggableFileUploadV2 from "../../../../app/component/input/DraggableFileUploadV2";
import { FlagEdit } from "../../../../app/config/enum";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  readOnly: boolean;
};

function ProposalAttachmentForm({ isOpen, handleClose, readOnly }: Props) {
  const { addAttachment, generalInformation, getAttachment, attachment, editAttachment, deleteAttachment } = useStore().proposalStore;
  const query = useQuery();
  const id = query.get("id");

  const [loadingAttc, setLoadingAttc] = useState(false);
  const [files, setFiles] = useState<DraggableFileUploadData<IProposalAttachment>[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const isValid = useMemo(() => {
    return files.length > 0;
  }, [files]);

  const submitForm = async () => {
    if (readOnly) return;

    const id = query.get("id");

    if (!id) return;

    setIsLoading(true);

    try {
      const formData = new FormData();

      files.forEach((file: any, index) => {
        formData.append(`attachments[${index}].fileName`, "");
        formData.append(`attachments[${index}].attachmentFor`, file.attachmentFor);

        if (!!file.data?.id) {
          formData.append(`attachments[${index}].attachment`, file.data?.attachment!);
          file.data?.id && formData.append(`attachments[${index}].id`, file.data?.id);
          formData.append(`attachments[${index}].flagEdit`, String(FlagEdit.PROPOSAL));
        } else {
          formData.append(`attachments[${index}].attachment`, file.file as any);
        }

        // !!generalInformation &&
        //   formData.append(
        //     `attachments[${index}].attachmentFor`,
        //     generalInformation?.title
        //   );
      });

      if (!!attachment && !!attachment.length) {
        await editAttachment(id, formData);
      } else {
        await addAttachment(id, formData);
      }

      getAttachment(id);

      handleClose();
      setFiles([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!!attachment && !!attachment.length) {
      setFiles(() =>
        attachment.map((i) => ({
          file: { name: i.fileName, id: i.id },
          title: "",
          data: i,
          fileName: i.fileName,
          attachmentFor: i.attachmentFor,
        }))
      );
    }
  }, [attachment]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      sx={{
        ".MuiPaper-root": {
          width: "500px",
        },
      }}
    >
      <Box
        sx={{
          m: "16px 16px 0 16px",
          pb: "8px",
          borderBottom: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "space-between",
          mb: "16px",
        }}
      >
        <Typography fontSize={"14px"} fontWeight={700} color="black">
          Add Attachment
        </Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <Stack component="form" flex={1} overflow="auto" p="16px" spacing="24px">
        <DraggableFileUploadV2
          fields={[
            {
              fieldComponent: "TextField",
              name: "attachmentFor",
              label: "Attachment for",
            },
          ]}
          viewable
          viewableOptions={{ propsoalId: id ?? "" }}
          loading={loadingAttc}
          onDelete={(data) => {
            if (!id) return;

            setLoadingAttc(true);

            deleteAttachment(id, data?.id!)
              .then(() => getAttachment(id))
              .finally(() => setLoadingAttc(false));
          }}
          data={files}
          setData={setFiles as any}
        />
      </Stack>
      <Box
        sx={{
          m: "0 16px 16px 16px",
          pt: "16px",
          borderTop: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <LoadingButton variant="contained" loading={isLoading} disabled={!isValid || isLoading || readOnly} onClick={submitForm}>
          Add
        </LoadingButton>
      </Box>
    </Drawer>
  );
}

export default observer(ProposalAttachmentForm);
