import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material";
import { useField } from "formik";
import { observer } from "mobx-react-lite";

interface Props extends CheckboxProps{
    label: string
}

function RkdCheckbox(props: Props) {
    const {label, ...otherProps} = props;
    const [field] = useField(props.name!);
    return (
        <FormControlLabel control={
            <Checkbox {...field} {...otherProps} checked={field.value} />
        } label={label} />
    );
}

export default observer(RkdCheckbox);