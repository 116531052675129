import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IRegional, IRegionalBody } from "../models/regional";

export class RegionalStore {
  regional: IRegional[] = []
  regioanlLoading = false;
  
  selectedRegional: IRegional | null = null
  
  constructor() {
    makeAutoObservable(this)
  }

  getRegional = async () => {
    this.regioanlLoading = true
    try {
      const res = await agent.General.getRegional()

      runInAction(() => this.regional = res);

      return res
    } catch (e) {
      throw e
    } finally {
      runInAction(() => this.regioanlLoading = false)
    }
  }

  addRegional = async (body: IRegionalBody) => {
    try {
      await agent.General.addRegional(body)
    } catch (e) {
      throw e;
    }
  }

  editRegional = async (id: string, body: IRegionalBody) => {
    try {
      await agent.General.editRegional(id, body)
    } catch (e) {
      throw e;
    }
  }

  setSelectedRegional = (data: IRegional | null) => {
    this.selectedRegional = data;
  }

  deleteRegional = async (id: string) => {
    try {
      await agent.General.deleteRegional(id)
    } catch (e) {
      throw e;
    }
  }
}