import { Close, Delete, DescriptionOutlined, Edit } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, DialogContent, Drawer, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useStore } from "../../../../../app/stores/store";
import useQuery from "../../../../../app/hook/useQuery";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { observer } from "mobx-react-lite";
import { FlagEdit } from "../../../../../app/config/enum";
import CommentList from "../components/CommentList";
import { NumericFormat } from "react-number-format";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import EmptyState from "../../../../../app/component/EmptyState";
import { format } from "date-fns";
import Add from "@mui/icons-material/Add";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  readOnly: boolean;
};

type ListItemValue = {
  id?: string;
  budget: number;
  description: string;
  startDate: Date;
  endDate: Date;
};

type SelectedItemValue = ListItemValue & {
  index: number;
};

function ProposalInvestmentBudget({ isOpen, handleClose, readOnly }: Props) {

  const { snackbarStore, proposalStore } = useStore();
  const {
    investmentBudget,
    addInvestmentBudget,
    editInvestmentBudget,
    getInvestmentBudget,
    downloadTemplateRincianInvestasi,
    getGeneralInformation,
    uploadTemplateRincianInvestasi,
  } = proposalStore;
  const [isDeletedId, setIsDeletedId] = useState<string>();
  const [itemList, setItemList] = useState<ListItemValue[]>([]);
  const [selectedItem, setSelectedItem] = useState<SelectedItemValue>();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const hiddenFileInput = useRef<any>(null);
  const query = useQuery();
  const id = query.get("id");

  const handleDownloadTemplate = () => {
    if (!id) return;

    downloadTemplateRincianInvestasi(id);
  };

  const handleUploadTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!id) return;

    if (e?.target.files && e?.target.files.length > 0) {
      const formData = new FormData();

      formData.append("file", e?.target.files[0]);

      uploadTemplateRincianInvestasi(id, formData).then(() => {
        snackbarStore.show("success", `upload data success`);
        getInvestmentBudget(id);
      });
    }
  };

  const handleSubmit = async () => {
    if (readOnly) return;

    if (!id) return;

    setIsSubmitting(true);

    if (!!investmentBudget?.length) {
      await editInvestmentBudget(
        id,
        itemList.map((i) => ({
          ...i,
          flagEdit: FlagEdit.PROPOSAL,
        })) as any
      )
        .then(() => {
          handleClose();
          setItemList([]);
          getInvestmentBudget(id);
        })
        .finally(() => setIsSubmitting(false));
    } else {
      await addInvestmentBudget(
        id,
        itemList.map((i) => ({
          ...i,
          flagEdit: FlagEdit.PROPOSAL,
        })) as any
      )
        .then(() => {
          handleClose();
          setItemList([]);
          getInvestmentBudget(id);
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  useEffect(() => {
    const defaultValue: ListItemValue[] =
      investmentBudget?.map((i) => ({
        budget: i.budget,
        description: i.description,
        startDate: i.startDate,
        endDate: i.endDate,
        id: i.id,
      })) ?? [];

    setItemList(defaultValue);
  }, [investmentBudget]);

  const handleDeleteItem = async (timelineId: string) => {
    if (!id) return;

    setIsDeletedId(timelineId!);
    // await deleteInvestmentBudget(id, timelineId)
    // await getInvestmentBudget(id)
  };

  useEffect(() => {
    if (!id || isOpen) return

    getGeneralInformation(id)
  }, [isOpen])

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={handleClose}
        sx={{
          ".MuiPaper-root": {
            width: "500px",
          },
        }}
      >
        <Box
          sx={{
            m: "16px 16px 0 16px",
            pb: "8px",
            borderBottom: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "space-between",
            mb: "16px",
          }}
        >
          <Typography fontSize={"14px"} fontWeight={700} color="black">
            Rincian Nilai Investasi
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Stack component="form" flex={1} overflow="auto" p="16px" spacing="24px">
          {!!itemList.length ? (
            <>
              {itemList.map((item, index) => (
                <>
                  <AddedItem
                    budget={item.budget}
                    description={item.description}
                    onEdit={() => setSelectedItem({ ...item, index })}
                    onDelete={() => setIsDeletedId(item.id!)}
                    startDate={item.startDate}
                    endDate={item.endDate}
                  />
                </>
              ))}
              <Button onClick={() => setIsFormOpen(true)} startIcon={<Add />} color="inherit" variant="outlined">
                Add Rincian Nilai Investasi
              </Button>
            </>
          ) : (
            <Stack justifyContent="center" alignItems="center" spacing={3} flex={1}>
              <EmptyState />
              <Button variant="contained" onClick={() => setIsFormOpen(true)}>
                Add Rincian Nilai Investasi
              </Button>
            </Stack>
          )}
          {isOpen && investmentBudget ? <CommentList isOpen={isOpen} data={investmentBudget} /> : ""}
        </Stack>
        <Box
          sx={{
            m: "0 16px 16px 16px",
            pt: "16px",
            borderTop: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: "12px" }}>
            <LoadingButton onClick={handleDownloadTemplate} disabled={isSubmitting || readOnly} variant="contained" loading={isSubmitting}>
              Download
            </LoadingButton>
            <LoadingButton sx={{ whiteSpace: "nowrap" }} component="label" variant="contained">
              Upload
              <input
                ref={hiddenFileInput}
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                hidden
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUploadTemplate(e)}
              />
            </LoadingButton>
          </Box>
          <Box>
            <LoadingButton onClick={handleSubmit} disabled={isSubmitting || readOnly} variant="contained" loading={isSubmitting}>
              Save
            </LoadingButton>
          </Box>
        </Box>
      </Drawer>
      <BudgetForm
        setItemList={setItemList}
        readOnly={readOnly}
        selectedItem={selectedItem}
        isOpen={!!selectedItem || isFormOpen}
        onClose={() => {
          setSelectedItem(undefined);
          setIsFormOpen(false);
        }}
      />
      {isDeletedId && id && <DeleteConfirmation onClose={() => setIsDeletedId(undefined)} itemId={isDeletedId} proposalId={id} />}
    </>
  );
}

export default observer(ProposalInvestmentBudget);

const DeleteConfirmation = observer(({ proposalId, itemId, onClose }: { proposalId: string; itemId: string; onClose: () => void }) => {
  const { deleteInvestmentBudget, deleteInvestmentBudgetLoading, getInvestmentBudget } = useStore().proposalStore;

  return (
    <Dialog open={!!itemId} onClose={onClose}>
      <DialogContent sx={{ padding: "16px", width: "365px" }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight="700" fontSize="14px" color="black">
            Delete confirmation
          </Typography>
          <Close onClick={onClose} />
        </Stack>
        <Box mt={"16px"} display="flex" flexDirection="column" gap={3}>
          <Typography fontSize="14px">Are you sure want to remove this item</Typography>
        </Box>
        <Stack direction="row" justifyContent="flex-end" gap="12px" mt={"24px"}>
          <Button onClick={onClose} type="button" variant="outlined" color="inherit">
            Cancel
          </Button>
          <LoadingButton
            onClick={() =>
              deleteInvestmentBudget(proposalId, itemId).finally(() => {
                if (!proposalId) return;

                onClose();
                getInvestmentBudget(proposalId);
              })
            }
            loading={deleteInvestmentBudgetLoading}
            type="submit"
            variant="contained"
            color="error"
          >
            Remove
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
});

const AddedItem = ({
  description,
  budget,
  onEdit,
  onDelete,
  startDate,
  endDate,
  isDelete,
}: {
  description: string;
  budget: number;
  startDate: Date;
  endDate: Date;
  onEdit: () => any;
  onDelete: () => any;
  isDelete?: boolean;
}) => {
  return (
    <Box
      sx={{
        border: ({ palette }) => `1px solid ${palette.divider}`,
        padding: "16px",
        borderRadius: "4px",
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        pointerEvents: isDelete ? "none" : "auto",
        opacity: isDelete ? 0.8 : 1,
      }}
    >
      <Stack flex={1} direction="row" alignItems="center" spacing={"16px"}>
        <DescriptionOutlined sx={{ color: "black" }} />
        <Stack>
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "350px",
            }}
          >
            <Typography noWrap color="black" fontWeight={700} mb={"4px"}>
              {description.length > 30 ? `${description.slice(0, 30)}...` : description}
            </Typography>
          </Box>
          <Typography fontSize={"12px"}>Rp {budget.toLocaleString()}</Typography>
          <Typography fontSize="12px">
            {format(new Date(startDate), "dd MMM yyyy")} - {format(new Date(endDate), "dd MMM yyyy")}
          </Typography>
        </Stack>
      </Stack>
      <Box sx={{ display: "flex" }}>
        <IconButton size="small" onClick={onEdit} color="inherit">
          <Edit />
        </IconButton>
        {isDelete ? (
          <IconButton disabled>
            <CircularProgress color="error" size={18} />
          </IconButton>
        ) : (
          <IconButton size="small" onClick={onDelete} color="error">
            <Delete />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

const BudgetForm = ({
  isOpen = true,
  onClose,
  readOnly,
  selectedItem,
  setItemList,
}: {
  isOpen: boolean;
  onClose: () => any;
  readOnly: boolean;
  selectedItem?: SelectedItemValue;
  setItemList: Dispatch<SetStateAction<ListItemValue[]>>;
}) => {
  const { submitForm, handleChange, setFieldValue, values, errors, resetForm, setErrors } = useFormik({
    onSubmit: (v) => {
      if (selectedItem)
        setItemList((prev) =>
          prev.map((i, x) =>
            x === selectedItem.index
              ? {
                ...v,
                endDate: v.endDate ?? new Date(),
                startDate: v.startDate ?? new Date(),
              }
              : i
          )
        );
      else
        setItemList((prev) => [
          ...prev,
          {
            ...v,
            endDate: v.endDate ?? new Date(),
            startDate: v.startDate ?? new Date(),
          },
        ]);

      handleClose();
    },
    validationSchema: yup.object({
      id: yup.string(),
      description: yup.string().typeError("Description mush be string").required("Description must be filled"),
      startDate: yup.date().required(),
      endDate: yup.date().required(),
      budget: yup.number().typeError("Budget mush be string").positive("Bugdet must be positive value"),
    }),
    initialValues: selectedItem
      ? {
        description: selectedItem.description,
        startDate: selectedItem.startDate,
        endDate: selectedItem.endDate,
        budget: selectedItem.budget,
        id: selectedItem.id,
      }
      : {
        description: "",
        budget: 0,
      },
    enableReinitialize: true,
  });

  const handleClose = () => {
    onClose();
    resetForm();
    setErrors({});
  };

  useEffect(() => {
    setFieldValue("startDate", new Date());
    setFieldValue("endDate", new Date());
  }, [setFieldValue]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      sx={{
        ".MuiPaper-root": {
          width: "500px",
          p: 2,
        },
      }}
    >
      <Box
        sx={{
          pb: "8px",
          borderBottom: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "space-between",
          mb: "16px",
        }}
      >
        <Typography fontSize={"14px"} fontWeight={700} color="black">
          {!!selectedItem ? `Edit ${selectedItem?.description}` : "Add Rincian Nilai Investasi"}
        </Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <Stack flex={1} spacing={3}>
        <TextField
          label="Description"
          rows={5}
          multiline
          name={`description`}
          value={values.description}
          error={!!errors.description}
          helperText={errors.description}
          onChange={handleChange}
          disabled={readOnly}
          fullWidth
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack direction="row" spacing={2}>
            <DatePicker
              label="Start Date"
              value={values.startDate}
              disabled={readOnly}
              onChange={(e: any) => setFieldValue("startDate", e)}
              inputFormat="dd MMM yyyy"
              disableMaskedInput
              renderInput={(props) => (
                <TextField
                  required
                  fullWidth
                  name="startDate"
                  error={!!errors.startDate}
                  helperText={errors.startDate && String(errors.startDate)}
                  {...props}
                />
              )}
            />
            <DatePicker
              label="End Date"
              value={values.endDate}
              disabled={readOnly}
              inputFormat="dd MMM yyyy"
              onChange={(e: any) => setFieldValue("endDate", e)}
              disableMaskedInput
              renderInput={(props) => (
                <TextField
                  required
                  fullWidth
                  name="endDate"
                  error={!!errors.endDate}
                  helperText={errors.endDate && String(errors.endDate)}
                  {...props}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
        <NumericFormat
          customInput={TextField}
          label="Budget"
          fullWidth
          placeholder="Cost"
          value={values.budget}
          error={!!errors?.budget}
          helperText={errors?.budget}
          thousandSeparator={true}
          prefix={"Rp "}
          disabled={readOnly}
          onValueChange={(e) => setFieldValue(`budget`, e.floatValue)}
        />
      </Stack>
      <Box
        sx={{
          pt: "16px",
          borderTop: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={submitForm} variant="contained">
          Edit
        </Button>
      </Box>
    </Drawer>
  );
};
