import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { DataGridResult } from "../models/materialUI/dataGrid";
import { IInventoryCategory, IInventoryCategoryBody, IInventoryCategoryQueryParams } from "../models/inventoryCategory";

export class InventoryCategoryStore {
  inventoryCategoryGrid: DataGridResult<IInventoryCategory[]> = new DataGridResult([], 0)
  inventoryCategoryGridLoading = false;

  inventoryCategory: IInventoryCategory[] = []
  inventoryCategoryLoading = false;
  
  selectedInventoryCategory: IInventoryCategory | null = null
  
  queryparams: IInventoryCategoryQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: ''
  }
  
  constructor() {
    makeAutoObservable(this)

    reaction(() => this.queryparams, () => {
      this.getInventoryCategoryGrid()
    })
  }

  getInventoryCategoryGrid = async () => {
    this.inventoryCategoryGridLoading = true
    try {
      const res = await agent.Master.getInventoryCategoryGrid(this.queryparams)

      runInAction(() => this.inventoryCategoryGrid = res);
    } catch (e) {
      throw e
    } finally {
      runInAction(() => this.inventoryCategoryGridLoading = false)
    }
  }

  getInventoryCategory = async () => {
    this.inventoryCategoryLoading = true
    try {
      const res = await agent.Master.getInventoryCategory()

      runInAction(() => this.inventoryCategory = res);
    } catch (e) {
      throw e
    } finally {
      runInAction(() => this.inventoryCategoryLoading = false)
    }
  }
  
  addInventoryCategory = async (body: IInventoryCategoryBody) => {
    try {
      await agent.Master.addInventoryCategory(body)
    } catch (e) {
      throw e;
    }
  }

  editInventoryCategory = async (id: string, body: IInventoryCategoryBody) => {
    try {
      await agent.Master.editInventoryCategory(id, body)
    } catch (e) {
      throw e;
    }
  }

  setSelectedInventoryCategory = (data: IInventoryCategory | null) => {
    this.selectedInventoryCategory = data;
  }

  deleteInventoryCategory = async (id: string) => {
    try {
      await agent.Master.deleteInventoryCategory(id)
    } catch (e) {
      throw e;
    }
  }

  setQueryParams = (query: 'pageSize' | 'search' | 'currentPage', v: string | number) => {
    this.queryparams = {
      ...this.queryparams,
      [query]: v,
    }
  }
}