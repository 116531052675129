import { Box } from "@mui/material";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useStore } from "../../app/stores/store";

export type TBarChartGridProps = {
  name: string;
  labels: string[];
  series: number[];
  isCost?: boolean;
};

function DynamicBarChartGrid({ isCost, name, labels, series }: TBarChartGridProps) {
  const { dataPointIndexBarChart } = useStore().DashboardStore;
  const ref = useRef(null);

  useEffect(() => {
    const options = {
      series: [
        {
          name: name,
          data: series,
        },
      ],
      chart: {
        height: 350,
        width: "100%",
        type: "bar",
        toolbar: {
          export: {
            csv: {
              filename: `${dataPointIndexBarChart} Detail - ${format(new Date(), "dd MMM yyyy")}`,
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp: any) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: `${dataPointIndexBarChart} Detail - ${format(new Date(), "dd MMM yyyy")}`,
            },
            png: {
              filename: `${dataPointIndexBarChart} Detail - ${format(new Date(), "dd MMM yyyy")}`,
            },
          },
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top",
          },
          distributed: true,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        tickPlacement: "on",
        categories: labels,
        labels: {
          formatter: function (val: string) {
            return val;
          },
          style: {
            colors: [],
            fontSize: "8px",
            fontWeight: "bold",
            cssClass: "apexcharts-xaxis-label",
          },
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return isCost ? "Rp" + (val / 1000000).toLocaleString() : val;
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            return isCost ? "Rp" + (val / 1000000).toLocaleString() : val;
          },
        },
      },
    };

    const chart = new ApexCharts(ref.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [name, dataPointIndexBarChart, series, labels, isCost]);

  return (
    <Box sx={{ width: "100%" }} bgcolor="white" pt={3}>
      <div ref={ref} />
    </Box>
  );
}

export default observer(DynamicBarChartGrid);
