import { useStore } from "../../app/stores/store";
import { useEffect } from "react";

export default function AccessDenied() {
  const { commonStore } = useStore();
  useEffect(() => {
    commonStore.setTitlePage('Access Denied');
  });
  return (
      <h3>Oops - we've looked u does'nt have access to this page</h3>
  )
}