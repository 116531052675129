import { useEffect, useMemo } from "react"
import { StyledDataGrid } from "../../../../app/component/StyledDataGrid"
import { useStore } from "../../../../app/stores/store"
import useQuery from "../../../../app/hook/useQuery"
import { observer } from "mobx-react-lite"

export const HistoryCommentDataGrid = observer(() => {
  const { getComment, comment, commentLoading } = useStore().proposalReviewerStore
  const query = useQuery()
  const id = query.get('id')

  useEffect(() => {
    if (!id) return;

    getComment(id)
  }, [getComment, id])

  const displayedComment = useMemo(() => comment.map((i, x) => ({ ...i, no: x + 1 })), [comment])

  return (
    <StyledDataGrid
      rows={displayedComment}
      columns={[
        {
          field: 'no',
          headerName: 'No',
          width: 100
        },
        {
          field: 'displayName',
          headerName: 'Name',
          width: 250
        },
        {
          field: 'organizationName',
          headerName: 'Organization Name',
          width: 250
        },
        {
          field: 'comment',
          headerName: 'Comment',
          flex: 1,
          width: 250
        }
      ]}
      autoHeight
      loading={commentLoading}
    />
  )
})