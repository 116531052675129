import { Box } from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { Add } from "@mui/icons-material";
import OwnershipCategoryDataGrid from "./OwnershipCategoryDataGrid";
import OwnershipCategoryForm from "./OwnershipCategoryForm";
import { useState } from "react";

export default function OwnershipCategoryPageIndex() {
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false)
  
  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: 'Status Kepemilikan',
            href: '/master/ownership-category'
          },
          {
            label: 'Status Kepemilikan List',
            href: '/master/ownership-category'
          },
        ]}
        pageTitle="Status Kepemilikan List"
      >
        <DashboardLayoutHeader.Button 
          startIcon={<Add />}
          variant="contained"
          onClick={() => setIsOpenForm(true)}
        >
          Add Status Kepemilikan
        </DashboardLayoutHeader.Button>
      </DashboardLayoutHeader>
      <OwnershipCategoryDataGrid />
      <OwnershipCategoryForm isOpen={isOpenForm} onClose={() => setIsOpenForm(false)} />
    </Box>
  )
}