import { Box } from "@mui/material";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useStore } from "../../app/stores/store";

type TBarChartGridProps = {
  title?: string;
  series: number[];
  name: string;
  labels: string[];
  currently: number;
};

function BarChartProjectMilestones({ title, series, name, labels }: TBarChartGridProps) {
  const { setDataPointIndexBarChartMilestones, setIndexHelperBarChart } = useStore().DashboardStore;

  const ref = useRef(null);

  useEffect(() => {
    const options = {
      series: [
        {
          name: name,
          data: series,
        },
      ],
      chart: {
        height: 350,
        width: "100%",
        type: "bar",
        events: {
          dataPointSelection: function (event: any, chartContex: any, config: any) {
            setIndexHelperBarChart(config.dataPointIndex);
            setDataPointIndexBarChartMilestones(true);
          },
          xAxisLabelClick: function (event: any, chartContex: any, config: any) {
            setIndexHelperBarChart(config.labelIndex);
            setDataPointIndexBarChartMilestones(true);
          },
        },
        toolbar: {
          export: {
            csv: {
              filename: `${title} - ${format(new Date(), "dd MMM yyyy")}`,
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp: any) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: `${title} - ${format(new Date(), "dd MMM yyyy")}`,
            },
            png: {
              filename: `${title} - ${format(new Date(), "dd MMM yyyy")}`,
            },
          },
        },
      },
      colors: ["rgb(0, 143, 251)", "#8BCE4A"],
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top",
          },
          distributed: true,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        labels: {
          formatter: function (val: string) {
            return val.length > 25 ? `${val.substring(0, 24)}...` : val;
          },
          style: {
            colors: [],
            fontSize: "8px",
            fontWeight: "bold",
            cssClass: "apexcharts-xaxis-label",
          },
        },
        // tickPlacement: "on",
        categories: labels,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return `Rp${(val / 1000000).toLocaleString()}`;
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            return `Rp${(val / 1000000).toLocaleString()}`;
          },
        },
      },
    };

    const chart = new ApexCharts(ref.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [title, series, name, setDataPointIndexBarChartMilestones, labels]);

  return (
    <Box sx={{ width: "100%" }} bgcolor="white" pt={3}>
      <div ref={ref} />
    </Box>
  );
}

export default observer(BarChartProjectMilestones);
