import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { IUnitBusiness, IUnitBusinessBody, IUnitBusinessGrid, IUnitBusinessQueryParams } from "../models/unitBusiness";
import { DataGridResult } from "../models/materialUI/dataGrid";

export class UnitBusinessStore {
  unitBusinessGrid: DataGridResult<IUnitBusinessGrid[]> = new DataGridResult<IUnitBusinessGrid[]>([], 0)
  unitBusinessGridLoading = false
  queryParams: IUnitBusinessQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: '',
  }
  
  unitBusiness: IUnitBusiness[] = []
  unitBusinessLoading = false;
  
  selectedUnitBusiness: IUnitBusiness | null = null
  
  constructor() {
    makeAutoObservable(this)

    reaction(() => this.queryParams, () => {
      this.getUnitBusinessGrid()
    })
  }

  getUnitBusinessGrid = async () => {
    this.unitBusinessGridLoading = true
    
    try {
      const res = await agent.General.getUnitBusinessGrid(this.queryParams)

      runInAction(() => this.unitBusinessGrid = res)
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => this.unitBusinessGridLoading = false)
    }
  }
  
  getUnitBusiness = async (params?: {categoryUnitBusinessId: string}) => {
    this.unitBusinessLoading = true
    try {
      const res = await agent.General.getUnitBusiness(params)

      runInAction(() => this.unitBusiness = res);
    } catch (e) {
      throw e
    } finally {
      runInAction(() => this.unitBusinessLoading = false)
    }
  }

  addUnitBusiness = async (body: IUnitBusinessBody) => {
    try {
      await agent.General.addUnitBusiness(body)
    } catch (e) {
      throw e;
    }
  }

  editUnitBusiness = async (id: string, body: IUnitBusinessBody) => {
    try {
      await agent.General.editUnitBusiness(id, body)
    } catch (e) {
      throw e;
    }
  }

  setSelectedUnitBusiness = (data: IUnitBusiness | null) => {
    this.selectedUnitBusiness = data;
  }

  deleteUnitBusiness = async (id: string) => {
    try {
      await agent.General.deleteUnitBusiness(id)
    } catch (e) {
      throw e;
    }
  }

  setQueryParams = (query: 'pageSize' | 'search' | 'currentPage', v: string | number) => {
    this.queryParams = {
      ...this.queryParams,
      [query]: v,
    }
  }
}