import { useEffect, useState } from "react";
import { CircularProgress, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import useQuery from "../../app/hook/useQuery";
import agent from "../../app/api/agent";

function ViewFile() {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const proposalId = query.get("id");
  const fileName = query.get("fileName");

  const viewFile = async () => {
    try {
      setLoading(true);

      const res = await agent.General.getFile(proposalId ?? "", fileName ?? "");

      let prefix = "";

      if (res.fileExtension.toLowerCase().includes("png")) {
        prefix = "data:image/png;base64,";
      } else if (res.fileExtension.toLowerCase().includes("jpg")) {
        prefix = "data:image/jpg;base64,";
      } else if (res.fileExtension.toLowerCase().includes("jpeg")) {
        prefix = "data:image/jpeg;base64,";
      } else if (res.fileExtension.toLowerCase().includes("pdf")) {
        prefix = "data:application/pdf;base64,";
      }

      const url = prefix + res.bytes;

      const html = `
        <html style="margin:0; padding:0;">
          <body style="margin:0; padding:0;">
            <iframe src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%; backgroundColor:black;" allowfullscreen></iframe>
          </body>
        </html>
      `;

      window.document.write(html);
      window.document.close();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    viewFile();
  }, []);

  return (
    <Box sx={{ height: "100vh" }} display="flex" justifyContent="center" alignItems="center">
      {loading ? <CircularProgress /> : ""}
    </Box>
  );
}

export default observer(ViewFile);
