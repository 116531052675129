import { useState } from "react";
import { Add } from "@mui/icons-material";
import { Box } from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import OrganizationDataGrid from "./OrganizationDataGrid";
import OrganizationForm from "./OrganizationForm";

export default function OrganizationPageIndex() {
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);

  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Data Master",
            href: "/master/organizations",
          },
          {
            label: "Organizations List",
            href: "/master/organizations",
          },
        ]}
        pageTitle="Organizations List"
      >
        <DashboardLayoutHeader.Button startIcon={<Add />} variant="contained" onClick={() => setIsOpenForm(true)}>
          Add Organization
        </DashboardLayoutHeader.Button>
      </DashboardLayoutHeader>
      <OrganizationDataGrid />
      <OrganizationForm isOpen={isOpenForm} onClose={() => setIsOpenForm(false)} />
    </Box>
  );
}
