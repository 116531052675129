import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, IconButton, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../../../app/stores/store";
import ABIGrid from "./ABIGrid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { LockOpen, Lock } from "@mui/icons-material";
import useQuery from "../../../../../../../app/hook/useQuery";

function ABI() {
  const { dialogStore, abiStore } = useStore();
  const [totalAbi, setTotalAbi] = useState<number>(0);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const query = useQuery();
  const proposalId = query.get("id");

  const handleChangeYear = (year: string) => setYear(format(new Date(year), "yyyy"));

  useEffect(() => {
    if (!proposalId) return;

    abiStore.getTotalABI(proposalId, year).then((res) => setTotalAbi(res?.sumAbi ?? 0));
  }, [abiStore, proposalId, year]);

  return (
    <Box>
      {/* {gridLoading ? (
        <Box display="flex" justifyContent="center" py="32px">
          <CircularProgress />
        </Box>
      ) : ( */}
      <Box>
        <Typography sx={{ mt: "24px", mb: "24px" }} color="black" fontWeight={700} fontSize="20px">
          ABI - Rp{totalAbi.toLocaleString()}
        </Typography>
        <Grid container sx={{ mb: "12px" }}>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Year"
                value={year}
                views={["year"]}
                inputFormat="yyyy"
                onChange={(e: any) => handleChangeYear(e)}
                renderInput={(props) => <TextField fullWidth name="startYear" {...props} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={10} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <IconButton
              size="small"
              color="error"
              sx={{ flexShrink: 0 }}
              onClick={() => {
                dialogStore.open({
                  action: async () => {
                    if (!proposalId) return;

                    let payload = {
                      projectId: proposalId,
                      year: year,
                    };

                    abiStore.lockAbi(payload).then(() => abiStore.getAbiGrid(proposalId, Number(year)));
                  },
                  actionText: "Submit",
                  title: "Lock",
                  closeText: "Cancel",
                  actionButtonProps: {
                    color: "error",
                  },
                  description: `Are you sure want to lock ABI (${year}) in this project?`,
                });
              }}
            >
              <Lock />
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              sx={{ flexShrink: 0 }}
              onClick={() => {
                dialogStore.open({
                  action: async () => {
                    if (!proposalId) return;
                    let payload = {
                      projectId: proposalId,
                      year: year,
                    };

                    abiStore.unLockAbi(payload).then(() => abiStore.getAbiGrid(proposalId, Number(year)));
                  },
                  actionText: "Submit",
                  title: "Unlock",
                  closeText: "Cancel",
                  actionButtonProps: {
                    color: "error",
                  },
                  description: `Are you sure want to unlock ABI (${year}) in this project?`,
                });
              }}
            >
              <LockOpen />
            </IconButton>
          </Grid>
        </Grid>
        <ABIGrid year={year} />
      </Box>
      {/* )} */}
    </Box>
  );
}

export default observer(ABI);
