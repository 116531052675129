import { Button, Dialog, DialogContent, FormHelperText, Stack, TextField, TextareaAutosize, Typography } from "@mui/material";
import DraggableFileUpload, { DraggableFileUploadData } from "../../../../app/component/input/DraggableFileUpload";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import useQuery from "../../../../app/hook/useQuery";
import { history } from "../../../..";
import { ProjectStatus } from "../../../../app/config/enum";
import { useParams } from "react-router-dom";

function ApproveProposalForm({ isOpen, onClose }: { onClose: () => any; isOpen: boolean }) {
  const [data, setData] = useState<DraggableFileUploadData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [summaryReview, setSummaryReview] = useState<string>("");

  const { reviewerApproveRefuseProposal, decisionGateApproveRefuseProposal } = useStore().projectManagementStore;
  const { id } = useParams();
  const query = useQuery();
  const status = query.get("status");

  const onSubmit = async () => {
    if (!id) return;

    setIsLoading(true);

    try {
      const formData = new FormData();

      data.forEach((item, index) => {
        formData.append(`attachments[${index}].file`, item.file as File);
        formData.append(`attachments[${index}].fileName`, "fileName");
      });
      formData.append("notes", summaryReview);

      if (status === ProjectStatus.Reviewer) {
        formData.append("status", "SUBMITTED");

        await reviewerApproveRefuseProposal(id, formData);
      } else if (status === ProjectStatus.DecisionGate) {
        formData.append("status", "APPROVED");

        await decisionGateApproveRefuseProposal(id, formData);
      }

      handleClose();

      history.push("/project-monitoring/approval/list");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setData([]);
    // setError('')
    setIsLoading(false);

    onClose();
  };

  const handleSummaryReviewChange = (e: any) => {
    setSummaryReview(e.target.value);
  };

  // useEffect(() => {
  //   if (data.length > 0) {
  //     setError('')
  //   }
  // }, [data])

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent sx={{ width: "365px" }}>
        <Typography color="black" fontWeight={700} fontSize="14px">
          Info
        </Typography>
        <Typography mb="16px" color="black" mt="24px">
          Add document & summary review to approve usulan investasi
        </Typography>
        <TextField
          onChange={(e) => handleSummaryReviewChange(e)}
          sx={{ mb: "12px" }}
          fullWidth
          label="Summary Review"
          name="notes"
          multiline
          rows={5}
        />
        <DraggableFileUpload data={data} setData={setData} editableTitle={false} accept="application/pdf, image/*" />
        <Stack mt={2} justifyContent="flex-end" direction="row" gap={1}>
          <Button onClick={handleClose} color="inherit" variant="outlined">
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} loading={isLoading} variant="contained" onClick={onSubmit}>
            Approve
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default observer(ApproveProposalForm);
