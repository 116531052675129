import { DeleteOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import ViewAttachment from "../ViewAttachment";

export type DraggableFileUploadDataV3<T = any> = {
  title?: string;
  file: File | { name: string; id?: string };
  data?: T;
};

interface DraggableFileUploadPropsV3<T> {
  data: DraggableFileUploadDataV3<T>[];
  setData: React.Dispatch<React.SetStateAction<DraggableFileUploadDataV3<T>[]>>;
  accept?: string;
  /**
   * @default true
   */
  multiple?: boolean;
  fields?: (
    file: DraggableFileUploadDataV3<T>
  ) => UploadedItemPropsV3<T>["fields"];
  onDelete?: (data: DraggableFileUploadDataV3<T>["data"]) => any;
  loading?: boolean;
  /**
   * @default true
   */
  viewable?: boolean;
  viewableOptions?: {
    propsoalId?: string;
  };
  error?: string;
}

function isDuplicatedFileName(
  files: any[],
  targetFilenames: string[]
): boolean {
  return !!files.filter((file) => targetFilenames.includes(file.file.name))
    .length;
}

export default function DraggableFileUploadV3<T = any>({
  data,
  loading,
  fields,
  setData,
  accept,
  viewable,
  viewableOptions,
  onDelete,
  error,
  multiple = true,
}: DraggableFileUploadPropsV3<T>) {
  const dropArea = useRef<HTMLDivElement>(null);
  const [isDraggingOverElement, setIsDraggingOverElement] = useState(false);

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDraggingOverElement(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDraggingOverElement(false);
  };

  const handleDrop = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      setIsDraggingOverElement(false);

      const dt = e.dataTransfer;
      const files = dt.files;

      Array.from(files).map((fl) => {
        setData((prev) => [...prev, { ...(fl as any), file: fl, title: "" }]);
      });
    },
    [setData]
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const duplicated = isDuplicatedFileName(
        data,
        Array.from(files).map((i) => i.name)
      );

      if (duplicated) {
        return alert(`Filename can't be identical`);
      }

      Array.from(files).map((fl) => {
        setData((prev) => [...prev, { ...fl, file: fl, title: "" }]);
      });
    }
  };

  const handleDelete = (
    file: DraggableFileUploadDataV3<T>["data"],
    index: number
  ) => {
    if (file) {
      onDelete && onDelete(file);
    } else {
      setData((prev) => prev.filter((i, ix) => ix !== index));
    }
  };

  useEffect(() => {
    dropArea.current?.addEventListener("dragover", handleDragOver);
    dropArea.current?.addEventListener("dragleave", handleDragLeave);
    dropArea.current?.addEventListener("drop", handleDrop);

    return () => {
      dropArea.current?.removeEventListener("dragover", handleDragOver);
      dropArea.current?.removeEventListener("dragleave", handleDragLeave);
      dropArea.current?.removeEventListener("drop", handleDrop);
    };
  }, [handleDrop]);

  return (
    <Stack>
      <Stack gap={"16px"} position="relative">
        {loading ? (
          <Stack spacing={2}>
            <ItemSkeleton />
            <PanelSkeleton />
          </Stack>
        ) : (
          data &&
          data.map((value, fileX) => (
            <UploadedItem<T>
              viewable={viewable}
              proposalId={viewableOptions?.propsoalId}
              data={data}
              key={fileX}
              url={(value.data as any)?.url || (value.data as any)?.attachment}
              value={value.file.name}
              filename={value.file.name}
              onDelete={() => handleDelete(value.data, fileX)}
              index={fileX}
              setData={setData}
              fields={fields && fields(value)}
            />
          ))
        )}
        {!loading && (
          <FormControl
            ref={dropArea}
            disabled={!multiple && !!data.length}
            sx={{
              border: ({ palette }) => "1px solid " + palette.grey[900],
              borderColor: ({ palette }) =>
                error
                  ? palette.error.main
                  : !multiple && !!data.length
                  ? palette.grey[200]
                  : isDraggingOverElement
                  ? palette.grey[900]
                  : palette.grey[400],
              backgroundColor: ({ palette }) =>
                isDraggingOverElement ? palette.grey[100] : "transparent",
              borderRadius: "3px",
              p: "16px",
            }}
            fullWidth
          >
            <Stack gap="16px" sx={{ position: "relative" }}>
              <Typography
                textAlign="center"
                color={!multiple && !!data.length ? "GrayText" : "black"}
                fontWeight={700}
              >
                Drag Files Here
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                gap={"10px"}
                justifyContent="center"
              >
                <Divider
                  sx={{
                    width: "20px",
                    borderWidth: "1px",
                    borderColor: "#999999",
                  }}
                />
                <Typography color="#999999">or</Typography>
                <Divider
                  sx={{
                    width: "20px",
                    borderWidth: "1px",
                    borderColor: "#999999",
                  }}
                />
              </Stack>
              <Button
                htmlFor="draggableFileUpload"
                component="label"
                color="inherit"
                variant="outlined"
                disabled={!multiple && !!data.length}
                sx={{
                  width: "fit-content",
                  mx: "auto",
                  textTransform: "capitalize",
                }}
              >
                Choose File
              </Button>
            </Stack>
          </FormControl>
        )}
        <input
          multiple={multiple}
          id="draggableFileUpload"
          type="file"
          hidden
          onChange={handleChange}
          accept={accept}
        />
      </Stack>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Stack>
  );
}


interface UploadedItemPropsV3<T> {
  filename: string;
  onDelete: () => any;
  value: string;
  data: DraggableFileUploadDataV3<T>[];
  fields?: ({ fieldComponent: "TextField"; name: string } & TextFieldProps)[];
  setData: React.Dispatch<React.SetStateAction<DraggableFileUploadDataV3<T>[]>>;
  index: number;
  proposalId?: string;
  viewable?: boolean;
  url?: string;
}

function UploadedItem<T>({
  filename,
  url,
  index,
  data,
  onDelete,
  setData,
  fields,
  proposalId,
  viewable,
}: UploadedItemPropsV3<T>) {
  
  return (
    <Box
      sx={{
        border: ({ palette }) => `1px solid ${palette.grey[400]}`,
        padding: "16px",
        borderRadius: "3px",
      }}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        {viewable && proposalId && url ? (
          <ViewAttachment
            loadingComponent={<Skeleton width={150} variant="text" />}
            url={url}
            proposalId={proposalId}
          >
            <Typography fontWeight={700} color="black">
              {filename}
            </Typography>
          </ViewAttachment>
        ) : (
          <Typography fontWeight={700} color="black">
            {filename}
          </Typography>
        )}
        <IconButton onClick={onDelete} color="error" size="small">
          <DeleteOutline />
        </IconButton>
      </Stack>
      {fields && (
        <Stack mt={2} direction="column" gap={2}>
          {fields.map((field) => {
            if (field.fieldComponent === "TextField") {
              return (
                <TextField
                  value={(data as any)[index][field.name]}
                  onChange={(e) =>
                    setData((prev) =>
                      prev.map((p, px) =>
                        px === index
                          ? {
                              ...p,
                              file: p.file,
                              [e.target.name]: e.target.value,
                            }
                          : p
                      )
                    )
                  }
                  {...field}
                />
              );
            }
          })}
        </Stack>
      )}
    </Box>
  );
}

const ItemSkeleton = () => {
  return (
    <Box
      sx={{
        border: ({ palette }) => `1px solid ${palette.grey[400]}`,
        padding: "16px",
        borderRadius: "3px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Skeleton height={30} variant="text" width="75%" />
        <Skeleton variant="circular" width="40px" height="40px" />
      </Box>
    </Box>
  );
};

const PanelSkeleton = () => {
  return (
    <Box
      sx={{
        border: ({ palette }) => `1px solid ${palette.grey[400]}`,
        padding: "16px",
        borderRadius: "3px",
      }}
    >
      <Skeleton
        sx={{ mx: "auto", mb: "10px" }}
        variant="text"
        height={30}
        width="40%"
      />
      <Skeleton
        sx={{ mx: "auto", mb: "2px" }}
        variant="text"
        height={15}
        width="20%"
      />
      <Skeleton
        sx={{ mx: "auto" }}
        variant="text"
        height={60}
        width={"120px"}
      />
    </Box>
  );
};
