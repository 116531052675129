import { Box, CircularProgress, Collapse, Typography } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../../app/stores/store";
import ProgressConsolidation from "./ProgressConsolidation";
import useQuery from "../../../../../../app/hook/useQuery";
import CostConsolidation from "./CostConsolidation";

function Consolidation() {
  const { getCostConsolidation, getConsolidation, consolidationLoading, costConsolidationLoading } = useStore().consolidationStore;
  const [isOpen, setIsOpen] = useState<string>();
  const query = useQuery();
  const id = query.get("id");

  const consolidationList = [
    {
      title: "Progress Consolidation",
      proposal: <ProgressConsolidation />,
    },
    {
      title: "Cost Consolidation",
      proposal: <CostConsolidation />,
    },
  ];

  useEffect(() => {
    if (!id) return;

    getConsolidation(id);
    getCostConsolidation(id);
  }, [getConsolidation, getCostConsolidation, id]);

  return (
    <>
      {consolidationLoading || costConsolidationLoading ? (
        <Box display="flex" justifyContent="center" py="32px">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography sx={{ mt: "24px", mb: "24px" }} color="black" fontWeight={700} fontSize="20px">
            Progress & Cost Consolidation
          </Typography>

          {consolidationList.map((item) => (
            <Box
              sx={{
                p: "16px",
                cursor: "pointer",
                borderRadius: "4px",
                border: "1px solid #DEDEDE",
                background: "#FFF",
                mb: "12px",
              }}
            >
              <Box
                onClick={() => setIsOpen((prev) => (prev === item.title ? undefined : item.title))}
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <Box>
                  <Box sx={{ fontWeight: "bold", color: "#212121" }}>{item.title}</Box>
                  <Box sx={{ fontSize: "12px" }}>Click to see detail</Box>
                </Box>
                <Box>{isOpen === item.title ? <ExpandLess /> : <ExpandMore />}</Box>
              </Box>
              <Collapse sx={{ mt: "24px" }} in={isOpen === item.title} timeout="auto" unmountOnExit>
                {item.proposal}
              </Collapse>
            </Box>
          ))}
        </>
      )}
    </>
  );
}

export default observer(Consolidation);
