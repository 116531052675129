import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { GridValueGetterParams } from "@mui/x-data-grid";
import { Button, Grid, MenuItem, Paper, Select, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ILandDelete } from "../../app/models/land";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import LandActionButton from "./LandActionButton";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import ErrorBoundary from "../../app/component/error/ErrorBoundary";
import LandForm from "./LandForm";
import { StyledDataGrid } from "../../app/component/StyledDataGrid";
import { Roles } from "../../app/config/enum";

function LandIndexPage() {
  const { landStore, dialogStore, accountStore } = useStore();
  const { getLandType, getLandStatus } = landStore;
  const [selectionDelete, setSelectionDelete] = useState<ILandDelete[]>([]);
  const [isAddingLand, setIsAddingLand] = useState(false);

  const handleDelete = (data: any) => {
    setSelectionDelete([]);
    data.map((item: string) => {
      const x: ILandDelete = { id: item };
      return setSelectionDelete((prevArr) => [...prevArr, x]);
    });
  };

  const handleSelectedDelete = () =>
    dialogStore.open({
      title: "Delete",
      description: "Are you sure want to remove this data?",
      closeText: "Cancel",
      actionText: "Delete",
      action: async () => landStore.deleteLand(selectionDelete),
      actionButtonProps: {
        color: "error",
      },
    });

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      landStore.setSearch(searchTerm);
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [landStore, searchTerm]);

  const columns = useMemo(
    () => [
      { field: "noAsset", headerName: "No Asset", width: 200 },
      { field: "address", headerName: "Address", width: 340, editable: false },
      { field: "landArea", headerName: "Luas Lahan", width: 150, editable: false },
      { field: "location", headerName: "Location", width: 110, editable: false },
      {
        field: "typeName",
        headerName: "Tipe Lahan",
        sortable: false,
        width: 120,
      },
      {
        field: "statusName",
        headerName: "Status Lahan",
        width: 180,
        editable: false,
      },
      {
        field: "action",
        headerName: "",
        width: 50,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridValueGetterParams) => {
          return <LandActionButton data={params.row} />;
        },
      },
    ],
    []
  );

  useEffect(() => {
    landStore.getLand();
  }, [landStore, landStore.search, landStore.status, landStore.type, landStore.currentPage, landStore.pageSize]);

  useEffect(() => {
    getLandType();
    getLandStatus();
  }, [getLandType, getLandStatus]);

  return (
    <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Profile Lahan",
            href: "/land/list",
          },
          {
            label: "Profile Lahan List",
          },
        ]}
        pageTitle="Profile Lahan"
      >
        <DashboardLayoutHeader.Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ mb: 2 }}
          onClick={() => setIsAddingLand(true)}
          disabled={accountStore.account?.roles.includes(Roles.General)}
        >
          Add Profile Lahan
        </DashboardLayoutHeader.Button>
      </DashboardLayoutHeader>

      <Paper sx={{ p: "16px" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth sx={{ minWidth: 200 }}>
              <InputLabel id="demo-simple-select-label">Land Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={landStore.type}
                label="Type"
                onChange={(e) => landStore.setType(e.target.value)}
              >
                <MenuItem value={"all"}>All</MenuItem>
                {landStore.landType.map((item, idx) => (
                  <MenuItem value={item.id} key={idx}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Land Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={landStore.status}
                label="Status"
                onChange={(e) => landStore.setStatus(e.target.value)}
              >
                <MenuItem value={"all"}>All</MenuItem>
                {landStore.landStatus.map((item, idx) => (
                  <MenuItem value={item.id} key={idx}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ width: "100%", justifyContent: "end", display: "flex", my: 2 }}>
          {selectionDelete.length > 0 ? (
            <Button
              variant="outlined"
              color="error"
              endIcon={<DeleteOutlineOutlinedIcon />}
              onClick={() => handleSelectedDelete()}
              disabled={landStore.loadingDelete}
            >
              Delete
            </Button>
          ) : (
            <></>
          )}
        </Box>
        <StyledDataGrid
          rows={landStore.land}
          columns={columns}
          pageSize={landStore.pageSize}
          getRowId={(row) => row.id}
          rowsPerPageOptions={[5, 10, 20]}
          loading={landStore.loadingList}
          rowCount={landStore.rowCount}
          page={landStore.currentPage}
          paginationMode="server"
          disableSelectionOnClick
          autoHeight
          onPageChange={(newPage) => landStore.setCurrentPage(newPage)}
          onPageSizeChange={(pageSize) => landStore.setPageSize(pageSize)}
          checkboxSelection
          isRowSelectable={(params) => {
            return !params.row.isDeleted;
          }}
          getRowClassName={(params) => (params.row.isDeleted ? "StyledDataGrid-IsDeleted-Row" : "")}
          onSelectionModelChange={(ids) => {
            handleDelete(ids);
          }}
        />
      </Paper>
      <ErrorBoundary>
        <LandForm
          refetchDetail
          isOpen={isAddingLand || !!landStore.selectedLand}
          onClose={() => {
            setIsAddingLand(false);
            landStore.setSelectedLand(null);
          }}
        />
      </ErrorBoundary>
    </Box>
  );
}

export default observer(LandIndexPage);
