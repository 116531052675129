import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../api/agent";
import { Roles } from "../config/enum";
import { IUser, IUserOptions, IUserPhoto } from "../models/user";
import DataGridStore from "./materialUI/dataGridStore";

export default class UserStore extends DataGridStore {
    loadingForm = false;
    loadingDelete = false;
    loadingRole = false;

    userOptions: IUserOptions[] = []
    userOptionsLoading = false
    
    constructor() {
        super();

        makeObservable(this, {
            loadingForm: observable,
            loadingDelete: observable,
            loadingRole: observable,
            getUserList: action,
            getDetail: action,
            createUser: action,
            editUser: action,
            deleteUser: action,
            addRole: action,
            removeRole: action,
        })
    }

    updateGridCallback = action(() => {
        this.getUserList();
    })
    
    getUserList = async () => {
        this.loadingGrid = true;
        try {
            const urlParams = this.createDataGridParam();
            const result = await agent.User.list(urlParams);
            this.setDataGridResult(result);
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingGrid = false);
        }
    }

    getUserOptions = async (params?: { search: string }) => {
        this.userOptionsLoading = true
        try {
            const res = await agent.User.getOptions(params)

            this.userOptions = res;
        } catch (e) {
            throw e;
        } finally {
            runInAction(() => this.userOptionsLoading = false)
        }
    }

    getDetail = async (username: string) => {
        this.loadingForm = true;
        try {
            const result = await agent.User.detail(username);
            return result;
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingForm = false);
        }
    }

    createUser = async (user: IUser) => {
        try {
            await agent.User.create(user);
        } catch (error) {
            throw error;
        }
    }

    editUser = async (user: IUser) => {
        try {
            await agent.User.edit(user);
        } catch (error) {
            throw error;
        }
    }

    deleteUser = async (username: string) => {
        this.loadingDelete = true;
        try {
            await agent.User.delete(username);
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingDelete = false);
        }
    }

    uploadPhoto = async(data: IUserPhoto) => {
        try {
            await agent.User.upload(data)
        } catch (error) {
            throw error;
        }
    }

    addRole = async (username: string, role: Roles) => {
        this.loadingRole = true;
        try {
            await agent.User.addRole(username, role);
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingRole = false);
        }
    }

    removeRole = async (username: string, role: Roles) => {
        this.loadingRole = true;
        try {
            await agent.User.removeRole(username, role);
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingRole = false);
        }
    }

    resetPassword = async (username: string) => {
        this.loadingRole = true;
        try {
            await agent.User.resetPass(username);
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => this.loadingRole = false);
        }
    }
}