import { Box, Paper, Skeleton, Stack, TextField, Typography } from "@mui/material";
import useQuery from "../../app/hook/useQuery";
import ProposalReviewerTab from "../projectMonitoring/proposal/tabs/ProposalReviewerTab";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { ReviewerStatus } from "../../app/config/enum";

function ApprovalInReviewPage() {
  const { reviewer, reviewerLoading } = useStore().proposalReviewerStore
  
  const query = useQuery()
  const title = query.get('title')
  
  return (
    <Box>
      <Paper sx={{ padding: '16px' }}>
        <Box>
          <Typography mb={1} color="black" fontWeight={700} fontSize="20px">Title</Typography>
          <TextField 
            fullWidth 
            placeholder="Title" 
            value={title} 
            disabled
          />
        </Box>
        <Box mt={2}>
          <Stack mb={2} direction="row" justifyContent="space-between">
            <Box>
              <Typography color="black" fontWeight={700} fontSize="20px">Approval</Typography>
              {reviewerLoading 
                ? <Skeleton variant="text" width={150} />
                : <Typography>{reviewer.length} approvals are required to proceed to the next step.</Typography>
              }
            </Box>
            <Box>
              {reviewerLoading 
                ? <Skeleton variant="text" height={20} width={50} />
                : <Typography textAlign="right" color="black" fontWeight={700} fontSize="20px">{reviewer.filter(i => i.status === ReviewerStatus.Submitted || i.status === ReviewerStatus.Approved).length}/{reviewer.length}</Typography>
              }
              <Typography textAlign="right">approved</Typography>
            </Box>
          </Stack>
          <ProposalReviewerTab />
        </Box>
      </Paper>
    </Box>
  )
}

export default observer(ApprovalInReviewPage)