import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { TDownloadTemplateProjectAdminPayload, TUploadTemplateResponse } from "../models/uploadProjectAdmin";

export class UploadPlanProjectStore {
  loadingUploadProjectAdmin = false;
  projectAdminPreviewGrid: TUploadTemplateResponse | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  downloadTemplateUploadProjectAdmin = async (payload: TDownloadTemplateProjectAdminPayload) => {
    this.loadingUploadProjectAdmin = true;

    try {
      await agent.UploadProjectAdmin.getTemplateUploadProjectAdmin(payload);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.loadingUploadProjectAdmin = false));
    }
  };

  uploadTemplateUploadProjectAdmin = async (payload: FormData) => {
    this.loadingUploadProjectAdmin = true;

    try {
      let result = await agent.UploadProjectAdmin.uploadTemplateUploadProjectAdmin(payload);

      runInAction(() => (this.projectAdminPreviewGrid = result));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.loadingUploadProjectAdmin = false));
    }
  };

  submitTemplateUploadProjectAdmin = async (payload: any) => {
    this.loadingUploadProjectAdmin = true;

    try {
      await agent.UploadProjectAdmin.submitTemplateUploadProjectAdmin(payload);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.loadingUploadProjectAdmin = false));
    }
  };

  setProjectAdminPreviewGrid = (data: TUploadTemplateResponse | undefined) => {
    this.projectAdminPreviewGrid = data;
  };
}
