import { makeAutoObservable, reaction, runInAction, observable, action } from "mobx";
import { TOrganization, TOrganizationQueryParams, TSelectedOrganization } from "../models/organization";
import { DataGridResult } from "../models/materialUI/dataGrid";
import agent from "../api/agent";

export class OrganizationStore {
  organizationGrid: DataGridResult<TOrganization[]> = new DataGridResult<TOrganization[]>([], 0);
  organizationGridLoading = false;

  reviewerByOrganization: TOrganization[] | [] = [];
  reviewerByOrganizationLoading = false;

  organizationNestedLoading = false;
  organizationNested: any[] = [];

  loadingForm = false;
  queryParams: TOrganizationQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: "",
  };

  organization: TOrganization[] = [];
  unitBusinessLoading = false;

  selectedOrganization: TOrganization | null = null;

  constructor() {
    makeAutoObservable(this, {
      loadingForm: observable,
      getOrganizationList: action,
    });

    reaction(
      () => this.queryParams,
      () => {
        this.getOrganizationGrid();
      }
    );
  }

  getOrganizationGrid = async () => {
    this.organizationGridLoading = true;

    try {
      const res = await agent.Master.getOrganizationGrid(this.queryParams);

      runInAction(() => (this.organizationGrid = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.organizationGridLoading = false));
    }
  };

  getReviewerByOrganization = async (organizationId: string) => {
    this.reviewerByOrganizationLoading = true;

    try {
      const res = await agent.Master.getReviewerByOrganization(organizationId);

      runInAction(() => (this.reviewerByOrganization = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.reviewerByOrganizationLoading = false));
    }
  };

  getOrganization = async () => {
    this.unitBusinessLoading = true;
    try {
      const res = await agent.Master.getOrganizations();

      runInAction(() => (this.organization = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.unitBusinessLoading = false));
    }
  };

  addOrganization = async (body: TSelectedOrganization) => {
    try {
      await agent.Master.addOrganization(body);
    } catch (e) {
      throw e;
    }
  };

  editOrganization = async (id: string, body: TSelectedOrganization) => {
    try {
      await agent.Master.editOrganization(id, body);
    } catch (e) {
      throw e;
    }
  };

  setSelectedOrganization = (data: TOrganization | null) => {
    this.selectedOrganization = data;
  };

  deleteOrganization = async (id: string) => {
    try {
      await agent.Master.deleteOrganization(id);
    } catch (e) {
      throw e;
    }
  };

  setQueryParams = (query: "pageSize" | "search" | "currentPage", v: string | number) => {
    this.queryParams = {
      ...this.queryParams,
      [query]: v,
    };
  };

  getOrganizationsByProposalId = async (proposalId: string) => {
    this.organizationNestedLoading = true;

    try {
      const organization = await agent.Organization.getOrganizationsByProposalId(proposalId);

      runInAction(() => (this.organizationNested = organization));

      return organization;
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => (this.organizationNestedLoading = false));
    }
  };

  getOrganizationList = async (level?: string) => {
    this.organizationNestedLoading = true;

    try {
      const organization = await agent.Organization.list(level);

      runInAction(() => (this.organizationNested = organization));

      return organization;
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => (this.organizationNestedLoading = false));
    }
  };
}
