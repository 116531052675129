import { observer } from "mobx-react-lite";
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { useStore } from "../../stores/store";

function RkdSnackbar() {
    const { snackbarStore: {isShow, severity, message, handleHide} } = useStore();
    return (
        <Snackbar open={isShow} autoHideDuration={3000} onClose={handleHide} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
            <Alert severity={severity} variant="filled" sx={{ color:'#ffffff' }}>{message}</Alert>
        </Snackbar>
    );
}

export default observer(RkdSnackbar);