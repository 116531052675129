import { Close } from "@mui/icons-material";
import { Box, Drawer, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useStore } from "../../../../app/stores/store";
import useQuery from "../../../../app/hook/useQuery";
import { LoadingButton } from "@mui/lab";
import { Fragment, useEffect, useMemo, useState } from "react";
import { FlagEdit } from "../../../../app/config/enum";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import DraggableFileUploadV2, { DraggableFileUploadData } from "../../../../app/component/input/DraggableFileUploadV2";
import CommentList from "./components/CommentList";
import { NumericFormat } from "react-number-format";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  readOnly: boolean;
};

const validationSchema = yup.object({
  items: yup.array().of(
    yup.object({
      npv: yup.number(),
      irr: yup.number(),
      hurdleRate: yup.number(),
      paybackPeriod: yup.number(),
      profitablityIndex: yup.number(),
      pertaminaSizePercentage: yup.number().max(100, "porsi partajasa must be less than or equal to 100"),
      pertaminaSizeIDR: yup.number(),
      pertaminaSizeUSD: yup.number(),
      investationValueIDR: yup.number(),
      sensitivity: yup.string(),
      kursUSD: yup.number(),
      // investationValueUSD: yup.number(),
      // valueExchange: yup.number(),
      // contractValueUSD: yup.number(),
    })
  ),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function ProposalEconomyAnalysisForm({ isOpen, handleClose, readOnly }: Props) {
  const { proposalStore, dialogStore } = useStore();
  const [loadingAttc, setLoadingAttc] = useState(false);
  const [files, setFiles] = useState<DraggableFileUploadData[]>([]);
  const { addEconomyAnalysis, economyAnalysis, getEconomyAnalysis, editEconomyAnalysis, deleteAttachment, generalInformation } = proposalStore;
  const query = useQuery();
  const id = query.get("id");

  const initialValues: any = useMemo(() => {
    if (economyAnalysis && economyAnalysis.length > 0) {
      return {
        items: economyAnalysis,
      };
    } else {
      return {
        items: [
          {
            npv: 0,
            irr: 0,
            hurdleRate: 0,
            paybackPeriod: 0,
            profitablityIndex: 0,
            pertaminaSizePercentage: 0,
            pertaminaSizeIDR: 0,
            pertaminaSizeUSD: 0,
            investationValueIDR: generalInformation?.cost ?? 0,
            sensitivity: "",
            kursUSD: 0,
            // investationValueUSD: 0,
            // valueExchange: 0,
            // contractValueUSD: 0
          },
        ],
      };
    }
  }, [economyAnalysis, generalInformation]);

  const onSubmit = async (values: ValidationSchema) => {
    if (readOnly) return;

    if (generalInformation?.cost === 0) {
      dialogStore.open({
        title: "Info",
        closeText: "Close",
        description: "Cost pada Analisis Costplan harus diisi terlebih dahulu",
      });
      return;
    }

    const id = query.get("id");

    if (!id) return;

    const arrayOfFileName = files.map((i) => (i.data ? i.data.fileName : i.file.name));
    const isDuplicateFileName = arrayOfFileName.some((item, index) => arrayOfFileName.indexOf(item) !== index);
    if (isDuplicateFileName) {
      return alert("Attachment name is duplicate");
    }

    const formData = new FormData();

    values.items?.forEach((item, index) => {
      item.npv && formData.append(`economyAnalyses[${index}].npv`, item.npv.toString());
      item.irr && formData.append(`economyAnalyses[${index}].irr`, item.irr.toString());
      item.hurdleRate && formData.append(`economyAnalyses[${index}].hurdleRate`, item.hurdleRate.toString());
      item.paybackPeriod && formData.append(`economyAnalyses[${index}].paybackPeriod`, item.paybackPeriod.toString());
      item.profitablityIndex && formData.append(`economyAnalyses[${index}].profitablityIndex`, item.profitablityIndex.toString());
      item.pertaminaSizePercentage && formData.append(`economyAnalyses[${index}].pertaminaSizePercentage`, item.pertaminaSizePercentage.toString());
      item.pertaminaSizeIDR && formData.append(`economyAnalyses[${index}].pertaminaSizeIDR`, item.pertaminaSizeIDR.toString());
      item.pertaminaSizeUSD && formData.append(`economyAnalyses[${index}].pertaminaSizeUSD`, item.pertaminaSizeUSD.toString());
      item.investationValueIDR && formData.append(`economyAnalyses[${index}].investationValueIDR`, item.investationValueIDR.toString());
      item.sensitivity && formData.append(`economyAnalyses[${index}].sensitivity`, item.sensitivity.toString());
      item.kursUSD && formData.append(`economyAnalyses[${index}].kursUSD`, item.kursUSD.toString());

      if (economyAnalysis && economyAnalysis.length > 0) {
        const i = item as any;
        formData.append(`economyAnalyses[${index}].id`, economyAnalysis[0].id);
        formData.append(`economyAnalyses[${index}].flagEdit`, FlagEdit.PROPOSAL.toString());
      }

      files.forEach((file, x) => {
        if (file.data?.id) {
          formData.append(`economyAnalyses[${index}].attachments[${x}].attachment`, file.data.attachment as File);
          formData.append(`economyAnalyses[${index}].attachments[${x}].fileName`, "");
          formData.append(`economyAnalyses[${index}].attachments[${x}].id`, file.data.id);
        } else {
          formData.append(`economyAnalyses[${index}].attachments[${x}].attachment`, file.file as File);
          formData.append(`economyAnalyses[${index}].attachments[${x}].fileName`, "");
        }
      });
    });

    if (economyAnalysis && economyAnalysis.length > 0) {
      await editEconomyAnalysis(id, formData as any).then(async () => {
        handleClose();
        resetForm();
        setErrors({ items: undefined });
        await getEconomyAnalysis(id);
      });
    } else {
      await addEconomyAnalysis(id, formData as any).then(async () => {
        handleClose();
        resetForm();
        setErrors({ items: undefined });
        await getEconomyAnalysis(id);
      });
    }
  };

  const { handleChange, submitForm, setFieldValue, resetForm, setErrors, values, errors, isValid, isSubmitting } = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (economyAnalysis) {
      economyAnalysis.map((item) => {
        setFiles(
          item.attachments.map((attachment) => ({
            file: {
              name: attachment.fileName,
            },
            data: attachment,
          }))
        );
      });
    }
  }, [economyAnalysis]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      sx={{
        ".MuiPaper-root": {
          width: "500px",
        },
      }}
    >
      <Box
        sx={{
          m: "16px 16px 0 16px",
          pb: "8px",
          borderBottom: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "space-between",
          mb: "16px",
        }}
      >
        <Typography fontSize={"14px"} fontWeight={700} color="black">
          {economyAnalysis && economyAnalysis.length > 0 ? "Edit" : "Add"} Analisis Keekonomian
        </Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <Stack component="form" flex={1} overflow="auto" p="16px" spacing="24px">
        {values.items?.map((item, index) => {
          const error: any = errors.items ? errors.items[index] : undefined;

          return (
            <Fragment key={index}>
              <NumericFormat
                customInput={TextField}
                label="NPV (Rp)"
                fullWidth
                value={item.npv}
                error={!!error?.npv}
                helperText={error?.npv}
                thousandSeparator={true}
                onValueChange={(e) => {
                  setFieldValue(`items[${index}].npv`, e.floatValue);
                }}
                name={`items[${index}].npv`}
                disabled={readOnly}
              />
              <NumericFormat
                customInput={TextField}
                label="IRR (%)"
                fullWidth
                value={item.irr}
                error={!!error?.irr}
                helperText={error?.irr}
                thousandSeparator={true}
                onValueChange={(e) => {
                  setFieldValue(`items[${index}].irr`, e.floatValue);
                }}
                name={`items[${index}].irr`}
                disabled={readOnly}
              />
              <NumericFormat
                customInput={TextField}
                label="Hurdle Rate (%)"
                fullWidth
                value={item.hurdleRate}
                error={!!error?.hurdleRate}
                helperText={error?.hurdleRate}
                thousandSeparator={true}
                onValueChange={(e) => {
                  setFieldValue(`items[${index}].hurdleRate`, e.floatValue);
                }}
                name={`items[${index}].hurdleRate`}
                disabled={readOnly}
              />
              <NumericFormat
                customInput={TextField}
                label="Payback Period (Tahun)"
                fullWidth
                value={item.paybackPeriod}
                error={!!error?.paybackPeriod}
                helperText={error?.paybackPeriod}
                thousandSeparator={true}
                onValueChange={(e) => {
                  setFieldValue(`items[${index}].paybackPeriod`, e.floatValue);
                }}
                name={`items[${index}].paybackPeriod`}
                disabled={readOnly}
              />
              <NumericFormat
                customInput={TextField}
                label="Profitability Index (%)"
                fullWidth
                value={item.profitablityIndex}
                error={!!error?.profitablityIndex}
                helperText={error?.profitablityIndex}
                thousandSeparator={true}
                onValueChange={(e) => {
                  setFieldValue(`items[${index}].profitablityIndex`, e.floatValue);
                }}
                name={`items[${index}].profitablityIndex`}
                disabled={readOnly}
              />
              <NumericFormat
                customInput={TextField}
                label="Nilai Investasi (IDR)"
                fullWidth
                value={item.investationValueIDR}
                error={!!error?.investationValueIDR}
                helperText={error?.investationValueIDR}
                thousandSeparator={true}
                onValueChange={(e) => {
                  setFieldValue(`items[${index}].investationValueIDR`, e.floatValue);
                }}
                prefix="Rp "
                name={`items[${index}].investationValueIDR`}
                disabled
              />
              <Stack spacing="24px" direction="row">
                <NumericFormat
                  customInput={TextField}
                  label="Porsi Patrajasa (%)"
                  fullWidth
                  value={item.pertaminaSizePercentage}
                  error={!!error?.pertaminaSizePercentage}
                  helperText={error?.pertaminaSizePercentage}
                  thousandSeparator={true}
                  onValueChange={(e) => {
                    setFieldValue(`items[${index}].pertaminaSizePercentage`, e.floatValue);
                    if (!generalInformation || !e.floatValue) return;
                    setFieldValue(`items[${index}].pertaminaSizeIDR`, (e.floatValue * generalInformation?.cost) / 100);
                    setFieldValue(`items[${index}].pertaminaSizeUSD`, (e.floatValue * generalInformation?.cost) / 100 / (item.kursUSD ?? 0));
                  }}
                  name={`items[${index}].pertaminaSizePercentage`}
                  disabled={readOnly}
                />
                <NumericFormat
                  customInput={TextField}
                  label="Porsi Patrajasa (IDR)"
                  fullWidth
                  value={item.pertaminaSizeIDR}
                  error={!!error?.pertaminaSizeIDR}
                  helperText={error?.pertaminaSizeIDR}
                  thousandSeparator={true}
                  onValueChange={(e) => {
                    setFieldValue(`items[${index}].pertaminaSizeIDR`, e.floatValue);
                  }}
                  prefix="Rp "
                  name={`items[${index}].pertaminaSizeIDR`}
                  disabled
                />
              </Stack>
              <Stack spacing="24px" direction="row">
                <NumericFormat
                  customInput={TextField}
                  label="KURS"
                  fullWidth
                  value={item.kursUSD}
                  error={!!error?.kursUSD}
                  helperText={error?.kursUSD}
                  thousandSeparator={true}
                  onValueChange={(e) => {
                    setFieldValue(`items[${index}].kursUSD`, e.floatValue);
                    if (!generalInformation || !e.floatValue) return;
                    setFieldValue(`items[${index}].pertaminaSizeUSD`, (item.pertaminaSizeIDR ?? 0) / e.floatValue);
                  }}
                  prefix="Rp "
                  name={`items[${index}].kursUSD`}
                  disabled={readOnly}
                />
                <NumericFormat
                  customInput={TextField}
                  label="Porsi Patrajasa (USD)"
                  fullWidth
                  value={item.pertaminaSizeUSD?.toFixed(2)}
                  error={!!error?.pertaminaSizeUSD}
                  helperText={error?.pertaminaSizeUSD}
                  thousandSeparator={true}
                  onValueChange={(e) => {
                    setFieldValue(`items[${index}].pertaminaSizeUSD`, e.floatValue);
                  }}
                  prefix="$ "
                  name={`items[${index}].pertaminaSizeUSD`}
                  disabled
                />
              </Stack>

              {/* <TextField
                label="Nilai Investasi (USD)"
                type="number"
                name={`items[${index}].investationValueUSD`}
                value={item.investationValueUSD}
                error={!!error?.investationValueUSD}
                helperText={error?.investationValueUSD}
                onChange={handleChange}
              /> */}
              {/* <TextField
                label="Value Exchange"
                type="number"
                name={`items[${index}].valueExchange`}
                value={item.valueExchange}
                error={!!error?.valueExchange}
                helperText={error?.valueExchange}
                onChange={handleChange}
              /> */}
              {/* <TextField
                label="Contract"
                type="number"
                name={`items[${index}].contractValueUSD`}
                value={item.contractValueUSD}
                error={!!error?.contractValueUSD}
                helperText={error?.contractValueUSD}
                onChange={handleChange}
              /> */}
              {/* <TextField
                label="Sensitivitas"
                name={`items[${index}].sensitivity`}
                value={item.sensitivity}
                error={!!error?.sensitivity}
                helperText={error?.sensitivity}
                onChange={handleChange}
                disabled={readOnly}
              /> */}
              <DraggableFileUploadV2
                data={files}
                setData={setFiles}
                viewable
                viewableOptions={{ propsoalId: id ?? "" }}
                loading={loadingAttc}
                accept="application/pdf,application/msword,image/*"
                onDelete={(data) => {
                  if (!id) return;

                  setLoadingAttc(true);

                  deleteAttachment(id, data.id)
                    .then(() => getEconomyAnalysis(id))
                    .finally(() => setLoadingAttc(false));
                }}
              />
            </Fragment>
          );
        })}
        {isOpen && economyAnalysis ? <CommentList isOpen={isOpen} data={economyAnalysis} /> : ""}
      </Stack>
      <Box
        sx={{
          m: "0 16px 16px 16px",
          pt: "16px",
          borderTop: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <LoadingButton type="submit" variant="contained" loading={isSubmitting} onClick={submitForm} disabled={!isValid || readOnly}>
          {economyAnalysis && economyAnalysis.length > 0 ? "Edit" : "Add"}
        </LoadingButton>
      </Box>
    </Drawer>
  );
}

export default observer(ProposalEconomyAnalysisForm);
