import { useEffect, useState } from "react";
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { ProjectStatus, ReviewerLog } from "../../../../../app/config/enum";
import { useStore } from "../../../../../app/stores/store";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import useQuery from "../../../../../app/hook/useQuery";

type TCommentListProps = {
  isOpen: boolean;
  data: any;
};

export default function CommentList({ isOpen, data }: TCommentListProps) {
  const [commentList, setCommentList] = useState<any[]>([]);
  const { getReviewerLog } = useStore().reviewerLogStore;
  const query = useQuery();
  const id = query.get("id");
  const proposalStatus = query.get("status");

  const getReviewerLogData = () => {
    if (!id || !data || !proposalStatus) return;
    if (data[0]?.id === undefined) return;
    getReviewerLog(id, data[0]?.id, proposalStatus, "").then((res: any) => {
      setCommentList(res);
    });
  };

  useEffect(() => {
    if (isOpen) {
      getReviewerLogData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div>
      {commentList.filter((comment) => comment.status === ReviewerLog.NOT_PROCEED).length > 0 ? (
        <Box>
          <Typography fontSize={"14px"} fontWeight={700} color="black">
            Komentar
          </Typography>
          {commentList.map((item) => (
            <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <AccountCircleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item?.userName} secondary={item.comment} />
              </ListItem>
            </List>
          ))}
        </Box>
      ) : (
        ""
      )}
    </div>
  );
}
