import { useEffect } from "react";
import DashboardLayoutHeader from "../../../app/component/layout/DashboardLayoutHeader";
import { Grid, Paper, TextField, Box, Stack } from "@mui/material";
import { ArrowBackIos, HandshakeOutlined } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { Link } from "react-router-dom";
import PageButton from "../components/PageButton";
// import CommentSection from "../components/CommentSection";
import LoadingSection from "../components/LoadingSection";
import useQuery from "../../../app/hook/useQuery";
import TextEditor from "../../../app/component/TextEditor";
import AttachmentSection from "../components/AttachmentSection";

function CollaborationAnalysis() {
  const { proposalStore } = useStore();
  const { collaborationAnalysis, collaborationAnalysisLoading, getCollaborationAnalysis, getGeneralInformation, generalInformation } = proposalStore;
  const { id } = useParams();
  const query = useQuery();
  const status = query.get("status");

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
  }, [getGeneralInformation, id]);

  useEffect(() => {
    if (!id) return;

    getCollaborationAnalysis(id);
  }, [getCollaborationAnalysis, id]);

  return (
    <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Approval",
            href: "/project-monitoring/approval/list",
          },
          {
            label: "Approval List",
            href: "/project-monitoring/approval/list",
          },
          {
            label: `${generalInformation?.title}`,
          },
        ]}
        pageTitle="Project Monitoring"
      />

      <Paper sx={{ p: "16px" }}>
        <Link
          style={{ color: "#000", textDecoration: "none", display: "flex", alignItems: "center", gap: "3px", marginBottom: "24px" }}
          to={`/project-monitoring/approval/list/${id}?status=${status}`}
        >
          <ArrowBackIos sx={{ fontSize: "14px" }} />
          <Box>Back</Box>
        </Link>
        <Box
          sx={{
            mb: "12px",
            backgroundColor: "#F2F9FF",
            display: "flex",
            p: "16px",
            alignItems: "center",
            gap: "6px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <HandshakeOutlined sx={{ mr: "12px", color: "#0664B4", fontSize: "34px" }} />
            <Box sx={{ fontWeight: "bold" }}>Analisis Kerjasama</Box>
          </Box>
          {/* {collaborationAnalysisLoading ? (
            "Loading..."
          ) : (
            <CommentSection typeId={collaborationAnalysis?.length ? collaborationAnalysis[0]?.id : ""} />
          )} */}
        </Box>

        {collaborationAnalysisLoading ? (
          <LoadingSection />
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Stack flex={1} overflow="auto" pt="12px" spacing="24px">
                  <TextEditor label="MOU" value={collaborationAnalysis && collaborationAnalysis[0]?.mou} disabled />
                  <TextEditor label="Skema Kerjasama" value={collaborationAnalysis && collaborationAnalysis[0]?.scheme} disabled />
                  <TextEditor label="PKS" value={collaborationAnalysis && collaborationAnalysis[0]?.pks} disabled />
                  <TextEditor label="Project Sponsor" value={collaborationAnalysis && collaborationAnalysis[0]?.projectSponsor} disabled />
                  <TextEditor label="Partner" value={collaborationAnalysis && collaborationAnalysis[0]?.partner} disabled />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack flex={1} overflow="auto" pt="12px" spacing="24px">
                  <Stack direction="row" spacing={"24px"}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Start Date"
                        disabled
                        value={collaborationAnalysis && collaborationAnalysis[0]?.starDate}
                        views={["year", "month"]}
                        onChange={() => null}
                        renderInput={(props) => <TextField required fullWidth name="starDate" {...props} disabled />}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="End Date"
                        value={collaborationAnalysis && collaborationAnalysis[0]?.endDate}
                        disabled
                        views={["year", "month"]}
                        onChange={() => null}
                        renderInput={(props) => <TextField required fullWidth name="endDate" disabled {...props} />}
                      />
                    </LocalizationProvider>
                  </Stack>
                  <TextEditor label="Scope" value={collaborationAnalysis && collaborationAnalysis[0]?.scope} disabled />
                  <TextEditor label="Description" value={collaborationAnalysis && collaborationAnalysis[0]?.description} disabled />
                  <TextField label="NDA" value={collaborationAnalysis && collaborationAnalysis[0]?.nda} disabled />
                </Stack>
              </Grid>
            </Grid>
            <AttachmentSection attachtment={collaborationAnalysis?.length ? collaborationAnalysis[0]?.attachments : []} />
          </>
        )}

        <PageButton
          isPrevButton
          isNextButton
          prevButtonRoute={`/project-monitoring/approval/list/${id}/economyAnalysis?status=${status}`}
          nextButtonRoute={`/project-monitoring/approval/list/${id}/risk?status=${status}`}
        />
      </Paper>
      <Box sx={{ py: "16px" }} />
    </Box>
  );
}

export default observer(CollaborationAnalysis);
