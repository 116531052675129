import { MapContainer, TileLayer, MapContainerProps, useMapEvents, Marker as MapMarker } from "react-leaflet";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";

import "leaflet/dist/leaflet.css"
import "leaflet-geosearch/dist/geosearch.css";
import { ReactNode, useEffect } from "react";
import { Icon, LeafletMouseEventHandlerFn } from "leaflet";

type MapViewerProps = {
  children?: ReactNode;
} & MapContainerProps

function MapViewer({ children, ...props }: MapViewerProps) {
  return (
    <MapContainer center={[0.7893, 113.9213]} zoom={13} {...props}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {children}
    </MapContainer>
  )
}

const provider = new OpenStreetMapProvider()

const SearchControl = () => {
  const map = useMapEvents({})

  const searchControl = GeoSearchControl({ provider, style: 'bar'})
  
  useEffect(() => {
    map.addControl(searchControl)

    return () => {
      map.removeControl(searchControl)
    }
  }, [map, searchControl])
  
  return null
}

type MarkerProps = {
  onClick?: LeafletMouseEventHandlerFn
  position?: {
    lng: number;
    lat: number;
  },
  autoFocus?: boolean;
}

const Marker = ({ onClick, position, autoFocus }: MarkerProps) => {
  const map = useMapEvents({
    click(e) {
      onClick && onClick(e)
    },
    // locationfound(e) {
    //   // setPosition(e.latlng)
    //   map.flyTo(e.latlng, map.getZoom())
    // },
  })

  // handle auto focus
  useEffect(() => {
    if (position && autoFocus) {
      map.flyTo(position, map.getZoom())
    }
  }, [position, autoFocus, map])

  const customMarker = new Icon({
    iconUrl: '/marker.png',
    iconAnchor:  [22, 32],
    iconSize: [32, 32]
  })
  
  if (!position) return null
  
  return (
    <MapMarker position={position} icon={customMarker} />
  )
}

export default Object.assign(MapViewer, {
  SearchControl,
  Marker
})
