import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { DataGridResult } from "../models/materialUI/dataGrid";
import { IRefid, IGeneralReFid, IFidMilestoneTaskReFIDBody, TApproveRefuseReFIDBody } from "../models/reFid";
import { IFidMilestone, IFidMilestoneQueryParams, IFidMilestoneSubTaskBody, IFidMilestoneTaskBody } from "../models/fidMilestones";

export class ReFidStore {
  capex: DataGridResult<IFidMilestone[]> = new DataGridResult([], 0);
  capexLoading: boolean = false;
  reFidGrid: DataGridResult<IRefid[]> = new DataGridResult([], 0);
  reFidGridLoading: boolean = false;

  reFidGeneralInformation: null | IGeneralReFid = null;
  reFidGeneralInformationLoading = false;

  queryParams: IFidMilestoneQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  submitReFid = async (proposalId: string, body: any) => {
    try {
      await agent.ReFID.submitReFid(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  approveReFid = async (proposalId: string, status: string, body: any) => {
    try {
      await agent.ReFID.approveReFid(proposalId, status, body);
    } catch (e) {
      throw e;
    }
  };

  getCapexGrid = async (proposalId: string) => {
    this.capexLoading = true;
    try {
      const res = await agent.ReFID.getCapexReFid(proposalId, this.queryParams);

      runInAction(() => (this.capex = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.capexLoading = false));
    }
  };

  getReFidGeneralInformation = async (proposalId: string) => {
    this.reFidGeneralInformationLoading = true;
    try {
      const res = await agent.ReFID.getgeneralInformationReFid(proposalId);
      runInAction(() => (this.reFidGeneralInformation = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.reFidGeneralInformationLoading = false));
    }
  };

  addReFidGeneralInformation = async (proposalId: string, body: IGeneralReFid) => {
    try {
      await agent.ReFID.addgeneralInformationReFid(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  deleteGeneralInformationReFid = async (proposalId: string, generalInformationReFidId: string) => {
    try {
      await agent.ReFID.deleteGeneralInformationReFid(proposalId, generalInformationReFidId);
    } catch (e) {
      throw e;
    }
  };

  editReFidGeneralInformation = async (proposalId: string, body: IGeneralReFid, generalInformationReFid: string) => {
    try {
      await agent.ReFID.editgeneralInformationReFid(proposalId, body, generalInformationReFid);
    } catch (e) {
      throw e;
    }
  };

  getReFidGrid = async () => {
    this.reFidGridLoading = true;
    try {
      const res = await agent.ReFID.getGrid();
      this.reFidGrid = res;
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.reFidGridLoading = false));
    }
  };

  addTaskReFid = async (body: IFidMilestoneTaskReFIDBody) => {
    try {
      return await agent.ReFID.addTask(body);
    } catch (e) {
      throw e;
    }
  };

  editTaskReFid = async (taskId: string, body: IFidMilestoneTaskReFIDBody) => {
    try {
      return await agent.ReFID.editTask(taskId, body);
    } catch (e) {
      throw e;
    }
  };

  addSubTaskReFid = async (body: IFidMilestoneSubTaskBody) => {
    try {
      return await agent.ReFID.addSubTask(body);
    } catch (e) {
      throw e;
    }
  };

  editSubTaskReFid = async (id: string, body: IFidMilestoneSubTaskBody) => {
    try {
      return await agent.ReFID.editSubTask(id, body);
    } catch (e) {
      throw e;
    }
  };

  setQueryParams = (query: "pageSize" | "search" | "currentPage", v: string | number) => {
    this.queryParams = {
      ...this.queryParams,
      [query]: v,
    };
  };
}
