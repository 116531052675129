import { Delete, DescriptionOutlined, Edit } from "@mui/icons-material";
import { Box, Grid, Paper, Skeleton, Stack, TextField, Typography } from "@mui/material";
import MapViewer from "../../app/component/map/MapViewer";
import LandForm from "./LandForm";
import { useEffect, useMemo } from "react";
import { useStore } from "../../app/stores/store";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { history } from "../..";
import { Roles } from "../../app/config/enum";
import { NumericFormat } from "react-number-format";

function LandDetail() {
  const { landStore, dialogStore, accountStore } = useStore();
  const { getLandDetail, getLandStatus, getLandType, landDetail, getLand, setLandDetail, landDetailLoading, setSelectedLand, selectedLand } =
    landStore;
  const { account } = accountStore;
  const { id: landId } = useParams();

  const handleDelete = () =>
    dialogStore.open({
      title: "Delete",
      description: "Are you sure want to remove this data?",
      closeText: "Cancel",
      actionText: "Delete",
      action: async () =>
        landStore.deleteLand([{ id: String(landId) }]).then((res) => {
          history.push("/land/list");
          getLand();
          setSelectedLand(null);
          setLandDetail(null);

          return res;
        }),
      actionButtonProps: {
        color: "error",
      },
    });

  const values = useMemo(
    () => ({
      noAsset: landDetail?.noAsset,
      landArea: landDetail?.landArea,
      address: landDetail?.address,
      type: landDetail?.typeName,
      status: landDetail?.statusName,
      location: landDetail?.location,
      longitude: landDetail?.longitude,
      latitude: landDetail?.latitude,
      certificate: landDetail?.certificate,
      typeNotes: landDetail?.typeNotes,
      statusNotes: landDetail?.statusNotes,
      ownerShipStatus: landDetail?.ownershipStatusName,
      landStatusAllotmentName: landDetail?.landStatusAllotmentName,
      pbb: landDetail?.pbb,
      province: landDetail?.province,
      city: landDetail?.city,
      kbd: landDetail?.regulations[0] ? landDetail?.regulations[0].kdb : "",
      klb: landDetail?.regulations[0] ? landDetail?.regulations[0].klb : "",
      gbs: landDetail?.regulations[0] ? landDetail?.regulations[0].gbs : "",
      kdh: landDetail?.regulations[0] ? landDetail?.regulations[0].kdh : "",
      buildingHeight: landDetail?.regulations[0] ? landDetail?.regulations[0].buildingHeight : "",
      attachments: landDetail?.attachments.map((i) => ({
        title: i.title,
        url: i.url,
        fileName: i.fileName,
        fileSize: i.fileSize,
      })),
    }),
    [landDetail]
  );

  useEffect(() => {
    if (!landId) return;

    getLandDetail(landId);
  }, [landId, getLandDetail, getLandStatus, getLandType]);

  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Profile Lahan",
            href: "/land/list",
          },
          {
            label: "Profile Lahan List",
            href: "/land/list",
          },
          {
            label: String(landId),
          },
        ]}
        canGoBack
        pageTitle="Back"
      >
        <DashboardLayoutHeader.Button
          variant="contained"
          startIcon={<Edit />}
          onClick={() => setSelectedLand(landDetail)}
          disabled={!landDetail || landDetailLoading || account?.roles.includes(Roles.General)}
        >
          Edit
        </DashboardLayoutHeader.Button>
        <DashboardLayoutHeader.Button
          variant="contained"
          color="error"
          startIcon={<Delete />}
          onClick={() => handleDelete()}
          disabled={account?.roles.includes(Roles.General)}
        >
          Delete
        </DashboardLayoutHeader.Button>
      </DashboardLayoutHeader>
      <Paper sx={{ p: "16px" }}>
        {landDetailLoading ? (
          <LoadingSection />
        ) : (
          <Grid container spacing="24px">
            <Grid item md={6}>
              <Typography fontSize="20px" fontWeight={700} color="black" mb={"16px"}>
                General Information
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                <Box display="flex" gap="16px">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    label="No Asset"
                    defaultValue={values.noAsset}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    label="Luas Lahan (m2)"
                    defaultValue={values.landArea}
                  />
                </Box>
                <TextField
                  fullWidth
                  label="Address"
                  defaultValue={values.address}
                  disabled
                  InputLabelProps={{ shrink: true }}
                  multiline
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  rows={5}
                />
                <Box display="flex" gap="16px">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    label="Tipe Lahan"
                    defaultValue={values.type}
                  />
                  {values.type === "Others" && (
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      disabled
                      sx={{
                        flex: 1,
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                      label="Notes Tipe Lahan"
                      defaultValue={values.typeNotes}
                    />
                  )}
                </Box>
                <Box display="flex" gap="16px">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    label="Status Lahan"
                    defaultValue={values.status}
                  />
                  {values.status === "Others" && (
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      disabled
                      sx={{
                        flex: 1,
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#000000",
                        },
                      }}
                      label="Notes Status Lahan"
                      defaultValue={values.statusNotes}
                    />
                  )}
                </Box>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  label="Status Kepemilikan"
                  defaultValue={values.ownerShipStatus}
                />
                {/* <TextField
                  InputLabelProps={{ shrink: true }}
                  disabled
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  label="Peruntukan"
                  defaultValue={values.landStatusAllotmentName}
                /> */}
                <Box display="flex" gap="16px">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    label="Latitude"
                    defaultValue={values.latitude}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    label="Longitude"
                    defaultValue={values.longitude}
                  />
                </Box>
                <Box display="flex" gap="16px">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    label="Provinsi"
                    defaultValue={values.province}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    label="Kabupaten/Kota"
                    defaultValue={values.city}
                  />
                  {/* <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    label="Location"
                    defaultValue={values.location}
                  /> */}
                </Box>
                <Box display="flex" gap="16px">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    label="Certificate"
                    defaultValue={values.certificate}
                  />
                </Box>
                <NumericFormat
                  customInput={TextField}
                  label="NJOP Bumi (m2)"
                  fullWidth
                  disabled
                  value={values.pbb}
                  // value={economyAnalysis && economyAnalysis[0]?.investationValueIDR}
                  thousandSeparator={true}
                  prefix="Rp "
                />
                {/* <TextField
                  disabled
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="NJOP Bumi (m2)"
                  defaultValue={values.pbb}
                  InputProps={{
                    startAdornment: <Typography>Rp</Typography>,
                  }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                /> */}
              </Box>
              <Typography mt="24px" fontSize="20px" fontWeight={700} color="black" mb={"16px"}>
                Regulation
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                <Box display="flex" gap="16px">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    label="Peruntukan"
                    defaultValue={values.landStatusAllotmentName}
                  />
                  <TextField
                    disabled
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    label="KDB"
                    defaultValue={values.kbd}
                  />
                </Box>
                <Box display="flex" gap="16px">
                  <TextField
                    disabled
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    label="KLB"
                    defaultValue={values.klb}
                  />
                  {/* <TextField
                    disabled
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    label="GSB"
                    defaultValue={values.gbs}
                  /> */}
                  <TextField
                    disabled
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                    label="KDH"
                    defaultValue={values.kdh}
                  />
                </Box>
                <TextField
                  fullWidth
                  label="Building height"
                  defaultValue={values.buildingHeight}
                  disabled
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: <Typography>Meter</Typography>,
                  }}
                />
                <TextField
                  fullWidth
                  label="Lain Lain"
                  defaultValue={values.buildingHeight}
                  disabled
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#000000",
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Typography fontSize="20px" fontWeight={700} color="black" mb="16px">
                Attachment
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                {values.attachments?.map((attc, attcX) => (
                  <Paper
                    sx={{
                      p: "11px 16px",
                      boxShadow: "none",
                      borderRadius: "4px",
                      border: ({ palette }) => `1px solid ${palette.grey[300]}`,
                    }}
                    key={attcX}
                  >
                    <Stack direction="row" alignItems="center" spacing={"12px"}>
                      <DescriptionOutlined sx={{ width: "24px", height: "24px" }} />
                      <Stack spacing={"4px"}>
                        <Typography fontSize="14px" fontWeight={700} color="black">
                          {attc.title}
                        </Typography>
                        <Stack spacing="8px" direction="row" alignItems="center">
                          <Typography color={({ palette }) => palette.grey[500]} fontSize="12px">
                            {attc.fileName}
                          </Typography>
                          <Box borderRadius="100%" height="4px" width="4px" bgcolor={({ palette }) => palette.grey[500]} />
                          <Typography color={({ palette }) => palette.grey[500]} fontSize="12px">
                            {attc.fileSize}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Paper>
                ))}
                {/* <TextField disabled InputLabelProps={{ shrink: true }} fullWidth label="Name" defaultValue={values.name} />
                  <TextField disabled InputLabelProps={{ shrink: true }} fullWidth label="Description" defaultValue={values.description} />
                  <TextField InputProps={{ startAdornment: <AttachFile /> }} fullWidth type="file" /> */}
              </Box>
              <Typography fontSize="20px" fontWeight={700} color="black" my={"16px"}>
                Location
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                <MapViewer style={{ height: "250px" }} zoom={12}>
                  <MapViewer.Marker
                    autoFocus
                    position={values.latitude && values.longitude ? { lat: Number(values.latitude), lng: Number(values.longitude) } : undefined}
                  />
                </MapViewer>
              </Box>
            </Grid>
          </Grid>
        )}
      </Paper>
      <LandForm isOpen={!!selectedLand} onClose={() => setSelectedLand(null)} />
    </Box>
  );
}

export default observer(LandDetail);

const LoadingSection = () => {
  return (
    <Grid container spacing={"24px"}>
      <Grid item md={6}>
        <Typography fontSize="20px" fontWeight={700} color="black" mb="16px">
          General Information
        </Typography>
        <Stack spacing={"24px"}>
          <Stack direction="row" spacing={"16px"}>
            <Skeleton variant="rounded" sx={{ flex: 1 }} height={56} />
            <Skeleton variant="rounded" sx={{ flex: 1 }} height={56} />
          </Stack>
          <Skeleton variant="rounded" width="100%" height={56} />
          <Stack direction="row" spacing={"16px"}>
            <Skeleton variant="rounded" sx={{ flex: 1 }} height={56} />
            <Skeleton variant="rounded" sx={{ flex: 1 }} height={56} />
          </Stack>
          <Stack direction="row" spacing={"16px"}>
            <Skeleton variant="rounded" sx={{ flex: 1 }} height={56} />
            <Skeleton variant="rounded" sx={{ flex: 1 }} height={56} />
          </Stack>
          <Stack direction="row" spacing={"16px"}>
            <Skeleton variant="rounded" sx={{ flex: 1 }} height={56} />
            <Skeleton variant="rounded" sx={{ flex: 1 }} height={56} />
          </Stack>
          <Skeleton variant="rounded" width="100%" height={56} />
          <Skeleton variant="rounded" width="100%" height={56} />
        </Stack>
        <Typography mt={"24px"} fontSize="20px" fontWeight={700} color="black" mb="16px">
          Regulations
        </Typography>
        <Stack spacing={"24px"}>
          <Stack direction="row" spacing={"16px"}>
            <Skeleton variant="rounded" sx={{ flex: 1 }} height={56} />
            <Skeleton variant="rounded" sx={{ flex: 1 }} height={56} />
          </Stack>
          <Stack direction="row" spacing={"16px"}>
            <Skeleton variant="rounded" sx={{ flex: 1 }} height={56} />
            <Skeleton variant="rounded" sx={{ flex: 1 }} height={56} />
          </Stack>
          <Skeleton variant="rounded" width="100%" height={56} />
        </Stack>
      </Grid>
      <Grid item md={6}>
        <Typography fontSize="20px" fontWeight={700} color="black" mb="16px">
          Attachment
        </Typography>
        <Skeleton variant="rounded" width="100%" height={56} />
        <Typography mt={"24px"} fontSize="20px" fontWeight={700} color="black" mb="16px">
          Regulations
        </Typography>
        <Skeleton variant="rounded" width="100%" height={331} />
      </Grid>
    </Grid>
  );
};
