import { action, makeObservable, observable, reaction } from "mobx";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";
import { IDataGridResult, IDataGridParams, DataGridResult } from "../../models/materialUI/dataGrid";

export default class DataGridStore implements IDataGridParams {
    currentPage = 0;
    pageSize = 10;
    sortModel: GridSortModel = [];
    filterModel: GridFilterModel | null = null;
    loadingGrid = false;
    dataGridResult: IDataGridResult<any[]> = new DataGridResult<any[]>([], 0);
    
    constructor() {
        makeObservable(this, {
            currentPage: observable,
            pageSize: observable,
            sortModel: observable,
            filterModel: observable,
            loadingGrid: observable,
            dataGridResult: observable,
            createDataGridParam: action,
            setPage: action,
            setPageSize: action,
            setSortModel: action,
            setFilterModel: action,
            setDataGridResult: action,
        });
        reaction(
            () => [this.currentPage, this.pageSize, this.sortModel, this.filterModel],
            () => {
                this.updateGridCallback();
            }
        )
    }

    updateGridCallback = action(() => {})

    createDataGridParam = () => {
        const params = new URLSearchParams(); // no need to import from anywhere!!
        params.append('currentPage', this.currentPage.toString());
        params.append('pageSize', this.pageSize.toString());
        params.append('sortModel', JSON.stringify(this.sortModel));
        params.append('filterModel', JSON.stringify(this.filterModel));
        return params;
    }

    setPage = (value: number) => {
        this.currentPage = value;
    }

    setPageSize = (value: number) => {
        this.pageSize = value;
    }

    setSortModel = (sortModel: GridSortModel) => {
        this.sortModel = sortModel;
    }

    setFilterModel = (filterModel: GridFilterModel) => {
        this.filterModel = filterModel;
    }

    setDataGridResult = (result: IDataGridResult<any[]>) => {
        this.dataGridResult = result;
    }
}