import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { TReviewers } from "../../../app/models/proposalGeneralInformation";
import EmptyState from "../../../app/component/EmptyState";
import ViewAttachment from "../../../app/component/ViewAttachment";
import { DescriptionOutlined } from "@mui/icons-material";

type TSummaryReviewListProps = {
  data: TReviewers[];
  proposalId: string;
};

export default function SummaryReviewList({ proposalId, data = [] }: TSummaryReviewListProps) {
  return (
    <Box>
      {data.length > 0 ? (
        <>
          {data
            .filter((filteredData) => filteredData.notes !== null)
            .map((item) => (
              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <AccountCircleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={item?.username} secondary={item?.notes ?? ""} />
                </ListItem>
                <Box sx={{ ml: "18px" }}>
                  {item.attachments.length > 0 ? (
                    <>
                      <hr />
                      <Typography fontWeight="bold" color="black" sx={{ textAlign: "center", mb: "12px" }}>
                        Attachment List
                      </Typography>
                      <Grid container spacing={2}>
                        {item.attachments.map((attachment: any) => (
                          <Grid item xs={6}>
                            <Box
                              sx={{
                                display: "flex",
                                p: "8px",
                                borderRadius: "4px",
                                border: "1px solid rgba(29, 27, 32, 0.12)",
                              }}
                            >
                              <ViewAttachment loadingComponent={<Skeleton width={150} variant="text" />} url={attachment.url} proposalId={proposalId}>
                                <DescriptionOutlined sx={{ cursor: "pointer" }} />
                              </ViewAttachment>
                              <Box
                                sx={{
                                  width: "100%",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  display: "flex",
                                  ml: "12px",
                                }}
                              >
                                <Box sx={{ fontWeight: "bold" }}>{attachment.fileName ?? "No Name"}</Box>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </List>
            ))}
        </>
      ) : (
        <EmptyState />
      )}
    </Box>
  );
}
