import { observer } from "mobx-react-lite";
import { Autocomplete, CircularProgress, TextField } from "@mui/material"
import { useEffect, useState } from "react";

interface Props {
    getOptions: (search?: string) => Promise<any[]>;
    name?: string;
    label?: string;
    textPropName?: string;
    valuePropName?: string;
    charNumRequired?: number;
    multiSelect?: boolean;
}

function RkdSearchSelect(props: Props) {
    const { getOptions, textPropName, valuePropName, charNumRequired, multiSelect, label, name } = props;

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [options, setOptions] = useState<any[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const [optionValue, setOptionValue] = useState("");
    const [lastSearch, setLastSearch] = useState<string>();

    const valueProp = valuePropName ?? "value";
    const textProp = textPropName ?? "text";

    useEffect(() => {
        // set input value yg hidden (value yg buat di-submit bareng form)
        if (multiSelect) {
            const optionVals = selectedOptions.map((selectedOption: any) => {
                return selectedOption[valueProp];
            });
            setOptionValue(optionVals.join(", "));
        } else {
            setOptionValue(selectedOptions ? selectedOptions[valueProp] : "");
        }
    }, [multiSelect, valueProp, selectedOptions]);

    let timeoutTimeStamp = 0; // sebagai ID event pen-trigger search
    const handleOnInputChange = (event: React.SyntheticEvent, value: string, reason: string) => {
        timeoutTimeStamp = event.timeStamp;
        setTimeout(async () => {
            if (timeoutTimeStamp === event.timeStamp && !loading && reason === "input") { // kalau ID pen-trigger beda, berarti input udah diganti lagi sebelum timeout, jadi ga usah lakukan search
                await getData(value);
            }
        }, 1000);
    }

    const getData = async (search: string) => {
        if ((charNumRequired === undefined || search.length >= charNumRequired) && (search !== lastSearch)) {
            try {
                setLoading(true);
                setLastSearch(search);
                await getOptions(search).then(options => setOptions(options));
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    }

    const handleOnChange = (event: React.SyntheticEvent, value: any, reason: string) => {
        // nandain item yg dipilih aja
        setSelectedOptions(value);
    }

    return (
        <Autocomplete
            open={open}
            loading={loading}
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}
            onInputChange={handleOnInputChange}
            onChange={handleOnChange}
            options={options}
            multiple={multiSelect}
            getOptionDisabled={option => {
                if (multiSelect) {
                    return selectedOptions.filter((opt: any) => opt[valueProp] === option[valueProp]).length > 0;
                } else {
                    return false;
                }
            }}
            getOptionLabel={(option) => option[textProp]}
            isOptionEqualToValue={(option, value) => option[valueProp] === value[valueProp]}
            renderOption={(props, option) => (
                <li {...props} key={option[valueProp]}>
                    {option[textProp]}
                </li>
            )}
            renderInput={(params) => (
                <>
                    <TextField
                        {...params}
                        label={label ?? (charNumRequired ? `Type at least ${charNumRequired} char(s) to search...` : `Type to search...`)}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            )
                        }}
                    />
                    <input name={name} type="hidden" value={optionValue ?? ""} />
                </>
            )}
        />
    );
}

export default observer(RkdSearchSelect);