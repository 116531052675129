import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import { ProjectCategory } from "../../app/config/enum";
import { useStore } from "../../app/stores/store";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import useQuery from "../../app/hook/useQuery";
import { observer } from "mobx-react-lite";

function ProjectMonitoringFormStepper({ current, projectCategory }: { current: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8; projectCategory: string }) {
  const { getStepBarPercentage, stepBarPercentage, stepBarPercentageLoading } = useStore().stepBarPercentageStore;
  const list = [
    { number: 1, text: "Usulan", percentage: stepBarPercentage?.usulan },
    { number: 2, text: "Reviewer", percentage: stepBarPercentage?.reviewer },
    { number: 3, text: "Decision Gate", percentage: stepBarPercentage?.decisionGate },
    { number: 4, text: projectCategory === ProjectCategory.NonBD ? "PS" : "FID", percentage: stepBarPercentage?.fid },
    { number: 5, text: "Perencanaan", percentage: stepBarPercentage?.preparation },
    { number: 6, text: "Procurement", percentage: stepBarPercentage?.procurement },
    { number: 7, text: "Execution", percentage: stepBarPercentage?.contruction }, // Construction
    { number: 8, text: "Project Closing", percentage: 8 },
  ];

  const query = useQuery();
  const id = query.get("id");

  const modifiedCurrent = () => {
    if (current === 5 || current === 6 || current === 7) return 7
    else return current
  }

  useEffect(() => {
    if (!id) return;
    getStepBarPercentage(id);
  }, [getStepBarPercentage, id]);

  return (
    <Stack direction="row" alignItems="center" gap={"7px"}>
      {list.map((i, x) => (
        <Fragment key={x}>
          <Item
            current={modifiedCurrent()}
            key={i.number}
            number={i.number}
            text={i.text}
            active={modifiedCurrent() > x}
            percentage={i.percentage ?? 0}
            stepBarPercentageLoading={stepBarPercentageLoading}
          />
          {x !== list.length - 1 && <Box height={"1px"} bgcolor="#BABABB" flex={1} />}
        </Fragment>
      ))}
    </Stack>
  );
}

export default observer(ProjectMonitoringFormStepper);

const Item = ({
  number,
  text,
  active,
  current,
  percentage,
  stepBarPercentageLoading,
}: {
  number: number;
  text: string;
  active?: boolean;
  current: number;
  percentage: number;
  stepBarPercentageLoading: boolean;
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding={number === current ? "15px 19px" : "15px"}
      bgcolor={active ? "white" : "#EAEAEA"}
      width="fit-content"
      borderRadius="100px"
      gap="7px"
      sx={{
        ".ProjectMonitoringFormStepper-text_active": {
          display: "",
          color: "#999999",
        },
        ":hover": {
          ".ProjectMonitoringFormStepper-text_active": {
            display: "block",
          },
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="38px"
        height="38px"
        bgcolor={({ palette }) => (active ? percentage > 110 ? palette.error.main : palette.primary.main : "#999999")}
        borderRadius="38px"
      >
        {stepBarPercentageLoading ? (
          <CircularProgress size="16px" sx={{ color: "white" }} />
        ) : (
          <Typography fontSize="12px" fontWeight={700} color="white">
            {number === 8 ? <EmojiFlagsIcon /> : `${percentage.toFixed(0)}%`}
          </Typography>
        )}
      </Box>
      <Typography
        className={number !== current ? "ProjectMonitoringFormStepper-text_active" : ""}
        textTransform="capitalize"
        color={active ? "black" : "#999999"}
        fontWeight={700}
        fontSize="14px"
      >
        {text}
      </Typography>
    </Box>
  );
};
