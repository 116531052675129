import { observer } from "mobx-react-lite";
import { Box, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { StyledDataGridPremium } from "../../../../../../app/component/StyledDataGridPremium";
import { useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useStore } from "../../../../../../app/stores/store";
import { format } from "date-fns";
import useQuery from "../../../../../../app/hook/useQuery";

function WbsTab() {
  const { getWbs, wbs, wbsLoading } = useStore().proposalStore;
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const query = useQuery();
  const id = query.get("id");
  const apiRef = useGridApiRef();

  const handleChangeYear = (year: string) => {
    setYear(format(new Date(year), "yyyy"));
  };

  useEffect(() => {
    if (!id) return;

    getWbs(id, year);
  }, [getWbs, id, year]);

  if (wbsLoading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={5}>
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <Typography sx={{ mt: "24px", mb: "24px" }} color="black" fontWeight={700} fontSize="20px">
        Work Breakdown Structure
      </Typography>
      <Stack mb={2} direction="row" justifyContent="flex-end">
        <Box width={"150px"}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Year"
              value={year}
              views={["year"]}
              inputFormat="yyyy"
              onChange={(e: any) => handleChangeYear(e)}
              renderInput={(props) => <TextField fullWidth name="startYear" {...props} />}
            />
          </LocalizationProvider>
        </Box>
      </Stack>
      <StyledDataGridPremium
        apiRef={apiRef}
        sx={{
          ".datagrid-hidden-header": { backgroundColor: "rgba(0, 0, 0, .02)" },
          ".datagrid-highlight-cell": { background: ({ palette }) => palette.primary.main + "33" },
        }}
        experimentalFeatures={{ columnGrouping: true }}
        disableRowSelectionOnClick
        initialState={{
          pinnedColumns: {
            left: ["code", "task"],
          },
        }}
        columns={[
          {
            field: "code",
            headerName: "Code WBS",
            sortable: false,
            disableColumnMenu: true,
            width: 150,
            pinnable: true,
          },
          {
            field: "task",
            headerName: "Task WBS",
            sortable: false,
            disableColumnMenu: true,
            width: 250,
            pinnable: true,
          },
          {
            field: "labels",
            headerName: "",
            sortable: false,
            disableColumnMenu: true,
            width: 150,
            renderCell: (params) => {
              return (
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "right" }}>Plan</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>Real</td>
                  </tr>
                </table>
              );
            },
          },
          {
            field: "totalTargetProgress",
            headerName: "Total Target Progress",
            sortable: false,
            disableColumnMenu: true,
            width: 200,
            headerClassName: "datagrid-highlight-cell",
            cellClassName: "datagrid-highlight-cell",
            renderCell: (params) => {
              return (
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.totalTargetProgress.plan.toFixed(3)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.totalTargetProgress.planActual.toFixed(3)}</td>
                  </tr>
                </table>
              );
            },
          },
          {
            field: "totalProgress",
            headerName: wbs?.header.totalProgress,
            sortable: false,
            disableColumnMenu: true,
            width: 200,
            headerClassName: "datagrid-highlight-cell",
            cellClassName: "datagrid-highlight-cell",
            renderCell: (params) => {
              return (
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.totalProgress.plan.toFixed(3)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.totalProgress.planActual.toFixed(3)}</td>
                  </tr>
                </table>
              );
            },
          },
          {
            field: "jan",
            headerName: "Jan (%)",
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            renderCell: (params) => {
              return (
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.jan.plan.toFixed(3)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.jan.planActual.toFixed(3)}</td>
                  </tr>
                </table>
              );
            },
          },
          {
            field: "feb",
            headerName: "Feb (%)",
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            renderCell: (params) => {
              return (
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.feb.plan.toFixed(3)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.feb.planActual.toFixed(3)}</td>
                  </tr>
                </table>
              );
            },
          },
          {
            field: "mar",
            headerName: "Mar (%)",
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            renderCell: (params) => {
              return (
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.mar.plan.toFixed(3)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.mar.planActual.toFixed(3)}</td>
                  </tr>
                </table>
              );
            },
          },
          {
            field: "apr",
            headerName: "Apr (%)",
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            renderCell: (params) => {
              return (
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.apr.plan.toFixed(3)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.apr.planActual.toFixed(3)}</td>
                  </tr>
                </table>
              );
            },
          },
          {
            field: "mei",
            headerName: "Mei (%)",
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            renderCell: (params) => {
              return (
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.mei.plan.toFixed(3)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.mei.planActual.toFixed(3)}</td>
                  </tr>
                </table>
              );
            },
          },
          {
            field: "jun",
            headerName: "Jun (%)",
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            renderCell: (params) => {
              return (
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.jun.plan.toFixed(3)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.jun.planActual.toFixed(3)}</td>
                  </tr>
                </table>
              );
            },
          },
          {
            field: "jul",
            headerName: "Jul (%)",
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            renderCell: (params) => {
              return (
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.jul.plan.toFixed(3)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.jul.planActual.toFixed(3)}</td>
                  </tr>
                </table>
              );
            },
          },
          {
            field: "agu",
            headerName: "Agu (%)",
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            renderCell: (params) => {
              return (
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.agu.plan.toFixed(3)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.agu.planActual.toFixed(3)}</td>
                  </tr>
                </table>
              );
            },
          },
          {
            field: "sep",
            headerName: "Sep (%)",
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            renderCell: (params) => {
              return (
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.sep.plan.toFixed(3)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.sep.planActual.toFixed(3)}</td>
                  </tr>
                </table>
              );
            },
          },
          {
            field: "okt",
            headerName: "Okt (%)",
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            renderCell: (params) => {
              return (
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.okt.plan.toFixed(3)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.okt.planActual.toFixed(3)}</td>
                  </tr>
                </table>
              );
            },
          },
          {
            field: "nov",
            headerName: "Nov (%)",
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            renderCell: (params) => {
              return (
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.nov.plan.toFixed(3)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.nov.planActual.toFixed(3)}</td>
                  </tr>
                </table>
              );
            },
          },
          {
            field: "des",
            headerName: "Des (%)",
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            renderCell: (params) => {
              return (
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.des.plan.toFixed(3)}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }}>{params.row.des.planActual.toFixed(3)}</td>
                  </tr>
                </table>
              );
            },
          },
        ]}
        columnGroupingModel={[
          {
            groupId: "Code WBS",
            headerName: "",
            headerClassName: "datagrid-hidden-header",
            children: [{ field: "code" }],
          },
          {
            groupId: "Task WBS",
            headerName: "",
            headerClassName: "datagrid-hidden-header",
            children: [{ field: "task" }, { field: "labels" }],
          },
          {
            groupId: "Progress (%)",
            headerAlign: "center",
            children: [{ field: "totalTargetProgress" }, { field: "totalProgress" }],
          },
          {
            groupId: "Month",
            headerName: "",
            headerClassName: "datagrid-hidden-header",
            children: [
              { field: "jan" },
              { field: "feb" },
              { field: "mar" },
              { field: "apr" },
              { field: "mei" },
              { field: "jun" },
              { field: "jul" },
              { field: "agu" },
              { field: "sep" },
              { field: "okt" },
              { field: "nov" },
              { field: "des" },
            ],
          },
        ]}
        rows={wbs?.data ?? []}
        autoHeight
      />
    </>
  );
}

export default observer(WbsTab);
