import { TGeneralLog, TGeneralLogQueryParams } from "../models/generalLog";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { DataGridResult } from "../models/materialUI/dataGrid";
import agent from "../api/agent";

export class GeneralLogStore {
  generalLogGrid: DataGridResult<TGeneralLog[]> = new DataGridResult([], 0);
  generalLogGridLoading = false;

  queryparams: TGeneralLogQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: "",
    startDate: null,
    endDate: null,
  };

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.queryparams,
      () => {
        this.getGeneralLogGrid();
      }
    );
  }

  getGeneralLogGrid = async () => {
    this.generalLogGridLoading = true;
    try {
      const res = await agent.Master.getGeneralLogGrid(this.queryparams);

      runInAction(() => (this.generalLogGrid = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.generalLogGridLoading = false));
    }
  };

  setQueryParams = (query: "pageSize" | "search" | "currentPage" | "startDate" | "endDate", v: string | number | null | Date) => {
    this.queryparams = {
      ...this.queryparams,
      [query]: v,
    };
  };
}
