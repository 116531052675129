import { LinearProgress } from "@mui/material";
import { Box } from "@mui/system";

type TProgress = {
  data: number | string;
  max?: number | string;
};

export default function Progress({ data, max }: TProgress) {
  return (
    <Box sx={{ width: "120px", textAlign: "center" }}>
      <LinearProgress sx={{}} variant="determinate" value={max ? (Number(data) * 100) / Number(max) : Number(data)} />
      <span style={{ fontSize: "12px" }}>{data}%</span>
    </Box>
  );
}

// value={(data.data * 100) / 15}
