import { Chip, styled } from "@mui/material";

export const StyledChip = styled(Chip)<{ color?: 'primary' | 'error' }>(({ color = 'primary' }) => {
  let rest = {}

  switch (color) {
    case 'primary': {
      rest = ({
        backgroundColor: '#F2F9FF',
        color: '#0664B4',
        '&.MuiChip-clickable': {
          ':hover': {
            backgroundColor: '#D6ECFF !important',
          }
        }
      })
      break;
    }
    case 'error': {
      rest = ({
        backgroundColor: '#FBE8E9',
          color: '#DB1A22',
          '&.MuiChip-clickable': {
            ':hover': {
              backgroundColor: '#F7D4D5'
            }
          }
      })
      break;
    }
    default: rest = {}
  }

  
  return {
    '&': {
      padding: '6px 12px',
      borderRadius: '8px',
      fontWeight: 500,
      ...rest,
    },
  }
})