import { useEffect } from "react";
import DashboardLayoutHeader from "../../../app/component/layout/DashboardLayoutHeader";
import { Grid, Paper, TextField, Box, Typography, Stack } from "@mui/material";
import { ArrowBackIos, MapRounded } from "@mui/icons-material";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { Link } from "react-router-dom";
import CSelect from "../../../app/component/CSelect";
import PageButton from "../components/PageButton";
import LoadingSection from "../components/LoadingSection";
// import CommentSection from "../components/CommentSection";
import useQuery from "../../../app/hook/useQuery";

function Land() {
  const { proposalStore, landStore } = useStore();
  const { getLand, land, landLoading, getGeneralInformation, generalInformation } = proposalStore;
  const { getDropdownLand, dropdownLand, dropdownLandLoading } = landStore;
  const { id } = useParams();
  const query = useQuery();
  const status = query.get("status");

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
    getDropdownLand().then(() => getLand(id));
  }, [getDropdownLand, getGeneralInformation, getLand, id]);

  const regulation = dropdownLand.find((i) => i.id === (land && land[0]?.landId))?.regulations[0];

  return (
    <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Approval",
            href: "/project-monitoring/approval/list",
          },
          {
            label: "Approval List",
            href: "/project-monitoring/approval/list",
          },
          {
            label: `${generalInformation?.title}`,
          },
        ]}
        pageTitle="Project Monitoring"
      />

      <Paper sx={{ p: "16px" }}>
        <Link
          style={{ color: "#000", textDecoration: "none", display: "flex", alignItems: "center", gap: "3px", marginBottom: "24px" }}
          to={`/project-monitoring/approval/list/${id}?status=${status}`}
        >
          <ArrowBackIos sx={{ fontSize: "14px" }} />
          <Box>Back</Box>
        </Link>
        <Box
          sx={{
            mb: "12px",
            backgroundColor: "#F2F9FF",
            display: "flex",
            p: "16px",
            alignItems: "center",
            gap: "6px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <MapRounded sx={{ mr: "12px", color: "#0664B4", fontSize: "34px" }} />
            <Box sx={{ fontWeight: "bold" }}>Lahan</Box>
          </Box>
          {/* {landLoading ? "Loading..." : <CommentSection typeId={land?.length ? land[0]?.id : ""} />} */}
        </Box>

        {dropdownLandLoading || landLoading ? (
          <LoadingSection />
        ) : (
          <>
            {land && land.length > 0 ? (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Stack flex={1} overflow="auto" pt="12px" spacing="24px">
                    <CSelect
                      label="Land"
                      options={dropdownLand.map((i) => ({ label: `${i.noAsset}${i.address ? ` - ${i.address}` : ""}`, value: i.id }))}
                      name="landId"
                      value={land && land[0]?.landId}
                      disabled
                    />
                    <TextField label="Land Area" value={dropdownLand.find((i) => i.id === (land && land[0].landId))?.landArea} disabled />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack flex={1} overflow="auto" pt="12px" spacing="24px">
                    <Typography color="black" fontSize="20px" fontWeight={700}>
                      Regulation
                    </Typography>
                    <Stack direction="row" spacing={"24px"}>
                      <TextField
                        fullWidth
                        label="KDB"
                        disabled
                        InputLabelProps={{
                          shrink: !!regulation?.kdb,
                        }}
                        value={regulation?.kdb}
                      />
                      <TextField
                        fullWidth
                        label="KLB"
                        disabled
                        InputLabelProps={{
                          shrink: !!regulation?.klb,
                        }}
                        value={regulation?.klb}
                      />
                    </Stack>
                    <Stack direction="row" spacing={"24px"}>
                      <TextField
                        fullWidth
                        label="GSB"
                        disabled
                        InputLabelProps={{
                          shrink: !!regulation?.gbs,
                        }}
                        value={regulation?.gbs}
                      />
                      <TextField
                        fullWidth
                        label="KDH"
                        disabled
                        InputLabelProps={{
                          shrink: !!regulation?.kdh,
                        }}
                        value={regulation?.kdh}
                      />
                    </Stack>
                    <TextField
                      fullWidth
                      label="Building Height"
                      disabled
                      InputProps={{
                        endAdornment: <Typography color="black">Meter</Typography>,
                      }}
                      InputLabelProps={{
                        shrink: !!regulation?.buildingHeight,
                      }}
                      value={regulation?.buildingHeight}
                    />
                  </Stack>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </>
        )}

        <PageButton
          isPrevButton
          isNextButton
          prevButtonRoute={`/project-monitoring/approval/list/${id}/generalInformation?status=${status}`}
          nextButtonRoute={`/project-monitoring/approval/list/${id}/productAnalysis?status=${status}`}
        />
      </Paper>
      <Box sx={{ py: "16px" }} />
    </Box>
  );
}

export default observer(Land);
