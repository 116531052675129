import { Box, IconButton } from "@mui/material";
import { useStore } from "../../app/stores/store";
import { StyledDataGridPremium } from "../../app/component/StyledDataGridPremium";
import { ProjectStatus } from "../../app/config/enum";
import { Info } from "@mui/icons-material";
import DynamicBarShowChartDialog from "./components/DynamicBarShowChartDialog";
import { useState } from "react";

export default function GridProject() {
  const { dashboardRecentProject, dashboardRecentProjectLoading } = useStore().DashboardStore;
  const [openChartDialog, setOpenChartDialog] = useState(false);
  const [selectedProposalId, setSelectedProposalId] = useState("");
  const disabledSktlRedirect = [ProjectStatus.Preparation, ProjectStatus.ProjectClosing];

  return (
    <Box>
      <StyledDataGridPremium
        getRowId={(row) => row.proposalId}
        autoHeight
        columns={[
          {
            field: "title",
            headerName: "Project Name",
            width: 250,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: "projectCategoryName",
            headerName: "Category",
            width: 200,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: "regionalName",
            headerName: "Unit Operasi",
            width: 150,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: "organization",
            headerName: "Organization",
            width: 150,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: "projectStatusName",
            headerName: "Project Status",
            width: 150,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: "cost",
            headerName: "Cost (Rp)",
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            align: "right",
            renderCell: (params) => params.row.cost.toLocaleString(),
          },
          {
            field: "realizationCost",
            headerName: "Realisasi Cost (Rp)",
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            align: "right",
            renderCell: (params) => params.row.realizationCost.toLocaleString(),
          },
          {
            field: "realizationProgress",
            headerName: "Realisasi Progress (%)",
            width: 180,
            sortable: false,
            disableColumnMenu: true,
            align: "right",
            renderCell: (params) => params.row.realizationProgress.toLocaleString(),
          },
          {
            field: "spi",
            headerName: "SPI (%)",
            width: 180,
            sortable: false,
            disableColumnMenu: true,
            align: "right",
            renderCell: (params) => params.row.spi.toLocaleString(),
          },
          {
            field: "cpi",
            headerName: "CPI (%)",
            width: 180,
            sortable: false,
            disableColumnMenu: true,
            align: "right",
            renderCell: (params) => params.row.cpi.toLocaleString(),
          },
          {
            field: "sktl",
            headerName: "SKTL",
            width: 60,
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <IconButton
                disabled={!disabledSktlRedirect.includes(params.row.projectStatus)}
                onClick={
                  () => {
                    setSelectedProposalId(params.row.proposalId);
                    setOpenChartDialog(true);
                  }
                  // history.push(
                  //   `/project-monitoring/proposal/list/form?projectCategory=${params.row.projectCategory}&id=${params.row.proposalId}&status=${
                  //     params.row.projectStatus
                  //   }&reFID=${0}&fromPage=dashboard`
                  // )
                }
              >
                <Info sx={{ cursor: "pointer" }} />
              </IconButton>
            ),
          },
        ]}
        rows={dashboardRecentProject ?? []}
        loading={dashboardRecentProjectLoading}
      />
      {openChartDialog ? (
        <DynamicBarShowChartDialog proposalId={selectedProposalId} openChartDialog={openChartDialog} setOpenChartDialog={setOpenChartDialog} />
      ) : undefined}
    </Box>
  );
}
