import { IconButton, Paper, Stack } from "@mui/material";
import { StyledDataGrid } from "../../app/component/StyledDataGrid";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Delete, Edit } from "@mui/icons-material";

function UnitBusinessDataFrid() {
  const { unitBusinessStore, dialogStore } = useStore();
  const { getUnitBusinessGrid, queryParams, setQueryParams, unitBusinessGridLoading, setSelectedUnitBusiness, deleteUnitBusiness, unitBusinessGrid } =
    unitBusinessStore;

  const onDelete = (id: string) =>
    dialogStore.open({
      action: async () => await deleteUnitBusiness(id).then(getUnitBusinessGrid),
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      actionButtonProps: {
        color: "error",
      },
      description: "Are you sure want to delete this item?",
    });

  useEffect(() => {
    getUnitBusinessGrid();
  }, [getUnitBusinessGrid]);

  return (
    <Paper sx={{ p: "16px" }}>
      <StyledDataGrid
        // checkboxSelection
        loading={unitBusinessGridLoading}
        rows={unitBusinessGrid.data}
        rowCount={unitBusinessGrid.rowCount}
        pagination
        onPageChange={(v) => setQueryParams("currentPage", v)}
        onPageSizeChange={(v) => setQueryParams("pageSize", v)}
        paginationMode="server"
        pageSize={queryParams.pageSize}
        page={queryParams.currentPage}
        columns={[
          {
            field: "name",
            headerName: "Name",
            flex: 1,
          },
          {
            field: "unitBusinessCategoryName",
            headerName: "Business Category",
            flex: 1,
          },
          {
            field: "category",
            headerName: "Project Category",
            flex: 1,
          },
          {
            field: "action",
            headerName: "Action",
            width: 100,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            resizable: false,
            renderCell: (params) => (
              <Stack direction="row" spacing="8px">
                <IconButton size="small" onClick={() => setSelectedUnitBusiness(params.row as any)}>
                  <Edit />
                </IconButton>
                <IconButton size="small" onClick={() => onDelete(params.row.id)}>
                  <Delete />
                </IconButton>
              </Stack>
            ),
          },
        ]}
        disableSelectionOnClick
        autoHeight
      />
    </Paper>
  );
}

export default observer(UnitBusinessDataFrid);
