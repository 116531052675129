import { makeAutoObservable, reaction, runInAction } from "mobx";
import { TPeruntukan, TPeruntukanBody, TPeruntukanGrid, TPeruntukanQueryParams } from "../models/peruntukan";
import { DataGridResult } from "../models/materialUI/dataGrid";
import agent from "../api/agent";

export class PeruntukanStore {
  peruntukanGrid: DataGridResult<TPeruntukanGrid[]> = new DataGridResult<TPeruntukanGrid[]>([], 0);
  peruntukanGridLoading = false;
  queryParams: TPeruntukanQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: "",
  };

  peruntukan: TPeruntukan[] = [];
  peruntukanLoading = false;

  selectedPeruntukan: TPeruntukan | null = null;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.queryParams,
      () => {
        this.getPeruntukanGrid();
      }
    );
  }

  getPeruntukanGrid = async () => {
    this.peruntukanGridLoading = true;

    try {
      const res = await agent.Master.getPeruntukanGrid(this.queryParams);

      runInAction(() => (this.peruntukanGrid = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.peruntukanGridLoading = false));
    }
  };

  getPeruntukan = async () => {
    this.peruntukanLoading = true;
    try {
      const res = await agent.Master.getPeruntukan();

      runInAction(() => (this.peruntukan = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.peruntukanLoading = false));
    }
  };

  addPeruntukan = async (body: TPeruntukanBody) => {
    try {
      await agent.Master.addPeruntukan(body);
    } catch (e) {
      throw e;
    }
  };

  editPeruntukan = async (id: string, body: TPeruntukanBody) => {
    try {
      await agent.Master.editPeruntukan(id, body);
    } catch (e) {
      throw e;
    }
  };

  setSelectedPeruntukan = (data: TPeruntukan | null) => {
    this.selectedPeruntukan = data;
  };

  deletePeruntukan = async (id: string) => {
    try {
      await agent.Master.deletePeruntukan(id);
    } catch (e) {
      throw e;
    }
  };

  setQueryParams = (query: "pageSize" | "search" | "currentPage", v: string | number) => {
    this.queryParams = {
      ...this.queryParams,
      [query]: v,
    };
  };
}
