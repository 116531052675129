import { makeAutoObservable, runInAction } from "mobx";
import { IRegional } from "../models/regional";
import agent from "../api/agent";
import { IUnitBusiness } from "../models/unitBusiness";
import { ISubHolding } from "../models/subHoldings";
import { IProjectType } from "../models/projectType";
import { INotification, Notification } from "../models/notification";

export class GeneralStore {
  regionals: IRegional[] = [];
  regionalsLoading = false;

  unitBusiness: IUnitBusiness[] = [];
  unitBusinessLoading = false;

  unitBusinessByOrganization: IUnitBusiness[] = [];
  unitBusinessByOrganizationLoading = false;

  subHoldings: ISubHolding[] = [];
  subHoldingsLoading = false;

  projectTypes: IProjectType[] = [];
  projectTypesLoading = false;

  notification: INotification = new Notification();
  notificationLoading = false;

  pushNotification: INotification | null = null;
  displayBadgeNotification = true;

  constructor() {
    makeAutoObservable(this);
  }

  getRegionals = async () => {
    this.regionalsLoading = true;
    try {
      const result = await agent.General.getRegional();

      runInAction(() => (this.regionals = result));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.regionalsLoading = false));
    }
  };

  get regionalOptions() {
    return this.regionals.map((i) => ({ label: i.name, value: i.id }));
  }

  getUnitBusiness = async (params?: { categoryUnitBusinessId: string | null; category?: string }) => {
    this.unitBusinessLoading = true;
    try {
      const res = await agent.General.getUnitBusiness(params);

      runInAction(() => (this.unitBusiness = res));

      return res;
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.unitBusinessLoading = false));
    }
  };

  getUnitBusinessByOrganization = async (params?: { organizationId: string | null; category?: string }) => {
    this.unitBusinessByOrganizationLoading = true;
    try {
      const res = await agent.General.getUnitBusinessByOrganization(params);

      runInAction(() => (this.unitBusinessByOrganization = res));

      return res;
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.unitBusinessByOrganizationLoading = false));
    }
  };

  get unitBusinessOptions() {
    return this.unitBusiness.map((i) => ({ label: i.name, value: i.id }));
  }

  getSubHoldings = async () => {
    this.subHoldingsLoading = true;
    try {
      const res = await agent.General.getSubHoldings();

      runInAction(() => (this.subHoldings = res));

      return res;
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.subHoldingsLoading = false));
    }
  };

  get subHoldingOptions() {
    return this.subHoldings.map((i) => ({ label: i.name, value: i.id }));
  }

  getProjectTypes = async (category?: string) => {
    this.projectTypesLoading = true;
    try {
      const res = await agent.General.getProjectType(category);

      runInAction(() => (this.projectTypes = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.projectTypesLoading = false));
    }
  };

  get projectTypeOptions() {
    return this.projectTypes.map((i) => ({ label: i.name, value: i.id }));
  }

  getNotification = async () => {
    this.notificationLoading = true;
    try {
      const res = await agent.General.getNotification();

      return res;
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.notificationLoading = false));
    }
  };

  setDisplayBadgeNotification = (data: boolean) => {
    this.displayBadgeNotification = data;
  };

  setNotification = (data: INotification) => {
    this.notification = data;
  };
}
