import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { TConsolidationCost } from "../../../../../../app/models/consolidation";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../../app/stores/store";
import { format } from "date-fns";

function CostConsolidation() {
  const { costConsolidation } = useStore().consolidationStore;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableCell sx={{ fontWeight: 700 }}></TableCell>
          <TableCell sx={{ fontWeight: 700 }}>
            <TableRow sx={{ display: "flex", justifyContent: "center" }}>
              <Box sx={{ justifyContent: "center" }}>Perencanaan</Box>
            </TableRow>
            <TableRow sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <TableCell sx={{ borderBottom: "none" }}>Start</TableCell>
              <TableCell sx={{ borderBottom: "none" }}>End</TableCell>
            </TableRow>
          </TableCell>
          <TableCell sx={{ fontWeight: 700 }}>FID</TableCell>
          <TableCell sx={{ fontWeight: 700 }}>
            <Box sx={{ display: "center", justifyContent: "center" }}>Date Realisasi</Box>
          </TableCell>
          <TableCell sx={{ fontWeight: 700 }}>Realisasi</TableCell>
        </TableHead>
        {costConsolidation.map((item: TConsolidationCost) => (
          <TableBody>
            <TableRow>
              <TableCell>
                <Box
                  sx={{
                    width: "150px",
                    fontWeight: item.type !== "SubTask" ? "bold" : "",
                    color: item.type === "Task" ? "#494949" : item.type === "SubTask" ? "" : "black",
                    paddingLeft: item.type === "Task" ? "24px" : item.type === "SubTask" ? "48px" : "",
                  }}
                >
                  {item.name}
                </Box>
              </TableCell>
              <TableCell sx={{ fontWeight: 700 }}>
                <TableRow sx={{ display: "flex", justifyContent: "space-evenly" }}>
                  <TableCell sx={{ borderBottom: "none" }}>{item.startDate ? format(new Date(item.startDate), "MMM yyyy") : "-"}</TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>{item.endDate ? format(new Date(item.endDate), "MMM yyyy") : "-"}</TableCell>
                </TableRow>
              </TableCell>
              <TableCell>Rp{item.fid.toLocaleString()}</TableCell>
              <TableCell>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {item.dateRealisasi ? format(new Date(item.dateRealisasi), "MMM yyyy") : "-"}
                </Box>
              </TableCell>
              <TableCell>Rp{item.realisasi.toLocaleString()}</TableCell>
            </TableRow>
          </TableBody>
        ))}
      </Table>
    </TableContainer>
  );
}

export default observer(CostConsolidation);
