import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

type TPageButtonProps = {
  isNextButton?: boolean;
  nextButtonRoute?: string;
  isPrevButton?: boolean;
  prevButtonRoute?: string;
};

export default function PageButton({ isNextButton, nextButtonRoute = "", isPrevButton, prevButtonRoute = "" }: TPageButtonProps) {
  return (
    <Box sx={{ my: "24px", display: "flex", justifyContent: "space-between" }}>
      {isPrevButton ? (
        <Link style={{ textDecoration: "none" }} to={prevButtonRoute}>
          <Button variant="contained" startIcon={<KeyboardArrowLeft />}>
            Previous
          </Button>
        </Link>
      ) : (
        <Box></Box>
      )}

      {isNextButton ? (
        <Link style={{ textDecoration: "none" }} to={nextButtonRoute}>
          <Button variant="contained" endIcon={<KeyboardArrowRight />}>
            Next
          </Button>
        </Link>
      ) : (
        <Box></Box>
      )}
    </Box>
  );
}
