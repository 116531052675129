import React, { useEffect, useState } from "react";
import { differentStyle, spiCpiGridHelper } from "../../../../../../app/utils/utils";
import { StyledDataGridPremium } from "../../../../../../app/component/StyledDataGridPremium";
import { NO_SUBTASK_ID } from "../../../../../../app/config/enum";
import { useStore } from "../../../../../../app/stores/store";
import { Box, Typography } from "@mui/material";
import SCurveCostPerformanceIndex from "./SCurveCostPerformanceIndex";

export default function CostPerformanceIndexTab() {
  const { cpi } = useStore().proposalStore;
  const [gridColumnHelper, setGridColumnHelper] = useState<any[]>([]);
  const [gridGroupingHelper, setGridGroupingHelper] = useState<any>([]);
  const [gridGroupingNewData, setGridGroupingNewData] = useState<any>([]);

  useEffect(() => {
    if (!cpi || cpi.data.length === 0) return;

    const { tempColumnHelper, tempGroupingHelper, tempGroupingNewData } = spiCpiGridHelper(cpi.data, true);

    setGridColumnHelper(tempColumnHelper);
    setGridGroupingHelper(tempGroupingHelper);
    setGridGroupingNewData(tempGroupingNewData);
  }, [cpi]);

  return (
    <Box>
      <Typography sx={{ mt: "24px", mb: "24px" }} color="black" fontWeight={700} fontSize="20px">
        S-Curve
      </Typography>
      <SCurveCostPerformanceIndex />
      <Typography sx={{ mt: "24px", mb: "24px" }} color="black" fontWeight={700} fontSize="20px">
        Grid Data
      </Typography>
      <StyledDataGridPremium
        getRowId={(row) => row.itemName + row.bobot}
        sx={{
          ".datagrid-hidden-header": { backgroundColor: "rgba(0, 0, 0, .02)" },
          ".datagrid-highlight-general": { background: ({ palette }) => palette.primary.main + "33" },
          ".datagrid-highlight-cell": { background: "#CAE1BC" },
          ".datagrid-highlight-year-cell": { background: "#FDE4D5" },
          ".datagrid-actual": { background: "#FEF3CC" },
          ".datagrid-delta-green": { background: "#ECF7E7" },
          ".datagrid-delta-yellow": { background: "#FEF3CC" },
          ".datagrid-delta-red": { background: "#FBE4D7" },
        }}
        getCellClassName={(params: any) => {
          if (params.value > 0 && params.field !== "bobot" && !differentStyle.includes(params.row.itemName)) return "datagrid-highlight-general";

          if (params.row.itemName === "CPI") {
            if (params.value > 1.05) {
              return "datagrid-highlight-general";
            }
            if (params.value >= 0.9 && params.value <= 1.05) {
              return "datagrid-delta-green";
            }
            if (params.value < 0.9 && params.field !== "bobot") {
              return "datagrid-delta-red";
            }
          }

          if (params.row.itemName === "Delta Progress") {
            if (params.value > 0) {
              return "datagrid-delta-green";
            }
            if (params.value < 0) {
              return "datagrid-delta-red";
            }
            if (params.value === 0 && params.field !== "bobot") {
              return "datagrid-delta-yellow";
            }
          }

          if (
            (params.row.itemName === "Progress Aktual Kumulatif" || params.row.itemName === "Progress Aktual Bulanan") &&
            (params.value > 0 || params.value === 0) &&
            params.field !== "bobot"
          ) {
            return "datagrid-actual";
          }
          return "";
        }}
        experimentalFeatures={{ columnGrouping: true }}
        disableRowSelectionOnClick
        initialState={{
          pinnedColumns: {
            left: ["itemName", "bobot"],
          },
        }}
        columns={[
          {
            field: "itemName",
            headerName: "Item",
            sortable: false,
            headerClassName: "datagrid-highlight-year-cell",
            disableColumnMenu: true,
            width: 250,
            pinnable: true,
            renderCell: (params) => (
              <Box
                sx={{
                  fontWeight: params.row.isMilestones || params.row.id === NO_SUBTASK_ID ? "bold" : "",
                  pl: params.row.isMilestones ? "" : "24px",
                }}
              >
                {params.row.itemName}
              </Box>
            ),
          },
          {
            field: "bobot",
            headerName: "Total",
            sortable: false,
            headerClassName: "datagrid-highlight-year-cell",
            disableColumnMenu: true,
            width: 120,
            pinnable: true,
            renderCell: (params) => (
              <Box>
                {params.row.id === NO_SUBTASK_ID || params.row.isMilestones
                  ? " "
                  : `${params.row.bobot || params.row.bobot >= 0 ? "Rp" : ""}${Number(params.row.bobot.toFixed(0)).toLocaleString()}`}
              </Box>
            ),
          },
          ...gridColumnHelper,
        ]}
        columnGroupingModel={gridGroupingHelper}
        rows={gridGroupingNewData ?? []}
        autoHeight
      />
    </Box>
  );
}
