import { makeAutoObservable, reaction, runInAction } from "mobx";
import { DataGridResult } from "../models/materialUI/dataGrid";
import {
  IFidMilestone,
  IFidMilestoneBody,
  IFidMilestoneQueryParams,
  IFidMilestoneSubTaskBody,
  IFidMilestoneTaskBody,
  IFidTask,
  PreviewDataExcel,
  TFinancialPlan,
  TFinancialPlanBody,
  TPhysicalPlan,
  TPhysicalPlanBody,
  TPreviewDataExcel,
  TTotalMilestonestasks,
} from "../models/fidMilestones";
import agent from "../api/agent";
import { IAccountInfo } from "../models/account";
import { Roles, TaskSubTaskStatus } from "../config/enum";
import { ITaskSubTaskAttachment, TTaskAttachmentSuperadmin } from "../models/taskSubTaskAttachment";
import { serialize } from "object-to-formdata";

export class MilestoneStore {
  financialPlan: TFinancialPlan[] = [];
  financialPlanLoading: boolean = false;
  selectedFinancialPlan: TFinancialPlan | null = null;

  physicalPlan: TPhysicalPlan[] = [];
  physicalPlanLoading: boolean = false;
  selectedPhysicalPlan: TPhysicalPlan | null = null;

  milestones: DataGridResult<IFidMilestone[]> = new DataGridResult([], 0);
  milestonesLoading = false;

  taskSubTask: DataGridResult<IFidTask[]> = new DataGridResult([], 0);
  taskSubTaskLoading = false;

  totalMilestonestasks: TTotalMilestonestasks | null = null;
  totalMilestonestasksLoading: boolean = false;

  deleteTaskLoading = false;

  templateIdentifier: string | null = null;

  queryParams: IFidMilestoneQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: "",
  };

  taskAttachment: ITaskSubTaskAttachment[] = [];
  taskAttachmentLoading: boolean = false;

  taskSubTaskAttachment: ITaskSubTaskAttachment[] = [];
  taskSubTaskAttachmentLoading: boolean = false;

  taskAttachmentSuperAdmin: TTaskAttachmentSuperadmin[] = [];
  taskAttachmentSuperadminLoading = false;

  taskPlanningLoading: boolean = false;

  loadingDownloadTemplate: boolean = false;
  loadingUploadProduct: boolean = false;

  previewDataExcel: TPreviewDataExcel | null = null;

  selectedAmandement: ITaskSubTaskAttachment | null = null;

  isGagalTender: boolean = false;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.queryParams,
      () => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get("id");

        if (!id) return;

        this.getMilestone(id);
      }
    );
  }

  addAmandement = async (body: any) => {
    try {
      return await agent.FID.addAmandement(body);
    } catch (e) {
      throw e;
    }
  };

  addPhysicalPlan = async (body: TPhysicalPlanBody) => {
    try {
      return await agent.FID.addPhysicalPlan(body);
    } catch (e) {
      throw e;
    }
  };

  editPhysicalPlan = async (financialPlanId: string, body: TPhysicalPlanBody) => {
    try {
      return await agent.FID.editPhysicalPlan(financialPlanId, body);
    } catch (e) {
      throw e;
    }
  };

  deletePhysicalPlan = async (physicalPlanId: string) => {
    try {
      return await agent.FID.deletePhysicalPlan(physicalPlanId);
    } catch (e) {
      throw e;
    }
  };

  setSelectedPhysicalPlan = (data: TPhysicalPlan | null) => {
    this.selectedPhysicalPlan = data;
  };

  getPhysicalPlan = async (taskId: string, subTaskId?: string) => {
    this.physicalPlanLoading = true;

    try {
      const res = await agent.FID.getPhysicalPlan(taskId, subTaskId);

      runInAction(() => (this.physicalPlan = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.physicalPlanLoading = false));
    }
  };

  addFinancialPlan = async (body: TFinancialPlanBody) => {
    try {
      return await agent.FID.addFinancialPlan(body);
    } catch (e) {
      throw e;
    }
  };

  editFinancialPlan = async (financialPlanId: string, body: TFinancialPlanBody) => {
    try {
      return await agent.FID.editFinancialPlan(financialPlanId, body);
    } catch (e) {
      throw e;
    }
  };

  deleteFinancialPlan = async (financialPlanId: string) => {
    try {
      return await agent.FID.deleteFinancialPlan(financialPlanId);
    } catch (e) {
      throw e;
    }
  };

  setSelectedFinancialPlan = (data: TFinancialPlan | null) => {
    this.selectedFinancialPlan = data;
  };

  getFinancialPlan = async (taskId: string, subTaskId?: string) => {
    this.financialPlanLoading = true;

    try {
      const res = await agent.FID.getFinancialPlan(taskId, subTaskId);

      runInAction(() => (this.financialPlan = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.financialPlanLoading = false));
    }
  };

  getTotalMilestonestasks = async (proposalId: string) => {
    this.totalMilestonestasksLoading = true;
    try {
      const res = await agent.FID.getTotalMilestonestasks(proposalId);

      runInAction(() => (this.totalMilestonestasks = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.totalMilestonestasksLoading = false));
    }
  };

  downloadTemplateMilestones = async (proposalId: string) => {
    this.loadingDownloadTemplate = true;

    try {
      await agent.FID.downloadTemplateMilestones(proposalId);

      runInAction(() => (this.templateIdentifier = "milestones"));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDownloadTemplate = false));
    }
  };

  downloadTemplatePlan = async (proposalId: string) => {
    this.loadingDownloadTemplate = true;

    try {
      await agent.FID.downloadTemplatePlan(proposalId);

      runInAction(() => (this.templateIdentifier = "plan"));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDownloadTemplate = false));
    }
  };

  taskPlanning = async (proposalId: string, body: PreviewDataExcel[]) => {
    this.taskPlanningLoading = true;
    try {
      return await agent.FID.taskPlanning(proposalId, body);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.taskPlanningLoading = false));
    }
  };

  saveImportPlan = async (proposalId: string, body: PreviewDataExcel[]) => {
    this.taskPlanningLoading = true;
    try {
      return await agent.FID.saveImportPlan(proposalId, body);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.taskPlanningLoading = false));
    }
  };

  saveImportMilestones = async (proposalId: string, body: PreviewDataExcel[]) => {
    this.taskPlanningLoading = true;
    try {
      return await agent.FID.saveImportMilestones(proposalId, body);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.taskPlanningLoading = false));
    }
  };

  uploadExcelMilestones = async (file: File, proposalId: string) => {
    this.loadingUploadProduct = true;

    try {
      const data = { file: file };
      const formData = serialize(data, { indices: true, dotsForObjectNotation: true });
      const res = await agent.FID.uploadExcelMilestones(formData, proposalId);

      runInAction(() => (this.previewDataExcel = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.loadingUploadProduct = false));
    }
  };

  uploadExcelPlan = async (file: File, proposalId: string) => {
    this.loadingUploadProduct = true;

    try {
      const data = { file: file };
      const formData = serialize(data, { indices: true, dotsForObjectNotation: true });
      const res = await agent.FID.uploadExcelPlan(formData, proposalId);

      runInAction(() => (this.previewDataExcel = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.loadingUploadProduct = false));
    }
  };

  getMilestone = async (proposalId: string) => {
    this.milestonesLoading = true;
    try {
      const res = await agent.FID.getMilestonesGrid(proposalId, this.queryParams);

      runInAction(() => (this.milestones = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.milestonesLoading = false));
    }
  };

  getTaskSubTaskGrid = async (proposalId: string, account: IAccountInfo, projectOwner?: string) => {
    this.taskSubTaskLoading = true;
    let organizationIdHelper = account.displayName === projectOwner || account.displayName === Roles.Superadmin ? "" : account.organizationId;

    try {
      const res = await agent.FID.getTaskSubTaskGrid(proposalId, this.queryParams, organizationIdHelper);

      runInAction(() => (this.taskSubTask = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.taskSubTaskLoading = false));
    }
  };

  addMilestone = async (proposalId: string, body: IFidMilestoneBody) => {
    try {
      return await agent.FID.addMilestones(proposalId, body);
    } catch (e) {
      throw e;
    }
  };

  deleteMilestones = async (proposalId: string, milestoneId: string) => {
    try {
      return await agent.FID.deleteMilestones(proposalId, milestoneId);
    } catch (e) {
      throw e;
    }
  };

  // CRUD TASK SUPERADMIN
  getTaskAttachmentSuperadmin = async (taskId: string) => {
    this.taskAttachmentSuperadminLoading = true;
    try {
      const res = await agent.FID.getTaskAttachmentSuperadmin(taskId);

      return res;
      // runInAction(() => (this.taskAttachmentSuperAdmin = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.taskAttachmentSuperadminLoading = false));
    }
  };

  addTaskSuperadmin = async (body: any) => {
    try {
      return await agent.FID.addTaskSuperadmin(body);
    } catch (e) {
      throw e;
    }
  };

  editTaskSuperadmin = async (id: string, body: any) => {
    try {
      return await agent.FID.editTaskSuperadmin(id, body);
    } catch (e) {
      throw e;
    }
  };

  // CRUD TASK
  addTask = async (body: IFidMilestoneTaskBody) => {
    try {
      return await agent.FID.addTask(body);
    } catch (e) {
      throw e;
    }
  };
  editTask = async (id: string, body: IFidMilestoneTaskBody) => {
    try {
      return await agent.FID.editTask(id, body);
    } catch (e) {
      throw e;
    }
  };
  deleteTask = async (id: string) => {
    this.deleteTaskLoading = true;
    try {
      return await agent.FID.deleteTask(id);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.deleteTaskLoading = false));
    }
  };

  // Sub Task CRUD
  addSubTask = async (body: IFidMilestoneSubTaskBody) => {
    try {
      return await agent.FID.addSubTask(body);
    } catch (e) {
      throw e;
    }
  };

  editSubTask = async (id: string, body: IFidMilestoneSubTaskBody) => {
    try {
      return await agent.FID.editSubTask(id, body);
    } catch (e) {
      throw e;
    }
  };

  deleteSubTask = async (id: string) => {
    this.deleteTaskLoading = true;
    try {
      return await agent.FID.deleteSubTask(id);
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.deleteTaskLoading = false));
    }
  };

  getTaskSubTaskAttachment = async (proposalId: string, taskId: string, subTaskId: string) => {
    this.taskSubTaskAttachmentLoading = true;
    try {
      const res = await agent.FID.getSubtaskAttachment(proposalId, taskId, subTaskId);
      let helper = [...res];
      let gagalTender = helper.filter((item) => item.submittedOnStep === TaskSubTaskStatus.Lelang && item.isApproved === false);
      gagalTender.length > 0 ? (this.isGagalTender = true) : (this.isGagalTender = false);

      runInAction(() => (this.taskSubTaskAttachment = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.taskSubTaskAttachmentLoading = false));
    }
  };

  getTaskAttachment = async (proposalId: string, taskId: string) => {
    this.taskAttachmentLoading = true;
    try {
      const res = await agent.FID.getTaskAttachment(proposalId, taskId);
      let helper = [...res];
      let gagalTender = helper.filter((item) => item.submittedOnStep === TaskSubTaskStatus.Lelang && item.isApproved === false);
      gagalTender.length > 0 ? (this.isGagalTender = true) : (this.isGagalTender = false);

      runInAction(() => (this.taskSubTaskAttachment = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.taskAttachmentLoading = false));
    }
  };

  addTaskSubTaskAttachment = async (proposalId: string, body: any) => {
    try {
      await agent.FID.addTaskSubtaskAttachment(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  editTaskSubTaskAttachment = async (proposalId: string, body: any) => {
    try {
      await agent.FID.editTaskSubtaskAttachment(proposalId, body);
    } catch (e) {
      throw e;
    }
  };
  deleteTaskAttachment = async (proposalId: string, taskSubtaskAttachmentId: string) => {
    try {
      await agent.FID.deleteTaskSubtaskAttachment(proposalId, taskSubtaskAttachmentId);
    } catch (e) {
      throw e;
    }
  };

  setSelectedAmandement = (data: ITaskSubTaskAttachment | null) => {
    this.selectedAmandement = data;
  };

  setTemplateIdentifier = (identifier: string) => (this.templateIdentifier = identifier);

  clearTaskSubTaskAttachment = () => {
    this.taskAttachment = [];
    this.taskSubTaskAttachment = [];
  };

  clearSelectedFinancialPlan = () => {
    this.selectedFinancialPlan = null;
  };

  clearSelectedPhysicalPlan = () => {
    this.selectedPhysicalPlan = null;
  };

  resetTemplateIdetifier = () => {
    this.templateIdentifier = null;
  };

  setQueryParams = (query: "pageSize" | "search" | "currentPage", v: string | number) => {
    this.queryParams = {
      ...this.queryParams,
      [query]: v,
    };
  };
}
