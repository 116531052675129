import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useStore } from "../../stores/store";
import { Badge, Menu, MenuItem, Skeleton, Stack, Typography } from "@mui/material";
import { NotificationsOutlined } from "@mui/icons-material";
import AvatarAppBar from "./AvatarAppBar";
import { observer } from "mobx-react-lite";
import { history } from "../../..";
import Connector from "../../../signalr-connection";
import { Data } from "../../models/notification";

const drawerWidth = 280;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const DashboardLayout = (props: Props) => {
  const { accountStore } = useStore();
  const { events } = Connector();
  const [notificationRef, setNotificationRef] = React.useState<null | HTMLElement>(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { getNotification, setNotification, notification, notificationLoading, displayBadgeNotification, setDisplayBadgeNotification } =
    useStore().generalStore;

  useEffect(() => {
    events((_: any, message: any) => {
      setDisplayBadgeNotification(true);
      let temp = message.data.filter((item: Data) => item.userName === accountStore.account?.username);

      setNotification(temp);
    });
  });

  useEffect(() => {
    getNotification().then((res) => {
      let temp = res.data.filter((item: Data) => item.userName === accountStore.account?.username);

      setNotification({
        count: temp.length,
        data: temp,
      });
    });
  }, [accountStore, getNotification, setNotification]);

  if (!accountStore.isLoggedIn) {
    return <Navigate to="/auth/login" />;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "white",
          boxShadow: "0 0 8px 4px rgba(0,0,0, .05)",
        }}
      >
        <Toolbar>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Box>
              <IconButton aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
                <MenuIcon />
              </IconButton>
            </Box>
            <Stack direction="row" alignItems="center" gap="16px">
              <IconButton
                onClick={(e) => {
                  setNotificationRef(e.currentTarget);
                  setDisplayBadgeNotification(false);
                }}
              >
                {displayBadgeNotification ? (
                  <Badge badgeContent={notification.count} color="primary">
                    <NotificationsOutlined />
                  </Badge>
                ) : (
                  <NotificationsOutlined />
                )}
              </IconButton>
              <Menu
                anchorEl={notificationRef}
                open={!!notificationRef}
                onClose={() => {
                  setNotificationRef(null);
                }}
                sx={{
                  ".MuiPaper-root": {
                    width: 300,
                  },
                }}
              >
                {notificationLoading ? (
                  <Stack spacing={1} px={2}>
                    <Stack spacing={"4px"}>
                      <Skeleton height={20} variant="rounded" />
                      <Skeleton width="60%" height={15} variant="rounded" />
                    </Stack>
                    <Stack spacing={"4px"}>
                      <Skeleton height={20} variant="rounded" />
                      <Skeleton width="60%" height={15} variant="rounded" />
                    </Stack>
                    <Stack spacing={"4px"}>
                      <Skeleton height={20} variant="rounded" />
                      <Skeleton width="60%" height={15} variant="rounded" />
                    </Stack>
                  </Stack>
                ) : (
                  notification.data.map((item, x) => (
                    <MenuItem
                      onClick={() => {
                        setNotificationRef(null);
                        history.push(`/project-monitoring/approval/list/${item.proposalId}?status=${item.status}`);
                      }}
                      sx={{
                        whiteSpace: "wrap",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: 300,
                      }}
                      key={x}
                    >
                      <Typography width={270} color={"black"} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" fontWeight={700}>
                        {item.title}
                      </Typography>
                      <Typography width={270} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                        {item.date}
                      </Typography>
                    </MenuItem>
                  ))
                )}
              </Menu>
              <AvatarAppBar />
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>

      <Sidebar mobileOpen={mobileOpen} drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default observer(DashboardLayout);
