import { TAuctionStatus, TAuctionStatusQueryParams, TSelectedAuctionStatus } from "../models/auctionStatus";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { DataGridResult } from "../models/materialUI/dataGrid";
import agent from "../api/agent";

export class AuctionStatusStore {
  auctionStatusGrid: DataGridResult<TAuctionStatus[]> = new DataGridResult([], 0);
  auctionStatusGridLoading = false;

  auctionStatus: TAuctionStatus[] = [];
  auctionStatusLoading = false;

  selectedAuctionStatus: TAuctionStatus | null = null;

  queryparams: TAuctionStatusQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: "",
  };

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.queryparams,
      () => {
        this.getAuctionStatusGrid();
      }
    );
  }

  getAuctionStatus = async () => {
    this.auctionStatusLoading = true;
    try {
      const res = await agent.Master.getAuctionStatus();

      runInAction(() => (this.auctionStatus = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.auctionStatusLoading = false));
    }
  };

  getAuctionStatusGrid = async () => {
    this.auctionStatusGridLoading = true;
    try {
      const res = await agent.Master.getAuctionStatusGrid(this.queryparams);

      runInAction(() => (this.auctionStatusGrid = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.auctionStatusGridLoading = false));
    }
  };

  addAuctionStatus = async (body: TSelectedAuctionStatus) => {
    try {
      await agent.Master.addAuctionStatus(body);
    } catch (e) {
      throw e;
    }
  };

  editAuctionStatus = async (id: string, body: TSelectedAuctionStatus) => {
    try {
      await agent.Master.editAuctionStatus(id, body);
    } catch (e) {
      throw e;
    }
  };

  setSelectedAuctionStatus = (data: TAuctionStatus | null) => {
    this.selectedAuctionStatus = data;
  };

  deleteAuctionStatus = async (id: string) => {
    try {
      await agent.Master.deleteAuctionStatus(id);
    } catch (e) {
      throw e;
    }
  };

  setQueryParams = (query: "pageSize" | "search" | "currentPage", v: string | number) => {
    this.queryparams = {
      ...this.queryparams,
      [query]: v,
    };
  };
}
