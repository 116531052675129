import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { ILand, ILandDetail, ILandPayload, ILandDelete, ILandGridResponse, ILandType, ILandStatus } from "../models/land";

export default class LandStore {
  currentPage = 0;
  pageSize = 5;
  search = "";
  type: string = "all";
  status: string = "all";
  rowCount = 0;

  land: ILand[] = [];
  isSubmitted = false;

  loadingDelete = false;
  loadingList = false;

  selectedLand: ILand | null = null;
  landDetail: ILandDetail | null = null;
  landDetailLoading = false;

  landType: ILandType[] = [];
  landStatus: ILandStatus[] = [];

  dropdownLand: ILand[] = [];
  dropdownLandLoading = false;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.isSubmitted,
      (submit) => {
        if (submit) {
          this.getLand();
        }
        this.isSubmitted = false;
      }
    );
  }

  setSelectedLand = (land: ILand | null) => {
    this.selectedLand = land;
  };

  getLandStatus = async () => {
    try {
      const result = await agent.Land.getStatus();

      runInAction(() => (this.landStatus = result));
    } catch (e) {
      throw e;
    }
  };

  getLandType = async () => {
    try {
      const result = await agent.Land.getType();

      runInAction(() => (this.landType = result));
    } catch (e) {
      throw e;
    }
  };

  addLand = async (body: ILandPayload) => {
    try {
      await agent.Land.add(body);
    } catch (e) {
      throw e;
    }
  };

  editLand = async (landId: string, body: ILandPayload) => {
    try {
      await agent.Land.edit(landId, body);
    } catch (e) {
      throw e;
    }
  };

  getLandDetail = async (landId: string) => {
    this.landDetailLoading = true;
    try {
      const res = await agent.Land.detail(landId);

      this.setLandDetail(res);
    } catch (e) {
      throw e;
    } finally {
      this.landDetailLoading = false;
    }
  };

  setLandDetail = (landDetail: ILandDetail | null) => {
    this.landDetail = landDetail;
  };

  createDataGridParam = () => {
    const params = new URLSearchParams();
    params.append("currentPage", this.currentPage.toString());
    params.append("pageSize", this.pageSize.toString());
    this.type !== "all" && params.append("landTypeId", this.type);
    this.status !== "all" && params.append("landStatusId", this.status);
    params.append("Search", encodeURIComponent(this.search));
    return params;
  };

  getLand = async () => {
    this.loadingList = true;
    try {
      const urlParams = this.createDataGridParam();
      const land = await agent.Land.listGrid(urlParams);
      this.setLand(land);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingList = false));
    }
  };

  getDropdownLand = async () => {
    this.dropdownLandLoading = true;
    try {
      const res = await agent.Land.list();

      runInAction(() => (this.dropdownLand = res));
      return res
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.dropdownLandLoading = false));
    }
  };

  setLand = (land: ILandGridResponse) => {
    this.land = land.data;
    this.rowCount = land.rowCount;
  };

  deleteLand = async (data: ILandDelete[]) => {
    this.loadingDelete = true;
    try {
      await agent.Land.delete(data);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingDelete = false;
        this.setSubmitted();
      });
    }
  };

  setSubmitted = () => {
    this.isSubmitted = true;
  };

  setCurrentPage = (data: number) => {
    this.currentPage = data;
  };

  setPageSize = (data: number) => {
    this.pageSize = data;
  };

  setSearch = (data: string) => {
    this.search = data;
  };

  setType = (data: string) => {
    this.type = data;
  };

  setStatus = (data: string) => {
    this.status = data;
  };
}
