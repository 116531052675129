import ApexCharts from "react-apexcharts";
import { useStore } from "../../../../../../app/stores/store";
import { observer } from "mobx-react-lite";

function SCurveCostPerformanceIndex() {
  const { sCurveCpi } = useStore().proposalStore;

  return (
    <>
      <ApexCharts
        options={{
          chart: {
            type: "line",
            height: 100,
          },
          xaxis: {
            categories: sCurveCpi?.categories,
          },
          yaxis: {
            show: true,
            labels: {
              formatter: function (val: number) {
                return `Rp${Number(val.toFixed(0)).toLocaleString()}`;
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val: any) {
                return `Rp${Number(val.toFixed(0)).toLocaleString()}`;
              },
            },
          },
        }}
        series={sCurveCpi?.series}
        height={350}
      />
    </>
  );
}

export default observer(SCurveCostPerformanceIndex);
