import { ReactElement, cloneElement, useEffect, useMemo, useState } from "react";
import { Add, EditOutlined, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse, Stack, Typography } from "@mui/material";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import useQuery from "../../../app/hook/useQuery";
import ProposalFillForm from "./ProposalFillForm";
import { ProjectStatus } from "../../../app/config/enum";
import { CollapsableHistory } from "./components/CollapsableHistory";
import { HistoryCommentDataGrid } from "./components/HistoryCommentDataGrid";
import CancelNotes from "./components/CancelNotes";

type DrawerState = {
  // preparationPermission: boolean;
  // generalInformation: boolean;
  // schematicDrawing: boolean;
  // conceptSketch: boolean;
  // constructor: boolean;
  // preparation: boolean;
  // attachment: boolean;
  // design: boolean;
};

type FormList = {
  text: string;
  icon: ReactElement;
  name: keyof DrawerState;
  added: boolean;
  disabled?: boolean;
};

type TPreparationFillFormProps = {
  isHistoryView?: boolean;
};

function PreparationFillForm({ isHistoryView = false }: TPreparationFillFormProps) {
  const [open, setOpen] = useState<boolean>(false);
  const query = useQuery();
  const status = query.get("status");
  const reviewPage = query.get("reviewPage");
  const proposalId = query.get("id");

  const isApprovalMode = useMemo(() => {
    return reviewPage === "true";
  }, [reviewPage]);

  const {
    preparationPermissionLoading,
    resetPreparationPermission,
    getPreparationPermission,
    schematicDrawingLoading,
    resetGeneralInformation,
    resetSchematicDrawing,
    getGeneralInformation,
    preparationPermission,
    conceptSketchLoading,
    getSchematicDrawing,
    constructorsLoading,
    preparationLoading,
    generalInformation,
    resetConceptSketch,
    attachmentLoading,
    schematicDrawing,
    resetPreparation,
    getConceptSketch,
    resetConstructor,
    getConstructor,
    getPreparation,
    designLoading,
    conceptSketch,
    constructors,
    resetDesign,
    preparation,
    attachment,
    getAttachment,
    resetAttachment,
    getDesign,
    design,
  } = useStore().proposalStore;

  const [drawer, setDrawer] = useState<DrawerState>({
    // preparationPermission: false,
    // generalInformation: false,
    // schematicDrawing: false,
    // conceptSketch: false,
    // constructor: false,
    // preparation: false,
    // attachment: false,
    // design: false,
  });

  // const generalFormList: FormList[] = useMemo(
  //   () => [
  //     // {
  //     //   text: "Vendor Info",
  //     //   icon: <HandymanOutlined />,
  //     //   name: "constructor",
  //     //   added: constructors && constructors.length > 0 ? true : false,
  //     //   disabled: constructorsLoading,
  //     // },
  //     {
  //       text: "Attachment",
  //       icon: <AttachFile />,
  //       name: "attachment",
  //       added: attachment && attachment.length > 0 ? true : false,
  //       disabled: attachmentLoading,
  //     },
  //   ],
  //   [attachment, attachmentLoading]
  // );

  // const preparationFormList: FormList[] = useMemo(
  //   () => [
  //     {
  //       text: "Preparation",
  //       icon: <AssignmentTurnedInOutlined />,
  //       name: "preparation",
  //       added: preparation && preparation.length > 0 ? true : false,
  //       disabled: preparationLoading,
  //     },
  //     {
  //       text: "Concept Sketch",
  //       icon: <DrawOutlined />,
  //       name: "conceptSketch",
  //       added: conceptSketch && conceptSketch.length > 0 ? true : false,
  //       disabled: conceptSketchLoading,
  //     },
  //     {
  //       text: "Schematic Drawing",
  //       icon: <SchemaOutlined />,
  //       name: "schematicDrawing",
  //       added: schematicDrawing && schematicDrawing.length > 0 ? true : false,
  //       disabled: schematicDrawingLoading,
  //     },
  //     {
  //       text: "Permission",
  //       icon: <AssignmentLateOutlined />,
  //       name: "preparationPermission",
  //       added: preparationPermission && preparationPermission.length > 0 ? true : false,
  //       disabled: preparationPermissionLoading,
  //     },
  //     {
  //       text: "Design",
  //       icon: <SettingsSuggestOutlined />,
  //       name: "design",
  //       added: design && design.length > 0 ? true : false,
  //       disabled: designLoading,
  //     },
  //   ],
  //   [
  //     preparationPermissionLoading,
  //     schematicDrawingLoading,
  //     preparationPermission,
  //     conceptSketchLoading,
  //     preparationLoading,
  //     schematicDrawing,
  //     designLoading,
  //     conceptSketch,
  //     preparation,
  //     design,
  //   ]
  // );

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const openForm = (name: Partial<keyof DrawerState>) => {
    setDrawer((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const closeForm = (name: Partial<keyof DrawerState>) => {
    setDrawer((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  const formFilledCount = useMemo(() => {
    const fsCount = [
      // !!generalInformation,
      // preparationPermission && preparationPermission.length > 0,
      // schematicDrawing && schematicDrawing.length > 0,
      // conceptSketch && conceptSketch.length > 0,
      // constructors && constructors.length > 0,
      // preparation && preparation.length > 0,
      attachment && attachment.length > 0,
      // design && design.length > 0,
    ].filter((i) => i);

    return fsCount.length;
  }, [attachment]);

  useEffect(() => {
    const id = query.get("id");

    if (!id) return;

    // getPreparationPermission(id);
    // getGeneralInformation(id);
    // getSchematicDrawing(id);
    // getConceptSketch(id);
    // getPreparation(id);
    // getConstructor(id);
    // getDesign(id);
    getAttachment(id);

    return () => {
      resetAttachment();
      // resetPreparationPermission();
      // resetGeneralInformation();
      // resetSchematicDrawing();
      // resetConceptSketch();
      // resetPreparation();
      // resetConstructor();
      // resetDesign();
    };
  }, [
    // resetPreparationPermission,
    // getPreparationPermission,
    // resetGeneralInformation,
    // getGeneralInformation,
    // resetSchematicDrawing,
    // getSchematicDrawing,
    // resetConceptSketch,
    // resetPreparation,
    // getConceptSketch,
    // resetConstructor,
    // getConstructor,
    // getPreparation,
    // resetDesign,
    // getDesign,
    getAttachment,
    resetAttachment,
    query,
  ]);

  return (
    <>
      {status !== ProjectStatus.ProjectClosing ? (
        <>
          {/* <Box sx={{ my: "24px" }}>
            <hr />
          </Box>
          <Box>
            <Grid container spacing={3}>
              {generalFormList.map((item) => (
                <Grid item xs={6} key={item.name} onClick={() => !item.disabled && openForm(item.name as any)}>
                  <FillFormItem {...item} />
                </Grid>
              ))}
            </Grid>
          </Box> */}
          <Box sx={{ mt: "24px" }}>
            <Box sx={{ fontWeight: "bold", fontSize: "20px", color: "#000", mb: "12px" }}>History</Box>
            <Stack spacing={2}>
              <CollapsableHistory label="Proposal">
                <Box sx={{ p: 2 }}>
                  <ProposalFillForm isHistoryView={true} />
                </Box>
              </CollapsableHistory>
              <CollapsableHistory label="Comment">
                <HistoryCommentDataGrid />
              </CollapsableHistory>
              {status === ProjectStatus.CancelProject ? (
                <CollapsableHistory label="Cancel Notes">
                  <Box sx={{ mx: "24px" }}>
                    <CancelNotes
                      notes={generalInformation?.notes ?? ""}
                      cancelAttachment={generalInformation?.url ?? ""}
                      projectOwnerName={generalInformation?.projectOwnerName ?? ""}
                      proposalId={proposalId ?? ""}
                    />
                  </Box>
                </CollapsableHistory>
              ) : (
                ""
              )}
            </Stack>
          </Box>
        </>
      ) : (
        ""
      )}

      {/* FORM */}
      {/* <ProposalPreparation readOnly={isApprovalMode || isHistoryView} isOpen={drawer.preparation} handleClose={() => closeForm("preparation")} />
      <ProposalPreparationPermission
        readOnly={isApprovalMode || isHistoryView}
        isOpen={drawer.preparationPermission}
        handleClose={() => closeForm("preparationPermission")}
      />
      <ProposalConceptSketch
        readOnly={isApprovalMode || isHistoryView}
        isOpen={drawer.conceptSketch}
        handleClose={() => closeForm("conceptSketch")}
      />
      <ProposalSchematicDrawing
        readOnly={isApprovalMode || isHistoryView}
        isOpen={drawer.schematicDrawing}
        handleClose={() => closeForm("schematicDrawing")}
      />
      <ProposalDesign readOnly={isApprovalMode || isHistoryView} isOpen={drawer.design} handleClose={() => closeForm("design")} />
      <ProposalGeneralInformationForm
        readOnly={isApprovalMode || isHistoryView}
        isOpen={drawer.generalInformation}
        handleClose={() => closeForm("generalInformation")}
      /> */}
      {/* <ProposalConstuctorForm readOnly={isApprovalMode || isHistoryView} isOpen={drawer.constructor} handleClose={() => closeForm("constructor")} /> */}
      {/* <ProposalAttachmentForm readOnly={isApprovalMode || isHistoryView} isOpen={drawer.attachment} handleClose={() => closeForm("attachment")} /> */}
    </>
  );
}

const FillFormItem = ({ icon, text, added, disabled }: { icon: ReactElement; text: string; added?: boolean; disabled?: boolean }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      border={added ? "none" : `1px solid #DEDEDE`}
      bgcolor={disabled ? "#F5F5F5" : added ? "#F2F9EC" : "white"}
      borderRadius="4px"
      padding="16px"
      gap="12px"
      sx={{
        cursor: disabled ? "not-allowed" : "pointer",
        opacity: disabled ? 0.7 : 1,
      }}
    >
      {cloneElement(icon, {
        fontSize: "large",
        sx: { color: added ? "#7CC443" : "#999999" },
      })}
      <Stack flex={1}>
        <Typography fontWeight={700} color="black">
          {text}
        </Typography>
        {added ? <Typography color="black">Data added</Typography> : <Typography>Data is empty, add now</Typography>}
      </Stack>
      {added ? <EditOutlined sx={{ color: "black" }} /> : <Add sx={{ color: "black" }} />}
    </Box>
  );
};

export default observer(PreparationFillForm);
