import { Button, Chip, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { IFidMilestone, TFidMilestoneTask } from "../../../../../../app/models/fidMilestones";
import { Add, ChevronRight, VisibilityOutlined, AddCircleOutline, EditOutlined, MonetizationOnOutlined, ShowChart } from "@mui/icons-material";
import { format } from "date-fns";
import { SubTaskTable } from "./subtask-table";
import { TaskSubTask, TaskSubTaskStatus } from "../../../../../../app/config/enum";

export const TaskTable = ({
  tasks,
  showOrganizationField,
  setFormType,
  setTaskToEdit,
  setSubtaskToEdit,
  setParentId,
  setDrawerTypePembayaran,
  setDialogTypePembayaran,
  setDialogTypeProgress,
  readOnly,
  setIsOpenDialog,
}: {
  tasks: IFidMilestone["tasks"];
  showOrganizationField: boolean;
  setIsOpenDialog: Dispatch<SetStateAction<boolean>>;
  setDrawerTypePembayaran: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
  setDialogTypePembayaran: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
  setDialogTypeProgress: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
  setSubtaskToEdit: Dispatch<SetStateAction<IFidMilestone["tasks"][0]["subTasks"][0] | undefined>>;
  setFormType: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
  setTaskToEdit: Dispatch<SetStateAction<IFidMilestone["tasks"][0] | undefined>>;
  setParentId: Dispatch<SetStateAction<string | undefined>>;
  readOnly: boolean;
}) => {
  const [expandedTaskId, setExpandedTaskId] = useState<string>();
  const statusDisabledButtonSubTask = [
    TaskSubTaskStatus.PemenangLelang,
    TaskSubTaskStatus.Construction,
    TaskSubTaskStatus.Pembayaran,
    TaskSubTask.Completed,
  ];

  const displayPerencanaanPembayaranButton = (item: TFidMilestoneTask) => {
    if (item.subTasks.length === 0) {
      return (
        <>
          <IconButton
            size="small"
            color="inherit"
            sx={{ flexShrink: 0 }}
            onClick={() => {
              setDialogTypePembayaran("task");
              setTaskToEdit(item);
            }}
          >
            <MonetizationOnOutlined />
          </IconButton>
          <IconButton
            size="small"
            color="inherit"
            sx={{ flexShrink: 0 }}
            onClick={() => {
              setDialogTypeProgress("task");
              setTaskToEdit(item);
            }}
          >
            <AddCircleOutline />
          </IconButton>
          <IconButton
            size="small"
            color="inherit"
            sx={{ flexShrink: 0 }}
            onClick={() => {
              setDrawerTypePembayaran("task");
              setTaskToEdit(item);
            }}
          >
            <VisibilityOutlined />
          </IconButton>
        </>
      );
    } else {
      return "";
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableCell width={30} />
          <TableCell sx={{ minWidth: 250, fontWeight: 700, whiteSpace: "nowrap" }}>Task</TableCell>
          {/* {showOrganizationField && <TableCell sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>Organization</TableCell>} */}
          <TableCell sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>Organization</TableCell>
          <TableCell sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>Status</TableCell>
          <TableCell sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>Start Date</TableCell>
          <TableCell sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>End Date</TableCell>
          <TableCell sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>Percentage to CAPEX</TableCell>
          <TableCell sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>Nominal Pagu</TableCell>
          <TableCell />
        </TableHead>
        <TableBody>
          {tasks.map((item, index) => (
            <>
              <TableRow key={index}>
                <TableCell>
                  {!!item.subTasks.length && (
                    <IconButton onClick={() => setExpandedTaskId((prev) => (!!prev ? (item.id === prev ? undefined : item.id) : item.id))}>
                      <ChevronRight sx={{ transform: "rotate(90deg)" }} />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell>{item.name}</TableCell>
                {/* {showOrganizationField && <TableCell>{item.organizationName}</TableCell>} */}
                <TableCell>{item.organizationName}</TableCell>
                <TableCell>
                  <Chip component={"div"} label={item.status} color="primary" />
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>{format(new Date(item.startDate), "MMMM yyyy")}</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>{format(new Date(item.endDate), "MMMM yyyy")}</TableCell>
                <TableCell>{item.weightPhysical}%</TableCell>
                <TableCell>Rp {item.weightFinancial.toLocaleString()}</TableCell>
                <TableCell sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                  {displayPerencanaanPembayaranButton(item)}
                  <IconButton
                    size="small"
                    color="inherit"
                    sx={{ flexShrink: 0 }}
                    onClick={() => {
                      setFormType("task");
                      setTaskToEdit(item);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="inherit"
                    sx={{ flexShrink: 0 }}
                    onClick={() => {
                      setTaskToEdit(item);
                      setIsOpenDialog(true);
                    }}
                  >
                    <ShowChart />
                  </IconButton>
                  <Button
                    startIcon={<Add />}
                    sx={{ whiteSpace: "nowrap" }}
                    variant="contained"
                    disabled={readOnly || statusDisabledButtonSubTask.includes(item.status)}
                    onClick={() => {
                      setFormType("subtask");
                      setParentId(item.id);
                    }}
                  >
                    Add Subtask
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow sx={{ background: "rgba(0, 0, 0, .04)" }}>
                {/* <TableCell sx={{ p: 0 }} colSpan={showOrganizationField ? 9 : 8}> */}
                <TableCell sx={{ p: 0 }} colSpan={9}>
                  <Collapse in={expandedTaskId === item.id}>
                    <SubTaskTable
                      setDialogTypeProgress={setDialogTypeProgress}
                      setDrawerTypePembayaran={setDrawerTypePembayaran}
                      setDialogTypePembayaran={setDialogTypePembayaran}
                      setFormType={setFormType}
                      setSubtaskToEdit={setSubtaskToEdit}
                      subTask={item.subTasks}
                    />
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
