import { makeAutoObservable, reaction } from "mobx";
import { IServerError } from "../models/serverError";

export default class CommonStore {
    error: IServerError | null = null;
    token: string | null = window.localStorage.getItem('jwt');
    appLoaded = false;
    titlePage: string | null = null;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.token,
            (token) => {
                if (token) {
                    window.localStorage.setItem('jwt', token);
                } else {
                    window.localStorage.removeItem('jwt');
                }
            }
        );
    }

    setServerError = (err: IServerError) => {
        this.error = err;
    }

    setToken = (token: string | null) => {
        this.token = token;
    }

    setAppLoaded = () => {
        this.appLoaded = true;
    }

    setTitlePage = (titlePage: string | null, titleTab?: string | null) => {
        const project_app = '';
        if(titleTab){
            document.title = project_app+' | '+titleTab;
        } else{
            titlePage ? document.title = project_app+' | '+titlePage :  document.title = project_app;
        }
        this.titlePage = titlePage;        
    }
}