import { Stack } from "@mui/system";
import ListIsEmptySvg from "./svg/ListIsEmptySvg";
import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

export default function EmptyState({children, text = "There's nothing here"}: {children?: ReactNode, text?: string}) {
  return (
    <Stack direction="column">
      <Box mx="auto">
        <ListIsEmptySvg />
      </Box>
      <Typography textAlign="center" fontSize="14px" color="black" fontWeight={700}>{text}</Typography>
      {children && (
        <Box mt="24px">
          {children}
        </Box>
      )}
    </Stack>
  )
}