import { Button, Dialog, DialogContent, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import useQuery from "../../../../../../../app/hook/useQuery";
import { useStore } from "../../../../../../../app/stores/store";

function RefuseAmandementForm({ isOpen, onClose, gridId }: { onClose: () => any; isOpen: boolean; gridId: string }) {
  const { amandementStore } = useStore();
  const { approveRejectAmandement, getAmandementGrid } = amandementStore;
  const [openRefuseModal, setOpenRefuseModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState<string>("");
  const [error, setError] = useState("");
  const query = useQuery();
  const proposalId = query.get("id");

  const onSubmit = async () => {
    if (!proposalId) return;

    setIsLoading(true);

    let payload = {
      id: gridId,
      proposalId: proposalId,
      notes: "",
    };

    try {
      if (notes === "") {
        setError("Notes must be filled");

        return;
      } else {
        await approveRejectAmandement("REJECTED", payload).then(() => getAmandementGrid(proposalId));
      }
      handleClose();
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setNotes("");
    setError("");
    setIsLoading(false);

    onClose();
  };

  useEffect(() => {
    if (notes !== "") {
      setError("");
    }
  }, [notes]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent sx={{ width: "365px" }}>
        <Typography color="black" fontWeight={700} fontSize="14px">
          Refuse Amandement
        </Typography>
        <Typography mb="16px" color="black" mt="24px">
          Add notes to refuse this amandement
        </Typography>
        <TextField
          multiline
          rows={5}
          value={notes}
          fullWidth
          label="Notes"
          onChange={(e: any) => setNotes(e.target.value)}
          error={!!error}
          helperText={error}
        />
        <Stack mt={2} justifyContent="flex-end" direction="row" gap={1}>
          <Button onClick={handleClose} color="inherit" variant="outlined">
            Cancel
          </Button>
          <LoadingButton color="error" disabled={!!error || isLoading} loading={isLoading} variant="contained" onClick={onSubmit}>
            Refuse
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default observer(RefuseAmandementForm);
