import { Dispatch, SetStateAction, useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Button, Collapse, TextField } from "@mui/material";
import FilteredHistoryCollapse from "./FilteredHistoryCollapse";
import { ITaskSubTaskAttachment } from "../../../../../../../app/models/taskSubTaskAttachment";
import EmptyState from "../../../../../../../app/component/EmptyState";
import { StatusLelang } from "../../../../../../../app/config/enum";
import CSelect from "../../../../../../../app/component/CSelect";
import { NumericFormat } from "react-number-format";
import Amandement from "./Amandement";
import { useStore } from "../../../../../../../app/stores/store";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

type TFilteredHistory = {
  data: ITaskSubTaskAttachment[];
  currentStatus: any;
  subTask: any;
  setIsOpenDrawer: Dispatch<SetStateAction<any>>;
  title: string;
};

const TITLE = {
  Lelang: "Lelang",
  Pemenang_Lelang: "Pemenang Lelang",
};

export default function FilteredHistory({ currentStatus, title, setIsOpenDrawer, data = [], subTask }: TFilteredHistory) {
  const { setSelectedAmandement } = useStore().milestonetore;
  const { auctionStatus } = useStore().auctionStatusStore;
  const [openSummaryReview, setOpenSummaryReview] = useState<boolean>(false);
  const [isAmandementModalOpen, setIsAmandementModalOpen] = useState<boolean>(false);

  const handleCollapseSummaryReview = () => {
    setOpenSummaryReview((prev) => !prev);
  };

  const displayAmandemenButton = () => {
    if (title === TITLE.Pemenang_Lelang) {
      return (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              setIsAmandementModalOpen(true);
              setSelectedAmandement(data[0]);
            }}
            type="button"
            variant="contained"
            color="error"
          >
            Request Amandemen
          </Button>
        </Box>
      );
    } else {
      return "";
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        p: "16px",
        cursor: "pointer",
        borderRadius: "4px",
        border: "1px solid #DEDEDE",
        background: "#FFF",
        mb: "12px",
      }}
    >
      <Box onClick={() => handleCollapseSummaryReview()} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box>
          <Box sx={{ fontWeight: "bold", color: "#212121" }}>{title}</Box>
          <Box sx={{ fontSize: "12px" }}>Click to see detail</Box>
        </Box>
        <Box>{openSummaryReview ? <ExpandLess /> : <ExpandMore />}</Box>
      </Box>

      <Collapse sx={{ mt: "24px" }} in={openSummaryReview} timeout="auto" unmountOnExit>
        {title === TITLE.Lelang ? (
          <CSelect
            sx={{ mb: "12px" }}
            options={auctionStatus.map((item) => ({
              label: item.status,
              value: item.id,
            }))}
            label="Status Lelang"
            value={data.length > 0 ? data[0]?.auctionStatus : ""}
            disabled
          />
        ) : (
          <Box sx={{ mb: "-12px" }}>
            {title === TITLE.Pemenang_Lelang ? (
              <>
                <Box sx={{ mb: "12px" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Start Date Contract"
                      value={data[0].startDateContract}
                      onChange={(e: any) => null}
                      disabled
                      renderInput={(props: any) => <TextField required fullWidth name="startDateContract" {...props} />}
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ mb: "12px" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="End Date Contract"
                      value={data[0].endDateContract}
                      onChange={(e: any) => null}
                      disabled
                      renderInput={(props) => <TextField required fullWidth name="endDateContract" {...props} />}
                    />
                  </LocalizationProvider>
                </Box>
                <TextField sx={{ mb: "8px" }} disabled label="Pemenang Lelang" fullWidth value={data.length > 0 ? data[0].vendorWinner : "-"} />
                <NumericFormat
                  sx={{ mt: "8px", mb: "24px" }}
                  customInput={TextField}
                  label="Contract Value"
                  fullWidth
                  disabled
                  value={data.length > 0 ? data[0].contractValue : 0}
                  thousandSeparator={true}
                  prefix={"Rp "}
                />
              </>
            ) : (
              ""
            )}
          </Box>
        )}
        {data.length > 0 ? <FilteredHistoryCollapse setIsOpenDrawer={setIsOpenDrawer} subTask={subTask} data={data} /> : <EmptyState />}
        {displayAmandemenButton()}
      </Collapse>
      <Amandement attachmentReviewData={data} isAmandementModalOpen={isAmandementModalOpen} setIsAmandementModalOpen={setIsAmandementModalOpen} />
    </Box>
  );
}
