import { Box, Checkbox, FormControlLabel, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import { IAccountLoginValues } from "../../app/models/account";
import { useStore } from "../../app/stores/store";

import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";

const initialValues: IAccountLoginValues = {
  email: "",
  password: "",
  rememberMe: false,
};

const validationSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
  rememberMe: Yup.bool(),
});

const LoginPage = () => {
  const [revealPassword, setRevealPassword] = useState(false)
  const { accountStore } = useStore();

  const onSubmit = async (values: IAccountLoginValues) => {
    await accountStore.login(values)
  }
  
  const toggleRevealPassword = () => setRevealPassword(prev => !prev)
  
  const { 
    handleChange, 
    values, 
    errors, 
    handleBlur, 
    touched, 
    handleSubmit, 
    isSubmitting
  } = useFormik<IAccountLoginValues>({
    validationSchema,
    initialValues,
    onSubmit
  })
  
  return (
    <>
        <Helmet>
          <title>Patra Jasa | Login</title>
        </Helmet>
        <Box
          height="100vh"
          width="100vw"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="#F8F8F8"
        >
          <Paper 
            sx={{
              p:"34px 16px 16px 16px",
              maxWidth: '360px',
              width: '100%'
            }}
          >
            <Box mb="34px" display="flex" justifyContent="center">
              <img src="/logo.svg" alt="Patra Jasa" width={148} />
            </Box>
            <Stack gap={'24px'} component="form" onSubmit={handleSubmit}>
              <Typography 
                fontSize={'24px'} 
                fontWeight={700} 
                color="black" 
                textAlign="center"
              >
                User Login
              </Typography>
              <TextField 
                fullWidth 
                label="Email" 
                name="email" 
                onChange={handleChange}
                value={values.email}
                error={!!errors.email && touched.email}
                helperText={(touched.email && !!errors.email) ? errors.email : undefined}
                onBlur={handleBlur}
              />
              <TextField 
                fullWidth 
                label="Password" 
                name="password" 
                type={revealPassword ? 'text' : 'password'} 
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={toggleRevealPassword}>
                      {revealPassword
                        ? <VisibilityOutlined />
                        : <VisibilityOffOutlined />
                      } 
                    </IconButton>
                  )
                }}
                onChange={handleChange}
                value={values.password}
                error={!!errors.password && touched.password}
                helperText={(touched.password && !!errors.password) ? errors.password : undefined}
                onBlur={handleBlur}
              />
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <FormControlLabel 
                  control={
                    <Checkbox
                      name="rememberMe"
                      onChange={handleChange}
                      value={values.email}
                    />
                  } 
                  label="Remember me" 
                />
                <LoadingButton 
                  loading={isSubmitting} 
                  type="submit" 
                  variant="contained"
                >
                  Login
                </LoadingButton>
              </Stack>
            </Stack>
          </Paper>
        </Box>
    </>
  );
};

export default LoginPage;
