import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogContent, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { NumericFormat } from "react-number-format";
import { useStore } from "../../../../../../app/stores/store";
import useQuery from "../../../../../../app/hook/useQuery";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as yup from "yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ProjectStatus } from "../../../../../../app/config/enum";

interface AddMilestoneDialogProps {
  isOpen: boolean;
  onClose: () => any;
  readOnly: boolean;
}

export const AddMilestoneDialog = ({ readOnly, isOpen, onClose }: AddMilestoneDialogProps) => {
  const { assetCategoryStore, dialogStore, milestonetore } = useStore();
  const { addMilestone, getMilestone, getTotalMilestonestasks, totalMilestonestasks } = milestonetore;
  const query = useQuery();
  const id = query.get("id");
  const status = query.get("status");

  const closeDialog = () => {
    onClose();
    resetForm();
    setErrors({});
  };

  const successCallback = () => {
    closeDialog();

    if (!id) return;

    getMilestone(id);
    getTotalMilestonestasks(id);
  };

  const { values, resetForm, errors, submitForm, isValid, setFieldValue, handleChange, setErrors, dirty, isSubmitting } = useFormik({
    initialValues: {
      startDate: new Date(),
      endDate: new Date(),
      name: "",
      cost: 0,
    },
    onSubmit: async (values) => {
      if (!id || !totalMilestonestasks) return;

      if (values.cost + totalMilestonestasks?.totalMilestones > totalMilestonestasks?.totalAdditional && status === ProjectStatus.Preparation) {
        dialogStore.open({
          action: async () => await addMilestone(id, values).then(successCallback),
          actionText: "Add",
          title: "Warning",
          closeText: "Cancel",
          actionButtonProps: {
            color: "error",
          },
          description: "Nominal pagu yang diinputkan melebihi total CAPEX, apakah anda yakin?",
        });
      } else {
        await addMilestone(id, values).then(successCallback);
      }
    },
    validationSchema: yup.object({
      startDate: yup.date(),
      endDate: yup.date(),
      name: yup.string().required(),
      cost: yup.number().min(0).required(),
    }),
  });

  return (
    <Dialog open={isOpen}>
      <DialogContent sx={{ padding: "16px", width: "365px" }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight="700" fontSize="14px" color="black">
            Add Milestone
          </Typography>
          <Close onClick={closeDialog} />
        </Stack>
        <Box mt={"16px"} display="flex" flexDirection="column" gap={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              views={["year", "month"]}
              value={values.startDate}
              onChange={(e: any) => setFieldValue("startDate", e)}
              renderInput={(props) => (
                <TextField
                  required
                  fullWidth
                  name="startDate"
                  error={!!errors.startDate}
                  helperText={errors.startDate && String(errors.startDate)}
                  {...props}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="End Date"
              value={values.endDate}
              views={["year", "month"]}
              onChange={(e: any) => setFieldValue("endDate", e)}
              renderInput={(props) => (
                <TextField
                  required
                  fullWidth
                  name="endDate"
                  error={!!errors.endDate}
                  helperText={errors.endDate && String(errors.endDate)}
                  {...props}
                />
              )}
            />
          </LocalizationProvider>
          <TextField
            fullWidth
            label="Name"
            value={values.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
            name="name"
            disabled={readOnly}
          />
          <NumericFormat
            customInput={TextField}
            label="Cost"
            fullWidth
            placeholder="Cost"
            disabled={readOnly}
            onKeyDown={(e) => {
              if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                e.preventDefault();
              }
            }}
            value={values.cost}
            thousandSeparator={true}
            prefix={"Rp"}
            onValueChange={(e) => {
              setFieldValue("cost", e.floatValue);
            }}
          />
        </Box>
        <Stack direction="row" justifyContent="flex-end" gap="12px" mt={"24px"}>
          <Button onClick={closeDialog} type="button" variant="outlined" color="inherit">
            Cancel
          </Button>
          <LoadingButton onClick={submitForm} disabled={!isValid || !dirty} loading={isSubmitting} type="submit" variant="contained" color="primary">
            Add
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
