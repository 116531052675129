import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IAccountInfo } from "../models/account";
import { AbiGridResponse, AbiLockPayload } from "../models/abi";

export default class AbiStore {
  account: IAccountInfo | null = null;
  abiGrid: AbiGridResponse[] = [];
  gridLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  getAbiGrid = async (proposalId: string, year: number) => {
    this.gridLoading = true;
    try {
      let res = await agent.ABI.getABIGrid(proposalId, year);

      runInAction(() => (this.abiGrid = res));
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => (this.gridLoading = false));
    }
  };

  getTotalABI = async (proposalId: string, year: string) => {
    try {
      let res = await agent.ABI.getTotalABI(proposalId, year);

      return res;
    } catch (error) {
      console.error(error);
    }
  };

  lockAbi = async (payload: AbiLockPayload) => {
    this.gridLoading = true;
    try {
      await agent.ABI.lockAbi(payload);
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => (this.gridLoading = false));
    }
  };

  unLockAbi = async (payload: AbiLockPayload) => {
    try {
      await agent.ABI.unLockAbi(payload);
    } catch (error) {
      console.error(error);
    }
  };
}
