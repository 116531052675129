import { observer } from 'mobx-react-lite';
import RkdForm from '../../app/component/form/RkdForm';
import { Example, IExample } from '../../app/models/example';
import { useStore } from '../../app/stores/store';
import * as Yup from 'yup';
import RkdTextInput from '../../app/component/form/RkdTextInput';
import RkdDateTimePicker from '../../app/component/form/RkdDateTimePicker';
import RkdCheckbox from '../../app/component/form/RkdCheckbox';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import RkdBackdrop from '../../app/component/helper/RkdBackdrop';
import { Grid } from '@mui/material';
import RkdCard from '../../app/component/card/RkdCard';

function ExampleForm() {
  const { exampleStore, commonStore } = useStore();

  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const [example, setExample] = useState<IExample>(new Example());

  useEffect(() => {
    commonStore.setTitlePage('User Management','Form Example');
  });
  
  useEffect(() => {
    if (id)
      exampleStore
        .getExampleDetail(id)
        .then((exampleDetail) => setExample(exampleDetail));
  }, [id, exampleStore]);

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    price: Yup.number().required().moreThan(0),
    date: Yup.date().required(),
    isReady: Yup.boolean().required(),
  });

  if (exampleStore.loadingForm) return <RkdBackdrop />;

  return (
    <>      
      <Grid container>
        <Grid item xs={8}>
          <RkdCard title='Form Example' subtitle='subtitle'>
            <RkdForm
              initialValues={example}
              submitAction={
                id ? exampleStore.editExample : exampleStore.createExample
              }
              validationSchema={validationSchema}
            //   formTitle={`${id ? 'Edit' : 'Create'} Example`}
              buttonSubmitText="Submit"
              actionAfterSubmit={() => navigate('/example')}
              globalErrorMessage={`Error ${
                id ? 'editing' : 'creating'
              } Example`}
            >
              <div>{id && <input type="hidden" name="id" value={id} />}</div>
              <RkdTextInput name="name" type="text" label="Name" />
              <RkdTextInput
                name="price"
                type="number"
                label="Price"
                startAdornment="Rp"
              />
              <RkdDateTimePicker name="date" label="Date" />
              <RkdCheckbox name="isReady" label="Ready?" />
            </RkdForm>         
          </RkdCard>          
        </Grid>
      </Grid>
    </>
  );
}

export default observer(ExampleForm);
