import { useState } from "react";
import { Add } from "@mui/icons-material";
import { Box } from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import SettingApprovalDataGrid from "./SettingApprovalDataGrid";
import SettingApprovalForm from "./SettingApprovalForm";

export default function SettingApprovalPageIndex() {
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);

  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Data Master",
            href: "/master/settingApprovals",
          },
          {
            label: "Setting Approval List",
            href: "/master/settingApprovals",
          },
        ]}
        pageTitle="Setting Approval List"
      >
        <DashboardLayoutHeader.Button startIcon={<Add />} variant="contained" onClick={() => setIsOpenForm(true)}>
          Add Setting Approval
        </DashboardLayoutHeader.Button>
      </DashboardLayoutHeader>
      <SettingApprovalDataGrid />
      <SettingApprovalForm isOpen={isOpenForm} onClose={() => setIsOpenForm(false)} />
    </Box>
  );
}
