import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { DataGridResult } from "../models/materialUI/dataGrid";
import { IUnitBusinessCategory, IUnitBusinessCategoryBody, IUnitBusinessCategoryGrid, IUnitBusinessCategoryQueryParams } from "../models/unitBusinessCategory";

export class UnitBusinessCategoryStore {
  unitBusinessCategoryGrid: DataGridResult<IUnitBusinessCategoryGrid[]> = new DataGridResult<IUnitBusinessCategoryGrid[]>([], 0)
  unitBusinessCategoryGridLoading = false
  queryParams: IUnitBusinessCategoryQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: '',
  }
  
  unitBusinessCategory: IUnitBusinessCategory[] = []
  unitBusinessCategoryLoading = false;
  
  selectedUnitBusinessCategory: IUnitBusinessCategory | null = null
  
  constructor() {
    makeAutoObservable(this)

    reaction(() => this.queryParams, () => {
      this.getUnitBusinessCategoryGrid()
    })
  }

  getUnitBusinessCategoryGrid = async () => {
    this.unitBusinessCategoryGridLoading = true
    
    try {
      const res = await agent.General.getUnitBusinessCategoryGrid(this.queryParams)

      runInAction(() => this.unitBusinessCategoryGrid = res)
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => this.unitBusinessCategoryGridLoading = false)
    }
  }
  
  getUnitBusinessCategory = async () => {
    this.unitBusinessCategoryLoading = true
    try {
      const res = await agent.General.getUnitBusinessCategory()

      runInAction(() => this.unitBusinessCategory = res);
    } catch (e) {
      throw e
    } finally {
      runInAction(() => this.unitBusinessCategoryLoading = false)
    }
  }

  addUnitBusinessCategory = async (body: IUnitBusinessCategoryBody) => {
    try {
      await agent.General.addUnitBusinessCategory(body)
    } catch (e) {
      throw e;
    }
  }

  editUnitBusinessCategory = async (id: string, body: IUnitBusinessCategoryBody) => {
    try {
      await agent.General.editUnitBusinessCategory(id, body)
    } catch (e) {
      throw e;
    }
  }

  setSelectedUnitBusinessCategory = (data: IUnitBusinessCategory | null) => {
    this.selectedUnitBusinessCategory = data;
  }

  deleteUnitBusinessCategory = async (id: string) => {
    try {
      await agent.General.deleteUnitBusiness(id)
    } catch (e) {
      throw e;
    }
  }

  setQueryParams = (query: 'pageSize' | 'search' | 'currentPage', v: string | number) => {
    this.queryParams = {
      ...this.queryParams,
      [query]: v,
    }
  }
}