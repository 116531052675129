import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IProjectCategory } from "../models/projectCategory";

export class ProjectCategoryStore {
  projectCategory: IProjectCategory[] = []
  projectCategoryLoading = false;

  // selectedProjectCategory: IProjectCategory | null = null
  
  // queryparams: IProjectCategoryQueryParams = {
  //   currentPage: 0,
  //   pageSize: 25,
  //   search: ''
  // }
  
  constructor() {
    makeAutoObservable(this)
  }

  getProjectCategory = async () => {
    this.projectCategoryLoading = true
    try {
      const res = await agent.General.getProjectCategory()

      runInAction(() => this.projectCategory = res);
    } catch (e) {
      throw e
    } finally {
      runInAction(() => this.projectCategoryLoading = false)
    }
  }

  // addProjectCategory = async (body: IProjectCategoryBody) => {
  //   try {
  //     await agent.Master.addProjectCategory(body)
  //   } catch (e) {
  //     throw e;
  //   }
  // }

  // editProjectCategory = async (id: string, body: IProjectCategoryBody) => {
  //   try {
  //     await agent.Master.editProjectCategory(id, body)
  //   } catch (e) {
  //     throw e;
  //   }
  // }

  // setSelectedProjectCategory = (data: IProjectCategory | null) => {
  //   this.selectedProjectCategory = data;
  // }

  // deleteProjectCategory = async (id: string) => {
  //   try {
  //     await agent.Master.deleteProjectCategory(id)
  //   } catch (e) {
  //     throw e;
  //   }
  // }

  // setQueryParams = (query: 'pageSize' | 'search' | 'currentPage', v: string | number) => {
  //   this.queryparams = {
  //     ...this.queryparams,
  //     [query]: v,
  //   }
  // }
}