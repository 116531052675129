import { useMemo, useState } from "react";
import DraggableFileUpload, { DraggableFileUploadData } from "../../../../../../../app/component/input/DraggableFileUpload";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { NO_SUBTASK_ID } from "../../../../../../../app/config/enum";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { useStore } from "../../../../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import * as yup from "yup";
import DrawerForm from "../../../../../../../app/component/DrawerForm";
import useQuery from "../../../../../../../app/hook/useQuery";

const validationSchema = yup.object({
  vendorWinner: yup.string().required(),
  contractName: yup.string().required(),
  contractNumber: yup.string().required(),
  contractValue: yup.number().required(),
  endDateContract: yup.date().required(),
  startDateContract: yup.date().required(),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function AmandementForm({ isOpen, onClose }: { isOpen: boolean; onClose: () => any }) {
  const { amandementStore } = useStore();
  const { selectedAmandementGrid, editAmandement, getAmandementGrid, setSelectedAmandementGrid } = amandementStore;
  const [data, setData] = useState<DraggableFileUploadData[]>([]);
  const [deletedData, setDeletedData] = useState<any[]>([]);
  const query = useQuery();
  const proposalId = query.get("id");

  const initialValues: ValidationSchema = useMemo(() => {
    if (selectedAmandementGrid) {
      return {
        vendorWinner: selectedAmandementGrid.vendorWinner,
        contractName: selectedAmandementGrid.contractName,
        contractNumber: selectedAmandementGrid.contractNumber,
        contractValue: selectedAmandementGrid.contractValue,
        startDateContract: selectedAmandementGrid.startDateContract,
        endDateContract: selectedAmandementGrid.endDateContract,
      };
    } else {
      return {
        vendorWinner: "",
        contractName: "",
        contractNumber: "",
        contractValue: 0,
        startDateContract: new Date(),
        endDateContract: new Date(),
      };
    }
  }, [selectedAmandementGrid]);

  const onSubmit = async (values: ValidationSchema) => {
    if (!proposalId || !selectedAmandementGrid) return;

    const formData = new FormData();
    let dataAttachment = data.concat(deletedData);

    dataAttachment.forEach((item, index) => {
      formData.append(`data.File[${index}].attachment`, item.file as File);
      formData.append(`data.File[${index}].fileName`, "-");
      if (item.data) {
        formData.append(`data.File[${index}].id`, item.data.id);
        item.isDeleted && formData.append(`data.File[${index}].isDeleted`, item.isDeleted ? "1" : "0");
      }
    });

    formData.append("data.taskId", selectedAmandementGrid?.taskId);
    selectedAmandementGrid?.subTaskId !== NO_SUBTASK_ID && formData.append("data.subtaskId", selectedAmandementGrid?.subTaskId);
    formData.append("data.vendorWinner", values?.vendorWinner ?? "");
    formData.append("data.contractName", values?.contractName ?? "");
    formData.append("data.contractNumber", values?.contractNumber ?? "");
    formData.append("data.contractValue", String(values?.contractValue) ?? "0");
    formData.append("data.startDateContract", new Date(values.startDateContract).toUTCString());
    formData.append("data.endDateContract", new Date(values.endDateContract).toUTCString());
    formData.append("data.proposalId", proposalId);

    await editAmandement(selectedAmandementGrid.id, formData).then(() => {
      getAmandementGrid(proposalId);
      onClose();
      setSelectedAmandementGrid(null);
      resetForm();
      setErrors({
        vendorWinner: undefined,
        contractName: undefined,
        contractNumber: undefined,
        contractValue: undefined,
        startDateContract: undefined,
        endDateContract: undefined,
      });
    });
  };

  const { handleSubmit, resetForm, setErrors, handleChange, setFieldValue, values, errors, isValid, isSubmitting, dirty } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <DrawerForm
      title="Edit Amandement"
      isOpen={isOpen || !!selectedAmandementGrid}
      onClose={() => {
        onClose();
        setSelectedAmandementGrid(null);
      }}
      onSubmit={handleSubmit}
      disableAction={!isValid || !dirty}
      isLoading={isSubmitting}
      isEdit={!!selectedAmandementGrid}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Start Date Contract"
          value={values.startDateContract}
          onChange={(e: any) => setFieldValue("startDateContract", e)}
          renderInput={(props: any) => (
            <TextField
              required
              fullWidth
              name="startDateContract"
              error={!!errors.startDateContract}
              helperText={errors.startDateContract && String(errors.startDateContract)}
              {...props}
            />
          )}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="End Date Contract"
          value={values.endDateContract}
          onChange={(e: any) => setFieldValue("endDateContract", e)}
          renderInput={(props) => (
            <TextField
              required
              fullWidth
              name="endDateContract"
              error={!!errors.endDateContract}
              helperText={errors.endDateContract && String(errors.endDateContract)}
              {...props}
            />
          )}
        />
      </LocalizationProvider>
      <TextField
        label="Vendor Winner"
        name="vendorWinner"
        required
        fullWidth
        value={values.vendorWinner}
        error={!!errors.vendorWinner}
        onChange={handleChange}
      />
      <TextField
        label="Contract Name"
        name="contractName"
        required
        fullWidth
        error={!!errors.contractName}
        value={values.contractName}
        onChange={handleChange}
      />
      <TextField
        type="number"
        label="Contract Value"
        name="contractValue"
        required
        fullWidth
        error={!!errors.contractValue}
        value={values.contractValue}
        onChange={handleChange}
      />
      <TextField
        label="Contract Number"
        name="contractNumber"
        required
        fullWidth
        error={!!errors.contractNumber}
        value={values.contractNumber}
        onChange={handleChange}
      />
      <DraggableFileUpload
        getDeletedData={(value: any) => {
          let helper = deletedData;

          helper.push(value);
          setDeletedData(helper);
        }}
        data={data}
        setData={setData}
        editableTitle={false}
        accept="application/pdf, image/*"
      />
    </DrawerForm>
  );
}

export default observer(AmandementForm);
