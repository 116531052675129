import { useEffect } from "react";
import DashboardLayoutHeader from "../../../app/component/layout/DashboardLayoutHeader";
import { Grid, Paper, Box, Stack, Typography } from "@mui/material";
import { ArrowBackIos, FactCheckOutlined } from "@mui/icons-material";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { Link } from "react-router-dom";
import TextEditor from "../../../app/component/TextEditor";
import PageButton from "../components/PageButton";
// import CommentSection from "../components/CommentSection";
import LoadingSection from "../components/LoadingSection";
import useQuery from "../../../app/hook/useQuery";
import AttachmentSection from "../components/AttachmentSection";

function Permission() {
  const { proposalStore } = useStore();
  const { permission, permissionLoading, getPermission, getGeneralInformation, generalInformation } = proposalStore;
  const { id } = useParams();
  const query = useQuery();
  const status = query.get("status");

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
  }, [getGeneralInformation, id]);

  useEffect(() => {
    if (!id) return;

    getPermission(id);
  }, [getPermission, id]);

  return (
    <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Approval",
            href: "/project-monitoring/approval/list",
          },
          {
            label: "Approval List",
            href: "/project-monitoring/approval/list",
          },
          {
            label: `${generalInformation?.title}`,
          },
        ]}
        pageTitle="Project Monitoring"
      />

      <Paper sx={{ p: "16px" }}>
        <Link
          style={{ color: "#000", textDecoration: "none", display: "flex", alignItems: "center", gap: "3px", marginBottom: "24px" }}
          to={`/project-monitoring/approval/list/${id}?status=${status}`}
        >
          <ArrowBackIos sx={{ fontSize: "14px" }} />
          <Box>Back</Box>
        </Link>
        <Box
          sx={{
            mb: "12px",
            backgroundColor: "#F2F9FF",
            display: "flex",
            p: "16px",
            alignItems: "center",
            gap: "6px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FactCheckOutlined sx={{ mr: "12px", color: "#0664B4", fontSize: "34px" }} />
            <Box sx={{ fontWeight: "bold" }}>Kajian Perizinan</Box>
          </Box>
          {/* {permissionLoading ? "Loading..." : <CommentSection typeId={permission?.length ? permission[0]?.id : ""} />} */}
        </Box>
        {permissionLoading ? (
          <LoadingSection />
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Stack flex={1} overflow="auto" pt="12px" spacing="24px">
                  <Box>
                    <Typography color="black" fontSize="20px" fontWeight={700} sx={{ mb: "12px" }}>
                      Peraturan Daerah dan Peraturan Perundang undangan lainya
                    </Typography>
                    <TextEditor label="" value={permission && permission[0]?.pdppu} disabled />
                  </Box>
                  <Box>
                    <Typography color="black" fontSize="20px" fontWeight={700} sx={{ mb: "12px" }}>
                      Persetujuan Bangunan Gedung
                    </Typography>
                    <TextEditor value={permission && permission[0]?.buildingApproval} disabled />
                  </Box>
                  <Box>
                    <Typography color="black" fontSize="20px" fontWeight={700} sx={{ mb: "12px" }}>
                      Informasi Rencana Kota
                    </Typography>
                    <TextEditor value={permission && permission[0]?.cityPlanInformation} disabled />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack flex={1} overflow="auto" pt="12px" spacing="24px">
                  <Box>
                    <Typography color="black" fontSize="20px" fontWeight={700} sx={{ mb: "12px" }}>
                      Perizinan dan Sertifikat lainya
                    </Typography>
                    <TextEditor value={permission && permission[0]?.licensesCertificate} disabled />
                  </Box>
                  <Box>
                    <Typography color="black" fontSize="20px" fontWeight={700} sx={{ mb: "12px" }}>
                      Hal lain terkait perijinan yang perlu diperhatikan
                    </Typography>
                    <TextEditor label="" value={permission && permission[0]?.otherLicensing} disabled />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <AttachmentSection attachtment={permission?.length ? permission[0]?.attachments : []} />
          </>
        )}

        <PageButton
          isPrevButton
          isNextButton
          prevButtonRoute={`/project-monitoring/approval/list/${id}/legal?status=${status}`}
          nextButtonRoute={`/project-monitoring/approval/list/${id}/kajianFs?status=${status}`}
        />
      </Paper>
      <Box sx={{ py: "16px" }} />
    </Box>
  );
}

export default observer(Permission);
