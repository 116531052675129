import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { TConsolidation } from "../../../../../../app/models/consolidation";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../../app/stores/store";
import { format } from "date-fns";

function ProgressConsolidation() {
  const { consolidation } = useStore().consolidationStore;

  const compareDates = (d1: Date, realisasi: number) => {
    let result = "#FDE4D5";
    const yearNow = Number(format(new Date(), "yyyy"));
    const monthNow = Number(format(new Date(), "MM"));
    const preparationYear = Number(format(new Date(d1), "yyyy"));
    const preparationMonth = Number(format(new Date(d1), "MM"));

    if (realisasi < 100) {
      if (preparationYear < yearNow) return result;
      if (preparationYear === yearNow && preparationMonth < monthNow) return result;
    }

    return "";
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableCell sx={{ fontWeight: 700 }}></TableCell>
          <TableCell sx={{ fontWeight: 700 }}>
            <TableRow sx={{ display: "flex", justifyContent: "center" }}>
              <Box sx={{ justifyContent: "center" }}>Perencanaan</Box>
            </TableRow>
            <TableRow sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <TableCell sx={{ borderBottom: "none" }}>Start</TableCell>
              <TableCell sx={{ borderBottom: "none" }}>End</TableCell>
            </TableRow>
          </TableCell>
          <TableCell sx={{ fontWeight: 700 }}>FID</TableCell>
          <TableCell sx={{ fontWeight: 700 }}>
            <TableRow sx={{ display: "flex", justifyContent: "center" }}>Date Realisasi</TableRow>
            <TableRow sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <TableCell sx={{ borderBottom: "none" }}>Start</TableCell>
              <TableCell sx={{ borderBottom: "none" }}>End</TableCell>
            </TableRow>
          </TableCell>
          <TableCell sx={{ fontWeight: 700 }}>Realisasi</TableCell>
        </TableHead>
        {consolidation.map((item: TConsolidation) => (
          <TableBody>
            <TableRow>
              <TableCell sx={{ backgroundColor: compareDates(item.endDate, item.realisasi) }}>
                <Box
                  sx={{
                    fontWeight: item.type !== "SubTask" ? "bold" : "",
                    color: item.type === "Task" ? "#494949" : item.type === "SubTask" ? "" : "black",
                    width: "150px",
                    paddingLeft: item.type === "Task" ? "24px" : item.type === "SubTask" ? "48px" : "",
                  }}
                >
                  {item.name}
                </Box>
              </TableCell>
              <TableCell sx={{ backgroundColor: compareDates(item.endDate, item.realisasi), fontWeight: 700 }}>
                <TableRow
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <TableCell sx={{ borderBottom: "none", backgroundColor: compareDates(item.endDate, item.realisasi) }}>
                    {item.startDate ? format(new Date(item.startDate), "MMM yyyy") : "-"}
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>{item.endDate ? format(new Date(item.endDate), "MMM yyyy") : "-"}</TableCell>
                </TableRow>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: compareDates(item.endDate, item.realisasi),
                }}
              >
                {item.fid}%
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: compareDates(item.endDate, item.realisasi),
                  fontWeight: 700,
                }}
              >
                <TableRow sx={{ display: "flex", justifyContent: "space-evenly" }}>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    {item.startDateRealisasi ? format(new Date(item.startDateRealisasi), "MMM yyyy") : "-"}
                  </TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      borderBottom: "none",
                    }}
                  >
                    {item.endDateRealisasi ? format(new Date(item.endDateRealisasi), "MMM yyyy") : "-"}
                  </TableCell>
                </TableRow>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: compareDates(item.endDate, item.realisasi),
                }}
              >
                {item.realisasi}%
              </TableCell>
            </TableRow>
          </TableBody>
        ))}
      </Table>
    </TableContainer>
  );
}

export default observer(ProgressConsolidation);
