import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import DraggableFileUpload, { DraggableFileUploadData } from "../../../../../../../app/component/input/DraggableFileUpload";
import { Box, Button, Dialog, DialogContent, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ITaskSubTaskAttachment } from "../../../../../../../app/models/taskSubTaskAttachment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadingButton } from "@mui/lab";
import { NO_SUBTASK_ID } from "../../../../../../../app/config/enum";
import { useFormik } from "formik";
import { useStore } from "../../../../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { Close } from "@mui/icons-material";
import useQuery from "../../../../../../../app/hook/useQuery";
import * as yup from "yup";

type TAmandement = {
  setIsAmandementModalOpen: Dispatch<SetStateAction<boolean>>;
  attachmentReviewData?: ITaskSubTaskAttachment[];
  isAmandementModalOpen?: boolean;
};

function Amandement({ attachmentReviewData, isAmandementModalOpen = false, setIsAmandementModalOpen }: TAmandement) {
  const { accountStore, milestonetore } = useStore();
  const { selectedAmandement, addAmandement } = milestonetore;
  const { account } = accountStore;
  const [data, setData] = useState<DraggableFileUploadData[]>([]);
  const [deletedData, setDeletedData] = useState<any[]>([]);
  const query = useQuery();
  const id = query.get("id");

  const initialValues = useMemo(() => {
    return {
      vendorWinner: selectedAmandement ? selectedAmandement.vendorWinner : "",
      contractName: selectedAmandement ? selectedAmandement.contractName : "",
      contractNumber: selectedAmandement ? selectedAmandement.contractNumber : "",
      contractValue: selectedAmandement ? selectedAmandement.contractValue : 0,
      startDateContract: selectedAmandement ? selectedAmandement.startDateContract : new Date(),
      endDateContract: selectedAmandement ? selectedAmandement.endDateContract : new Date(),
    };
  }, [selectedAmandement]);

  const amandementFormik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      if (!id || !account || !selectedAmandement) return;

      const formData = new FormData();
      let dataAttachment = data.concat(deletedData);

      dataAttachment.forEach((item, index) => {
        formData.append(`data.File[${index}].attachment`, item.file as File);
        formData.append(`data.File[${index}].fileName`, "-");
        if (item.data) {
          formData.append(`data.File[${index}].id`, item.data.id);
          item.isDeleted && formData.append(`data.File[${index}].isDeleted`, item.isDeleted ? "1" : "0");
        }
      });

      formData.append("data.taskId", selectedAmandement?.taskId);
      selectedAmandement?.subTaskId !== NO_SUBTASK_ID && formData.append("data.subtaskId", selectedAmandement?.subTaskId);
      formData.append("data.vendorWinner", values?.vendorWinner ?? "");
      formData.append("data.contractName", values?.contractName ?? "");
      formData.append("data.contractNumber", values?.contractNumber ?? "");
      formData.append("data.contractValue", String(values?.contractValue) ?? "0");
      formData.append("data.startDateContract", new Date(values.startDateContract).toUTCString());
      formData.append("data.endDateContract", new Date(values.endDateContract).toUTCString());
      formData.append("data.proposalId", id);

      await addAmandement(formData).then(() => {
        setIsAmandementModalOpen(false);
      });
    },
    validationSchema: yup.object({
      vendorWinner: yup.string().required(),
      contractName: yup.string().required(),
      contractNumber: yup.string().required(),
      contractValue: yup.number().required(),
      endDateContract: yup.date().required(),
      startDateContract: yup.date().required(),
    }),
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!selectedAmandement) return;

    if (typeof selectedAmandement.attachment !== "string" && selectedAmandement.attachment !== null && selectedAmandement.attachment?.length > 0) {
      setData(
        selectedAmandement.attachment.map((item: any) => ({
          title: item.fileName ?? "No Name",
          file: { name: item.fileName ?? "No Name", id: item.id },
          data: item,
        })) ?? []
      );
    }
  }, [selectedAmandement]);

  useEffect(() => {
    if (!isAmandementModalOpen) {
      setData([]);
    }
  }, [isAmandementModalOpen]);

  return (
    <form onSubmit={amandementFormik.handleSubmit} noValidate>
      <Dialog
        open={isAmandementModalOpen}
        onClose={() => {
          setIsAmandementModalOpen(false);
          amandementFormik.resetForm();
        }}
      >
        <DialogContent sx={{ padding: "16px", width: "365px" }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight="700" fontSize="14px" color="black">
              Add Amandenemt
            </Typography>
            <Close
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setIsAmandementModalOpen(false);
                amandementFormik.resetForm();
              }}
            />
          </Stack>
          <Box mt={"12px"}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date Contract"
                value={amandementFormik.values.startDateContract}
                onChange={(e: any) => amandementFormik.setFieldValue("startDateContract", e)}
                renderInput={(props) => (
                  <TextField
                    required
                    fullWidth
                    name="startDateContract"
                    error={!!amandementFormik.errors.startDateContract}
                    helperText={amandementFormik.errors.startDateContract && String(amandementFormik.errors.startDateContract)}
                    {...props}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box mt={"12px"}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date Contract"
                value={amandementFormik.values.endDateContract}
                onChange={(e: any) => amandementFormik.setFieldValue("endDateContract", e)}
                renderInput={(props) => (
                  <TextField
                    required
                    fullWidth
                    name="endDateContract"
                    error={!!amandementFormik.errors.endDateContract}
                    helperText={amandementFormik.errors.endDateContract && String(amandementFormik.errors.endDateContract)}
                    {...props}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box mt="12px">
            <TextField
              label="Vendor Winner"
              name="vendorWinner"
              required
              fullWidth
              value={amandementFormik.values.vendorWinner}
              error={!!amandementFormik.errors.vendorWinner}
              onChange={amandementFormik.handleChange}
            />
          </Box>
          <Box mt="12px">
            <TextField
              label="Contract Name"
              name="contractName"
              required
              fullWidth
              error={!!amandementFormik.errors.contractName}
              value={amandementFormik.values.contractName}
              onChange={amandementFormik.handleChange}
            />
          </Box>
          <Box mt="12px">
            <TextField
              type="number"
              label="Contract Value"
              name="contractValue"
              required
              fullWidth
              error={!!amandementFormik.errors.contractValue}
              value={amandementFormik.values.contractValue}
              onChange={amandementFormik.handleChange}
            />
          </Box>
          <Box mt="12px">
            <TextField
              label="Contract Number"
              name="contractNumber"
              required
              fullWidth
              error={!!amandementFormik.errors.contractNumber}
              value={amandementFormik.values.contractNumber}
              onChange={amandementFormik.handleChange}
            />
          </Box>
          <Box mt="12px">
            <DraggableFileUpload
              getDeletedData={(value: any) => {
                let helper = deletedData;

                helper.push(value);
                setDeletedData(helper);
              }}
              data={data}
              setData={setData}
              editableTitle={false}
              accept="application/pdf, image/*"
            />
          </Box>
          <Stack direction="row" justifyContent="flex-end" gap="12px" mt={"24px"}>
            <Button
              onClick={() => {
                setIsAmandementModalOpen(false);
                amandementFormik.resetForm();
              }}
              type="button"
              variant="outlined"
              color="inherit"
            >
              Cancel
            </Button>
            <LoadingButton onClick={amandementFormik.submitForm} loading={amandementFormik.isSubmitting} variant="contained" color="primary">
              Submit
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </form>
  );
}

export default observer(Amandement);
