import { Box } from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { Add } from "@mui/icons-material";
import ProjectCategoryDataGrid from "./ProjectCategoryDataGrid";
import ProjectCategoryForm from "./ProjectCategoryForm";
import { useState } from "react";

export default function ProjectCategoryAllPageIndex() {
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);

  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Project Category",
            href: "/master/project-category",
          },
          {
            label: "Project Category List",
            href: "/master/project-category",
          },
        ]}
        pageTitle="Project Category"
      >
        <DashboardLayoutHeader.Button startIcon={<Add />} variant="contained" onClick={() => setIsOpenForm(true)}>
          Add Project Category
        </DashboardLayoutHeader.Button>
      </DashboardLayoutHeader>
      <ProjectCategoryDataGrid />
      <ProjectCategoryForm isOpen={isOpenForm} onClose={() => setIsOpenForm(false)} />
    </Box>
  );
}
