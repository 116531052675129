import { Box, FormHelperText, InputLabel } from "@mui/material";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

type Props = {
  value?: any;
  onChange?: (e: string) => any;
  label?: string;
  error?: string;
  disabled?: boolean;
};

export default function TextEditor({ disabled = false, label, error, value, onChange }: Props) {
  return (
    <Box>
      {label && <InputLabel sx={{ mb: 1, ml: 2, fontSize: "14px" }}>{label}</InputLabel>}
      <ReactQuill readOnly={disabled} value={value} onChange={onChange} />
      {!!error && <FormHelperText sx={{ mt: 1 }} error>{error}</FormHelperText>}
    </Box>
  );
}
