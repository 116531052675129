import { Box, Grid, Paper, TextField } from "@mui/material";
import { StyledDataGrid } from "../../app/component/StyledDataGrid";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Search } from "@mui/icons-material";
import { useDebounce } from "../../app/hook/useDebounce";
import { format } from "date-fns";
import { LocalizationProvider, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";

function GeneralLogDataGrid() {
  const { getGeneralLogGrid, generalLogGrid, generalLogGridLoading, setQueryParams, queryparams } = useStore().generalLogStore;

  const handleSearch = useDebounce(2000, (e) => setQueryParams("search", e.target.value));
  const handleChangeDate = useDebounce(1000, (e: any) => {
    if (e.some((val: Date | null) => val === null)) {
      setQueryParams("startDate", null);
      setQueryParams("endDate", null);
    } else {
      let startEndDateTemp = [];
      for (let index = 0; index <= 1; index++) startEndDateTemp.push(new Date(e[index].getTime() - e[index].getTimezoneOffset() * 60000));

      setQueryParams("startDate", startEndDateTemp[0]);
      setQueryParams("endDate", startEndDateTemp[1]);
    }
  });

  useEffect(() => {
    getGeneralLogGrid();
  }, [getGeneralLogGrid]);

  return (
    <Paper sx={{ p: "16px" }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField fullWidth placeholder="Search" InputProps={{ startAdornment: <Search /> }} sx={{ mb: "24px" }} onChange={handleSearch} />
        </Grid>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              onChange={(e: any) => handleChangeDate(e)}
              value={[queryparams.startDate, queryparams.endDate]}
              componentsProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
              localeText={{ start: "Start Date", end: "End Date" }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <StyledDataGrid
        loading={generalLogGridLoading}
        rows={generalLogGrid.data}
        columns={[
          {
            field: "date",
            headerName: "Access Date",
            flex: 0.2,
            renderCell: (row: any) => format(new Date(row.value), "dd MMM yyyy - hh.mm"),
          },
          {
            field: "description",
            headerName: "Description",
            flex: 1,
          },
        ]}
        disableSelectionOnClick
        autoHeight
        pagination
        pageSize={queryparams.pageSize}
        page={queryparams.currentPage}
        onPageSizeChange={(v) => setQueryParams("pageSize", v)}
        paginationMode="server"
        onPageChange={(v) => setQueryParams("currentPage", v)}
        rowCount={generalLogGrid.rowCount}
      />
    </Paper>
  );
}

export default observer(GeneralLogDataGrid);
