import { LoadingButton, TreeItem, TreeView } from "@mui/lab";
import DrawerForm from "../../../../../../app/component/DrawerForm";
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { NumericFormat } from "react-number-format";
import { ProjectStatus, Roles, TaskSubTaskStatus } from "../../../../../../app/config/enum";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useStore } from "../../../../../../app/stores/store";
import { IFidMilestone } from "../../../../../../app/models/fidMilestones";
import { useFormik } from "formik";
import * as yup from "yup";
import { findDeepObject } from "../../../../../../app/utils/utils";
import useQuery from "../../../../../../app/hook/useQuery";
import DraggableFileUploadV2 from "../../../../../../app/component/input/DraggableFileUploadV2";
import { DraggableFileUploadData } from "../../../../../../app/component/input/DraggableFileUpload";
import { TTaskAttachmentSuperadmin } from "../../../../../../app/models/taskSubTaskAttachment";

type OrgList = {
  value: string;
  id: string;
  level: string;
  name: string;
  child: OrgList[];
};

type AddItemFormProps = {
  taskToEdit?: IFidMilestone["tasks"][0];
  subTaskToEdit?: IFidMilestone["tasks"][0]["subTasks"][0];
  setTaskToEdit: Dispatch<SetStateAction<IFidMilestone["tasks"][0] | undefined>>;
  setSubtaskToEdit: Dispatch<SetStateAction<IFidMilestone["tasks"][0]["subTasks"][0] | undefined>>;
  parentId?: string;
  formType?: "task" | "subtask";
  onClose: () => any;
  isOpen: boolean;
  readOnly: boolean;
};

const taskValidation = yup.object({
  milestoneId: yup.string().required(),
  name: yup.string().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  weightPhysical: yup.number(),
  weightFinancial: yup.number().required("Nominal Pagu must be filled"),
  status: yup.string(),
  organizationId: yup.string(),
});

const subTaskValidation = yup.object({
  tasksId: yup.string().required(),
  name: yup.string().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  weightPhysical: yup.number(),
  weightFinancial: yup.number().required("Nominal Pagu must be filled"),
  status: yup.string(),
  organizationId: yup.string(),
});

export const AddItemFormDrawer = ({
  taskToEdit,
  subTaskToEdit,
  parentId,
  isOpen,
  setTaskToEdit,
  setSubtaskToEdit,
  formType,
  onClose,
  readOnly,
}: AddItemFormProps) => {
  const { dialogStore } = useStore();
  const {
    addSubTask,
    editSubTask,
    addTask,
    deleteTaskLoading,
    editTaskSuperadmin,
    addTaskSuperadmin,
    getTaskAttachmentSuperadmin,
    taskAttachmentSuperadminLoading,
    deleteTask,
    editTask,
    getMilestone,
    deleteSubTask,
    getTotalMilestonestasks,
    milestones,
    totalMilestonestasks,
  } = useStore().milestonetore;
  const { getOrganizationList, getOrganizationsByProposalId } = useStore().organizationStore;
  const { generalInformation } = useStore().proposalStore;
  const { account } = useStore().accountStore;
  const query = useQuery();
  const id = query.get("id");
  const status = query.get("status");
  const [files, setFiles] = useState<DraggableFileUploadData<TTaskAttachmentSuperadmin>[]>([]);

  const isSuperAdminAndPreparation = useMemo(() => {
    return account?.roles.includes(Roles.Superadmin) && status === ProjectStatus.Preparation;
  }, [account?.roles, status]);

  const closeDrawer = () => {
    onClose();
    setTaskToEdit(undefined);
    setSubtaskToEdit(undefined);
    resetForm();
    setErrors({});
  };

  const successCallback = () => {
    if (!id) return;

    closeDrawer();
    getMilestone(id);
    getTotalMilestonestasks(id);
  };

  const maxStartEndDateByParent = useMemo(() => {
    let selectedData = { startDate: new Date(), endDate: new Date() };
    let parentIdHelper = parentId || subTaskToEdit?.tasksId;

    if (isOpen) {
      milestones.data.map((milestone) => {
        const foundTask = milestone.tasks.find((task) => task.id === parentIdHelper);

        if (foundTask) selectedData = { startDate: foundTask.startDate, endDate: foundTask.endDate }; // Type assertion here
      });
    }

    return { startDate: selectedData?.startDate, endDate: selectedData?.endDate };
  }, [milestones, parentId, isOpen, subTaskToEdit?.tasksId]);

  const taskInitialValues = useMemo(() => {
    if (taskToEdit) {
      return taskToEdit;
    } else {
      return {
        milestoneId: parentId,
        name: "",
        organizationId: "",
        startDate: new Date(),
        endDate: new Date(),
        weightPhysical: 0,
        weightFinancial: 0,
        status: TaskSubTaskStatus.Perencanaan,
      };
    }
  }, [parentId, taskToEdit]);

  const subTaskInitialValues = useMemo(() => {
    if (subTaskToEdit) {
      return subTaskToEdit;
    } else {
      return {
        tasksId: parentId,
        name: "",
        organizationId: "",
        startDate: maxStartEndDateByParent.startDate,
        endDate: maxStartEndDateByParent.endDate,
        weightPhysical: 0,
        weightFinancial: 0,
        status: TaskSubTaskStatus.Perencanaan,
      };
    }
  }, [parentId, subTaskToEdit]);

  const onSubmitTask = async (values: yup.InferType<typeof taskValidation>) => {
    if (!id || !totalMilestonestasks) return;
    const formData = new FormData();

    const { organizationId, ...restValues } = values;
    let payload = organizationId === "" ? restValues : values;

    formData.append("milestoneId", payload.milestoneId);
    formData.append("name", payload.name);
    formData.append("startDate", taskToEdit ? String(new Date(payload.startDate).toUTCString()) : String(payload.startDate.toUTCString()));
    formData.append("endDate", taskToEdit ? String(new Date(payload.endDate).toUTCString()) : String(payload.endDate.toUTCString()));
    formData.append("weightPhysical", String(payload.weightPhysical));
    formData.append("weightFinancial", String(payload.weightFinancial));
    if (organizationId !== "") formData.append("organizationId", String(organizationId));

    files.forEach((file, x) => {
      if (file.data?.id) {
        formData.append(`attachments[${x}].file`, "");
        formData.append(`attachments[${x}].id`, file.data.id);
      } else {
        formData.append(`attachments[${x}].file`, file.file as File);
        formData.append(`attachments[${x}].id`, "");
      }
    });

    if (taskToEdit) {
      if (values.weightFinancial + totalMilestonestasks.totalTasks > totalMilestonestasks.totalAdditional && status === ProjectStatus.Preparation) {
        dialogStore.open({
          action: async () => await editTask(taskToEdit.id, payload as any).then(successCallback),
          actionText: "Add",
          title: "Warning",
          closeText: "Cancel",
          actionButtonProps: {
            color: "error",
          },
          description: "Nominal pagu yang diinputkan melebihi total CAPEX, apakah anda yakin?",
        });
      } else {
        isSuperAdminAndPreparation
          ? await editTaskSuperadmin(taskToEdit.id, formData as any).then(successCallback)
          : await editTask(taskToEdit.id, payload as any).then(successCallback);
      }
    } else {
      if (values.weightFinancial + totalMilestonestasks.totalTasks > totalMilestonestasks.totalAdditional && status === ProjectStatus.Preparation) {
        dialogStore.open({
          action: async () => await addTask(payload as any).then(successCallback),
          actionText: "Add",
          title: "Warning",
          closeText: "Cancel",
          actionButtonProps: {
            color: "error",
          },
          description: "Nominal pagu yang diinputkan melebihi total CAPEX, apakah anda yakin?",
        });
      } else {
        isSuperAdminAndPreparation
          ? await addTaskSuperadmin(formData as any).then(successCallback)
          : await addTask(payload as any).then(successCallback);
      }
    }
  };

  const onSubmitSubTask = async (values: yup.InferType<typeof taskValidation>) => {
    if (!id || !totalMilestonestasks) return;

    const { organizationId, ...restValues } = values;
    let payload = organizationId === "" ? restValues : values;

    if (subTaskToEdit) {
      if (
        values.weightFinancial + totalMilestonestasks.totalSubTasks > totalMilestonestasks.totalAdditional &&
        status === ProjectStatus.Preparation
      ) {
        dialogStore.open({
          action: async () => await editSubTask(subTaskToEdit.id, values as any).then(successCallback),
          actionText: "Add",
          title: "Warning",
          closeText: "Cancel",
          actionButtonProps: {
            color: "error",
          },
          description: "Nominal pagu yang diinputkan melebihi total CAPEX, apakah anda yakin?",
        });
      } else {
        await editSubTask(subTaskToEdit.id, values as any).then(successCallback);
      }
    } else {
      if (
        values.weightFinancial + totalMilestonestasks.totalSubTasks > totalMilestonestasks.totalAdditional &&
        status === ProjectStatus.Preparation
      ) {
        dialogStore.open({
          action: async () => await addSubTask(payload as any).then(successCallback),
          actionText: "Add",
          title: "Warning",
          closeText: "Cancel",
          actionButtonProps: {
            color: "error",
          },
          description: "Nominal pagu yang diinputkan melebihi total CAPEX, apakah anda yakin?",
        });
      } else {
        await addSubTask(payload as any).then(successCallback);
      }
    }
  };

  const [organizationsList, setOrganizationsList] = useState<OrgList[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<OrgList>();
  const [loadingAttc, setLoadingAttc] = useState(false);

  const { handleSubmit, dirty, isValid, resetForm, isSubmitting, setErrors, values, errors, handleChange, setFieldValue } = useFormik<
    yup.InferType<typeof taskValidation> | yup.InferType<typeof subTaskValidation>
  >({
    initialValues: (formType === "task" ? taskInitialValues : subTaskInitialValues) as
      | yup.InferType<typeof taskValidation>
      | yup.InferType<typeof subTaskValidation>,
    onSubmit: (formType === "task" ? onSubmitTask : onSubmitSubTask) as any,
    validationSchema: formType === "task" ? taskValidation : subTaskValidation,
    enableReinitialize: true,
  });

  const handleChangeOrganizationId = (node: any) => {
    setSelectedOrg(node);
    setFieldValue("organizationId", node.id);
  };

  const renderTreeItem = (nodes: any) => {
    return (
      <TreeItem onClick={() => handleChangeOrganizationId(nodes)} key={nodes.id} nodeId={nodes.id} label={nodes.name}>
        {Array.isArray(nodes.child) ? nodes.child.map((node: any) => renderTreeItem(node)) : null}
      </TreeItem>
    );
  };

  useEffect(() => {
    if (!taskToEdit) return;
    getTaskAttachmentSuperadmin(taskToEdit?.id).then((attachmentRes) => {
      setFiles(attachmentRes?.map((item) => ({ title: item.fileName, file: { name: item.fileName, id: item.id }, data: item })));
    });
  }, [getTaskAttachmentSuperadmin, taskToEdit]);

  useEffect(() => {
    if (!id) return;

    getOrganizationsByProposalId(id).then((organizationRes) => {
      setOrganizationsList(organizationRes?.map((item) => ({ ...item, text: item.name, value: item.id } as any)) ?? []);
    });
  }, [getOrganizationsByProposalId, id]);

  useEffect(() => {
    const result = findDeepObject(organizationsList, "id", values.organizationId as any);
    setSelectedOrg(result ?? undefined);
  }, [organizationsList, values.organizationId]);

  useEffect(() => {
    if (!generalInformation) return;
    if (!values.weightFinancial) {
      setFieldValue("weightPhysical", 0);
    } else {
      const weightPhysical = ((values.weightFinancial / generalInformation.cost) * 100).toFixed(2);

      setFieldValue("weightPhysical", weightPhysical);
    }
  }, [generalInformation, values.weightFinancial, setFieldValue]);

  return (
    <DrawerForm
      isOpen={isOpen}
      onClose={closeDrawer}
      onSubmit={handleSubmit}
      isEdit={false}
      title={formType === "task" ? (!!taskToEdit ? "Edit Task" : "Add task") : subTaskToEdit ? "Edit Subtask" : "Add Subtask"}
      disableAction={!dirty || !isValid}
      isLoading={isSubmitting}
      customButton={
        <>
          {(taskToEdit || subTaskToEdit) && (
            <LoadingButton
              variant="outlined"
              loading={deleteTaskLoading}
              color="error"
              disabled={readOnly}
              onClick={() =>
                formType === "task"
                  ? taskToEdit &&
                    deleteTask(taskToEdit.id).then(() => {
                      if (!id) return;

                      successCallback();
                    })
                  : subTaskToEdit &&
                    deleteSubTask(subTaskToEdit.id).then(() => {
                      if (!id) return;

                      successCallback();
                    })
              }
              type="button"
            >
              Delete
            </LoadingButton>
          )}
          <LoadingButton variant="contained" loading={isSubmitting} type="submit" disabled={readOnly}>
            Save
          </LoadingButton>
        </>
      }
    >
      <TextField
        label="Name"
        name="name"
        required
        disabled={readOnly}
        fullWidth
        value={values.name}
        error={!!errors.name}
        helperText={errors.name}
        onChange={handleChange}
      />
      <Stack direction="row" spacing="24px">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            value={values.startDate}
            onChange={(e: any) => setFieldValue("startDate", e)}
            disabled={readOnly}
            views={["month", "year"]}
            inputFormat="MMMM yyyy"
            maxDate={formType === "subtask" ? maxStartEndDateByParent.endDate : undefined}
            minDate={formType === "subtask" ? maxStartEndDateByParent.startDate : undefined}
            renderInput={(props) => (
              <TextField
                required
                fullWidth
                name="startDate"
                error={!!errors.startDate}
                helperText={errors.startDate && String(errors.startDate)}
                {...props}
              />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="End Date"
            value={values.endDate}
            maxDate={formType === "subtask" ? maxStartEndDateByParent.endDate : undefined}
            minDate={formType === "subtask" ? maxStartEndDateByParent.startDate : undefined}
            disabled={readOnly}
            views={["month", "year"]}
            inputFormat="MMMM yyyy"
            onChange={(e: any) => setFieldValue("endDate", e)}
            renderInput={(props) => (
              <TextField
                required
                fullWidth
                name="endDate"
                error={!!errors.endDate}
                helperText={errors.endDate && String(errors.endDate)}
                {...props}
              />
            )}
          />
        </LocalizationProvider>
      </Stack>
      {formType === "task" && status !== ProjectStatus.Proposal && (
        <FormControl fullWidth>
          <InputLabel shrink id="organization-select-id">
            Organization
          </InputLabel>
          <Select
            labelId="organization-select-id"
            id="organization-select-id"
            label="Organization"
            value={values.organizationId}
            disabled={readOnly}
            displayEmpty
          >
            <MenuItem sx={{ display: "none" }} value={values.organizationId}>
              {selectedOrg?.name}
            </MenuItem>
            <TreeView
              aria-label="select organization"
              defaultCollapseIcon={<ExpandMore />}
              defaultExpandIcon={<ChevronRight />}
              sx={{ height: 240, flexGrow: 1, overflowY: "auto" }}
            >
              {!!organizationsList?.length &&
                organizationsList.map((item) => {
                  return renderTreeItem(item);
                })}
            </TreeView>
          </Select>
        </FormControl>
      )}
      <Box display="flex" flexDirection="row" gap={3}>
        <NumericFormat
          customInput={TextField}
          label="Nominal Pagu"
          name="weightFinancial"
          required
          disabled={readOnly}
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
              e.preventDefault();
            }
          }}
          fullWidth
          value={values.weightFinancial}
          error={!!errors?.weightFinancial}
          helperText={errors?.weightFinancial}
          thousandSeparator={true}
          onValueChange={(e) => {
            setFieldValue(`weightFinancial`, e.floatValue);
          }}
          prefix="Rp "
        />
        <TextField
          label="Percentage to CAPEX"
          name="weightPhysical"
          required
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
              e.preventDefault();
            }
          }}
          sx={{ width: "250px" }}
          value={values.weightPhysical}
          error={!!errors.weightPhysical}
          onChange={handleChange}
          disabled
          type="number"
        />
      </Box>
      <Stack component="form" flex={1} overflow="auto" py="8px" spacing="24px">
        <Typography fontSize={"14px"} fontWeight={700} color="black">
          {isSuperAdminAndPreparation ? "Upload Document" : files.length ? "Document List" : ""}
        </Typography>
        {isSuperAdminAndPreparation ? (
          <>
            {taskAttachmentSuperadminLoading ? (
              <Box display="flex" justifyContent="center" py={2}>
                <CircularProgress />
              </Box>
            ) : (
              <DraggableFileUploadV2
                viewable
                viewableOptions={{ propsoalId: id ?? "" }}
                loading={loadingAttc}
                data={files}
                setData={setFiles as any}
              />
            )}
          </>
        ) : (
          ""
        )}
      </Stack>
    </DrawerForm>
  );
};
