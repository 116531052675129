import { Search } from "@mui/icons-material"
import { Stack, TextField } from "@mui/material"

export const ReFidDataGridToolbar = () => {
  return (
    <Stack>
      <TextField 
        InputProps={{
          startAdornment: <Search />
        }}
        placeholder="Search project name"
      />
    </Stack>
  )
}