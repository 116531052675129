import { useFormik } from "formik";
import DrawerForm from "../../app/component/DrawerForm";
import * as yup from 'yup';
import { TextField } from "@mui/material";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";

const validationSchema = yup.object({
  name: yup.string().required(),
  description: yup.string(),
})

type ValidationSchema = yup.InferType<typeof validationSchema>

function OwnershipCategoryForm({ isOpen, onClose }: {isOpen: boolean, onClose: () => any}) {
  const { addOwnershipCategory, selectedOwnershipCategory, editOwnershipCategory, getOwnershipCategoryGrid, setSelectedOwnershipCategory } = useStore().ownershipCategoryStore
  
  const initialValues: ValidationSchema = useMemo(() => {
    if (selectedOwnershipCategory) {
      return selectedOwnershipCategory
    } else {
      return { name: '', description: '' }
    }
  }, [selectedOwnershipCategory])

  const onSubmit = async (values: ValidationSchema) => {
    if (selectedOwnershipCategory) {
      await editOwnershipCategory(selectedOwnershipCategory.id, values)
        .then(() => {
          getOwnershipCategoryGrid()
          onClose()
          setSelectedOwnershipCategory(null)
          resetForm()
          setErrors({
            name: undefined
          })
        })
    } else {
      await addOwnershipCategory(values)
        .then(() => {
          getOwnershipCategoryGrid()
          onClose()
          setSelectedOwnershipCategory(null)
          resetForm()
          setErrors({
            name: undefined
          })
        })
    }
  }

  const { handleSubmit, resetForm, setErrors, handleChange, values, errors, isValid, isSubmitting, dirty } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  })
  
  return (
    <DrawerForm
      title={`${selectedOwnershipCategory ? 'Edit' : 'Add'} Status Kepemilikan`}
      isOpen={isOpen || !!selectedOwnershipCategory}
      onClose={() => {
        onClose()
        setSelectedOwnershipCategory(null)
      }}
      onSubmit={handleSubmit}
      disableAction={!isValid || !dirty}
      isLoading={isSubmitting}
      isEdit={!!selectedOwnershipCategory}
    >
      <TextField 
        label="Name"
        fullWidth
        name="name"
        value={values.name}
        error={!!errors.name}
        helperText={errors.name}
        onChange={handleChange}
      />
      <TextField 
        label="Description"
        fullWidth
        name="description"
        value={values.description}
        error={!!errors.description}
        helperText={errors.description}
        onChange={handleChange}
        multiline
        rows={5}
      />
    </DrawerForm>
  )
}

export default observer(OwnershipCategoryForm)