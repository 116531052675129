import { useEffect, useState } from "react";
import { UserFormValues } from "../../app/models/user";
import { TOrganization } from "../../app/models/organization";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import RkdSelectGroup from "../../app/component/form/RkdSelectGroup";
import RkdFormField from "../../app/component/form/RkdFormField";
import RkdTextInput from "../../app/component/form/RkdTextInput";
import RkdForm from "../../app/component/form/RkdForm";

interface Props {
  username?: string;
}

function UserForm({ username }: Props) {
  const [organizationsList, setOrganizationsList] = useState<TOrganization[] | undefined>();
  const { userStore, modalStore, organizationStore } = useStore();
  const [curUser, setCurUser] = useState(new UserFormValues());

  useEffect(() => {
    if (username)
      userStore.getDetail(username).then((userDetail) => {
        setCurUser(new UserFormValues(userDetail));
        modalStore.setLoading(false);
      });
  }, [username, userStore, modalStore]);

  useEffect(() => {
    organizationStore.getOrganizationList().then((organizationRes) => {
      setOrganizationsList(organizationRes?.map((item) => ({ ...item, text: item.name, value: item.id })));
    });
  }, [organizationStore]);

  if (userStore.loadingForm || organizationStore.loadingForm) return null;

  return (
    <RkdForm
      initialValues={curUser}
      submitAction={username ? userStore.editUser : userStore.createUser}
      actionAfterSubmit={() => {
        userStore.getUserList();
        modalStore.close();
      }}
      formTitle={`${username ? "Edit" : "Create New"} User`}
      buttonSubmitText="Submit"
      successMessage={`Success ${username ? "editing" : "creating"} User`}
      globalErrorMessage={`Error ${username ? "editing" : "creating"} User`}
    >
      <RkdFormField>
        <RkdTextInput name="username" disabled={!!username} label="Username" />
      </RkdFormField>
      <RkdFormField>
        <RkdSelectGroup name="organizationId" list={organizationsList || []} label="Organization" />
      </RkdFormField>
      <RkdFormField>
        <RkdTextInput name="displayName" label="Display Name" />
      </RkdFormField>
      <RkdFormField>
        <RkdTextInput name="email" label="Email" />
      </RkdFormField>
    </RkdForm>
  );
}

export default observer(UserForm);
