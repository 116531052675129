import { Route, Routes, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "./app/stores/store";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./app/config/theme";

import Home from "./features/home/Home";
import ExamplePage from "./features/example/ExamplePage";
import ExampleForm from "./features/example/ExampleForm";
import UserPage from "./features/user/UserPage";
import Tester from "./features/tester/Tester";
import ServerError from "./features/errors/ServerError";
import NotFound from "./features/errors/NotFound";
import LoginPage from "./features/account/LoginPage";
import RkdModal from "./app/component/helper/RkdModal";
import RkdSnackbar from "./app/component/helper/RkdSnackbar";
import RkdDialog from "./app/component/helper/RkdDialog";
import RkdBackdrop from "./app/component/helper/RkdBackdrop";
import RouteAuth from "./app/component/helper/RouteAuth";
import AccessDenied from "./features/errors/AccessDenied";
import { BD_OTHERS_NAME, NON_BD_OTHERS_NAME, ProjectCategory, Roles } from "./app/config/enum";
import LandIndexPage from "./features/land/LandIndexPage";
import LandDetail from "./features/land/LandDetail";
import DashboardLayout from "./app/component/layout/DashboardLayout";
import FileManagerIndexPage from "./features/fileManager/FileManagerIndexPage";
import { Box } from "@mui/material";
import ProposalIndexPage from "./features/projectMonitoring/proposal/ProposalIndexPage";
import ProposalFormPage from "./features/projectMonitoring/proposal/ProposalFormPage";
import ProjectTypeIndex from "./features/projectType/ProjectTypeIndex";
import RegionalPageIndex from "./features/regional/RegionalPageIndex";
import SubHoldingPageIndex from "./features/subHolding/SubHoldingPageIndex";
import UnderlyingPageIndex from "./features/underlying/UnderlyingPageIndex";
import AssetCategoryPageIndex from "./features/assetCategory/AssetCategoryPageIndex";
import InventoryCategoryPageIndex from "./features/inventoryCategory/InventoryCategoryPageIndex";
import OwnershipCategoryPageIndex from "./features/ownershipCategory/OwnershipCategoryPageIndex";
import UnitBusinessPageIndex from "./features/unitBusiness/UnitBusinessPageIndex";
import { Helmet } from "react-helmet";
import FidPageIndex from "./features/projectMonitoring/proposal/tabs/fid/FidTab";
import OrganizationPageIndex from "./features/organization/OrganizationPageIndex";
import ApprovalPageIndex from "./features/approval/ApprovalPageIndex";
import ApprovalInReviewPage from "./features/approval/ApprovalInReviewPage";
import SettingApprovalPageIndex from "./features/settingApproval/SettingApprovalPageIndex";
import ChangeRequestIndex from "./features/changeRequest/ChangeRequestIndex";
import ChangeRequestDetail from "./features/changeRequest/ChangeRequestDetail";
import ApprovalDetail from "./features/approval/ApprovalDetail";
import GeneralInformation from "./features/approval/proposal/GeneralInformation";
import Land from "./features/approval/proposal/Land";
import ProductAnalysis from "./features/approval/proposal/ProductAnalysis";
import MarketAnalysis from "./features/approval/proposal/MarketAnalysis";
import CostplanAnalysis from "./features/approval/proposal/CostplanAnalysis";
import TechnicalAnalysis from "./features/approval/proposal/TechnicalAnalysis";
import EconomyAnalysis from "./features/approval/proposal/EconomyAnalysis";
import CollaborationAnalysis from "./features/approval/proposal/CollaborationAnalysis";
import Risk from "./features/approval/proposal/Risk";
import Legal from "./features/approval/proposal/Legal";
import Permission from "./features/approval/proposal/Permission";
import Contructor from "./features/approval/proposal/Contructor";
import Attachment from "./features/approval/proposal/Attachment ";
import KajianFs from "./features/approval/proposal/KajianFs";
import BackgroundObjective from "./features/approval/proposal/BackgroundObjective";
import ScopeOfWork from "./features/approval/proposal/ScopeOfWork";
import Timeline from "./features/approval/proposal/Timeline";
import InvestmentBudget from "./features/approval/proposal/InvestmentBudget";
import Drawdown from "./features/approval/proposal/Drawdown";
import CostBenefit from "./features/approval/proposal/CostBenefit";
import RiskMitigation from "./features/approval/proposal/RiskMitigation";
import BackgroundObjectiveBD from "./features/approval/proposal/BackgroundObjectiveBD";
import PeruntukanPageIndex from "./features/peruntukan/PeruntukanPageIndex";
import UnitBusinessCategoryPageIndex from "./features/unitBusinessCategory/UnitBusinessCategoryPageIndex";
import { ReFidIndex } from "./features/reFid/ReFidIndex";
import ReFidDetailPage from "./features/reFid/ReFidDetailPage";
import ReFidFormPage from "./features/reFid/ReFidFormPage";
import ProjectCategoryPageIndex from "./features/projectCategoryNBD/ProjectCategoryPageIndex";
import { LicenseInfo } from "@mui/x-license-pro";
import AuctionStatusPageIndex from "./features/auctionStatus/AuctionStatusPageIndex";
import ViewFile from "./features/viewFile/ViewFile";
import Executive from "./features/home/Executive";
import ProjectCategoryAllPageIndex from "./features/projectCategory/ProjectCategoryPageIndex";
import GeneralLogPageIndex from "./features/generalLog/GeneralLogPageIndex";
import { TProjectCategoryAll } from "./app/models/projectCategoryAll";
import Projects from "./features/home/Projects";
import UploadAdmin from "./features/uploadAdmin/UploadAdmin";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY || "");

type TProjectCategoryList = {
  route: string;
  title: string;
  id: string;
};

const App = () => {
  const location = useLocation();
  const { commonStore, accountStore, projectCategoryAllStore } = useStore();
  const [projectCategorList, setProjectCategorList] = useState<TProjectCategoryList[]>([]);

  useEffect(() => {
    let temp = [...projectCategorList];
    if (commonStore.token) {
      accountStore.getUser().finally(() => commonStore.setAppLoaded());
      projectCategoryAllStore.getProjectCategoryAll().then((res: TProjectCategoryAll[]) => {
        res.forEach((category) => {
          let tempCategory = category.name === BD_OTHERS_NAME ? "BD" : category.name === NON_BD_OTHERS_NAME ? "NON_BD" : category.name;
          temp.push({ title: tempCategory, route: tempCategory, id: category.id });
        });
        setProjectCategorList(temp);
      });
    } else {
      commonStore.setAppLoaded();
    }
  }, [accountStore, commonStore, projectCategoryAllStore]);

  if (!commonStore.appLoaded) return <RkdBackdrop />;

  return (
    <Box sx={{ minHeight: "100vh", background: "#F8F8F8" }}>
      <Helmet>
        {/* Draft.js support */}
        <meta charSet="utf-8" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <RkdModal />
        <RkdDialog />
        <RkdSnackbar />
        {/* <AddLandForm isOpen={true} onClose={() => {}} /> */}
        <Routes>
          <Route path="/auth/login" element={<LoginPage />} />
          <Route element={<DashboardLayout />}>
            <Route index={true} element={<Home></Home>} />
            <Route path="/example" element={<ExamplePage />} />
            <Route path="/example/edit/:id" element={<ExampleForm key={location.key} />} />
            <Route path="/example/create" element={<ExampleForm key={location.key} />} />
            {/* SUPERADMIN */}
            {/* <Route element={<RouteAuth roles={[Roles.Superadmin]} />}> */}
            <Route path="/users/*" element={<UserPage />} />
            <Route path="/upload-admin/" element={<UploadAdmin />} />
            <Route path="/change-request/" element={<ChangeRequestIndex />} />
            <Route path="/change-request/:id" element={<ChangeRequestDetail />} />
            <Route path="/dashboard-executive" element={<Executive />} />
            <Route path="/dashboard-projects" element={<Projects />} />
            <Route path="/log" element={<GeneralLogPageIndex />} />
            <Route path="/tester" element={<Tester />} />
            <Route path="/master/project-type" element={<ProjectTypeIndex />} />
            <Route path="/master/regional" element={<RegionalPageIndex />} />
            <Route path="/master/project-category" element={<ProjectCategoryAllPageIndex />} />
            <Route path="/master/sub-holding" element={<SubHoldingPageIndex />} />
            <Route path="/master/underlying" element={<UnderlyingPageIndex />} />
            <Route path="/master/asset-category" element={<AssetCategoryPageIndex />} />
            {/* HIDE INVENTORY CATEGORY */}
            {/* <Route path='/master/inventory-category' element={<InventoryCategoryPageIndex />} /> */}
            <Route path="/master/ownership-category" element={<OwnershipCategoryPageIndex />} />
            <Route path="/master/auction-status" element={<AuctionStatusPageIndex />} />
            <Route path="/master/unit-business" element={<UnitBusinessPageIndex />} />
            <Route path="/master/unit-business-category" element={<UnitBusinessCategoryPageIndex />} />
            <Route path="/master/peruntukan" element={<PeruntukanPageIndex />} />
            <Route path="/master/organizations" element={<OrganizationPageIndex />} />
            <Route path="/master/settingApprovals" element={<SettingApprovalPageIndex />} />
            <Route path="/master/project-category-nbd" element={<ProjectCategoryPageIndex />} />
            {/* </Route> */}
            {/* SUPERADMIN || GENERAL */}
            {/* <Route element={<RouteAuth roles={[Roles.Superadmin, Roles.General]} />}> */}
            <Route path="/land/list" element={<LandIndexPage />} />
            <Route path="/land/list/:id" element={<LandDetail />} />
            <Route path="/land/file-manager" element={<FileManagerIndexPage />} />
            {projectCategorList.map((item) => (
              <Route
                path={`/project-monitoring/proposal/list/${item.title}`}
                element={<ProposalIndexPage projectCategory={item.title} projectCategoryId={item.id} />}
              />
            ))}
            {/* <Route
              path={`/project-monitoring/proposal/list/${ProjectCategory.BD}`}
              element={<ProposalIndexPage projectCategory={ProjectCategory.BD} />}
            />
            <Route
              path={`/project-monitoring/proposal/list/${ProjectCategory.NonBD}`}
              element={<ProposalIndexPage projectCategory={ProjectCategory.NonBD} />}
            />
            <Route
              path={`/project-monitoring/proposal/list/${ProjectCategory.Anorganik}`}
              element={<ProposalIndexPage projectCategory={ProjectCategory.Anorganik} />}
            /> */}
            <Route path="/project-monitoring/proposal/list/form" element={<ProposalFormPage />} />
            <Route path="/project-monitoring/fid" element={<FidPageIndex />} />
            {/* <Route path='/project-monitoring/approval/list/:id' element={<ApprovalPageIndex />} /> id === proposalId */}
            <Route path="/project-monitoring/approval/list" element={<ApprovalPageIndex />} />
            <Route path="/project-monitoring/approval/list/:id" element={<ApprovalDetail />} />
            <Route path="/project-monitoring/approval/list/:id/generalInformation" element={<GeneralInformation />} />
            <Route path="/project-monitoring/approval/list/:id/land" element={<Land />} />
            <Route path="/project-monitoring/approval/list/:id/productAnalysis" element={<ProductAnalysis />} />
            <Route path="/project-monitoring/approval/list/:id/marketAnalysis" element={<MarketAnalysis />} />
            <Route path="/project-monitoring/approval/list/:id/costplanAnalysis" element={<CostplanAnalysis />} />
            <Route path="/project-monitoring/approval/list/:id/technicalAnalysis" element={<TechnicalAnalysis />} />
            <Route path="/project-monitoring/approval/list/:id/economyAnalysis" element={<EconomyAnalysis />} />
            <Route path="/project-monitoring/approval/list/:id/collaborationAnalysis" element={<CollaborationAnalysis />} />
            <Route path="/project-monitoring/approval/list/:id/risk" element={<Risk />} />
            <Route path="/project-monitoring/approval/list/:id/legal" element={<Legal />} />
            <Route path="/project-monitoring/approval/list/:id/permission" element={<Permission />} />
            <Route path="/project-monitoring/approval/list/:id/kajianFs" element={<KajianFs />} />
            <Route path="/project-monitoring/approval/list/:id/contructor" element={<Contructor />} />
            <Route path="/project-monitoring/approval/list/:id/attachment" element={<Attachment />} />
            <Route path="/project-monitoring/approval/list/:id/backgroundObjectiveBD" element={<BackgroundObjectiveBD />} />
            <Route path="/project-monitoring/approval/list/:id/backgroundObjective" element={<BackgroundObjective />} />
            <Route path="/project-monitoring/approval/list/:id/scopeOfWork" element={<ScopeOfWork />} />
            <Route path="/project-monitoring/approval/list/:id/timeline" element={<Timeline />} />
            <Route path="/project-monitoring/approval/list/:id/investmentBudget" element={<InvestmentBudget />} />
            <Route path="/project-monitoring/approval/list/:id/drawdown" element={<Drawdown />} />
            <Route path="/project-monitoring/approval/list/:id/costBenefit" element={<CostBenefit />} />
            <Route path="/project-monitoring/approval/list/:id/psRiskMitigation" element={<RiskMitigation />} />
            <Route path="/project-monitoring/approval/list/in-review" element={<ApprovalInReviewPage />} />
            <Route path="/project-monitoring/re-fid/list" element={<ReFidIndex />} />
            <Route path="/project-monitoring/re-fid/list/:id" element={<ReFidDetailPage />} />
            <Route path="/project-monitoring/proposal/list/:projectCategory/request-refid" element={<ReFidFormPage />} />
            {/* </Route> */}
            {/* Error */}
            <Route path="/access-denied" element={<AccessDenied />} />
            <Route path="/server-error" element={<ServerError />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="/view-file" element={<ViewFile />} />
        </Routes>
      </ThemeProvider>
    </Box>
  );
};

export default observer(App);
