import { Button, Dialog, DialogContent, Paper, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ExcelDataGrid } from "./excel-data-grid";
import { observer } from "mobx-react-lite";
import { Close } from "@mui/icons-material";
import { convertExcelGridPreviewToPayload } from "../../../../../../app/utils/utils";
import { PreviewDataExcel } from "../../../../../../app/models/fidMilestones";
import { useState } from "react";
import { useStore } from "../../../../../../app/stores/store";
import useQuery from "../../../../../../app/hook/useQuery";

type TImportDialog = {
  isOpenImportDialog: boolean;
  onClose: () => any;
};

function ImportDialog({ onClose, isOpenImportDialog }: TImportDialog) {
  const { dialogStore, milestonetore } = useStore();
  const { taskPlanningLoading, getMilestone, templateIdentifier, saveImportPlan, saveImportMilestones, resetTemplateIdetifier } = milestonetore;
  const [excelDataPreview, setExcelDataPreview] = useState<PreviewDataExcel[]>([]);
  const query = useQuery();
  const id = query.get("id");

  const successCallback = () => {
    if (!id) return;

    getMilestone(id);
    resetTemplateIdetifier();
    onClose();
  };

  return (
    <Dialog
      fullScreen
      open={isOpenImportDialog}
      onClose={() => {
        onClose();
      }}
    >
      <DialogContent sx={{ flexDirection: "column", display: "flex", padding: "24px", width: "100%", height: "100%" }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight="700" fontSize="14px" color="black">
            Import Excel For Updating Progress & Cost
          </Typography>
          <Close
            sx={{ cursor: "pointer" }}
            onClick={() => {
              onClose();
            }}
          />
        </Stack>
        <Paper sx={{ p: "16px", my: "24px" }}>
          <ExcelDataGrid setExcelDataPreview={setExcelDataPreview} />
        </Paper>
        <Stack direction="row" gap="12px" sx={{ alignItems: "flex-end", justifyContent: "flex-end", height: "100%" }}>
          <Button
            onClick={() => {
              onClose();
            }}
            type="button"
            variant="outlined"
            color="inherit"
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={taskPlanningLoading}
            onClick={() =>
              dialogStore.open({
                title: "Submit Excel Data",
                description: `Are you sure you want to submit this data?`,
                closeText: "Cancel",
                action: async () => {
                  if (!id) return;

                  const payload = convertExcelGridPreviewToPayload(excelDataPreview);

                  templateIdentifier === "plan"
                    ? await saveImportPlan(id, payload).then(() => successCallback())
                    : await saveImportMilestones(id, payload).then(() => successCallback());
                },
                actionText: "Submit",
              })
            }
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default observer(ImportDialog);
