import { Box, CircularProgress, Collapse, TextField, Typography } from "@mui/material";
import ApexCharts from "react-apexcharts";
import SchedulePerformanceIndexTab from "./fid/tabs/SchedulePerformanceIndexTab";
import CostPerformanceIndexTab from "./fid/tabs/CostPerformanceIndexTab";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useStore } from "../../../../app/stores/store";
import useQuery from "../../../../app/hook/useQuery";
import { observer } from "mobx-react-lite";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const spiCpiList = [
  {
    title: "Schedule Performance Index",
    proposal: <SchedulePerformanceIndexTab />,
  },
  {
    title: "Cost Performance Index",
    proposal: <CostPerformanceIndexTab />,
  },
];

function ProposalDashboardTab() {
  const [isOpenId, setIsOpenId] = useState<string>();
  const [year, setYear] = useState<string | null>(null);
  console.log("🚀 ~ ProposalDashboardTab ~ year:", year);
  const { getSPI, getCPI, getSCurveSPI, getSCurveCPI, sCurveSpiLoading, spiLoading, cpiLoading } = useStore().proposalStore;
  const query = useQuery();
  const id = query.get("id");

  useEffect(() => {
    if (!id) return;

    getSPI(id, year);
    getCPI(id, year);
    getSCurveSPI(id, year);
    getSCurveCPI(id, year);
  }, [getCPI, getSCurveCPI, getSCurveSPI, getSPI, id, year]);

  if (cpiLoading || spiLoading || sCurveSpiLoading) {
    return (
      <Box display="flex" justifyContent="center" py="32px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography sx={{ mt: "24px", mb: "24px" }} color="black" fontWeight={700} fontSize="20px">
        Schedule & Cost Performance Index
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Year"
            componentsProps={{
              actionBar: {
                actions: ["clear"],
              },
            }}
            value={year}
            views={["year"]}
            inputFormat="yyyy"
            onChange={(e: any) => setYear(format(new Date(e), "yyyy") === "1970" ? null : format(new Date(e), "yyyy"))}
            renderInput={(props) => (
              <TextField onFocus={(e) => e.target.blur()} disabled size="small" sx={{ width: "100px" }} name="startYear" {...props} />
            )}
          />
        </LocalizationProvider>
      </Box>
      {spiCpiList.map((item) => (
        <Box
          sx={{
            mt: "12px",
            p: "16px",
            cursor: "pointer",
            borderRadius: "4px",
            border: "1px solid #DEDEDE",
            background: "#FFF",
            mb: "12px",
          }}
        >
          <Box
            onClick={() => setIsOpenId((prev) => (prev === item.title ? undefined : item.title))}
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <Box>
              <Box sx={{ fontWeight: "bold", color: "#212121" }}>{item.title}</Box>
              <Box sx={{ fontSize: "12px" }}>Click to see detail</Box>
            </Box>
            <Box>{isOpenId === item.title ? <ExpandLess /> : <ExpandMore />}</Box>
          </Box>
          <Collapse sx={{ mt: "24px" }} in={isOpenId === item.title} timeout="auto" unmountOnExit>
            {item.proposal}
          </Collapse>
        </Box>
      ))}
      <br />
      {/* <BarChart /> */}
    </Box>
  );
}

export default observer(ProposalDashboardTab);

export function BarChart() {
  const series = [
    {
      name: "Dashboard",
      data: [44, 100, 132],
    },
  ];

  return (
    <ApexCharts
      height={350}
      series={series}
      options={{
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            // endingShape: ''
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["Total Budget Plan", "Utilize", "Plan"],
        },
      }}
      type="bar"
    />
  );
}
