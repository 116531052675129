import { ReactElement, useState } from "react";
import agent, { handleBlobResponse } from "../api/agent";
import { Box, BoxProps, CircularProgress } from "@mui/material";
import Buffer from 'buffer';

type Props = BoxProps & {
  proposalId: string;
  url: string;
  loadingComponent?: ReactElement;
};

export default function ViewAttachment({
  children,
  proposalId,
  url,
  loadingComponent,
  ...props
}: Props) {
  const [loading, setLoading] = useState(false);

  const differentTypeAction = ['png', 'jpg', 'jpeg', 'pdf']

  const viewFile = async () => {
    const fileName = url.split("/").slice(-1)[0];
    try {
      setLoading(true)

      const res = await agent.General.getFile(proposalId, fileName);
      let prefix = ''

      if (res.fileExtension.toLowerCase().includes('xlsx') || res.fileExtension.toLowerCase().includes('txt')) {
        await agent.General.getFileWithDifferentBehaviour(proposalId, fileName)
      } else {
        if (res.fileExtension.toLowerCase().includes('png')) prefix = 'data:image/png;base64,'
        if (res.fileExtension.toLowerCase().includes('jpg')) prefix = 'data:image/jpg;base64,'
        if (res.fileExtension.toLowerCase().includes('jpeg')) prefix = 'data:image/jpeg;base64,'
        if (res.fileExtension.toLowerCase().includes('pdf')) prefix = 'data:application/pdf;base64,'

        const url = prefix + res.bytes

        const html = `
          <html style="margin:0; padding:0;">
            <body style="margin:0; padding:0;">
              <iframe src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%; backgroundColor:black;" allowfullscreen></iframe>
            </body>
          </html>
        `

        const newWin = window.open()
        newWin?.document.open();
        newWin?.document.write(html)
        newWin?.document.close()
      }


    } finally {
      setLoading(false)
    }
  }

  return (
    loading ? (
      !!loadingComponent ? (
        loadingComponent
      ) : (
        <CircularProgress />
      )
    ) : (
      <>
        <Box
          onClick={viewFile}
          sx={{ textDecoration: "none", cursor: 'pointer' }}
        >
          {children}
        </Box>
      </>
    )
  );
}
