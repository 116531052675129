import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { DescriptionOutlined } from "@mui/icons-material";
import ViewAttachment from "../../app/component/ViewAttachment";
import EmptyState from "../../app/component/EmptyState";
import { TRefidSubmitAttachments } from "../../app/models/reFid";

type TRequesterNotes = {
  attachmentList: TRefidSubmitAttachments[];
  notes: string;
  proposalId: string;
};

export default function RequesterNotes({ proposalId, attachmentList = [], notes }: TRequesterNotes) {
  return (
    <Box>
      {notes !== "" ? (
        <>
          {/* <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AccountCircleIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Typography color="black">{item?.username}</Typography>}
                secondary={
                  <Box>
                    <Typography sx={{}}>Summary Review: {item?.summaryReview ?? ""}</Typography>
                    <Typography sx={{}}>Reecommendations: {item?.recommendation ?? ""}</Typography>
                  </Box>
                }
              />
            </ListItem> */}
          <hr />
          <Box sx={{ ml: "18px" }}>
            <Typography fontWeight="bold" color="black" sx={{ textAlign: "center", mb: "12px" }}>
              Attachment List
            </Typography>
            {attachmentList.length > 0 ? (
              <Grid container spacing={2}>
                {attachmentList.map((attachment: any) => (
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        p: "8px",
                        borderRadius: "4px",
                        border: "1px solid rgba(29, 27, 32, 0.12)",
                      }}
                    >
                      <ViewAttachment loadingComponent={<Skeleton width={150} variant="text" />} url={attachment.url} proposalId={proposalId}>
                        <DescriptionOutlined sx={{ cursor: "pointer" }} />
                      </ViewAttachment>
                      <Box
                        sx={{
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                          display: "flex",
                          ml: "12px",
                        }}
                      >
                        <Box sx={{ fontWeight: "bold" }}>{attachment.fileName ?? "No Name"}</Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : (
              ""
            )}
          </Box>
        </>
      ) : (
        <EmptyState />
      )}
    </Box>
  );
}
