import React, { Dispatch, SetStateAction } from "react";
import { ITaskSubTaskAttachment } from "../../../../../../../app/models/taskSubTaskAttachment";
import { Box, Grid, Skeleton } from "@mui/material";
import { DescriptionOutlined } from "@mui/icons-material";
import CommentAttachment from "./CommentAttachment";
import ViewAttachment from "../../../../../../../app/component/ViewAttachment";
import useQuery from "../../../../../../../app/hook/useQuery";

type TFilteredHistoryCollapse = {
  data: ITaskSubTaskAttachment[];
  subTask: any;
  setIsOpenDrawer: Dispatch<SetStateAction<any>>;
};

export default function FilteredHistoryCollapse({ setIsOpenDrawer, data, subTask }: TFilteredHistoryCollapse) {
  const query = useQuery();
  const id = query.get("id");

  return (
    <Box>
      {data?.map((item: ITaskSubTaskAttachment) => (
        <Grid key={item.id} item xs={12} spacing={6} sx={{ mb: "12px" }}>
          <Box
            sx={{
              width: "100%",
              alignItems: "center",
              display: "flex",
              p: "16px",
              borderRadius: "4px",
              border: "1px solid rgba(29, 27, 32, 0.12)",
            }}
          >
            <ViewAttachment loadingComponent={<Skeleton width={150} variant="text" />} url={item.url} proposalId={id ?? ""}>
              <DescriptionOutlined sx={{ cursor: "pointer" }} />
            </ViewAttachment>
            <Box sx={{ width: "100%", justifyContent: "space-between", alignItems: "center", display: "flex", ml: "12px" }}>
              <Box>
                <Box sx={{ fontWeight: "bold" }}>{item.fileName}</Box>
                {item.description ? <Box sx={{ fontSize: "12px" }}>{item.description}</Box> : <Box></Box>}
                <Box sx={{ fontSize: "12px" }}>{item.submittedOnStepDescription}</Box>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CommentAttachment
                  setIsOpenDrawer={setIsOpenDrawer}
                  subTask={subTask}
                  taskId={item.tasksId}
                  attachmentId={item.id}
                  attachmentStatus={item.status}
                  attachmentComment={item.notes}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
    </Box>
  );
}
