import { FileDownloadOutlined } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useStore } from "../../../../../../app/stores/store";
import useQuery from "../../../../../../app/hook/useQuery";

type TDownloadDialog = {
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
};

function DownloadDialog({ openDialog, setOpenDialog }: TDownloadDialog) {
  const query = useQuery();
  const id = query.get("id");
  const { milestonetore } = useStore();
  const { downloadTemplateMilestones, downloadTemplatePlan, loadingDownloadTemplate } = milestonetore;
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const descriptionElementRef = useRef<HTMLElement>(null);

  const handleClose = () => setOpenDialog(false);

  return (
    <Dialog maxWidth="xl" open={openDialog} onClose={handleClose}>
      <DialogTitle id="scroll-dialog-title">Choose Template</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          <Box sx={{ gap: "12px", display: "flex" }}>
            <Button
              disabled={loadingDownloadTemplate}
              onClick={() => downloadTemplateMilestones(id ?? "").then(handleClose)}
              variant="outlined"
              startIcon={<FileDownloadOutlined />}
            >
              Download Milestones Template
            </Button>
            <Button
              disabled={loadingDownloadTemplate}
              onClick={() => downloadTemplatePlan(id ?? "").then(handleClose)}
              variant="outlined"
              startIcon={<FileDownloadOutlined />}
            >
              Download Plan Template
            </Button>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default observer(DownloadDialog);
