import React from "react";
import { Grid, Skeleton } from "@mui/material";

export default function LoadingSection() {
  return (
    <Grid container spacing={"24px"}>
      <Grid item md={6}>
        <Skeleton variant="rounded" width="100%" height={56} />
      </Grid>
      <Grid item md={6}>
        <Skeleton variant="rounded" width="100%" height={56} />
      </Grid>
      <Grid item md={6}>
        <Skeleton variant="rounded" width="100%" height={56} />
      </Grid>
      <Grid item md={6}>
        <Skeleton variant="rounded" width="100%" height={56} />
      </Grid>
      <Grid item md={6}>
        <Skeleton variant="rounded" width="100%" height={56} />
      </Grid>
      <Grid item md={6}>
        <Skeleton variant="rounded" width="100%" height={56} />
      </Grid>
    </Grid>
  );
}
