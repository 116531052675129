import { ReactElement, useEffect, useMemo, useState } from "react";
import { Box, Stack, TextField, Typography, Collapse, Grid } from "@mui/material";
import { AttachFile, ExpandLess, ExpandMore, HandymanOutlined } from "@mui/icons-material";
import { FillFormItem } from "../../FillFormItem";
import { useStore } from "../../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import ProposalConstuctorForm from "../../form/ProposalConstuctorForm";
import ProposalAttachmentForm from "../../form/ProposalAttachmentForm";
import PreparationFillForm from "../../PreparationFillForm";
import CSelect from "../../../../../app/component/CSelect";
import useQuery from "../../../../../app/hook/useQuery";
import ProposalFillForm from "../../ProposalFillForm";

type DrawerState = {
  constructor: boolean;
  attachment: boolean;
};

type FormList = {
  text: string;
  icon: ReactElement;
  name: keyof DrawerState;
  added: boolean;
  disabled?: boolean;
};

function ProjectClosingStepForm() {
  const { getProjectCategory, projectCategory, projectCategoryLoading } = useStore().projectCategoryStore;
  const { generalInformation, getGeneralInformation, constructorsLoading, attachmentLoading, getConstructor, constructors, attachment } =
    useStore().proposalStore;
  const [indexCollapse, setIndexCollapse] = useState<number>();
  const [open, setOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const [cost, setCost] = useState<number>();
  const query = useQuery();
  const currentProjectCategory = query.get("projectCategory");
  const proposalId = query.get("id");

  const handleClick = (index: number) => {
    setOpen((prev) => !prev);
    setIndexCollapse(index);
  };

  const [drawer, setDrawer] = useState<DrawerState>({
    constructor: false,
    attachment: false,
  });

  const generalFormList: FormList[] = useMemo(
    () => [
      // {
      //   text: "Vendor Info",
      //   icon: <HandymanOutlined />,
      //   name: "constructor",
      //   added: constructors && constructors.length > 0 ? true : false,
      //   disabled: constructorsLoading,
      // },
      {
        text: "Attachment",
        icon: <AttachFile />,
        name: "attachment",
        added: attachment && attachment.length > 0 ? true : false,
        disabled: attachmentLoading,
      },
    ],
    [attachment, attachmentLoading]
  );

  const collapseDataProjectClosing = [
    {
      title: "Proposal",
      proposal: <ProposalFillForm isHistoryView={true} />,
    },
    {
      title: "Preparations",
      proposal: <PreparationFillForm isHistoryView={true} />,
    },
  ];

  const openForm = (name: Partial<keyof DrawerState>) => {
    setDrawer((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const closeForm = (name: Partial<keyof DrawerState>) => {
    setDrawer((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  useEffect(() => {
    getGeneralInformation(proposalId ?? "");
    getProjectCategory();
  }, [getProjectCategory, getGeneralInformation, proposalId]);

  useEffect(() => {
    setTitle(generalInformation?.title);
    setCost(generalInformation?.cost);
  }, [setTitle, generalInformation, setCost]);

  useEffect(() => {
    const id = query.get("id");

    if (!id) return;

    getConstructor(id);
  }, [getConstructor, query]);

  return (
    <Box>
      <Box mb="24px">
        <Stack gap="24px">
          {!!generalInformation?.title && !!generalInformation.cost && (
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Typography sx={{ mb: "12px" }} color="black" fontWeight={700} fontSize="20px">
                  Title
                </Typography>
                <TextField fullWidth disabled placeholder="Title" value={title} />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ mb: "12px" }} color="black" fontWeight={700} fontSize="20px">
                  Cost
                </Typography>
                <TextField type="number" fullWidth placeholder="Cost" value={cost} disabled />
              </Grid>
            </Grid>
          )}
          <CSelect
            loading={projectCategoryLoading}
            disabled
            label="Kategori Proyek"
            options={projectCategory.map((i) => ({ label: i.text, value: i.value }))}
            value={currentProjectCategory}
          />
        </Stack>
      </Box>
      <Box sx={{ my: "24px" }}>
        <hr />
      </Box>
      <Box sx={{ mb: "24px" }}>
        <Grid container spacing={3}>
          {generalFormList.map((item) => (
            <Grid item xs={6} key={item.name} onClick={() => !item.disabled && openForm(item.name as any)}>
              <FillFormItem {...item} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ fontWeight: "bold", fontSize: "20px", color: "#000", mb: "12px" }}>History</Box>
      {collapseDataProjectClosing.map((item, index) => (
        <Box
          sx={{
            p: "16px",
            cursor: "pointer",
            borderRadius: "4px",
            border: "1px solid #DEDEDE",
            background: "#FFF",
            mb: "12px",
          }}
        >
          <Box onClick={() => handleClick(index)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box>
              <Box sx={{ fontWeight: "bold", color: "#212121" }}>{item.title}</Box>
              <Box sx={{ fontSize: "12px" }}>Click to see detail</Box>
            </Box>
            <Box>{open && indexCollapse === index ? <ExpandLess /> : <ExpandMore />}</Box>
          </Box>
          <Collapse sx={{ mt: "24px" }} in={open && indexCollapse === index} timeout="auto" unmountOnExit>
            {item.proposal}
          </Collapse>
        </Box>
      ))}
      {/* <ProposalConstuctorForm readOnly={true} isOpen={drawer.constructor} handleClose={() => closeForm("constructor")} /> */}
      <ProposalAttachmentForm readOnly={true} isOpen={drawer.attachment} handleClose={() => closeForm("attachment")} />
    </Box>
  );
}

export default observer(ProjectClosingStepForm);
