import { createTheme  } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';

let theme: Theme = createTheme({
  palette: {
      background:{
        default: '#f9fafb',
        paper: '#ffffff'
      },
      primary: {          
        main: '#0664B4',
        light: '#F2F9FF', 
        contrastText: '#ffffff'         
      },
      secondary: {        
        main: '#00c1db', 
        light: '#ffffff', 
        contrastText: '#637381'      
      },
      error: {
        main: '#DB1A22',  
        contrastText: '#ffffff'       
      },  
      success: {
        main: '#66bb6a',  
        contrastText: '#ffffff'       
      },            
      warning: {
        main: '#ffc107',
        contrastText: '#ffffff'
      },
      info: {
        main: '#637381',
        light: '#edeff1'
      }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#999999'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '16px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'capitalize',
        },
        text: {
          textTransform: 'capitalize',
          fontSize: '14px',
          fontWeight: 500
        },
        sizeMedium: {
          padding: '10px 24px',
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000"
          }
        }
      }
    }
  },
  
  typography: {
    "fontFamily": `"Montserrat", Roboto, sans-serif`,
    h1: {      
      fontSize: 24,
      fontWeight: 700,
      color: '#534e48'
    },
    h2: {
      fontSize: 18,
      fontWeight: 600,
      color: '#534e48'
    },
    h3: {
      fontSize: 14,
      fontWeight: 500,
      color: '#534e48'
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 400,
      color: '#534e48'
    },
    body1: {
      fontSize: 14,
      fontWeight: 500,
      color: '#534e48'
    },
    button: {
      fontSize:12,
      color: '#534e48',
      fontWeight: 600,      
    }
  },
});

export default theme;