import { useFormik } from "formik";
import DrawerForm from "../../app/component/DrawerForm";
import * as yup from 'yup';
import { TextField } from "@mui/material";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";

const validationSchema = yup.object({
  name: yup.string().required(),
})

type ValidationSchema = yup.InferType<typeof validationSchema>

function RegionalForm({ isOpen, onClose }: {isOpen: boolean, onClose: () => any}) {
  const { addRegional, selectedRegional, editRegional, getRegional, setSelectedRegional } = useStore().regionalStore
  
  const initialValues: ValidationSchema = useMemo(() => {
    if (selectedRegional) {
      return selectedRegional
    } else {
      return { name: '' }
    }
  }, [selectedRegional])

  const onSubmit = async (values: ValidationSchema) => {
    if (selectedRegional) {
      await editRegional(selectedRegional.id, values)
        .then(() => {
          getRegional()
          onClose()
          setSelectedRegional(null)
          resetForm()
          setErrors({
            name: undefined
          })
        })
    } else {
      await addRegional(values)
        .then(() => {
          getRegional()
          onClose()
          setSelectedRegional(null)
          resetForm()
          setErrors({
            name: undefined
          })
        })
    }
  }

  const { handleSubmit, resetForm, setErrors, handleChange, values, errors, isValid, isSubmitting, dirty } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  })
  
  return (
    <DrawerForm
      title={`${selectedRegional ? 'Edit' : 'Add'} Regional`}
      isOpen={isOpen || !!selectedRegional}
      onClose={() => {
        onClose()
        setSelectedRegional(null)
      }}
      onSubmit={handleSubmit}
      disableAction={!isValid || !dirty}
      isLoading={isSubmitting}
      isEdit={!!selectedRegional}
    >
      <TextField 
        label="Name"
        fullWidth
        name="name"
        value={values.name}
        error={!!errors.name}
        helperText={errors.name}
        onChange={handleChange}
      />
    </DrawerForm>
  )
}

export default observer(RegionalForm)