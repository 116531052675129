import { Fade, IconButton, Menu, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { ILand } from "../../app/models/land";
import { useStore } from "../../app/stores/store";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Link } from "react-router-dom";
import { Roles } from "../../app/config/enum";

interface actionProps {
  data: ILand;
}

function LandActionButton(props: actionProps) {
  const { data } = props;
  const { account } = useStore().accountStore

  const { dialogStore, landStore } = useStore();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setAnchorEl(null);
    landStore.setSelectedLand(props.data);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleDeleteLand = () =>
    dialogStore.open({
      title: "Delete",
      description: "Are you sure want to remove this data?",
      closeText: "Cancel",
      actionText: "Delete",
      action: async () => 
        landStore
          .deleteLand([{ id: String(data.id) }])
          .then(() => landStore.setSelectedLand(null)),
      actionButtonProps: {
        color: "error",
      },
    });

  return (
    <Box>
      <IconButton
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon sx={{ color: "gray" }} />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          component={Link}
          to={`/land/list/${data.id}`}
          onClick={handleClose}
        >
          <RemoveRedEyeOutlinedIcon sx={{ mr: 1 }} />
          View
        </MenuItem>
        <MenuItem disabled={data.isDeleted || account?.roles.includes(Roles.General)} onClick={handleEdit}>
          <EditOutlined sx={{ mr: 1 }} />
          Edit
        </MenuItem>
        <MenuItem
          disabled={data.isDeleted || account?.roles.includes(Roles.General)}
          sx={{ color: "red" }}
          onClick={() => handleDeleteLand()}
        >
          <DeleteOutlineOutlinedIcon sx={{ mr: 1 }} color="error" /> Delete
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default LandActionButton;
