import { Button, Dialog, DialogContent, Stack, TextField, Typography } from "@mui/material";
import DraggableFileUpload, { DraggableFileUploadData } from "../../../../app/component/input/DraggableFileUpload";
import { Dispatch, SetStateAction, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import useQuery from "../../../../app/hook/useQuery";
import { history } from "../../../..";
import Connector from "../../../../signalr-connection";

function DecisionGateDialog({
  isOpen,
  onClose,
  projectCategory,
  isLoading,
  isInProgresStep,
  setIsLoading,
}: {
  isLoading: boolean;
  isInProgresStep: boolean;
  onClose: () => any;
  isOpen: boolean;
  projectCategory: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}) {
  const { nextProjectStatus, promoteProposalToDecisionGate } = useStore().projectManagementStore;
  const { show } = useStore().snackbarStore;
  const { newMessage } = Connector();
  const [recommendations, setRecommendations] = useState<string>("");
  const [data, setData] = useState<DraggableFileUploadData[]>([]);
  const [summaryReview, setSummaryReview] = useState<string>("");
  const [error, setError] = useState("");
  const query = useQuery();
  const id = query.get("id");

  const onSubmit = async () => {
    if (!id) return;

    setIsLoading(true);

    try {
      const formData = new FormData();

      data.forEach((item, index) => {
        formData.append(`attachments[${index}].file`, item.file as File);
        formData.append(`attachments[${index}].fileName`, "fileName");
      });
      formData.append("summaryReview", summaryReview);
      formData.append("recommendation", recommendations);

      if (nextProjectStatus) {
        await promoteProposalToDecisionGate(id, formData)
          .then(() => {
            show("success", "Promote proposal sukses");
            history.push(`/project-monitoring/proposal/list/${projectCategory}`);
            newMessage(new Date().toISOString());
          })
          .finally(() => {
            setIsLoading(false);
          });
      }

      handleClose();

      history.push(`/project-monitoring/proposal/list/${projectCategory}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setData([]);
    // setError("");
    setIsLoading(false);

    onClose();
  };

  const handleTextChange = (e: any, identifier: string) =>
    identifier === "recommendations" ? setRecommendations(e.target.value) : setSummaryReview(e.target.value);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent sx={{ width: "365px" }}>
        <Typography color="black" fontWeight={700} fontSize="14px">
          Info
        </Typography>
        <Typography mb="16px" color="black" mt="24px">
          Add summary & recommendations for decision gate review
        </Typography>
        <TextField
          onChange={(e) => handleTextChange(e, "summary")}
          sx={{ mb: "12px" }}
          fullWidth
          label="Summary Review"
          name="summaryReview"
          multiline
          rows={5}
          error={!!error}
          helperText={error}
        />
        <TextField
          onChange={(e) => handleTextChange(e, "recommendations")}
          sx={{ mb: "12px" }}
          fullWidth
          label="Recommendation"
          name="recommendations"
          multiline
          rows={5}
          error={!!error}
          helperText={error}
        />
        <DraggableFileUpload data={data} setData={setData} editableTitle={false} accept="application/pdf, image/*" />
        <Stack mt={2} justifyContent="flex-end" direction="row" gap={1}>
          <Button onClick={handleClose} color="inherit" variant="outlined">
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} loading={isLoading} variant="contained" onClick={onSubmit}>
            Submit
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default observer(DecisionGateDialog);
