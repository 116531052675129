import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Drawer, IconButton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import TextEditor from "../../../../app/component/TextEditor";
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import useQuery from "../../../../app/hook/useQuery";
import { FlagEdit } from "../../../../app/config/enum";
import DraggableFileUploadV2, { DraggableFileUploadData } from "../../../../app/component/input/DraggableFileUploadV2";
import CommentList from "./components/CommentList";

const validationSchema = yup.object({
  items: yup.array().of(
    yup.object({
      BasicLegalReference: yup.string(),
      LimitationOfLegalReview: yup.string(),
      LegalStudies: yup.string(),
      Conclusion: yup.string(),
    })
  ),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function ProposalLegalForm({ isOpen, handleClose, readOnly }: { isOpen: boolean; handleClose: () => any; readOnly: boolean }) {
  const [loadingAttc, setLoadingAttc] = useState(false);
  const [files, setFiles] = useState<DraggableFileUploadData[]>([]);
  const { addLegal, legal, getLegal, deleteAttachment, editLegal } = useStore().proposalStore;
  const query = useQuery();
  const id = query.get("id");

  const initialValues: any = useMemo(() => {
    if (!!legal && legal.length > 0) {
      const res = legal.map((object) => {
        return {
          id: object.id,
          BasicLegalReference: object.basicLegalReference,
          LimitationOfLegalReview: object.limitationOfLegalReview,
          LegalStudies: object.legalStudies,
          Conclusion: object.conclusion,
        };
      });

      return { items: res };
    } else {
      return {
        items: [
          {
            BasicLegalReference: undefined,
            LimitationOfLegalReview: undefined,
            LegalStudies: undefined,
            Conclusion: undefined,
          },
        ],
      };
    }
  }, [legal]);

  const onSubmit = async (values: ValidationSchema) => {
    if (readOnly) return;

    const id = query.get("id");

    if (!id) return;

    const formData = new FormData();

    files.forEach((file, index) => {
      if (file.data?.id) {
        formData.append(`legalAnalyses[0].attachments[${index}].attachment`, file.data.attachment as File);
        formData.append(`legalAnalyses[0].attachments[${index}].fileName`, "");
      } else {
        formData.append(`legalAnalyses[0].attachments[${index}].attachment`, file.file as File);
        formData.append(`legalAnalyses[0].attachments[${index}].fileName`, "");
      }
    });

    values.items?.forEach((item, index) => {
      item.BasicLegalReference && formData.append(`legalAnalyses[${index}].BasicLegalReference`, item.BasicLegalReference);
      item.LimitationOfLegalReview && formData.append(`legalAnalyses[${index}].LimitationOfLegalReview`, item.LimitationOfLegalReview);
      item.LegalStudies && formData.append(`legalAnalyses[${index}].LegalStudies`, item.LegalStudies);
      item.Conclusion && formData.append(`legalAnalyses[${index}].Conclusion`, item.Conclusion);

      if (legal && legal.length > 0) {
        const i = item as any;
        i.id && formData.append(`legalAnalyses[${index}].id`, i.id);
        formData.append(`legalAnalyses[${index}].flagEdit`, FlagEdit.PROPOSAL.toString());
      }
      files.forEach((file, x) => {
        if (file.data?.id) {
          // formData.append(`legalAnalyses[0].attachments[${index}].attachment`, file.data.attachment as File)
          // formData.append(`legalAnalyses[0].attachments[${index}].fileName`, '')
          formData.append(`legalAnalyses[${index}].attachments[${x}].id`, file.data.id);
        } else {
          formData.append(`legalAnalyses[${index}].attachments[${x}].attachment`, file.file as File);
          formData.append(`legalAnalyses[${index}].attachments[${x}].fileName`, "");
        }
      });
    });

    if (legal && legal.length > 0) {
      await editLegal(id, formData as any).then(() => {
        handleClose();
        resetForm();
        setErrors({ items: undefined });
        getLegal(id);
      });
    } else {
      await addLegal(id, formData as any).then(() => {
        handleClose();
        resetForm();
        setErrors({ items: undefined });
        getLegal(id);
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const { handleSubmit, isSubmitting, submitForm, resetForm, setErrors, setFieldValue, values, isValid, dirty } = formik;

  useEffect(() => {
    if (legal) {
      legal.map((item) => {
        setFiles(
          item.attachments.map((attachment) => ({
            file: {
              name: attachment.fileName,
            },
            data: attachment,
          }))
        );
      });
    }
  }, [legal]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Drawer
        anchor="right"
        open={isOpen}
        sx={{
          ".MuiPaper-root": {
            width: "500px",
          },
        }}
      >
        <Box
          sx={{
            m: "16px 16px 0 16px",
            pb: "8px",
            borderBottom: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "space-between",
            mb: "16px",
          }}
        >
          <Typography fontSize={"14px"} fontWeight={700} color="black">
            {!!legal && legal.length > 0 ? "Edit" : "Add"} Legal
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Stack flex={1} overflow="auto" p="16px" spacing="24px">
          {values.items?.map((value, index) => (
            <>
              <Box>
                <TextEditor
                  label="Dasar Hukum dan Referensi Dokumen"
                  value={value.BasicLegalReference}
                  onChange={(e) => setFieldValue(`items[${index}].BasicLegalReference`, e)}
                />
              </Box>
              <Box>
                <TextEditor
                  label="Batasan Kajian Hukum"
                  value={value.LimitationOfLegalReview}
                  onChange={(e) => setFieldValue(`items[${index}].LimitationOfLegalReview`, e)}
                />
              </Box>
              <Box>
                <TextEditor label="Kajian Hukum" value={value.LegalStudies} onChange={(e) => setFieldValue(`items[${index}].LegalStudies`, e)} />
              </Box>
              <Box>
                <TextEditor label="Kesimpulan" value={value.Conclusion} onChange={(e) => setFieldValue(`items[${index}].Conclusion`, e)} />
              </Box>
              <Box>
                <DraggableFileUploadV2
                  data={files}
                  setData={setFiles}
                  accept="application/pdf,application/msword,image/*"
                  loading={loadingAttc}
                  viewable
                  viewableOptions={{ propsoalId: id ?? "" }}
                  onDelete={(data) => {
                    if (!id) return;

                    setLoadingAttc(true);

                    deleteAttachment(id, data.id)
                      .then(() => getLegal(id))
                      .finally(() => setLoadingAttc(false));
                  }}
                />
              </Box>
            </>
          ))}
          {isOpen && legal ? <CommentList isOpen={isOpen} data={legal} /> : ""}
        </Stack>
        <Box
          sx={{
            m: "0 16px 16px 16px",
            pt: "16px",
            borderTop: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <LoadingButton loading={isSubmitting} disabled={!isValid || !dirty || readOnly} type="submit" variant="contained" onClick={submitForm}>
            {!!legal && legal.length > 0 ? "Edit" : "Add"}
          </LoadingButton>
        </Box>
      </Drawer>
    </form>
  );
}

export default observer(ProposalLegalForm);
