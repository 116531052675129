import { DescriptionOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Box, Grid, Skeleton } from "@mui/material";
import ViewAttachment from "../../../app/component/ViewAttachment";
import { useParams } from "react-router-dom";

type TAttachtmentSectionProps = {
  attachtment: TAttachtment[];
};

type TAttachtment = {
  fileName: string;
  id: string;
  url: string;
};

export default function AttachmentSection({ attachtment }: TAttachtmentSectionProps) {
  const { id } = useParams();
  return (
    <div>
      {attachtment.length > 0 ? (
        <>
          <Box sx={{ mt: "24px", fontSize: "24px", fontWeight: "bold" }}>Attachments</Box>
          <Grid pt="12px" container spacing={3}>
            {attachtment?.map((item) => (
              <Grid key={item.id} item xs={6} spacing={3}>
                <Box
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    p: "16px",
                    borderRadius: "4px",
                    border: "1px solid rgba(29, 27, 32, 0.12)",
                  }}
                >
                  <DescriptionOutlined />
                  <Box sx={{ width: "100%", justifyContent: "space-between", alignItems: "center", display: "flex", ml: "12px" }}>
                    <Box>
                      <Box sx={{ fontWeight: "bold" }}>{item.fileName}</Box>
                    </Box>
                    <ViewAttachment loadingComponent={<Skeleton width={150} variant="text" />} url={item.url} proposalId={id ?? ""}>
                      <VisibilityOutlined sx={{ cursor: "pointer" }} />
                    </ViewAttachment>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
