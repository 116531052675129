import * as signalR from "@microsoft/signalr";
import { INotification } from "./app/models/notification";

const URL = process.env.REACT_APP_API_NOTIFICATIONS;

class Connector {
  private connection: signalR.HubConnection;
  public events: (onMessageReceived: (username: string, message: INotification) => void) => void;
  static instance: Connector;
  constructor() {
    this.connection = new signalR.HubConnectionBuilder().withUrl(URL!).withAutomaticReconnect().build();
    this.connection.start().catch((err) => document.write(err));
    this.events = (onMessageReceived) => {
      this.connection.on("messageReceived", (username, message) => onMessageReceived(username, message));
    };
  }
  public newMessage = (messages: any) => {
    this.connection.send("NewMessage", "foo", messages).then((x) => console.log("sent"));
  };
  public static getInstance(): Connector {
    if (!Connector.instance) Connector.instance = new Connector();
    return Connector.instance;
  }
}
export default Connector.getInstance;
