import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  onClose: () => any;
  isOpen: boolean;
  disableAction?: boolean;
  isLoading?: boolean;
  onSubmit: () => any;
  children: ReactNode;
  title: string;
  isEdit: boolean;
  customButton?: ReactNode;
}

export default function DrawerForm({ onClose, isOpen, isEdit, title, disableAction, isLoading, onSubmit, children, customButton }: Props) {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      sx={{
        '.MuiPaper-root': {
          width: '500px'
        }
      }}
    >
      <Box component="form" onSubmit={onSubmit} noValidate display="flex" flexDirection="column" flex={1}>
        <Box
          sx={{
            m: '16px 16px 0 16px',
            pb: '8px',
            borderBottom: '1px solid #EAEAEA',
            display: 'flex',
            justifyContent: 'space-between',
            mb: "16px",
            alignItems: 'center'
          }}
        >
          <Typography fontSize={'14px'} fontWeight={700} color="black">
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Stack flex={1} overflow="auto" p="16px" spacing="24px">
          {children}
        </Stack>
        {customButton 
          ? <Box
              sx={{
                m: '0 16px 16px 16px',
                pt: '16px',
                borderTop: '1px solid #EAEAEA',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '12px'
              }}
            >
              {customButton}
            </Box>
          : (
            <Box
              sx={{
                m: '0 16px 16px 16px',
                pt: '16px',
                borderTop: '1px solid #EAEAEA',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <LoadingButton
                variant="contained"
                loading={isLoading}
                disabled={disableAction}
                type="submit"
              >
                {isEdit ? 'Edit' : 'Add'}
              </LoadingButton>
            </Box>
          )
        }
      </Box>
    </Drawer>
  )
}