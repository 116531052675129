import { Box } from "@mui/material";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useStore } from "../../app/stores/store";

interface BarChartGridProps {
  title?: string;
  series: number[];
  name: string;
  labels: string[];
  currently: number;
  isCost?: boolean;
  isClicked?: boolean;
}

function BarChartGrid({ isCost, title, series, name, labels, currently, isClicked = true }: BarChartGridProps) {
  const { setDataPointIndexBarChart } = useStore().DashboardStore;
  const ref = useRef(null);

  useEffect(() => {
    const options = {
      series: [
        {
          name: name,
          data: series,
        },
      ],
      chart: {
        height: 350,
        width: "100%",
        type: "bar",
        events: {
          dataPointSelection: function (event: any, chartContex: any, config: any) {
            if (isClicked) {
              if (isCost) {
                setDataPointIndexBarChart(config.dataPointIndex === 0 ? "Plan" : "Realization");
              } else {
                setDataPointIndexBarChart(config.dataPointIndex);
              }
            }
          },
          xAxisLabelClick: function (event: any, chartContex: any, config: any) {
            if (isClicked) {
              if (isCost) {
                setDataPointIndexBarChart(config.labelIndex === 0 ? "Plan" : "Realization");
              } else {
                setDataPointIndexBarChart(config.labelIndex);
              }
            }
          },
        },
        toolbar: {
          export: {
            csv: {
              filename: `${title} - ${format(new Date(), "dd MMM yyyy")}`,
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp: any) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: `${title} - ${format(new Date(), "dd MMM yyyy")}`,
            },
            png: {
              filename: `${title} - ${format(new Date(), "dd MMM yyyy")}`,
            },
          },
        },
      },
      colors: ["rgb(0, 143, 251)", "#8BCE4A"],
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top",
          },
          distributed: true,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        labels: {
          formatter: function (val: string) {
            return val.length > 25 ? `${val.substring(0, 24)}...` : val;
          },
          style: {
            colors: [],
            fontSize: "8px",
            fontWeight: "bold",
            cssClass: "apexcharts-xaxis-label",
          },
        },
        // tickPlacement: "on",
        categories: labels,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return isCost ? "Rp" + (val / 1000000).toLocaleString() : val;
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            return isCost ? "Rp" + (val / 1000000).toLocaleString() : val;
          },
        },
      },
    };

    const chart = new ApexCharts(ref.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [title, series, name, isCost, setDataPointIndexBarChart]);

  return (
    <Box
      sx={{ width: "100%" }}
      bgcolor="white"
      // display="flex"
      pt={3}
      // justifyContent="space-between"
      // alignItems="flex-end"
    >
      <div ref={ref} />
      {/* <Box pr={2}>
        <table>
          {labels.map((item, index) => (
            <tr>
              <td style={{ paddingBottom: '20px' }}>
                <Typography fontWeight={700} color="black">{item}</Typography>
              </td>
              <td style={{ paddingBottom: '20px' }}>
                <Typography whiteSpace="nowrap" ml="20px" textAlign="right" fontWeight={700}>Rp {series[index].toLocaleString()}</Typography>
              </td>
            </tr>
          ))}
          <tr>
              <td style={{ paddingBottom: '20px' }}>
                <Typography fontWeight={700} color="black">Currently</Typography>
              </td>
              <td style={{ paddingBottom: '20px' }}>
                <Stack>
                  <Typography color={currently > 100 ? "#E4463D" : undefined} textAlign="right" fontWeight={700}>{currently}%</Typography>
                  <Typography color={currently > 100 ? "#E4463D" : undefined} textAlign="right">{currently > 100 ? 'Over Target' : 'Of Target'}</Typography>
                </Stack>
              </td>
            </tr>
        </table>
      </Box> */}
    </Box>
  );
}

export default observer(BarChartGrid);
