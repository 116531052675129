import {
  Add,
  AttachFile,
  AttachMoneyRounded,
  AutoGraphRounded,
  EditOutlined,
  FactCheckOutlined,
  HandshakeOutlined,
  HandymanOutlined,
  InfoOutlined,
  MapRounded,
  QueryStatsOutlined,
  Villa,
} from "@mui/icons-material";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { ReactElement, useEffect, useMemo, useState } from "react";
import ProposalGeneralInformationForm from "./form/ProposalGeneralInformationForm";
import ProposalProductAnalysis from "./form/ProposalProductAnalysis";
import ProposalMarketAnalysisForm from "./form/ProposalMarketAnalysisForm";
import ProposalCostplanAnalysisForm from "./form/ProposalCostplanAnalysisForm";
import ProposalEconomyAnalysisForm from "./form/ProposalEconomyAnalysisForm";
import ProposalCollaborationAnalysisForm from "./form/ProposalCollaborationAnalysisForm";
import ProposalFIDForm from "./form/ProposalFIDForm";
import ProposalAttachmentForm from "./form/ProposalAttachmentForm";
import { useStore } from "../../../app/stores/store";
import useQuery from "../../../app/hook/useQuery";
import { observer } from "mobx-react-lite";
import { BD_OTHERS_NAME, NON_BD_OTHERS_NAME, ProjectCategory, ProjectStatus } from "../../../app/config/enum";
import ProposalRiskForm from "./form/ProposalRiskForm";
import ProposalLandForm from "./form/ProposalLandForm";
import ProposalLegalForm from "./form/ProposalLegalForm";
import ProposalPermissionForm from "./form/ProposalPermissionForm";
import ProposalScopeOfWorkForm from "./form/projectSummary/ProposalScopeOfWorkForm";
import ProposalPsBackgroundObjectiveForm from "./form/ProposalPsBackgroundObjectiveForm";
import ProposalBackgroundObjectiveForm from "./form/ProposalBackgroundObjectiveForm";
import ProposalTimeline from "./form/projectSummary/ProposalTimeline";
import ProposalInvestmentBudget from "./form/projectSummary/ProposalInvestmentBudget";
import ProposalCostBenefirForm from "./form/projectSummary/ProposalCostBenefirForm";
import ProposalDrawdownForm from "./form/projectSummary/ProposalDrawdownForm";
import ProposalConstuctorForm from "./form/ProposalConstuctorForm";
import ProposalPSRiskForm from "./form/projectSummary/ProposalPSRiskForm";
import ProposalTechnicalAnalysisForm from "./form/ProposalTechnicalAnalysisForm";
import { FillFormItem } from "./FillFormItem";
import ProposalKajianFSForm from "./form/ProposalKajianFSForm";
import { CollapsableHistory } from "./components/CollapsableHistory";
import { HistoryCommentDataGrid } from "./components/HistoryCommentDataGrid";
import CancelNotes from "./components/CancelNotes";

type DrawerState = {
  generalInformation: boolean;
  land: boolean;
  productAnalysis: boolean;
  marketAnalysis: boolean;
  costplanAnalysis: boolean;
  economyAnalysis: boolean;
  collaborationAnalysis: boolean;
  permission: boolean;
  fid: boolean;
  technicalAnalysis: boolean;
  legal: boolean;
  risk: boolean;
  backgroundObjective: boolean;
  kajianFs: boolean;

  scopeOfWork: boolean;
  psBackgroundObjective: boolean;
  timeline: boolean;
  investmentBudget: boolean;
  costBenefit: boolean;
  drawdown: boolean;
  psRiskMitigation: boolean;

  contructor: boolean;
  attachment: boolean;
};

type FormList = {
  text: string;
  icon: ReactElement;
  name: keyof DrawerState;
  added: boolean;
  disabled?: boolean;
};

type TProposalFillFormProps = {
  isHistoryView?: boolean;
};

const statusForPreaparationForm: string[] = [
  ProjectStatus.Preparation,
  ProjectStatus.Procurement,
  ProjectStatus.Construction,
  ProjectStatus.ProjectClosing,
];

function ProposalFillForm({ isHistoryView = false }: TProposalFillFormProps) {
  const { getProjectCategoryAll, projectCategoryAll } = useStore().projectCategoryAllStore;
  const { getReviewerByOrganization } = useStore().organizationStore;
  const query = useQuery();
  const reviewPage = query.get("reviewPage");
  const status = query.get("status");
  const proposalId = query.get("id");
  const projectCategory = query.get("projectCategory");

  const categoryProjectBDOrNonBD = useMemo(() => {
    let temp = projectCategoryAll.find((item) => {
      let helper = item.name === BD_OTHERS_NAME ? "BD" : item.name === NON_BD_OTHERS_NAME ? "NON_BD" : item.name;
      if (helper === projectCategory) return item;
    });

    return temp;
  }, [projectCategory, projectCategoryAll]);

  const isApprovalMode = useMemo(() => {
    return reviewPage === "true";
  }, [reviewPage]);

  const hasPassDecisionGate = useMemo(() => {
    return (
      status === ProjectStatus.Fid ||
      status === ProjectStatus.Construction ||
      status === ProjectStatus.Procurement ||
      status === ProjectStatus.Construction ||
      status === ProjectStatus.ProjectClosing ||
      false
    );
  }, [status]);

  const {
    getGeneralInformation,
    resetGeneralInformation,
    generalInformationLoading,
    backgroundObjective,
    backgroundObjectiveLoading,
    getBackgroundObjective,
    resetBackgroundObjective,
    generalInformation,
    getLand,
    resetLand,
    land,
    landLoading,
    permission,
    permissionLoading,
    getPermission,
    resetPermission,
    productAnalisysLoading,
    productAnalysis,
    getProductAnalysis,
    resetProductAnalysis,
    getMarketAnalysis,
    resetMarketAnalysis,
    marketAnalysis,
    marketAnalysisLoading,
    costplanAnalysis,
    costplanAnalysisLoading,
    getCostplanAnalysis,
    resetCostplanAnalysis,
    collaborationAnalysis,
    collaborationAnalysisLoading,
    resetCollaborationAnalysis,
    getCollaborationAnalysis,
    economyAnalysis,
    economyAnalysisLoading,
    getEconomyAnalysis,
    resetEconomyAnalysis,
    legal,
    legalLoading,
    getLegal,
    resetLegal,
    getRisk,
    risk,
    riskLoading,
    resetRisk,
    getScopeOfWork,
    scopeOfWork,
    scopeOfWorkLoading,
    resetScopeOfWork,
    psBackgroundObjective,
    psBackgroundObjectiveLoading,
    resetPsBackgroundObjective,
    getPsBackgroundObjective,
    timeline,
    timelineLoading,
    getTimeline,
    resetTimeline,
    investmentBudget,
    investmentBudgetLoading,
    getInvestmentBudget,
    resetInvestmentBudget,
    costBenefit,
    costBenefitLoading,
    getCostBenefit,
    resetCostBenefit,
    drawdown,
    drawdownLoading,
    resetDrawdown,
    getDrawdown,
    constructors,
    constructorsLoading,
    getConstructor,
    resetConstructor,
    psRiskMitigation,
    psRiskMitigationLoading,
    getPsRiskMitigation,
    resetPsRiskMitigation,
    technicalAnalysis,
    technicalAnalysisLoading,
    getTechnicalAnalysis,
    resetTechnicalAnalysis,
    getKajianFs,
    resetKajianFs,
    attachment,
    attachmentLoading,
    kajianFs,
    kajianFsLoading,
    getAttachment,
    resetAttachment,
  } = useStore().proposalStore;

  const [drawer, setDrawer] = useState<DrawerState>({
    generalInformation: false,
    land: false,
    productAnalysis: false,
    costplanAnalysis: false,
    marketAnalysis: false,
    economyAnalysis: false,
    backgroundObjective: false,
    collaborationAnalysis: false,
    permission: false,
    fid: false,
    attachment: false,
    technicalAnalysis: false,
    legal: false,
    risk: false,
    kajianFs: false,
    scopeOfWork: false,
    psBackgroundObjective: false,
    timeline: false,
    investmentBudget: false,
    costBenefit: false,
    contructor: false,
    drawdown: false,
    psRiskMitigation: false,
  });

  const generalFormList: FormList[] = useMemo(() => {
    // if (status === ProjectStatus.Proposal) {
    return [
      {
        text: "Attachment",
        icon: <AttachFile />,
        name: "attachment",
        isCommented: attachment && attachment[0]?.isComment ? attachment[0]?.isComment : false,
        added: !!attachment && attachment.length > 0,
        disabled: !generalInformation || attachmentLoading,
      },
    ];
    // }
    // else {
    //   return [
    //     {
    //       text: "Vendor Info",
    //       icon: <HandymanOutlined />,
    //       name: "contructor",
    //       isCommented: constructors && constructors[0]?.isComment ? constructors[0]?.isComment : false,
    //       added: !!constructors && constructors.length > 0,
    //       disabled: !generalInformation || constructorsLoading,
    //     },
    //     {
    //       text: "Attachment",
    //       icon: <AttachFile />,
    //       name: "attachment",
    //       isCommented: attachment && attachment[0]?.isComment ? attachment[0]?.isComment : false,
    //       added: !!attachment && attachment.length > 0,
    //       disabled: !generalInformation || attachmentLoading,
    //     },
    //   ];
    // }
  }, [attachment, attachmentLoading, generalInformation]);

  const feasibilityStudyFormList: FormList[] = useMemo(
    () => [
      {
        text: "General Information",
        icon: <InfoOutlined />,
        name: "generalInformation",
        isCommented: generalInformation && generalInformation.isComment ? generalInformation?.isComment : false,
        added: !!generalInformation,
        disabled: generalInformationLoading,
      },
      // {
      //   text: "Latar Belakang dan Tujuan",
      //   icon: <InfoOutlined />,
      //   name: "backgroundObjective",
      //   isCommented: backgroundObjective && backgroundObjective[0]?.isComment ? backgroundObjective[0]?.isComment : false,
      //   added: !!generalInformation && !!backgroundObjective?.length,
      //   disabled: !generalInformation || backgroundObjectiveLoading,
      // },
      // {
      //   icon: <MapRounded />,
      //   text: "Lahan",
      //   name: "land",
      //   isCommented: land && land[0]?.isComment ? land[0]?.isComment : "",
      //   added: !!land && land.length > 0,
      //   disabled: !generalInformation || landLoading,
      // },
      // {
      //   icon: <Villa />,
      //   text: "Analisis Produk",
      //   name: "productAnalysis",
      //   isCommented: productAnalysis && productAnalysis[0]?.isComment ? productAnalysis[0]?.isComment : false,
      //   added: !!productAnalysis && productAnalysis.length > 0,
      //   disabled: !generalInformation || productAnalisysLoading,
      // },
      // {
      //   icon: <AutoGraphRounded />,
      //   text: "Analisis Pasar",
      //   name: "marketAnalysis",
      //   isCommented: marketAnalysis && marketAnalysis[0]?.isComment ? marketAnalysis[0]?.isComment : false,
      //   added: !!marketAnalysis && marketAnalysis.length > 0,
      //   disabled: !generalInformation || marketAnalysisLoading,
      // },
      {
        icon: <AttachMoneyRounded />,
        text: "Analisis Costplan",
        name: "costplanAnalysis",
        isCommented: costplanAnalysis && costplanAnalysis[0]?.isComment ? costplanAnalysis[0]?.isComment : false,
        added: !!costplanAnalysis && costplanAnalysis.length > 0,
        disabled: !generalInformation || costplanAnalysisLoading,
      },
      // {
      //   // TODO:
      //   icon: <AttachMoneyRounded />,
      //   text: "Analisis Teknik",
      //   name: "technicalAnalysis",
      //   isCommented: technicalAnalysis && technicalAnalysis[0]?.isComment ? technicalAnalysis[0]?.isComment : false,
      //   added: !!technicalAnalysis && technicalAnalysis.length > 0,
      //   disabled: !generalInformation || technicalAnalysisLoading,
      // },
      {
        icon: <QueryStatsOutlined />,
        text: "Analisis Keekonomian",
        name: "economyAnalysis",
        isCommented: economyAnalysis && economyAnalysis[0]?.isComment ? economyAnalysis[0]?.isComment : false,
        added: !!economyAnalysis && economyAnalysis.length > 0,
        disabled: !generalInformation || economyAnalysisLoading,
      },
      // {
      //   icon: <HandshakeOutlined />,
      //   text: "Analisis Kerjasama",
      //   name: "collaborationAnalysis",
      //   isCommented: collaborationAnalysis && collaborationAnalysis[0]?.isComment ? collaborationAnalysis[0]?.isComment : false,
      //   added: !!collaborationAnalysis && collaborationAnalysis.length > 0,
      //   disabled: !generalInformation || collaborationAnalysisLoading,
      // },
      {
        icon: <FactCheckOutlined />,
        text: "Kajian Resiko",
        name: "risk",
        isCommented: risk && risk[0]?.isComment ? risk[0]?.isComment : false,
        added: !!risk && risk.length > 0,
        disabled: !generalInformation || riskLoading,
      },
      {
        icon: <HandshakeOutlined />,
        text: "Kajian Legal",
        name: "legal",
        isCommented: legal && legal[0]?.isComment ? legal[0]?.isComment : false,
        added: !!legal && legal.length > 0,
        disabled: !generalInformation || legalLoading,
      },
      // {
      //   icon: <FactCheckOutlined />,
      //   text: "Approval",
      //   name: 'approval',
      //   added: !!approval && approval.length > 0,
      //   disabled: !generalInformation || approvalLoading
      // },
      {
        icon: <FactCheckOutlined />,
        text: "Kajian Perizinan",
        name: "permission",
        isCommented: permission && permission[0]?.isComment ? permission[0]?.isComment : false,
        added: !!permission && permission.length > 0,
        disabled: !generalInformation || permissionLoading,
      },
      // {
      //   text: "Vendor Info",
      //   icon: <HandymanOutlined />,
      //   name: "contructor",
      //   added: !!constructors && constructors.length > 0,
      //   disabled: !generalInformation || constructorsLoading,
      // },
      // {
      //   text: "Attachment",
      //   icon: <AttachFile />,
      //   name: "attachment",
      //   added: !!attachment && attachment.length > 0,
      //   disabled: !generalInformation || attachmentLoading,
      // },
      {
        text: "Kajian FS",
        icon: <AttachFile />,
        name: "kajianFs",
        added: !!kajianFs,
        disabled: !generalInformation || kajianFsLoading,
      },
    ],
    [
      generalInformation,
      generalInformationLoading,
      costplanAnalysis,
      costplanAnalysisLoading,
      economyAnalysis,
      economyAnalysisLoading,
      risk,
      riskLoading,
      legal,
      legalLoading,
      permission,
      permissionLoading,
      kajianFs,
      kajianFsLoading,
    ]
  );

  const projectSummaryFormList = useMemo(() => {
    return [
      {
        text: "General Information",
        icon: <InfoOutlined />,
        name: "generalInformation",
        added: !!generalInformation,
        disabled: generalInformationLoading,
      },
      // {
      //   text: "Latar Belakang dan Tujuan",
      //   icon: <InfoOutlined />,
      //   name: "psBackgroundObjective",
      //   isCommented: psBackgroundObjective && psBackgroundObjective[0]?.isComment ? psBackgroundObjective[0]?.isComment : false,
      //   added: !!psBackgroundObjective && psBackgroundObjective.length > 0,
      //   disabled: !generalInformation || psBackgroundObjectiveLoading,
      // },
      // {
      //   text: "Lingkup Pekerjaan",
      //   icon: <InfoOutlined />,
      //   name: "scopeOfWork",
      //   isCommented: scopeOfWork && scopeOfWork[0]?.isComment ? scopeOfWork[0]?.isComment : false,
      //   added: !!scopeOfWork && scopeOfWork.length > 0,
      //   disabled: !generalInformation || scopeOfWorkLoading,
      // },
      // {
      //   text: "Tata Waktu",
      //   icon: <InfoOutlined />,
      //   name: "timeline",
      //   isCommented: timeline && timeline[0]?.isComment ? timeline[0]?.isComment : false,
      //   added: !!timeline && timeline.length > 0,
      //   disabled: !generalInformation || timelineLoading || scopeOfWorkLoading || (scopeOfWork as any)?.length === 0,
      // },
      {
        text: `Rincian Nilai Investasi (${investmentBudget ? investmentBudget?.length : 0})`,
        icon: <InfoOutlined />,
        name: "investmentBudget",
        isCommented: investmentBudget && investmentBudget[0]?.isComment ? investmentBudget[0]?.isComment : false,
        added: !!investmentBudget && investmentBudget.length > 0 && !!investmentBudget[0]?.budget && investmentBudget.length > 0,
        disabled:
          !generalInformation ||
          // timelineLoading ||
          // scopeOfWorkLoading ||
          // (scopeOfWork as any)?.length === 0 ||
          // (timeline as any)?.length === 0 ||
          investmentBudgetLoading,
      },
      // {
      //   text: "Drawdown",
      //   icon: <InfoOutlined />,
      //   name: "drawdown",
      //   // added: !!costBenefit && (costBenefit.length > 0 && !!costBenefit[0]?.benefit && !!costBenefit[0]?.cost) && costBenefit.length > 0,
      //   // disabled: !generalInformation || timelineLoading || scopeOfWorkLoading || ((scopeOfWork as any)?.length === 0) || (timeline as any)?.length === 0 || costBenefitLoading,
      //   isCommented: drawdown && drawdown[0]?.isComment ? drawdown[0]?.isComment : false,
      //   added: !!drawdown && drawdown.length > 0,
      //   disabled: !generalInformation || drawdownLoading,
      // },
      // {
      //   text: "Cost and Benefit Analysis",
      //   icon: <InfoOutlined />,
      //   name: "costBenefit",
      //   isCommented: costBenefit && costBenefit[0]?.isComment ? costBenefit[0]?.isComment : false,
      //   added: !!costBenefit && costBenefit.length > 0 && !!costBenefit[0]?.benefit && !!costBenefit[0]?.cost && costBenefit.length > 0,
      //   disabled:
      //     !generalInformation ||
      //     timelineLoading ||
      //     scopeOfWorkLoading ||
      //     (scopeOfWork as any)?.length === 0 ||
      //     (timeline as any)?.length === 0 ||
      //     costBenefitLoading,
      // },
      // {
      //   text: "Vendor Info",
      //   icon: <HandymanOutlined />,
      //   name: "contructor",
      //   added: !!constructors && constructors.length > 0,
      //   disabled: !generalInformation || constructorsLoading,
      // },
      // {
      //   text: "Resiko",
      //   icon: <InfoOutlined />,
      //   name: "psRiskMitigation",
      //   isCommented: psRiskMitigation && psRiskMitigation[0]?.isComment ? psRiskMitigation[0]?.isComment : false,
      //   added: !!psRiskMitigation && psRiskMitigation.length > 0,
      //   disabled: !generalInformation || psRiskMitigationLoading,
      // },
      // {
      //   text: "Attachment",
      //   icon: <AttachFile />,
      //   name: "attachment",
      //   added: !!attachment && attachment.length > 0,
      //   disabled: !generalInformation || attachmentLoading,
      // },
      {
        text: "Kajian PS",
        icon: <AttachFile />,
        name: "kajianFs",
        added: !!kajianFs,
        disabled: !generalInformation || kajianFsLoading,
      },
    ];
  }, [generalInformation, generalInformationLoading, investmentBudget, investmentBudgetLoading, kajianFs, kajianFsLoading]);

  const openForm = (name: Partial<keyof DrawerState>) => {
    setDrawer((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const closeForm = (name: Partial<keyof DrawerState>) => {
    setDrawer((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  const formFilledCount = useMemo(() => {
    const fsCount = [
      !!generalInformation,
      land && land.length > 0,
      // productAnalysis && productAnalysis.length > 0,
      // marketAnalysis && marketAnalysis.length > 0,
      costplanAnalysis && costplanAnalysis.length > 0,
      economyAnalysis && economyAnalysis.length > 0,
      // collaborationAnalysis && collaborationAnalysis.length > 0,
      risk && risk.length > 0,
      legal && legal.length > 0,
      // approval && approval.length > 0,
      permission && permission.length > 0,
      // constructors && constructors.length > 0,
      kajianFs,
      attachment && attachment.length > 0,
    ].filter((i) => i);

    const psCount = [
      !!generalInformation,
      scopeOfWork && scopeOfWork.length > 0,
      // psBackgroundObjective && psBackgroundObjective.length > 0,
      timeline && timeline.length > 0,
      investmentBudget && investmentBudget.length > 0,
      costBenefit && costBenefit.length > 0,
      drawdown && drawdown.length > 0,
      constructors && constructors.length > 0,
      psRiskMitigation && psRiskMitigation.length > 0,
      attachment && attachment.length > 0,
    ].filter((i) => i);

    const currentCount =
      categoryProjectBDOrNonBD?.category === ProjectCategory.BD
        ? fsCount
        : categoryProjectBDOrNonBD?.category === ProjectCategory.NonBD
        ? psCount
        : [];

    return currentCount.length;
  }, [
    categoryProjectBDOrNonBD,
    generalInformation,
    costBenefit,
    psRiskMitigation,
    constructors,
    drawdown,
    investmentBudget,
    land,
    timeline,
    scopeOfWork,
    costplanAnalysis,
    economyAnalysis,
    risk,
    legal,
    permission,
    query,
    attachment,
  ]);

  useEffect(() => {
    const id = query.get("id");

    if (!id) return;

    if (!statusForPreaparationForm.includes(status ?? "")) {
      getConstructor(id);
      getAttachment(id);
    }
    getGeneralInformation(id).then((res) => getReviewerByOrganization(res.directoratNbd));
    getLand(id);
    // getProductAnalysis(id);
    // getMarketAnalysis(id);
    getCostplanAnalysis(id);
    // getCollaborationAnalysis(id);
    getEconomyAnalysis(id);
    getRisk(id);
    getLegal(id);
    getPermission(id);
    getTimeline(id);
    getScopeOfWork(id);
    // getBackgroundObjective(id);
    // getPsBackgroundObjective(id);
    getInvestmentBudget(id);
    getCostBenefit(id);
    getDrawdown(id);
    getPsRiskMitigation(id);
    getKajianFs(id);
    // getTechnicalAnalysis(id);

    return () => {
      resetLand();
      // resetProductAnalysis();
      // resetMarketAnalysis();
      resetCostplanAnalysis();
      // resetCollaborationAnalysis();
      resetEconomyAnalysis();
      resetRisk();
      resetLegal();
      resetTimeline();
      // resetPsBackgroundObjective();
      resetPermission();
      resetScopeOfWork();
      resetInvestmentBudget();
      resetCostBenefit();
      resetDrawdown();
      // resetBackgroundObjective();
      // resetConstructor();
      resetPsRiskMitigation();
      // resetTechnicalAnalysis();
      // resetAttachment();
      resetKajianFs();
    };
  }, [
    getAttachment,
    getBackgroundObjective,
    resetBackgroundObjective,
    resetAttachment,
    getGeneralInformation,
    resetTechnicalAnalysis,
    getTechnicalAnalysis,
    getConstructor,
    resetPsRiskMitigation,
    getPsRiskMitigation,
    resetConstructor,
    getDrawdown,
    resetDrawdown,
    getCostBenefit,
    resetCostBenefit,
    getInvestmentBudget,
    resetInvestmentBudget,
    getProductAnalysis,
    getPermission,
    resetPermission,
    getLegal,
    resetLegal,
    resetTimeline,
    resetGeneralInformation,
    resetProductAnalysis,
    resetLand,
    resetMarketAnalysis,
    getMarketAnalysis,
    getLand,
    query,
    resetRisk,
    getRisk,
    getTimeline,
    getCostplanAnalysis,
    resetCostplanAnalysis,
    getCollaborationAnalysis,
    resetCollaborationAnalysis,
    resetEconomyAnalysis,
    getEconomyAnalysis,
    resetScopeOfWork,
    getScopeOfWork,
    getPsBackgroundObjective,
    resetPsBackgroundObjective,
    status,
    getKajianFs,
    resetKajianFs,
  ]);

  const projectFormName = useMemo(() => {
    return categoryProjectBDOrNonBD?.category === ProjectCategory.BD
      ? "Feasibility Study"
      : categoryProjectBDOrNonBD?.category === ProjectCategory.NonBD
      ? "Project Summary"
      : "Fill Form";
  }, [categoryProjectBDOrNonBD]);

  const currentForm = useMemo(() => {
    if (categoryProjectBDOrNonBD?.category === ProjectCategory.BD) {
      return feasibilityStudyFormList;
    } else if (categoryProjectBDOrNonBD?.category === ProjectCategory.NonBD) {
      return projectSummaryFormList;
    }

    return [];
  }, [categoryProjectBDOrNonBD, feasibilityStudyFormList, projectSummaryFormList]);

  const displayedItem: any[] = useMemo(() => {
    if (isHistoryView) {
      const temp = currentForm;

      temp?.push({
        text: "Attachment",
        icon: <AttachFile />,
        name: "attachment",
        added: !!attachment && attachment.length > 0,
        disabled: !generalInformation || attachmentLoading,
      });

      return temp;
    } else {
      return currentForm;
    }
  }, [isHistoryView, currentForm, attachment, generalInformation, attachmentLoading]);

  return (
    <>
      <Box sx={{ my: "24px" }}>
        {status === ProjectStatus.CancelProject ? (
          <Box sx={{ my: "12px" }}>
            <Typography sx={{ marginBottom: "12px" }} color="error" fontWeight={700} fontSize="20px">
              Cancel Notes
            </Typography>
            <CollapsableHistory color="#DB1A22" label="Cancel Notes">
              <Box sx={{ mx: "24px" }}>
                <CancelNotes
                  notes={generalInformation?.notes ?? ""}
                  cancelAttachment={generalInformation?.url ?? ""}
                  projectOwnerName={generalInformation?.projectOwnerName ?? ""}
                  proposalId={proposalId ?? ""}
                />
              </Box>
            </CollapsableHistory>
          </Box>
        ) : (
          ""
        )}
      </Box>
      <Stack mb={"16px"} direction="row" justifyContent="space-between">
        <Stack>
          <Typography color="black" fontWeight={700} fontSize="20px">
            {projectFormName}
          </Typography>
          <Typography mb="16px">You must fill out all form</Typography>
        </Stack>
        <Stack>
          <Typography textAlign="right" color="black" fontWeight={700} fontSize="20px">
            {formFilledCount}/
            {statusForPreaparationForm.includes(status ?? "")
              ? displayedItem?.length
              : (displayedItem && displayedItem?.length + generalFormList.length) ?? "-"}
          </Typography>
          <Typography textAlign="right" mb="16px">
            {projectFormName}
          </Typography>
        </Stack>
      </Stack>
      {displayedItem && (
        <Grid container spacing={"24px"}>
          <Grid item md={6}>
            <Stack spacing="16px">
              {displayedItem.slice(0, Math.floor(displayedItem.length + 1) / 2).map((i) => (
                <div key={i.name} onClick={() => !i.disabled && openForm(i.name as any)}>
                  <FillFormItem {...i} />
                </div>
              ))}
            </Stack>
          </Grid>
          <Grid item md={6}>
            <Stack spacing="16px">
              {displayedItem.slice(Math.floor(displayedItem.length + 1) / 2, displayedItem.length).map((i) => (
                <div key={i.name} onClick={() => !i.disabled && openForm(i.name as any)}>
                  <FillFormItem {...i} />
                </div>
              ))}
            </Stack>
          </Grid>
        </Grid>
      )}
      {statusForPreaparationForm.includes(status ?? "") ? (
        ""
      ) : (
        <>
          <Box sx={{ my: "24px" }}>
            <hr />
          </Box>
          <Box>
            <Grid container spacing={3}>
              {generalFormList.map((item) => (
                <Grid item xs={6} key={item.name} onClick={() => !item.disabled && openForm(item.name as any)}>
                  <FillFormItem {...item} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
      {!isHistoryView && (
        <Box>
          <Box sx={{ fontWeight: "bold", fontSize: "20px", color: "#000", mb: "12px", pt: "24px" }}>History</Box>
          <Stack spacing={2}>
            <CollapsableHistory label="Comment">
              <HistoryCommentDataGrid />
            </CollapsableHistory>
          </Stack>
        </Box>
      )}

      {/* FORM */}
      <ProposalGeneralInformationForm
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.generalInformation}
        handleClose={() => closeForm("generalInformation")}
      />
      <ProposalLandForm
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.land}
        handleClose={() => closeForm("land")}
      />
      {/* <ProposalProductAnalysis
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.productAnalysis}
        handleClose={() => closeForm("productAnalysis")}
      />
      <ProposalMarketAnalysisForm
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.marketAnalysis}
        handleClose={() => closeForm("marketAnalysis")}
      /> */}
      <ProposalCostplanAnalysisForm
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.costplanAnalysis}
        handleClose={() => closeForm("costplanAnalysis")}
      />
      <ProposalEconomyAnalysisForm
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.economyAnalysis}
        handleClose={() => closeForm("economyAnalysis")}
      />
      {/* <ProposalCollaborationAnalysisForm
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.collaborationAnalysis}
        handleClose={() => closeForm("collaborationAnalysis")}
      /> */}
      {/* <ProposalFeasibilityStudyForm
        isOpen={drawer.feasibilityStudy} 
        handleClose={() => closeForm('feasibilityStudy')} 
      /> */}
      {/* <ProposalFIDForm readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate} isOpen={drawer.fid} handleClose={() => closeForm("fid")} /> */}
      <ProposalAttachmentForm
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.attachment}
        handleClose={() => closeForm("attachment")}
      />
      <ProposalRiskForm
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.risk}
        handleClose={() => closeForm("risk")}
      />
      <ProposalLegalForm
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.legal}
        handleClose={() => closeForm("legal")}
      />
      <ProposalPermissionForm
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.permission}
        handleClose={() => closeForm("permission")}
      />
      {/* <ProposalScopeOfWorkForm readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate} isOpen={drawer.scopeOfWork} handleClose={() => closeForm("scopeOfWork")} /> */}
      {/* <ProposalPsBackgroundObjectiveForm
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.psBackgroundObjective}
        handleClose={() => closeForm("psBackgroundObjective")}
      /> */}
      {/* <ProposalTimeline readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate} isOpen={drawer.timeline} onClose={() => closeForm("timeline")} /> */}
      <ProposalInvestmentBudget
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.investmentBudget}
        handleClose={() => closeForm("investmentBudget")}
      />
      {/* <ProposalCostBenefirForm readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate} isOpen={drawer.costBenefit} handleClose={() => closeForm("costBenefit")} /> */}
      {/* <ProposalDrawdownForm readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate} isOpen={drawer.drawdown} handleClose={() => closeForm("drawdown")} /> */}
      {/* <ProposalConstuctorForm readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate} isOpen={drawer.contructor} handleClose={() => closeForm("contructor")} /> */}
      {/* <ProposalPSRiskForm
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.psRiskMitigation}
        handleClose={() => closeForm("psRiskMitigation")}
      /> */}
      <ProposalKajianFSForm
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.kajianFs}
        handleClose={() => closeForm("kajianFs")}
      />
      {/* <ProposalTechnicalAnalysisForm
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.technicalAnalysis}
        handleClose={() => closeForm("technicalAnalysis")}
      /> */}
      {/* <ProposalBackgroundObjectiveForm
        readOnly={isApprovalMode || isHistoryView || hasPassDecisionGate}
        isOpen={drawer.backgroundObjective}
        handleClose={() => closeForm("backgroundObjective")}
      /> */}
      {/* <ProposalAttachmentForm
        readOnly={isApprovalMode || isHistoryView} 
        isOpen={drawer.attachment} 
        handleClose={() => closeForm("attachment")} 
      /> */}
    </>
  );
}

export default observer(ProposalFillForm);
