import { Button, Chip, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { Add, ChevronRight, VisibilityOutlined } from "@mui/icons-material";
import { format } from "date-fns";
import { SubTaskTable } from "./subtask-table";
import { TaskSubTask, TaskSubTaskStatus } from "../../../app/config/enum";
import { IFidMilestone } from "../../../app/models/fidMilestones";

export const TaskTable = ({
  tasks,
  showOrganizationField,
  setFormType,
  setTaskToEdit,
  milestonesId,
  setSubtaskToEdit,
  setParentId,
  readOnly,
}: {
  tasks: IFidMilestone["tasks"];
  milestonesId?: string;
  showOrganizationField: boolean;
  setSubtaskToEdit: Dispatch<SetStateAction<IFidMilestone["tasks"][0]["subTasks"][0] | undefined>>;
  setFormType: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
  setTaskToEdit: Dispatch<SetStateAction<IFidMilestone["tasks"][0] | undefined>>;
  setParentId: Dispatch<SetStateAction<string | undefined>>;
  readOnly: boolean;
}) => {
  const [expandedTaskId, setExpandedTaskId] = useState<string>();
  const statusDisabledButtonSubTask = [
    TaskSubTaskStatus.PemenangLelang,
    TaskSubTaskStatus.Construction,
    TaskSubTaskStatus.Pembayaran,
    TaskSubTask.Completed,
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableCell width={30} />
          <TableCell sx={{ minWidth: 250, fontWeight: 700, whiteSpace: "nowrap" }}>Task</TableCell>
          {showOrganizationField && <TableCell sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>Organization</TableCell>}
          <TableCell sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>Status</TableCell>
          <TableCell sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>Start Date</TableCell>
          <TableCell sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>End Date</TableCell>
          <TableCell sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>Weight Physical (%)</TableCell>
          <TableCell sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>Weight Financial (IDR)</TableCell>
          <TableCell />
        </TableHead>
        <TableBody>
          {tasks.map((item, index) => (
            <>
              <TableRow key={index}>
                <TableCell>
                  {!!item.subTasks.length && (
                    <IconButton onClick={() => setExpandedTaskId((prev) => (!!prev ? (item.id === prev ? undefined : item.id) : item.id))}>
                      <ChevronRight sx={{ transform: "rotate(90deg)" }} />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell>{item.name}</TableCell>
                {showOrganizationField && <TableCell>{item.organizationName}</TableCell>}
                <TableCell>
                  <Chip component={"div"} label={item.status} color="primary" />
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>{format(new Date(item.startDate), "dd MMM yyyy")}</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>{format(new Date(item.endDate), "dd MMM yyyy")}</TableCell>
                <TableCell>{item.weightPhysical}%</TableCell>
                <TableCell>Rp {item.weightFinancial.toLocaleString()}</TableCell>
                <TableCell sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                  <IconButton
                    size="small"
                    color="inherit"
                    sx={{ flexShrink: 0 }}
                    onClick={() => {
                      setFormType("task");
                      setParentId(milestonesId);
                      setTaskToEdit(item);
                    }}
                  >
                    <VisibilityOutlined />
                  </IconButton>
                  <Button
                    startIcon={<Add />}
                    sx={{ whiteSpace: "nowrap" }}
                    variant="contained"
                    disabled={readOnly || statusDisabledButtonSubTask.includes(item.status)}
                    onClick={() => {
                      setFormType("subtask");
                      setParentId(item.id);
                    }}
                  >
                    Add Subtask
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow sx={{ background: "rgba(0, 0, 0, .04)" }}>
                <TableCell sx={{ p: 0 }} colSpan={showOrganizationField ? 9 : 8}>
                  <Collapse in={expandedTaskId === item.id}>
                    <SubTaskTable setParentId={setParentId} setFormType={setFormType} setSubtaskToEdit={setSubtaskToEdit} subTask={item.subTasks} />
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
