import { useState } from 'react';
import RkdFileUpload from '../../app/component/form/RkdFileUpload';
import RkdForm from '../../app/component/form/RkdForm';
import { FileType } from '../../app/config/enum';
import { IUserPhoto } from '../../app/models/user';
import { useStore } from '../../app/stores/store';

interface Props {
  username: string;
  photo: string;
} 

function UserFormUploadPhoto(props: Props) {
  const { username, photo } = props;  
  const { userStore, modalStore } = useStore();
  const [curUserPhoto] = useState<IUserPhoto>({ username, photo });
  
  return (
    <RkdForm
        initialValues={curUserPhoto}
        submitAction={userStore.uploadPhoto}
        actionAfterSubmit={() => {
            userStore.getUserList();
            modalStore.close();
        }}
        formTitle={`Upload Photo User`}
        buttonSubmitText="Submit"
        successMessage={`Success ${username} Upload Photo User`}
        globalErrorMessage={`Error ${username} Upload Photo User`}
    >      
      <RkdFileUpload text='Upload' typeFile={[FileType.Image]} name='file' photo={photo}/>
    </RkdForm>
  );
}

export default UserFormUploadPhoto;