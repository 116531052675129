import ApexCharts from "react-apexcharts";
import { useStore } from "../../../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { TSCurveSPI } from "../../../../../../app/models/spi";

type TSCurveSchedulePerformanceIndexProps = {
  data: TSCurveSPI | null;
};

function SCurveSchedulePerformanceIndex({ data }: TSCurveSchedulePerformanceIndexProps) {
  // const { sCurveSpi } = useStore().DashboardStore;
  // const { sCurveSpi: sCurveTask } = useStore().proposalStore;
  // const { sCurveTask, sCurveSpi } = useStore().DashboardStore;

  return (
    <>
      <ApexCharts
        options={{
          chart: {
            type: "line",
            height: 100,
          },
          xaxis: {
            categories: data?.categories,
          },
          yaxis: {
            show: true,
            max: 100,
            min: 0,
            labels: {
              formatter: function (val: number) {
                return val.toFixed(2);
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val: any) {
                return val.toFixed(2);
              },
            },
          },
        }}
        series={data?.series ?? []}
        height={350}
      />
    </>
  );
}

export default observer(SCurveSchedulePerformanceIndex);
