import { Dispatch, SetStateAction } from "react";
import { TFidMilestoneSubtask, TFidMilestoneTask } from "../../../../../../app/models/fidMilestones";
import { Delete, EditOutlined, ShowChart } from "@mui/icons-material";
import { useStore } from "../../../../../../app/stores/store";
import { format } from "date-fns";
import { Box, IconButton } from "@mui/material";
import EmptyState from "../../../../../../app/component/EmptyState";

type TPembayaranCollapse = {
  setDialogTypeProgress: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
  drawerPembayaran: "task" | "subtask" | undefined;
  task: TFidMilestoneTask | undefined;
  subtask: TFidMilestoneSubtask | undefined;
};

export default function ProgressCollapse({ setDialogTypeProgress, subtask, task, drawerPembayaran }: TPembayaranCollapse) {
  const { dialogStore, milestonetore } = useStore();
  const { physicalPlan, deletePhysicalPlan, setSelectedPhysicalPlan, getPhysicalPlan } = milestonetore;

  return (
    <Box>
      {physicalPlan.length > 0 ? (
        <>
          {physicalPlan.map((item: any, pIndex: number) => (
            <Box
              sx={{
                display: "flex",
                gap: "12px",
                padding: "16px",
                alignItems: "center",
                mb: "12px",
                borderRadius: "4px",
                border: "1px solid rgba(29, 27, 32, 0.12)",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                    <ShowChart />
                    <Box>
                      <Box>{`${item.weightPhysicalPlan}%`}</Box>
                      <Box sx={{ fontSize: "12px", color: "#999" }}>{format(new Date(item.date), "MMMM yyyy")}</Box>
                      <Box sx={{ fontSize: "12px", color: "#999" }}>{`${item.description ?? "-"}`}</Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <IconButton
                        disabled={item.abiLockFlag}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedPhysicalPlan(item);
                          setDialogTypeProgress(drawerPembayaran);
                        }}
                      >
                        <EditOutlined
                          sx={{
                            fontSize: "24px",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        disabled={item.abiLockFlag}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          dialogStore.open({
                            action: async () =>
                              await deletePhysicalPlan(item.id).then(() => {
                                getPhysicalPlan(
                                  drawerPembayaran === "subtask" ? subtask?.tasksId ?? "" : task?.id ?? "",
                                  drawerPembayaran === "subtask" ? subtask?.id ?? "" : ""
                                );
                              }),
                            actionText: "OK",
                            title: "Delete",
                            closeText: "Cancel",
                            actionButtonProps: {
                              color: "error",
                            },
                            description: "Are you sure want to delete this item?",
                          });
                        }}
                      >
                        <Delete sx={{ fontSize: "24px" }} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </>
      ) : (
        <EmptyState />
      )}
    </Box>
  );
}
