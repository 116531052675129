import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import RkdBackdrop from "./RkdBackdrop";

function RkdModal() {
    const { modalStore } = useStore();
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal open={modalStore.modal.open} onClose={modalStore.close} aria-labelledby="modal-title" aria-describedby="modal-description">
            <Box sx={style}>
                {modalStore.modal.title && <h2 id="modal-title">{modalStore.modal.title}</h2>}
                {modalStore.modal.loading && <RkdBackdrop />}
                {modalStore.modal.body}
            </Box>
        </Modal>
    );
}

export default observer(RkdModal);