import { useFormik } from "formik";
import DrawerForm from "../../app/component/DrawerForm";
import * as yup from 'yup';
import { TextField } from "@mui/material";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";

const validationSchema = yup.object({
  name: yup.string().required(),
})

type ValidationSchema = yup.InferType<typeof validationSchema>

function ProjectTypeForm({ isOpen, onClose }: {isOpen: boolean, onClose: () => any}) {
  const { addProjectType, setSelectedProjectType, editProjectType, getProjectType, selectedProjectType } = useStore().projectTypeStore
  
  const initialValues: ValidationSchema = useMemo(() => {
    if (selectedProjectType) {
      return selectedProjectType
    } else {
      return { name: '' }
    }
  }, [selectedProjectType])

  const onSubmit = async (values: ValidationSchema) => {
    if (selectedProjectType) {
      await editProjectType(selectedProjectType.id, values)
        .then(() => {
          getProjectType()
          onClose()
          setSelectedProjectType(null)
          resetForm()
          setErrors({
            name: undefined
          })
        })
    } else {
      await addProjectType(values)
        .then(() => {
          getProjectType()
          onClose()
          setSelectedProjectType(null)
          resetForm()
          setErrors({
            name: undefined
          })
        })
    }
  }

  const { handleSubmit, resetForm, setErrors, handleChange, values, errors, isValid, isSubmitting, dirty } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  })
  
  return (
    <DrawerForm
      title={`${selectedProjectType ? 'Edit' : 'Add'} Project Type`}
      isOpen={isOpen || !!selectedProjectType}
      onClose={() => {
        onClose()
        setSelectedProjectType(null)
      }}
      onSubmit={handleSubmit}
      disableAction={!isValid || !dirty}
      isLoading={isSubmitting}
      isEdit={!!selectedProjectType}
    >
      <TextField 
        label="Name"
        fullWidth
        name="name"
        value={values.name}
        error={!!errors.name}
        helperText={errors.name}
        onChange={handleChange}
      />
    </DrawerForm>
  )
}

export default observer(ProjectTypeForm)