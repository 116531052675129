import { Button, Dialog, DialogContent, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { history } from "../../..";
import useQuery from "../../../app/hook/useQuery";

function RefuseDialog({ isOpen, onClose }: { onClose: () => any; isOpen: boolean }) {
  const { approveReFid } = useStore().reFidStore;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<string>("");
  const [error, setError] = useState("");
  const { id } = useParams();
  const query = useQuery();
  const status = query.get("status");

  const onSubmit = async () => {
    if (!id || !status) return;

    setIsLoading(true);

    try {
      if (data.length === 0) {
        setError("Reason must be filled");

        return;
      }

      const formData = new FormData();
      formData.append("data.notes", data);
      formData.append("data.status", "REFUSE");

      await approveReFid(id, status, formData).then(() => history.push(`/project-monitoring/re-fid/list`));

      handleClose();
      history.push(`/project-monitoring/approval/list`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setData("");
    setError("");
    setIsLoading(false);
    onClose();
  };

  useEffect(() => {
    if (data.length > 0) {
      setError("");
    }
  }, [data]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent sx={{ width: "365px" }}>
        <Typography color="black" fontWeight={700} fontSize="14px">
          Info
        </Typography>
        <Typography mb="16px" color="black" mt="24px">
          Add reason to refuse Refid
        </Typography>
        <TextField
          multiline
          rows={5}
          value={data}
          fullWidth
          label="Reason"
          onChange={(e) => setData(e.target.value)}
          error={!!error}
          helperText={error}
        />
        <Stack mt={2} justifyContent="flex-end" direction="row" gap={1}>
          <Button onClick={handleClose} color="inherit" variant="outlined">
            Cancel
          </Button>
          <LoadingButton color="error" disabled={!!error || isLoading} loading={isLoading} variant="contained" onClick={onSubmit}>
            Refuse
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default observer(RefuseDialog);
