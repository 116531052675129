import { Dispatch, SetStateAction, useState } from "react";
import { Button, ButtonBase, Collapse, Divider, Stack, Typography } from "@mui/material";
import { Add, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { TaskTable } from "./task-table";
import { observer } from "mobx-react-lite";
import { IFidMilestone } from "../../../app/models/fidMilestones";
import { useStore } from "../../../app/stores/store";
import useQuery from "../../../app/hook/useQuery";
import { ProjectStatus } from "../../../app/config/enum";
import EmptyState from "../../../app/component/EmptyState";

type MilestoneListProps = {
  milestone: IFidMilestone;
  readOnly: boolean;
  taskToEdit?: IFidMilestone["tasks"][0];
  setTaskToEdit: Dispatch<SetStateAction<IFidMilestone["tasks"][0] | undefined>>;
  setSubtaskToEdit: Dispatch<SetStateAction<IFidMilestone["tasks"][0]["subTasks"][0] | undefined>>;
  setParentId: Dispatch<SetStateAction<string | undefined>>;
  setFormType: Dispatch<SetStateAction<"task" | "subtask" | undefined>>;
};

export const MilestoneList = observer(
  ({
    readOnly,
    milestone: { isOrigin, cost, milestonesName, milestonesId, tasks },
    taskToEdit,
    setTaskToEdit,
    setSubtaskToEdit,
    setParentId,
    setFormType,
  }: MilestoneListProps) => {
    const [open, setIsOpen] = useState(false);

    const openForm = (val: "task" | "subtask") => setFormType(val);

    const toggleExpandCollapse = () => setIsOpen((prev) => !prev);

    const { dialogStore, milestonetore } = useStore();
    const query = useQuery();
    const id = query.get("id");
    const status = query.get("status");

    const handleDelete = (milestoneId: string) =>
      dialogStore.open({
        action: async () => id && (await milestonetore.deleteMilestones(id, milestoneId).then(() => milestonetore.getMilestone(id))),
        actionText: "Delete",
        title: "Delete",
        closeText: "Cancel",
        actionButtonProps: {
          color: "error",
        },
        description: "Are you sure want to delete this item?",
      });

    const showOrganizationField =
      status === ProjectStatus.Preparation ||
      status === ProjectStatus.Procurement ||
      status === ProjectStatus.Construction ||
      status === ProjectStatus.ProjectClosing;

    return (
      <>
        <Stack direction="row" alignItems="center" gap="8px">
          <Stack
            py={"20px"}
            sx={{
              cursor: "pointer",
              width: "100%",
              justifyContent: "flex-start",
            }}
            component={ButtonBase}
            onClick={toggleExpandCollapse}
          >
            <Stack flex={1} direction="row" spacing={"16px"} justifyContent="space-between" pr={4} sx={{ width: "100%" }}>
              <Stack direction="row" alignItems="center" spacing={"16px"}>
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                <Typography fontWeight={700} color="black">
                  {milestonesName}
                </Typography>
              </Stack>
              <Typography fontWeight={700} color="black">
                Rp {cost ? cost.toLocaleString() : 0}
              </Typography>
            </Stack>
          </Stack>
          {status === ProjectStatus.ProjectClosing ? (
            ""
          ) : (
            <Stack direction="row" alignItems="center" gap="8px">
              <div>
                <Button onClick={() => handleDelete(milestonesId)} disabled={isOrigin || readOnly} variant="outlined" color="error">
                  Delete
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    openForm("task");
                    setParentId(milestonesId);
                  }}
                  variant="contained"
                  sx={{ whiteSpace: "nowrap" }}
                  startIcon={<Add />}
                  disabled={readOnly}
                >
                  {taskToEdit ? "Edit" : "Add"} Task
                </Button>
              </div>
            </Stack>
          )}
        </Stack>
        <Divider />
        <Collapse sx={{ background: "rgba(0, 0, 0, .03)" }} in={open} timeout="auto" unmountOnExit>
          {tasks.length > 0 ? (
            <TaskTable
              milestonesId={milestonesId ?? ""}
              readOnly={readOnly}
              setParentId={setParentId}
              setSubtaskToEdit={setSubtaskToEdit}
              setTaskToEdit={setTaskToEdit}
              setFormType={setFormType}
              tasks={tasks}
              showOrganizationField={showOrganizationField}
            />
          ) : (
            <EmptyState />
          )}
        </Collapse>
      </>
    );
  }
);
