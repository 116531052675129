import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { RkdFormikContext } from "./RkdForm";
import RkdTextInput, { RkdTextInputProps } from "./RkdTextInput";

function RkdDateTimePicker(props: RkdTextInputProps) {
    const context = useContext(RkdFormikContext);
    const {startAdornment, endAdornment, ...otherProps} = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                renderInput={(params) => <RkdTextInput {...params} {...otherProps}></RkdTextInput>}
                onChange={(value) => context!.setFieldValue(props.name!, value)}
                value={context?.values[props.name!]}
            />
        </LocalizationProvider>
    );
}

export default observer(RkdDateTimePicker);