import { useFormik } from "formik";
import DrawerForm from "../../app/component/DrawerForm";
import * as yup from 'yup';
import { TextField } from "@mui/material";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";

const validationSchema = yup.object({
  name: yup.string().required(),
})

type ValidationSchema = yup.InferType<typeof validationSchema>

function ProjectCategoryForm({ isOpen, onClose }: {isOpen: boolean, onClose: () => any}) {
  const { addProjectCategoryNBD, getProjectCategoryNBDGrid, selectedProjectCategoryNBD, editProjectCategoryNBD, setSelectedProjectCategoryNBD } = useStore().projectCategoryNBDStore
  
  const initialValues: ValidationSchema = useMemo(() => {
    if (selectedProjectCategoryNBD) {
      return selectedProjectCategoryNBD
    } else {
      return { name: '' }
    }
  }, [selectedProjectCategoryNBD])

  const onSubmit = async (values: ValidationSchema) => {
    if (selectedProjectCategoryNBD) {
      await editProjectCategoryNBD(selectedProjectCategoryNBD.id, values)
        .then(() => {
          getProjectCategoryNBDGrid()
          onClose()
          setSelectedProjectCategoryNBD(null)
          resetForm()
          setErrors({
            name: undefined
          })
        })
    } else {
      await addProjectCategoryNBD(values)
        .then(() => {
          getProjectCategoryNBDGrid()
          onClose()
          setSelectedProjectCategoryNBD(null)
          resetForm()
          setErrors({
            name: undefined
          })
        })
    }
  }

  const { handleSubmit, resetForm, setErrors, handleChange, values, errors, isValid, isSubmitting, dirty } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  })
  
  return (
    <DrawerForm
      title={`${selectedProjectCategoryNBD ? 'Edit' : 'Add'} Project Category Non-BD`}
      isOpen={isOpen || !!selectedProjectCategoryNBD}
      onClose={() => {
        onClose()
        setSelectedProjectCategoryNBD(null)
      }}
      onSubmit={handleSubmit}
      disableAction={!isValid || !dirty}
      isLoading={isSubmitting}
      isEdit={!!selectedProjectCategoryNBD}
    >
      <TextField 
        label="Name"
        fullWidth
        name="name"
        value={values.name}
        error={!!errors.name}
        helperText={errors.name}
        onChange={handleChange}
      />
    </DrawerForm>
  )
}

export default observer(ProjectCategoryForm)
