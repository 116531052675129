import { Add, Close, Search } from "@mui/icons-material";
import { Box, Button, CircularProgress, Drawer, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { AddItemFormDrawer } from "./add-item-form-drawer";
import { AddMilestoneDialog } from "./add-milestone-dialog";
import { MilestoneList } from "./milestone-list";
import { RequestReFidDialog } from "./request-refid-dialog";
import { IFidMilestone } from "../../../app/models/fidMilestones";
import useQuery from "../../../app/hook/useQuery";
import { useStore } from "../../../app/stores/store";
import { useDebounce } from "../../../app/hook/useDebounce";
import { ProjectStatus, Roles } from "../../../app/config/enum";
import EmptyState from "../../../app/component/EmptyState";
import { LoadingButton } from "@mui/lab";

interface MilestonesDrawerFormProps {
  approvalMode?: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

function MilestonesDrawerForm({ setIsOpen, isOpen, approvalMode = false }: MilestonesDrawerFormProps) {
  const { reFidGeneralInformation, reFidGeneralInformationLoading, getReFidGeneralInformation, getCapexGrid, capex, capexLoading } =
    useStore().reFidStore;
  const [reFidDialogOpen, setReFidDialogOpen] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState<IFidMilestone["tasks"][0]>();
  const [subtaskToEdit, setSubtaskToEdit] = useState<IFidMilestone["tasks"][0]["subTasks"][0]>();
  const [formType, setFormType] = useState<"task" | "subtask">();
  const [parentId, setParentId] = useState<string>();
  const query = useQuery();

  const id = query.get("id");
  const proposalId = query.get("proposalId");
  const status = query.get("status");
  const projectCategory = query.get("projectCategory");

  const isFormDrawerOpen = !!formType;
  const closeFormDrawer = () => {
    setFormType(undefined);
    setParentId(undefined);
  };
  const closeDialog = () => {
    setAddMilestoneDialog(false);
    setParentId(undefined);
  };

  const { getMilestone, setQueryParams, milestonesLoading, milestones } = useStore().milestonetore;

  const { getGeneralInformation, generalInformation } = useStore().proposalStore;
  const { account } = useStore().accountStore;
  const [addMilestoneDialog, setAddMilestoneDialog] = useState(false);
  const { member, getMember } = useStore().proposalMemberStore;

  const handleSearch = useDebounce(2000, (v) => setQueryParams("search", v.target.value));

  const readOnly = useMemo(() => {
    const currentMember = member.find((i) => i.name === account?.displayName);

    return generalInformation?.projectOwnerName !== account?.displayName && !account?.roles.includes(Roles.Superadmin) && !currentMember;
  }, [generalInformation, account, member]);

  const displayRequestReFID =
    status === ProjectStatus.Construction ||
    status === ProjectStatus.DecisionGate ||
    status === ProjectStatus.Preparation ||
    status === ProjectStatus.Procurement ||
    status === ProjectStatus.ProjectClosing;

  console.log("proposalId", proposalId);
  console.log("capex", capex);
  console.log("milestones", milestones);

  useEffect(() => {
    if (!proposalId) return;

    getCapexGrid(proposalId);
    getGeneralInformation(proposalId);
    getMember(proposalId);
    getMilestone(proposalId);
  }, [getCapexGrid, getGeneralInformation, getMember, getMilestone, proposalId]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      sx={{
        ".MuiPaper-root": {
          width: "75%",
        },
      }}
    >
      <Box
        sx={{
          m: "16px 16px 0 16px",
          pb: "8px",
          borderBottom: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "space-between",
          mb: "16px",
          alignItems: "center",
        }}
      >
        <Typography fontSize={"14px"} fontWeight={700} color="black">
          Capex
        </Typography>
        <IconButton onClick={() => setIsOpen(false)}>
          <Close />
        </IconButton>
      </Box>
      <Stack component="form" flex={1} overflow="auto" p="16px" spacing="24px">
        <Box>
          <Stack mb="24px" direction="row" spacing="12px">
            <TextField
              fullWidth
              InputProps={{
                startAdornment: <Search />,
              }}
              onChange={handleSearch}
              placeholder="Search"
            />
            {status === ProjectStatus.ProjectClosing ? (
              ""
            ) : (
              <Button
                onClick={() => setAddMilestoneDialog(true)}
                sx={{ whiteSpace: "nowrap" }}
                disabled={readOnly || approvalMode}
                startIcon={<Add />}
              >
                Add Milestone
              </Button>
            )}
          </Stack>
          {generalInformation && (
            <Stack direction="row" spacing={2} mb={3}>
              <Stack
                flex={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ backgroundColor: ({ palette }) => palette.primary.light }}
                p={2}
                borderRadius={2}
              >
                <Typography fontSize="20px" fontWeight={700} sx={{ color: ({ palette }) => palette.primary.main }}>
                  {generalInformation?.title}
                </Typography>
                <Typography fontSize="20px" fontWeight={700} sx={{ color: ({ palette }) => palette.primary.main }}>
                  Rp {generalInformation?.cost.toLocaleString()}
                </Typography>
              </Stack>
            </Stack>
          )}
          {capexLoading ? (
            <Box display="flex" justifyContent="center" py="32px">
              <CircularProgress />
            </Box>
          ) : capex.data.length === 0 ? (
            <EmptyState />
          ) : (
            capex.data.map((item, index) => (
              <MilestoneList
                setParentId={setParentId}
                setFormType={setFormType}
                setSubtaskToEdit={setSubtaskToEdit}
                setTaskToEdit={setTaskToEdit}
                taskToEdit={taskToEdit}
                readOnly={readOnly || approvalMode}
                milestone={item}
                key={index}
              />
            ))
          )}
          <AddMilestoneDialog readOnly={readOnly || approvalMode} isOpen={addMilestoneDialog} onClose={closeDialog} />
          <AddItemFormDrawer
            readOnly={readOnly || approvalMode}
            subTaskToEdit={subtaskToEdit}
            formType={formType}
            isOpen={isFormDrawerOpen}
            parentId={parentId}
            taskToEdit={taskToEdit}
            onClose={closeFormDrawer}
            setTaskToEdit={setTaskToEdit}
            setSubtaskToEdit={setSubtaskToEdit}
          />
          {/* <RequestReFidDialog isOpen={reFidDialogOpen} setIsOpen={setReFidDialogOpen} /> */}
        </Box>
      </Stack>
    </Drawer>
  );
}

export default observer(MilestonesDrawerForm);
