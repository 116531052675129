import { useEffect, useState } from "react";
import { Box, Grid, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useStore } from "../../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import PreparationFillForm from "../../PreparationFillForm";
import useQuery from "../../../../../app/hook/useQuery";
import { NumericFormat } from "react-number-format";
import { BD_OTHERS_NAME, NON_BD_OTHERS_NAME } from "../../../../../app/config/enum";

function PreparationStepForm() {
  const { generalInformation } = useStore().proposalStore;
  const { getProjectCategoryAll, projectCategoryAll } = useStore().projectCategoryAllStore;
  const [title, setTitle] = useState<string>();
  const [cost, setCost] = useState<number>();
  const query = useQuery();
  const currentProjectCategory = query.get("projectCategory");

  useEffect(() => {
    getProjectCategoryAll();
  }, [getProjectCategoryAll]);

  useEffect(() => {
    setTitle(generalInformation?.title);
    setCost(generalInformation?.cost);
  }, [setTitle, generalInformation, setCost]);

  return (
    <Box>
      <Box mb="24px">
        <Stack gap="24px">
          {!!generalInformation?.title && !!generalInformation.cost && (
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Typography sx={{ mb: "12px" }} color="black" fontWeight={700} fontSize="20px">
                  Title
                </Typography>
                <TextField fullWidth disabled placeholder="Title" value={title} />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ mb: "12px" }} color="black" fontWeight={700} fontSize="20px">
                  Cost
                </Typography>
                <NumericFormat customInput={TextField} fullWidth placeholder="Cost" value={cost} thousandSeparator={true} prefix={"Rp"} disabled />
              </Grid>
            </Grid>
          )}
          <Select disabled label="Kategori Proyek" value={currentProjectCategory}>
            {projectCategoryAll.map((item) => {
              return (
                <MenuItem
                  sx={{ display: "none" }}
                  value={item.name === BD_OTHERS_NAME ? "BD" : item.name === NON_BD_OTHERS_NAME ? "NON_BD" : item.name}
                >
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </Stack>
      </Box>
      <Box>
        <PreparationFillForm />
      </Box>
    </Box>
  );
}

export default observer(PreparationStepForm);
