import { makeAutoObservable, runInAction } from "mobx";
import { IProposalMember, IProposalMemberBody } from "../models/proposalMember";
import agent from "../api/agent";

export class ProposalMemberStore {
  member: IProposalMember[] = []
  memberLoading = false;
  
  constructor() {
    makeAutoObservable(this)
  }

  getMember = async (proposalId: string, params?: { search?: string }) => {
    this.memberLoading = true
    try {
      const res = await agent.Proposal.getMember(proposalId, params)
      
      runInAction(() => this.member = res);
    } catch (e) {
      throw e
    } finally {
      runInAction(() => this.memberLoading = false)
    }
  }

  addMember = async (payload: IProposalMemberBody) => {
    try {
      await agent.Proposal.addMember(payload)
    } catch (e) {
      throw e
    }
  }

  deleteMember = async (memberId: string) => {
    try {
      await agent.Proposal.deleteMember(memberId)
    } catch (e) {
      throw e;
    }
  }
}