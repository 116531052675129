import { Grid, Box, Button, Avatar } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import RkdCard from "../../app/component/card/RkdCard";
import RkdSearchSelect from "../../app/component/form/RkdSearchSelect";
import { useStore } from "../../app/stores/store";
import TesterError from "./TesterError";

function Tester() {
    const { commonStore, accountStore, modalStore, snackbarStore, exampleStore } = useStore();
    useEffect(() => {
        commonStore.setTitlePage('Tester');
    });

    return (
        <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={6}>
                <RkdCard >
                    <Box>
                        <Button color="primary" variant="contained">Primary</Button>
                        <Button color="secondary" variant="contained">Secondary</Button>
                        <Button color="success" variant="contained">Success</Button>
                        <Button color="warning" variant="contained">Warning</Button>
                        <Button color="error" variant="contained">Error</Button>
                        <Button color="info" variant="contained">Info</Button>
                    </Box>
                </RkdCard>
            </Grid>
            <Grid item xs={6} >
                <RkdCard title="User Info" subtitle="">
                    <Avatar src={accountStore.account?.photo ?? "/assets/user.png"} alt={accountStore.account?.username} />
                </RkdCard>
            </Grid>
            <Grid item xs={6}>
                <RkdCard title="Errors" subtitle="">
                    <TesterError />
                </RkdCard>
            </Grid>
            <Grid item xs={6}>
                <RkdCard title="Components" subtitle="">
                    <Box>
                        <Button onClick={() => modalStore.open(<h1>Modal Content</h1>)}>Open Modal</Button>
                        <Button onClick={() => snackbarStore.show("info", "Snackbar opened")}>Open Snackbar</Button>
                    </Box>
                    <Box>
                        <RkdSearchSelect
                            getOptions={exampleStore.getExamples}
                            textPropName="name"
                            valuePropName="id"
                            multiSelect
                            charNumRequired={3}
                            name="exampleSelector"
                        />
                    </Box>
                </RkdCard>
            </Grid>
        </Grid>
    );
}

export default observer(Tester);