import Drawer from "@mui/material/Drawer";
import MenuList from "./MenuList";
import { BD_OTHERS_NAME, NON_BD_OTHERS_NAME, Roles, defaultProjectMonitoringMenu } from "../../config/enum";
import InventoryIcon from "@mui/icons-material/Inventory";
import { Box } from "@mui/material";
import { Map, Monitor, People, EditNoteOutlined, History, DashboardRounded, CloudUpload } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import { TProjectCategoryAll } from "../../models/projectCategoryAll";
import { Menu } from "../../config/menu";

export type TDynamincProjectMonitoringMenu = Pick<Menu, "title" | "route">;

const SubIcon = () => (
  <Box sx={{ width: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}>
    <Box width={"9px"} height={"9px"} borderRadius={"9px"} bgcolor={"#999999"} />
  </Box>
);

interface Props {
  window?: () => Window;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  drawerWidth: number;
}

function NavMenu() {
  const { projectCategoryAllStore } = useStore();
  const { projectCategoryAll } = projectCategoryAllStore;
  const [dynamincProjectMonitoringMenu, setDynamincProjectMonitoringMenu] = useState<TDynamincProjectMonitoringMenu[]>([]);

  let menus: Array<Menu> = [
    {
      title: "Dashboard",
      icon: <DashboardRounded fontSize="small" />,
      permissions: [
        {
          permission: "all",
          role: Roles.Superadmin,
        },
        {
          permission: "all",
          role: Roles.General,
        },
        {
          permission: "all",
          role: Roles.AdminAsset,
        },
      ],
      submenu: [
        {
          title: "Executive",
          route: "/dashboard-executive",
          permissions: [
            {
              permission: "all",
              role: Roles.Superadmin,
            },
            {
              permission: "hidden",
              role: Roles.General,
            },
            {
              permission: "hidden",
              role: Roles.AdminAsset,
            },
          ],
        },
        {
          title: "Projects",
          route: "/dashboard-projects",
        },
        {
          title: "Report",
          route: "/",
        },
      ],
    },
    {
      title: "Profile Lahan",
      icon: <Map fontSize="small" />,
      permissions: [
        {
          permission: "all",
          role: Roles.Superadmin,
        },
        {
          permission: "read-only",
          role: Roles.General,
        },
        {
          permission: "all",
          role: Roles.AdminAsset,
        },
      ],
      route: "/land/list",
    },
    {
      title: "Project Monitoring",
      icon: <Monitor fontSize="small" />,
      submenu: [
        {
          title: "Usulan Investasi",
          icon: <SubIcon />,
          submenu: dynamincProjectMonitoringMenu,
        },
        {
          title: "Reviewer / Approval",
          icon: <SubIcon />,
          route: "/project-monitoring/approval/list",
        },
        {
          title: "Re-FID",
          icon: <SubIcon />,
          route: "/project-monitoring/re-fid/list",
        },
      ],
    },
    {
      title: "Master",
      icon: <InventoryIcon fontSize="small" />,
      permissions: [
        {
          permission: "all",
          role: Roles.Superadmin,
        },
        {
          permission: "hidden",
          role: Roles.General,
        },
        {
          permission: "hidden",
          role: Roles.AdminAsset,
        },
      ],
      submenu: [
        // {
        //   title: "Project Type",
        //   route: "/master/project-type",
        // },
        {
          title: "Regional",
          route: "/master/regional",
        },
        {
          title: "Project Category",
          route: "/master/project-category",
        },
        {
          title: "Sub Holding",
          route: "/master/sub-holding",
        },
        {
          title: "Underlying",
          route: "/master/underlying",
        },
        {
          title: "Asset Category",
          route: "/master/asset-category",
        },
        // HIDE
        // {
        //   title: "Inventory Category",
        //   route: "/master/inventory-category",
        // },
        {
          title: "Status Kepemilikan",
          route: "/master/ownership-category",
        },
        {
          title: "Status Lelang",
          route: "/master/auction-status",
        },
        {
          title: "Mata Anggaran",
          route: "/master/unit-business",
        },
        {
          title: "Project Type",
          route: "/master/unit-business-category",
        },
        {
          title: "Peruntukan",
          route: "/master/peruntukan",
        },
        {
          title: "Organizations",
          route: "/master/organizations",
        },
        {
          title: "Setting Approvals",
          route: "/master/settingApprovals",
        },
        {
          title: "Project Category Non-BD",
          route: "/master/project-category-nbd",
        },
      ],
    },
    {
      title: "Upload Plan Project Admin",
      icon: <CloudUpload fontSize="small" />,
      route: "/upload-admin",
      permissions: [
        {
          permission: "all",
          role: Roles.Superadmin,
        },
        {
          permission: "hidden",
          role: Roles.General,
        },
        {
          permission: "hidden",
          role: Roles.AdminAsset,
        },
      ],
    },
    {
      title: "Change Request",
      icon: <EditNoteOutlined fontSize="small" />,
      route: "/change-request",
      permissions: [
        {
          permission: "all",
          role: Roles.Superadmin,
        },
        {
          permission: "hidden",
          role: Roles.General,
        },
        {
          permission: "hidden",
          role: Roles.AdminAsset,
        },
      ],
    },
    {
      title: "User Management",
      icon: <People fontSize="small" />,
      route: "/users",
      permissions: [
        {
          permission: "all",
          role: Roles.Superadmin,
        },
        {
          permission: "hidden",
          role: Roles.General,
        },
        {
          permission: "hidden",
          role: Roles.AdminAsset,
        },
      ],
    },
    {
      title: "Log",
      icon: <History fontSize="small" />,
      route: "/log",
      permissions: [
        {
          permission: "all",
          role: Roles.Superadmin,
        },
      ],
    },
  ];

  useEffect(() => {
    let temp: TDynamincProjectMonitoringMenu[] = [];
    projectCategoryAll.map((item: TProjectCategoryAll) => {
      let tempCategory = item.name === BD_OTHERS_NAME ? "BD" : item.name === NON_BD_OTHERS_NAME ? "NON_BD" : item.name;
      temp.push({ title: item.name, route: `/project-monitoring/proposal/list/${tempCategory}` });
    });
    setDynamincProjectMonitoringMenu(temp);
  }, [projectCategoryAll]);

  return menus;
}

function Sidebar(props: Props) {
  const { window, mobileOpen, handleDrawerToggle, drawerWidth } = props;
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="menu">
      {/* Mobile */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "flex", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth, backgroundColor: "white" },
          flexDirection: "column",
        }}
      >
        <Box component="div" sx={{ bgcolor: "background.default", height: "100%", px: 2.5, py: 3 }}>
          <Box sx={{ display: "flex", bgColor: "primary", justifyContent: "center", mb: "36px" }}>
            <img src="/logo.svg" alt="Logo" width={148} />
          </Box>
          <MenuList menu={NavMenu()} />
        </Box>
      </Drawer>

      {/* PC */}
      <Drawer
        variant="permanent"
        sx={{
          backgroundColor: "white",
          display: { xs: "none", sm: "flex" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            border: "none",
            backgroundColor: "white",
            boxShadow: "0 0 8px 4px rgba(0,0,0, .05)",
          },
          flexDirection: "column",
        }}
        open
      >
        <Box component="div" sx={{ height: "100%", px: 2.5, py: 3 }}>
          <Box sx={{ display: "flex", justifyContent: "center", mb: "36px" }}>
            <img src="/logo.svg" alt="Logo" width={148} />
          </Box>
          <MenuList menu={NavMenu()} />
        </Box>
      </Drawer>
    </Box>
  );
}

export default observer(Sidebar);
