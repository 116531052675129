export const provincies = [
  { kode: { id_provinsi: 11 }, provinsi: "ACEH" },
  { kode: { id_provinsi: 12 }, provinsi: "SUMATERA UTARA" },
  { kode: { id_provinsi: 13 }, provinsi: "SUMATERA BARAT" },
  { kode: { id_provinsi: 14 }, provinsi: "RIAU" },
  { kode: { id_provinsi: 15 }, provinsi: "JAMBI" },
  { kode: { id_provinsi: 16 }, provinsi: "SUMATERA SELATAN" },
  { kode: { id_provinsi: 17 }, provinsi: "BENGKULU" },
  { kode: { id_provinsi: 18 }, provinsi: "LAMPUNG" },
  { kode: { id_provinsi: 19 }, provinsi: "KEPULAUAN BANGKA BELITUNG" },
  { kode: { id_provinsi: 21 }, provinsi: "KEPULAUAN RIAU" },
  { kode: { id_provinsi: 31 }, provinsi: "DKI JAKARTA" },
  { kode: { id_provinsi: 32 }, provinsi: "JAWA BARAT" },
  { kode: { id_provinsi: 33 }, provinsi: "JAWA TENGAH" },
  { kode: { id_provinsi: 34 }, provinsi: "DAERAH ISTIMEWA YOGYAKARTA" },
  { kode: { id_provinsi: 35 }, provinsi: "JAWA TIMUR" },
  { kode: { id_provinsi: 36 }, provinsi: "BANTEN" },
  { kode: { id_provinsi: 51 }, provinsi: "BALI" },
  { kode: { id_provinsi: 52 }, provinsi: "NUSA TENGGARA BARAT" },
  { kode: { id_provinsi: 53 }, provinsi: "NUSA TENGGARA TIMUR" },
  { kode: { id_provinsi: 61 }, provinsi: "KALIMANTAN BARAT" },
  { kode: { id_provinsi: 62 }, provinsi: "KALIMANTAN TENGAH" },
  { kode: { id_provinsi: 63 }, provinsi: "KALIMANTAN SELATAN" },
  { kode: { id_provinsi: 64 }, provinsi: "KALIMANTAN TIMUR" },
  { kode: { id_provinsi: 65 }, provinsi: "KALIMANTAN UTARA" },
  { kode: { id_provinsi: 71 }, provinsi: "SULAWESI UTARA" },
  { kode: { id_provinsi: 72 }, provinsi: "SULAWESI TENGAH" },
  { kode: { id_provinsi: 73 }, provinsi: "SULAWESI SELATAN" },
  { kode: { id_provinsi: 74 }, provinsi: "SULAWESI TENGGARA" },
  { kode: { id_provinsi: 75 }, provinsi: "GORONTALO" },
  { kode: { id_provinsi: 76 }, provinsi: "SULAWESI BARAT" },
  { kode: { id_provinsi: 81 }, provinsi: "MALUKU" },
  { kode: { id_provinsi: 82 }, provinsi: "MALUKU UTARA" },
  { kode: { id_provinsi: 91 }, provinsi: "P A P U A" },
  { kode: { id_provinsi: 92 }, provinsi: "PAPUA BARAT" },
];
