import { Box, Collapse, Divider, Drawer, IconButton, List, ListItem, Stack, Typography } from "@mui/material";
import { ChartGrid } from "./PieChartGrid";
import { useMemo, useState } from "react";
import { Close, ExpandLess, ExpandMore, VisibilityOutlined } from "@mui/icons-material";
import { history } from "../..";

interface DetailedPieChartProps {
  series: number[];
  title?: string;
  labels: string[];
  details: any[][];
}

export const DetailedPieChart = ({ labels, series, title = "", details }: DetailedPieChartProps) => {
  const [detailedIndex, setDetailedIndex] = useState<number>();
  const [expandedIndex, setExpandedIndex] = useState<number>();

  const currentDetailList = useMemo(() => (!detailedIndex !== undefined ? details[detailedIndex!] : undefined), [detailedIndex, details]);
  const detailedLabel = useMemo(() => (!detailedIndex !== undefined ? labels[detailedIndex!] : undefined), [detailedIndex, labels]);

  const chart = useMemo(
    () => (
      <ChartGrid
        title={title}
        series={series}
        labels={labels}
        extendsOptions={{
          legend: {
            formatter: function (val: any, opts: any) {
              return `${val} -  ${opts.w.globals.series[opts.seriesIndex]} Tasks`;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    showAlways: true,
                    show: true,
                  },
                },
              },
            },
          },
        }}
        extendsChartOptions={{
          height: "100%",
          type: "donut",
          events: {
            dataPointSelection: (event: any, chartContext: any, { dataPointIndex }: any) => {
              setDetailedIndex(dataPointIndex);
            },
          },
        }}
      />
    ),
    [title, series, labels]
  );

  return (
    <>
      {chart}
      <Drawer open={detailedIndex !== undefined} anchor="right" onClose={() => setDetailedIndex(undefined)}>
        <Box
          sx={{
            m: "16px 16px 0 16px",
            pb: "8px",
            borderBottom: "1px solid #EAEAEA",
            display: "flex",
            justifyContent: "space-between",
            mb: "16px",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"14px"} fontWeight={700} color="black">
            {detailedLabel}
          </Typography>
          <IconButton onClick={() => setDetailedIndex(undefined)}>
            <Close />
          </IconButton>
        </Box>
        <List sx={{ width: "500px", px: 2, pb: 3 }}>
          {currentDetailList?.map((detail, index) => (
            <ListItem
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "3px",
                border: "1px solid rgba(0, 0, 0, .1)",
                alignItems: "flex-start",
                mb: 2,
              }}
            >
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box flex={1}>
                  <Typography mb={0.5} fontWeight={700} color="black">
                    {detail.name}
                  </Typography>
                  <Typography fontSize="small">Rp {detail.cost.toLocaleString()}</Typography>
                </Box>
                <Stack flexDirection="row">
                  <IconButton color="inherit" onClick={(e) => setExpandedIndex((prev) => (prev === index ? undefined : index))}>
                    {!!expandedIndex ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                  <IconButton
                    color="inherit"
                    onClick={(e) =>
                      history.push(
                        `/project-monitoring/proposal/list/form?projectCategory=${detail.projectCategory}&id=${detail.proposalId}&status=${detail.projectStatus}&reFID=${detail.reFid}`
                      )
                    }
                  >
                    <VisibilityOutlined />
                  </IconButton>
                </Stack>
              </Box>
              <Collapse in={expandedIndex === index} sx={{ width: "100%" }}>
                <Divider sx={{ width: "100%", my: 1 }} />
                <table>
                  <tr>
                    <td>
                      <Typography color="black">Project Category</Typography>
                    </td>
                    <td>:</td>
                    <td>
                      <Typography>{detail.projectCategoryName}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography color="black">Stage</Typography>
                    </td>
                    <td>:</td>
                    <td>
                      <Typography>{detail.projectStatusName}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography color="black">ReFID</Typography>
                    </td>
                    <td>:</td>
                    <td>
                      <Typography>{detail.reFid ? "Yes" : "No"}</Typography>
                    </td>
                  </tr>
                </table>
              </Collapse>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};
