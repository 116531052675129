import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Drawer, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { Dispatch, SetStateAction } from "react";
import { NumericFormat } from "react-number-format";
import * as yup from "yup";
import { useStore } from "../../../app/stores/store";
import useQuery from "../../../app/hook/useQuery";
import { NO_SUBTASK_ID } from "../../../app/config/enum";

interface ReFidGeneralInfoFormProps {
  readonly?: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  category?: string;
}

export const ReFidGeneralInfoForm = ({ category = "", readonly = false, isOpen, setIsOpen }: ReFidGeneralInfoFormProps) => {
  const { addReFidGeneralInformation, editReFidGeneralInformation, reFidGeneralInformation, getReFidGeneralInformation, getCapexGrid } =
    useStore().reFidStore;
  const query = useQuery();
  const id = query.get("proposalId");

  const closeDialog = () => {
    setErrors({
      cost: undefined,
      description: undefined,
    });
    resetForm();
    setIsOpen(false);
  };

  const { values, errors, handleChange, submitForm, setFieldValue, setErrors, resetForm, isSubmitting } = useFormik({
    validationSchema: yup.object({
      description: yup.string().required(),
      cost: yup.number().required(),
    }),
    initialValues: {
      description: reFidGeneralInformation ? reFidGeneralInformation.description : "",
      cost: reFidGeneralInformation ? reFidGeneralInformation.cost : 0,
    },
    onSubmit: async (v) => {
      if (!id) return;

      let payload = { ...v, category: category };

      if (reFidGeneralInformation?.id !== NO_SUBTASK_ID) {
        await editReFidGeneralInformation(id, payload, reFidGeneralInformation?.id ?? "").then(() => {
          closeDialog();
          getCapexGrid(id);
          getReFidGeneralInformation(id);
        });
      } else {
        await addReFidGeneralInformation(id, payload).then(() => {
          closeDialog();
          getCapexGrid(id);
          getReFidGeneralInformation(id);
        });
      }
    },
    enableReinitialize: true,
  });

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      sx={{
        ".MuiPaper-root": {
          width: "500px",
        },
      }}
    >
      <Box
        sx={{
          m: "16px 16px 0 16px",
          pb: "8px",
          borderBottom: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "space-between",
          mb: "16px",
          alignItems: "center",
        }}
      >
        <Typography fontSize={"14px"} fontWeight={700} color="black">
          General Information
        </Typography>
        <IconButton onClick={closeDialog}>
          <Close />
        </IconButton>
      </Box>
      <Stack component="form" flex={1} overflow="auto" p="16px" spacing="24px">
        <TextField
          label="Description"
          name={"description"}
          value={values.description}
          error={!!errors.description}
          disabled={readonly}
          helperText={errors.description}
          onChange={handleChange}
        />
        <NumericFormat
          customInput={TextField}
          label="Capex"
          name="cost"
          required
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
              e.preventDefault();
            }
          }}
          fullWidth
          value={values.cost}
          error={!!errors?.cost}
          disabled={readonly}
          helperText={errors?.cost}
          thousandSeparator={true}
          onValueChange={(e) => {
            setFieldValue(`cost`, e.floatValue);
          }}
          prefix="Rp "
        />
      </Stack>
      <Box
        sx={{
          m: "0 16px 16px 16px",
          pt: "16px",
          borderTop: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <LoadingButton onClick={submitForm} variant="contained" loading={isSubmitting} disabled={readonly}>
          Save
        </LoadingButton>
      </Box>
    </Drawer>
  );
};
