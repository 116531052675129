import { useEffect } from "react";
import DashboardLayoutHeader from "../../../app/component/layout/DashboardLayoutHeader";
import { Grid, Paper, TextField, Box, Stack } from "@mui/material";
import { ArrowBackIos, InfoOutlined } from "@mui/icons-material";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { Link } from "react-router-dom";
import PageButton from "../components/PageButton";
// import CommentSection from "../components/CommentSection";
import LoadingSection from "../components/LoadingSection";
import useQuery from "../../../app/hook/useQuery";

function RiskMitigation() {
  const { proposalStore } = useStore();
  const { psRiskMitigation, psRiskMitigationLoading, getPsRiskMitigation, getGeneralInformation, generalInformation } = proposalStore;
  const { id } = useParams();
  const query = useQuery();
  const status = query.get("status");

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
  }, [getGeneralInformation, id]);

  useEffect(() => {
    if (!id) return;

    getPsRiskMitigation(id);
  }, [getPsRiskMitigation, id]);

  return (
    <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Approval",
            href: "/project-monitoring/approval/list",
          },
          {
            label: "Approval List",
            href: "/project-monitoring/approval/list",
          },
          {
            label: `${generalInformation?.title}`,
          },
        ]}
        pageTitle="Project Monitoring"
      />

      <Paper sx={{ p: "16px" }}>
        <Link
          style={{ color: "#000", textDecoration: "none", display: "flex", alignItems: "center", gap: "3px", marginBottom: "24px" }}
          to={`/project-monitoring/approval/list/${id}?status=${status}`}
        >
          <ArrowBackIos sx={{ fontSize: "14px" }} />
          <Box>Back</Box>
        </Link>
        <Box
          sx={{
            mb: "12px",
            backgroundColor: "#F2F9FF",
            display: "flex",
            p: "16px",
            alignItems: "center",
            gap: "6px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <InfoOutlined sx={{ mr: "12px", color: "#0664B4", fontSize: "34px" }} />
            <Box sx={{ fontWeight: "bold" }}>Resiko</Box>
          </Box>
          {/* {psRiskMitigationLoading ? "Loading..." : <CommentSection typeId={psRiskMitigation?.length ? psRiskMitigation[0]?.id : ""} />} */}
        </Box>

        {psRiskMitigationLoading ? (
          <LoadingSection />
        ) : (
          <Grid pt="12px" container spacing={3}>
            {psRiskMitigation?.map((item, index) => (
              <>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    label={`Description ${psRiskMitigation && psRiskMitigation?.length > 1 ? index + 1 : ""}`}
                    rows={5}
                    multiline
                    name={`items[${index}].description`}
                    value={(item as any).description}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sx={{ gap: "3px" }}>
                  <Stack direction="column" spacing={"24px"}>
                    <TextField
                      label={`Resiko ${psRiskMitigation && psRiskMitigation?.length > 1 ? index + 1 : ""}`}
                      name={`items[${index}].risk`}
                      value={item.risk}
                      disabled
                      fullWidth
                    />
                    <TextField
                      label={`Mitigasi ${psRiskMitigation && psRiskMitigation?.length > 1 ? index + 1 : ""}`}
                      value={item.mitigation}
                      disabled
                      fullWidth
                    />
                  </Stack>
                </Grid>
              </>
            ))}
          </Grid>
        )}

        <PageButton isPrevButton prevButtonRoute={`/project-monitoring/approval/list/${id}/costBenefit?status=${status}`} />
      </Paper>
      <Box sx={{ py: "16px" }} />
    </Box>
  );
}

export default observer(RiskMitigation);
