import { Paper, Stack } from "@mui/material"
import { ReFidDataGrid } from "./ReFidDataGrid"
import { ReFidDataGridToolbar } from "./ReFidDataGridToolbar"
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader"

export const ReFidIndex = () => {
  return (
    <>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: 'Re-FID',
            href: '/project-monitoring/re-fid/list'
          },
          {
            label: 'Re-FID List',
            href: '/project-monitoring/re-fid/list'
          },
        ]}
        pageTitle="Re-FID"
      />
      <Paper sx={{ p: 2 }}>
        <Stack gap={2}>
          <ReFidDataGridToolbar />
          <ReFidDataGrid />
        </Stack>
      </Paper>
    </>
  )
}
