import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { DataGridResult } from "../models/materialUI/dataGrid";
import { IUnderlying, IUnderlyingBody, IUnderlyingQueryParams } from "../models/underlying";

export class UnderlyingStore {
  underlyingGrid: DataGridResult<IUnderlying[]> = new DataGridResult([], 0)
  underlyingGridLoading = false;
  
  underlying: IUnderlying[] = []
  underlyingLoading: boolean = false
  
  selectedUnderlying: IUnderlying | null = null
  
  queryparams: IUnderlyingQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: ''
  }
  
  constructor() {
    makeAutoObservable(this)

    reaction(() => this.queryparams, () => {
      this.getUnderlyingGrid()
    })
  }

  getUnderlying = async () => {
    this.underlyingLoading = true;

    try {
      const res = await agent.Master.getUnderlying()

      runInAction(() => this.underlying = res)
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => this.underlyingLoading = false)
    }
  }
  
  getUnderlyingGrid = async () => {
    this.underlyingGridLoading = true
    try {
      const res = await agent.Master.getUnderlyingGrid(this.queryparams)

      runInAction(() => this.underlyingGrid = res);
    } catch (e) {
      throw e
    } finally {
      runInAction(() => this.underlyingGridLoading = false)
    }
  }

  addUnderlying = async (body: IUnderlyingBody) => {
    try {
      await agent.Master.addUnderlying(body)
    } catch (e) {
      throw e;
    }
  }

  editUnderlying = async (id: string, body: IUnderlyingBody) => {
    try {
      await agent.Master.editUnderlying(id, body)
    } catch (e) {
      throw e;
    }
  }

  setSelectedUnderlying = (data: IUnderlying | null) => {
    this.selectedUnderlying = data;
  }

  deleteUnderlying = async (id: string) => {
    try {
      await agent.Master.deleteUnderlying(id)
    } catch (e) {
      throw e;
    }
  }

  setQueryParams = (query: 'pageSize' | 'search' | 'currentPage', v: string | number) => {
    this.queryparams = {
      ...this.queryparams,
      [query]: v,
    }
  }
}