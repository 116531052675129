import { useEffect } from "react";
import DashboardLayoutHeader from "../../../app/component/layout/DashboardLayoutHeader";
import { Grid, Paper, TextField, Box } from "@mui/material";
import { ArrowBackIos, InfoOutlined } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { Link } from "react-router-dom";
import PageButton from "../components/PageButton";
// import CommentSection from "../components/CommentSection";
import LoadingSection from "../components/LoadingSection";
import useQuery from "../../../app/hook/useQuery";

function Drawdown() {
  const { proposalStore } = useStore();
  const { drawdown, drawdownLoading, getDrawdown, getGeneralInformation, generalInformation } = proposalStore;
  const { id } = useParams();
  const query = useQuery();
  const status = query.get("status");

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
  }, [getGeneralInformation, id]);

  useEffect(() => {
    if (!id) return;

    getDrawdown(id);
  }, [getDrawdown, id]);

  return (
    <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Approval",
            href: "/project-monitoring/approval/list",
          },
          {
            label: "Approval List",
            href: "/project-monitoring/approval/list",
          },
          {
            label: `${generalInformation?.title}`,
          },
        ]}
        pageTitle="Project Monitoring"
      />

      <Paper sx={{ p: "16px" }}>
        <Link
          style={{ color: "#000", textDecoration: "none", display: "flex", alignItems: "center", gap: "3px", marginBottom: "24px" }}
          to={`/project-monitoring/approval/list/${id}?status=${status}`}
        >
          <ArrowBackIos sx={{ fontSize: "14px" }} />
          <Box>Back</Box>
        </Link>
        <Box
          sx={{
            mb: "12px",
            backgroundColor: "#F2F9FF",
            display: "flex",
            p: "16px",
            alignItems: "center",
            gap: "6px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <InfoOutlined sx={{ mr: "12px", color: "#0664B4", fontSize: "34px" }} />
            <Box sx={{ fontWeight: "bold" }}>Drowdown</Box>
          </Box>
          {/* {drawdownLoading ? "Loading..." : <CommentSection typeId={drawdown?.length ? drawdown[0]?.id : ""} />} */}
        </Box>

        {drawdownLoading ? (
          <LoadingSection />
        ) : (
          <Grid pt="12px" container spacing={3}>
            {drawdown?.map((item) => (
              <>
                <Grid item xs={6}>
                  <LocalizationProvider style={{ minWidth: "100%" }} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      views={["year"]}
                      disabled
                      value={item.year}
                      onChange={() => null}
                      renderInput={(params) => <TextField {...params} onKeyDown={(e) => e.preventDefault()} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <TextField fullWidth type="number" label="Cost" value={item.cost} disabled />
                </Grid>
              </>
            ))}
          </Grid>
        )}

        <PageButton
          isPrevButton
          isNextButton
          prevButtonRoute={`/project-monitoring/approval/list/${id}/investmentBudget?status=${status}`}
          nextButtonRoute={`/project-monitoring/approval/list/${id}/costBenefit?status=${status}`}
        />
      </Paper>
      <Box sx={{ py: "16px" }} />
    </Box>
  );
}

export default observer(Drawdown);
