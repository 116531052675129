import { useFormik } from "formik";
import DrawerForm from "../../app/component/DrawerForm";
import * as yup from 'yup';
import { TextField } from "@mui/material";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";

const validationSchema = yup.object({
  name: yup.string().required(),
  description: yup.string(),
})

type ValidationSchema = yup.InferType<typeof validationSchema>

function UnderlyingForm({ isOpen, onClose }: {isOpen: boolean, onClose: () => any}) {
  const { addUnderlying, selectedUnderlying, editUnderlying, getUnderlyingGrid, setSelectedUnderlying } = useStore().underlyingStore
  
  const initialValues: ValidationSchema = useMemo(() => {
    if (selectedUnderlying) {
      return selectedUnderlying
    } else {
      return { name: '', description: '' }
    }
  }, [selectedUnderlying])

  const onSubmit = async (values: ValidationSchema) => {
    if (selectedUnderlying) {
      await editUnderlying(selectedUnderlying.id, values)
        .then(() => {
          getUnderlyingGrid()
          onClose()
          setSelectedUnderlying(null)
          resetForm()
          setErrors({
            name: undefined
          })
        })
    } else {
      await addUnderlying(values)
        .then(() => {
          getUnderlyingGrid()
          onClose()
          setSelectedUnderlying(null)
          resetForm()
          setErrors({
            name: undefined
          })
        })
    }
  }

  const { handleSubmit, resetForm, setErrors, handleChange, values, errors, isValid, isSubmitting, dirty } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  })
  
  return (
    <DrawerForm
      title={`${selectedUnderlying ? 'Edit' : 'Add'} Underlying`}
      isOpen={isOpen || !!selectedUnderlying}
      onClose={() => {
        onClose()
        setSelectedUnderlying(null)
      }}
      onSubmit={handleSubmit}
      disableAction={!isValid || !dirty}
      isLoading={isSubmitting}
      isEdit={!!selectedUnderlying}
    >
      <TextField 
        label="Name"
        fullWidth
        name="name"
        value={values.name}
        error={!!errors.name}
        helperText={errors.name}
        onChange={handleChange}
      />
      <TextField 
        label="Description"
        fullWidth
        name="description"
        value={values.description}
        error={!!errors.description}
        helperText={errors.description}
        onChange={handleChange}
        multiline
        rows={5}
      />
    </DrawerForm>
  )
}

export default observer(UnderlyingForm)