import { makeAutoObservable, runInAction, observable } from "mobx";
import agent from "../api/agent";
import { TConsolidation, TConsolidationCost, TConsolidationQueryParams } from "../models/consolidation";

export class ConsolidationStore {
  consolidation: TConsolidation[] = [];
  consolidationLoading = false;

  costConsolidation: TConsolidationCost[] = [];
  costConsolidationLoading = false;

  queryParams: TConsolidationQueryParams = {
    currentPage: 0,
    pageSize: 25,
    search: "",
  };
  selectedSettingApproval: TConsolidation | null = null;
  consolidationGridLoading = false;
  loadingForm = false;

  constructor() {
    makeAutoObservable(this, {
      loadingForm: observable,
    });
  }

  getConsolidation = async (proposalId: string) => {
    this.consolidationLoading = true;

    try {
      const res = await agent.Consolidation.getConsolidation(proposalId);

      runInAction(() => (this.consolidation = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.consolidationLoading = false));
    }
  };

  getCostConsolidation = async (proposalId: string) => {
    this.costConsolidationLoading = true;

    try {
      const res = await agent.Consolidation.getCostConsolidation(proposalId);

      runInAction(() => (this.costConsolidation = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.costConsolidationLoading = false));
    }
  };
}
