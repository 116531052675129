export interface Data {
  proposalId: string;
  title: string;
  date: string;
  status: string;
  userName: string;
}

export interface INotification {
  count: number;
  data: Data[];
}

export class Notification<T> {
  data: T[] = [];
  count = 0;

  constructor(count: number = 0, data: T[] = []) {
    this.count = count;
    this.data = data;
  }
}
