import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import CSelect from "../../app/component/CSelect";
import { ProjectCategory, ProjectStatus, Roles } from "../../app/config/enum";
import { useFormik } from "formik";
import * as yup from "yup";
import { TreeItem, TreeView } from "@mui/lab";
import { ChevronRight, Close, ExpandMore } from "@mui/icons-material";
import { findDeepObject } from "../../app/utils/utils";
import { useStore } from "../../app/stores/store";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useDebounce } from "../../app/hook/useDebounce";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import GridProject from "./GridProject";
import { ExportToExcel } from "./components/ExportToExcel";

// const startYear = 2023;
// const currentyear = new Date().getFullYear();
// const marginBetweenYear = currentyear - startYear;

// const yearOptions: CSelectProps["options"] = [];

// if (marginBetweenYear === 0) {
//   yearOptions.push({
//     label: String(startYear),
//     value: String(startYear),
//   });
// } else {
//   for (let i = 0; i < marginBetweenYear; i++) {
//     const value = startYear + i;

//     yearOptions.push({
//       label: String(value),
//       value: String(value),
//     });
//   }
// }

export type OrgList = {
  value: string;
  id: string;
  level: string;
  name: string;
  child: OrgList[];
};

type TProjectDataGridProps = {
  year?: number | string;
};

const ProjectDataGrid = ({ year }: TProjectDataGridProps) => {
  const { account } = useStore().accountStore;
  const { getDashboardRecentProject, dashboardRecentProject, dashboardRecentProjectLoading } = useStore().DashboardStore;
  const { getOrganizationList } = useStore().organizationStore;
  const [organizationsList, setOrganizationsList] = useState<OrgList[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<OrgList>();
  const [title, setTitle] = useState<string>();

  const isSuperAdmin = useMemo(() => {
    if (account?.roles.includes(Roles.Superadmin)) return true;

    return false;
  }, [account]);

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      year: year ?? null,
      organizationId: isSuperAdmin ? "" : account?.organizationId ?? "",
      title: "",
      status: "",
      projectCategory: "",
    },
    validationSchema: {
      year: yup.number(),
      organizationId: yup.string(),
      title: yup.string(),
      status: yup.string(),
      projectCategory: yup.string(),
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    titleDebounce(e);
  };

  const titleDebounce = useDebounce(2000, (e) => setFieldValue("title", e.target.value));

  const handleChangeOrganizationId = (node: any) => {
    setSelectedOrg(node);
    setFieldValue("organizationId", node.id);
  };

  const renderTreeItem = (nodes: any) => {
    return (
      <TreeItem onClick={() => handleChangeOrganizationId(nodes)} key={nodes.id} nodeId={nodes.id} label={nodes.name}>
        {Array.isArray(nodes.child) ? nodes.child.map((node: any) => renderTreeItem(node)) : null}
      </TreeItem>
    );
  };

  useEffect(() => {
    const result = findDeepObject(organizationsList, "id", values.organizationId);
    setSelectedOrg(result ?? undefined);
  }, [organizationsList, values.organizationId]);

  useEffect(() => {
    getOrganizationList().then((organizationRes) => {
      setOrganizationsList(organizationRes?.map((item) => ({ ...item, text: item.name, value: item.id } as any)) ?? []);
    });
  }, [getOrganizationList]);

  useEffect(() => {
    let params = {};

    const keys = Object.keys(values);
    for (let key of keys) {
      const value = (values as any)[key];

      if (!!value)
        params = {
          ...params,
          [key]: value,
        };
    }

    getDashboardRecentProject(params);
  }, [values, getDashboardRecentProject]);

  return (
    <Paper sx={{ padding: 2 }}>
      <Grid container spacing={2} sx={{ alignItems: "center", mt: "2px", mb: "12px" }}>
        <Grid item xs={6}>
          <Typography variant="h2" color="black">
            Projects Summary
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "end" }}>
          <ExportToExcel apiData={dashboardRecentProject} fileName={`Project Summary - ${format(new Date(), "dd MMMM yyyy")}`} />
        </Grid>
      </Grid>
      <hr />
      <Grid container spacing="6px" sx={{ mt: "6px", mb: "12px" }}>
        <Grid item xs={3}>
          <TextField sx={{ width: "100%" }} value={title} onChange={handleChangeName} size="small" name="title" label="Project Name" fullWidth />
        </Grid>
        <Grid item xs={4.5}>
          <Grid container spacing="6px">
            <Grid item xs={6}>
              <CSelect
                value={values.projectCategory}
                onChange={handleChange}
                label="Project Category"
                name="projectCategory"
                size="small"
                onClear={() => setFieldValue("projectCategory", "")}
                options={[
                  { label: "Business Development", value: ProjectCategory.BD },
                  {
                    label: "Non-Business Development",
                    value: ProjectCategory.NonBD,
                  },
                  { label: "Anorganik", value: ProjectCategory.Anorganik },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel size="small" id="organization-select-id">
                  Organization
                </InputLabel>
                <Select
                  labelId="organization-select-id"
                  id="organization-select-id"
                  label="Organization"
                  value={values.organizationId}
                  displayEmpty
                  disabled={!isSuperAdmin}
                  size="small"
                  endAdornment={
                    !!values.organizationId && isSuperAdmin ? (
                      <Close
                        onClick={() => {
                          setSelectedOrg(undefined);
                          setFieldValue("organizationId", "");
                        }}
                        sx={{ mr: 2, cursor: "pointer" }}
                        fontSize="small"
                      />
                    ) : undefined
                  }
                >
                  <MenuItem sx={{ display: "none" }} value={values.organizationId}>
                    {selectedOrg?.name}
                  </MenuItem>
                  <TreeView
                    aria-label="select organization"
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                    sx={{ height: 240, flexGrow: 1, overflowY: "auto" }}
                  >
                    {!!organizationsList?.length &&
                      organizationsList.map((item) => {
                        return renderTreeItem(item);
                      })}
                  </TreeView>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ alignItems: "right" }} item xs={4.5}>
          <Grid container spacing="6px">
            <Grid item xs={6}>
              <CSelect
                label="Status"
                value={values.status}
                onChange={handleChange}
                size="small"
                name="status"
                onClear={() => setFieldValue("status", "")}
                options={[
                  { label: "Proposal", value: ProjectStatus.Proposal },
                  { label: "Reviewer", value: ProjectStatus.Reviewer },
                  { label: "Decision Gate", value: ProjectStatus.DecisionGate },
                  { label: "FID", value: ProjectStatus.Fid },
                  { label: "Preparation", value: ProjectStatus.Preparation },
                  { label: "Procurement", value: ProjectStatus.Procurement },
                  { label: "Construction", value: ProjectStatus.Construction },
                  { label: "Project Closing", value: ProjectStatus.ProjectClosing },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Year"
                  disabled={!!year}
                  componentsProps={{
                    actionBar: {
                      actions: ["clear"],
                    },
                  }}
                  value={values.year}
                  views={["year"]}
                  inputFormat="yyyy"
                  onChange={(e: any) => setFieldValue("year", format(new Date(e), "yyyy") === "1970" ? null : format(new Date(e), "yyyy"))}
                  renderInput={(props) => <TextField onFocus={(e) => e.target.blur()} disabled size="small" fullWidth name="startYear" {...props} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <GridProject />
    </Paper>
  );
};

export default observer(ProjectDataGrid);
