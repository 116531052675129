import { useEffect } from "react";
import DashboardLayoutHeader from "../../../app/component/layout/DashboardLayoutHeader";
import { Grid, Paper, TextField, Box } from "@mui/material";
import { ArrowBackIos, InfoOutlined } from "@mui/icons-material";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { Link } from "react-router-dom";
import PageButton from "../components/PageButton";
// import CommentSection from "../components/CommentSection";
import LoadingSection from "../components/LoadingSection";
import useQuery from "../../../app/hook/useQuery";

function ScopeOfWork() {
  const { proposalStore } = useStore();
  const { scopeOfWork, scopeOfWorkLoading, getScopeOfWork, getGeneralInformation, generalInformation } = proposalStore;
  const { id } = useParams();
  const query = useQuery();
  const status = query.get("status");

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
  }, [getGeneralInformation, id]);

  useEffect(() => {
    if (!id) return;

    getScopeOfWork(id);
  }, [getScopeOfWork, id]);

  return (
    <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Approval",
            href: "/project-monitoring/approval/list",
          },
          {
            label: "Approval List",
            href: "/project-monitoring/approval/list",
          },
          {
            label: `${generalInformation?.title}`,
          },
        ]}
        pageTitle="Project Monitoring"
      />

      <Paper sx={{ p: "16px" }}>
        <Link
          style={{ color: "#000", textDecoration: "none", display: "flex", alignItems: "center", gap: "3px", marginBottom: "24px" }}
          to={`/project-monitoring/approval/list/${id}?status=${status}`}
        >
          <ArrowBackIos sx={{ fontSize: "14px" }} />
          <Box>Back</Box>
        </Link>
        <Box
          sx={{
            mb: "12px",
            backgroundColor: "#F2F9FF",
            display: "flex",
            p: "16px",
            alignItems: "center",
            gap: "6px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <InfoOutlined sx={{ mr: "12px", color: "#0664B4", fontSize: "34px" }} />
            <Box sx={{ fontWeight: "bold" }}>Lingkup Pekerjaan</Box>
          </Box>
          {/* {scopeOfWorkLoading ? "Loading..." : <CommentSection typeId={scopeOfWork?.length ? scopeOfWork[0]?.id : ""} />} */}
        </Box>

        {scopeOfWorkLoading ? (
          <LoadingSection />
        ) : (
          <Grid pt="12px" container spacing={3}>
            {scopeOfWork?.map((item, index) => (
              <Grid item xs={6}>
                <TextField
                  label={`Description ${scopeOfWork && scopeOfWork?.length > 1 ? index + 1 : ""}`}
                  rows={5}
                  multiline
                  value={item.description}
                  disabled
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>
        )}

        <PageButton
          isPrevButton
          isNextButton
          prevButtonRoute={`/project-monitoring/approval/list/${id}/backgroundObjective?status=${status}`}
          nextButtonRoute={`/project-monitoring/approval/list/${id}/timeline?status=${status}`}
        />
      </Paper>
      <Box sx={{ py: "16px" }} />
    </Box>
  );
}

export default observer(ScopeOfWork);
