import { useStore } from "../../app/stores/store";

export default function ServerError() {
    const { commonStore } = useStore();
    const { error } = commonStore;

    return (
        <>
            <h1>{error?.message}</h1>
            {error?.details && 
                <code>{error.details}</code>
            }
        </>
    );
}