import { Dialog, DialogContent, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { StatusDetailGrid } from "./components/StatusDetailGrid";
import { observer } from "mobx-react-lite";

type TStatusDetailDialogProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

function StatusDetailDialog({ isOpen, setIsOpen }: TStatusDetailDialogProps) {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog sx={{ "& .MuiDialog-paper": { maxWidth: "100%" } }} fullWidth open={isOpen} onClose={handleClose}>
      <DialogContent>
        <Typography sx={{ mb: "24px" }} color="black" fontWeight={700} fontSize="14px">
          Task List by Status
        </Typography>
        <StatusDetailGrid />
      </DialogContent>
    </Dialog>
  );
}

export default observer(StatusDetailDialog);
