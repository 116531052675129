import { Box, Typography } from "@mui/material";
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import useQuery from "../../../../app/hook/useQuery";
import ListIsEmptySvg from "../../../../app/component/svg/ListIsEmptySvg";
import { format } from "date-fns";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";

function ProposalRefidHistoryTab() {
  const { getRefidLogs, refidLogs, refidLogsLoading } = useStore().projectManagementStore
  const query = useQuery()
  const id = query.get('id')

  const renderEmptyState = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flex={1} flexDirection="column" height="100%">
        <ListIsEmptySvg />
        <Typography color="black" fontWeight={700}>There is nothing here</Typography>
      </Box>
    )
  }

  useEffect(() => {
    if (!id) return;

    getRefidLogs(id)
  }, [getRefidLogs, id])

  return (
    <Box>
      <Typography mb="16px" color="black" fontWeight={700} fontSize="20px">Re-FID Logs</Typography>
      {!!refidLogs.length ?
        <StyledDataGridPremium
          getRowId={(row) => row.submitedDate}
          disableRowSelectionOnClick
          autoHeight
          loading={refidLogsLoading}
          columns={[
            {
              field: "submitedDate",
              headerName: "Date",
              disableColumnMenu: true,
              flex: 0.5,
              headerAlign: 'center',
              align: 'center',
              renderCell: (params) => format(new Date(params.row.submitedDate), 'dd MMM yy - HH:mm:ss')
            },
            {
              field: "submitedBy",
              headerName: "Submited by",
              headerAlign: 'center',
              align: 'center',
              disableColumnMenu: true,
              flex: 0.5,
            },
            {
              field: "refidAmount",
              headerName: "Amount",
              disableColumnMenu: true,
              flex: 0.5,
              headerAlign: 'center',
              align: 'center',
              renderCell: (params) => `Rp${params.row.refidAmount.toLocaleString()}`
            },
            {
              field: "status",
              headerName: "Status",
              headerAlign: 'center',
              align: 'center',
              disableColumnMenu: true,
              flex: 0.5,
            },
            {
              field: "description",
              headerName: "Description",
              headerAlign: 'center',
              align: 'center',
              disableColumnMenu: true,
              flex: 0.5,
            },
          ]}
          rows={refidLogs}
        />
        : renderEmptyState()}
    </Box>
  )
}

export default observer(ProposalRefidHistoryTab)
