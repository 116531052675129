import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Drawer, Box, IconButton, CircularProgress } from "@mui/material";
import { useStore } from "../../../../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { Close } from "@mui/icons-material";
import { Stack } from "@mui/system";
import AttachmentList from "./AttachmentList";
import useQuery from "../../../../../../../app/hook/useQuery";
import { ReviewerStatus, Roles, TaskSubTaskStatus } from "../../../../../../../app/config/enum";
import { LoadingButton } from "@mui/lab";

type TAttachmentComment = {
  task?: any;
  subTask?: any;
  isOpenDrawer: boolean;
  proposalId: string;
  setIsOpenDrawer: Dispatch<SetStateAction<any>>;
  projectOwnerName: string;
};

const AUCTION_STATUS = {
  GAGAL_TENDER: "GAGAL_TENDER",
};

export const disabledButtonForFungsiPengusul = [TaskSubTaskStatus.DocumentTender, TaskSubTaskStatus.Lelang, TaskSubTaskStatus.PemenangLelang];

function AttachmentComment({ projectOwnerName, proposalId, task, subTask, isOpenDrawer, setIsOpenDrawer }: TAttachmentComment) {
  const { dialogStore } = useStore();
  const { getTaskAttachment, taskSubTaskAttachment, taskSubTaskAttachmentLoading, taskAttachmentLoading, getTaskSubTaskAttachment } =
    useStore().milestonetore;
  const { getAuctionStatus } = useStore().auctionStatusStore;
  const { getNewProgressRealization } = useStore().progressRealizationStore;
  const { reviewerAcceptance, reviewerAcceptanceLoading } = useStore().reviewerLogStore;
  const { account } = useStore().accountStore;
  const [currentStatus, setCurrentStatus] = useState<any>();
  const query = useQuery();
  const id = query.get("id");

  const disableSubmitButton = useMemo(() => {
    let helperStatus = subTask ? subTask.status : task.status;

    if (account?.roles.includes(Roles.Superadmin)) return false;
    if (disabledButtonForFungsiPengusul.includes(helperStatus) && !account?.roles.includes(Roles.Procurement)) return true;

    return false;
  }, []);

  const reviewAttachments = (status: string) => {
    if (!id) return;

    let helperStatus = subTask ? subTask.status : task.status;
    let helperPrevStep;

    if (helperStatus === TaskSubTaskStatus.Perencanaan) helperPrevStep = "";
    if (helperStatus === TaskSubTaskStatus.DocumentTender) helperPrevStep = TaskSubTaskStatus.Perencanaan;
    if (helperStatus === TaskSubTaskStatus.Lelang) helperPrevStep = TaskSubTaskStatus.DocumentTender;
    if (helperStatus === TaskSubTaskStatus.PemenangLelang) helperPrevStep = TaskSubTaskStatus.Lelang;
    if (helperStatus === TaskSubTaskStatus.Construction) helperPrevStep = TaskSubTaskStatus.PemenangLelang;
    if (helperStatus === TaskSubTaskStatus.Pembayaran) helperPrevStep = TaskSubTaskStatus.Construction;

    const payload = {
      auctionStatus: "",
      taskId: subTask ? subTask.tasksId : task.tasksId,
      subTaskId: subTask ? subTask.subTaskId : null,
      status,
      submittedOnStep: subTask ? subTask.status : task.status,
      prevStep: helperPrevStep,
    };

    dialogStore.open({
      action: async () =>
        await reviewerAcceptance(id, payload).then(() => {
          setIsOpenDrawer(false);
          if (id && account) getNewProgressRealization(id, account, projectOwnerName);
        }),
      actionText: "OK",
      title: status === ReviewerStatus.Refused ? "Refuse" : "Submit",
      closeText: "Cancel",
      actionButtonProps: {
        color: "error",
      },
      description:
        status === ReviewerStatus.Refused
          ? `Are you sure to refuse this attachment to previous step?`
          : `Are you sure want to proceed to next step with ${
              taskSubTaskAttachment?.filter((filterData) => filterData.isApproved === false && !filterData.status).length
            } attachments?`,
    });
  };

  useEffect(() => {
    if (subTask) {
      getTaskSubTaskAttachment(proposalId, subTask.tasksId, subTask.subTaskId);
    } else {
      getTaskAttachment(proposalId, task.tasksId);
    }
    getAuctionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentStatus(subTask ? subTask.status : task.status);
  }, [subTask, task]);

  return (
    <Drawer
      anchor="right"
      open={isOpenDrawer}
      onClose={() => setIsOpenDrawer(false)}
      sx={{
        ".MuiPaper-root": {
          width: "500px",
        },
      }}
    >
      <Box
        sx={{
          m: "16px 16px 0 16px",
          pb: "8px",
          borderBottom: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ color: "black", fontWeight: "bold" }}>Attachment Review</Box>
        <IconButton onClick={() => setIsOpenDrawer(false)}>
          <Close />
        </IconButton>
      </Box>
      <Stack flex={1} overflow="auto" p="0px 16px" spacing="24px">
        {taskSubTaskAttachmentLoading || taskAttachmentLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" my={5}>
            <CircularProgress />
          </Box>
        ) : (
          <AttachmentList
            disableSubmitButton={disableSubmitButton}
            proposalId={id ?? ""}
            currentStatus={currentStatus}
            setIsOpenDrawer={setIsOpenDrawer}
            subTask={subTask}
            task={task}
            isAttachmentReview
            columnOnLine={12}
            attachtment={taskSubTaskAttachment}
          />
        )}
      </Stack>
      <Box
        sx={{
          m: "0 16px 16px 16px",
          pt: "16px",
          borderTop: "1px solid #EAEAEA",
          gap: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {subTask ? (
          subTask.status === TaskSubTaskStatus.Construction ? (
            ""
          ) : (
            <>
              <LoadingButton
                loading={reviewerAcceptanceLoading}
                onClick={() => reviewAttachments(ReviewerStatus.Refused)}
                disabled={disableSubmitButton}
                variant="contained"
                color="error"
              >
                Refuse
              </LoadingButton>
              <LoadingButton
                loading={reviewerAcceptanceLoading}
                onClick={() => reviewAttachments(ReviewerStatus.Submitted)}
                variant="contained"
                disabled={disableSubmitButton}
                color="primary"
              >
                Submit
              </LoadingButton>
            </>
          )
        ) : task.status === TaskSubTaskStatus.Construction ? (
          ""
        ) : (
          <>
            <LoadingButton
              loading={reviewerAcceptanceLoading}
              onClick={() => reviewAttachments(ReviewerStatus.Refused)}
              variant="contained"
              disabled={disableSubmitButton}
              color="error"
            >
              Refuse
            </LoadingButton>
            <LoadingButton
              loading={reviewerAcceptanceLoading}
              onClick={() => reviewAttachments(ReviewerStatus.Submitted)}
              variant="contained"
              color="primary"
              disabled={
                (currentStatus === TaskSubTaskStatus.PemenangLelang && taskSubTaskAttachment[0]?.auctionStatus === AUCTION_STATUS.GAGAL_TENDER) ||
                disableSubmitButton
              }
            >
              Submit
            </LoadingButton>
          </>
        )}
      </Box>
    </Drawer>
  );
}

export default observer(AttachmentComment);
