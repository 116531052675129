import { useEffect, useMemo, useState } from "react";
import { Grid, Paper, TextField, Typography, Box, Collapse } from "@mui/material";
import { Check, Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import { ProjectCategory, ProjectStatus, SUPERADMIN } from "../../app/config/enum";
import { useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { Stack } from "@mui/system";
import ApproveProposalForm from "../projectMonitoring/proposal/form/ApproveProposalForm";
import RefuseProposalForm from "../projectMonitoring/proposal/form/RefuseProposalForm";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import CSelect from "../../app/component/CSelect";
import ApprovalFillPage from "./ApprovalFillPage";
import useQuery from "../../app/hook/useQuery";
import Milestone from "./proposal/Milestone";
import SummaryReviewList from "./components/SummaryReviewList";
import { NumericFormat } from "react-number-format";
import RecommendationsList from "./components/RecommendationsList";
import { TReviewers } from "../../app/models/proposalGeneralInformation";

function ApprovalDetail() {
  const { projectCategoryStore, proposalStore, accountStore } = useStore();
  const { account } = accountStore;
  const { getProjectCategory, projectCategory, projectCategoryLoading } = projectCategoryStore;
  const { getGeneralInformation, generalInformation, generalInformationLoading } = proposalStore;
  const [isOpenDetailInformation, setIsOpenDetailInformation] = useState<string>();
  const [category, setCategory] = useState<string>(ProjectCategory.Anorganik);
  const [isOpenSummaryReview, setIsOpenSummaryReview] = useState<string>();
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openRefuseModal, setOpenRefuseModal] = useState(false);
  const [title, setTitle] = useState<string>();
  const [cost, setCost] = useState<number>();
  const { id } = useParams();
  const query = useQuery();
  const status = query.get("status");

  const displayRefuseApproveButton = useMemo(() => {
    if (!generalInformation?.reviewers) return;
    const temp = [...generalInformation?.reviewers].map((item: TReviewers) => item.username);

    if (account?.username.toLowerCase() === SUPERADMIN.toLowerCase()) return true;
    if (account?.isReviewerLead && status === ProjectStatus.Reviewer) return true;
    if (temp.includes(account?.displayName ?? "")) return true;

    return false;
  }, [account, generalInformation]);

  const detailInformation = [
    {
      title: "Usulan Investasi",
      proposal: <ApprovalFillPage proposalId={id} category={generalInformation?.category} />,
    },
    {
      title: "CAPEX",
      proposal: <Milestone />,
    },
  ];

  const summaryReview = [
    {
      title: "Recommendations Reviewer",
      proposal: <RecommendationsList proposalId={id ?? ""} data={generalInformation?.recommendationReviewers ?? []} />,
    },
    {
      title: "List Review",
      proposal: <SummaryReviewList proposalId={id ?? ""} data={generalInformation?.reviewers ?? []} />,
    },
  ];

  const handleChangeCategory = (e: any) => {
    setCategory(e);
  };

  useEffect(() => {
    getProjectCategory();
  }, [getProjectCategory]);

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
  }, [getGeneralInformation, id]);

  useEffect(() => {
    setTitle(generalInformation?.title);
    setCost(generalInformation?.cost);
    handleChangeCategory(generalInformation?.category);
  }, [setTitle, generalInformation, setCost]);

  return (
    <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: status === ProjectStatus.Reviewer ? "Reviewer" : "Approval",
            href: "/project-monitoring/approval/list",
          },
          {
            label: status === ProjectStatus.Reviewer ? "Reviewer List" : "Approval List",
            href: "/project-monitoring/approval/list",
          },
          {
            label: status === ProjectStatus.Reviewer ? "Reviewer Change" : "Approval Change",
          },
        ]}
        pageTitle="Project Monitoring"
      >
        {displayRefuseApproveButton && (
          <Box sx={{ display: "flex", gap: "12px" }}>
            <DashboardLayoutHeader.Button
              disabled={status === ProjectStatus.DecisionGate}
              variant="contained"
              color="error"
              startIcon={<Close />}
              onClick={() => setOpenRefuseModal(true)}
            >
              Refuse
            </DashboardLayoutHeader.Button>
            <DashboardLayoutHeader.Button variant="contained" color="success" onClick={() => setOpenApproveModal(true)} startIcon={<Check />}>
              {status === ProjectStatus.Reviewer ? "Submit" : "Approve"}
            </DashboardLayoutHeader.Button>
          </Box>
        )}
        <RefuseProposalForm isOpen={openRefuseModal} onClose={() => setOpenRefuseModal(false)} />
        <ApproveProposalForm isOpen={openApproveModal} onClose={() => setOpenApproveModal(false)} />
      </DashboardLayoutHeader>

      <Paper sx={{ p: "16px" }}>
        <Box mb="24px">
          <Stack gap="24px">
            {!!generalInformation?.title && !!generalInformation.cost && (
              <Grid container spacing={1}>
                <Grid item xs={9}>
                  <Typography sx={{ mb: "12px" }} color="black" fontWeight={700} fontSize="20px">
                    Title
                  </Typography>
                  <TextField fullWidth disabled placeholder="Title" value={title} />
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ mb: "12px" }} color="black" fontWeight={700} fontSize="20px">
                    Cost
                  </Typography>
                  <NumericFormat
                    sx={{ width: "100%" }}
                    prefix={"Rp"}
                    thousandSeparator={true}
                    customInput={TextField}
                    placeholder="Cost"
                    value={cost}
                    disabled
                  />
                </Grid>
              </Grid>
            )}
            <CSelect
              loading={projectCategoryLoading || generalInformationLoading}
              disabled
              label="Kategori Proyek"
              onChange={(e) => handleChangeCategory(e.target.value)}
              options={projectCategory.map((i) => ({ label: i.text, value: i.value }))}
              value={category}
            />
          </Stack>
        </Box>

        {status === ProjectStatus.DecisionGate ? (
          <>
            <Typography sx={{ mb: "12px" }} color="black" fontWeight={700} fontSize="20px">
              Summary & Recommendations
            </Typography>
            {summaryReview.map((item) => (
              <Box
                sx={{
                  p: "16px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  border: "1px solid #DEDEDE",
                  background: "#FFF",
                  mb: "12px",
                }}
              >
                <Box
                  onClick={() => setIsOpenSummaryReview((prev) => (prev === item.title ? undefined : item.title))}
                  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  <Box>
                    <Box sx={{ fontWeight: "bold", color: "#212121" }}>{item.title}</Box>
                    <Box sx={{ fontSize: "12px" }}>Click to see detail</Box>
                  </Box>
                  <Box>{isOpenSummaryReview ? <ExpandLess /> : <ExpandMore />}</Box>
                </Box>
                <Collapse sx={{ mt: "24px" }} in={isOpenSummaryReview === item.title} timeout="auto" unmountOnExit>
                  {item.proposal}
                </Collapse>
              </Box>
            ))}
          </>
        ) : (
          ""
        )}

        <Typography sx={{ mb: "12px" }} color="black" fontWeight={700} fontSize="20px">
          Detail Information
        </Typography>
        {detailInformation.map((item) => (
          <Box
            sx={{
              p: "16px",
              cursor: "pointer",
              borderRadius: "4px",
              border: "1px solid #DEDEDE",
              background: "#FFF",
              mb: "12px",
            }}
          >
            <Box
              onClick={() => setIsOpenDetailInformation((prev) => (prev === item.title ? undefined : item.title))}
              sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
            >
              <Box>
                <Box sx={{ fontWeight: "bold", color: "#212121" }}>{item.title}</Box>
                <Box sx={{ fontSize: "12px" }}>Click to see detail</Box>
              </Box>
              <Box>{isOpenDetailInformation ? <ExpandLess /> : <ExpandMore />}</Box>
            </Box>
            <Collapse sx={{ mt: "24px" }} in={isOpenDetailInformation === item.title} timeout="auto" unmountOnExit>
              {item.proposal}
            </Collapse>
          </Box>
        ))}
      </Paper>
      <Box sx={{ py: "16px" }} />
    </Box>
  );
}

export default observer(ApprovalDetail);
