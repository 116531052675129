import { useState } from "react";
import { Box, Typography } from "@mui/material";
import AmandementDataGrid from "./AmandementDataGrid";
import AmandementForm from "./AmandementForm";

export default function AmandementPageIndex() {
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);

  return (
    <Box>
      <Typography sx={{ mt: "24px", mb: "24px" }} color="black" fontWeight={700} fontSize="20px">
        Ammandement List
      </Typography>
      <AmandementDataGrid />
      <AmandementForm isOpen={isOpenForm} onClose={() => setIsOpenForm(false)} />
    </Box>
  );
}
