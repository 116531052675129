import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useStore } from "../../../../../../app/stores/store";
import useQuery from "../../../../../../app/hook/useQuery";
import { IFidMilestone } from "../../../../../../app/models/fidMilestones";
import SCurveSchedulePerformanceIndex from "../tabs/SCurveSchedulePerformanceIndex";
import { observer } from "mobx-react-lite";

type TChartDialogProps = {
  isOpenDialog: boolean;
  setIsOpenDialog: Dispatch<SetStateAction<boolean>>;
  taskToEdit?: IFidMilestone["tasks"][0];
};

function ChartDialog({ taskToEdit, isOpenDialog, setIsOpenDialog }: TChartDialogProps) {
  const { getSCurveTask, sCurveTask, sCurveTaskLoading } = useStore().DashboardStore;
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const descriptionElementRef = useRef<HTMLElement>(null);

  const query = useQuery();
  const proposalId = query.get("id");

  const handleClose = () => setIsOpenDialog(false);

  useEffect(() => {
    if (!proposalId) return;

    getSCurveTask(proposalId, taskToEdit?.id ?? "");
  }, []);

  return (
    <Dialog fullWidth maxWidth="xl" open={isOpenDialog} onClose={handleClose}>
      <DialogTitle id="scroll-dialog-title">Task Chart</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          {sCurveTaskLoading ? (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <SCurveSchedulePerformanceIndex data={sCurveTask} />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default observer(ChartDialog);
