import { Box } from "@mui/material";
import ApprovalDataGrid from "./ApprovalDataGrid";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import useQuery from "../../app/hook/useQuery";
import { ProjectStatus } from "../../app/config/enum";

export default function ApprovalPageIndex() {
  const query = useQuery();
  const status = query.get("status");

  return (
    <Box>
      <DashboardLayoutHeader
        pageTitle="Project Monitoring"
        breadcrumbs={[
          { label: status === ProjectStatus.Reviewer ? "Reviewer" : "Approval", href: "/project-monitoring/approval/list" },
          { label: status === ProjectStatus.Reviewer ? "Reviewer List" : "Approval List", href: "/project-monitoring/approval/list" },
        ]}
      />
      <ApprovalDataGrid />
    </Box>
  );
}
