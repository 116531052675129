import { useFormik } from "formik";
import DrawerForm from "../../app/component/DrawerForm";
import * as yup from "yup";
import { TextField } from "@mui/material";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import CSelect from "../../app/component/CSelect";

const validationSchema = yup.object({
  name: yup.string().required(),
  category: yup.string().required(),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function ProjectCategoryForm({ isOpen, onClose }: { isOpen: boolean; onClose: () => any }) {
  const { addProjectCategoryAll, selectedProjectCategoryAll, editProjectCategoryAll, getProjectCategoryAll, setSelectedRegional } =
    useStore().projectCategoryAllStore;

  const initialValues: ValidationSchema = useMemo(() => {
    if (selectedProjectCategoryAll) {
      return selectedProjectCategoryAll;
    } else {
      return { name: "", category: "" };
    }
  }, [selectedProjectCategoryAll]);

  const onSubmit = async (values: ValidationSchema) => {
    if (selectedProjectCategoryAll) {
      await editProjectCategoryAll(selectedProjectCategoryAll.id, values).then(() => {
        getProjectCategoryAll();
        onClose();
        setSelectedRegional(null);
        resetForm();
        setErrors({
          name: undefined,
        });
      });
    } else {
      await addProjectCategoryAll(values).then(() => {
        getProjectCategoryAll();
        onClose();
        setSelectedRegional(null);
        resetForm();
        setErrors({
          name: undefined,
        });
      });
    }
  };

  const { handleSubmit, resetForm, setErrors, handleChange, values, errors, isValid, isSubmitting, dirty } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <DrawerForm
      title={`${selectedProjectCategoryAll ? "Edit" : "Add"} Project Category`}
      isOpen={isOpen || !!selectedProjectCategoryAll}
      onClose={() => {
        onClose();
        setSelectedRegional(null);
      }}
      onSubmit={handleSubmit}
      disableAction={!isValid || !dirty}
      isLoading={isSubmitting}
      isEdit={!!selectedProjectCategoryAll}
    >
      <TextField label="Name" fullWidth name="name" value={values.name} error={!!errors.name} helperText={errors.name} onChange={handleChange} />
      <CSelect
        name="category"
        label="Category"
        options={[
          { label: "Non Business Development", value: "NON_BD" },
          { label: "Business Development", value: "BD" },
        ]}
        fullWidth
        onChange={handleChange}
        value={values.category}
        error={!!errors.category}
        helperText={errors.category}
      />
    </DrawerForm>
  );
}

export default observer(ProjectCategoryForm);
