import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { useStore } from "../../app/stores/store";
import DynamicBarChartGrid, { TBarChartGridProps } from "./DynamicBarChartGrid";
import ProjectDataGrid from "./ProjectDataGrid";

type TBarDialogProps = TBarChartGridProps & {
  openDialog: boolean;
};

function DynamicBarDialog({ isCost = false, openDialog, series, labels, name }: TBarDialogProps) {
  const {
    setDataPointIndexBarChart,
    dashboardBarProjectSummary,
    dataPointIndexBarChart,
    dashboardBarRealizationPlanDetailLoading,
    dashboardBarProjectSummaryLoading,
    setDataPointIndexBarChartMilestones,
  } = useStore().DashboardStore;
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const descriptionElementRef = useRef<HTMLElement>(null);

  const handleClose = () => {
    setDataPointIndexBarChart("");
    setDataPointIndexBarChartMilestones(false);
  };

  return (
    <Dialog fullWidth maxWidth="xl" open={openDialog} onClose={handleClose}>
      <DialogTitle id="scroll-dialog-title">{`${
        isCost ? `${dataPointIndexBarChart} Tasks (dalam jutaan)` : `Project Summary ${dashboardBarProjectSummary?.labels[dataPointIndexBarChart as any]}`
      }`}</DialogTitle>
      <DialogContent dividers={scroll === "paper"}>
        <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
          {dashboardBarRealizationPlanDetailLoading || dashboardBarProjectSummaryLoading ? (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            // Cost means Project Budget
            <>
              {isCost ? (
                <DynamicBarChartGrid isCost series={series} labels={labels} name="Cost" />
              ) : (
                <ProjectDataGrid year={dashboardBarProjectSummary?.labels[dataPointIndexBarChart as any]} />
              )}
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default observer(DynamicBarDialog);
