import { useEffect } from "react";
import DashboardLayoutHeader from "../../../app/component/layout/DashboardLayoutHeader";
import { Grid, Paper, TextField, Box, Stack } from "@mui/material";
import { ArrowBackIos, QueryStatsOutlined } from "@mui/icons-material";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { Link } from "react-router-dom";
import PageButton from "../components/PageButton";
// import CommentSection from "../components/CommentSection";
import LoadingSection from "../components/LoadingSection";
import useQuery from "../../../app/hook/useQuery";
import AttachmentSection from "../components/AttachmentSection";
import { NumericFormat } from "react-number-format";

function EconomyAnalysis() {
  const { proposalStore } = useStore();
  const { economyAnalysis, economyAnalysisLoading, getEconomyAnalysis, getGeneralInformation, generalInformation } = proposalStore;
  const { id } = useParams();
  const query = useQuery();
  const status = query.get("status");

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
  }, [getGeneralInformation, id]);

  useEffect(() => {
    if (!id) return;

    getEconomyAnalysis(id);
  }, [getEconomyAnalysis, id]);

  return (
    <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Approval",
            href: "/project-monitoring/approval/list",
          },
          {
            label: "Approval List",
            href: "/project-monitoring/approval/list",
          },
          {
            label: `${generalInformation?.title}`,
          },
        ]}
        pageTitle="Project Monitoring"
      />

      <Paper sx={{ p: "16px" }}>
        <Link
          style={{ color: "#000", textDecoration: "none", display: "flex", alignItems: "center", gap: "3px", marginBottom: "24px" }}
          to={`/project-monitoring/approval/list/${id}?status=${status}`}
        >
          <ArrowBackIos sx={{ fontSize: "14px" }} />
          <Box>Back</Box>
        </Link>
        <Box
          sx={{
            mb: "12px",
            backgroundColor: "#F2F9FF",
            display: "flex",
            p: "16px",
            alignItems: "center",
            gap: "6px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <QueryStatsOutlined sx={{ color: "#0664B4", fontSize: "34px" }} />
            <Box sx={{ fontWeight: "bold" }}>Analisis Ekonomi</Box>
          </Box>
          {/* {economyAnalysisLoading ? "Loading..." : <CommentSection typeId={economyAnalysis?.length ? economyAnalysis[0]?.id : ""} />} */}
        </Box>

        {economyAnalysisLoading ? (
          <LoadingSection />
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Stack flex={1} overflow="auto" pt="12px" spacing="24px">
                  <TextField label="NPV (Rp)" value={economyAnalysis && economyAnalysis[0]?.npv} type="number" disabled />
                  <TextField label="IRR (%)" value={economyAnalysis && economyAnalysis[0]?.irr} type="number" disabled />
                  <TextField label="Hurdle Rate (%)" value={economyAnalysis && economyAnalysis[0]?.hurdleRate} type="number" disabled />
                  <TextField label="Payback Period (Tahun)" value={economyAnalysis && economyAnalysis[0]?.paybackPeriod} type="number" disabled />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack flex={1} overflow="auto" pt="12px" spacing="24px">
                  <TextField
                    fullWidth
                    label="Profitability Index (%)"
                    type="number"
                    value={economyAnalysis && economyAnalysis[0]?.profitablityIndex}
                    disabled
                  />
                  <NumericFormat
                    customInput={TextField}
                    label="Nilai Investasi (IDR)"
                    fullWidth
                    value={economyAnalysis && economyAnalysis[0]?.investationValueIDR}
                    thousandSeparator={true}
                    prefix="Rp "
                    disabled
                  />
                </Stack>
                <Stack pt="24px" spacing="24px" direction="row">
                  <TextField
                    fullWidth
                    label="Porsi Patrajasa (%)"
                    type="number"
                    value={economyAnalysis && economyAnalysis[0]?.pertaminaSizePercentage}
                    disabled
                  />
                  <NumericFormat
                    customInput={TextField}
                    label="Porsi Patrajasa (IDR)"
                    fullWidth
                    value={economyAnalysis && economyAnalysis[0]?.pertaminaSizeIDR}
                    thousandSeparator={true}
                    prefix="Rp "
                    disabled
                  />
                </Stack>
                <Stack direction="row" pt="24px" spacing="24px">
                  <NumericFormat
                    customInput={TextField}
                    label="KURS"
                    fullWidth
                    value={economyAnalysis && economyAnalysis[0]?.kursUSD}
                    thousandSeparator={true}
                    prefix="Rp "
                    disabled
                  />
                  <NumericFormat
                    customInput={TextField}
                    label="Porsi Patrajasa (IDR)"
                    fullWidth
                    value={economyAnalysis && economyAnalysis[0]?.pertaminaSizeUSD.toFixed(2)}
                    thousandSeparator={true}
                    prefix="Rp "
                    disabled
                  />
                  {/* <TextField label="Sensitivitas" value={economyAnalysis && economyAnalysis[0]?.sensitivity} disabled /> */}
                </Stack>
              </Grid>
            </Grid>
            <AttachmentSection attachtment={economyAnalysis?.length ? economyAnalysis[0]?.attachments : []} />
          </>
        )}

        <PageButton
          isPrevButton
          isNextButton
          prevButtonRoute={`/project-monitoring/approval/list/${id}/costplanAnalysis?status=${status}`}
          nextButtonRoute={`/project-monitoring/approval/list/${id}/risk?status=${status}`}
        />
      </Paper>
      <Box sx={{ py: "16px" }} />
    </Box>
  );
}

export default observer(EconomyAnalysis);
