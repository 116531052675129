import { Box } from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { Add } from "@mui/icons-material";
import ProjectTypeDataGrid from "./ProjectTypeDataGrid";
import ProjectTypeForm from "./ProjectTypeForm";
import { useState } from "react";

export default function ProjectTypeIndex() {
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false)
  
  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: 'Project Type',
            href: '/master/project-type'
          },
          {
            label: 'Project Type List',
            href: '/master/project-type'
          },
        ]}
        pageTitle="Project Type"
      >
        <DashboardLayoutHeader.Button 
          startIcon={<Add />}
          variant="contained"
          onClick={() => setIsOpenForm(true)}
        >
          Add Project Type
        </DashboardLayoutHeader.Button>
      </DashboardLayoutHeader>
      <ProjectTypeDataGrid />
      <ProjectTypeForm isOpen={isOpenForm} onClose={() => setIsOpenForm(false)} />
    </Box>
  )
}