import { useState } from "react";
import { Box, Chip, Dialog, DialogTitle, Grid, IconButton, Skeleton } from "@mui/material";
import { VisibilityOutlined } from "@mui/icons-material";
import { IProposalReviewer } from "../../app/models/proposalReviewer";
import { StyledDataGrid } from "../../app/component/StyledDataGrid";
import { ReviewerStatus } from "../../app/config/enum";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import ViewAttachment from "../../app/component/ViewAttachment";

type TReviewerReFid = {
  proposalId: string;
};

function ReviewerReFid({ proposalId }: TReviewerReFid) {
  const { reviewerRefidLoading, reviewerRefid } = useStore().proposalReviewerStore;
  const [currentReason, setCurrentReason] = useState<string | null>(null);

  const handleClickEye = (row: IProposalReviewer) => {
    setCurrentReason(row.reason);
  };

  return (
    <>
      <StyledDataGrid
        autoHeight
        loading={reviewerRefidLoading}
        columns={[
          {
            resizable: true,
            field: "name",
            headerName: "User",
            width: 150,
          },
          {
            resizable: true,
            field: "organization",
            headerName: "Organization",
            width: 200,
          },
          {
            field: "submittedDate",
            headerName: "Date",
            width: 200,
            renderCell: (params) => (params.row.submittedDate ? format(new Date(params.row.submittedDate), "dd MMM yyyy - hh:mm") : "-"),
            filterable: false,
            resizable: true,
            sortable: false,
          },
          {
            field: "submittedDuration",
            headerName: "Submitted Duration",
            width: 200,
            resizable: true,
          },
          {
            field: "status",
            headerName: "Status",
            width: 150,
            resizable: true,
            renderCell: (params) => {
              return <Chip label={params.row.status} />;
            },
          },
          {
            resizable: true,
            field: "from",
            width: 150,
            headerName: "From",
          },
          {
            resizable: true,
            field: "attachment",
            headerName: "Attachment",
            width: 250,
            renderCell: (params) => {
              if (!params.row.attachment) return "-";

              return (
                <Grid container>
                  {params.row.attachments.map((item: any) => (
                    <Grid item xs={12}>
                      <ViewAttachment loadingComponent={<Skeleton width={150} variant="text" />} url={item.url} proposalId={proposalId ?? ""}>
                        {item.fileName}
                      </ViewAttachment>
                    </Grid>
                  ))}
                </Grid>
              );
            },
          },
          {
            resizable: true,
            field: "reason",
            headerName: "Reason",
            width: 250,
          },
          {
            field: "action",
            headerName: "Action",
            sortable: false,
            width: 100,
            filterable: false,
            disableColumnMenu: true,
            renderCell(params) {
              return (
                <Box display="flex" justifyContent="flex-end">
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={() => handleClickEye(params.row.reason)}
                    disabled={params.row.status !== ReviewerStatus.Approved || params.row.status !== ReviewerStatus.Refused}
                  >
                    <VisibilityOutlined />
                  </IconButton>
                </Box>
              );
            },
          },
        ]}
        disableSelectionOnClick={true}
        getRowId={(row) => row.submittedDate + "-" + Math.random()}
        rows={reviewerRefid}
      />
      <Dialog open={!!currentReason} onClose={() => setCurrentReason(null)}>
        <DialogTitle title="Reason">Reason</DialogTitle>
      </Dialog>
    </>
  );
}

export default observer(ReviewerReFid);
