import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { TProjectCategoryAll, TProjectCategoryAllBody } from "../models/projectCategoryAll";

export class ProjectCategoryAllStore {
  projectCategoryAll: TProjectCategoryAll[] = [];
  projectCategoryAllLoading = false;

  selectedProjectCategoryAll: TProjectCategoryAll | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  getProjectCategoryAll = async () => {
    this.projectCategoryAllLoading = true;
    try {
      const res = await agent.General.getProjectCategoryAll();

      runInAction(() => (this.projectCategoryAll = res));

      return res;
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.projectCategoryAllLoading = false));
    }
  };

  addProjectCategoryAll = async (body: TProjectCategoryAllBody) => {
    try {
      await agent.General.addProjectCategoryAll(body);
    } catch (e) {
      throw e;
    }
  };

  editProjectCategoryAll = async (id: string, body: TProjectCategoryAllBody) => {
    try {
      await agent.General.editProjectCategoryAll(id, body);
    } catch (e) {
      throw e;
    }
  };

  setSelectedRegional = (data: TProjectCategoryAll | null) => {
    this.selectedProjectCategoryAll = data;
  };

  deleteProjectCategoryAll = async (id: string) => {
    try {
      await agent.General.deleteProjectCategoryAll(id);
    } catch (e) {
      throw e;
    }
  };
}
