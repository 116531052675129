import { Close } from "@mui/icons-material";
import { Box, Button, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { DraggableFileUploadData } from "../../../../app/component/input/DraggableFileUpload";
import { useEffect, useMemo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useStore } from "../../../../app/stores/store";
import useQuery from "../../../../app/hook/useQuery";
import { observer } from "mobx-react-lite";
import { IProposalAttachment } from "../../../../app/models/proposalAttachment";
import DraggableFileUploadV2 from "../../../../app/component/input/DraggableFileUploadV2";
import DraggableFileUploadV3, { DraggableFileUploadDataV3 } from "../../../../app/component/input/DraggableFileUploadV3";
import { IProposalKajianFS } from "../../../../app/models/proposalKajianFs";
import { ProjectCategory, ProjectStatus } from "../../../../app/config/enum";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  readOnly: boolean;
};

function ProposalKajianFSForm({ isOpen, handleClose, readOnly }: Props) {
  const { dialogStore, proposalStore } = useStore();
  const { addKajianFs, generalInformation, getKajianFs, deleteKajianFs, kajianFs, editKajianFs, deleteAttachment } = proposalStore;
  const query = useQuery();
  const id = query.get("id");
  const status = query.get("status");
  const projectCategory = query.get("projectCategory");

  const [loadingAttc, setLoadingAttc] = useState(false);
  const [files, setFiles] = useState<DraggableFileUploadDataV3<IProposalKajianFS>[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const isValid = useMemo(() => {
    return files.length > 0;
  }, [files]);

  const submitForm = async () => {
    if (readOnly) return;

    if (!id) return;
    if (!projectCategory) return;

    setIsLoading(true);

    try {
      const formData = new FormData();

      files.forEach((file: any, index) => {
        formData.append(`category`, projectCategory);

        if (!!file.data?.id) {
          formData.append(`attachment`, file.data?.attachment!);

          formData.append(`id`, file.data?.id);
          formData.append(`isEdit`, String(1));
        } else {
          formData.append(`attachment`, file.file as any);
        }
      });

      if (!!kajianFs) {
        await editKajianFs(id, formData);
      } else {
        await addKajianFs(id, formData);
      }

      getKajianFs(id);

      handleClose();
      setFiles([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!!kajianFs) {
      setFiles(() => [
        {
          file: { name: kajianFs.fileName, id: kajianFs.id },
          title: "",
          data: kajianFs,
          fileName: kajianFs.fileName,
        },
      ]);
    }
  }, [kajianFs]);

  const displayTitle =
    ((projectCategory === ProjectCategory.Anorganik || projectCategory === ProjectCategory.BD) && "Kajian FS") ||
    (projectCategory === ProjectCategory.NonBD && "Kajian PS");

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      sx={{
        ".MuiPaper-root": {
          width: "500px",
        },
      }}
    >
      <Box
        sx={{
          m: "16px 16px 0 16px",
          pb: "8px",
          borderBottom: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "space-between",
          mb: "16px",
        }}
      >
        <Typography fontSize={"14px"} fontWeight={700} color="black">
          {!!kajianFs ? "Edit" : "Add"} {displayTitle}
        </Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <Stack component="form" flex={1} overflow="auto" p="16px" spacing="24px">
        <DraggableFileUploadV3
          loading={loadingAttc}
          viewable
          viewableOptions={{ propsoalId: id ?? "" }}
          multiple={false}
          onDelete={(data) => {
            if (!id) return;

            if (files.length > 0 && status === ProjectStatus.Fid) {
              dialogStore.open({
                title: "Info",
                closeText: "Close",
                actionButtonProps: {
                  color: "error",
                },
                description: "Can't delete item when proposal status is FID",
              });
              return;
            }

            setLoadingAttc(true);

            deleteKajianFs(id)
              .then(() => {
                getKajianFs(id);
                setFiles([]);
              })
              .finally(() => setLoadingAttc(false));
          }}
          data={files}
          setData={setFiles as any}
        />
      </Stack>
      <Box
        sx={{
          m: "0 16px 16px 16px",
          pt: "16px",
          borderTop: "1px solid #EAEAEA",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <LoadingButton variant="contained" loading={isLoading} disabled={!isValid || isLoading || readOnly} onClick={submitForm}>
          Add
        </LoadingButton>
      </Box>
    </Drawer>
  );
}

export default observer(ProposalKajianFSForm);
