import { ChevronRight, Close, ExpandMore, KeyboardArrowDown, KeyboardArrowUp, VisibilityOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  ListItem as MuiListItem,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useStore } from "../../../app/stores/store";
import EmptyState from "../../../app/component/EmptyState";
import useQuery from "../../../app/hook/useQuery";
import { useFormik } from "formik";
import * as yup from "yup";
import { LoadingButton, TreeItem, TreeView } from "@mui/lab";
import { observer } from "mobx-react-lite";
import { IFidMilestone } from "../../../app/models/fidMilestones";
import DrawerForm from "../../../app/component/DrawerForm";
import { TaskSubTaskStatus } from "../../../app/config/enum";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CSelect from "../../../app/component/CSelect";
import { NumericFormat } from "react-number-format";
import { findDeepObject } from "../../../app/utils/utils";
import { useParams } from "react-router-dom";
// import CommentSection from "../components/CommentSection";

function Milestone() {
  const { getMilestone, addMilestone, milestonesLoading, milestones } = useStore().milestonetore;
  const { getGeneralInformation, generalInformation } = useStore().proposalStore;
  const [addMilestoneForm, setAddMileStoreForm] = useState(false);
  const { id } = useParams();

  const closeAddForm = () => {
    setAddMileStoreForm(false);
  };

  const addMilesoneFormik = useFormik({
    initialValues: {
      name: "",
      cost: 0,
    },
    onSubmit: async (values) => {
      if (!id) return;

      await addMilestone(id, values).then((res) => {
        setAddMileStoreForm(false);
        getMilestone(id);
        addMilesoneFormik.resetForm();
        addMilesoneFormik.setErrors({
          name: undefined,
          cost: undefined,
        });
      });
    },
    validationSchema: yup.object({
      name: yup.string().required(),
      cost: yup.number().positive().required(),
    }),
  });

  useEffect(() => {
    if (id) getMilestone(id);
  }, [getMilestone, id]);

  useEffect(() => {
    if (!id) return;

    getGeneralInformation(id);
  }, [getGeneralInformation, id]);

  return (
    <Box>
      {generalInformation && (
        <Box
          sx={{
            backgroundColor: ({ palette }) => palette.primary.light,
            display: "flex",
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              flex: 1,
              p: 2,
              borderRadius: 2,
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Typography fontSize="20px" fontWeight={700} sx={{ color: ({ palette }) => palette.primary.main }}>
              {generalInformation?.title}
            </Typography>
            <Typography fontSize="20px" fontWeight={700} sx={{ color: ({ palette }) => palette.primary.main }}>
              Rp {generalInformation?.cost.toLocaleString()}
            </Typography>
          </Box>
          {/* <hr style={{}} className="rounded" />
          <Box sx={{ display: "flex", flex: 0.5, alignItems: "center", justifyContent: "flex-end", pr: "24px" }}>
            <CommentSection typeStatus="FID" typeId={generalInformation?.id} type="fid" />
          </Box> */}
        </Box>
      )}
      {milestonesLoading ? (
        <Box display="flex" justifyContent="center" py="32px">
          <CircularProgress />
        </Box>
      ) : milestones.data.length === 0 ? (
        <EmptyState />
      ) : (
        milestones.data.map((item, index) => <ListItem milestone={item} key={index} />)
      )}
      <Dialog open={addMilestoneForm} onClose={closeAddForm}>
        <DialogContent sx={{ padding: "16px", width: "365px" }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight="700" fontSize="14px" color="black">
              Add Milestone
            </Typography>
            <Close onClick={closeAddForm} />
          </Stack>
          <Box mt={"16px"} display="flex" flexDirection="column" gap={3}>
            <TextField
              fullWidth
              label="Name"
              value={addMilesoneFormik.values.name}
              onChange={addMilesoneFormik.handleChange}
              error={!!addMilesoneFormik.errors.name}
              helperText={addMilesoneFormik.errors.name}
              name="name"
            />
            <NumericFormat
              customInput={TextField}
              label="Cost"
              fullWidth
              placeholder="Cost"
              onKeyDown={(e) => {
                if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                  e.preventDefault();
                }
              }}
              value={addMilesoneFormik.values.cost}
              thousandSeparator={true}
              prefix={"Rp"}
              onValueChange={(e) => {
                addMilesoneFormik.setFieldValue("cost", e.floatValue);
              }}
            />
          </Box>
          <Stack direction="row" justifyContent="flex-end" gap="12px" mt={"24px"}>
            <Button onClick={closeAddForm} type="button" variant="outlined" color="inherit">
              Cancel
            </Button>
            <LoadingButton
              onClick={addMilesoneFormik.submitForm}
              disabled={!addMilesoneFormik.isValid || !addMilesoneFormik.dirty}
              loading={addMilesoneFormik.isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
            >
              Add
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default observer(Milestone);

type ListItemProps = {
  milestone: IFidMilestone;
};

type OrgList = {
  value: string;
  id: string;
  level: string;
  name: string;
  child: OrgList[];
};

const ListItem = observer(({ milestone: { isOrigin, cost, milestonesName, milestonesId, tasks } }: ListItemProps) => {
  const { milestonetore } = useStore();
  const [open, setIsOpen] = React.useState(false);
  const [isOpenAddMOdal, setIsOpenAddMOdal] = useState(false);
  const [currentTask, setCurrentTask] = useState<IFidMilestone["tasks"][0]>();
  const { organizationStore } = useStore();
  const [organizationsList, setOrganizationsList] = useState<OrgList[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<OrgList>();

  const toggleExpandCollapse = () => setIsOpen((prev) => !prev);
  const query = useQuery();
  const id = query.get("id");

  const initialValues = useMemo(() => {
    if (currentTask) {
      return currentTask;
    } else {
      return {
        milestoneId: milestonesId,
        name: "",
        organizationId: "",
        startDate: new Date(),
        endDate: new Date(),
        weightPhysical: 0,
        weightFinancial: 0,
        status: TaskSubTaskStatus.Construction,
      };
    }
  }, [milestonesId, currentTask]);

  const addTaskFormik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      if (!id) return;

      if (currentTask) {
        await milestonetore.editTask(currentTask.id, values).then(() => {
          milestonetore.getMilestone(id);
          setIsOpenAddMOdal(false);
          addTaskFormik.resetForm();
          setCurrentTask(undefined);
        });
      } else {
        await milestonetore.addTask(values).then(() => {
          milestonetore.getMilestone(id);
          setIsOpenAddMOdal(false);
          addTaskFormik.resetForm();
          setCurrentTask(undefined);
        });
      }
    },
    validationSchema: yup.object({
      milestoneId: yup.string().required(),
      name: yup.string().required(),
      startDate: yup.date().required(),
      endDate: yup.date().required(),
      weightPhysical: yup.number().positive().max(100).required(),
      weightFinancial: yup.number().required().positive(),
      status: yup.string().required(),
      organizationId: yup.string().required(),
    }),
    enableReinitialize: true,
  });

  const handleOpenEditModal = (item: IFidMilestone["tasks"][0]) => {
    setCurrentTask(item);
    setIsOpenAddMOdal(true);
  };

  const handleChangeOrganizationId = (node: any) => {
    setSelectedOrg(node);
    addTaskFormik.setFieldValue("organizationId", node.id);
  };

  const renderTreeItem = (nodes: any) => {
    return (
      <TreeItem onClick={() => handleChangeOrganizationId(nodes)} key={nodes.id} nodeId={nodes.id} label={nodes.name}>
        {Array.isArray(nodes.child) ? nodes.child.map((node: any) => renderTreeItem(node)) : null}
      </TreeItem>
    );
  };

  const handleCloseAddTask = () => {
    setIsOpenAddMOdal(false);
    addTaskFormik.resetForm();
    addTaskFormik.setErrors({
      endDate: undefined,
      id: undefined,
      milestoneId: undefined,
      name: undefined,
      organizationId: undefined,
      organizationName: undefined,
      startDate: undefined,
      status: undefined,
      weightFinancial: undefined,
      weightPhysical: undefined,
    });
    setCurrentTask(undefined);
  };

  useEffect(() => {
    const result = findDeepObject(organizationsList, "id", addTaskFormik.values.organizationId);
    setSelectedOrg(result ?? undefined);
  }, [organizationsList, addTaskFormik.values.organizationId]);

  useEffect(() => {
    if (open) {
      organizationStore.getOrganizationList().then((organizationRes) => {
        setOrganizationsList(organizationRes?.map((item) => ({ ...item, text: item.name, value: item.id } as any)) ?? []);
      });
    }
  }, [open, organizationStore]);

  return (
    <>
      <Stack direction="row" alignItems="center" gap="8px">
        <Stack
          py={"20px"}
          sx={{
            cursor: "pointer",
            width: "100%",
            justifyContent: "flex-start",
          }}
          component={ButtonBase}
          onClick={toggleExpandCollapse}
        >
          <Stack flex={1} direction="row" spacing={"16px"} justifyContent="space-between" pr={4} sx={{ width: "100%" }}>
            <Stack direction="row" alignItems="center" spacing={"16px"}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              <Typography fontWeight={700} color="black">
                {milestonesName}
              </Typography>
            </Stack>
            <Typography fontWeight={700} color="black">
              Rp {cost ? cost.toLocaleString() : 0}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Collapse in={open} timeout="auto" unmountOnExit>
        {tasks.length > 0 ? (
          <Table>
            <TableHead>
              <TableCell sx={{ fontWeight: 700 }}>Task</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Organization</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Start Date</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>End Date</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Weight Physical (%)</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Weight Financial (IDR)</TableCell>
              <TableCell />
            </TableHead>
            <TableBody>
              {tasks.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.organizationName}</TableCell>
                  <TableCell>
                    <Chip component={"div"} label={item.status} color="primary" />
                  </TableCell>
                  <TableCell>{format(new Date(item.startDate), "dd/MM/yyyy")}</TableCell>
                  <TableCell>{format(new Date(item.endDate), "dd/MM/yyyy")}</TableCell>
                  <TableCell>{item.weightPhysical}%</TableCell>
                  <TableCell>Rp {item.weightFinancial.toLocaleString()}</TableCell>
                  <TableCell sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <IconButton size="small" color="inherit" onClick={() => handleOpenEditModal(item)}>
                      <VisibilityOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyState />
        )}
      </Collapse>
      <DrawerForm
        isOpen={isOpenAddMOdal}
        onClose={handleCloseAddTask}
        onSubmit={addTaskFormik.handleSubmit}
        isEdit={false}
        title="Add Task"
        disableAction={!addTaskFormik.dirty || !addTaskFormik.isValid}
        isLoading={addTaskFormik.isSubmitting}
        customButton={
          <>
            {currentTask && (
              <LoadingButton
                variant="outlined"
                loading={milestonetore.deleteTaskLoading}
                disabled
                color="error"
                onClick={() =>
                  currentTask &&
                  milestonetore.deleteTask(currentTask.id).then(() => {
                    if (!id) return;

                    milestonetore.getMilestone(id);
                    setIsOpenAddMOdal(false);
                    addTaskFormik.resetForm();
                  })
                }
                type="button"
              >
                Delete
              </LoadingButton>
            )}
            <LoadingButton variant="contained" loading={addTaskFormik.isSubmitting} disabled type="submit">
              Edit
            </LoadingButton>
          </>
        }
      >
        <TextField
          label="Name"
          name="name"
          required
          disabled
          fullWidth
          value={addTaskFormik.values.name}
          error={!!addTaskFormik.errors.name}
          helperText={addTaskFormik.errors.name}
          onChange={addTaskFormik.handleChange}
        />
        <Stack direction="row" spacing="24px">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled
              label="Start Date"
              value={addTaskFormik.values.startDate}
              onChange={(e: any) => addTaskFormik.setFieldValue("startDate", e)}
              renderInput={(props) => (
                <TextField
                  required
                  fullWidth
                  disabled
                  name="startDate"
                  error={!!addTaskFormik.errors.startDate}
                  helperText={addTaskFormik.errors.startDate && String(addTaskFormik.errors.startDate)}
                  {...props}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disabled
              label="End Date"
              value={addTaskFormik.values.endDate}
              onChange={(e: any) => addTaskFormik.setFieldValue("endDate", e)}
              renderInput={(props) => (
                <TextField
                  required
                  fullWidth
                  disabled
                  name="endDate"
                  error={!!addTaskFormik.errors.endDate}
                  helperText={addTaskFormik.errors.endDate && String(addTaskFormik.errors.endDate)}
                  {...props}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
        <FormControl fullWidth>
          <InputLabel shrink id="organization-select-id">
            Organization
          </InputLabel>
          <Select
            labelId="organization-select-id"
            id="organization-select-id"
            disabled
            label="Organization"
            value={addTaskFormik.values.organizationId}
            displayEmpty
          >
            <MenuItem sx={{ display: "none" }} value={addTaskFormik.values.organizationId}>
              {selectedOrg?.name}
            </MenuItem>
            <TreeView
              aria-label="select organization"
              defaultCollapseIcon={<ExpandMore />}
              defaultExpandIcon={<ChevronRight />}
              sx={{ height: 240, flexGrow: 1, overflowY: "auto" }}
            >
              {!!organizationsList?.length &&
                organizationsList.map((item) => {
                  return renderTreeItem(item);
                })}
            </TreeView>
          </Select>
        </FormControl>
        <TextField
          label="Weight Physical (%)"
          name="weightPhysical"
          required
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
              e.preventDefault();
            }
          }}
          fullWidth
          value={addTaskFormik.values.weightPhysical}
          error={!!addTaskFormik.errors.weightPhysical}
          helperText={addTaskFormik.errors.weightPhysical}
          onChange={addTaskFormik.handleChange}
          type="number"
          disabled
        />
        <NumericFormat
          customInput={TextField}
          label="Weight Financial"
          name="weightFinancial"
          required
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
              e.preventDefault();
            }
          }}
          fullWidth
          value={addTaskFormik.values.weightFinancial}
          error={!!addTaskFormik.errors?.weightFinancial}
          helperText={addTaskFormik.errors?.weightFinancial}
          thousandSeparator={true}
          onValueChange={(e) => {
            addTaskFormik.setFieldValue(`weightFinancial`, e.floatValue);
          }}
          disabled
          prefix="Rp "
        />
        <CSelect
          options={Object.keys(TaskSubTaskStatus).map((i) => ({
            label: i === "InProgress" ? "In Progress" : i,
            value: (TaskSubTaskStatus as any)[i],
          }))}
          label="Status"
          name="status"
          disabled
          value={addTaskFormik.values.status}
          error={!!addTaskFormik.errors.status}
          helperText={addTaskFormik.errors.status}
          onChange={addTaskFormik.handleChange}
        />
      </DrawerForm>
    </>
  );
});
