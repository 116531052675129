import { useState, useEffect } from "react";
import { Box, Button, Collapse, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { FillFormItem } from "../projectMonitoring/proposal/FillFormItem";
import { BallotOutlined, Check, Close, ExpandLess, ExpandMore, InfoOutlined } from "@mui/icons-material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { useStore } from "../../app/stores/store";
import { ReFidGeneralInfoForm } from "./form/ReFidGeneralInfoForm";
import MilestonesDrawerForm from "./capex/index";
import useQuery from "../../app/hook/useQuery";
import { NumericFormat } from "react-number-format";
import { history } from "../..";
import { observer } from "mobx-react-lite";
import ReviewerReFid from "./ReviewerReFid";
import ApproveDialog from "./dialog/ApproveDialog";
import { ProjectStatus } from "../../app/config/enum";
import RefuseDialog from "./dialog/RefuseDialog";
import RequesterNotes from "./RequesterNotes";

function ReFidDetailPage() {
  const { dialogStore, proposalStore, proposalReviewerStore } = useStore();
  const { reFidGeneralInformation, reFidGeneralInformationLoading, getReFidGeneralInformation, getCapexGrid, approveReFid } = useStore().reFidStore;
  const { getGeneralInformation, generalInformation } = proposalStore;
  const { getReviewerRefid } = proposalReviewerStore;
  const [generalInformationDrawerOpen, setGeneralInformationDrawerOpen] = useState(false);
  const [isOpenSummaryReview, setIsOpenSummaryReview] = useState<string>();
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openRefuseModal, setOpenRefuseModal] = useState(false);
  const [capexDrawer, setCapexDrawer] = useState(false);
  const params = useParams();
  const id = params.id;
  const query = useQuery();
  const proposalId = query.get("proposalId");
  const status = query.get("status");

  const refidDetail = [
    {
      title: "Requester Notes",
      proposal: (
        <RequesterNotes
          notes={reFidGeneralInformation?.notes ?? ""}
          attachmentList={reFidGeneralInformation?.submitAttachments ?? []}
          proposalId={proposalId ?? ""}
        />
      ),
    },
    {
      title: "Reviewer List",
      proposal: <ReviewerReFid proposalId={proposalId ?? ""} />,
    },
  ];

  useEffect(() => {
    if (!proposalId) return;

    getCapexGrid(proposalId);
    getGeneralInformation(proposalId);
    getReFidGeneralInformation(proposalId);
    getReviewerRefid(proposalId);
  }, [getCapexGrid, getGeneralInformation, getReFidGeneralInformation, getReviewerRefid, proposalId]);

  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: "Approval Re-FID",
            href: "/project-monitoring/re-fid/list",
          },
          {
            label: "Re-FID List",
            href: "/project-monitoring/re-fid/list",
          },
          {
            label: id ?? "",
            href: `/project-monitoring/re-fid/list/${id}`,
          },
        ]}
        pageTitle="Approval Re-FID"
      >
        <div>
          <Button
            sx={{ mr: "12px" }}
            color="error"
            startIcon={<Close />}
            variant="contained"
            onClick={() => {
              if (!id || !status) return;

              if (status === ProjectStatus.DecisionGate) {
                setOpenRefuseModal(true);
              } else {
                const payload = { status: "REFUSE", notes: "", requestReFidId: reFidGeneralInformation?.id ?? "" };

                dialogStore.open({
                  action: async () => approveReFid(id, status, payload).then(() => history.push(`/project-monitoring/re-fid/list`)),
                  actionText: "Refuse",
                  title: "Refuse",
                  closeText: "Cancel",
                  actionButtonProps: {
                    color: "error",
                  },
                  description: "Are you sure want to refuse this Re-FID?",
                });
              }
            }}
          >
            Refuse
          </Button>
          <Button
            color="success"
            variant="contained"
            startIcon={<Check />}
            onClick={() => {
              if (!id || !status) return;

              if (status === ProjectStatus.DecisionGate) {
                setOpenApproveModal(true);
              } else {
                const payload = { status: "APPROVED", notes: "", requestReFidId: reFidGeneralInformation?.id ?? "" };

                dialogStore.open({
                  action: async () => approveReFid(id, status, payload).then(() => history.push(`/project-monitoring/re-fid/list`)),
                  actionText: "Submit",
                  title: "Approve",
                  closeText: "Cancel",
                  actionButtonProps: {
                    color: "primary",
                  },
                  description: "Are you sure want to approve this Re-FID?",
                });
              }
            }}
          >
            Approve
          </Button>
        </div>
      </DashboardLayoutHeader>
      <Paper sx={{ p: 2 }}>
        <Box mb="24px">
          <Stack gap="24px">
            {!!generalInformation?.title && !!generalInformation.cost && (
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <Typography sx={{ mb: "12px" }} color="black" fontWeight={700} fontSize="20px">
                    Title
                  </Typography>
                  <TextField fullWidth disabled placeholder="Title" value={generalInformation.title} />
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ mb: "12px" }} color="black" fontWeight={700} fontSize="20px">
                    Previous Cost
                  </Typography>
                  <NumericFormat
                    sx={{ width: "100%" }}
                    prefix={"Rp"}
                    thousandSeparator={true}
                    customInput={TextField}
                    placeholder="Cost"
                    value={generalInformation.cost}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Kategori Proyek" value={generalInformation.categoryName} disabled />
                </Grid>
              </Grid>
            )}
          </Stack>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="black" fontWeight={700} fontSize="20px">
              Detail Information
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ cursor: "pointer" }} onClick={() => (reFidGeneralInformationLoading ? {} : setGeneralInformationDrawerOpen(true))}>
              <FillFormItem icon={<InfoOutlined />} text="General Information" added />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ cursor: "pointer" }} onClick={() => (!reFidGeneralInformation ? {} : setCapexDrawer(true))}>
              <FillFormItem icon={<BallotOutlined />} text="Capex" added />
            </Box>
          </Grid>
        </Grid>

        {refidDetail.map((item) => (
          <Box
            sx={{
              p: "16px",
              cursor: "pointer",
              borderRadius: "4px",
              border: "1px solid #DEDEDE",
              background: "#FFF",
              mb: "12px",
              my: "24px",
            }}
          >
            <Box
              onClick={() => setIsOpenSummaryReview((prev) => (prev === item.title ? undefined : item.title))}
              sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
            >
              <Box>
                <Box sx={{ fontWeight: "bold", color: "#212121" }}>{item.title}</Box>
                <Box sx={{ fontSize: "12px" }}>Click to see detail</Box>
              </Box>
              <Box>{isOpenSummaryReview ? <ExpandLess /> : <ExpandMore />}</Box>
            </Box>
            <Collapse sx={{ mt: "24px" }} in={isOpenSummaryReview === item.title} timeout="auto" unmountOnExit>
              {item.proposal}
            </Collapse>
          </Box>
        ))}
      </Paper>
      <ApproveDialog isOpen={openApproveModal} onClose={() => setOpenApproveModal(false)} />
      <RefuseDialog isOpen={openRefuseModal} onClose={() => setOpenRefuseModal(false)} />
      <ReFidGeneralInfoForm readonly isOpen={generalInformationDrawerOpen} setIsOpen={setGeneralInformationDrawerOpen} />
      <MilestonesDrawerForm approvalMode setIsOpen={setCapexDrawer} isOpen={capexDrawer} />
    </Box>
  );
}

export default observer(ReFidDetailPage);
