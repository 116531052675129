import { Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { useStore } from "../../app/stores/store";
import ProjectDataGrid from "./ProjectDataGrid";
import LoadingSection from "../approval/components/LoadingSection";

function Home() {
  const {
    accountStore: { getUser },
  } = useStore();
  const { getProjectCategoryAll } = useStore().projectCategoryAllStore;
  const {
    dashboardPieCategoryLoading,
    dashboardPieOverdueLoading,
    dashboardProjectStatusLoading,
    dashboardLineProjectCountLoading,
    dashboardBarRealizationLoading,
  } = useStore().DashboardStore;

  const isLoading = useMemo(() => {
    if (dashboardPieCategoryLoading) return true;
    if (dashboardProjectStatusLoading) return true;
    if (dashboardPieOverdueLoading) return true;
    if (dashboardBarRealizationLoading) return true;
    if (dashboardLineProjectCountLoading) return true;

    return false;
  }, [
    dashboardBarRealizationLoading,
    dashboardLineProjectCountLoading,
    dashboardPieCategoryLoading,
    dashboardPieOverdueLoading,
    dashboardProjectStatusLoading,
  ]);

  useEffect(() => {
    getUser();
    getProjectCategoryAll();
  }, [getProjectCategoryAll, getUser]);

  return (
    <Stack gap={3}>
      <Typography sx={{ textAlign: "center", my: "12px" }} variant="h1" color="black">
        Report
      </Typography>
      {isLoading ? <LoadingSection /> : <ProjectDataGrid />}
    </Stack>
  );
}

export default observer(Home);
