import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { TRecommendations } from "../../../app/models/proposalGeneralInformation";
import EmptyState from "../../../app/component/EmptyState";
import ViewAttachment from "../../../app/component/ViewAttachment";
import { DescriptionOutlined } from "@mui/icons-material";

type TRecommendationsList = {
  data: TRecommendations[];
  proposalId: string;
};

export default function RecommendationsList({ proposalId, data = [] }: TRecommendationsList) {
  return (
    <Box>
      {data.length > 0 ? (
        <>
          {data.map((item) => (
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <AccountCircleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography color="black">{item?.username}</Typography>}
                  secondary={
                    <Box>
                      <Typography sx={{}}>Summary Review: {item?.summaryReview ?? ""}</Typography>
                      <Typography sx={{}}>Reecommendations: {item?.recommendation ?? ""}</Typography>
                    </Box>
                  }
                />
              </ListItem>
              <hr />
              <Box sx={{ ml: "18px" }}>
                <Typography fontWeight="bold" color="black" sx={{ textAlign: "center", mb: "12px" }}>
                  Attachment List
                </Typography>
                {item.attachments.length > 0 ? (
                  <Grid container spacing={2}>
                    {item.attachments.map((attachment: any) => (
                      <Grid item xs={6}>
                        <Box
                          sx={{
                            display: "flex",
                            p: "8px",
                            borderRadius: "4px",
                            border: "1px solid rgba(29, 27, 32, 0.12)",
                          }}
                        >
                          <ViewAttachment loadingComponent={<Skeleton width={150} variant="text" />} url={attachment.url} proposalId={proposalId}>
                            <DescriptionOutlined sx={{ cursor: "pointer" }} />
                          </ViewAttachment>
                          <Box
                            sx={{
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              display: "flex",
                              ml: "12px",
                            }}
                          >
                            <Box sx={{ fontWeight: "bold" }}>{attachment.fileName ?? "No Name"}</Box>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  ""
                )}
              </Box>
            </List>
          ))}
        </>
      ) : (
        <EmptyState />
      )}
    </Box>
  );
}
