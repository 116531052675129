import { IconButton, Paper, Stack } from "@mui/material";
import { StyledDataGrid } from "../../app/component/StyledDataGrid";
import { Delete, Edit } from "@mui/icons-material";
import { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { Operator } from "../../app/config/enum";

function SettingApprovalDataGrid() {
  const { settingApprovalStore, dialogStore } = useStore();
  const { getSettingApprovalGrid, queryParams, setQueryParams, settingApprovalGridLoading, setSelectedSettingApproval, deleteSettingApproval, settingApprovalGrid } =
    settingApprovalStore;

  const onDelete = (id: string) =>
    dialogStore.open({
      action: async () => await deleteSettingApproval(id).then(getSettingApprovalGrid),
      actionText: "Delete",
      title: "Delete",
      closeText: "Cancel",
      actionButtonProps: {
        color: "error",
      },
      description: "Are you sure want to delete this item?",
    });

  useEffect(() => {
    getSettingApprovalGrid();
  }, [getSettingApprovalGrid]);

  return (
    <Paper sx={{ p: "16px" }}>
      <StyledDataGrid
        loading={settingApprovalGridLoading}
        rows={settingApprovalGrid.data}
        rowCount={settingApprovalGrid.rowCount}
        pagination
        onPageChange={(v: string | number) => setQueryParams("currentPage", v)}
        onPageSizeChange={(v: string | number) => setQueryParams("pageSize", v)}
        paginationMode="server"
        pageSize={queryParams.pageSize}
        page={queryParams.currentPage}
        columns={[
          {
            field: "category",
            headerName: "Category",
            flex: 0.5,
          },
          {
            field: "organizationName",
            headerName: "Organization Name",
            flex: 1,
          },
          {
            field: "status",
            headerName: "Status",
            flex: 1,
          },
          {
            field: "minCost",
            headerName: "Min Cost",
            flex: 1,
            renderCell: (params) => 'Rp ' + params.row.minCost.toLocaleString()
          },
          {
            field: "maxCost",
            headerName: "Max Cost",
            flex: 1,
            renderCell: (params) => 'Rp ' + params.row.maxCost.toLocaleString()
          },
          {
            field: "action",
            headerName: "Action",
            width: 100,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            resizable: false,
            renderCell: (params: any) => (
              <Stack direction="row" spacing="8px">
                <IconButton size="small" onClick={() => setSelectedSettingApproval(params.row as any)}>
                  <Edit />
                </IconButton>
                <IconButton size="small" onClick={() => onDelete(params.row.id)}>
                  <Delete />
                </IconButton>
              </Stack>
            ),
          },
        ]}
        disableSelectionOnClick
        autoHeight
      />
    </Paper>
  );
}

export default observer(SettingApprovalDataGrid);
