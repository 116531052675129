import { Box } from "@mui/material";
import DashboardLayoutHeader from "../../app/component/layout/DashboardLayoutHeader";
import { Add } from "@mui/icons-material";
import UnitBusinessDataFrid from "./UnitBusinessDataFrid";
import UnitBusinessForm from "./UnitBusinessForm";
import { useState } from "react";

export default function UnitBusinessPageIndex() {
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false)
  
  return (
    <Box>
      <DashboardLayoutHeader
        breadcrumbs={[
          {
            label: 'Mata Anggaran',
            href: '/master/unit-business'
          },
          {
            label: 'Mata Anggaran List',
            href: '/master/unit-business'
          },
        ]}
        pageTitle="Mata Anggaran List"
      >
        <DashboardLayoutHeader.Button 
          startIcon={<Add />}
          variant="contained"
          onClick={() => setIsOpenForm(true)}
        >
          Add Mata Anggaran
        </DashboardLayoutHeader.Button>
      </DashboardLayoutHeader>
      <UnitBusinessDataFrid />
      <UnitBusinessForm isOpen={isOpenForm} onClose={() => setIsOpenForm(false)} />
    </Box>
  )
}