import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogContent, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { NumericFormat } from "react-number-format";
import * as yup from 'yup';
import { useStore } from "../../../app/stores/store";
import useQuery from "../../../app/hook/useQuery";

interface AddMilestoneDialogProps {
  isOpen: boolean;
  onClose: () => any
  readOnly: boolean;
}

export const AddMilestoneDialog = ({ readOnly, isOpen, onClose }: AddMilestoneDialogProps) => {
  const { addMilestone, getMilestone } = useStore().milestonetore
  const query = useQuery()
  const id = query.get('id')
  
  const closeDialog = () => {
    onClose()
    resetForm()
    setErrors({})
  }

  const successCallback = () => {
    closeDialog();

    if (!id) return;
    
    getMilestone(id);
  }
  
  const { values, resetForm, errors, submitForm, isValid, setFieldValue, handleChange, setErrors, dirty, isSubmitting } = useFormik({
    initialValues: {
      name: "",
      cost: 0,
    },
    onSubmit: async (values) => {
      if (!id) return;

      await addMilestone(id, values).then(successCallback);
    },
    validationSchema: yup.object({
      name: yup.string().required(),
      cost: yup.number().min(0).required(),
    }),
  });
  
  return (
    <Dialog open={isOpen}>
        <DialogContent sx={{ padding: "16px", width: "365px" }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontWeight="700" fontSize="14px" color="black">
              Add Milestone
            </Typography>
            <Close onClick={closeDialog} />
          </Stack>
          <Box mt={"16px"} display="flex" flexDirection="column" gap={3}>
            <TextField
              fullWidth
              label="Name"
              value={values.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
              name="name"
              disabled={readOnly}
            />
            <NumericFormat
              customInput={TextField}
              label="Cost"
              fullWidth
              placeholder="Cost"
              disabled={readOnly}
              onKeyDown={(e) => {
                if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                  e.preventDefault();
                }
              }}
              value={values.cost}
              thousandSeparator={true}
              prefix={"Rp"}
              onValueChange={(e) => {
                setFieldValue("cost", e.floatValue);
              }}
            />
          </Box>
          <Stack
            direction="row"
            justifyContent="flex-end"
            gap="12px"
            mt={"24px"}
          >
            <Button
              onClick={closeDialog}
              type="button"
              variant="outlined"
              color="inherit"
            >
              Cancel
            </Button>
            <LoadingButton
              onClick={submitForm}
              disabled={!isValid || !dirty}
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
            >
              Add
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
  )
}