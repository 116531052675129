import { useFormik } from "formik";
import DrawerForm from "../../app/component/DrawerForm";
import * as yup from "yup";
import { TextField } from "@mui/material";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";

const validationSchema = yup.object({
  status: yup.string().required(),
  description: yup.string(),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function AuctionStatusForm({ isOpen, onClose }: { isOpen: boolean; onClose: () => any }) {
  const { addAuctionStatus, selectedAuctionStatus, editAuctionStatus, getAuctionStatusGrid, setSelectedAuctionStatus } =
    useStore().auctionStatusStore;

  const initialValues: ValidationSchema = useMemo(() => {
    if (selectedAuctionStatus) {
      return selectedAuctionStatus;
    } else {
      return { status: "", description: "" };
    }
  }, [selectedAuctionStatus]);

  const onSubmit = async (values: ValidationSchema) => {
    if (selectedAuctionStatus) {
      await editAuctionStatus(selectedAuctionStatus.id, values).then(() => {
        getAuctionStatusGrid();
        onClose();
        setSelectedAuctionStatus(null);
        resetForm();
        setErrors({
          status: undefined,
        });
      });
    } else {
      await addAuctionStatus(values).then(() => {
        getAuctionStatusGrid();
        onClose();
        setSelectedAuctionStatus(null);
        resetForm();
        setErrors({
          status: undefined,
        });
      });
    }
  };

  const { handleSubmit, resetForm, setErrors, handleChange, values, errors, isValid, isSubmitting, dirty } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <DrawerForm
      title={`${selectedAuctionStatus ? "Edit" : "Add"} Status Lelang`}
      isOpen={isOpen || !!selectedAuctionStatus}
      onClose={() => {
        onClose();
        setSelectedAuctionStatus(null);
      }}
      onSubmit={handleSubmit}
      disableAction={!isValid || !dirty}
      isLoading={isSubmitting}
      isEdit={!!selectedAuctionStatus}
    >
      <TextField
        label="Status"
        fullWidth
        name="status"
        value={values.status}
        error={!!errors.status}
        helperText={errors.status}
        onChange={handleChange}
      />
      <TextField
        label="Description"
        fullWidth
        name="description"
        value={values.description}
        error={!!errors.description}
        helperText={errors.description}
        onChange={handleChange}
        multiline
        rows={5}
      />
    </DrawerForm>
  );
}

export default observer(AuctionStatusForm);
