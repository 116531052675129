import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { ITaskSubTaskAttachment } from "../../../../../../../app/models/taskSubTaskAttachment";
import { DescriptionOutlined } from "@mui/icons-material";
import { Box, Grid, Skeleton, TextField } from "@mui/material";
import CommentAttachment from "./CommentAttachment";
import { StatusLelang, TaskSubTaskStatus, enumToArray } from "../../../../../../../app/config/enum";
import EmptyState from "../../../../../../../app/component/EmptyState";
import FilteredHistory from "./FilteredHistory";
import CSelect from "../../../../../../../app/component/CSelect";
import { NumericFormat } from "react-number-format";
import ViewAttachment from "../../../../../../../app/component/ViewAttachment";
import { useStore } from "../../../../../../../app/stores/store";

type TAttachtmentSectionProps = {
  isAttachmentReview?: boolean;
  subTask?: any;
  task?: any;
  proposalId: string;
  disableSubmitButton: boolean;
  columnOnLine?: number; // this props is equal with xs props in MUI grid
  attachtment: ITaskSubTaskAttachment[];
  setIsOpenDrawer: Dispatch<SetStateAction<any>>;
  currentStatus: any;
};

export default function AttachmentList({
  proposalId,
  currentStatus,
  setIsOpenDrawer,
  subTask,
  disableSubmitButton,
  columnOnLine,
  attachtment,
  task,
}: TAttachtmentSectionProps) {
  const { auctionStatus } = useStore().auctionStatusStore;
  const [filterredHistory, setFilteredHistory] = useState<any>();

  useEffect(() => {
    let temp: ITaskSubTaskAttachment[] = [...attachtment];
    let tempFilterredHistory: any = {
      perencanaan: [],
      docTender: [],
      lelang: [],
      pemenangLelang: [],
      construction: [],
      pembayaran: [],
    };

    temp.map((item: ITaskSubTaskAttachment) => {
      if (item.submittedOnStep === TaskSubTaskStatus.Perencanaan && item.isApproved) tempFilterredHistory.perencanaan.push(item);
      if (item.submittedOnStep === TaskSubTaskStatus.DocumentTender && item.isApproved) tempFilterredHistory.docTender.push(item);
      if (item.submittedOnStep === TaskSubTaskStatus.Lelang && item.isApproved) tempFilterredHistory.lelang.push(item);
      if (item.submittedOnStep === TaskSubTaskStatus.PemenangLelang && item.isApproved) tempFilterredHistory.pemenangLelang.push(item);
      if (item.submittedOnStep === TaskSubTaskStatus.Construction && item.isApproved) tempFilterredHistory.construction.push(item);
      if (item.submittedOnStep === TaskSubTaskStatus.Pembayaran && item.isApproved) tempFilterredHistory.pembayaran.push(item);
    });

    setFilteredHistory(tempFilterredHistory);
  }, [attachtment]);

  return (
    <div>
      {attachtment.length > 0 ? (
        <>
          <Box sx={{ mt: "24px", mb: "12px", fontSize: "24px", fontWeight: "bold" }}>Attachments</Box>
          <Grid sx={{ pb: "24px" }} pt="12px" container spacing={3}>
            {attachtment?.filter(
              (filterData) => filterData.isApproved === false && filterData.submittedOnStep !== (subTask ? subTask.status : task.status)
            ).length > 0 ? (
              <>
                {attachtment
                  ?.filter((filterData) => filterData.isApproved === false && filterData.submittedOnStep !== (subTask ? subTask.status : task.status))
                  .map((item, index) => (
                    <Grid key={item.id} item xs={columnOnLine ?? 6} spacing={3}>
                      {currentStatus === TaskSubTaskStatus.PemenangLelang && index === 0 ? (
                        <CSelect
                          sx={{ mb: "12px" }}
                          options={auctionStatus.map((item) => ({
                            label: item.status,
                            value: item.id,
                          }))}
                          label="Status Lelang"
                          value={item.auctionStatus}
                          disabled
                        />
                      ) : (
                        <Box sx={{ mb: "-12px" }}>
                          {currentStatus === TaskSubTaskStatus.Construction && index === 0 ? (
                            <>
                              <TextField sx={{ mb: "8px" }} disabled label="Pemenang Lelang" fullWidth value={item.vendorWinner ?? "-"} />
                              <NumericFormat
                                sx={{ mt: "8px", mb: "24px" }}
                                customInput={TextField}
                                label="Contract Value"
                                fullWidth
                                disabled
                                value={item.contractValue}
                                thousandSeparator={true}
                                prefix={"Rp "}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </Box>
                      )}
                      <Box
                        sx={{
                          width: "100%",
                          alignItems: "center",
                          display: "flex",
                          p: "16px",
                          borderRadius: "4px",
                          border: "1px solid rgba(29, 27, 32, 0.12)",
                        }}
                      >
                        <ViewAttachment loadingComponent={<Skeleton width={150} variant="text" />} url={item.url} proposalId={proposalId}>
                          <DescriptionOutlined sx={{ cursor: "pointer" }} />
                        </ViewAttachment>
                        <Box sx={{ width: "100%", justifyContent: "space-between", alignItems: "center", display: "flex", ml: "12px" }}>
                          <Box>
                            <Box sx={{ fontWeight: "bold" }}>{item.fileName}</Box>
                            {item.description ? <Box sx={{ fontSize: "12px" }}>{item.description}</Box> : <Box></Box>}
                            <Box sx={{ fontSize: "12px" }}>{item.submittedOnStepDescription}</Box>
                          </Box>
                          <Box sx={{ display: "flex" }}>
                            <CommentAttachment
                              setIsOpenDrawer={setIsOpenDrawer}
                              subTask={subTask}
                              disableSubmitButton={disableSubmitButton}
                              taskId={item.tasksId}
                              attachmentId={item.id}
                              attachmentStatus={item.status}
                              attachmentComment={item.notes}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
              </>
            ) : (
              <Box sx={{ marginTop: "12px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <EmptyState text="Please submit to proceed the next step" />
              </Box>
            )}
            <Box sx={{ width: "100%", pl: "24px", display: "flex", flexDirection: "column" }}>
              <Box sx={{ my: "24px", fontSize: "24px", fontWeight: "bold" }}>History</Box>
              {filterredHistory?.perencanaan.length > 0 ? (
                <FilteredHistory
                  currentStatus={currentStatus}
                  setIsOpenDrawer={setIsOpenDrawer}
                  subTask={subTask}
                  data={filterredHistory?.perencanaan}
                  title="Perencanaan"
                />
              ) : (
                ""
              )}
              {filterredHistory?.docTender.length > 0 ? (
                <FilteredHistory
                  currentStatus={currentStatus}
                  setIsOpenDrawer={setIsOpenDrawer}
                  subTask={subTask}
                  data={filterredHistory?.docTender}
                  title="Dokumen Tender"
                />
              ) : (
                ""
              )}
              {filterredHistory?.lelang.length > 0 ? (
                <FilteredHistory
                  currentStatus={currentStatus}
                  setIsOpenDrawer={setIsOpenDrawer}
                  subTask={subTask}
                  data={filterredHistory?.lelang}
                  title="Lelang"
                />
              ) : (
                ""
              )}
              {filterredHistory?.pemenangLelang.length > 0 ? (
                <FilteredHistory
                  setIsOpenDrawer={setIsOpenDrawer}
                  currentStatus={currentStatus}
                  subTask={subTask}
                  data={filterredHistory?.pemenangLelang}
                  title="Pemenang Lelang"
                />
              ) : (
                ""
              )}
              {filterredHistory?.construction.length > 0 ? (
                <FilteredHistory
                  currentStatus={currentStatus}
                  setIsOpenDrawer={setIsOpenDrawer}
                  subTask={subTask}
                  data={filterredHistory?.construction}
                  title="Construction"
                />
              ) : (
                ""
              )}
              {filterredHistory?.construction.length > 0 ? (
                <FilteredHistory
                  currentStatus={currentStatus}
                  setIsOpenDrawer={setIsOpenDrawer}
                  subTask={subTask}
                  data={filterredHistory?.pembayarab}
                  title="Perencanaan"
                />
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </>
      ) : (
        <EmptyState text="Please submit to proceed the next step" />
      )}
    </div>
  );
}
